import React from "react";
import { Page, setPage } from "features/appstate/AppStateSlice";
import { TemplateSurveys } from "api/sms-api";
import { Spin, Table } from "antd";
import { RootState } from "store/store";
import { connect } from "react-redux";
import { getTemplateSurveys } from "./SurveyActions";
import i18n from "i18n";
import SMSLink from "components/SMSLink";
import { compact } from "lodash";

interface SurveyTemplateProps {
  setPage: (page: Page) => void;
  templateSurveys: Array<TemplateSurveys>;
  templateSurveysLoading: boolean;
  getTemplateSurveys: () => void;
}

const SurveyTemplate: React.FC<SurveyTemplateProps> = (props) => {
  React.useEffect(() => {
    props.setPage(Page.surveyTemplate);
  }, [props.setPage]);

  React.useEffect(() => {
    props.getTemplateSurveys();
  }, []);

  if (props.templateSurveysLoading) {
    return <Spin />;
  }

  const columns = () =>
    compact([
      {
        title: i18n.t("survey.table.title.title"),
        dataIndex: "title",
        key: "title",
        render: (title: string, record: TemplateSurveys) => {
          return (
            <SMSLink
              to={`/${Page.survey}/edit`}
              queryParams={{
                id: record.id,
                type: "template",
              }}
            >
              {title || i18n.t("survey.table.item.untitled")}
            </SMSLink>
          );
        },
      },
    ]);

  return (
    <Table
      dataSource={props.templateSurveys}
      columns={columns()}
      rowKey={(template: TemplateSurveys) => template.id}
      size="middle"
      pagination={false}
      loading={props.templateSurveysLoading}
    />
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    templateSurveys: state.survey.templateSurveys,
    templateSurveysLoading: state.survey.templateSurveysLoading,
  };
};

const mapDispatchToProps = {
  getTemplateSurveys,
  setPage,
};

export default connect(mapStateToProps, mapDispatchToProps)(SurveyTemplate);
