import React, { useEffect } from "react";
import { Page, setPage } from "features/appstate/AppStateSlice";
import { RootState } from "store/store";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { Row, Spin } from "antd";
import i18n from "i18n";
import { EcLink } from "api/sms-api";
import { getEcLinks } from "./EcLinkStatusActions";
import { Alert } from "antd";

interface EcLinkStatusProps {
  ecLinks: Array<EcLink>;
  ecLinksLoading: boolean;
  getEcLinks: () => void;

  setPage: (page: Page) => void;
}

const EcLinkStatus: React.FC<EcLinkStatusProps & RouteComponentProps> = (
  props
) => {
  useEffect(() => {
    props.setPage(Page.ecLinkStatus);
  }, [props.setPage]);

  React.useEffect(() => {
    props.getEcLinks();
  }, [props.getEcLinks]);

  if (props.ecLinksLoading) {
    return <Spin />;
  }

  const noEcLinksAlert = (
    <Alert type="warning" message={i18n.t("ecLinkStatus.noEcLinks")} />
  );
  const ecLinkRows =
    props.ecLinks.length > 0
      ? props.ecLinks
          .filter((ecLink) => ecLink.isLinked)
          .map((ecLink) => {
            return (
              <Row key={ecLink.linkName}>
                {i18n.t(`ecLinkStatus.${ecLink.linkName}`)}
              </Row>
            );
          })
      : noEcLinksAlert;

  return (
    <div>
      <div className="table-button-row">{ecLinkRows}</div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    ecLinks: state.ecLinkStatus.ecLinks,
    ecLinksLoading: state.ecLinkStatus.ecLinksLoading,
  };
};

const mapDispatchToProps = {
  setPage,
  getEcLinks,
};

export default connect(mapStateToProps, mapDispatchToProps)(EcLinkStatus);
