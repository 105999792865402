/* eslint-disable */
import { AppDispatch, AppThunk } from "store/store";
import { httpWrapper } from "../../utils/http";
import { SMS_API_BASE } from "../../constants";
import { handleError } from "errors/errors";
import {
  listGroupStart,
  listGroupSuccess,
  listGroupError,
  getGroupStart,
  getGroupSuccess,
  getGroupError,
  addGroupStart,
  addGroupSuccess,
  addGroupError,
  editGroupStart,
  editGroupSuccess,
  editGroupError,
  deleteGroupStart,
  deleteGroupSuccess,
  deleteGroupError,
} from "./GroupSlice";
import {
  Paginated,
  ListGroupParams,
  FullGroup,
  Group,
  GroupAddForm,
} from "../../api/sms-api";
import i18n from "i18n";
import { showNotification } from "utils/notifications";

export const listGroup = (params: ListGroupParams): AppThunk => async (
  dispatch: AppDispatch
) => {
  dispatch(listGroupStart());
  try {
    const items = await httpWrapper<Paginated<FullGroup>>(
      "GET",
      `${SMS_API_BASE}/group`,
      params
    );

    dispatch(listGroupSuccess(items));
  } catch (e) {
    handleError(e);
    dispatch(listGroupError(e));
  }
};

export const getGroup = (id: string): AppThunk => async (
  dispatch: AppDispatch
) => {
  dispatch(getGroupStart());
  try {
    const item = await httpWrapper<FullGroup>(
      "GET",
      `${SMS_API_BASE}/group/${id}`,
      {}
    );
    dispatch(getGroupSuccess(item));
  } catch (e) {
    handleError(e);
    dispatch(getGroupError(e));
  }
};

export const addGroup = (params: GroupAddForm): AppThunk => async (
  dispatch: AppDispatch
) => {
  dispatch(addGroupStart());
  try {
    await httpWrapper("POST", `${SMS_API_BASE}/group`, params);

    showNotification(i18n.t("groups.createSuccess"));
    dispatch(addGroupSuccess());
  } catch (e) {
    handleError(e);
    dispatch(addGroupError(e));
  }
};

export const editGroup = (id: string, params: GroupAddForm): AppThunk => async (
  dispatch: AppDispatch
) => {
  dispatch(editGroupStart());
  try {
    await httpWrapper("PATCH", `${SMS_API_BASE}/group/${id}`, params);

    showNotification(i18n.t("groups.editSuccess"));
    dispatch(editGroupSuccess());
  } catch (e) {
    handleError(e);
    dispatch(editGroupError(e));
  }
};

export const deleteGroup = (id: string): AppThunk => async (
  dispatch: AppDispatch
) => {
  dispatch(deleteGroupStart());
  try {
    await httpWrapper("DELETE", `${SMS_API_BASE}/group/${id}`, {});
    showNotification(i18n.t("groups.deleteSuccess"));
    dispatch(deleteGroupSuccess());
  } catch (e) {
    handleError(e);
    dispatch(deleteGroupError(e));
  }
};
