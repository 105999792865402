import React, { useEffect } from "react";
import { RootState } from "store/store";
import { connect } from "react-redux";
import { Modal } from "antd";
import i18n from "i18n";
import { Form, Input } from "antd";
import { splitStringByNewline, emailIsInvalid } from "../../utils/strings";
import {
  SurveyMeoSiteSettings,
  SurveyMeoSiteSettingsUpdateItem,
} from "api/sms-api";
import { updateSurveyMeoSiteSettings } from "./MeoSiteSettingsActions";

const { TextArea } = Input;

interface MeoSitesSettingMailProps {
  selectedMeoSites: Array<SurveyMeoSiteSettings>;
  visible: boolean;
  closeModal: () => void;
  pageSize: number;
  page: number;
  emails: Array<string | null>;

  updateSurveyMeoSiteSettings: (
    pageSize: number,
    page: number,
    form: Array<SurveyMeoSiteSettingsUpdateItem>
  ) => void;
}

const MeoSitesSettingMail: React.FC<MeoSitesSettingMailProps> = (props) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      recipientEmail: props.emails.join("\n") || "",
    });
  }, [props.emails]);

  const onClickSetEmailButton = (value: { recipientEmail: string }) => {
    const mailArray = splitStringByNewline(value.recipientEmail);
    const updateItems = props.selectedMeoSites.map((item) => {
      return {
        meoSiteId: item.meoSiteId,
        recipientEmail: mailArray,
      };
    });
    props.updateSurveyMeoSiteSettings(props.pageSize, props.page, updateItems);
    form.resetFields();
    props.closeModal();
  };

  const onClickCancelButton = () => {
    form.resetFields();
    props.closeModal();
  };

  return (
    <Modal
      visible={props.visible}
      title={i18n.t("meoSiteSettings.table.settings.recipientEmail")}
      okText={i18n.t("common.save")}
      cancelText={i18n.t("common.cancel")}
      onCancel={onClickCancelButton}
      onOk={() => {
        form.validateFields().then((values) => {
          onClickSetEmailButton(values);
        });
      }}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          name="recipientEmail"
          className="input-mail-form"
          validateTrigger={["onBlur"]}
          rules={[
            {
              validator(_, value) {
                const mailArray = splitStringByNewline(value);
                if (!emailIsInvalid(mailArray)) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(i18n.t("validation.mail")));
              },
            },
          ]}
        >
          <TextArea
            placeholder={i18n.t(
              "meoSiteSettings.settingMail.inputMailPlaceholder"
            )}
            autoSize={{ minRows: 5 }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    selectedMeoSites: state.meoSiteSettings.selectedMeoSites,
  };
};

const mapDispatchToProps = {
  updateSurveyMeoSiteSettings,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MeoSitesSettingMail);
