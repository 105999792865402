import { Alert, Button, Col, Row } from "antd";
import { GMBReplyForm, GMBReview, MEOSite } from "api/sms-api";
import { formItemLayout } from "../../constants";
import { Formik } from "formik";
import { Form, Input } from "formik-antd";
import i18n from "i18n";
import React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { RootState } from "store/store";
import { getGMBReview, updateGMBReply, deleteGMBReply } from "./ReviewActions";
import {
  ReviewNeedsUpdate,
  setNeedsUpdate,
  setSavedForm,
  setSelected,
} from "./ReviewSlice";
import { MailOutlined, FormOutlined } from "@ant-design/icons";
import { pushTo } from "utils/navigation";
import { Page } from "features/appstate/AppStateSlice";
import { gmbReplyFormSchema } from "validation";
import BackButton from "components/BackButton";
import { PromptIfDirty } from "components/PromptIfDirty";

interface ReviewReplyMultiProps {
  selected: Array<GMBReview>;
  updateLoading: boolean;
  savedForm?: GMBReplyForm;
  updateGMBReply: (form: GMBReplyForm, isUpdate: boolean) => Promise<void>;
  setSavedForm: (form: GMBReplyForm) => void;
  setNeedsUpdate: (items: Array<ReviewNeedsUpdate>) => void;
  setSelected: (selected: Array<GMBReview>) => void;
  meoSite: MEOSite;
}

const ReviewReplyMulti: React.FC<ReviewReplyMultiProps> = (props) => {
  if (props.selected.length === 0) {
    return <div>{i18n.t("reviews.multiNoneSelected")}</div>;
  }
  const history = useHistory();

  const initialValues: GMBReplyForm = {
    reviewNames: props.selected.map((r) => r.name),
    meoSiteId: props.meoSite.id,
    comment: "",
  };

  if (props.savedForm) {
    initialValues.comment = props.savedForm.comment;
  }

  const handleSubmit = async (form: GMBReplyForm) => {
    await props.updateGMBReply(form, true);
    const updateItems: Array<ReviewNeedsUpdate> = props.selected.map((r) => {
      return {
        reviewName: r.name,
        replyComment: form.comment,
      };
    });
    props.setNeedsUpdate(updateItems);
    props.setSelected([]);
    pushTo(history, `/${Page.reviews}`, {});
  };

  const goToSelectTemplate = () => {
    props.setSavedForm({
      meoSiteId: props.meoSite.id,
      reviewNames: props.selected.map((r) => r.name),
      comment: "",
    });
    pushTo(history, `/${Page.replyTemplates}`, {
      selecting: true,
      backUrl: `/${Page.reviews}/replyMulti`,
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={gmbReplyFormSchema}
      onSubmit={handleSubmit}
    >
      {(_formikBag) => {
        return (
          <Form {...formItemLayout}>
            <PromptIfDirty message={i18n.t("reviews.leaveConfirm")} />
            <Row style={{ marginBottom: "1em" }}>
              <Col {...formItemLayout.labelCol} />
              <Col {...formItemLayout.wrapperCol}>
                <Alert
                  message={
                    <div>
                      <div>
                        {i18n.t("reviews.multiReplyExplanation", {
                          count: props.selected.length,
                        })}
                      </div>
                      <div>{i18n.t("reviews.multiReplyRecipients")}</div>
                      <div>
                        {props.selected.map((r, i) => (
                          <div key={i}>{r.reviewer.displayName || null}</div>
                        ))}
                      </div>
                    </div>
                  }
                />
              </Col>
            </Row>
            <Form.Item name="comment" label={i18n.t("reviews.replyComment")}>
              <Input.TextArea name="comment" autoSize={{ minRows: 3 }} />
            </Form.Item>
            <Row className="ant-form-item">
              <Col {...formItemLayout.labelCol}></Col>
              <Col>
                <Button icon={<FormOutlined />} onClick={goToSelectTemplate}>
                  {i18n.t("reviews.selectFromTemplate")}
                </Button>
              </Col>
            </Row>
            <Row className="form-button-row">
              <Button
                loading={props.updateLoading}
                icon={<MailOutlined />}
                htmlType="submit"
                type="primary"
              >
                {i18n.t("reviews.doReply")}
              </Button>
              <BackButton disabled={props.updateLoading} />
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    selected: state.reviews.selected,
    getLoading: state.reviews.getLoading,
    deleteLoading: state.reviews.deleteLoading,
    updateLoading: state.reviews.updateLoading,
    savedForm: state.reviews.savedForm,
    meoSite: state.seo.meoSite!,
  };
};

const mapDispatchToProps = {
  getGMBReview,
  updateGMBReply,
  deleteGMBReply,
  setSavedForm,
  setNeedsUpdate,
  setSelected,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReviewReplyMulti);
