import React, { useEffect } from "react";
import { Page, setPage } from "features/appstate/AppStateSlice";
import { RootState } from "store/store";
import { connect } from "react-redux";
import { useHistory, useLocation, RouteComponentProps } from "react-router-dom";
import { Table, Row, Spin } from "antd";
import i18n from "i18n";
import {
  Paginated,
  OrderSurveyEmailWithOrder,
  PaginationParams,
} from "api/sms-api";
import { PAGE_LIMIT, MODE_SURVEY, surveyTypeStore } from "../../constants";
import _ from "lodash";
import { clearItems } from "./OrderSurveyEmailsSlice";
import { getOrderSurveyEmails } from "./OrderSurveyEmailsActions";
import qs from "qs";
import SimplePagination, {
  PaginatedComponentProps,
} from "components/SimplePagination";
import moment from "moment";
import SMSLink from "components/SMSLink";

interface OrderSurveyEmailsProps {
  items: Paginated<OrderSurveyEmailWithOrder>;
  listLoading: boolean;
  getOrderSurveyEmails: (param: PaginationParams) => void;

  setPage: (page: Page) => void;
  clearItems: () => void;
}

const OrderSurveyEmails: React.FC<
  OrderSurveyEmailsProps & RouteComponentProps & PaginatedComponentProps
> = (props) => {
  const history = useHistory();
  const location = useLocation();
  const parsed = qs.parse(location.search, { ignoreQueryPrefix: true });

  useEffect(() => {
    props.setPage(Page.orderSurveyEmails);
  }, [props.setPage]);

  useEffect(() => {
    const params = {
      ..._.omit(parsed, ["before", "after"]),
    };
    props.getOrderSurveyEmails({ ...params, limit: PAGE_LIMIT });
  }, [props.getOrderSurveyEmails]);

  React.useEffect(() => {
    return () => {
      props.clearItems();
    };
  }, []);

  if (props.listLoading) {
    return <Spin />;
  }

  const columns = () => [
    {
      title: i18n.t("orderSurveyEmails.table.title.sentAt"),
      dataIndex: "sentAt",
      key: "sentAt",
      render: (_: any, item: OrderSurveyEmailWithOrder) => {
        return item.sentAt
          ? moment(item.sentAt).format("YYYY-MM-DD HH:mm:ss")
          : "-";
      },
    },
    {
      title: i18n.t("orderSurveyEmails.table.title.sendAt"),
      dataIndex: "sendAt",
      key: "sentAt",
      render: (_: any, item: OrderSurveyEmailWithOrder) => {
        return item.sendAt
          ? moment(item.sendAt).format("YYYY-MM-DD HH:mm:ss")
          : "-";
      },
    },
    {
      title: i18n.t("orderSurveyEmails.table.title.orderId"),
      dataIndex: "orderId",
      key: "orderId",
      render: (_: any, item: OrderSurveyEmailWithOrder) => {
        // Check if there are multiple orders
        let linkDisplay = item.order.orderId;
        if (item.hasMultipleOrders) {
          linkDisplay = `${item.order.orderId} (${item.orderIndex})`;
        }
        return (
          <SMSLink
            to={`/${Page.orderHistory}/orderSurveyEmailDetails`}
            queryParams={{
              id: item.order.id,
              mode: MODE_SURVEY,
              surveyType: parsed.surveyType || surveyTypeStore,
            }}
          >
            {linkDisplay}
          </SMSLink>
        );
      },
    },
    {
      title: i18n.t("orderSurveyEmails.table.title.subject"),
      dataIndex: "subject",
      key: "subject",
    },
    {
      title: i18n.t("orderSurveyEmails.table.title.emailAddress"),
      dataIndex: "emailAddress",
      key: "emailAddress",
    },
    {
      title: i18n.t("orderSurveyEmails.table.title.openStatus"),
      dataIndex: "openStatus",
      key: "openStatus",
      render: (_: any, item: OrderSurveyEmailWithOrder) => {
        return item.openStatus
          ? i18n.t(`orderSurveyEmails.${item.openStatus}`)
          : i18n.t("orderSurveyEmails.Open_Status_Unread");
      },
    },
    {
      title: i18n.t("orderSurveyEmails.table.title.sendStatus"),
      dataIndex: "sendStatus",
      key: "sendStatus",
      render: (_: any, item: OrderSurveyEmailWithOrder) => {
        return item.sendStatus
          ? i18n.t(`orderSurveyEmails.${item.sendStatus}`)
          : i18n.t("orderSurveyEmails.Send_Status_Failure");
      },
    },
    {
      title: i18n.t("orderSurveyEmails.table.title.clickCount"),
      dataIndex: "clickCount",
      key: "clickCount",
    },
  ];

  const pagination = (
    <SimplePagination
      items={props.items.items}
      getCursor={(model: OrderSurveyEmailWithOrder) => model.createdAt}
      sortDescending={true}
      hasRight={props.items.hasRight}
      hasLeft={props.items.hasLeft}
      location={props.location}
      history={history}
      match={props.match}
    />
  );

  return (
    <div>
      <Table
        dataSource={props.items.items}
        columns={columns()}
        rowKey={(items: OrderSurveyEmailWithOrder) => items.id}
        size="middle"
        pagination={false}
      />
      <Row className="pagination-row">{pagination}</Row>
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    items: state.orderSurveyEmails.items,
    listLoading: state.orderSurveyEmails.listLoading,
  };
};

const mapDispatchToProps = {
  setPage,
  clearItems,
  getOrderSurveyEmails,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderSurveyEmails);
