import React, { useEffect } from "react";
import { Formik } from "formik";
import { Form, InputNumber, Select } from "formik-antd";
import i18n from "i18n";
import { Spin, Row, Col } from "antd";
import { connect } from "react-redux";
import { RootState } from "store/store";
import { SurveyEcEmailSettingsData, SurveyEcEmailSettings } from "api/sms-api";
import { ecEmailSettingsFormSchema } from "validation";
import { getEcEmailSettings, editEcEmailSettings } from "./SurveyActions";
import qs from "qs";
import { Page, setPage } from "features/appstate/AppStateSlice";
import SaveButton from "components/SaveButton";
import { useHistory } from "react-router-dom";
import { pushTo } from "utils/navigation";
import { MODE_SURVEY, surveyTypeStore } from "../../constants";

interface EcEmailSettingsProps {
  setPage: (page: Page) => void;

  ecEmailSettings?: SurveyEcEmailSettings;
  ecEmailSettingsLoading: boolean;
  getEcEmailSettings: (surveyId: string) => void;

  editEcEmailSettings: (params: SurveyEcEmailSettingsData) => Promise<void>;
}

export interface EcEmailSettingsFormProps {
  waitDays: number;
  sendTime: string;
}

const EcEmailSettings: React.FC<EcEmailSettingsProps> = (props) => {
  const history = useHistory();
  const parsed = qs.parse(location.search, { ignoreQueryPrefix: true });
  const surveyId = parsed.id ? parsed.id.toString() : "";

  useEffect(() => {
    props.setPage(Page.ecEmailSettings);
  }, [props.setPage]);

  useEffect(() => {
    if (surveyId) {
      props.getEcEmailSettings(surveyId);
    }
  }, [props.getEcEmailSettings, surveyId]);

  if (props.ecEmailSettingsLoading) {
    return <Spin />;
  }

  const handleSubmit = (form: EcEmailSettingsFormProps) => {
    const param: SurveyEcEmailSettingsData = {
      surveysId: surveyId,
      waitDays: form.waitDays,
      sendTime: form.sendTime,
    };
    props.editEcEmailSettings(param);
    pushTo(history, "/survey", {
      mode: MODE_SURVEY,
      surveyType: parsed.surveyType || surveyTypeStore,
    });
  };

  const initialValues = {
    waitDays: props.ecEmailSettings?.waitDays || 0,
    sendTime: props.ecEmailSettings?.sendTime || "00:00:00",
  };

  const sendTimeOptions: Array<any> = [];
  for (let i = 0; i < 24; i++) {
    const value = i < 10 ? `0${i}:00` : `${i}:00`;
    sendTimeOptions.push({
      value: `${value}:00`,
      label: value,
    });
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={ecEmailSettingsFormSchema}
    >
      {(formikBag) => {
        return (
          <Form layout="vertical">
            <div className="question-container">
              <Row justify="center">
                <Col span={18}>
                  <Form.Item
                    name="waitDays"
                    label={i18n.t("survey.waitDaysTitle")}
                  >
                    <InputNumber
                      name="waitDays"
                      min={0}
                      defaultValue={formikBag.values.waitDays}
                    />
                  </Form.Item>
                  <Form.Item
                    name="sendTime"
                    label={i18n.t("survey.sendTimeTitle")}
                  >
                    <Select
                      name="sendTime"
                      defaultValue={formikBag.values.sendTime}
                      placeholder={i18n.t("survey.editSendTimePlaceholder")}
                      options={sendTimeOptions}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row justify="end" style={{ marginTop: "2.5em" }}>
                <SaveButton />
              </Row>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    ecEmailSettings: state.survey.ecEmailSettings,
    ecEmailSettingsLoading: state.survey.ecEmailSettingsLoading,
  };
};

const mapDispatchToProps = {
  setPage,
  getEcEmailSettings,
  editEcEmailSettings,
};

export default connect(mapStateToProps, mapDispatchToProps)(EcEmailSettings);
