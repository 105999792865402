import React from "react";
import { RollbackOutlined } from "@ant-design/icons";
import SMSLink from "components/SMSLink";
import { connect } from "react-redux";
import { Page, setPage } from "features/appstate/AppStateSlice";
import { listTemplates } from "features/templates/TemplatesActions";
import { clearItems } from "features/templates/TemplatesSlice";
import {
  Paginated,
  Template,
  ListTemplateParams,
  MEOSite,
  TemplateType,
} from "api/sms-api";
import { Table, Button, Row, Alert } from "antd";
import { Radio } from "antd";
import { RootState } from "store/store";
import qs from "qs";
import i18n from "i18n";
import SimplePagination, {
  PaginatedComponentProps,
} from "components/SimplePagination";
import { RouteComponentProps } from "react-router";
import { useHistory, useLocation } from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";
import _ from "lodash";
import { setSavedForm } from "features/publish/PublishSlice";
import { ModifiedPublishForm } from "components/SendForm";
import Media from "react-media";
import { mobileMediaQuery } from "../../constants";
import { RadioChangeEvent } from "antd/lib/radio";
import { pushTo } from "utils/navigation";

interface TemplatesProps {
  templates: Paginated<Template>;
  loading: boolean;
  savedForm?: ModifiedPublishForm;

  meoSite: MEOSite;
  setPage: (page: Page) => void;
  listTemplates: (params: ListTemplateParams) => void;
  setSavedForm: (form: ModifiedPublishForm) => void;
  clearItems: () => void;
}

const Templates: React.FC<
  TemplatesProps & RouteComponentProps & PaginatedComponentProps
> = (props) => {
  const history = useHistory();
  const location = useLocation();

  const [selecting, setSelecting] = React.useState(false);

  React.useEffect(() => {
    props.setPage(Page.templates);
  }, [props.setPage]);

  React.useEffect(() => {
    const parsed = qs.parse(location.search, { ignoreQueryPrefix: true });
    if (_.has(parsed, "selecting")) {
      setSelecting(true);
    }

    // Remove other parameters that might show up in the query string
    const params = {
      ..._.omit(parsed, ["selecting"]),
      meoSiteId: props.meoSite.id,
    };

    props.listTemplates(params);
  }, [props.listTemplates, location.search, props.meoSite]);

  React.useEffect(() => {
    return () => {
      props.clearItems();
    };
  }, [props.clearItems]);

  // Select a template, or if null, go back without selecting anything
  const selectTemplate = (template?: Template) => {
    if (props.savedForm) {
      const form = Object.assign({}, props.savedForm);
      if (template) {
        form.message = template.content;
      }
      props.setSavedForm(form);
    }
    pushTo(history, "/publish", {
      shortcutSelected: template ? "template" : "back",
    });
  };

  const columns = (smallScreen: boolean) => [
    {
      title: i18n.t("templates.name"),
      dataIndex: "name",
      key: "name",
      render: (name: string, template: Template) => {
        const nameText = smallScreen ? _.truncate(name, { length: 10 }) : name;
        if (selecting) {
          return (
            <Button type="link" onClick={() => selectTemplate(template)}>
              {nameText}
            </Button>
          );
        } else {
          return (
            <SMSLink to={`/templates/${template.id}`}> {nameText}</SMSLink>
          );
        }
      },
    },
    {
      title: i18n.t("templates.content"),
      dataIndex: "content",
      key: "content",
      render: (content: string) => {
        return _.truncate(content, { length: 20 });
      },
    },
    {
      title: i18n.t("templates.type"),
      dataIndex: "type",
      key: "type",
      render: (type: string) => {
        return i18n.t("templateTypes." + type);
      },
    },
  ];

  const addButton = (
    <SMSLink to="/templates/add">
      <Button icon={<PlusOutlined />} type="primary">
        {i18n.t("templates.add")}
      </Button>
    </SMSLink>
  );

  const pagination = (
    <SimplePagination
      items={props.templates.items}
      getCursor={(model: Template) => model.createdAt}
      sortDescending={true}
      hasRight={props.templates.hasRight}
      hasLeft={props.templates.hasLeft}
      location={props.location}
      history={history}
      match={props.match}
    />
  );

  const selectingExplanation = selecting && (
    <Alert
      message={
        <div>
          <span>{i18n.t("templates.selectingExplanation")}</span>
          <Button
            icon={<RollbackOutlined />}
            onClick={() => selectTemplate(undefined)}
          >
            {i18n.t("common.back")}
          </Button>
        </div>
      }
      type="info"
      style={{ marginBottom: "2em" }}
    />
  );

  const handleTypeChange = () => (e: RadioChangeEvent) => {
    const parsed = qs.parse(location.search, { ignoreQueryPrefix: true });
    delete parsed["type"];
    delete parsed["before"];
    delete parsed["after"];
    if (e.target.value !== "all") {
      parsed["type"] = e.target.value;
    }
    pushTo(props.history, null, parsed);
  };

  const templateTypeValue = () => {
    const parsed = qs.parse(location.search, { ignoreQueryPrefix: true });
    if (_.has(parsed, "type")) {
      if (parsed["type"] === TemplateType.Store) {
        return TemplateType.Store;
      } else if (parsed["type"] === TemplateType.Gyron) {
        return TemplateType.Gyron;
      }
    } else {
      return TemplateType.All;
    }
  };
  return (
    <Media query={mobileMediaQuery}>
      {(smallScreen) => (
        <div>
          {selectingExplanation}
          <Radio.Group
            name="radiogroup"
            defaultValue={templateTypeValue()}
            onChange={handleTypeChange()}
          >
            <Radio name={TemplateType.All} value={TemplateType.All}>
              {i18n.t("templateTypes.all")}
            </Radio>
            <Radio name={TemplateType.Gyron} value={TemplateType.Gyron}>
              {i18n.t("templateTypes.gyron")}
            </Radio>
            <Radio name={TemplateType.Store} value={TemplateType.Store}>
              {i18n.t("templateTypes.store")}
            </Radio>
          </Radio.Group>
          <Table
            dataSource={props.templates.items}
            columns={columns(smallScreen)}
            locale={{ emptyText: i18n.t("templates.empty") }}
            rowKey={(c: Template) => c.id}
            size="middle"
            pagination={false}
            loading={props.loading}
            style={{ marginTop: "1em" }}
          />
          <Row className="pagination-row">{pagination}</Row>
          <Row className="form-button-row">
            {selecting ? <div /> : addButton}
          </Row>
        </div>
      )}
    </Media>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    templates: state.templates.items,
    loading: state.templates.listLoading,
    savedForm: state.publish.savedForm,
    meoSite: state.seo.meoSite!,
  };
};
const mapDispatchToProps = {
  setPage,
  listTemplates,
  setSavedForm,
  clearItems,
};

export default connect(mapStateToProps, mapDispatchToProps)(Templates);
