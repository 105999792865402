import React from "react";
import i18n from "i18n";
import { ErrorWrapper, CustomError } from "errors/errors";
import { Alert } from "antd";

interface ErrorDisplayProps {
  error: ErrorWrapper;
}

const ErrorDisplay: React.FC<ErrorDisplayProps> = (props) => {
  let message = i18n.t("errors.unknown");
  switch (props.error.error) {
    case CustomError.LandingPageNotFound:
      message = i18n.t("errors.landingPageNotFound", {
        names: props.error.names,
      });
      break;
    default:
  }
  return <Alert className="error-display" type="error" message={message} />;
};

export default ErrorDisplay;
