import React from "react";
import { Redirect, RedirectProps, withRouter } from "react-router-dom";
import { LocationDescriptorObject } from "history";
import { RouteComponentProps } from "react-router";
import qs from "qs";
import { getNewParams } from "utils/navigation";

// Similar to SMSLink, a Redirect that preserves certain query parameters

type SMSRedirectProps = React.PropsWithoutRef<RedirectProps> &
  RouteComponentProps;

const SMSRedirect: React.FC<SMSRedirectProps> = (props) => {
  const newParams = getNewParams({}, []);
  const newLocation: LocationDescriptorObject = {
    pathname: props.to as string,
    search: qs.stringify(newParams),
  };
  return <Redirect to={newLocation}>{props.children}</Redirect>;
};

export default withRouter(SMSRedirect);
