import { SSOClient } from "api/sms-api";
import React from "react";
import GYRON_LOGO from "../gyron_logo.png";

interface LogoProps {
  ssoClient: SSOClient;
}

const Logo: React.FC<LogoProps> = (props) => {
  if (props.ssoClient.affiliateLogoUrl && props.ssoClient.affiliateLogoType) {
    const logoImage = (
      <img className="header-logo" src={props.ssoClient.affiliateLogoUrl} />
    );
    /*
     affiliateLogoType:
     1: Gyro-n×＜代理店ロゴ＞
     2: ＜代理店ロゴ＞　Powered by Gyro-n
     3: ＜代理店ロゴ＞
     */
    if (props.ssoClient.affiliateLogoType === 1) {
      return <div>Gyro-n×{logoImage}</div>;
    } else if (props.ssoClient.affiliateLogoType === 2) {
      return <div>{logoImage} Powered by Gyro-n</div>;
    } else {
      return logoImage;
    }
  } else {
    return <img className="header-logo" src={GYRON_LOGO} />;
  }
};

export default Logo;
