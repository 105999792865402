import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  Address,
  Paginated,
  AddressImportResult,
  FullAddress,
  ActivityGroup,
} from "../../api/sms-api";
import { initialCRUDState } from "store/CRUDStore";
import { ErrorWrapper } from "errors/errors";

// Group selection is by ID; PublishForm selection is by phoneNumber
export interface SelectedAddress {
  id?: string;
  name?: string;
  phoneNumber?: string;
}

interface AddressesState {
  // These are copied from CRUDState because this Slice uses FullAddress for the list and Address for the get
  items: Paginated<FullAddress>;
  item?: Address;
  listLoading: boolean;
  createLoading: boolean;
  getLoading: boolean;
  editLoading: boolean;
  deleteLoading: boolean;
  deleteFinished: boolean;
  multipleDeleteLoading: boolean;
  multipleDeleteFinished: boolean;
  editFinished: boolean;
  error?: ErrorWrapper;
  shortcutLoading: boolean;
  searchResults: Array<Address>;
  selected: Array<SelectedAddress>;
  importResult?: AddressImportResult;
  activity: Array<ActivityGroup>;
  activityLoading: boolean;
}

export const initialState: AddressesState = {
  ...initialCRUDState,
  shortcutLoading: false,
  searchResults: [],
  selected: [],
  activity: [],
  activityLoading: false,
  multipleDeleteLoading: false,
  multipleDeleteFinished: false,
};

export const slice = createSlice({
  name: "addresses",
  initialState,
  reducers: {
    listAddressesStart: (state) => {
      state.listLoading = true;
    },
    listAddressesSuccess: (
      state,
      action: PayloadAction<Paginated<FullAddress>>
    ) => {
      state.items = action.payload;
      state.listLoading = false;
    },
    listAddressesError: (state, _action: PayloadAction<ErrorWrapper>) => {
      state.listLoading = false;
    },
    addAddressStart: (state) => {
      state.createLoading = true;
    },
    addAddressSuccess: (state) => {
      state.createLoading = false;
    },
    addAddressError: (state, _action: PayloadAction<ErrorWrapper>) => {
      state.createLoading = false;
    },
    editAddressStart: (state) => {
      state.editLoading = true;
    },
    editAddressSuccess: (state) => {
      state.editLoading = false;
      state.editFinished = true;
    },
    editAddressError: (state, _action: PayloadAction<ErrorWrapper>) => {
      state.editLoading = false;
    },
    getAddressStart: (state) => {
      state.getLoading = true;
    },
    getAddressSuccess: (state, action: PayloadAction<Address>) => {
      state.item = action.payload;
      state.getLoading = false;
    },
    getAddressError: (state, action: PayloadAction<ErrorWrapper>) => {
      state.getLoading = false;
      state.error = action.payload;
    },
    deleteAddressStart: (state) => {
      state.deleteLoading = true;
    },
    deleteAddressSuccess: (state) => {
      state.deleteLoading = false;
      state.deleteFinished = true;
    },
    deleteAddressError: (state, _action: PayloadAction<ErrorWrapper>) => {
      state.deleteLoading = false;
    },
    deleteAddressesStart: (state) => {
      state.multipleDeleteLoading = true;
    },
    deleteAddressesSuccess: (state) => {
      state.multipleDeleteLoading = false;
      state.multipleDeleteFinished = true;
    },
    deleteAddressesError: (state, _action: PayloadAction<ErrorWrapper>) => {
      state.multipleDeleteLoading = false;
    },
    clearAddress: (state) => {
      // Reset everything that was specific to viewing one Address
      state.item = undefined;
      state.deleteFinished = false;
      state.multipleDeleteFinished = false;
      state.editFinished = false;
    },
    searchAddressesSuccess: (state, action: PayloadAction<Array<Address>>) => {
      state.searchResults = action.payload;
      // Sharing the loading state with the `list` action for now
      state.listLoading = false;
    },
    setSelected: (state, action: PayloadAction<Array<SelectedAddress>>) => {
      state.selected = action.payload;
    },
    clearSearchResults: (state) => {
      state.searchResults = [];
    },
    importStart: (state) => {
      state.createLoading = true;
    },
    importSuccess: (state, action: PayloadAction<AddressImportResult>) => {
      state.createLoading = false;
      state.importResult = action.payload;
    },
    importError: (state) => {
      state.createLoading = false;
    },
    clearImport: (state) => {
      state.importResult = undefined;
    },
    clearItems: (state) => {
      state.items = { items: [], hasRight: false, hasLeft: false };
    },
    getActivityStart: (state) => {
      state.activityLoading = true;
    },
    getActivitySuccess: (
      state,
      action: PayloadAction<Array<ActivityGroup>>
    ) => {
      state.activity = action.payload;
      state.activityLoading = false;
    },
    getActivityError: (state) => {
      state.activityLoading = false;
    },
  },
});

export const {
  listAddressesStart,
  listAddressesSuccess,
  listAddressesError,
  addAddressStart,
  addAddressSuccess,
  addAddressError,
  editAddressStart,
  editAddressSuccess,
  editAddressError,
  getAddressStart,
  getAddressSuccess,
  getAddressError,
  deleteAddressStart,
  deleteAddressSuccess,
  deleteAddressError,
  deleteAddressesStart,
  deleteAddressesSuccess,
  deleteAddressesError,
  clearAddress,
  searchAddressesSuccess,
  setSelected,
  clearSearchResults,
  importStart,
  importSuccess,
  importError,
  clearImport,
  clearItems,
  getActivityStart,
  getActivitySuccess,
  getActivityError,
} = slice.actions;

export default slice.reducer;
