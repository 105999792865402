import React from "react";
import { connect } from "react-redux";
import { FullGroup } from "api/sms-api";
import { getGroup } from "./GroupActions";
import { RootState } from "store/store";
import { Page, setPage } from "features/appstate/AppStateSlice";
import { Spin, Row } from "antd";
import { CustomError, ErrorWrapper } from "errors/errors";
import NotFound from "components/NotFound";
import AddressBlock from "components/AddressBlock";
import { useParams } from "react-router-dom";
import BackButton from "components/BackButton";

interface GroupAddressProps {
  getLoading: boolean;
  item?: FullGroup;
  error?: ErrorWrapper;
  getGroup: (id: string) => void;
  setPage: (page: Page) => void;
}

const GroupAddress: React.FC<GroupAddressProps> = (props) => {
  const { id } = useParams() as any;

  React.useEffect(() => {
    props.setPage(Page.group);
  }, [props.setPage]);

  React.useEffect(() => {
    props.getGroup(id);
  }, [props.getGroup, id]);

  if (props.error?.error === CustomError.NotFound) {
    return <NotFound />;
  }

  if (props.getLoading || !props.item) {
    return <Spin />;
  }

  return (
    <div>
      <AddressBlock
        addresses={props.item.addresses}
        groupName={props.item.group.name}
      />
      <Row className="form-button-row">
        <BackButton />
      </Row>
    </div>
  );
};

const mapStateToProps = (root: RootState) => {
  return {
    item: root.group.item,
    getLoading: root.group.getLoading,
    error: root.group.error,
  };
};
const mapDispatchToProps = {
  getGroup,
  setPage,
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupAddress);
