import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ReplyTemplate, Paginated } from "../../api/sms-api";
import { ErrorWrapper } from "errors/errors";
import { CRUDState, initialCRUDState } from "store/CRUDStore";

interface ReplyTemplatesState extends CRUDState<ReplyTemplate> {}

export const initialState: ReplyTemplatesState = {
  ...initialCRUDState,
};

export const slice = createSlice({
  name: "reply_templates",
  initialState,
  reducers: {
    listReplyTemplatesStart: (state) => {
      state.listLoading = true;
    },
    listReplyTemplatesSuccess: (
      state,
      action: PayloadAction<Paginated<ReplyTemplate>>
    ) => {
      state.items = action.payload;
      state.listLoading = false;
    },
    listReplyTemplatesError: (state, _action: PayloadAction<ErrorWrapper>) => {
      state.listLoading = false;
    },
    addReplyTemplateStart: (state) => {
      state.createLoading = true;
    },
    addReplyTemplateSuccess: (state) => {
      state.createLoading = false;
    },
    addReplyTemplateError: (state, _action: PayloadAction<ErrorWrapper>) => {
      state.createLoading = false;
    },
    editReplyTemplateStart: (state) => {
      state.editLoading = true;
    },
    editReplyTemplateSuccess: (state) => {
      state.editLoading = false;
    },
    editReplyTemplateError: (state, _action: PayloadAction<ErrorWrapper>) => {
      state.editLoading = false;
    },
    getReplyTemplateStart: (state) => {
      state.getLoading = true;
    },
    getReplyTemplateSuccess: (state, action: PayloadAction<ReplyTemplate>) => {
      state.item = action.payload;
      state.getLoading = false;
      state.error = undefined;
    },
    getReplyTemplateError: (state, action: PayloadAction<ErrorWrapper>) => {
      state.getLoading = false;
      state.error = action.payload;
    },
    deleteReplyTemplateStart: (state) => {
      state.deleteLoading = true;
    },
    deleteReplyTemplateSuccess: (state) => {
      state.deleteLoading = false;
      state.deleteFinished = true;
    },
    deleteReplyTemplateError: (state, _action: PayloadAction<ErrorWrapper>) => {
      state.deleteLoading = false;
    },
    clearReplyTemplate: (state) => {
      state.item = undefined;
    },
    clearItems: (state) => {
      state.items = { items: [], hasRight: false, hasLeft: false };
    },
  },
});

export const {
  listReplyTemplatesStart,
  listReplyTemplatesSuccess,
  listReplyTemplatesError,
  addReplyTemplateStart,
  addReplyTemplateSuccess,
  addReplyTemplateError,
  editReplyTemplateStart,
  editReplyTemplateSuccess,
  editReplyTemplateError,
  getReplyTemplateStart,
  getReplyTemplateSuccess,
  getReplyTemplateError,
  deleteReplyTemplateStart,
  deleteReplyTemplateSuccess,
  deleteReplyTemplateError,
  clearReplyTemplate,
  clearItems,
} = slice.actions;

export default slice.reducer;
