import React from "react";
import { RouteComponentProps } from "react-router-dom";
import SMSLink from "components/SMSLink";
import { connect } from "react-redux";
import { Page, setPage } from "features/appstate/AppStateSlice";
import { listScheduleds } from "features/schedule/ScheduleActions";
import { clearItems } from "features/schedule/ScheduleSlice";
import {
  Paginated,
  ListScheduledParams,
  FullScheduledWithParts,
  MEOSite,
} from "api/sms-api";
import { Table, Row } from "antd";
import { RootState } from "store/store";
import qs from "qs";
import { useHistory, useLocation } from "react-router-dom";
import i18n from "i18n";
import SimplePagination, {
  PaginatedComponentProps,
} from "components/SimplePagination";
import moment from "moment";
import "moment/locale/ja";
import Media from "react-media";
import { mobileMediaQuery } from "../../constants";
import { truncate } from "lodash";

interface SchedulesProps {
  scheduleds: Paginated<FullScheduledWithParts>;
  loading: boolean;

  meoSite: MEOSite;
  setPage: (page: Page) => void;
  listScheduleds: (params: ListScheduledParams) => void;
  clearItems: () => void;
}

const Schedules: React.FC<
  SchedulesProps & RouteComponentProps & PaginatedComponentProps
> = (props) => {
  const history = useHistory();
  const location = useLocation();

  React.useEffect(() => {
    props.setPage(Page.scheduled);
  }, [props.setPage]);

  React.useEffect(() => {
    const parsed = qs.parse(location.search, { ignoreQueryPrefix: true });

    const params = {
      ...parsed,
      meoSiteId: props.meoSite.id,
    };

    props.listScheduleds(params);
  }, [props.listScheduleds, location.search, props.meoSite]);

  React.useEffect(() => {
    return () => {
      props.clearItems();
    };
  }, [props.clearItems]);

  const columns = [
    {
      title: i18n.t("schedule.scheduledTime"),
      dataIndex: "scheduled.scheduledTime",
      key: "scheduled.scheduledTime",
      render: (_: any, scheduled: FullScheduledWithParts) => {
        return (
          <SMSLink
            to={`/${Page.scheduled}/${scheduled.fullScheduled.scheduled.id}`}
          >
            {moment(scheduled.fullScheduled.scheduled.scheduledTime).format(
              "LLLL"
            )}
          </SMSLink>
        );
      },
    },
    {
      title: i18n.t("history.messageParts"),
      dataIndex: "totalGyronMessageParts",
      key: "totalGyronMessageParts",
      render: (_: any, record: FullScheduledWithParts) => (
        <span>{record.gyronMessageParts}</span>
      ),
    },
    {
      title: i18n.t("common.addresses"),
      dataIndex: "contacts",
      key: "contacts",
      render: (_: any, record: FullScheduledWithParts) => {
        return (
          <SMSLink
            to={`/${Page.scheduled}/${record.fullScheduled.scheduled.id}/contacts`}
          >
            {`${i18n.t("common.addresses")} (${
              record.fullScheduled.contacts.length
            })`}
          </SMSLink>
        );
      },
    },
    {
      title: i18n.t("common.group"),
      dataIndex: "groups",
      key: "groups",
      render: (_: any, record: FullScheduledWithParts) => {
        return (
          <SMSLink
            to={`/${Page.scheduled}/${record.fullScheduled.scheduled.id}/groups`}
          >
            {`${i18n.t("common.group")} (${
              record.fullScheduled.groups.length
            })`}
          </SMSLink>
        );
      },
      hideOnSmall: true,
    },
    {
      title: i18n.t("common.message"),
      dataIndex: "scheduled.message",
      key: "scheduled.message",
      render: (_: any, record: FullScheduledWithParts) => {
        return (
          <SMSLink
            to={`/${Page.scheduled}/${record.fullScheduled.scheduled.id}/message`}
          >
            {truncate(record.fullScheduled.scheduled.message, { length: 10 })}
          </SMSLink>
        );
      },
      hideOnSmall: true,
    },
  ];

  const getResponsiveColumns = (smallScreen: boolean) =>
    columns.filter(({ hideOnSmall = false }) => !(smallScreen && hideOnSmall));

  const pagination = (
    <SimplePagination
      items={props.scheduleds.items}
      getCursor={(model: FullScheduledWithParts) =>
        model.fullScheduled.scheduled.scheduledTime
      }
      sortDescending={false}
      hasRight={props.scheduleds.hasRight}
      hasLeft={props.scheduleds.hasLeft}
      location={props.location}
      history={history}
      match={props.match}
    />
  );

  return (
    <Media query={mobileMediaQuery}>
      {(smallScreen) => (
        <div>
          <Table
            dataSource={props.scheduleds.items}
            columns={getResponsiveColumns(smallScreen)}
            locale={{ emptyText: i18n.t("schedule.empty") }}
            rowKey={(c: FullScheduledWithParts) => c.fullScheduled.scheduled.id}
            size="middle"
            pagination={false}
            loading={props.loading}
          />
          <Row className="pagination-row">{pagination}</Row>
        </div>
      )}
    </Media>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    scheduleds: state.scheduleds.items,
    loading: state.scheduleds.listLoading,
    meoSite: state.seo.meoSite!,
  };
};

const mapDispatchToProps = {
  setPage,
  listScheduleds,
  clearItems,
};

export default connect(mapStateToProps, mapDispatchToProps)(Schedules);
