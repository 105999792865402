import React from "react";
import { Modal, Button } from "antd";

interface CustomModalProps {
  title: string;
  okText: string;
  visible: boolean;
  closeModal: () => void;
}

const CustomModal: React.FC<CustomModalProps> = ({
  title,
  okText,
  visible,
  closeModal,
  children,
}) => {
  const handleClick = () => {
    closeModal();
  };

  return (
    <Modal
      visible={visible}
      title={title}
      closable={false}
      onOk={handleClick}
      footer={[
        <Button key="save" type="primary" onClick={handleClick}>
          {okText}
        </Button>,
      ]}
    >
      {children}
    </Modal>
  );
};

export default CustomModal;
