import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MailTemplateSender, Paginated } from "../../api/sms-api";
import { CRUDState, initialCRUDState } from "store/CRUDStore";
import { ErrorWrapper } from "errors/errors";

interface MailTemplateSendersState extends CRUDState<MailTemplateSender> {
  items: Paginated<MailTemplateSender>;
  allItems: Array<MailTemplateSender>;
  listAllLoading: boolean;
  selectedMailTemplateSenders: Array<MailTemplateSender>;
}

export const initialState: MailTemplateSendersState = {
  ...initialCRUDState,
  allItems: [],
  listAllLoading: false,
  selectedMailTemplateSenders: [],
};

export const slice = createSlice({
  name: "mailTemplateSenders",
  initialState,
  reducers: {
    listMailTemplateSenderStart: (state) => {
      state.listLoading = true;
    },
    listMailTemplateSenderSuccess: (state, action) => {
      state.items = action.payload;
      state.listLoading = false;
    },
    listMailTemplateSenderError: (
      state,
      _action: PayloadAction<ErrorWrapper>
    ) => {
      state.listLoading = false;
    },
    listAllMailTemplateSenderStart: (state) => {
      state.listAllLoading = true;
    },
    listAllMailTemplateSenderSuccess: (state, action) => {
      state.allItems = action.payload;
      state.listAllLoading = false;
    },
    listAllMailTemplateSenderError: (
      state,
      _action: PayloadAction<ErrorWrapper>
    ) => {
      state.listAllLoading = false;
    },
    addMailTemplateSenderStart: (state) => {
      state.createLoading = true;
    },
    addMailTemplateSenderSuccess: (state) => {
      state.createLoading = false;
    },
    addMailTemplateSenderError: (
      state,
      _action: PayloadAction<ErrorWrapper>
    ) => {
      state.createLoading = false;
    },
    editMailTemplateSenderStart: (state) => {
      state.editLoading = true;
    },
    editMailTemplateSenderSuccess: (state) => {
      state.editLoading = false;
    },
    editMailTemplateSenderError: (
      state,
      _action: PayloadAction<ErrorWrapper>
    ) => {
      state.editLoading = false;
    },
    getMailTemplateSenderStart: (state) => {
      state.getLoading = true;
    },
    getMailTemplateSenderSuccess: (
      state,
      action: PayloadAction<MailTemplateSender>
    ) => {
      state.item = action.payload;
      state.getLoading = false;
      state.error = undefined;
    },
    getMailTemplateSenderError: (
      state,
      action: PayloadAction<ErrorWrapper>
    ) => {
      state.getLoading = false;
      state.error = action.payload;
    },
    deleteMailTemplateSenderStart: (state) => {
      state.deleteLoading = true;
    },
    deleteMailTemplateSenderSuccess: (state) => {
      state.deleteLoading = false;
      state.deleteFinished = true;
    },
    deleteMailTemplateSenderError: (
      state,
      _action: PayloadAction<ErrorWrapper>
    ) => {
      state.deleteLoading = false;
    },
    clearItems: (state) => {
      state.items = { items: [], hasRight: false, hasLeft: false };
    },
    clearAllItems: (state) => {
      state.allItems = [];
    },
    setSelectedMailTemplateSenders: (
      state,
      action: PayloadAction<Array<MailTemplateSender>>
    ) => {
      state.selectedMailTemplateSenders = action.payload;
    },
    clearSelectedMailTemplateSenders: (state) => {
      state.selectedMailTemplateSenders = [];
    },
  },
});

export const {
  listMailTemplateSenderStart,
  listMailTemplateSenderSuccess,
  listMailTemplateSenderError,
  listAllMailTemplateSenderStart,
  listAllMailTemplateSenderSuccess,
  listAllMailTemplateSenderError,
  addMailTemplateSenderStart,
  addMailTemplateSenderSuccess,
  addMailTemplateSenderError,
  editMailTemplateSenderStart,
  editMailTemplateSenderSuccess,
  editMailTemplateSenderError,
  getMailTemplateSenderStart,
  getMailTemplateSenderSuccess,
  getMailTemplateSenderError,
  deleteMailTemplateSenderStart,
  deleteMailTemplateSenderSuccess,
  deleteMailTemplateSenderError,
  clearItems,
  clearAllItems,
  setSelectedMailTemplateSenders,
  clearSelectedMailTemplateSenders,
} = slice.actions;

export default slice.reducer;
