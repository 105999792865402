import React, { ReactElement } from "react";
import { RouteComponentProps } from "react-router-dom";
import { connect } from "react-redux";
import { Page, setPage } from "features/appstate/AppStateSlice";
import { listAddresses } from "features/address/AddressActions";
import {
  Paginated,
  ListAddressParams,
  MEOSite,
  NameAndPhoneNumber,
  FullAddress,
} from "api/sms-api";
import { Alert, Table, Button, Row } from "antd";
import { RootState } from "store/store";
import qs from "qs";
import { useHistory, useLocation } from "react-router-dom";
import i18n from "i18n";
import {
  PlusOutlined,
  RollbackOutlined,
  CheckOutlined,
  ImportOutlined,
} from "@ant-design/icons";
import SimplePagination, {
  PaginatedComponentProps,
} from "components/SimplePagination";
import SMSLink from "components/SMSLink";
import _ from "lodash";
import { setSavedForm as setSavedGroupForm } from "features/group/GroupSlice";
import { setSavedForm as setSavedPublishForm } from "features/publish/PublishSlice";
import { setSavedForm as setSavedScheduleForm } from "features/schedule/ScheduleSlice";
import {
  setSelected,
  SelectedAddress,
  clearItems,
  clearAddress,
} from "./AddressSlice";
import { ModifiedGroupForm } from "features/group/GroupAdd";
import { pushTo } from "utils/navigation";
import moment from "moment";
import "moment/locale/ja";
import { ModifiedPublishForm } from "components/SendForm";
import { ModifiedScheduledEditForm } from "features/schedule/ScheduleEdit";
import { mobileMediaQuery } from "../../constants";
import Media from "react-media";
import AddressFilters from "./AddressFilters";

interface AddressesProps {
  addresses: Paginated<FullAddress>;
  loading: boolean;
  multipleDeleteFinished: boolean;

  meoSite: MEOSite;
  setPage: (page: Page) => void;
  listAddresses: (params: ListAddressParams) => void;

  selected: Array<SelectedAddress>;
  setSelected: (selected: Array<SelectedAddress>) => void;

  savedGroupForm?: ModifiedGroupForm;
  setSavedGroupForm: (form: ModifiedGroupForm) => void;

  savedPublishForm?: ModifiedPublishForm;
  setSavedPublishForm: (form: ModifiedPublishForm) => void;

  savedScheduleForm?: ModifiedScheduledEditForm;
  setSavedScheduleForm: (form: ModifiedScheduledEditForm) => void;

  clearItems: () => void;
  clearAddress: () => void;
}

const Addresses: React.FC<
  AddressesProps & RouteComponentProps & PaginatedComponentProps
> = (props) => {
  const history = useHistory();
  const location = useLocation();
  const parsed = qs.parse(location.search, { ignoreQueryPrefix: true });

  const [selecting, setSelecting] = React.useState<string | null>(null);
  const [selectAlert, setSelectAlert] = React.useState(false);
  const [scheduledId, setScheduledId] = React.useState<string | null>(null);

  const detailsMode = parsed["details"] as string;
  const urlViewStart = parsed["url_view_start"] as string;
  const urlViewEnd = parsed["url_view_end"] as string;
  const landingPageViewStart = parsed["landing_page_view_start"] as string;
  const landingPageViewEnd = parsed["landing_page_view_end"] as string;
  const reviewLinkClickStart = parsed["review_link_click_start"] as string;
  const reviewLinkClickEnd = parsed["review_link_click_end"] as string;
  const reviewLinkName = parsed["review_link_name"] as string;

  React.useEffect(() => {
    props.setPage(Page.addresses);
  }, [props.setPage]);

  React.useEffect(() => {
    if (props.multipleDeleteFinished) {
      props.setSelected([]);
      props.clearAddress();
      // Remove other parameters that might show up in the query string
      const params = {
        ..._.omit(parsed, ["before", "after", "selecting", "scheduledId"]),
        meoSiteId: props.meoSite.id,
      };
      props.listAddresses({ ...params, limit: 100 });
    }
  }, [
    props.multipleDeleteFinished,
    props.clearAddress,
    props.setSelected,
    props.listAddresses,
  ]);

  React.useEffect(() => {
    if (props.savedGroupForm && selecting === "group") {
      const selectedAddresses: Array<SelectedAddress> = props.savedGroupForm.addressIds.map(
        (id) => {
          return {
            id,
          };
        }
      );
      props.setSelected(selectedAddresses);
    }
  }, [props.setSelected, selecting]);

  React.useEffect(() => {
    if (props.savedPublishForm && selecting === "send_form") {
      const selectedAddresses: Array<SelectedAddress> = props.savedPublishForm.contacts
        .filter((c) => !!c.phoneNumber)
        .map((c) => {
          return {
            name: c.name,
            phoneNumber: c.phoneNumber,
          };
        });
      props.setSelected(selectedAddresses);
    }
    if (props.savedScheduleForm && selecting === "scheduled") {
      const selectedAddresses: Array<SelectedAddress> = props.savedScheduleForm.contacts
        .filter((c) => !!c.phoneNumber)
        .map((c) => {
          return {
            name: c.name,
            phoneNumber: c.phoneNumber,
          };
        });
      props.setSelected(selectedAddresses);
    }
  }, [props.setSelected, selecting]);

  React.useEffect(() => {
    if (_.has(parsed, "selecting")) {
      setSelecting(parsed["selecting"] as string);
    }
    if (_.has(parsed, "scheduledId")) {
      setScheduledId(parsed["scheduledId"] as string);
    }

    // Remove other parameters that might show up in the query string
    const params = {
      ..._.omit(parsed, ["selecting", "scheduledId"]),
      meoSiteId: props.meoSite.id,
    };

    props.listAddresses({ ...params, limit: 100 });
  }, [props.listAddresses, location.search, props.meoSite]);

  React.useEffect(() => {
    return () => {
      props.clearItems();
    };
  }, [props.clearItems]);

  const finishSelection = (save: boolean) => {
    if (selecting === "group") {
      if (save && props.savedGroupForm) {
        const form = Object.assign({}, props.savedGroupForm);

        // For groups, we select by ID, so assume everything has an ID
        const selectedIds: Array<string> = props.selected.map((s) => s.id!);
        form.addressIds = selectedIds;
        props.setSavedGroupForm(form);
      }
      if (parsed.backUrl) {
        pushTo(history, parsed.backUrl as string, {
          shortcutSelected: "back",
        });
      }
    } else if (selecting === "send_form" && props.savedPublishForm) {
      if (save) {
        // For PublishForm, we select by phone number, so assume everything has a phone number (and name)
        const selectedContacts: Array<NameAndPhoneNumber> = props.selected.map(
          (s) => {
            return {
              name: s.name!,
              phoneNumber: s.phoneNumber!,
            };
          }
        );

        const form = {
          ...props.savedPublishForm,
          contacts: selectedContacts,
        };
        props.setSavedPublishForm(form);
      }
      pushTo(history, "/publish", {
        shortcutSelected: "back",
      });
    } else if (selecting === "scheduled" && props.savedScheduleForm) {
      if (save) {
        // For PublishForm, we select by phone number, so assume everything has a phone number (and name)
        const selectedContacts: Array<NameAndPhoneNumber> = props.selected.map(
          (s) => {
            return {
              name: s.name!,
              phoneNumber: s.phoneNumber!,
            };
          }
        );

        const form = {
          ...props.savedScheduleForm,
          contacts: selectedContacts,
        };
        props.setSavedScheduleForm(form);
      }
      pushTo(history, `/scheduled/${scheduledId}`, {
        shortcutSelected: "back",
      });
    }
  };

  const columns = (smallScreen: boolean) => {
    const base = [
      {
        title: i18n.t("common.name"),
        dataIndex: "name",
        key: "name",
        render: (_name: string, full: FullAddress) => {
          if (!selecting) {
            return (
              <SMSLink to={`/addresses/${full.address.id}`}>
                {" "}
                {full.address.name}
              </SMSLink>
            );
          } else {
            return <span>{full.address.name}</span>;
          }
        },
      },
      {
        title: i18n.t("common.phoneNumber"),
        dataIndex: "phoneNumber",
        key: "phoneNumber",
        render: (_phoneNumber: string, full: FullAddress) => (
          <span>{full.address.phoneNumber}</span>
        ),
      },
      {
        title: i18n.t("addresses.restrictions"),
        key: "restrictions",
        render: (_: any, full: FullAddress) => (
          <div>
            {full.optedOut && <div>{i18n.t("addresses.optedOut")}</div>}
            {full.blacklisted && <div>{i18n.t("addresses.blacklisted")}</div>}
          </div>
        ),
      },
    ];

    if (!smallScreen) {
      base.push({
        title: i18n.t("addresses.createdAt"),
        dataIndex: "createdAt",
        key: "createdAt",
        render: (_createdAt: string, full: FullAddress) => (
          <span>{moment(full.address.createdAt).format("LLLL")}</span>
        ),
      });
    }

    return base;
  };

  const addButton = (
    <SMSLink to="/addresses/add">
      <Button icon={<PlusOutlined />} type="primary">
        {i18n.t("addresses.add")}
      </Button>
    </SMSLink>
  );

  const importButton = (
    <SMSLink to="/addresses/import">
      <Button icon={<ImportOutlined />}>{i18n.t("addresses.import")}</Button>
    </SMSLink>
  );

  const pagination = (
    <SimplePagination
      items={props.addresses.items}
      getCursor={(model: FullAddress) => model.address.createdAt}
      sortDescending={true}
      hasRight={props.addresses.hasRight}
      hasLeft={props.addresses.hasLeft}
      location={props.location}
      history={history}
      match={props.match}
    />
  );

  let selectingExplanation: ReactElement | null = null;
  if (selecting) {
    const text =
      selecting === "send_form" || selecting === "scheduled"
        ? i18n.t("addresses.selectingExplanation.sendForm")
        : i18n.t("addresses.selectingExplanation.group");
    selectingExplanation = (
      <Alert
        message={
          <div>
            <div>
              <span>{text}</span>
            </div>
            <div style={{ marginTop: "1em" }}>
              <Button
                type="primary"
                icon={<CheckOutlined />}
                onClick={() => finishSelection(true)}
                style={{ marginRight: "1em" }}
              >
                {i18n.t("common.select")}
              </Button>
              <Button
                icon={<RollbackOutlined />}
                onClick={() => finishSelection(false)}
              >
                {i18n.t("common.back")}
              </Button>
            </div>
          </div>
        }
        type="info"
        style={{ marginBottom: "2em" }}
      />
    );
  }

  let clickRangeExplanation: ReactElement | null = null;
  let text: string | null = null;
  if (urlViewStart && urlViewEnd) {
    text =
      detailsMode === "daily"
        ? i18n.t("addresses.urlViewExplanationDaily", {
            day: moment(urlViewStart).format("LL"),
          })
        : i18n.t("addresses.urlViewExplanationMonthly", {
            startDay: moment(urlViewStart).format("LL"),
            endDay: moment(urlViewEnd).subtract(1, "days").format("LL"),
          });
  } else if (landingPageViewStart && landingPageViewEnd) {
    text =
      detailsMode === "daily"
        ? i18n.t("addresses.landingPageViewExplanationDaily", {
            day: moment(landingPageViewStart).format("LL"),
          })
        : i18n.t("addresses.landingPageViewExplanationMonthly", {
            startDay: moment(landingPageViewStart).format("LL"),
            endDay: moment(landingPageViewEnd).subtract(1, "days").format("LL"),
          });
  } else if (reviewLinkClickStart && reviewLinkClickEnd && reviewLinkName) {
    text =
      detailsMode === "daily"
        ? i18n.t("addresses.reviewLinkClickExplanationDaily", {
            day: moment(reviewLinkClickStart).format("LL"),
            name: reviewLinkName,
          })
        : i18n.t("addresses.reviewLinkClickExplanationMonthly", {
            startDay: moment(reviewLinkClickStart).format("LL"),
            endDay: moment(reviewLinkClickEnd).subtract(1, "days").format("LL"),
            name: reviewLinkName,
          });
  } else if (reviewLinkClickStart && reviewLinkClickEnd) {
    text =
      detailsMode === "daily"
        ? i18n.t("addresses.reviewLinkClickAllExplanationDaily", {
            day: moment(reviewLinkClickStart).format("LL"),
          })
        : i18n.t("addresses.reviewLinkClickAllExplanationMonthly", {
            startDay: moment(reviewLinkClickStart).format("LL"),
            endDay: moment(reviewLinkClickEnd).subtract(1, "days").format("LL"),
          });
  }

  if (text) {
    clickRangeExplanation = (
      <Alert message={text} type="info" style={{ marginBottom: "2em" }} />
    );
  }

  const selectMaxAlert = selectAlert && (
    <Alert
      message={
        <div>
          <span>
            {selecting === "group"
              ? i18n.t("addresses.maxSelectGroups")
              : i18n.t("addresses.maxSelectAddress")}
          </span>
        </div>
      }
      type="error"
      style={{ marginBottom: "2em" }}
    />
  );

  const handleSelect = (
    record: FullAddress,
    isSelected: boolean,
    selectedRows: Array<any>
  ) => {
    if (selectedRows.length > 100) {
      setSelectAlert(true);
      return;
    }

    if (isSelected) {
      // Adding
      const newSelected = [
        ...props.selected,
        {
          id: record.address.id,
          name: record.address.name,
          phoneNumber: record.address.phoneNumber,
        },
      ];
      props.setSelected(newSelected);
    } else {
      // Removing
      let current = [...props.selected];
      if (selecting === "send_form" || selecting === "scheduled") {
        // Remove by phone number
        current = current.filter(
          (c) => c.phoneNumber !== record.address.phoneNumber
        );
      } else {
        // Remove by ID
        current = current.filter((c) => c.id !== record.address.id);
      }

      props.setSelected(current);
    }

    setSelectAlert(false);
  };

  const handleSelectAll = (
    isSelected: boolean,
    _selectedRows: Array<FullAddress>, // Don't use; this will contain `undefined` values when you move between pages
    changeRows: Array<FullAddress>
  ) => {
    if (_selectedRows.length > 100) {
      setSelectAlert(true);
      return;
    }
    if (isSelected) {
      // Add all
      const newSelections = changeRows.map((a) => {
        return {
          id: a.address.id,
          name: a.address.name,
          phoneNumber: a.address.phoneNumber,
        };
      });

      // Add the newly selected rows, deduplicating by key
      const newResult = _.uniqBy(
        [...props.selected, ...newSelections],
        (a: SelectedAddress) => {
          return selecting === "send_form" || selecting === "scheduled"
            ? a.phoneNumber!
            : a.id!;
        }
      );
      props.setSelected(newResult);
    } else {
      // Remove all
      if (selecting === "send_form" || selecting === "scheduled") {
        const deselectedNumbers = changeRows.map((a) => a.address.phoneNumber);
        const newResult = props.selected.filter(
          (c) => !deselectedNumbers.includes(c.phoneNumber!)
        );
        props.setSelected(newResult);
      } else {
        const deselectedIds = changeRows.map((a) => a.address.id);
        const newResult = props.selected.filter(
          (c) => !deselectedIds.includes(c.id!)
        );
        props.setSelected(newResult);
      }
    }
  };

  const selectedRowKeys = props.selected.map((s: SelectedAddress) => {
    return selecting === "send_form" || selecting === "scheduled"
      ? s.phoneNumber!
      : s.id!;
  });

  const rowSelection = {
    selectedRowKeys,
    onSelect: handleSelect,
    onSelectAll: handleSelectAll,
    preserveSelectedRowKeys: true,
  };

  return (
    <Media query={mobileMediaQuery}>
      {(smallScreen) => {
        return (
          <div>
            {selectingExplanation}
            {selectMaxAlert}
            {clickRangeExplanation}
            {!detailsMode && (
              <AddressFilters
                selecting={selecting}
                selected={props.selected}
                meoSite={props.meoSite}
              />
            )}
            <Table
              rowSelection={rowSelection}
              dataSource={props.addresses.items}
              columns={columns(smallScreen)}
              locale={{ emptyText: i18n.t("addresses.empty") }}
              rowKey={(a: FullAddress) => {
                // Use phoneNumber as the key if selecting for the PublishForm, ID otherwise. This is so we can use the `rowSelection` feature correctly.
                return selecting === "send_form" || selecting === "scheduled"
                  ? a.address.phoneNumber
                  : a.address.id;
              }}
              size="middle"
              pagination={false}
              loading={props.loading}
            />
            <Row className="pagination-row">{pagination}</Row>
            <Row className="form-button-row">
              {!selecting && addButton}
              {!selecting && importButton}
            </Row>
          </div>
        );
      }}
    </Media>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    addresses: state.addresses.items,
    loading: state.addresses.listLoading,
    meoSite: state.seo.meoSite!,
    savedGroupForm: state.group.savedForm,
    savedPublishForm: state.publish.savedForm,
    savedScheduleForm: state.scheduleds.savedForm,
    selected: state.addresses.selected,
    multipleDeleteFinished: state.addresses.multipleDeleteFinished,
  };
};
const mapDispatchToProps = {
  setPage,
  listAddresses,
  setSavedGroupForm,
  setSavedPublishForm,
  setSavedScheduleForm,
  setSelected,
  clearItems,
  clearAddress,
};

export default connect(mapStateToProps, mapDispatchToProps)(Addresses);
