import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GMBStatisticsResponse, StatisticsResponse } from "api/sms-api";

interface StatisticsState {
  response?: StatisticsResponse;
  gmbResponse?: GMBStatisticsResponse;
  loading: boolean;
  gmbLoading: boolean;
}

const initialState: StatisticsState = {
  loading: false,
  gmbLoading: false,
};

export const slice = createSlice({
  name: "statistics",
  initialState,
  reducers: {
    getStatisticsStart: (state) => {
      state.loading = true;
    },
    getStatisticsSuccess: (
      state,
      action: PayloadAction<StatisticsResponse>
    ) => {
      state.loading = false;
      state.response = action.payload;
    },
    getStatisticsError: (state) => {
      state.loading = false;
    },
    getGMBStatisticsStart: (state) => {
      state.gmbLoading = true;
    },
    getGMBStatisticsSuccess: (
      state,
      action: PayloadAction<GMBStatisticsResponse>
    ) => {
      state.gmbLoading = false;
      state.gmbResponse = action.payload;
    },
    getGMBStatisticsError: (state) => {
      state.gmbLoading = false;
    },
    clearStatistics: (state) => {
      state.response = undefined;
      state.gmbResponse = undefined;
    },
  },
});

export const {
  getStatisticsStart,
  getStatisticsSuccess,
  getStatisticsError,
  getGMBStatisticsStart,
  getGMBStatisticsSuccess,
  getGMBStatisticsError,
  clearStatistics,
} = slice.actions;

export default slice.reducer;
