import { EcLink } from "api/sms-api";
import {
  getEcLinksStart,
  getEcLinksSuccess,
  getEcLinksError,
} from "./EcLinkStatusSlice";
import { AppDispatch } from "store/store";
import { httpWrapper } from "utils/http";
import { SMS_API_BASE } from "../../constants";
import { handleError } from "errors/errors";

export const getEcLinks = () => async (dispatch: AppDispatch) => {
  dispatch(getEcLinksStart());
  try {
    const ecLinks = await httpWrapper<Array<EcLink>>(
      "GET",
      `${SMS_API_BASE}/client/ec_links`,
      {}
    );
    dispatch(getEcLinksSuccess(ecLinks));
  } catch (e) {
    handleError(e);
    dispatch(getEcLinksError());
  }
};
