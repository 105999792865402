import React from "react";
import { Button } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import i18n from "i18n";
import { NativeButtonProps } from "antd/lib/button/button";

const DeleteButton: React.FC<NativeButtonProps> = (props) => {
  return (
    <Button danger={true} icon={<DeleteOutlined />} {...props}>
      {i18n.t("common.delete")}
    </Button>
  );
};

export default DeleteButton;
