import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Page, setPage } from "features/appstate/AppStateSlice";
import {
  editOptoutPageTemplate,
  getOptoutPageTemplate,
} from "./OptoutPageTemplateActions";
import { getClientEcLogoDetails } from "features/contract_details/ContractDetailsActions";
import {
  Client,
  ClientEditForm,
  PublishType,
  ClientEcLogoDetails,
} from "api/sms-api";
import { Row, Col, Spin } from "antd";
import { Form } from "formik-antd";
import { RootState } from "store/store";
import i18n from "i18n";
import { Formik } from "formik";
import RichEditor from "components/RichEditor";
import SaveButton from "components/SaveButton";
import { optoutPageTemplateTagItems } from "../../constants";
import OptoutPageTemplatePreviewDialog from "./OptoutPageTemplatePreviewDialog";
import { replaceStoreLogoInserts } from "utils/strings";

interface OptoutPageTemplateProps {
  item?: Client;
  loading: boolean;
  setPage: (page: Page) => void;
  editOptoutPageTemplate: (params: ClientEditForm) => void;
  getOptoutPageTemplate: () => void;
  clientEcLogoDetails?: ClientEcLogoDetails;
  getClientEcLogoDetails: () => void;
  getClientEcLogoDetailsLoading: boolean;
}

const OptoutPageTemplate: React.FC<OptoutPageTemplateProps> = (props) => {
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);

  useEffect(() => {
    props.setPage(Page.optoutPageTemplate);
  }, [props.setPage]);

  useEffect(() => {
    if (!props.loading && !props.item) {
      props.getOptoutPageTemplate();
    }
  }, [props.getOptoutPageTemplate, props.loading]);

  useEffect(() => {
    if (!props.getClientEcLogoDetailsLoading) {
      props.getClientEcLogoDetails();
    }
  }, [props.getClientEcLogoDetails]);

  if (props.loading || !props.item) {
    return <Spin />;
  }

  const handleSubmit = async (form: ClientEditForm) => {
    await props.editOptoutPageTemplate(form);
    await props.getOptoutPageTemplate();
  };

  const closePreviewModal = () => {
    setIsPreviewModalOpen(false);
  };

  const defaultContent = Object.keys(optoutPageTemplateTagItems)
    .map((key: string) => {
      return `<p>${optoutPageTemplateTagItems[key]}</p>`;
    })
    .join("");

  const optOutPageTemplateData =
    props.item?.optoutPageTemplate || defaultContent;

  const initialValues: ClientEditForm = {
    sendUserId: props.item?.sendUserId || "",
    defaultPublishType: props.item?.defaultPublishType || PublishType.Direct,
    optoutPageTemplate: optOutPageTemplateData,
  };

  const previewButton: any = {
    name: i18n.t("mailTemplate.optoutPageTemplate.preview"),
    icon: "eye",
    exec: function () {
      setIsPreviewModalOpen(true);
    },
  };

  const processContent = (content: string): string => {
    return replaceStoreLogoInserts(
      content,
      optoutPageTemplateTagItems[i18n.t("mailTemplate.tags.storeLogo")],
      props.clientEcLogoDetails?.storeLogoUrl || ""
    );
  };

  return (
    <div>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {(formikBag) => {
          return (
            <Form layout="vertical">
              <Row justify="center">
                <Col span={18}>
                  <Form.Item
                    name="optoutPageTemplate"
                    label={i18n.t("mailTemplate.optoutPageTemplate.title")}
                  >
                    <RichEditor
                      hideButtonList={["preview"]}
                      customButtons={[previewButton]}
                      tagList={optoutPageTemplateTagItems}
                      value={
                        formikBag.values.optoutPageTemplate || defaultContent
                      }
                      onChange={(value) => {
                        formikBag.setFieldValue("optoutPageTemplate", value);
                      }}
                    />
                  </Form.Item>
                  <OptoutPageTemplatePreviewDialog
                    content={processContent(
                      formikBag.values.optoutPageTemplate || defaultContent
                    )}
                    visible={isPreviewModalOpen}
                    closeModal={closePreviewModal}
                  />
                </Col>
              </Row>
              <Row justify="end" style={{ marginTop: "2.5em" }}>
                <SaveButton />
              </Row>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    item: state.optoutPageTemplate.item,
    loading: state.optoutPageTemplate.getLoading,
    getClientEcLogoDetailsLoading:
      state.contractDetails.getClientEcLogoDetailsLoading,
    clientEcLogoDetails: state.contractDetails.clientEcLogoDetails,
  };
};

const mapDispatchToProps = {
  setPage,
  editOptoutPageTemplate,
  getOptoutPageTemplate,
  getClientEcLogoDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(OptoutPageTemplate);
