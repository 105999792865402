import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { connect } from "react-redux";
import { Formik } from "formik";
import { Form, Radio } from "formik-antd";
import { Page, setPage } from "features/appstate/AppStateSlice";
import {
  listMailExclusionSettings,
  editMailExclusionSettings,
  getMailExclusionSetting,
} from "./MailExclusionSettingsActions";
import {
  MailExclusionSettingsEditForm,
  ClientEcOptoutDetails,
} from "api/sms-api";
import { Row, Col, List, Button, Spin } from "antd";
import { RootState } from "store/store";
import i18n from "i18n";
import { ecAllowAll, ecSameProductExclude } from "../../constants";
import SaveButton from "components/SaveButton";
import MailExclusionSettingsAdd from "./MailExclusionSettingsAdd";

interface MailExclusionSettingsProps {
  items: Array<ClientEcOptoutDetails>;
  loading: boolean;
  listMailExclusionSettings: () => void;
  editMailExclusionSettings: (params: MailExclusionSettingsEditForm) => void;
  mailExclusionSetting?: string;
  getMailExclusionSettingLoading: boolean;
  getMailExclusionSetting: () => void;
  setPage: (page: Page) => void;
}

const MailExclusionSettings: React.FC<
  MailExclusionSettingsProps & RouteComponentProps
> = (props) => {
  const [
    isMailExclusionSettingsAddModalOpen,
    setIsMailExclusionSettingsAddModalOpen,
  ] = useState(false);
  useEffect(() => {
    props.setPage(Page.mailExclusionSettings);
  }, [props.setPage]);

  useEffect(() => {
    props.listMailExclusionSettings();
  }, [props.listMailExclusionSettings]);

  useEffect(() => {
    props.getMailExclusionSetting();
  }, [props.getMailExclusionSetting]);

  if (props.loading || props.getMailExclusionSettingLoading) {
    return <Spin />;
  }

  const handleSubmit = async (form: MailExclusionSettingsEditForm) => {
    await props.editMailExclusionSettings(form);
    await props.listMailExclusionSettings();
    await props.getMailExclusionSetting();
  };

  const closeMailExclusionSettingsAddModal = () => {
    setIsMailExclusionSettingsAddModalOpen(false);
  };

  const onClickMailExclusionSettingsAddButton = () => {
    setIsMailExclusionSettingsAddModalOpen(true);
  };

  const initialValues: MailExclusionSettingsEditForm = {
    mailExclusionSetting: props.mailExclusionSetting || ecAllowAll,
  };

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {() => {
          return (
            <Form layout="vertical">
              <Row justify="start">
                <Col span={18}>
                  <Form.Item
                    name="mailExclusionSetting"
                    label={i18n.t("mailExclusionSettings.setting")}
                  >
                    <Radio.Group
                      name="mailExclusionSetting"
                      className="form-radio-group"
                    >
                      <Radio.Button value={ecAllowAll}>
                        {i18n.t("mailExclusionSettings.allowAll")}
                      </Radio.Button>
                      <Radio.Button value={ecSameProductExclude}>
                        {i18n.t("mailExclusionSettings.sameProductExclude")}
                      </Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
              <Row justify="start">
                <Col span={18}>
                  <span>{i18n.t("mailExclusionSettings.emailList")}</span>
                </Col>
              </Row>
              <Row justify="start">
                <Col span={18}>
                  <Button
                    className="mail-exclusion-padding"
                    type="primary"
                    onClick={onClickMailExclusionSettingsAddButton}
                  >
                    {i18n.t("mailExclusionSettings.add")}
                  </Button>
                </Col>
              </Row>
              <Row justify="start">
                <Col span={18}>
                  <List
                    size="small"
                    bordered
                    dataSource={props.items}
                    renderItem={(item) => (
                      <List.Item>{item.emailAddress}</List.Item>
                    )}
                  />
                </Col>
              </Row>
              <Row justify="end" style={{ marginTop: "2.5em" }}>
                <SaveButton />
              </Row>
            </Form>
          );
        }}
      </Formik>
      <MailExclusionSettingsAdd
        visible={isMailExclusionSettingsAddModalOpen}
        closeModal={closeMailExclusionSettingsAddModal}
      />
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    items: state.mailExclusionSettings.items,
    loading: state.mailExclusionSettings.listLoading,
    mailExclusionSetting: state.mailExclusionSettings.mailExclusionSetting,
    getMailExclusionSettingLoading:
      state.mailExclusionSettings.getMailExclusionSettingLoading,
  };
};

const mapDispatchToProps = {
  setPage,
  listMailExclusionSettings,
  editMailExclusionSettings,
  getMailExclusionSetting,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MailExclusionSettings);
