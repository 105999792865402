import {
  LandingPageSettings,
  LandingPageSettingsEditForm,
  LogoUploadResult,
  LandingData,
  LandingDataPreviewForm,
  LandingPageSettingsAddForm,
  Paginated,
} from "api/sms-api";
import { AppThunk, AppDispatch, AsyncAppThunk } from "store/store";
import { httpWrapper } from "utils/http";
import {
  addSettingsStart,
  addSettingsSuccess,
  addSettingsError,
  listSettingsStart,
  listSettingsSuccess,
  listSettingsError,
  deleteSettingsStart,
  deleteSettingsSuccess,
  deleteSettingsError,
  editSettingsStart,
  editSettingsSuccess,
  editSettingsError,
  getSettingsStart,
  getSettingsSuccess,
  getSettingsError,
  fileUploadStart,
  fileUploadSuccess,
  fileUploadError,
  getPreviewDataStart,
  getPreviewDataSuccess,
  getPreviewDataError,
} from "./LandingPageSettingsSlice";
import { SMS_API_BASE } from "../../constants";
import i18n from "i18n";
import { showNotification } from "utils/notifications";
import { handleError } from "errors/errors";

export const editLandingPageSettings = (
  id: string,
  form: LandingPageSettingsEditForm
): AsyncAppThunk<void> => async (dispatch: AppDispatch) => {
  dispatch(editSettingsStart());
  try {
    await httpWrapper(
      "PATCH",
      `${SMS_API_BASE}/landing_page_settings/${id}`,
      form
    );

    showNotification(i18n.t("landingPageSettings.editSuccess"));
    dispatch(editSettingsSuccess());
  } catch (e) {
    handleError(e);
    dispatch(editSettingsError(e));
  }
};

export const getLandingPageSettings = (id: string): AppThunk => async (
  dispatch: AppDispatch
) => {
  dispatch(getSettingsStart());
  try {
    const landingPageSettings = await httpWrapper<LandingPageSettings>(
      "GET",
      `${SMS_API_BASE}/landing_page_settings/${id}`,
      {}
    );
    dispatch(getSettingsSuccess(landingPageSettings));
  } catch (e) {
    handleError(e);
    dispatch(getSettingsError());
  }
};

export const listLandingPageSettings = (meoSiteId: number): AppThunk => async (
  dispatch: AppDispatch
) => {
  dispatch(listSettingsStart());
  try {
    const landingPageSettings = await httpWrapper<
      Paginated<LandingPageSettings>
    >(
      "GET",
      `${SMS_API_BASE}/landing_page_settings?meo_site_id=${meoSiteId}`,
      {}
    );
    dispatch(listSettingsSuccess(landingPageSettings));
  } catch (e) {
    handleError(e);
    dispatch(listSettingsError());
  }
};

export const uploadLogo = (
  file: File
): AsyncAppThunk<LogoUploadResult | undefined> => async (
  dispatch: AppDispatch
) => {
  dispatch(fileUploadStart());
  try {
    const formData = new FormData();
    formData.append("file", file);
    const result = await httpWrapper<LogoUploadResult>(
      "POST",
      `${SMS_API_BASE}/landing_page_settings/logo`,
      formData,
      { "Content-Type": "multipart/form-data" }
    );
    dispatch(fileUploadSuccess());
    return result;
  } catch (e) {
    handleError(e);
    dispatch(fileUploadError());
  }
};

export const getPreviewData = (
  form: LandingDataPreviewForm
): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(getPreviewDataStart());
  try {
    const data = await httpWrapper<LandingData>(
      "POST",
      `${SMS_API_BASE}/landing_data/preview`,
      form
    );

    dispatch(getPreviewDataSuccess(data));
  } catch (e) {
    handleError(e);
    dispatch(getPreviewDataError());
  }
};

export const addLandingPageSettings = (
  params: LandingPageSettingsAddForm
): AsyncAppThunk<void> => async (dispatch: AppDispatch) => {
  dispatch(addSettingsStart());
  try {
    await httpWrapper("POST", `${SMS_API_BASE}/landing_page_settings`, params);
    showNotification(i18n.t("landingPageSettings.createSuccess"));
    dispatch(addSettingsSuccess());
  } catch (e) {
    handleError(e);
    dispatch(addSettingsError(e));
  }
};

export const deleteLandingPageSettings = (
  id: string
): AsyncAppThunk<void> => async (dispatch: AppDispatch) => {
  dispatch(deleteSettingsStart());
  try {
    await httpWrapper(
      "DELETE",
      `${SMS_API_BASE}/landing_page_settings/${id}`,
      {}
    );
    showNotification(i18n.t("landingPageSettings.deleteSuccess"));
    dispatch(deleteSettingsSuccess());
  } catch (e) {
    handleError(e);
    dispatch(deleteSettingsError());
  }
};
