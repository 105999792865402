import { IdAndName, NameAndPhoneNumber } from "api/sms-api";

export function transformPhoneNumber(phoneNumber: string) {
  if (phoneNumber.startsWith("+")) {
    if (phoneNumber.startsWith("+810")) {
      phoneNumber = "+81" + phoneNumber.substr(4);
    }
  } else {
    if (phoneNumber.startsWith("0")) {
      phoneNumber = "+81" + phoneNumber.substr(1);
    } else {
      phoneNumber = "+81" + phoneNumber;
    }
  }
  return phoneNumber;
}

export function transformPhoneNumberList(contacts: Array<NameAndPhoneNumber>) {
  return contacts.map((c) => {
    return {
      ...c,
      phoneNumber: transformPhoneNumber(c.phoneNumber),
    };
  });
}

export function duplicatePhoneNumberCheck(contacts: Array<NameAndPhoneNumber>) {
  let result = false;
  const phoneNumbers = contacts.map((c) => c.phoneNumber);
  const duplicate = phoneNumbers.filter(function (x, i, self) {
    return self.indexOf(x) === i && i !== self.lastIndexOf(x);
  });
  if (duplicate.length === 0) {
    result = true;
  }
  return result;
}

export function transformContactList(contacts: Array<NameAndPhoneNumber>) {
  return transformPhoneNumberList(contacts.filter((c) => c.phoneNumber !== ""));
}

export function transformGroupList(groups: Array<IdAndName>) {
  if (groups.length === 1 && groups[0].id === "") {
    return [];
  }
  return groups;
}
