import React, { useRef } from "react";
import i18n from "i18n";
import QRCode from "qrcode.react";
import { Button } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { saveAs } from "file-saver";
import html2canvas from "html2canvas";

interface SurveyQRProps {
  url: string;
  fileName?: string;
  title?: string;
}

const SurveyQR: React.FC<SurveyQRProps> = (props) => {
  const captureRef: any = useRef();

  const handleDownload = async () => {
    const canvas = await html2canvas(captureRef.current);
    const fileName = props.fileName ? props.fileName : "qr";
    canvas.toBlob(function (blob: any) {
      saveAs(blob, `${fileName}.png`);
    });
  };

  return (
    <div className="lps-qr-code" style={{ textAlign: "center" }}>
      <div ref={captureRef}>
        {props.title ? (
          <p style={{ paddingTop: "20px", marginBottom: "0" }}>{props.title}</p>
        ) : null}
        <QRCode value={props.url} size={256} level="M" includeMargin={true} />
      </div>
      <Button
        onClick={() => handleDownload()}
        icon={<DownloadOutlined />}
        style={{ marginTop: "1em" }}
      >
        {i18n.t("common.download")}
      </Button>
    </div>
  );
};

export default SurveyQR;
