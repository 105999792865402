import { Paginated } from "api/sms-api";
import { ErrorWrapper } from "errors/errors";

interface BaseCRUDState<A> {
  item?: A;
  listLoading: boolean;
  createLoading: boolean;
  getLoading: boolean;
  editLoading: boolean;
  deleteLoading: boolean;
  deleteFinished: boolean;
  addFinished: boolean;
  editFinished: boolean;
  error?: ErrorWrapper;
}

export interface CRUDState<A> extends BaseCRUDState<A> {
  items: Paginated<A>;
}

export interface NonPaginatedCRUDState<A> extends BaseCRUDState<A> {
  items: Array<A>;
}

const initialBaseCRUDState = {
  listLoading: false,
  createLoading: false,
  getLoading: false,
  editLoading: false,
  deleteLoading: false,
  deleteFinished: false,
  addFinished: false,
  editFinished: false,
};

export const initialCRUDState: CRUDState<any> = {
  ...initialBaseCRUDState,
  items: { items: [], hasRight: false, hasLeft: false },
};

export const initialNonPaginatedCRUDState: NonPaginatedCRUDState<any> = {
  ...initialBaseCRUDState,
  items: [],
};
