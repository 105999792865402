import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  Surveys,
  Paginated,
  QuestionItem,
  TemplateSurveys,
  SelectedQuestion,
  PickedQuestion,
  ReviewMediaData,
  LanguageModel,
  SurveyEcEmailSettings,
  LanguageSettings,
} from "api/sms-api";
import { CRUDState, initialCRUDState } from "store/CRUDStore";
import { ErrorWrapper } from "errors/errors";

export interface SurveyListState extends CRUDState<Surveys> {
  selectedSurveys: Array<{ id: string }>;
  radioItems: Array<OptionItems>;
  checkboxItems: Array<OptionItems>;
  survey?: Surveys;
  multipleDeleteLoading: boolean;
  multipleDeleteFinished: boolean;
  pickedQuestions: Array<PickedQuestion>;
  editSurveySettingsLoading: boolean;
  editReviewMediaSettingsLoading: boolean;

  reviewMediaSettingLoading: boolean;
  reviewMediaSettings?: ReviewMediaData;

  selectedQuestions: Array<SelectedQuestion>;
  selectedQuestionsLoading: boolean;

  questionBranchItems: Array<QuestionItem>;
  questionBranchItemsLoading: boolean;

  questionGroupItems: Array<QuestionItem>;
  questionGroupItemsLoading: boolean;

  baseQuestionItem: Array<QuestionItem>;
  baseQuestionItemLoading: boolean;

  customQuestionItem: Array<QuestionItem>;
  customQuestionItemLoading: boolean;

  templateSurveys: Array<TemplateSurveys>;
  templateSurveysLoading: boolean;

  templateQuestionsLoading: boolean;

  addQuestionsLoading: boolean;

  conditions: string;
  freeInput: string;

  duplicateLoading: boolean;

  fileUploading: boolean;

  languageModel: Array<LanguageModel>;
  isProofreading: boolean;
  getLanguageModelLoading: boolean;

  languageSettings: Array<LanguageSettings>;
  getLanguageSettingsListLoading: boolean;

  ecEmailSettingsLoading: boolean;
  ecEmailSettings?: SurveyEcEmailSettings;
}

export interface OptionItems {
  name: string;
  value: string;
  selectId?: string;
  notPrompt?: boolean;
}

const initialState: SurveyListState = {
  ...initialCRUDState,
  selectedSurveys: [],
  radioItems: [],
  checkboxItems: [],
  multipleDeleteLoading: false,
  multipleDeleteFinished: false,
  pickedQuestions: [],
  selectedQuestions: [],
  selectedQuestionsLoading: false,
  baseQuestionItem: [],
  baseQuestionItemLoading: false,
  questionBranchItems: [],
  questionBranchItemsLoading: false,
  questionGroupItems: [],
  questionGroupItemsLoading: false,
  customQuestionItem: [],
  customQuestionItemLoading: false,
  templateSurveys: [],
  templateSurveysLoading: false,
  templateQuestionsLoading: false,
  editSurveySettingsLoading: false,
  editReviewMediaSettingsLoading: false,
  reviewMediaSettingLoading: false,
  addQuestionsLoading: false,
  conditions: "",
  freeInput: "",
  duplicateLoading: false,
  fileUploading: false,
  languageModel: [],
  isProofreading: false,
  getLanguageModelLoading: false,
  languageSettings: [],
  getLanguageSettingsListLoading: false,
  ecEmailSettingsLoading: false,
};

export const slice = createSlice({
  name: "survey",
  initialState,
  reducers: {
    getSurveyListStart: (state) => {
      state.listLoading = true;
    },
    getSurveyListSuccess: (
      state,
      action: PayloadAction<Paginated<Surveys>>
    ) => {
      state.items = action.payload;
      state.listLoading = false;
    },
    getSurveyListError: (state) => {
      state.listLoading = false;
    },
    clearItems: (state) => {
      state.items = { items: [], hasRight: false, hasLeft: false };
    },
    getSurveyStart: (state) => {
      state.getLoading = true;
    },
    getSurveySuccess: (state, action: PayloadAction<Surveys>) => {
      state.survey = action.payload;
      state.getLoading = false;
    },
    getSurveyError: (state) => {
      state.getLoading = false;
    },
    clearSurvey: (state) => {
      state.survey = undefined;
    },
    addSurveysStart: (state) => {
      state.createLoading = true;
    },
    addSurveysSuccess: (state, action: PayloadAction<Surveys>) => {
      state.survey = action.payload;
      state.createLoading = false;
    },
    addSurveysError: (state, action: PayloadAction<ErrorWrapper>) => {
      state.createLoading = false;
      state.error = action.payload;
    },
    addQuestionsStart: (state) => {
      state.addQuestionsLoading = true;
    },
    addQuestionsSuccess: (state) => {
      state.addQuestionsLoading = false;
    },
    addQuestionsError: (state, action: PayloadAction<ErrorWrapper>) => {
      state.addQuestionsLoading = false;
      state.error = action.payload;
    },
    duplicateSurveyStart: (state) => {
      state.duplicateLoading = true;
    },
    duplicateSurveySuccess: (state) => {
      state.duplicateLoading = false;
    },
    duplicateSurveyError: (state, action: PayloadAction<ErrorWrapper>) => {
      state.duplicateLoading = false;
      state.error = action.payload;
    },
    editSurveysStart: (state) => {
      state.editLoading = true;
    },
    editSurveysSuccess: (state) => {
      state.editLoading = false;
    },
    editSurveysError: (state, action: PayloadAction<ErrorWrapper>) => {
      state.editLoading = false;
      state.error = action.payload;
    },
    editSurveySettingsStart: (state) => {
      state.editSurveySettingsLoading = true;
    },
    editSurveySettingsSuccess: (state) => {
      state.editSurveySettingsLoading = false;
    },
    editSurveySettingsError: (state, action: PayloadAction<ErrorWrapper>) => {
      state.editSurveySettingsLoading = false;
      state.error = action.payload;
    },
    getReviewMediaSettingsStart: (state) => {
      state.reviewMediaSettingLoading = true;
    },
    getReviewMediaSettingsSuccess: (
      state,
      action: PayloadAction<ReviewMediaData>
    ) => {
      state.reviewMediaSettings = action.payload;
      state.reviewMediaSettingLoading = false;
    },
    getReviewMediaSettingsError: (state) => {
      state.reviewMediaSettingLoading = false;
    },
    editReviewMediaSettingsStart: (state) => {
      state.editReviewMediaSettingsLoading = true;
    },
    editReviewMediaSettingsSuccess: (state) => {
      state.editReviewMediaSettingsLoading = false;
    },
    editReviewMediaSettingsError: (
      state,
      action: PayloadAction<ErrorWrapper>
    ) => {
      state.editReviewMediaSettingsLoading = false;
      state.error = action.payload;
    },
    deleteSurveysStart: (state) => {
      state.multipleDeleteLoading = true;
    },
    deleteSurveysSuccess: (state) => {
      state.multipleDeleteLoading = false;
      state.multipleDeleteFinished = true;
    },
    deleteSurveysError: (state, _action: PayloadAction<ErrorWrapper>) => {
      state.multipleDeleteLoading = false;
    },
    setSelectedSurveys: (
      state,
      action: PayloadAction<Array<{ id: string }>>
    ) => {
      state.selectedSurveys = action.payload;
    },
    clearSelectedSurveys: (state) => {
      state.selectedSurveys = [];
    },
    setRadioItems: (state, action: PayloadAction<Array<OptionItems>>) => {
      state.radioItems = action.payload;
    },
    clearRadioItems: (state) => {
      state.radioItems = [];
    },
    setCheckboxItems: (state, action: PayloadAction<Array<OptionItems>>) => {
      state.checkboxItems = action.payload;
    },
    clearCheckboxItems: (state) => {
      state.checkboxItems = [];
    },
    clearPickedQuestions: (state) => {
      state.pickedQuestions = [];
    },
    getSelectedQuestionsStart: (state) => {
      state.selectedQuestionsLoading = true;
    },
    getSelectedQuestionsSuccess: (
      state,
      action: PayloadAction<Array<SelectedQuestion>>
    ) => {
      state.selectedQuestions = action.payload;
      state.selectedQuestionsLoading = false;
    },
    getSelectedQuestionsError: (state, action: PayloadAction<any>) => {
      state.selectedQuestionsLoading = false;
      state.error = action.payload;
    },
    getQuestionBranchItemsStart: (state) => {
      state.questionBranchItemsLoading = true;
    },
    getQuestionBranchItemsSuccess: (
      state,
      action: PayloadAction<Array<QuestionItem>>
    ) => {
      state.questionBranchItemsLoading = false;
      state.questionBranchItems = action.payload;
    },
    getQuestionBranchItemsError: (state) => {
      state.questionBranchItemsLoading = false;
    },
    getQuestionGroupItemsStart: (state) => {
      state.questionGroupItemsLoading = true;
    },
    getQuestionGroupItemsSuccess: (
      state,
      action: PayloadAction<Array<QuestionItem>>
    ) => {
      state.questionGroupItemsLoading = false;
      state.questionGroupItems = action.payload;
    },
    getQuestionGroupItemsError: (state) => {
      state.questionGroupItemsLoading = false;
    },
    getBaseQuestionItemStart: (state) => {
      state.baseQuestionItemLoading = true;
    },
    getBaseQuestionItemSuccess: (
      state,
      action: PayloadAction<Array<QuestionItem>>
    ) => {
      state.baseQuestionItemLoading = false;
      state.baseQuestionItem = action.payload;
    },
    getBaseQuestionItemError: (state) => {
      state.baseQuestionItemLoading = false;
    },
    getCustomQuestionItemStart: (state) => {
      state.customQuestionItemLoading = true;
    },
    getCustomQuestionItemSuccess: (
      state,
      action: PayloadAction<Array<QuestionItem>>
    ) => {
      state.customQuestionItemLoading = false;
      state.customQuestionItem = action.payload;
    },
    getCustomQuestionItemError: (state) => {
      state.customQuestionItemLoading = false;
    },
    getTemplateSurveysStart: (state) => {
      state.templateSurveysLoading = true;
    },
    getTemplateSurveysSuccess: (
      state,
      action: PayloadAction<Array<TemplateSurveys>>
    ) => {
      state.templateSurveys = action.payload;
      state.templateSurveysLoading = false;
    },
    getTemplateSurveysError: (state) => {
      state.templateSurveysLoading = false;
    },
    getTemplateQuestionsStart: (state) => {
      state.templateQuestionsLoading = true;
    },
    getTemplateQuestionsSuccess: (
      state,
      action: PayloadAction<Array<SelectedQuestion>>
    ) => {
      state.selectedQuestions = action.payload;
      state.templateQuestionsLoading = false;
    },
    getTemplateQuestionsError: (state) => {
      state.templateQuestionsLoading = false;
    },
    setConditions: (state, action: PayloadAction<string>) => {
      state.conditions = action.payload;
    },
    setFreeInput: (state, action: PayloadAction<string>) => {
      state.freeInput = action.payload;
    },
    fileUploadStart: (state) => {
      state.fileUploading = true;
    },
    fileUploadSuccess: (state) => {
      state.fileUploading = false;
    },
    fileUploadError: (state) => {
      state.fileUploading = false;
    },
    getLanguageModelListStart: (state) => {
      state.getLanguageModelLoading = true;
    },
    getLanguageModelListSuccess: (
      state,
      action: PayloadAction<Array<LanguageModel>>
    ) => {
      state.languageModel = action.payload;
      state.getLanguageModelLoading = false;
    },
    getLanguageModelListError: (state) => {
      state.getLanguageModelLoading = false;
    },
    getLanguageSettingsListStart: (state) => {
      state.getLanguageSettingsListLoading = true;
    },
    getLanguageSettingsListSuccess: (
      state,
      action: PayloadAction<Array<LanguageSettings>>
    ) => {
      state.languageSettings = action.payload;
      state.getLanguageSettingsListLoading = false;
    },
    getLanguageSettingsListError: (state) => {
      state.getLanguageSettingsListLoading = false;
    },
    getEcEmailSettingsStart: (state) => {
      state.ecEmailSettingsLoading = true;
    },
    getEcEmailSettingsSuccess: (
      state,
      action: PayloadAction<SurveyEcEmailSettings>
    ) => {
      state.ecEmailSettings = action.payload;
      state.ecEmailSettingsLoading = false;
    },
    getEcEmailSettingsError: (state) => {
      state.ecEmailSettingsLoading = false;
    },
    editEcEmailSettingsStart: (state) => {
      state.ecEmailSettingsLoading = true;
    },
    editEcEmailSettingsSuccess: (state) => {
      state.ecEmailSettingsLoading = false;
    },
    editEcEmailSettingsError: (state, action: PayloadAction<ErrorWrapper>) => {
      state.ecEmailSettingsLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  getSurveyListStart,
  getSurveyListSuccess,
  getSurveyListError,
  getSurveyStart,
  getSurveySuccess,
  getSurveyError,
  clearSurvey,
  addSurveysStart,
  addSurveysSuccess,
  addSurveysError,
  addQuestionsStart,
  addQuestionsSuccess,
  addQuestionsError,
  duplicateSurveyStart,
  duplicateSurveySuccess,
  duplicateSurveyError,
  editSurveysStart,
  editSurveysSuccess,
  editSurveysError,
  editSurveySettingsStart,
  editSurveySettingsSuccess,
  editSurveySettingsError,
  getReviewMediaSettingsStart,
  getReviewMediaSettingsSuccess,
  getReviewMediaSettingsError,
  editReviewMediaSettingsStart,
  editReviewMediaSettingsSuccess,
  editReviewMediaSettingsError,
  deleteSurveysStart,
  deleteSurveysSuccess,
  deleteSurveysError,
  setSelectedSurveys,
  clearSelectedSurveys,
  setRadioItems,
  clearRadioItems,
  setCheckboxItems,
  clearCheckboxItems,
  clearItems,
  clearPickedQuestions,
  getSelectedQuestionsStart,
  getSelectedQuestionsSuccess,
  getSelectedQuestionsError,
  getQuestionBranchItemsStart,
  getQuestionBranchItemsSuccess,
  getQuestionBranchItemsError,
  getQuestionGroupItemsStart,
  getQuestionGroupItemsSuccess,
  getQuestionGroupItemsError,
  getBaseQuestionItemStart,
  getBaseQuestionItemSuccess,
  getBaseQuestionItemError,
  getCustomQuestionItemStart,
  getCustomQuestionItemSuccess,
  getCustomQuestionItemError,
  getTemplateSurveysStart,
  getTemplateSurveysSuccess,
  getTemplateSurveysError,
  getTemplateQuestionsStart,
  getTemplateQuestionsSuccess,
  getTemplateQuestionsError,
  setConditions,
  setFreeInput,
  fileUploadStart,
  fileUploadSuccess,
  fileUploadError,
  getLanguageModelListStart,
  getLanguageModelListSuccess,
  getLanguageModelListError,
  getLanguageSettingsListStart,
  getLanguageSettingsListSuccess,
  getLanguageSettingsListError,
  getEcEmailSettingsStart,
  getEcEmailSettingsSuccess,
  getEcEmailSettingsError,
  editEcEmailSettingsStart,
  editEcEmailSettingsSuccess,
  editEcEmailSettingsError,
} = slice.actions;

export default slice.reducer;
