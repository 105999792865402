import React from "react";
import { Modal } from "antd";
import i18n from "i18n";

interface OptoutPageTemplatePreviewDialogProps {
  content: string;
  visible: boolean;
  closeModal: () => void;
}

const OptoutPageTemplatePreviewDialog: React.FC<OptoutPageTemplatePreviewDialogProps> = (
  props
) => {
  const onClickCancelButton = () => {
    props.closeModal();
  };

  return (
    <Modal
      width={"fit-content"}
      visible={props.visible}
      title={i18n.t("mailTemplate.optoutPageTemplate.preview")}
      cancelText={i18n.t("common.cancel")}
      onCancel={onClickCancelButton}
      footer={null}
    >
      <div
        dangerouslySetInnerHTML={{
          __html: props.content,
        }}
      />
    </Modal>
  );
};

export default OptoutPageTemplatePreviewDialog;
