import React, { useState } from "react";
import { RootState } from "store/store";
import { connect } from "react-redux";
import i18n from "i18n";
import { Row, Col, Form, Input, Radio, Modal } from "antd";
import { MailExclusionSettingsUpdateForm } from "api/sms-api";
import {
  addMailExclusionSettings,
  updateMailExclusionSettings,
} from "./MailExclusionSettingsActions";
import {
  ecOptoutTypeStore,
  mailExclusionAddMode,
  mailExclusionUpdateMode,
} from "../../constants";
import { emailIsInvalid } from "utils/strings";
import { RadioChangeEvent } from "antd/lib/radio";

interface MailExclusionSettingsAddProps {
  visible: boolean;
  closeModal: () => void;
  addMailExclusionSettings: (form: MailExclusionSettingsUpdateForm) => void;
  updateMailExclusionSettings: (form: MailExclusionSettingsUpdateForm) => void;
}

const MailExclusionSettingsAdd: React.FC<MailExclusionSettingsAddProps> = (
  props
) => {
  const [mailExclusionMode, setMailExclusionMode] = useState<string>(
    mailExclusionAddMode
  );
  const [form] = Form.useForm();

  const onClickAddButton = (value: {
    emailAddresses: string;
    mode: string;
  }) => {
    // Convert the email address string to a list of email addresses
    const updateForm = {
      emailAddresses: value.emailAddresses.split(/\r\n|\r|\n|,/g),
      optoutType: ecOptoutTypeStore,
    };
    if (value.mode === mailExclusionAddMode) {
      props.addMailExclusionSettings(updateForm);
    } else {
      props.updateMailExclusionSettings(updateForm);
    }
    form.resetFields();
    props.closeModal();
  };

  const onClickCancelButton = () => {
    form.resetFields();
    props.closeModal();
  };

  const handleModeChange = (e: RadioChangeEvent) => {
    if (e.target.value) {
      setMailExclusionMode(e.target.value);
    }
  };

  const initialValues = {
    mode: mailExclusionAddMode,
    emailAddresses: "",
  };

  return (
    <Modal
      visible={props.visible}
      title={i18n.t("mailExclusionSettings.addEmailList.title")}
      okText={i18n.t("common.save")}
      cancelText={i18n.t("common.cancel")}
      onCancel={onClickCancelButton}
      onOk={() => {
        form.validateFields().then((values) => {
          onClickAddButton(values);
        });
      }}
    >
      <Form form={form} layout="vertical" initialValues={initialValues}>
        <Row justify="center">
          <Col span={18}>
            <Form.Item name="mode">
              <Radio.Group
                name="mode"
                className="form-radio-group"
                onChange={handleModeChange}
              >
                <Radio.Button
                  name={mailExclusionAddMode}
                  value={mailExclusionAddMode}
                >
                  {i18n.t("mailExclusionSettings.addEmailList.newList")}
                </Radio.Button>
                <Radio.Button
                  name={mailExclusionUpdateMode}
                  value={mailExclusionUpdateMode}
                >
                  {i18n.t("mailExclusionSettings.addEmailList.updateList")}
                </Radio.Button>
              </Radio.Group>
            </Form.Item>
            <span className="mail-exclusion-warning">
              {mailExclusionMode === mailExclusionUpdateMode
                ? i18n.t("mailExclusionSettings.addEmailList.newDescription")
                : i18n.t(
                    "mailExclusionSettings.addEmailList.updateDescription"
                  )}
            </span>
            <Form.Item
              name="emailAddresses"
              rules={[
                {
                  validator(_, value) {
                    if (
                      value &&
                      !emailIsInvalid(value.split(/\r\n|\r|\n|,/g))
                    ) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error(i18n.t("validation.mail")));
                  },
                },
              ]}
            >
              <Input.TextArea
                name="emailAddresses"
                // onChange={(e) => setValue(e.target.value)}
                placeholder={i18n.t(
                  "mailExclusionSettings.addEmailList.placeholder"
                )}
                autoSize={{ minRows: 3, maxRows: 5 }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

const mapStateToProps = (state: RootState) => {
  return state;
};

const mapDispatchToProps = {
  addMailExclusionSettings,
  updateMailExclusionSettings,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MailExclusionSettingsAdd);
