import React from "react";
import { DatePicker } from "formik-antd";
import _ from "lodash";
import locale from "antd/es/date-picker/locale/ja_JP";
import moment from "moment";
import "moment/locale/ja";

const ScheduledTimePicker: React.FC<Record<string, any>> = () => {
  return (
    <DatePicker
      name="scheduledTime"
      showTime
      format="YYYY-MM-DD HH:mm"
      minuteStep={5}
      locale={locale as any}
      allowClear={false}
      showToday={false}
      disabledDate={(date: moment.Moment) => {
        // cloneしないと日付を選択できなくなる
        const cloned = date.clone();
        return cloned.startOf("day").isBefore(moment().startOf("day"));
      }}
      disabledTime={(date: moment.Moment | null) => {
        const now = moment();
        if (date !== null && date.isSame(now, "day")) {
          // They've selected today in the datepicker -- disable all times
          // before now
          const nowHour = now.hour();
          const nowMinute = now.minute();
          const selectedHour = date.hour();
          const disabledHours = _.range(0, 24).filter((h) => h < nowHour);
          const disabledMinutes =
            selectedHour === nowHour
              ? _.range(0, 60).filter((m) => m < nowMinute)
              : [];
          return {
            disabledHours: () => disabledHours,
            disabledMinutes: () => disabledMinutes,
          };
        }
        // For dates in the future, all times are OK
        return {};
      }}
    />
  );
};

export default ScheduledTimePicker;
