import React from "react";
import { Typography } from "antd";
import { Form, Select } from "formik-antd";
import i18n from "i18n";
import CustomModal from "components/CustomModal";

const { Paragraph } = Typography;
const options = Array.from({ length: 9 }, (_, i) => ({
  name: i + 2,
  value: i + 2,
}));

interface RandomDisplaySettingModalProps {
  visible: boolean;
  closeModal: () => void;
}

const RandomDisplaySettingModal: React.FC<RandomDisplaySettingModalProps> = ({
  visible,
  closeModal,
}) => {
  const handleCloseModal = () => {
    closeModal();
  };

  return (
    <CustomModal
      title={i18n.t("survey.randomDisplay")}
      okText={i18n.t("common.save")}
      visible={visible}
      closeModal={handleCloseModal}
    >
      <Form layout="vertical">
        <Paragraph>{i18n.t("survey.randomSettingText1")}</Paragraph>
        <Form.Item
          name="randomDisplayCount"
          label={i18n.t("survey.randomSettingText2")}
        >
          <Select
            name="randomDisplayCount"
            options={options}
            style={{ width: "70px" }}
          />
        </Form.Item>
        <Paragraph>{i18n.t("survey.randomSettingText3")}</Paragraph>
      </Form>
    </CustomModal>
  );
};

export default RandomDisplaySettingModal;
