import React from "react";
import { Space } from "antd";
import { Form, Radio } from "formik-antd";
import i18n from "i18n";
import CustomModal from "components/CustomModal";
import { ImpressionPromptMode } from "../../constants";

interface SelectImpressionPromptModalProps {
  visible: boolean;
  closeModal: () => void;
}

const SelectImpressionPromptModal: React.FC<SelectImpressionPromptModalProps> = ({
  visible,
  closeModal,
}) => {
  const handleCloseModal = () => {
    closeModal();
  };

  return (
    <CustomModal
      title={i18n.t("survey.selectImpressionPrompt")}
      okText={i18n.t("common.save")}
      visible={visible}
      closeModal={handleCloseModal}
    >
      <Form layout="vertical">
        <Form.Item name="impressionPromptMode">
          <Radio.Group name="impressionPromptMode">
            <Space direction="vertical">
              <Radio
                name="impressionPromptMode"
                value={ImpressionPromptMode.Random}
              >
                {i18n.t("survey.impressionPromptMode.random")}
              </Radio>
              <Radio
                name="impressionPromptMode"
                value={ImpressionPromptMode.Always}
              >
                {i18n.t("survey.impressionPromptMode.always")}
              </Radio>
            </Space>
          </Radio.Group>
        </Form.Item>
      </Form>
    </CustomModal>
  );
};

export default SelectImpressionPromptModal;
