import React from "react";
import { AddressImportResult, AddressImportError } from "api/sms-api";
import { Alert, List } from "antd";
import i18n from "i18n";
import ImportError from "./ImportError";

interface ImportResultProps {
  result: AddressImportResult;
}

const ImportResult: React.FC<ImportResultProps> = (props) => {
  let alert;

  if (props.result.errors.length === 0) {
    alert = (
      <Alert
        type="success"
        message={i18n.t("addresses.importSuccess", {
          count: props.result.successCount,
        })}
      />
    );
  } else {
    const text = i18n.t("addresses.importErrors", {
      count: props.result.successCount,
    });
    const errors = props.result.errors.map(
      (e: AddressImportError, i: number) => {
        return <ImportError error={e} key={i} />;
      }
    );

    const fullMessage = (
      <div>
        <span>{text}</span>
        <List style={{ marginTop: "1em" }}>{errors}</List>
      </div>
    );

    alert = <Alert type="warning" message={fullMessage} />;
  }

  return <div style={{ marginTop: "1em" }}>{alert}</div>;
};

export default ImportResult;
