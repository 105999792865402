import { configureStore } from "@reduxjs/toolkit";
import { Action, combineReducers } from "@reduxjs/toolkit";
import { ThunkAction } from "redux-thunk";
import appStateReducer from "../features/appstate/AppStateSlice";
import publishReducer from "../features/publish/PublishSlice";
import addressReducer from "../features/address/AddressSlice";
import groupReducer from "../features/group/GroupSlice";
import templatesReducer from "../features/templates/TemplatesSlice";
import replyTemplatesReducer from "../features/reply_templates/ReplyTemplateSlice";
import historyReducer from "../features/history/HistorySlice";
import scheduleReducer from "../features/schedule/ScheduleSlice";
import clientReducer from "../features/client/ClientSlice";
import seoReducer from "../features/seo/SEOSlice";
import landingPageSettingsReducer from "../features/landing_page_settings/LandingPageSettingsSlice";
import reviewLinkReducer from "../features/review_link/ReviewLinkSlice";
import statisticsReducer from "../features/statistics/StatisticsSlice";
import blacklistReducer from "../features/blacklist/BlacklistSlice";
import reviewsReducer from "../features/reviews/ReviewSlice";
import surveyReducer from "../features/survey/SurveySlice";
import surveyMeoSitesReducer from "../features/survey_meo_sites/SurveyMeoSitesSlice";
import meoSiteSettingsReducer from "../features/meo_site_settings/MeoSiteSettingsSlice";
import contractDetailsReducer from "../features/contract_details/ContractDetailsSlice";
import productSettingsReducer from "../features/product_settings/ProductSettingsSlice";
import surveyProductsReducer from "../features/survey_products/SurveyProductsSlice";
import ecLinkStatusReducer from "../features/ec_link_status/EcLinkStatusSlice";
import orderHistoryReducer from "../features/order_history/OrderHistorySlice";
import orderSurveyEmailsReducer from "../features/order_survey_emails/OrderSurveyEmailsSlice";
import mailTemplatesReducer from "../features/mail_template/MailTemplateSlice";
import mailTemplateSenderReducer from "../features/mail_template_sender/MailTemplateSenderSlice";
import ecProvidersReducer from "../features/product_settings/EcProvidersSlice";
import optoutPageTemplateReducer from "../features/optout_page_template/OptoutPageTemplateSlice";
import mailExclusionSettingsReducer from "../features/mail_exclusion_settings/MailExclusionSettingsSlice";

export const rootReducer = combineReducers({
  app: appStateReducer,
  publish: publishReducer,
  addresses: addressReducer,
  group: groupReducer,
  templates: templatesReducer,
  replyTemplates: replyTemplatesReducer,
  history: historyReducer,
  scheduleds: scheduleReducer,
  client: clientReducer,
  seo: seoReducer,
  landingPageSettings: landingPageSettingsReducer,
  reviewLinks: reviewLinkReducer,
  statistics: statisticsReducer,
  blacklist: blacklistReducer,
  reviews: reviewsReducer,
  survey: surveyReducer,
  surveyMeoSites: surveyMeoSitesReducer,
  meoSiteSettings: meoSiteSettingsReducer,
  contractDetails: contractDetailsReducer,
  productSettings: productSettingsReducer,
  surveyProducts: surveyProductsReducer,
  ecLinkStatus: ecLinkStatusReducer,
  orderHistory: orderHistoryReducer,
  orderSurveyEmails: orderSurveyEmailsReducer,
  mailTemplates: mailTemplatesReducer,
  mailTemplateSender: mailTemplateSenderReducer,
  ecProviders: ecProvidersReducer,
  optoutPageTemplate: optoutPageTemplateReducer,
  mailExclusionSettings: mailExclusionSettingsReducer,
});

const store = configureStore({
  devTools: process.env.NODE_ENV !== "production",
  reducer: rootReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;
export type AsyncAppThunk<A> = ThunkAction<
  Promise<A>,
  RootState,
  null,
  Action<string>
>;
export type AppDispatch = typeof store.dispatch;

export default store;
