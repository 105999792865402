import React, { useEffect } from "react";
import { Formik } from "formik";
import { Form, Radio, Input } from "formik-antd";
import i18n from "i18n";
import { Spin, Row, Col, Space } from "antd";
import { connect } from "react-redux";
import { RootState } from "store/store";
import { ReviewMediaData } from "api/sms-api";
import { reviewMediaSettingsFormSchema } from "validation";
import {
  getReviewMediaSettings,
  editReviewMediaSettings,
} from "./SurveyActions";
import qs from "qs";
import { Page, setPage } from "features/appstate/AppStateSlice";
import SaveButton from "components/SaveButton";
import { useHistory } from "react-router-dom";
import { pushTo } from "utils/navigation";
import CompletionMessage from "./CompletionMessage";
import { MODE_SURVEY, surveyTypeStore } from "../../constants";

interface ReviewMediaSettingsProps {
  setPage: (page: Page) => void;

  reviewMediaSettings?: ReviewMediaData;
  reviewMediaSettingLoading: boolean;
  getReviewMediaSettings: (surveyId: string) => void;

  editReviewMediaSettings: (
    surveyId: string,
    params: ReviewMediaData
  ) => Promise<void>;
}

export interface ReviewMediaSettingsFormProps {
  reviewMediaType: string;
  completionMessageV2: string;
  requestReviewMessage: string;
  mediaName: string;
  nonGoogleCompletionMessageV2: string;
  nonGoogleRequestReviewMessage: string;
}

const ReviewMediaSettings: React.FC<ReviewMediaSettingsProps> = (props) => {
  const history = useHistory();
  const parsed = qs.parse(location.search, { ignoreQueryPrefix: true });
  const surveyId = parsed.id ? parsed.id.toString() : "";

  useEffect(() => {
    props.setPage(Page.reviewMediaSettings);
  }, [props.setPage]);

  useEffect(() => {
    if (surveyId) {
      props.getReviewMediaSettings(surveyId);
    }
  }, [props.getReviewMediaSettings, surveyId]);

  if (props.reviewMediaSettingLoading || !props.reviewMediaSettings) {
    return <Spin />;
  }

  const handleSubmit = (form: ReviewMediaSettingsFormProps) => {
    const param: ReviewMediaData = {
      isGoogleReview:
        form.reviewMediaType === i18n.t("survey.reviewMediaType.google"),
      completionMessageV2: form.completionMessageV2,
      requestReviewMessage: form.requestReviewMessage,
      mediaName: form.mediaName,
      nonGoogleCompletionMessageV2: form.nonGoogleCompletionMessageV2,
      nonGoogleRequestReviewMessage: form.nonGoogleRequestReviewMessage,
    };
    props.editReviewMediaSettings(surveyId, param);
    pushTo(history, "/survey", {
      mode: MODE_SURVEY,
      surveyType: parsed.surveyType || surveyTypeStore,
    });
  };

  const initialValues = {
    reviewMediaType: props.reviewMediaSettings.isGoogleReview
      ? i18n.t("survey.reviewMediaType.google")
      : i18n.t("survey.reviewMediaType.other"),
    completionMessageV2: props.reviewMediaSettings.completionMessageV2,
    requestReviewMessage: props.reviewMediaSettings.requestReviewMessage,
    mediaName: props.reviewMediaSettings.mediaName || "",
    nonGoogleCompletionMessageV2:
      props.reviewMediaSettings.nonGoogleCompletionMessageV2,
    nonGoogleRequestReviewMessage:
      props.reviewMediaSettings.nonGoogleRequestReviewMessage,
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={reviewMediaSettingsFormSchema}
    >
      {(formikBag) => {
        return (
          <Form layout="vertical">
            <div className="question-container">
              <Row justify="center">
                <Col span={18}>
                  <Form.Item name="reviewMediaType">
                    <Radio.Group name="reviewMediaType">
                      <Space direction="vertical">
                        {parsed.surveyType === surveyTypeStore ? (
                          <Radio
                            name="reviewMediaType"
                            value={i18n.t("survey.reviewMediaType.google")}
                          >
                            {i18n.t("survey.reviewMediaType.google")}
                          </Radio>
                        ) : null}
                        {formikBag.values.reviewMediaType ===
                          i18n.t("survey.reviewMediaType.google") &&
                        parsed.surveyType === surveyTypeStore ? (
                          <>
                            <CompletionMessage
                              completionMessageName="completionMessageV2"
                              completionMessageValue={
                                formikBag.values.completionMessageV2
                              }
                              messageLabel={i18n.t(
                                "survey.requestReviewMessageLabel"
                              )}
                              messageName="requestReviewMessage"
                              messageValue={
                                formikBag.values.requestReviewMessage
                              }
                              formikBag={formikBag}
                            />
                          </>
                        ) : null}
                        {parsed.surveyType === surveyTypeStore ? (
                          <Radio
                            name="reviewMediaType"
                            value={i18n.t("survey.reviewMediaType.other")}
                          >
                            {i18n.t("survey.reviewMediaType.other")}
                          </Radio>
                        ) : null}
                        {formikBag.values.reviewMediaType ===
                        i18n.t("survey.reviewMediaType.other") ? (
                          <>
                            {parsed.surveyType === surveyTypeStore ? (
                              <Form.Item
                                label={i18n.t("survey.reviewMediaName")}
                                name="mediaName"
                                className="review-media-container"
                              >
                                <Input name="mediaName" />
                              </Form.Item>
                            ) : null}
                            <CompletionMessage
                              completionMessageName="nonGoogleCompletionMessageV2"
                              completionMessageValue={
                                formikBag.values.nonGoogleCompletionMessageV2
                              }
                              messageLabel={i18n.t(
                                "survey.requestReviewMessageLabel"
                              )}
                              messageName="nonGoogleRequestReviewMessage"
                              messageValue={
                                formikBag.values.nonGoogleRequestReviewMessage
                              }
                              formikBag={formikBag}
                            />
                          </>
                        ) : null}
                      </Space>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
              <Row justify="end" style={{ marginTop: "2.5em" }}>
                <SaveButton />
              </Row>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    reviewMediaSettings: state.survey.reviewMediaSettings,
    reviewMediaSettingLoading: state.survey.reviewMediaSettingLoading,
  };
};

const mapDispatchToProps = {
  setPage,
  getReviewMediaSettings,
  editReviewMediaSettings,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReviewMediaSettings);
