import React from "react";
import { RecipientEmailCsvImportError } from "api/sms-api";
import i18n from "i18n";
import { List } from "antd";

interface ImportErrorProps {
  error: RecipientEmailCsvImportError;
}

const ImportError: React.FC<ImportErrorProps> = (props) => {
  const description = i18n.t(
    `productSettings.csv.importErrorDescription.${props.error.errorType}`
  );
  return (
    <List.Item>
      {`${i18n.t("productSettings.csv.importErrorLine", {
        line: props.error.lineNumber,
      })}${description}`}
    </List.Item>
  );
};

export default ImportError;
