import React from "react";
import { Contact } from "api/sms-api";
import { Table } from "antd";
import PageLocale from "./PageLocale";
import i18n from "i18n";

interface ContactBlockProps {
  contacts: Array<Contact>;
  showStatus: boolean;
  isDirect: boolean;
}

const locale = PageLocale;

const ContactBlock: React.FC<ContactBlockProps> = (props) => {
  const columns: Array<any> = [
    {
      title: i18n.t("common.name"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: i18n.t("common.phoneNumber"),
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
  ];

  if (props.showStatus) {
    columns.push({
      title: i18n.t("contacts.status"),
      dataIndex: "status",
      key: "status",
      render: (status: string) => {
        return i18n.t(`contacts.statuses.${status}`);
      },
    });
  }

  return (
    <Table
      title={() =>
        props.isDirect
          ? i18n.t("common.addressDirect")
          : i18n.t("common.addresses")
      }
      dataSource={props.contacts}
      rowKey={(contact: Contact) => contact.id}
      columns={columns}
      pagination={{ locale }}
    />
  );
};

export default ContactBlock;
