import React from "react";
import { connect } from "react-redux";
import { pushTo } from "utils/navigation";
import { FullGroup, GroupAddForm, MEOSite } from "api/sms-api";
import { getGroup, editGroup, deleteGroup } from "./GroupActions";
import { ModifiedGroupForm } from "features/group/GroupAdd";
import { clearGroup } from "./GroupSlice";
import { RootState } from "store/store";
import { Page, setPage } from "features/appstate/AppStateSlice";
import { Button, Spin, Row, Popconfirm } from "antd";
import { CustomError, ErrorWrapper } from "errors/errors";
import { Formik, FormikHelpers } from "formik";
import { Form, Input } from "formik-antd";
import i18n from "i18n";
import { groupAddFormSchema } from "validation";
import { formItemLayout } from "../../constants";
import { useHistory, useParams } from "react-router-dom";
import NotFound from "components/NotFound";
import BackButton from "components/BackButton";
import SaveButton from "components/SaveButton";
import DeleteButton from "components/DeleteButton";
import { setSavedForm, clearSavedForm } from "./GroupSlice";
import qs from "qs";

interface GroupEditProps {
  getLoading: boolean;
  editLoading: boolean;
  deleteLoading: boolean;
  deleteFinished: boolean;
  editFinished: boolean;
  group?: FullGroup;
  error?: ErrorWrapper;
  getGroup: (id: string) => void;
  deleteGroup: (id: string) => void;
  editGroup: (id: string, form: GroupAddForm) => void;
  setPage: (page: Page) => void;
  clearGroup: () => void;
  meoSite: MEOSite;
  savedForm?: ModifiedGroupForm;
  setSavedForm: (form: ModifiedGroupForm) => void;
  clearSavedForm: () => void;
}

const GroupEdit: React.FC<GroupEditProps> = (props) => {
  const history = useHistory();
  const { id } = useParams() as any;
  const parsed = qs.parse(location.search, { ignoreQueryPrefix: true });

  React.useEffect(() => {
    props.setPage(Page.group);
  }, [props.setPage]);

  React.useEffect(() => {
    props.getGroup(id);
  }, [props.getGroup, id]);

  React.useEffect(() => {
    if (props.deleteFinished || props.editFinished) {
      pushTo(history, `/${Page.group}`, {});
    }
  }, [props.deleteFinished, props.editFinished]);

  React.useEffect(() => {
    // Run on unmount
    return () => {
      props.clearGroup();
    };
  }, [props.clearGroup]);

  if (props.error?.error === CustomError.NotFound) {
    return <NotFound />;
  }

  if (props.getLoading || !props.group) {
    return <Spin />;
  }

  let initialValues: ModifiedGroupForm = {
    meoSiteId: props.meoSite.id,
    name: props.group?.group.name || "",
    addressIds: props.group?.addresses.map((a) => a.id) || [],
  };

  if (props.savedForm && parsed.shortcutSelected === "back") {
    initialValues = props.savedForm;
    props.clearSavedForm();
  }

  const handleSubmit = (
    form: ModifiedGroupForm,
    helpers: FormikHelpers<ModifiedGroupForm>
  ) => {
    const groupAddForm: GroupAddForm = {
      meoSiteId: props.meoSite.id,
      name: form.name,
      addressIds: form.addressIds,
    };
    props.editGroup(id, groupAddForm);
    helpers.setFieldValue("name", form.name);
  };

  const handleDelete = () => {
    props.deleteGroup(id);
  };

  const goToSelectAddress = (form: ModifiedGroupForm) => {
    props.setSavedForm(form);
    pushTo(history, "/addresses", {
      selecting: "group",
      backUrl: "/group/edit/" + id,
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={groupAddFormSchema}
    >
      {(formikBag) => {
        return (
          <Form {...formItemLayout}>
            <Form.Item name="name" label={i18n.t("common.name")}>
              <Input
                name="name"
                placeholder={i18n.t("groups.namePlaceholder")}
              />
            </Form.Item>
            <Form.Item
              name="addressIds"
              label={i18n.t("groups.addresses")}
              extra={i18n.t("groups.selectedCount", {
                count: formikBag.values.addressIds.length,
              })}
            >
              <Button
                className="template-shortcut max-width-on-mobile"
                onClick={() => goToSelectAddress(formikBag.values)}
              >
                {i18n.t("groups.selectFromAddress")}
              </Button>
              {formikBag.errors.addressIds && formikBag.touched.addressIds && (
                <div className="ant-form-item-has-error">
                  <div className="ant-form-item-explain">
                    {formikBag.errors.addressIds}
                  </div>
                </div>
              )}
            </Form.Item>
            <Row className="form-button-row">
              <SaveButton loading={props.editLoading} />
              <BackButton />
              <Popconfirm
                title={i18n.t("groups.deleteConfirm")}
                onConfirm={handleDelete}
                okText={i18n.t("common.delete")}
                cancelText={i18n.t("common.cancel")}
                okType="danger"
              >
                <DeleteButton loading={props.deleteLoading} />
              </Popconfirm>
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = (root: RootState) => {
  return {
    group: root.group.item,
    getLoading: root.group.getLoading,
    editLoading: root.group.editLoading,
    deleteLoading: root.group.deleteLoading,
    deleteFinished: root.group.deleteFinished,
    editFinished: root.group.editFinished,
    error: root.group.error,
    meoSite: root.seo.meoSite!,
    savedForm: root.group.savedForm,
  };
};
const mapDispatchToProps = {
  getGroup,
  editGroup,
  deleteGroup,
  clearGroup,
  setPage,
  setSavedForm,
  clearSavedForm,
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupEdit);
