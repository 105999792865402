import { AppDispatch, AppThunk, AsyncAppThunk } from "store/store";
import { httpWrapper } from "../../utils/http";
import { SMS_API_BASE } from "../../constants";
import { handleError } from "errors/errors";
import {
  listTemplatesStart,
  listTemplatesSuccess,
  listTemplatesError,
  addTemplateStart,
  addTemplateSuccess,
  addTemplateError,
  getTemplateStart,
  getTemplateSuccess,
  getTemplateError,
  editTemplateStart,
  editTemplateSuccess,
  editTemplateError,
  deleteTemplateStart,
  deleteTemplateSuccess,
  deleteTemplateError,
  getMessageStatsStart,
  getMessageStatsSuccess,
  getMessageStatsFailure,
} from "./TemplatesSlice";
import {
  Template,
  Paginated,
  ListTemplateParams,
  TemplateAddForm,
  MessageStatsForm,
  MessageStats,
} from "../../api/sms-api";
import i18n from "i18n";
import { showNotification } from "utils/notifications";

export const listTemplates = (params: ListTemplateParams): AppThunk => async (
  dispatch: AppDispatch
) => {
  dispatch(listTemplatesStart());
  try {
    const templates = await httpWrapper<Paginated<Template>>(
      "GET",
      `${SMS_API_BASE}/templates`,
      params
    );

    dispatch(listTemplatesSuccess(templates));
  } catch (e) {
    handleError(e);
    dispatch(listTemplatesError(e));
  }
};

export const addTemplate = (
  params: TemplateAddForm
): AsyncAppThunk<void> => async (dispatch: AppDispatch) => {
  dispatch(addTemplateStart());
  try {
    await httpWrapper("POST", `${SMS_API_BASE}/templates`, params);

    showNotification(i18n.t("templates.createSuccess"));
    dispatch(addTemplateSuccess());
  } catch (e) {
    handleError(e);
    dispatch(addTemplateError(e));
  }
};

export const editTemplate = (
  id: string,
  params: TemplateAddForm
): AsyncAppThunk<void> => async (dispatch: AppDispatch) => {
  dispatch(editTemplateStart());
  try {
    await httpWrapper("PATCH", `${SMS_API_BASE}/templates/${id}`, params);

    showNotification(i18n.t("templates.editSuccess"));
    dispatch(editTemplateSuccess());
  } catch (e) {
    handleError(e);
    dispatch(editTemplateError(e));
  }
};

export const getTemplate = (
  id: string,
  forShortcut: boolean
): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(getTemplateStart());
  try {
    const template = await httpWrapper<Template>(
      "GET",
      `${SMS_API_BASE}/templates/${id}`,
      {}
    );
    dispatch(getTemplateSuccess({ template, forShortcut }));
  } catch (e) {
    handleError(e);
    dispatch(getTemplateError(e));
  }
};

export const deleteTemplate = (id: string): AppThunk => async (
  dispatch: AppDispatch
) => {
  dispatch(deleteTemplateStart());
  try {
    await httpWrapper("DELETE", `${SMS_API_BASE}/templates/${id}`, {});
    showNotification(i18n.t("templates.deleteSuccess"));
    dispatch(deleteTemplateSuccess());
  } catch (e) {
    handleError(e);
    dispatch(deleteTemplateError(e));
  }
};

export const getMessageStats = (form: MessageStatsForm): AppThunk => async (
  dispatch: AppDispatch
) => {
  dispatch(getMessageStatsStart());
  try {
    const stats = await httpWrapper<MessageStats>(
      "post",
      `${SMS_API_BASE}/meta/message_stats`,
      form
    );
    dispatch(getMessageStatsSuccess(stats));
  } catch (e) {
    dispatch(getMessageStatsFailure());
  }
};
