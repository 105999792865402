import {
  SurveyProductsParams,
  Paginated,
  SurveyProducts,
  SurveyProductDeleteForm,
  SurveyProductsCsvImportResult,
  SurveyProductsEditForm,
} from "api/sms-api";
import { AppDispatch } from "store/store";
import { httpWrapper } from "utils/http";
import {
  getSurveyProductsListStart,
  getSurveyProductsListSuccess,
  getSurveyProductsListError,
  deleteSurveyProductsListStart,
  deleteSurveyProductsListSuccess,
  deleteSurveyProductsListError,
  exportStart,
  exportSuccess,
  exportError,
  importStart,
  importSuccess,
  importError,
  editReviewLinkStart,
  editReviewLinkSuccess,
  editReviewLinkError,
} from "./SurveyProductsSlice";
import { SMS_API_BASE, BOM } from "../../constants";
import { handleError } from "errors/errors";
import { showNotification } from "utils/notifications";
import i18n from "i18n";
import download from "js-file-download";

export const getSurveyProductsList = (params: SurveyProductsParams) => async (
  dispatch: AppDispatch
) => {
  dispatch(getSurveyProductsListStart());
  try {
    const surveyProductsList = await httpWrapper<Paginated<SurveyProducts>>(
      "GET",
      `${SMS_API_BASE}/client_next_engine/survey_products`,
      params
    );
    dispatch(getSurveyProductsListSuccess(surveyProductsList));
  } catch (e) {
    handleError(e);
    dispatch(getSurveyProductsListError());
  }
};

export const deleteSurveyProducts = (params: SurveyProductDeleteForm) => async (
  dispatch: AppDispatch
) => {
  dispatch(deleteSurveyProductsListStart());
  try {
    await httpWrapper(
      "PUT",
      `${SMS_API_BASE}/client_next_engine/survey_products`,
      params
    );
    showNotification(i18n.t("products.table.settings.deleteSuccess"));
    dispatch(deleteSurveyProductsListSuccess());
  } catch (e) {
    const error: any = e;
    handleError(error);
    dispatch(deleteSurveyProductsListError(error));
  }
};

export const exportCsv = (id: string) => async (dispatch: AppDispatch) => {
  dispatch(exportStart());
  try {
    const result = await httpWrapper<any>(
      "POST",
      `${SMS_API_BASE}/client_next_engine/survey_products/export/${id}`,
      {}
    );
    download(`${BOM}${result}`, i18n.t("products.csv.fileName"));
    dispatch(exportSuccess());
    return result;
  } catch (e) {
    handleError(e);
    dispatch(exportError());
  }
};

export const importCsv = (file: File) => async (dispatch: AppDispatch) => {
  dispatch(importStart());
  try {
    const formData = new FormData();
    formData.append("file", file);
    const result = await httpWrapper<SurveyProductsCsvImportResult>(
      "POST",
      `${SMS_API_BASE}/client_next_engine/survey_products/import`,
      formData,
      { "Content-Type": "multipart/form-data" }
    );
    dispatch(importSuccess(result));
    return result;
  } catch (e) {
    handleError(e);
    dispatch(importError());
  }
};

export const editReviewLink = (
  id: string,
  form: SurveyProductsEditForm
) => async (dispatch: AppDispatch) => {
  dispatch(editReviewLinkStart());
  try {
    await httpWrapper(
      "PATCH",
      `${SMS_API_BASE}/client_next_engine/survey_products/${id}`,
      form
    );
    dispatch(editReviewLinkSuccess());
  } catch (e) {
    const error: any = e;
    handleError(error);
    dispatch(editReviewLinkError(error));
  }
};
