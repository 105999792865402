import React from "react";
import Chart from "react-apexcharts";
import { Spin } from "antd";

export interface SeriesData {
  name: string;
  data: Array<number>;
}

interface StatisticsChartProps {
  xAxisLabels: Array<string>;
  barSeriesData: SeriesData;
  lineSeriesData: Array<SeriesData>;
  loading: boolean;
}

const StatisticsChart: React.FC<StatisticsChartProps> = (props) => {
  const fullSeries = [
    {
      type: "column",
      name: props.barSeriesData.name,
      data: props.barSeriesData.data,
    },
  ];

  for (const series of props.lineSeriesData) {
    fullSeries.push({
      type: "line",
      name: series.name,
      data: series.data,
    });
  }

  const options = {
    chart: {
      id: "chart",
    },
    xaxis: {
      categories: props.xAxisLabels,
    },
    yaxis: {
      labels: {
        formatter: function (val: string) {
          return parseInt(val, 10);
        },
      },
    },
    legend: {
      offsetY: 20,
      position: "top",
    },
  };

  if (props.loading) {
    return (
      <div style={{ textAlign: "center" }}>
        <Spin />
      </div>
    );
  }
  return (
    <div>
      <Chart options={options} series={fullSeries} />
    </div>
  );
};

export default StatisticsChart;
