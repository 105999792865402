import React, { useState, useEffect } from "react";
import { Typography, Spin, Row } from "antd";
import { Form, Select, Checkbox } from "formik-antd";
import i18n from "i18n";
import CustomModal from "components/CustomModal";
import { LanguageModel } from "api/sms-api";
import { connect } from "react-redux";
import { RootState } from "store/store";
import { proofReadingModel } from "../../constants";

const { Paragraph } = Typography;

interface SelectGptModelModalProps {
  visible: boolean;
  closeModal: () => void;
  selectModelId: string | null;

  languageModel: Array<LanguageModel>;
  getLanguageModelLoading: boolean;
}

const SelectGptModelModal: React.FC<SelectGptModelModalProps> = ({
  visible,
  closeModal,
  selectModelId,
  languageModel,
  getLanguageModelLoading,
}) => {
  useEffect(() => {
    const initialSelectedModel = options.find(
      (option) => option.value === selectModelId
    );
    if (initialSelectedModel) {
      setSelectedModelName(initialSelectedModel.label);
    }
  }, [languageModel]);
  const handleCloseModal = () => {
    closeModal();
  };
  const [selectedModelName, setSelectedModelName] = useState<string | null>(
    null
  );

  const options = languageModel.map(({ name, id }) => ({
    label: name,
    value: id,
  }));

  const handleModelChange = (value: string) => {
    const selectedOption = options.find((option) => option.value === value);
    setSelectedModelName(selectedOption ? selectedOption.label : null);
  };

  return (
    <CustomModal
      title={i18n.t("survey.selectGptModel")}
      okText={i18n.t("common.save")}
      visible={visible}
      closeModal={handleCloseModal}
    >
      <Form layout="vertical">
        <Paragraph>{i18n.t("survey.selectGptModelText")}</Paragraph>
        <Row className="select-language">
          {getLanguageModelLoading ? (
            <Spin />
          ) : (
            <Form.Item name="languageModelId">
              <Select
                name="languageModelId"
                options={options}
                style={{ width: "190px" }}
                onChange={handleModelChange}
              />
            </Form.Item>
          )}
          {selectedModelName && selectedModelName.includes(proofReadingModel) && (
            <Form.Item name="isProofreading">
              <Checkbox name="isProofreading" style={{ paddingLeft: "20px" }} />
              {i18n.t("survey.selectGptModelOption")}
            </Form.Item>
          )}
        </Row>
      </Form>
    </CustomModal>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    languageModel: state.survey.languageModel,
    isProofreading: state.survey.isProofreading,
    getLanguageModelLoading: state.survey.getLanguageModelLoading,
  };
};

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectGptModelModal);
