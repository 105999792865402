import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export enum Page {
  me = "me",
  publish = "publish",
  addresses = "addresses",
  group = "group",
  templates = "templates",
  replyTemplates = "replyTemplates",
  scheduled = "scheduled",
  history = "history",
  client = "client",
  landingPageSettings = "landingPageSettings",
  statistics = "statistics",
  reviews = "reviews",
  blacklist = "blacklist",
  survey = "survey",
  surveyAdd = "surveyAdd",
  surveyEdit = "surveyEdit",
  surveyTemplate = "surveyTemplate",
  surveySettings = "surveySettings",
  meoSites = "meoSites",
  reviewMediaSettings = "reviewMediaSettings",
  meoSiteSettings = "meoSiteSettings",
  contractDetails = "contractDetails",
  productSettings = "productSettings",
  orderHistory = "orderHistory",
  products = "products",
  ecLinkStatus = "ecLinkStatus",
  ecEmailSettings = "ecEmailSettings",
  orderSurveyEmailDetails = "orderSurveyEmailDetails",
  orderSurveyEmails = "orderSurveyEmails",
  mailTemplates = "mailTemplates",
  mailTemplateSenders = "mailTemplateSenders",
  optoutPageTemplate = "optoutPageTemplate",
  mailExclusionSettings = "mailExclusionSettings",
}

export enum PaginationDirection {
  left = "left",
  right = "right",
}

interface AppState {
  page: Page;
  sidebarCollapsed: boolean;
  paginationDirection: PaginationDirection;
  canPaginateLeft: boolean;
  canPaginateRight: boolean;
}

const initialState: AppState = {
  page: Page.publish,
  sidebarCollapsed: true,
  paginationDirection: PaginationDirection.right,
  canPaginateLeft: false,
  canPaginateRight: false,
};

export const slice = createSlice({
  name: "appState",
  initialState,
  reducers: {
    setPage: (state, action: PayloadAction<Page>) => {
      state.page = action.payload;
    },
    toggleSidebar: (state) => {
      state.sidebarCollapsed = !state.sidebarCollapsed;
    },
    setCanPaginateLeft: (state, action: PayloadAction<boolean>) => {
      state.canPaginateLeft = action.payload;
    },
    setCanPaginateRight: (state, action: PayloadAction<boolean>) => {
      state.canPaginateRight = action.payload;
    },
    resetPagination: (state) => {
      state.canPaginateLeft = false;
      state.canPaginateRight = false;
    },
  },
});

export const {
  setPage,
  toggleSidebar,
  resetPagination,
  setCanPaginateLeft,
  setCanPaginateRight,
} = slice.actions;

export default slice.reducer;
