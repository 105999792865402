import { AppDispatch, AppThunk, AsyncAppThunk } from "store/store";
import { httpWrapper } from "../../utils/http";
import { SMS_API_BASE, ecOptoutTypeStore } from "../../constants";
import { handleError } from "errors/errors";
import {
  listMailExclusionSettingsStart,
  listMailExclusionSettingsSuccess,
  listMailExclusionSettingsError,
  addMailExclusionSettingsStart,
  addMailExclusionSettingsSuccess,
  addMailExclusionSettingsError,
  updateMailExclusionSettingsStart,
  updateMailExclusionSettingsSuccess,
  updateMailExclusionSettingsError,
  editMailExclusionSettingsStart,
  editMailExclusionSettingsSuccess,
  editMailExclusionSettingsError,
  getMailExclusionSettingStart,
  getMailExclusionSettingSuccess,
  getMailExclusionSettingError,
} from "./MailExclusionSettingsSlice";
import {
  Client,
  ClientEcOptoutDetails,
  MailExclusionSettingsEditForm,
  MailExclusionSettingsUpdateForm,
} from "../../api/sms-api";
import i18n from "i18n";
import { showNotification } from "utils/notifications";

export const listMailExclusionSettings = (): AppThunk => async (
  dispatch: AppDispatch
) => {
  dispatch(listMailExclusionSettingsStart());
  try {
    const mailTemplates = await httpWrapper<Array<ClientEcOptoutDetails>>(
      "GET",
      `${SMS_API_BASE}/client_ec_optout/all_with_session`,
      { optoutType: ecOptoutTypeStore }
    );
    dispatch(listMailExclusionSettingsSuccess(mailTemplates));
  } catch (e) {
    handleError(e);
    dispatch(listMailExclusionSettingsError(e));
  }
};

export const addMailExclusionSettings = (
  params: MailExclusionSettingsUpdateForm
): AsyncAppThunk<void> => async (dispatch: AppDispatch) => {
  dispatch(addMailExclusionSettingsStart());
  try {
    await httpWrapper(
      "POST",
      `${SMS_API_BASE}/client_ec_optout/add_bulk`,
      params
    );

    showNotification(i18n.t("mailExclusionSettings.createSuccess"));
    dispatch(addMailExclusionSettingsSuccess());
    // Reload the mail exclusion settings
    dispatch(listMailExclusionSettings());
  } catch (e) {
    handleError(e);
    dispatch(addMailExclusionSettingsError(e));
  }
};

export const updateMailExclusionSettings = (
  params: MailExclusionSettingsUpdateForm
): AsyncAppThunk<void> => async (dispatch: AppDispatch) => {
  dispatch(updateMailExclusionSettingsStart());
  try {
    await httpWrapper(
      "PATCH",
      `${SMS_API_BASE}/client_ec_optout/update_bulk`,
      params
    );

    showNotification(i18n.t("mailExclusionSettings.updateSuccess"));
    dispatch(updateMailExclusionSettingsSuccess());
    // Reload the mail exclusion settings
    dispatch(listMailExclusionSettings());
  } catch (e) {
    handleError(e);
    dispatch(updateMailExclusionSettingsError(e));
  }
};

export const editMailExclusionSettings = (
  params: MailExclusionSettingsEditForm
): AsyncAppThunk<void> => async (dispatch: AppDispatch) => {
  dispatch(editMailExclusionSettingsStart());
  try {
    await httpWrapper(
      "PATCH",
      `${SMS_API_BASE}/client/update_mail_exclusion_setting`,
      params
    );

    showNotification(i18n.t("mailExclusionSettings.editSuccess"));
    dispatch(editMailExclusionSettingsSuccess());
  } catch (e) {
    handleError(e);
    dispatch(editMailExclusionSettingsError(e));
  }
};

export const getMailExclusionSetting = (): AppThunk => async (
  dispatch: AppDispatch
) => {
  dispatch(getMailExclusionSettingStart());
  try {
    const client = await httpWrapper<Client>(
      "GET",
      `${SMS_API_BASE}/client`,
      {}
    );
    dispatch(getMailExclusionSettingSuccess(client.mailExclusionSetting));
  } catch (e) {
    handleError(e);
    dispatch(getMailExclusionSettingError(e));
  }
};
