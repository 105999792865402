import React, { useState, useEffect, ReactElement } from "react";
import { Page, setPage } from "features/appstate/AppStateSlice";
import { RootState } from "store/store";
import { connect } from "react-redux";
import { useHistory, useLocation, RouteComponentProps } from "react-router-dom";
import {
  Table,
  Row,
  Col,
  Button,
  Spin,
  Alert,
  Popconfirm,
  Typography,
  Select,
} from "antd";
import i18n from "i18n";
import {
  Paginated,
  SurveyProductSettings,
  PaginationParams,
  SurveyProductsAddForm,
  ProductsDeleteForm,
  EcProvider,
} from "api/sms-api";
import {
  CheckOutlined,
  RollbackOutlined,
  ImportOutlined,
  ExportOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { MODE_SURVEY, surveyTypeStore, PAGE_LIMIT } from "../../constants";
import _ from "lodash";
import {
  setSelectedProducts,
  clearSelectedProducts,
  clearItems,
} from "./ProductSettingsSlice";
import ProductsSettingMail from "./ProductSettingsMail";
import AddProductDialog from "./AddProductDialog";
import {
  getSurveyProductSettings,
  addProductSettings,
  deleteProductSettings,
} from "./ProductSettingsActions";
import { getEcProviders } from "./EcProvidersActions";
import SMSLink from "components/SMSLink";
import qs from "qs";
import { pushTo } from "utils/navigation";
import { exportCsv } from "features/product_settings/ProductSettingsActions";
import SimplePagination, {
  PaginatedComponentProps,
} from "components/SimplePagination";

const { Text } = Typography;

interface ProductSettingsProps {
  items: Paginated<SurveyProductSettings>;
  listLoading: boolean;
  getSurveyProductSettings: (
    param: PaginationParams,
    ecProviderId?: string
  ) => void;
  clearItems: () => void;

  deleteProductSettings: (params: ProductsDeleteForm) => void;
  multipleDeleteFinished: boolean;
  multipleDeleteLoading: boolean;

  setPage: (page: Page) => void;

  selectedProducts: Array<SurveyProductSettings>;
  setSelectedProducts: (selected: Array<SurveyProductSettings>) => void;
  clearSelectedProducts: () => void;

  updateSurveyProductSettingsLoading: boolean;

  createLoading: boolean;

  addProductSettings: (params: Array<SurveyProductsAddForm>) => Promise<void>;

  exportCsv: () => void;

  getEcProviders: () => void;
  ecProviders: Array<EcProvider>;
}

const ProductSettings: React.FC<
  ProductSettingsProps & RouteComponentProps & PaginatedComponentProps
> = (props) => {
  const history = useHistory();
  const location = useLocation();
  const parsed = qs.parse(location.search, { ignoreQueryPrefix: true });
  const [surveyId, setSurveyId] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAddProdutModalOpen, setIsAddProductModalOpen] = useState(false);
  const [ecProviderId, setEcProviderId] = useState<string | undefined>(
    undefined
  );

  const emailsSet = new Set<string>();
  (props.items.items as SurveyProductSettings[])
    .filter((item) =>
      props.selectedProducts.some((selected) => selected.id === item.id)
    )
    .forEach((item) => {
      if (item.recipientEmail) {
        item.recipientEmail.forEach((email) => emailsSet.add(email));
      }
    });

  const emails = Array.from(emailsSet);

  useEffect(() => {
    props.setPage(Page.productSettings);
  }, [props.setPage]);

  useEffect(() => {
    const params = {
      ..._.omit(parsed, ["before", "after"]),
    };
    props.getSurveyProductSettings({ ...params, limit: PAGE_LIMIT });
  }, [props.getSurveyProductSettings]);

  React.useEffect(() => {
    return () => {
      props.clearItems();
      props.clearSelectedProducts();
    };
  }, []);

  useEffect(() => {
    if (props.multipleDeleteFinished && !props.multipleDeleteLoading) {
      props.clearSelectedProducts();
      const params = {
        ..._.omit(parsed, ["mode", "before", "after"]),
      };
      props.getSurveyProductSettings({ ...params, limit: PAGE_LIMIT });
    }
  }, [
    props.getSurveyProductSettings,
    props.multipleDeleteFinished,
    props.multipleDeleteLoading,
  ]);

  useEffect(() => {
    props.getEcProviders();
  }, [props.getEcProviders]);

  useEffect(() => {
    if (_.has(parsed, "surveyId")) {
      setSurveyId(parsed["surveyId"] as string);
    }
  }, []);

  if (props.listLoading || props.createLoading) {
    return <Spin />;
  }

  const columns = () => [
    {
      title: "",
      dataIndex: "surveyCount",
      key: "surveyCount",
      render: (_: any, items: SurveyProductSettings) => {
        let result = null;
        if (items.surveyCount > 0) {
          result = <CheckOutlined className="survey-table-publish" />;
        }
        return result;
      },
    },
    {
      title: i18n.t("productSettings.table.title.ecProviderId"),
      dataIndex: "ecProvider",
      key: "ecProvider",
      render: (_: any, item: SurveyProductSettings) => {
        return i18n.t(`ecLinkStatus.${item.ecProvider}`);
      },
    },
    {
      title: i18n.t("productSettings.table.title.productCode"),
      dataIndex: "productCode",
      key: "productCode",
      render: (title: string, record: SurveyProductSettings) => {
        return (
          <SMSLink
            to={`/${Page.productSettings}/edit`}
            queryParams={{
              id: record.id,
              type: "edit",
              mode: MODE_SURVEY,
              surveyType: parsed.surveyType || surveyTypeStore,
            }}
          >
            {title || i18n.t("productSettings.table.item.untitled")}
          </SMSLink>
        );
      },
    },
    {
      title: i18n.t("productSettings.table.title.productName"),
      dataIndex: "productName",
      key: "productName",
    },
    {
      title: i18n.t("productSettings.table.title.reviewPageUrl"),
      dataIndex: "reviewPageUrl",
      key: "reviewPageUrl",
    },
    {
      title: i18n.t("productSettings.table.title.recipientEmail"),
      dataIndex: "recipientEmail",
      key: "recipientEmail",
      render: (_: any, items: SurveyProductSettings) => {
        if (!items.recipientEmail) {
          return null;
        }
        return items.recipientEmail.map((item, index) => {
          return <div key={index}>{item}</div>;
        });
      },
    },
  ];

  const selectedRowKeys = props.selectedProducts.map(({ id }) => id);

  const handleSelect = (record: SurveyProductSettings, isSelected: boolean) => {
    if (isSelected) {
      // Adding
      const newSelected = [
        ...props.selectedProducts,
        {
          id: record.id,
          clientId: record.clientId,
          productCode: record.productCode,
          productName: record.productName,
          reviewPageUrl: record.reviewPageUrl,
          recipientEmail: [],
          surveyCount: record.surveyCount,
          ecProviderId: record.ecProviderId,
          ecProvider: record.ecProvider,
          createdAt: record.createdAt,
          updatedAt: record.updatedAt,
        },
      ];
      props.setSelectedProducts(newSelected);
    } else {
      // Removing
      let current = [...props.selectedProducts];
      current = current.filter((c) => c.id !== record.id);
      props.setSelectedProducts(current);
    }
  };

  const handleSelectAll = (
    isSelected: boolean,
    changeRows: Array<SurveyProductSettings>
  ) => {
    if (isSelected) {
      // Add all
      const newSelections = changeRows.map((row) => {
        return {
          id: row.id,
          clientId: row.clientId,
          productCode: row.productCode,
          productName: row.productName,
          reviewPageUrl: row.reviewPageUrl,
          recipientEmail: row.recipientEmail,
          surveyCount: row.surveyCount,
          ecProviderId: row.ecProviderId,
          ecProvider: row.ecProvider,
          createdAt: row.createdAt,
          updatedAt: row.updatedAt,
        };
      });

      // Add the newly selected rows, deduplicating by key
      const newResult = _.uniqBy(
        [...props.selectedProducts, ...newSelections],
        "id"
      );
      props.setSelectedProducts(newResult);
    } else {
      // Remove all
      props.setSelectedProducts([]);
    }
  };

  const rowSelection = {
    selectedRowKeys,
    onSelect: handleSelect,
    onSelectAll: handleSelectAll,
    preserveSelectedRowKeys: true,
  };

  const onClickSetEmailButton = () => {
    setIsModalOpen(true);
  };

  const onClickAddProductButton = () => {
    setIsAddProductModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const closeAddProductModal = () => {
    setIsAddProductModalOpen(false);
  };

  const handleClickExport = () => {
    props.exportCsv();
  };

  const handleDelete = () => {
    const selectedIds: Array<string> = props.selectedProducts.map((s) => s.id!);
    const form: ProductsDeleteForm = {
      clientNextEngineProductIds: selectedIds,
    };
    props.deleteProductSettings(form);
  };

  const pagination = (
    <SimplePagination
      items={props.items.items}
      getCursor={(model: SurveyProductSettings) => model.createdAt}
      sortDescending={true}
      hasRight={props.items.hasRight}
      hasLeft={props.items.hasLeft}
      location={props.location}
      history={history}
      match={props.match}
    />
  );

  const finishSelection = async (save: boolean) => {
    if (!surveyId) return;

    if (save) {
      const form = props.selectedProducts.map((item) => ({
        surveysId: surveyId,
        clientNextEngineProductId: item.id,
      }));

      await props.addProductSettings(form);
    }

    pushTo(history, `/${Page.products}`, {
      id: surveyId,
      mode: MODE_SURVEY,
      surveyType: parsed.surveyType || surveyTypeStore,
    });
  };

  const selectEcProviders = (value: string) => {
    setEcProviderId(value);
    const params = {
      ..._.omit(parsed, ["before", "after"]),
    };

    if (value) {
      props.getSurveyProductSettings({ ...params, limit: PAGE_LIMIT }, value);
    } else {
      props.getSurveyProductSettings({ ...params, limit: PAGE_LIMIT });
    }
  };

  let selectingExplanation: ReactElement | null = null;
  if (surveyId) {
    selectingExplanation = (
      <Alert
        message={
          <div>
            <div>
              <span>
                {i18n.t("productSettings.table.settings.selectingExplanation")}
              </span>
            </div>
            <div style={{ marginTop: "1em" }}>
              <Button
                type="primary"
                icon={<CheckOutlined />}
                onClick={() => finishSelection(true)}
                style={{ marginRight: "1em" }}
                disabled={props.createLoading}
              >
                {i18n.t("common.select")}
              </Button>
              <Button
                icon={<RollbackOutlined />}
                onClick={() => finishSelection(false)}
              >
                {i18n.t("common.back")}
              </Button>
            </div>
          </div>
        }
        type="info"
        style={{ marginBottom: "2em" }}
      />
    );
  }

  return (
    <div>
      <div className="table-button-row">
        {selectingExplanation}
        <Row>
          <Col xs={20} md={12} lg={8}>
            <Text>{i18n.t("productSettings.table.filter.ecProviders")}</Text>
            <Select
              value={ecProviderId}
              allowClear
              showSearch
              optionFilterProp="label"
              size="middle"
              dropdownMatchSelectWidth={false}
              options={props.ecProviders
                .map((item) => {
                  return {
                    value: item.id,
                    label: item.name,
                  };
                })
                .sort((a, b) => a.label.localeCompare(b.label))}
              className="meo-businesses-select"
              onChange={selectEcProviders}
            />
          </Col>
        </Row>
        <Row justify="space-between">
          <Col>
            <div className="form-button-row">
              <Button type="primary" onClick={onClickAddProductButton}>
                {i18n.t("productSettings.table.settings.add")}
              </Button>
              <Button
                type="primary"
                onClick={onClickSetEmailButton}
                disabled={
                  !(props.selectedProducts.length > 0) || surveyId !== null
                }
              >
                {i18n.t("productSettings.table.settings.recipientEmail")}
              </Button>
              <Popconfirm
                title={i18n.t("products.table.settings.deleteCountConfirm", {
                  count: props.selectedProducts.length,
                })}
                disabled={props.selectedProducts.length < 1}
                onConfirm={handleDelete}
                okText={i18n.t("common.delete")}
                cancelText={i18n.t("common.cancel")}
                okType="danger"
              >
                <Button
                  danger={props.selectedProducts.length > 0}
                  disabled={props.selectedProducts.length < 1}
                  icon={<DeleteOutlined />}
                >
                  {i18n.t("common.delete")}
                </Button>
              </Popconfirm>
              <ProductsSettingMail
                existingParams={parsed}
                emails={emails}
                visible={isModalOpen}
                closeModal={closeModal}
              />
              <AddProductDialog
                existingParams={parsed}
                visible={isAddProdutModalOpen}
                closeModal={closeAddProductModal}
              />
            </div>
          </Col>
          <Col>
            <div className="form-button-row">
              <Button
                icon={<ExportOutlined />}
                onClick={handleClickExport}
                disabled={surveyId !== null}
              >
                {i18n.t("common.csvExport")}
              </Button>
              <SMSLink
                to={`/${Page.productSettings}/import`}
                queryParams={{
                  mode: MODE_SURVEY,
                  surveyType: parsed.surveyType || surveyTypeStore,
                }}
              >
                <Button icon={<ImportOutlined />} disabled={surveyId !== null}>
                  {i18n.t("productSettings.table.settings.import")}
                </Button>
              </SMSLink>
            </div>
          </Col>
        </Row>
      </div>
      <Table
        rowSelection={rowSelection}
        dataSource={props.items.items}
        columns={columns()}
        rowKey={(items: SurveyProductSettings) => items.id}
        size="middle"
        pagination={false}
      />
      <Row className="pagination-row">{pagination}</Row>
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    items: state.productSettings.items,
    listLoading: state.productSettings.listLoading,
    selectedProducts: state.productSettings.selectedProducts,
    updateSurveyProductSettingsLoading:
      state.productSettings.updateSurveyProductSettingsLoading,
    createLoading: state.productSettings.createLoading,
    multipleDeleteFinished: state.productSettings.multipleDeleteFinished,
    multipleDeleteLoading: state.productSettings.multipleDeleteLoading,
    ecProviders: state.ecProviders.items,
  };
};

const mapDispatchToProps = {
  setPage,
  getSurveyProductSettings,
  deleteProductSettings,
  clearItems,
  setSelectedProducts,
  clearSelectedProducts,
  addProductSettings,
  exportCsv,
  getEcProviders,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductSettings);
