import React, { useEffect, useState, useRef } from "react";
import { Page, setPage } from "features/appstate/AppStateSlice";
import { RootState } from "store/store";
import { connect } from "react-redux";
import { RouteComponentProps, useLocation, useHistory } from "react-router-dom";
import { Row, Spin, Col, Button } from "antd";
import i18n from "i18n";
import qs from "qs";
import {
  SSOClient,
  ClientEcLogoDetails,
  ClientPlanDetails,
  ClientAllSuggestionsCount,
  ContractDetailsGetParam,
  ClientEcLogoEditForm,
} from "api/sms-api";
import {
  getClientAllSuggestionsCount,
  getClientPlanDetails,
  getClientEcLogoDetails,
  createClientEcLogoDetails,
  editClientEcLogoDetails,
} from "./ContractDetailsActions";
import { clearClientEcLogo } from "./ContractDetailsSlice";
import { surveyTypeEc, MODE_SURVEY, surveyTypeStore } from "../../constants";
import { Formik } from "formik";
import { Form, Input } from "formik-antd";
import { CloseOutlined } from "@ant-design/icons";
import SaveButton from "components/SaveButton";
import { getFileAsDataUrl } from "utils/files";
import { pushTo } from "utils/navigation";

interface ContractDetailsProps {
  clientPlanDetails: ClientPlanDetails;
  clientAllSuggestionsCount: ClientAllSuggestionsCount;
  getClientPlanDetailsLoading: boolean;
  getClientAllSuggestionsLoading: boolean;
  ssoClient?: SSOClient;
  getClientAllSuggestionsCount: (param: ContractDetailsGetParam) => void;
  getClientPlanDetails: () => void;
  clientEcLogoDetails?: ClientEcLogoDetails;
  getClientEcLogoDetails: () => void;
  getClientEcLogoDetailsLoading: boolean;
  createClientEcLogoDetailsLoading: boolean;
  createClientEcLogoDetails: (
    params: ClientEcLogoEditForm,
    file: File | null
  ) => void;
  editClientEcLogoDetailsLoading: boolean;
  editClientEcLogoDetails: (
    id: string,
    params: ClientEcLogoEditForm,
    file: File | null
  ) => void;
  clearClientEcLogo: () => void;

  setPage: (page: Page) => void;
}

const ContractDetails: React.FC<ContractDetailsProps & RouteComponentProps> = (
  props
) => {
  useEffect(() => {
    props.setPage(Page.contractDetails);
  }, [props.setPage]);
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1;
  const history = useHistory();
  const location = useLocation();
  const parsed = qs.parse(location.search, { ignoreQueryPrefix: true });
  const [storeLogoUrl, setStoreLogoUrl] = useState<string>("");
  const [storeLogoFile, setStoreLogoFile] = useState<File | null>(null);
  const storeLogoInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const param = {
      year: currentYear,
      month: currentMonth,
    };

    props.getClientAllSuggestionsCount(param);
  }, [props.getClientAllSuggestionsCount]);

  React.useEffect(() => {
    props.getClientPlanDetails();
  }, [props.getClientPlanDetails]);

  React.useEffect(() => {
    if (parsed.surveyType === surveyTypeEc) {
      props.getClientEcLogoDetails();
    }
  }, [props.getClientEcLogoDetails]);

  if (
    (props.getClientAllSuggestionsLoading &&
      props.getClientPlanDetailsLoading) ||
    props.getClientEcLogoDetailsLoading
  ) {
    return <Spin />;
  }

  const handleSubmit = async (form: ClientEcLogoEditForm) => {
    if (!storeLogoUrl) {
      form.storeLogoUrl = undefined;
    }
    if (props.clientEcLogoDetails && props.clientEcLogoDetails.id) {
      await props.editClientEcLogoDetails(
        props.clientEcLogoDetails.id,
        form,
        storeLogoFile
      );
    } else {
      await props.createClientEcLogoDetails(form, storeLogoFile);
    }
    pushTo(history, "/survey", {
      mode: MODE_SURVEY,
      surveyType: parsed.surveyType || surveyTypeStore,
    });
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }

    const file = e.target.files[0];
    // Get the file contents as a Base64 data URL string for the preview
    const result = await getFileAsDataUrl(file);

    if (result) {
      setStoreLogoUrl(result);
    }
    // Store the file for upload later
    setStoreLogoFile(file);
  };

  const handleFileRemove = async () => {
    if (storeLogoInputRef.current) {
      storeLogoInputRef.current.value = "";
    }
    setStoreLogoUrl("");
    await props.clearClientEcLogo();
  };

  const displayLogoUrl =
    props.clientEcLogoDetails?.storeLogoUrl || storeLogoUrl;

  const initialValues = {
    storeName: props.clientEcLogoDetails?.storeName || "",
    storeLogoUrl: displayLogoUrl,
  };

  let ecDetails = null;
  if (parsed.surveyType === surveyTypeEc) {
    ecDetails = (
      <Row>
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
          {() => {
            return (
              <Form layout="vertical">
                <Row>
                  <Col span={18}>
                    <Form.Item
                      name="storeName"
                      label={i18n.t("contractDetails.storeName")}
                    >
                      <Input name="storeName" />
                    </Form.Item>
                    <Form.Item
                      name="storeLogoUrl"
                      label={i18n.t("contractDetails.storeLogo")}
                    >
                      <input
                        type="file"
                        onChange={handleFileChange}
                        ref={storeLogoInputRef}
                      />
                      {displayLogoUrl && (
                        <Row align="top">
                          <img
                            style={{ marginTop: "1em", maxWidth: "90%" }}
                            src={displayLogoUrl}
                          />
                          <Button
                            shape="circle"
                            icon={<CloseOutlined />}
                            type="text"
                            style={{ marginTop: "0.5em" }}
                            onClick={handleFileRemove}
                          />
                        </Row>
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row justify="end" style={{ marginTop: "2.5em" }}>
                  <SaveButton />
                </Row>
              </Form>
            );
          }}
        </Formik>
      </Row>
    );
  }

  return (
    <div>
      <div className="table-button-row">
        <Row>
          {i18n.t("contractDetails.company")}：{props.ssoClient?.clientName}
        </Row>
        <Row>
          {i18n.t("contractDetails.account")}：{props.ssoClient?.clientToken}
        </Row>
        <Row>
          {i18n.t("contractDetails.plan")}：{props.clientPlanDetails.plan}
          {i18n.t("contractDetails.month", {
            month: props.clientPlanDetails.value,
          })}
        </Row>
        <Row>
          {i18n.t("contractDetails.count")}：
          {props.clientAllSuggestionsCount.count}/
          {props.clientPlanDetails.value}
        </Row>
      </div>
      {ecDetails}
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    clientPlanDetails: state.contractDetails.clientPlanDetails,
    clientAllSuggestionsCount: state.contractDetails.clientAllSuggestionsCount,
    getClientPlanDetailsLoading:
      state.contractDetails.getClientPlanDetailsLoading,
    getClientAllSuggestionsLoading:
      state.contractDetails.getClientPlanDetailsLoading,
    ssoClient: state.client.ssoClient,
    getClientEcLogoDetailsLoading:
      state.contractDetails.getClientEcLogoDetailsLoading,
    createClientEcLogoDetailsLoading:
      state.contractDetails.createClientEcLogoDetailsLoading,
    editClientEcLogoDetailsLoading:
      state.contractDetails.editClientEcLogoDetailsLoading,
    clientEcLogoDetails: state.contractDetails.clientEcLogoDetails,
  };
};

const mapDispatchToProps = {
  setPage,
  getClientPlanDetails,
  getClientAllSuggestionsCount,
  getClientEcLogoDetails,
  createClientEcLogoDetails,
  editClientEcLogoDetails,
  clearClientEcLogo,
};

export default connect(mapStateToProps, mapDispatchToProps)(ContractDetails);
