import React, { ReactElement, useState } from "react";
import { connect } from "react-redux";
import { getMEOSite } from "features/seo/SEOActions";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useParams,
} from "react-router-dom";
import { RootState } from "store/store";
import { Layout, Spin, Row, Card, Col, Alert, Button } from "antd";
import { Page } from "features/appstate/AppStateSlice";
import {
  UsageStatistics,
  Client,
  SSOClient,
  MEOSite,
  Surveys,
} from "api/sms-api";
const { Content } = Layout;
import AppHeader from "components/AppHeader";
import Sidebar from "components/Sidebar";
import SidebarSurvey from "components/SidebarSurvey";
import BackArrowButton from "components/BackArrowButton";
import Addresses from "./features/address/Addresses";
import Group from "./features/group/Group";
import GroupAddresses from "./features/group/GroupAddresses";
import GroupAdd from "./features/group/GroupAdd";
import GroupEdit from "./features/group/GroupEdit";
import Scheduleds from "./features/schedule/Schedule";
import ScheduleEdit from "./features/schedule/ScheduleEdit";
import Templates from "./features/templates/Templates";
import {
  getCardLayout,
  BILLING_BASE,
  APP_VERSION,
  MODE_SURVEY,
  surveyTypeStore,
} from "./constants";
import i18n from "i18n";
import History from "./features/history/History";
import HistoryContacts from "./features/history/HistoryContacts";
import HistoryMessage from "./features/history/HistoryMessage";
import AddressAdd from "./features/address/AddressAdd";
import TemplateAdd from "./features/templates/TemplateAdd";
import TemplateEdit from "./features/templates/TemplateEdit";
import SendForm from "components/SendForm";
import ClientEdit from "./features/client/ClientEdit";
import {
  getClient,
  getSSOClient,
  getUsageStatistics,
} from "./features/client/ClientActions";
import { getClientType } from "./features/contract_details/ContractDetailsActions";
import ScheduledMessage from "features/schedule/ScheduledMessage";
import ScheduledContacts from "features/schedule/ScheduledContacts";
import ScheduledGroups from "features/schedule/ScheduledGroups";
import LandingPageSettings from "./features/landing_page_settings/LandingPageSettings";
import { CustomError, ErrorWrapper } from "errors/errors";
import TRUSTe from "./TRUSTe.png";
import NotFound from "components/NotFound";
import ReviewLinkAdd from "features/review_link/ReviewLinkAdd";
import ReviewLinkEdit from "features/review_link/ReviewLinkEdit";
import qs from "qs";
import SMSRedirect from "components/SMSRedirect";
import MeoSiteSelector from "components/MeoSiteSelector";
import Statistics from "./features/statistics/Statistics";
import AddressImport from "features/address/AddressImport";
import LandingPageSettingsAdd from "features/landing_page_settings/LandingPageSettingsAdd";
import LandingPageSettingsDetails from "features/landing_page_settings/LandingPageSettingsDetails";
import ReviewList from "features/reviews/ReviewList";
import ReviewDetails from "features/reviews/ReviewDetails";
import TagManager from "react-gtm-module";
import AddressDetails from "features/address/AddressDetails";
import ReplyTemplates from "features/reply_templates/ReplyTemplates";
import ReplyTemplateAdd from "features/reply_templates/ReplyTemplateAdd";
import ReplyTemplateEdit from "features/reply_templates/ReplyTemplateEdit";
import ReviewReplyMulti from "features/reviews/ReviewReplyMulti";
import { ContainerOutlined, CloseOutlined } from "@ant-design/icons";
import Survey from "./features/survey/SurveyList";
import SurveyAdd from "features/survey/SurveyAdd";
import SurveyEdit from "features/survey/SurveyEdit";
import SurveyTemplate from "features/survey/SurveyTemplate";
import SurveySettings from "features/survey/SurveySettings";
import ReviewMediaSettings from "features/survey/ReviewMediaSettings";
import SurveyMeoSites from "features/survey_meo_sites/SurveyMeoSitesList";
import MeoSiteSettings from "./features/meo_site_settings/MeoSiteSettings";
import ContractDetails from "./features/contract_details/ContractDetalis";
import MeoSiteSettingsImport from "features/meo_site_settings/RecipientEmailCsvImport";
import SurveyMeoSitesCsvImport from "features/survey_meo_sites/SurveyMeoSitesCsvImport";
import ProductSettings from "./features/product_settings/ProductSettings";
import ProductSettingsImport from "features/product_settings/RecipientEmailCsvImport";
import ProductEdit from "features/product_settings/EditProduct";
import SurveyProducts from "features/survey_products/SurveyProductsList";
import SurveyProductsCsvImport from "features/survey_products/SurveyProductsCsvImport";
import EcLinkStatus from "features/ec_link_status/EcLinkStatus";
import OrderHistory from "features/order_history/OrderHistory";
import OrderHistoryImport from "features/order_history/OrderHistoryCsvImport";
import EcEmailSettings from "features/survey/EcEmailSettings";
import SurveyEmailDetails from "features/order_history/SurveyEmailDetails";
import OrderSurveyEmails from "features/order_survey_emails/OrderSurveyEmails";
import MailTemplates from "features/mail_template/MailTemplates";
import MailTemplateAdd from "features/mail_template/MailTemplateAdd";
import MailTemplateSenders from "features/mail_template_sender/MailTemplateSenders";
import OptoutPageTemplate from "features/optout_page_template/OptoutPageTemplate";
import MailExclusionSettings from "features/mail_exclusion_settings/MailExclusionSettings";
import LinkButton from "components/LinkButton";

interface AppProps {
  page: Page;
  client?: Client;
  clientLoading: boolean;
  meoSite?: MEOSite;
  meoSiteLoading: boolean;
  getMEOSite: (meoSiteId: number) => void;
  getClient: () => void;
  ssoClient?: SSOClient;
  ssoClientLoading: boolean;
  getSSOClient: () => Promise<SSOClient | undefined>;
  statistics?: UsageStatistics;
  statisticsLoading: boolean;
  getUsageStatistics: (meoSiteId: number) => void;
  survey: Surveys;
  clientError?: ErrorWrapper;
  seoError?: ErrorWrapper;
  getClientType: () => void;
  clientType: string;
  getClientTypeLoading: boolean;
}

const App: React.FC<AppProps> = (props: AppProps) => {
  // Fetch the user whenever the app is loaded to make sure that they're logged
  // in (if they're not, the API will return a 401 and they'll get redirected
  // to the SSO login page due to the check in the UI's httpWrapper).
  React.useEffect(() => {
    props.getClient();
  }, [props.getClient]);

  React.useEffect(() => {
    props.getSSOClient().then((ssoClient) => {
      if (ssoClient) {
        const tagManagerArgs = {
          dataLayer: {
            clientToken: ssoClient.clientToken,
            clientName: ssoClient.clientName,
            userToken: ssoClient.userToken,
            userName: ssoClient.userName,
          },
        };
        TagManager.dataLayer(tagManagerArgs);
        if (!props.clientType && !props.getClientTypeLoading) {
          props.getClientType();
        }
      }
    });
  }, [
    props.getSSOClient,
    props.clientType,
    props.getClientTypeLoading,
    props.getClientType,
  ]);

  React.useEffect(() => {
    const parsed = qs.parse(location.search, { ignoreQueryPrefix: true });
    const meoSiteId = parseInt(parsed["meo_site_id"] as string, 10);
    if (meoSiteId) {
      props.getMEOSite(meoSiteId);
    }
  }, [location, props.getMEOSite]);

  React.useEffect(() => {
    if (props.meoSite) {
      props.getUsageStatistics(props.meoSite.id);
    }
  }, [props.meoSite, props.getUsageStatistics]);

  const [displayHelp, setDisplayHelp] = useState(
    sessionStorage.getItem("help_display") === null
  );

  const parsed = qs.parse(location.search, { ignoreQueryPrefix: true });
  const mode = parsed["mode"];
  const [displaySurvey, setDisplaySurvey] = useState(mode === MODE_SURVEY);

  // Show a spinner until all initial loading is complete
  let content = (
    <Row justify="center" align="middle" style={{ minHeight: "100vh" }}>
      <Spin size="large" />
    </Row>
  );

  const footer = (
    <Row className="footer">
      <Col xs={24} md={{ offset: 8, span: 8 }}>
        <p>Gyro-n</p>
        <span className="copyright">Copyright © UNIVA Gyro-n Limited.</span>
      </Col>
      <Col xs={24} md={{ span: 8 }}>
        <a
          href="https://www.truste.or.jp/hssl/validate/01511.php"
          target="_blank"
          rel="noreferrer noopener"
        >
          <img className="trust-e" src={TRUSTe} />
        </a>
      </Col>
    </Row>
  );

  // Not used right now, put back in somewhere?
  /*eslint-disable */
  const billingLink = (
    <a
      href={`${BILLING_BASE}/option/seo/input.html`}
      target="_blank"
      rel="noopener noreferrer"
    >
      {i18n.t("errors.noContractLink")}
    </a>
  );
  /*eslint-enable */

  const onClickClose = () => {
    sessionStorage.setItem("help_display", "false");
    setDisplayHelp(false);
  };

  const onClickSurveyLink = () => {
    setDisplaySurvey(true);
  };

  const otherContent = (title: string, body: ReactElement) => {
    const cardLayout = getCardLayout(props.page);
    // Determine the survey type
    const surveyType = props.clientType || surveyTypeStore;
    return (
      <Layout>
        {props.ssoClient && <AppHeader ssoClient={props.ssoClient} />}
        <Layout className="main-layout">
          <Content>
            {props.ssoClient && props.ssoClient.admin && (
              <Row justify="end">
                <LinkButton
                  pathName={`/${Page.survey}?mode=survey&surveyType=${surveyType}`}
                  buttonName={i18n.t("menu.survey")}
                  handleClick={onClickSurveyLink}
                />
              </Row>
            )}
            <Row>
              <Col {...cardLayout}>
                <Card className="content-card" title={title}>
                  {body}
                </Card>
                {footer}
              </Col>
            </Row>
          </Content>
        </Layout>
      </Layout>
    );
  };

  if (
    !props.clientLoading &&
    !props.ssoClientLoading &&
    !props.meoSiteLoading &&
    props.client
  ) {
    if (props.clientError || props.seoError) {
      if (
        props.clientError?.error !== CustomError.Unauthed &&
        props.seoError?.error !== CustomError.Unauthed
      ) {
        let message = i18n.t("errors.unknown");
        if (props.seoError?.error === CustomError.MeoSiteNotEnabled) {
          message = i18n.t("errors.smsNotEnabled");
        } else if (props.seoError?.error === CustomError.NotFound) {
          message = i18n.t("common.notFound");
        }
        content = otherContent(
          i18n.t("errors.unknown"),
          <Alert type="error" message={message} />
        );
      }
    } else if (!props.meoSite && !displaySurvey) {
      content = otherContent(i18n.t("siteSelector.title"), <MeoSiteSelector />);
    } else if (
      props.client &&
      props.ssoClient &&
      props.ssoClient.admin &&
      displaySurvey
    ) {
      const cardLayout = {
        xs: { span: 24 },
        md: { span: 24 },
        lg: { span: 24 },
        xl: { span: 19, offset: 3 },
      };
      const title =
        props.page === Page.surveyEdit && props.survey
          ? `${i18n.t(`menu.${props.page}`)}：${props.survey.title}`
          : i18n.t(`menu.${props.page}`);
      content = (
        <Layout>
          <AppHeader
            client={props.client}
            ssoClient={props.ssoClient}
            statistics={props.statistics}
            survey={true}
          />
          <Layout className="main-layout">
            <SidebarSurvey
              surveyType={
                parsed.surveyType
                  ? (parsed.surveyType as string)
                  : surveyTypeStore
              }
            />
            <Layout>
              <Content>
                <Row>
                  <Col {...cardLayout}>
                    {props.page !== Page.survey &&
                    props.page !== Page.surveyEdit &&
                    props.page !== Page.meoSiteSettings &&
                    props.page !== Page.contractDetails ? (
                      <Row style={{ marginTop: "5%" }}>
                        <BackArrowButton
                          url={
                            props.page === Page.meoSites
                              ? `/${Page.survey}`
                              : ""
                          }
                        />
                      </Row>
                    ) : null}
                    <Card className="content-card" title={title}>
                      <Switch>
                        <Route
                          exact
                          path={`/${Page.survey}`}
                          component={Survey}
                        />
                        <Route
                          exact
                          path={`/${Page.survey}/add`}
                          component={SurveyAdd}
                        />
                        <Route
                          exact
                          path={`/${Page.survey}/edit`}
                          component={SurveyEdit}
                        />
                        <Route
                          exact
                          path={`/${Page.survey}/settings`}
                          component={SurveySettings}
                        />
                        <Route
                          exact
                          path={`/${Page.survey}/template`}
                          component={SurveyTemplate}
                        />
                        <Route
                          exact
                          path={`/${Page.survey}/reviewMediaSettings`}
                          component={ReviewMediaSettings}
                        />
                        <Route
                          exact
                          path={`/${Page.meoSites}`}
                          component={SurveyMeoSites}
                        />
                        <Route
                          exact
                          path={`/${Page.meoSites}/import`}
                          component={SurveyMeoSitesCsvImport}
                        />
                        <Route
                          exact
                          path={`/${Page.meoSiteSettings}`}
                          component={MeoSiteSettings}
                        />
                        <Route
                          exact
                          path={`/${Page.meoSiteSettings}/import`}
                          component={MeoSiteSettingsImport}
                        />
                        <Route
                          exact
                          path={`/${Page.contractDetails}`}
                          component={ContractDetails}
                        />
                        <Route
                          exact
                          path={`/${Page.productSettings}`}
                          component={ProductSettings}
                        />
                        <Route
                          exact
                          path={`/${Page.productSettings}/import`}
                          component={ProductSettingsImport}
                        />
                        <Route
                          exact
                          path={`/${Page.productSettings}/edit`}
                          component={ProductEdit}
                        />
                        <Route
                          exact
                          path={`/${Page.products}`}
                          component={SurveyProducts}
                        />
                        <Route
                          exact
                          path={`/${Page.products}/import`}
                          component={SurveyProductsCsvImport}
                        />
                        <Route
                          exact
                          path={`/${Page.ecLinkStatus}`}
                          component={EcLinkStatus}
                        />
                        <Route
                          exact
                          path={`/${Page.orderHistory}`}
                          component={OrderHistory}
                        />
                        <Route
                          exact
                          path={`/${Page.orderHistory}/import`}
                          component={OrderHistoryImport}
                        />
                        <Route
                          exact
                          path={`/${Page.survey}/ecEmailSettings`}
                          component={EcEmailSettings}
                        />
                        <Route
                          exact
                          path={`/${Page.orderHistory}/orderSurveyEmailDetails`}
                          component={SurveyEmailDetails}
                        />
                        <Route
                          exact
                          path={`/${Page.orderSurveyEmails}`}
                          component={OrderSurveyEmails}
                        />
                        <Route
                          exact
                          path={`/${Page.mailTemplates}`}
                          component={MailTemplates}
                        />
                        <Route
                          exact
                          path={`/${Page.mailTemplates}/settings`}
                          component={MailTemplateAdd}
                        />
                        <Route
                          exact
                          path={`/${Page.mailTemplateSenders}`}
                          component={MailTemplateSenders}
                        />
                        <Route
                          exact
                          path={`/${Page.optoutPageTemplate}`}
                          component={OptoutPageTemplate}
                        />
                        <Route
                          exact
                          path={`/${Page.mailExclusionSettings}`}
                          component={MailExclusionSettings}
                        />
                        <Route component={NotFound} />
                      </Switch>
                    </Card>
                    {footer}
                  </Col>
                </Row>
              </Content>
            </Layout>
          </Layout>
          <div style={{ display: "none" }}>{APP_VERSION}</div>
        </Layout>
      );
    } else if (props.client && props.ssoClient) {
      // Otherwise, show the main app
      const cardLayout = getCardLayout(props.page);
      content = (
        <Layout>
          <AppHeader
            client={props.client}
            ssoClient={props.ssoClient}
            statistics={props.statistics}
          />
          <Layout className="main-layout">
            <Sidebar />
            <Layout>
              <Content>
                <Row>
                  <Col {...cardLayout}>
                    <Card
                      className="content-card"
                      title={i18n.t(`menu.${props.page}`)}
                    >
                      <Switch>
                        <Route
                          exact
                          path="/"
                          render={() => <SMSRedirect to={`/${Page.publish}`} />}
                        />
                        <Route
                          exact
                          path={`/${Page.publish}/`}
                          component={SendForm}
                        />
                        <Route
                          exact
                          path={`/${Page.addresses}`}
                          component={Addresses}
                        />
                        <Route
                          exact
                          path={`/${Page.addresses}/add`}
                          component={AddressAdd}
                        />
                        <Route
                          exact
                          path={`/${Page.addresses}/import`}
                          component={AddressImport}
                        />
                        <Route
                          exact
                          path={`/${Page.addresses}/:id`}
                          component={() => {
                            const { id } = useParams() as any;
                            return (
                              <SMSRedirect
                                to={`/${Page.addresses}/${id}/edit`}
                              />
                            );
                          }}
                        />
                        <Route
                          exact
                          path={`/${Page.addresses}/:id/:key`}
                          component={AddressDetails}
                        />
                        <Route
                          exact
                          path={`/${Page.group}`}
                          component={Group}
                        />
                        <Route
                          exact
                          path={`/${Page.group}/add`}
                          component={GroupAdd}
                        />
                        <Route
                          exact
                          path={`/${Page.group}/edit/:id`}
                          component={GroupEdit}
                        />
                        <Route
                          exact
                          path={`/${Page.group}/:id`}
                          component={GroupAddresses}
                        />
                        <Route
                          exact
                          path={`/${Page.scheduled}`}
                          component={Scheduleds}
                        />
                        <Route
                          exact
                          path={`/${Page.scheduled}/:id`}
                          component={ScheduleEdit}
                        />
                        <Route
                          exact
                          path={`/${Page.scheduled}/:id/contacts`}
                          component={ScheduledContacts}
                        />
                        <Route
                          exact
                          path={`/${Page.scheduled}/:id/groups`}
                          component={ScheduledGroups}
                        />
                        <Route
                          exact
                          path={`/${Page.scheduled}/:id/message`}
                          component={ScheduledMessage}
                        />
                        <Route
                          exact
                          path={`/${Page.templates}`}
                          component={Templates}
                        />
                        <Route
                          exact
                          path={`/${Page.templates}/add`}
                          component={TemplateAdd}
                        />
                        <Route
                          exact
                          path={`/${Page.templates}/:id`}
                          component={TemplateEdit}
                        />
                        <Route
                          exact
                          path={`/${Page.replyTemplates}`}
                          component={ReplyTemplates}
                        />
                        <Route
                          exact
                          path={`/${Page.replyTemplates}/add`}
                          component={ReplyTemplateAdd}
                        />
                        <Route
                          exact
                          path={`/${Page.replyTemplates}/:id`}
                          component={ReplyTemplateEdit}
                        />
                        <Route
                          exact
                          path={`/${Page.history}`}
                          component={History}
                        />
                        <Route
                          exact
                          path={`/${Page.history}/:id/contacts`}
                          component={HistoryContacts}
                        />
                        <Route
                          exact
                          path={`/${Page.history}/:id/message`}
                          component={HistoryMessage}
                        />
                        <Route
                          exact
                          path={`/${Page.client}`}
                          component={ClientEdit}
                        />
                        <Route
                          exact
                          path={`/${Page.landingPageSettings}`}
                          component={LandingPageSettings}
                        />
                        <Route
                          exact
                          path={`/${Page.landingPageSettings}/add`}
                          component={LandingPageSettingsAdd}
                        />
                        <Route
                          exact
                          path={`/${Page.landingPageSettings}/:id`}
                          component={() => {
                            const { id } = useParams() as any;
                            return (
                              <SMSRedirect
                                to={`/${Page.landingPageSettings}/${id}/settings`}
                              />
                            );
                          }}
                        />
                        <Route
                          exact
                          path={`/${Page.landingPageSettings}/:id/:key`}
                          component={LandingPageSettingsDetails}
                        />
                        <Route
                          exact
                          path={`/${Page.landingPageSettings}/:lpsId/reviewLinks/add`}
                          component={ReviewLinkAdd}
                        />
                        <Route
                          exact
                          path={`/${Page.landingPageSettings}/:lpsId/reviewLinks/:id`}
                          component={ReviewLinkEdit}
                        />
                        <Route
                          exact
                          path={`/${Page.statistics}`}
                          component={Statistics}
                        />
                        <Route
                          exact
                          path={`/${Page.reviews}`}
                          component={ReviewList}
                        />
                        <Route
                          exact
                          path={`/${Page.reviews}/replyMulti`}
                          component={ReviewReplyMulti}
                        />
                        <Route
                          exact
                          path={`/${Page.reviews}/:name`}
                          component={ReviewDetails}
                        />
                        <Route component={NotFound} />
                      </Switch>
                    </Card>
                    {footer}
                  </Col>
                </Row>
              </Content>
            </Layout>
          </Layout>
          <div style={{ display: "none" }}>{APP_VERSION}</div>
        </Layout>
      );
    }
  }
  return (
    <Router>
      {content}
      {displayHelp ? (
        <div className="help-button-wrapper">
          <Button
            type="primary"
            href="https://help.gyro-n.com/review/"
            target="_blank"
            className="help-button"
          >
            <div>
              <ContainerOutlined />
              {i18n.t("common.help")}
            </div>
          </Button>
          <Button onClick={onClickClose} className="help-close-button">
            <CloseOutlined />
          </Button>
        </div>
      ) : null}
    </Router>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    page: state.app.page,
    client: state.client.item,
    clientLoading: state.client.getLoading,
    meoSite: state.seo.meoSite,
    meoSiteLoading: state.seo.getLoading,
    ssoClient: state.client.ssoClient,
    ssoClientLoading: state.client.ssoClientLoading,
    statistics: state.client.statistics,
    statisticsLoading: state.client.statisticsLoading,
    survey: state.survey.survey!,
    clientError: state.client.error,
    seoError: state.seo.error,
    getClientTypeLoading: state.contractDetails.getClientTypeLoading,
    clientType: state.contractDetails.clientType,
  };
};

const mapDispatchToProps = {
  getClient,
  getSSOClient,
  getUsageStatistics,
  getMEOSite,
  getClientType,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
