import React from "react";
import { Button } from "antd";
import { useHistory } from "react-router-dom";

interface LinkButtonProps {
  pathName: string;
  buttonName: string;
  handleClick: () => void;
}

const LinkButton: React.FC<LinkButtonProps> = (props) => {
  const history = useHistory();
  const goToPage = () => {
    history.replace(props.pathName);
    props.handleClick();
  };

  return (
    <Button type="text" size="large" onClick={goToPage}>
      {props.buttonName}
    </Button>
  );
};

export default LinkButton;
