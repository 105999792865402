import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  Paginated,
  SurveyProductSettings,
  RecipientEmailCsvImportResult,
} from "api/sms-api";
import { ErrorWrapper } from "errors/errors";
import { CRUDState, initialCRUDState } from "store/CRUDStore";

export interface ProductsState extends CRUDState<SurveyProductSettings> {
  updateSurveyProductSettingsLoading: boolean;
  multipleDeleteLoading: boolean;
  multipleDeleteFinished: boolean;
  selectedProducts: Array<SurveyProductSettings>;
  importResult?: RecipientEmailCsvImportResult;
  csvImporting: boolean;
  csvExporting: boolean;
}

const initialState: ProductsState = {
  ...initialCRUDState,
  updateSurveyProductSettingsLoading: false,
  multipleDeleteLoading: false,
  multipleDeleteFinished: false,
  selectedProducts: [],
  csvImporting: false,
  csvExporting: false,
};

export const slice = createSlice({
  name: "products",
  initialState,
  reducers: {
    getSurveyProductSettingsStart: (state) => {
      state.listLoading = true;
    },
    getSurveyProductSettingsSuccess: (
      state,
      action: PayloadAction<Paginated<SurveyProductSettings>>
    ) => {
      state.items = action.payload;
      state.listLoading = false;
    },
    getSurveyProductSettingsError: (state) => {
      state.listLoading = false;
    },
    getSurveyProductSettingStart: (state) => {
      state.getLoading = true;
    },
    getSurveyProductSettingSuccess: (
      state,
      action: PayloadAction<SurveyProductSettings>
    ) => {
      state.item = action.payload;
      state.getLoading = false;
      state.error = undefined;
    },
    getSurveyProductSettingError: (
      state,
      action: PayloadAction<ErrorWrapper>
    ) => {
      state.getLoading = false;
      state.error = action.payload;
    },
    clearItems: (state) => {
      state.items = { items: [], hasRight: false, hasLeft: false };
    },
    updateSurveyProductSettingsStart: (state) => {
      state.updateSurveyProductSettingsLoading = true;
    },
    updateSurveyProductSettingsSuccess: (
      state,
      action: PayloadAction<Paginated<SurveyProductSettings>>
    ) => {
      state.items = action.payload;
      state.updateSurveyProductSettingsLoading = false;
    },
    updateSurveyProductSettingsError: (
      state,
      action: PayloadAction<ErrorWrapper>
    ) => {
      state.updateSurveyProductSettingsLoading = false;
      state.error = action.payload;
    },
    addProductStart: (state) => {
      state.createLoading = true;
    },
    addProductSuccess: (state) => {
      state.createLoading = false;
    },
    addProductError: (state, action: PayloadAction<ErrorWrapper>) => {
      state.createLoading = false;
      state.error = action.payload;
    },
    addProductSettingsStart: (state) => {
      state.createLoading = true;
    },
    addProductSettingsSuccess: (state) => {
      state.createLoading = false;
    },
    addProductSettingsError: (state, action: PayloadAction<ErrorWrapper>) => {
      state.createLoading = false;
      state.error = action.payload;
    },
    deleteProductSettingsStart: (state) => {
      state.multipleDeleteLoading = true;
    },
    deleteProductSettingsSuccess: (state) => {
      state.multipleDeleteLoading = false;
      state.multipleDeleteFinished = true;
    },
    deleteProductSettingsError: (
      state,
      _action: PayloadAction<ErrorWrapper>
    ) => {
      state.multipleDeleteLoading = false;
    },
    setSelectedProducts: (
      state,
      action: PayloadAction<Array<SurveyProductSettings>>
    ) => {
      state.selectedProducts = action.payload;
    },
    clearSelectedProducts: (state) => {
      state.selectedProducts = [];
    },
    exportStart: (state) => {
      state.csvExporting = true;
    },
    exportSuccess: (state) => {
      state.csvExporting = false;
    },
    exportError: (state) => {
      state.csvExporting = false;
    },
    importStart: (state) => {
      state.csvImporting = true;
    },
    importSuccess: (
      state,
      action: PayloadAction<RecipientEmailCsvImportResult>
    ) => {
      state.csvImporting = false;
      state.importResult = action.payload;
    },
    importError: (state) => {
      state.csvImporting = false;
    },
    clearImport: (state) => {
      state.importResult = undefined;
    },
  },
});

export const {
  getSurveyProductSettingsStart,
  getSurveyProductSettingsSuccess,
  getSurveyProductSettingsError,
  getSurveyProductSettingStart,
  getSurveyProductSettingSuccess,
  getSurveyProductSettingError,
  clearItems,
  updateSurveyProductSettingsStart,
  updateSurveyProductSettingsSuccess,
  updateSurveyProductSettingsError,
  addProductStart,
  addProductSuccess,
  addProductError,
  deleteProductSettingsStart,
  deleteProductSettingsSuccess,
  deleteProductSettingsError,
  setSelectedProducts,
  clearSelectedProducts,
  clearImport,
  exportStart,
  exportSuccess,
  exportError,
  importStart,
  importSuccess,
  importError,
  addProductSettingsStart,
  addProductSettingsSuccess,
  addProductSettingsError,
} = slice.actions;

export default slice.reducer;
