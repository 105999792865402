import { Tabs } from "antd";
import i18n from "i18n";
import React from "react";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { pushTo } from "utils/navigation";
import AddressEdit from "./AddressEdit";
import ActivityList from "./Activity";
import { Page, setPage } from "features/appstate/AppStateSlice";

const { TabPane } = Tabs;

interface AddressDetailsProps {
  setPage: (page: Page) => void;
}

const AddressDetails: React.FC<AddressDetailsProps> = (props) => {
  const { key } = useParams() as any;
  const history = useHistory();
  const { id } = useParams() as any;

  React.useEffect(() => {
    props.setPage(Page.addresses);
  }, [props.setPage]);

  const onTabClick = (key: string) => {
    pushTo(history, `/${Page.addresses}/${id}/${key}`, {}, [], true);
  };

  return (
    <Tabs activeKey={key} onTabClick={onTabClick}>
      <TabPane tab={i18n.t("addresses.tabs.edit")} key="edit">
        <AddressEdit />
      </TabPane>
      <TabPane tab={i18n.t("addresses.tabs.activity")} key="activity">
        <ActivityList />
      </TabPane>
    </Tabs>
  );
};

const mapDispatchToProps = {
  setPage,
};

export default connect(null, mapDispatchToProps)(AddressDetails);
