import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ModifiedPublishForm } from "components/SendForm";
import { MessageStats } from "api/sms-api";
import { ErrorWrapper } from "errors/errors";

interface PublishState {
  loading: boolean;
  savedForm?: ModifiedPublishForm;
  messageStats?: MessageStats;
  messageStatsLoading: boolean;
  error?: ErrorWrapper;
}

const initialState: PublishState = {
  loading: false,
  messageStatsLoading: false,
};

export const slice = createSlice({
  name: "publish",
  initialState,
  reducers: {
    publishStart: (state) => {
      state.loading = true;
    },
    publishSuccess: (state) => {
      state.loading = false;
      state.error = undefined;
    },
    publishFailure: (
      state,
      action: PayloadAction<ErrorWrapper | undefined>
    ) => {
      state.loading = false;
      state.error = action.payload;
    },
    setSavedForm: (state, action: PayloadAction<ModifiedPublishForm>) => {
      state.savedForm = action.payload;
    },
    clearSavedForm: (state) => {
      state.savedForm = undefined;
    },
    getMessageStatsStart: (state) => {
      state.messageStatsLoading = true;
    },
    getMessageStatsSuccess: (state, action: PayloadAction<MessageStats>) => {
      state.messageStatsLoading = false;
      state.messageStats = action.payload;
    },
    getMessageStatsFailure: (state) => {
      state.messageStatsLoading = false;
    },
    clearError: (state) => {
      state.error = undefined;
    },
  },
});

export const {
  publishStart,
  publishSuccess,
  publishFailure,
  setSavedForm,
  clearSavedForm,
  getMessageStatsStart,
  getMessageStatsSuccess,
  getMessageStatsFailure,
  clearError,
} = slice.actions;

export default slice.reducer;
