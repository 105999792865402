import React from "react";
import { useFormikContext } from "formik";
import { Prompt } from "react-router-dom";

interface PromptIfDirtyProps {
  message: string;
}

export const PromptIfDirty = (props: PromptIfDirtyProps) => {
  const formik = useFormikContext();
  return (
    <Prompt
      when={formik.dirty && formik.submitCount === 0}
      message={props.message}
    />
  );
};
