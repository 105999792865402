import { AppDispatch, AsyncAppThunk } from "store/store";
import { httpWrapper } from "../../utils/http";
import { SMS_API_BASE } from "../../constants";
import { handleError } from "errors/errors";
import {
  deleteGMBReplyError,
  deleteGMBReplyStart,
  deleteGMBReplySuccess,
  getGMBError,
  getGMBStart,
  getGMBSuccess,
  listGMBError,
  listGMBStart,
  listGMBSuccess,
  updateGMBReplyError,
  updateGMBReplyStart,
  updateGMBReplySuccess,
} from "./ReviewSlice";
import {
  GMBReviews,
  GMBReview,
  GMBReviewFilter,
  GMBReplyForm,
} from "api/sms-api";
import i18n from "i18n";
import { showNotification } from "utils/notifications";

export const getGMBReview = (
  meoSiteId: number,
  reviewName: string
): AsyncAppThunk<void> => async (dispatch: AppDispatch) => {
  dispatch(getGMBStart());
  try {
    const review = await httpWrapper<GMBReview>(
      "GET",
      `${SMS_API_BASE}/reviews/gmb/details`,
      {
        reviewName,
        meoSiteId,
      }
    );
    dispatch(getGMBSuccess(review));
  } catch (e) {
    handleError(e);
    dispatch(getGMBError());
  }
};

export const listGMBReviews = (
  filter: GMBReviewFilter
): AsyncAppThunk<void> => async (dispatch: AppDispatch) => {
  dispatch(listGMBStart());
  try {
    const reviews = await httpWrapper<GMBReviews>(
      "GET",
      `${SMS_API_BASE}/reviews/gmb`,
      filter
    );
    dispatch(listGMBSuccess(reviews));
  } catch (e) {
    handleError(e);
    dispatch(listGMBError());
  }
};

export const updateGMBReply = (
  form: GMBReplyForm,
  isUpdate: boolean
): AsyncAppThunk<void> => async (dispatch: AppDispatch) => {
  dispatch(updateGMBReplyStart());
  try {
    await httpWrapper<void>("POST", `${SMS_API_BASE}/reviews/gmb/reply`, form);
    if (isUpdate) {
      showNotification(i18n.t("reviews.updateSuccess"));
    } else {
      showNotification(i18n.t("reviews.doReplySuccess"));
    }
    dispatch(updateGMBReplySuccess());
  } catch (e) {
    handleError(e);
    dispatch(updateGMBReplyError());
  }
};

export const deleteGMBReply = (
  meoSiteId: number,
  reviewName: string
): AsyncAppThunk<void> => async (dispatch: AppDispatch) => {
  dispatch(deleteGMBReplyStart());
  try {
    await httpWrapper<GMBReview>(
      "DELETE",
      `${SMS_API_BASE}/reviews/gmb/reply`,
      {
        reviewName,
        meoSiteId,
      }
    );
    showNotification(i18n.t("reviews.deleteSuccess"));
    dispatch(deleteGMBReplySuccess());
  } catch (e) {
    handleError(e);
    dispatch(deleteGMBReplyError());
  }
};
