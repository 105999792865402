import React from "react";
import SMSLink from "components/SMSLink";
import { Page, setPage } from "features/appstate/AppStateSlice";
import { connect } from "react-redux";
import {
  Paginated,
  ListHistoryParams,
  FullHistory,
  ContactStatus,
  HistoryType,
  MEOSite,
} from "api/sms-api";
import { RootState } from "store/store";
import qs from "qs";
import { listHistory } from "features/history/HistoryActions";
import { clearItems } from "features/history/HistorySlice";
import i18n from "i18n";
import { Table, Row } from "antd";
import moment from "moment";
import "moment/locale/ja";
import { RouteComponentProps, useHistory, useLocation } from "react-router";
import SimplePagination, {
  PaginatedComponentProps,
} from "components/SimplePagination";
import Media from "react-media";
import { mobileMediaQuery } from "../../constants";
import { truncate } from "lodash";

interface HistoryProps {
  items: Paginated<FullHistory>;
  loading: boolean;

  meoSite: MEOSite;
  listHistory: (params: ListHistoryParams) => void;
  clearItems: () => void;
  setPage: (page: Page) => void;
}

const HistoryList: React.FC<
  HistoryProps & RouteComponentProps & PaginatedComponentProps
> = (props) => {
  const history = useHistory();
  const location = useLocation();

  React.useEffect(() => {
    props.setPage(Page.history);
  }, [props.setPage]);

  React.useEffect(() => {
    const parsed = qs.parse(location.search, { ignoreQueryPrefix: true });
    const params = {
      ...parsed,
      meoSiteId: props.meoSite.id,
    };
    props.listHistory(params);
  }, [props.listHistory, location.search, props.meoSite]);

  React.useEffect(() => {
    return () => {
      props.clearItems();
    };
  }, [props.clearItems]);

  const columns = [
    {
      title: i18n.t("history.sentAt"),
      dataIndex: "sentAt",
      key: "sentAt",
      render: (_: any, record: FullHistory) => (
        <span>{moment(record.history.sentAt).format("LLLL")}</span>
      ),
    },
    {
      title: i18n.t("common.addresses"),
      dataIndex: "contacts",
      key: "contacts",
      render: (_: any, record: FullHistory) => {
        const statuses = record.contacts.map((c) => c.status);
        const totalSuccess = statuses.filter((s) => s === ContactStatus.success)
          .length;
        const totalError = statuses.filter((s) => s === ContactStatus.error)
          .length;
        return (
          <span>
            <SMSLink to={`/${Page.history}/${record.history.id}/contacts`}>
              <div>
                {i18n.t("history.totalContacts", {
                  count: record.contacts.length,
                })}
              </div>
            </SMSLink>
            {record.history.type !== HistoryType.Direct && (
              <div>
                {i18n.t("history.successContacts", { count: totalSuccess })}
              </div>
            )}
            {record.history.type !== HistoryType.Direct && totalError > 0 && (
              <div>
                {i18n.t("history.errorContacts", { count: totalError })}
              </div>
            )}
          </span>
        );
      },
    },

    {
      title: i18n.t("history.messageParts"),
      dataIndex: "totalGyronMessageParts",
      key: "totalGyronMessageParts",
      render: (_: any, record: FullHistory) => (
        <span>{record.gyronMessageParts}</span>
      ),
    },
    {
      title: i18n.t("history.type"),
      dataIndex: "type",
      key: "type",
      render: (_: any, record: FullHistory) => (
        <span>{i18n.t("history." + record.history.type)}</span>
      ),
    },
    {
      title: i18n.t("common.message"),
      dataIndex: "message",
      key: "message",
      render: (_: any, record: FullHistory) => {
        return (
          <SMSLink to={`/${Page.history}/${record.history.id}/message`}>
            {truncate(record.history.message, { length: 10 })}
          </SMSLink>
        );
      },
      hideOnSmall: true,
    },
  ];

  const getResponsiveColumns = (smallScreen: boolean) =>
    columns.filter(({ hideOnSmall = false }) => !(smallScreen && hideOnSmall));

  const pagination = (
    <SimplePagination
      items={props.items.items}
      getCursor={(model: FullHistory) => model.history.sentAt}
      sortDescending={true}
      hasRight={props.items.hasRight}
      hasLeft={props.items.hasLeft}
      location={props.location}
      history={history}
      match={props.match}
    />
  );

  return (
    <Media query={mobileMediaQuery}>
      {(smallScreen) => (
        <div>
          <Table
            dataSource={props.items.items}
            columns={getResponsiveColumns(smallScreen)}
            locale={{ emptyText: i18n.t("history.empty") }}
            rowKey={(s: FullHistory) => s.history.id}
            size="middle"
            pagination={false}
            loading={props.loading}
          />
          <Row className="pagination-row">{pagination}</Row>
        </div>
      )}
    </Media>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    items: state.history.items,
    loading: state.history.listLoading,
    meoSite: state.seo.meoSite!,
  };
};

const mapDispatchToProps = {
  setPage,
  listHistory,
  clearItems,
};

export default connect(mapStateToProps, mapDispatchToProps)(HistoryList);
