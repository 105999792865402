import React from "react";
import { connect } from "react-redux";
import { Page, setPage } from "features/appstate/AppStateSlice";
import { Row, Spin } from "antd";
import { Formik } from "formik";
import { Checkbox, Form, Input, Select } from "formik-antd";
import i18n from "i18n";
import { ReviewLinkAddForm, MEOSite, ReviewLinkColors } from "api/sms-api";
import { formItemLayout } from "../../constants";
import { RootState } from "store/store";
import { addReviewLink, getColors } from "./ReviewLinkActions";
import { reviewLinkAddFormSchema } from "validation";
import CreateButton from "components/CreateButton";
import BackButton from "components/BackButton";
import { useParams } from "react-router-dom";
import { pushTo } from "utils/navigation";
import { useHistory } from "react-router-dom";
import ColorOption from "./ColorOption";

interface ReviewLinkAddProps {
  setPage: (page: Page) => void;
  addReviewLink: (
    landingPageSettingsId: string,
    form: ReviewLinkAddForm
  ) => Promise<void>;
  loading: boolean;
  colors?: ReviewLinkColors;
  colorsLoading: boolean;
  getColors: () => void;
  meoSite: MEOSite;
}

const ReviewLinkAdd: React.FC<ReviewLinkAddProps> = (props) => {
  const history = useHistory();
  const { lpsId } = useParams() as any;

  React.useEffect(() => {
    props.setPage(Page.landingPageSettings);
  }, [props.setPage]);

  React.useEffect(() => {
    props.getColors();
  }, [props.getColors]);

  const handleSubmit = async (form: ReviewLinkAddForm) => {
    await props.addReviewLink(lpsId, form);
    pushTo(history, `/${Page.landingPageSettings}/${lpsId}/reviewLinks`, {});
  };

  if (props.colorsLoading || !props.colors) {
    return <Spin />;
  }

  const defaultColor = props.colors.colors[0].id;

  const initialValues: ReviewLinkAddForm = {
    text: "",
    url: "",
    reviewLinkColorId: defaultColor,
    hasIcon: true,
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={reviewLinkAddFormSchema}
    >
      {(_formikBag) => {
        return (
          <Form {...formItemLayout}>
            <Form.Item name="text" label={i18n.t("common.message")}>
              <Input name="text" />
            </Form.Item>
            <Form.Item name="url" label={i18n.t("reviewLinks.url")}>
              <Input name="url" />
            </Form.Item>
            <Form.Item
              name="reviewLinkColorId"
              label={i18n.t("reviewLinks.color")}
            >
              <Select
                className="color-picker"
                name="reviewLinkColorId"
                defaultValue={defaultColor}
              >
                {props.colors!.colors.map((color) => (
                  <Select.Option key={color.id} value={color.id}>
                    <ColorOption color={color} />
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="hasIcon" label={i18n.t("reviewLinks.hasIcon")}>
              <Checkbox name="hasIcon" />
            </Form.Item>
            <Row className="form-button-row">
              <CreateButton loading={props.loading} />
              <BackButton />
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = (root: RootState) => {
  return {
    loading: root.reviewLinks.createLoading,
    colors: root.reviewLinks.colors,
    colorsLoading: root.reviewLinks.colorsLoading,
    meoSite: root.seo.meoSite!,
  };
};

const mapDispatchToProps = {
  setPage,
  addReviewLink,
  getColors,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReviewLinkAdd);
