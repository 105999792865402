import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Paginated, Blacklist } from "../../api/sms-api";
import { ErrorWrapper } from "errors/errors";
import { initialCRUDState, CRUDState } from "store/CRUDStore";

interface BlacklistSlice extends CRUDState<Blacklist> {}

export const initialState: BlacklistSlice = {
  ...initialCRUDState,
};

export const slice = createSlice({
  name: "blacklist",
  initialState,
  reducers: {
    listBlacklistStart: (state) => {
      state.listLoading = true;
    },
    listBlacklistSuccess: (
      state,
      action: PayloadAction<Paginated<Blacklist>>
    ) => {
      state.items = action.payload;
      state.listLoading = false;
    },
    listBlacklistError: (state, _action: PayloadAction<ErrorWrapper>) => {
      state.listLoading = false;
    },
  },
});

export const {
  listBlacklistStart,
  listBlacklistSuccess,
  listBlacklistError,
} = slice.actions;

export default slice.reducer;
