import React from "react";
import { Address } from "api/sms-api";
import { Table } from "antd";
import PageLocale from "./PageLocale";
import i18n from "i18n";

interface AddressBlockProps {
  addresses: Array<Address>;
  groupName: string;
}

const locale = PageLocale;

const AddressBlock: React.FC<AddressBlockProps> = (props) => {
  const columns: Array<any> = [
    {
      title: i18n.t("common.name"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: i18n.t("common.phoneNumber"),
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
  ];

  return (
    <Table
      title={() => props.groupName}
      dataSource={props.addresses}
      rowKey={(address: Address) => address.id}
      columns={columns}
      pagination={{ locale }}
    />
  );
};

export default AddressBlock;
