import React, { ChangeEvent } from "react";
import { connect } from "react-redux";
import { pushTo } from "utils/navigation";
import { Formik, Field, FormikProps, FormikHelpers } from "formik";
import { Form, Input, Radio } from "formik-antd";
import { Button, Row, Col, Spin, Modal, message } from "antd";
import {
  PublishForm,
  Template,
  DirectLinkResponse,
  PublishType,
  ScheduleForm,
  MessageStats,
  MessageStatsForm,
  Client,
  TemplateAddForm,
  UsageStatistics,
  MEOSite,
  DirectLinkForm,
} from "api/sms-api";
import i18n from "i18n";
import { setValidationMessages } from "i18n/validation";
import {
  ProfileOutlined,
  SaveOutlined,
  MobileOutlined,
  CloudOutlined,
  SendOutlined,
  CalendarOutlined,
} from "@ant-design/icons";
import { formItemLayout, mobileMediaQuery, defaultMessage } from "../constants";
import { useHistory, useLocation } from "react-router-dom";
import qs from "qs";
import { clearAddress } from "features/address/AddressSlice";
import { getTemplate } from "features/templates/TemplatesActions";
import { clearTemplate } from "features/templates/TemplatesSlice";
import { RootState } from "store/store";
import { Page, setPage } from "features/appstate/AppStateSlice";
import {
  publish,
  schedule,
  getMessageStats,
  getDirectLink,
} from "features/publish/PublishActions";
import ScheduledTimePicker from "./ScheduledTimePicker";
import { modifiedPublishFormSchema } from "validation";
import MobileTimePicker from "./MobileTimePicker";
import moment from "moment";
import Media from "react-media";
import PhoneNumberInput, {
  FormWithRecipients,
  handleSendErrors,
} from "./PhoneNumberInput";
import { showNotification } from "utils/notifications";
import {
  duplicatePhoneNumberCheck,
  transformContactList,
  transformGroupList,
  transformPhoneNumberList,
} from "utils/phone_number";
import {
  setSavedForm,
  clearSavedForm,
  clearError,
} from "features/publish/PublishSlice";
import { RadioChangeEvent } from "antd/lib/radio";
import { getUsageStatistics } from "features/client/ClientActions";
import { addTemplate } from "features/templates/TemplatesActions";
import { convertContent } from "utils/strings";
import { ErrorWrapper, isErrorWrapper } from "errors/errors";
import ErrorDisplay from "./ErrorDisplay";

setValidationMessages();

interface SendFormProps {
  getDirectLink: (
    form: DirectLinkForm
  ) => Promise<ErrorWrapper | DirectLinkResponse>;

  // for sending via AWS
  publish: (form: PublishForm) => Promise<void | ErrorWrapper>;
  schedule: (form: ScheduleForm) => Promise<void | ErrorWrapper>;

  setPage: (page: Page) => void;

  loading: boolean;

  // If `templateId` is present in the query string,
  // look up the template and place it into the form. While
  // it's loading, disable the form from being edited
  templateLoading: boolean;
  getTemplate: (templateId: string, forShortcut: boolean) => void;

  shortcutTemplate?: Template;
  clearTemplate: () => void;

  clientLoading: boolean;
  client?: Client;
  statistics?: UsageStatistics;
  statisticsLoading: boolean;

  savedForm?: ModifiedPublishForm;
  setSavedForm: (form: ModifiedPublishForm) => void;
  clearSavedForm: () => void;

  messageStats?: MessageStats;
  messageStatsLoading: boolean;
  getMessageStats: (form: MessageStatsForm) => void;

  getUsageStatistics: (meoSiteId: number) => void;

  addTemplate: (form: TemplateAddForm) => void;
  meoSite: MEOSite;

  error?: ErrorWrapper;
  clearError: () => void;
}

// When to send a cloud message
export enum Timing {
  Now = "now",
  Scheduled = "scheduled",
}

interface BasePublishForm {
  message: string;
  sendUserId: string;
  timing: Timing;
  scheduledTime?: string;
  type: PublishType;
  templateName: string;
}

// For when the user is searching/typing names and numbers; some recipients
// won't have a address ID yet
export interface ModifiedPublishForm
  extends FormWithRecipients,
    BasePublishForm {
  cursorPosition?: number;
}

const SendForm: React.FC<SendFormProps> = (props: SendFormProps) => {
  const location = useLocation();
  const history = useHistory();
  const parsed = qs.parse(location.search, { ignoreQueryPrefix: true });
  const [modalVisible, setModalVisible] = React.useState(false);
  const [modalValidationResult, setModalValidationResult] = React.useState(
    false
  );
  const [cursorPosition, setCursorPosition] = React.useState(0);

  React.useEffect(() => {
    const parsed = qs.parse(location.search, { ignoreQueryPrefix: true });
    if (parsed.shortcutSelected === "template") {
      showNotification(i18n.t("templates.selected"));
    }
  }, [location, props.getTemplate]);

  React.useEffect(() => {
    props.setPage(Page.publish);
  }, [props.setPage]);

  // Clear the message stats on load if no saved form, or get the stats if
  // there is a saved form
  React.useEffect(() => {
    props.getMessageStats({
      message: props.savedForm ? convertContent(props.savedForm.message) : "",
      contacts: props.savedForm
        ? transformContactList(props.savedForm.contacts)
        : [],
      groups: props.savedForm ? transformGroupList(props.savedForm.groups) : [],
    });
  }, [props.getMessageStats, props.savedForm]);

  React.useEffect(() => {
    // Run on unmount
    return () => {
      props.clearError();
    };
  }, [props.clearError]);

  const debounceWait = 500;
  const [
    timeoutHandler,
    setTimeoutHandler,
  ] = React.useState<NodeJS.Timeout | null>(null);

  let initialValues: ModifiedPublishForm;

  const resetInitialValues = () => {
    initialValues = {
      contacts: [{ phoneNumber: "", name: "" }],
      templateName: "",
      message: defaultMessage,
      sendUserId: props.client?.sendUserId || "",
      type: props.client?.defaultPublishType || PublishType.Direct,
      timing: Timing.Now,
      scheduledTime: undefined,
      groups: [],
    };
  };

  resetInitialValues();

  if (
    props.savedForm &&
    (parsed.shortcutSelected === "template" ||
      parsed.shortcutSelected === "landing_page" ||
      parsed.shortcutSelected === "back")
  ) {
    // When returning from selecting Addresses, remove the default blank
    // contact in the form if present.
    const modifiedSavedForm = {
      ...props.savedForm,
      contacts: props.savedForm.contacts.filter((c) => c.phoneNumber !== ""),
      groups: props.savedForm.groups.filter((g) => g.id !== ""),
    };

    // If this results in 0 contacts (i.e. they removed all of them), add the blank one back in
    if (
      modifiedSavedForm.contacts.length === 0 &&
      modifiedSavedForm.groups.length === 0
    ) {
      modifiedSavedForm.contacts = [
        {
          name: "",
          phoneNumber: "",
        },
      ];
    }

    initialValues = modifiedSavedForm;
  } else {
    props.clearSavedForm();
  }

  const clearForm = (
    form: ModifiedPublishForm,
    formikBag: FormikHelpers<ModifiedPublishForm>
  ) => {
    resetInitialValues();
    formikBag.setValues(initialValues);
    formikBag.setTouched({});
    if (form.type === "cloud") {
      props.getMessageStats({
        message: "",
        contacts: [],
        groups: [],
      });
    }
  };
  const doSubmit = async (
    form: ModifiedPublishForm,
    formikBag: FormikHelpers<ModifiedPublishForm>
  ) => {
    form.contacts = transformPhoneNumberList(form.contacts);

    if (form.type === "direct") {
      const directLinkForm: DirectLinkForm = {
        meoSiteId: props.meoSite.id,
        contact: form.contacts[0],
        message: form.message,
      };
      const result = await props.getDirectLink(directLinkForm);
      handleSendErrors(result, form, formikBag);
      if (!isErrorWrapper(result)) {
        clearForm(form, formikBag);
        window.location.href = result.link;
      }
    } else {
      // Cloud
      if (!duplicatePhoneNumberCheck(form.contacts)) {
        message.warn(i18n.t("messageForm.duplicatePhoneNumber"));
      } else {
        const cloudForm: PublishForm = {
          meoSiteId: props.meoSite.id,
          contacts: form.contacts,
          message: form.message,
          sendUserId: form.sendUserId,
          groups: form.groups,
        };
        if (form.timing === Timing.Now) {
          // Immediate publish
          const result = await props.publish(cloudForm);
          handleSendErrors(result, form, formikBag);
          if (!isErrorWrapper(result)) {
            clearForm(form, formikBag);
          }
        } else if (form.timing === Timing.Scheduled && form.scheduledTime) {
          // Scheduled publish; have to make a different form
          const scheduleForm: ScheduleForm = {
            meoSiteId: props.meoSite.id,
            message: cloudForm.message,
            sendUserId: cloudForm.sendUserId,
            contacts: cloudForm.contacts,
            scheduledTime: form.scheduledTime,
            groups: cloudForm.groups,
          };

          const result = await props.schedule(scheduleForm);
          handleSendErrors(result, form, formikBag);
          if (!isErrorWrapper(result)) {
            clearForm(form, formikBag);
          }
        } else {
          // This case shouldn't happen...
          console.error("Invalid form");
        }
        // Refresh remaining message count
        props.getUsageStatistics(props.meoSite.id);
      }
    }
  };

  const goToSelectTemplate = (form: ModifiedPublishForm) => {
    props.setSavedForm(form);
    pushTo(history, "/templates", { selecting: 1 });
  };

  const goToSelectLandingPageSettings = (form: ModifiedPublishForm) => {
    props.setSavedForm({
      ...form,
      cursorPosition,
    });
    pushTo(history, `/${Page.landingPageSettings}`, {
      selecting: `/${Page.publish}`,
    });
  };

  const handleContactRemove = (form: FormikProps<ModifiedPublishForm>) => (
    i: number
  ) => {
    const contacts = form.values.contacts.filter((c, idx) => idx !== i);
    props.getMessageStats({
      message: convertContent(form.values.message),
      contacts: transformContactList(contacts),
      groups: transformGroupList(form.values.groups),
    });
  };

  const handleGroupRemove = (form: FormikProps<ModifiedPublishForm>) => (
    i: number
  ) => {
    const groups = form.values.groups.filter((c, idx) => idx !== i);
    props.getMessageStats({
      message: convertContent(form.values.message),
      contacts: transformContactList(form.values.contacts),
      groups: transformGroupList(groups),
    });
  };

  const handleContactInput = (form: FormikProps<ModifiedPublishForm>) => (
    phoneNumber: string,
    i: number
  ) => {
    if (phoneNumber.length >= 11 && form.values.type === PublishType.Cloud) {
      form.values.contacts.map((c, idx) => {
        if (idx === i) {
          c.phoneNumber = phoneNumber;
        }
      });
      props.getMessageStats({
        message: convertContent(form.values.message),
        contacts: transformContactList(form.values.contacts),
        groups: transformGroupList(form.values.groups),
      });
    }
  };

  const handleMessageChange = (
    form: FormikProps<ModifiedPublishForm>,
    getStats: boolean
  ) => (event: ChangeEvent<HTMLTextAreaElement>) => {
    setCursorPosition(event.target.selectionStart);
    if (form.values.type === PublishType.Cloud && getStats) {
      const message = convertContent(event.target.value);
      if (timeoutHandler) {
        clearTimeout(timeoutHandler);
      }

      setTimeoutHandler(
        setTimeout(() => {
          props.getMessageStats({
            message,
            contacts: form.values.contacts
              ? transformContactList(form.values.contacts)
              : [],
            groups: form.values.groups
              ? transformGroupList(form.values.groups)
              : [],
          });
        }, debounceWait)
      );
    }
  };

  const handleTypeChange = (form: FormikProps<ModifiedPublishForm>) => (
    event: RadioChangeEvent
  ) => {
    if (event.target.value === PublishType.Cloud) {
      props.getMessageStats({
        message: convertContent(form.values.message),
        contacts: transformContactList(form.values.contacts),
        groups: transformGroupList(form.values.groups),
      });
    } else if (form.values.contacts.length === 0) {
      form.setFieldValue("contacts", [{ name: "", phoneNumber: "" }]);
    }
  };

  const saveTemplate = (form: ModifiedPublishForm) => {
    if (modalValidationResult) {
      props.addTemplate({
        meoSiteId: props.meoSite.id,
        name: form.templateName,
        content: form.message,
      });
      setModalVisible(false);
    }
  };
  const showModal = () => {
    setModalVisible(true);
  };

  const modalCancel = () => {
    setModalVisible(false);
  };

  const remainingMessages = props.statistics
    ? props.statistics.totalAllowedMessages - props.statistics.usedMessages
    : null;

  function validateTemplateName(value: string) {
    let error;
    if (!value) {
      error = i18n.t("validation.required");
      setModalValidationResult(false);
    } else {
      setModalValidationResult(true);
    }
    return error;
  }

  return (
    <Media query={mobileMediaQuery}>
      {(smallScreen) => (
        <Formik
          initialValues={initialValues}
          onSubmit={doSubmit}
          validationSchema={modifiedPublishFormSchema}
          enableReinitialize={true}
          validateOnBlur={false}
        >
          {(formikBag) => {
            const verb =
              formikBag.values.type === PublishType.Cloud &&
              formikBag.values.timing === Timing.Scheduled
                ? i18n.t("messageForm.schedule")
                : i18n.t("messageForm.submit");

            const templateShortcutButton = (
              <Button
                className="template-shortcut"
                icon={<ProfileOutlined />}
                onClick={() => goToSelectTemplate(formikBag.values)}
              >
                {i18n.t("messageForm.selectFromTemplate")}
              </Button>
            );

            return (
              <div>
                <Form className="publish-form" {...formItemLayout}>
                  <Form.Item name="type" label={i18n.t("messageForm.type")}>
                    <Radio.Group
                      name="type"
                      className="form-radio-group"
                      onChange={handleTypeChange(formikBag)}
                    >
                      <Radio.Button value={PublishType.Direct}>
                        <MobileOutlined style={{ marginRight: "0.5em" }} />
                        {i18n.t("publishTypes.directLink")}
                      </Radio.Button>
                      <Radio.Button value={PublishType.Cloud}>
                        <CloudOutlined style={{ marginRight: "0.5em" }} />
                        {i18n.t("publishTypes.cloud")}
                      </Radio.Button>
                    </Radio.Group>
                  </Form.Item>

                  <PhoneNumberInput
                    smallScreen={smallScreen}
                    formikBag={formikBag as any}
                    onContactRemove={handleContactRemove(formikBag)}
                    onGroupRemove={handleGroupRemove(formikBag)}
                    onContactInput={handleContactInput(formikBag)}
                    setSavedForm={(form: any) => {
                      props.setSavedForm(form);
                    }}
                    scheduledId=""
                    selectingParameter="send_form"
                  />
                  {formikBag.values.type === PublishType.Cloud && (
                    <Form.Item
                      name="sendUserId"
                      label={i18n.t("common.sendUserId")}
                      extra={i18n.t("common.sendUserIdNote")}
                    >
                      {props.clientLoading ? (
                        <Spin />
                      ) : (
                        <Input
                          name="sendUserId"
                          placeholder={i18n.t("common.sendUserIdPlaceholder")}
                        />
                      )}
                    </Form.Item>
                  )}

                  <Form.Item
                    name="message"
                    className="message"
                    label={
                      smallScreen ? (
                        <Row
                          justify="space-between"
                          align="middle"
                          style={{ width: "100%" }}
                        >
                          <Col>{i18n.t("common.message")}</Col>
                          <Col>{templateShortcutButton}</Col>
                        </Row>
                      ) : (
                        i18n.t("common.message")
                      )
                    }
                    extra={
                      formikBag.values.type === PublishType.Cloud &&
                      i18n.t("common.messageNote")
                    }
                  >
                    <Input.TextArea
                      className="message"
                      name="message"
                      onChange={handleMessageChange(formikBag, true)}
                      onBlur={handleMessageChange(formikBag, false)}
                      placeholder={i18n.t("messageForm.messagePlaceholder")}
                      autoSize={{ minRows: 3 }}
                    />
                  </Form.Item>
                  {props.templateLoading ? (
                    <Spin />
                  ) : (
                    <Row className="ant-form-item">
                      <Col {...formItemLayout.labelCol}></Col>
                      <Col
                        className="form-button-row"
                        xs={{ span: 24 }}
                        sm={{ span: 18 }}
                      >
                        {!smallScreen && templateShortcutButton}
                        <Button
                          icon={<SaveOutlined />}
                          className="max-width-on-mobile"
                          onClick={() => showModal()}
                          disabled={formikBag.values.message.length < 1}
                        >
                          {i18n.t("messageForm.templateSave")}
                        </Button>
                        <Modal
                          title={i18n.t("messageForm.modal.title")}
                          visible={modalVisible}
                          onOk={() => saveTemplate(formikBag.values)}
                          onCancel={modalCancel}
                          cancelText={i18n.t("common.cancel")}
                          okText={i18n.t("messageForm.modal.saveTemplate")}
                        >
                          <Field
                            className="ant-input"
                            name="templateName"
                            validate={validateTemplateName}
                            placeholder={i18n.t(
                              "messageForm.modal.templateNamePlaceholder"
                            )}
                          />
                          {formikBag.errors.templateName &&
                            formikBag.touched.templateName && (
                              <div className="ant-form-item-has-error">
                                <div className="ant-form-item-explain">
                                  {formikBag.errors.templateName}
                                </div>
                              </div>
                            )}
                        </Modal>
                        <Button
                          className="landing-page-shortcut max-width-on-mobile"
                          onClick={() =>
                            goToSelectLandingPageSettings(formikBag.values)
                          }
                        >
                          {i18n.t("messageForm.selectLandingPage")}
                        </Button>
                      </Col>
                    </Row>
                  )}
                  {formikBag.values.type === PublishType.Cloud && (
                    <Form.Item
                      name="timing"
                      label={i18n.t("messageForm.timing.label")}
                    >
                      <Radio.Group name="timing" className="form-radio-group">
                        <Radio.Button value={Timing.Now}>
                          {i18n.t("messageForm.timing.now")}
                        </Radio.Button>
                        <Radio.Button value={Timing.Scheduled}>
                          {i18n.t("messageForm.timing.scheduled")}
                        </Radio.Button>
                      </Radio.Group>
                    </Form.Item>
                  )}
                  {formikBag.values.type === PublishType.Cloud &&
                    formikBag.values.timing === Timing.Scheduled && (
                      <Form.Item
                        name="scheduledTime"
                        label={i18n.t("messageForm.scheduledTime")}
                      >
                        {smallScreen ? (
                          <MobileTimePicker
                            date={
                              formikBag.values.scheduledTime
                                ? moment(
                                    formikBag.values.scheduledTime
                                  ).toDate()
                                : undefined
                            }
                            setDate={(date: Date) =>
                              formikBag.setFieldValue(
                                "scheduledTime",
                                date.toISOString()
                              )
                            }
                          />
                        ) : (
                          <ScheduledTimePicker />
                        )}
                      </Form.Item>
                    )}
                  {formikBag.values.type === PublishType.Cloud &&
                    props.messageStatsLoading && (
                      <Row>
                        <Col {...formItemLayout.labelCol} />
                        <Col {...formItemLayout.wrapperCol}>
                          <Spin />
                        </Col>
                      </Row>
                    )}
                  {formikBag.values.type === PublishType.Cloud &&
                    !props.messageStatsLoading &&
                    props.messageStats &&
                    props.messageStats.senderCount > 0 &&
                    props.messageStats.numberOfParts > 0 &&
                    remainingMessages !== null && (
                      <Row
                        className={
                          props.messageStats.senderCount > 100 ||
                          remainingMessages < props.messageStats.numberOfParts
                            ? "error"
                            : ""
                        }
                      >
                        <Col {...formItemLayout.labelCol} />
                        <Col {...formItemLayout.wrapperCol}>
                          {props.messageStats.senderCount > 100 &&
                            i18n.t("messageForm.multiMessageMaxErrorNote", {
                              parts: props.messageStats.senderCount,
                            })}
                          {props.messageStats.senderCount <= 100 &&
                            remainingMessages >=
                              props.messageStats.numberOfParts &&
                            i18n.t("messageForm.multiMessageNote", {
                              parts: props.messageStats.numberOfParts,
                            })}
                          {props.messageStats.senderCount <= 100 &&
                            remainingMessages <
                              props.messageStats.numberOfParts &&
                            i18n.t("messageForm.multiMessageErrorNote", {
                              parts:
                                props.messageStats.numberOfParts -
                                remainingMessages,
                              allowed: remainingMessages,
                            })}
                        </Col>
                      </Row>
                    )}
                  {props.error && (
                    <Row>
                      <Col {...formItemLayout.labelCol} />
                      <Col {...formItemLayout.wrapperCol}>
                        <ErrorDisplay error={props.error} />
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Col {...formItemLayout.labelCol} />
                    <Col {...formItemLayout.wrapperCol}>
                      <Button
                        icon={
                          formikBag.values.type === PublishType.Cloud &&
                          formikBag.values.timing === Timing.Scheduled ? (
                            <CalendarOutlined />
                          ) : (
                            <SendOutlined />
                          )
                        }
                        className="submit max-width-on-mobile"
                        type="primary"
                        htmlType="submit"
                        disabled={
                          formikBag.values.type === PublishType.Cloud &&
                          !props.messageStatsLoading &&
                          props.messageStats &&
                          props.messageStats.numberOfParts > 0 &&
                          (remainingMessages == null ||
                            remainingMessages <
                              props.messageStats.numberOfParts ||
                            props.messageStats.senderCount > 100)
                        }
                        loading={props.loading}
                      >
                        {verb}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </div>
            );
          }}
        </Formik>
      )}
    </Media>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    loading: state.publish.loading,
    templateLoading: state.templates.getLoading,
    shortcutTemplate: state.templates.shortcutItem,
    clientLoading: state.client.getLoading,
    client: state.client.item,
    savedForm: state.publish.savedForm,
    messageStats: state.publish.messageStats,
    messageStatsLoading: state.publish.messageStatsLoading,
    statistics: state.client.statistics,
    statisticsLoading: state.client.statisticsLoading,
    meoSite: state.seo.meoSite!,
    error: state.publish.error,
  };
};

const mapDispatchToProps = {
  getTemplate,
  clearAddress,
  clearTemplate,
  setPage,
  getDirectLink,
  publish,
  schedule,
  setSavedForm,
  clearSavedForm,
  getMessageStats,
  getUsageStatistics,
  addTemplate,
  clearError,
};
export default connect(mapStateToProps, mapDispatchToProps)(SendForm);
