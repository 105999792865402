import {
  Paginated,
  LocalBusinesses,
  SurveyMeoSiteSettingsGetParam,
  SurveyMeoSiteSettings,
  SurveyMeoSiteSettingsUpdateItem,
  RecipientEmailCsvImportResult,
  SurveyMeoSitesAddForm,
} from "api/sms-api";
import { AppDispatch } from "store/store";
import { httpWrapper } from "utils/http";
import {
  getLocalBusinessesStart,
  getLocalBusinessesSuccess,
  getLocalBusinessesError,
  getSurveyMeoSiteSettingsStart,
  getSurveyMeoSiteSettingsSuccess,
  getSurveyMeoSiteSettingsError,
  updateSurveyMeoSiteSettingsStart,
  updateSurveyMeoSiteSettingsSuccess,
  updateSurveyMeoSiteSettingsError,
  exportStart,
  exportSuccess,
  exportError,
  importStart,
  importSuccess,
  importError,
  addMeoSitesStart,
  addMeoSitesSuccess,
  addMeoSitesError,
} from "./MeoSiteSettingsSlice";
import { SMS_API_BASE, BOM } from "../../constants";
import { handleError } from "errors/errors";
import { showNotification } from "utils/notifications";
import i18n from "i18n";
import download from "js-file-download";

export const getLocalBusinessesList = () => async (dispatch: AppDispatch) => {
  dispatch(getLocalBusinessesStart());
  try {
    const localBusinesses = await httpWrapper<LocalBusinesses>(
      "GET",
      `${SMS_API_BASE}/seo/meo_businesses`,
      {}
    );
    dispatch(getLocalBusinessesSuccess(localBusinesses));
  } catch (e) {
    handleError(e);
    dispatch(getLocalBusinessesError());
  }
};

export const getSurveyMeoSiteSettings = (
  param: SurveyMeoSiteSettingsGetParam
) => async (dispatch: AppDispatch) => {
  dispatch(getSurveyMeoSiteSettingsStart());
  try {
    const surveyMeoSiteSettings = await httpWrapper<
      Paginated<SurveyMeoSiteSettings>
    >("GET", `${SMS_API_BASE}/meo_site_settings`, param);
    dispatch(getSurveyMeoSiteSettingsSuccess(surveyMeoSiteSettings));
  } catch (e) {
    handleError(e);
    dispatch(getSurveyMeoSiteSettingsError());
  }
};

export const getSurveyMeoSiteSettingsByLocalBusiness = (
  id: number,
  param: SurveyMeoSiteSettingsGetParam
) => async (dispatch: AppDispatch) => {
  dispatch(getSurveyMeoSiteSettingsStart());
  try {
    const surveyMeoSiteSettings = await httpWrapper<
      Paginated<SurveyMeoSiteSettings>
    >("GET", `${SMS_API_BASE}/meo_site_settings/${id}`, param);
    dispatch(getSurveyMeoSiteSettingsSuccess(surveyMeoSiteSettings));
  } catch (e) {
    handleError(e);
    dispatch(getSurveyMeoSiteSettingsError());
  }
};

export const updateSurveyMeoSiteSettings = (
  pageSize: number,
  page: number,
  form: Array<SurveyMeoSiteSettingsUpdateItem>
) => async (dispatch: AppDispatch) => {
  dispatch(updateSurveyMeoSiteSettingsStart());
  try {
    const surveyMeoSiteSettings = await httpWrapper<
      Paginated<SurveyMeoSiteSettings>
    >("PATCH", `${SMS_API_BASE}/meo_site_settings`, {
      pageSize,
      page,
      updateItems: form,
    });
    showNotification(i18n.t("meoSiteSettings.settingMail.success"));
    dispatch(updateSurveyMeoSiteSettingsSuccess(surveyMeoSiteSettings));
  } catch (e) {
    const error: any = e;
    handleError(error);
    dispatch(updateSurveyMeoSiteSettingsError(error));
  }
};

export const exportCsv = () => async (dispatch: AppDispatch) => {
  dispatch(exportStart());
  try {
    const result = await httpWrapper<any>(
      "POST",
      `${SMS_API_BASE}/meo_site_settings/export`,
      {}
    );
    download(`${BOM}${result}`, i18n.t("meoSiteSettings.csv.fileName"));
    dispatch(exportSuccess());
    return result;
  } catch (e) {
    handleError(e);
    dispatch(exportError());
  }
};

export const importCsv = (file: File) => async (dispatch: AppDispatch) => {
  dispatch(importStart());
  try {
    const formData = new FormData();
    formData.append("file", file);
    const result = await httpWrapper<RecipientEmailCsvImportResult>(
      "POST",
      `${SMS_API_BASE}/meo_site_settings/import`,
      formData,
      { "Content-Type": "multipart/form-data" }
    );
    dispatch(importSuccess(result));
    return result;
  } catch (e) {
    handleError(e);
    dispatch(importError());
  }
};

export const addMeoSites = (params: Array<SurveyMeoSitesAddForm>) => async (
  dispatch: AppDispatch
) => {
  dispatch(addMeoSitesStart());
  try {
    await httpWrapper("POST", `${SMS_API_BASE}/surveys/meo_sites`, params);
    showNotification(i18n.t("meoSites.table.settings.addSuccess"));
    dispatch(addMeoSitesSuccess());
  } catch (e) {
    const error: any = e;
    handleError(error);
    dispatch(addMeoSitesError(error));
  }
};
