import { LandingData, ReviewLinkColors } from "api/sms-api";
import { Modal, Layout, Row, Col, Card, Button } from "antd";
import React from "react";
import { getCardLayout } from "../../constants";
const { Content } = Layout;
import { Page } from "features/appstate/AppStateSlice";
import { LikeOutlined, GoogleOutlined } from "@ant-design/icons";

export function showPreview(
  data: LandingData,
  colors: ReviewLinkColors,
  handleOk: () => void
) {
  const cardLayout = getCardLayout(Page.landingPageSettings);
  Modal.success({
    width: 1200,
    icon: null,
    onOk: handleOk,
    content: (
      <Layout style={{ minHeight: "100vh" }}>
        <Content>
          <Row>
            <Col {...cardLayout}>
              <Card className="content-card">
                <div className="landing-page-content">
                  {data.logoUrl && (
                    <div className="logo">
                      <img src={data.logoUrl} />
                    </div>
                  )}
                  {
                    <div
                      className="message"
                      dangerouslySetInnerHTML={{
                        __html: data.message,
                      }}
                    />
                  }
                  {data.links.map((link, i) => {
                    let icon = null;
                    if (link.hasIcon) {
                      icon = link.url.startsWith("https://search.google") ? (
                        <GoogleOutlined />
                      ) : (
                        <LikeOutlined />
                      );
                    }

                    const matchingColor = colors.colors.find(
                      (c) => c.id === link.reviewLinkColorId
                    )!;

                    const style = {
                      background: matchingColor.hexCode,
                      borderColor: matchingColor.hexCode,
                    };
                    return (
                      <div key={i}>
                        <Button
                          className="review-link"
                          href={link.url}
                          type="primary"
                          size="large"
                          shape="round"
                          icon={icon}
                          style={style}
                        >
                          {link.text}
                        </Button>
                      </div>
                    );
                  })}
                </div>
              </Card>
            </Col>
          </Row>
        </Content>
      </Layout>
    ),
  });
}
