import { ActivityType } from "api/sms-api";

export default {
  common: {
    phoneNumber: "電話番号",
    name: "名前",
    message: "メッセージ",
    sendUserId: "送信者ID",
    create: "作成",
    save: "保存",
    saveAndCloseButton: "保存して閉じる",
    delete: "削除",
    cancel: "キャンセル",
    notFound: "データがありません。",
    unknownError: "内部エラーです。",
    addresses: "送信先",
    group: "送信先グループ",
    sendUserIdPlaceholder: "1つ以上の英字を含む、最大11文字の半角英数字",
    sendUserIdNote:
      "受信者がメッセージを受け取った時に、この値が送信者として表示されます。",
    confirm: "確認",
    messageNote:
      "全角46文字、半角140文字を超える長いメッセージは、複数のメッセージとして扱われます。",
    back: "戻る",
    select: "決定",
    addressDirect:
      "送信先（端末送信のメッセージ送信結果はお使いのモバイル端末でご確認ください）",
    search: "検索",
    download: "ダウンロード",
    csvExport: "CSVエクスポート",
    time: "日時",
    all: "すべて",
    loading: "取得中...",
    help: "ヘルプ",
    close: "閉じる",
    edit: "編集",
  },
  publishTypes: {
    directLink: "端末で送信",
    cloud: "クラウドで送信",
  },
  templateTypes: {
    all: "全て",
    store: "店舗作成メッセージ",
    gyron: "口コミ促進メッセージ(固定)",
  },
  mailTemplateContentTypes: {
    text: "text/plain",
    html: "text/html",
  },
  header: {
    remainingMessages: "クラウド送信 残り%{parts}通分",
    survey: "アンケートツール",
  },
  menu: {
    publish: "送信",
    scheduled: "予約",
    addresses: "連絡先",
    group: "グループ",
    templates: "送信テンプレート",
    replyTemplates: "返信テンプレート",
    history: "履歴",
    client: "設定",
    landingPageSettings: "クッションページ",
    statistics: "集計",
    reviews: "クチコミ",
    me: "Me",
    logout: "ログアウト",
    bookmarkQR: "管理画面をモバイルでブックマーク",
    survey: "アンケート",
    surveyAdd: "アンケート",
    surveyTemplate: "アンケート",
    surveyEdit: "アンケート",
    surveySettings: "設定",
    meoSites: "対象店舗",
    reviewMediaSettings: "媒体設定",
    meoSiteSettings: "店舗一覧",
    contractDetails: "契約内容",
    productSettings: "商品一覧",
    orderHistory: "購買者リスト",
    products: "対象商品",
    ecLinkStatus: "購入媒体",
    ecEmailSettings: "配信設定",
    orderSurveyEmailDetails: "送信メール",
    orderSurveyEmails: "送付履歴",
    emailSettings: "メール設定",
    mailTemplates: "メールテンプレート",
    mailTemplateSenders: "送付元アドレス",
    optoutPageTemplate: "オプトアウトページ",
    mailExclusionSettings: "除外設定",
  },
  messageForm: {
    type: "タイプ",
    recipientAdd: "送信先を追加する",
    messagePlaceholder: "口コミをお願いします",
    selectFromTemplate: "テンプレートから選ぶ",
    selectFromAddresses: "連絡先一覧から選ぶ",
    selectFromGroups: "グループ一覧から選ぶ",
    selectLandingPage: "クッションページのリンクを入れる",
    templateSave: "テンプレートとして保存する",
    timing: {
      label: "いつ送る",
      now: "今送信する",
      scheduled: "予約する",
    },
    scheduledTime: "送る時間",
    submit: "送信",
    schedule: "予約",
    // cloudMessageLengthNote: "メッセージの文字数制限140バイトまで",
    multiMessageNote: "%{parts}通分の送信料金がかかります。",
    multiMessageErrorNote:
      "クラウド送信の残り件数が%{allowed}件のため、%{parts}通分のメッセージを送信できません。",
    multiMessageMaxErrorNote:
      "送信先は最大100件です。%{parts}件の送信先が選択されています。",
    modal: {
      title: "テンプレート保存",
      saveTemplate: "保存",
      templateNamePlaceholder: "テンプレート名",
    },
    maxContacts: "送信先は最大100件です。",
    duplicatePhoneNumber: "送信先の電話番号が重複しています。",
  },
  directLink: {
    explanation:
      "リンク作成しました。ボタンをクリックして端末のアプリからメッセージを送信できます。",
    button: "メッセージアプリを開く",
  },
  cloudPublish: {
    success:
      "送信を受け付けました。送信結果は履歴画面をご確認ください。送信には数分かかることがあります。",
  },
  schedule: {
    scheduledTime: "予約時間",
    empty: "予約がありません。",
    editSuccess: "予約を保存しました。",
    deleteConfirm: "この予約を削除しますか？",
    deleteSuccess: "予約を削除しました。",
    createSuccess: "送信予約しました。",
  },
  addresses: {
    empty: "連絡先がありません",
    add: "連絡先を追加",
    createSuccess: "連絡先を作成しました。",
    editSuccess: "連絡先を保存しました。",
    deleteCountConfirm: "選択した%{count}件の連絡先を削除しますか？",
    deleteConfirm: "この連絡先を削除しますか？",
    deleteSuccess: "連絡先を削除しました。",
    namePlaceholder: "全角8文字、半角24文字まで",
    selectingExplanation: {
      group: "グループに登録したい連絡先のチェックボックスを選択してください。",
      sendForm: "追加したい連絡先のチェックボックスを選択してください。",
    },
    maxSelectGroups: "グループに登録できる連絡先は最大100件です。",
    maxSelectAddress: "選択できるのは最大100件です。",
    createdAt: "作成日時",
    import: "CSVインポート",
    importFile: "CSVファイル",
    importButtonText: "インポート",
    importSuccess: "連絡先を%{count}件インポートできました。",
    importErrors:
      "連絡先を%{count}件インポートできましたが、以下のエラーでインポートできなかった件もありました。",
    importErrorLine: "%{line}行: ",
    importErrorDescription: {
      /*eslint-disable */
      name_required: "名前が必須です",
      name_too_long: "名前は全角8文字、半角24文字まで",
      invalid_phone_number: "不正な電話番号",
      csv_read_error: "CSVの読み込み失敗しました",
      /*eslint-enable */
    },
    sampleDownload: "雛形CSVファイルをダウンロードする",
    restrictions: "配信制限",
    optedOut: "オプトアウト",
    blacklisted: "配信不能",
    urlViewExplanationDaily:
      "%{day}に短縮URLをクリックした連絡先を表示しています。",
    urlViewExplanationMonthly:
      "%{startDay}と%{endDay}の間に短縮URLをクリックした連絡先を表示しています。",
    landingPageViewExplanationDaily:
      "%{day}にクッションページをアクセスした連絡先を表示しています。",
    landingPageViewExplanationMonthly:
      "%{startDay}と%{endDay}の間にクッションページをアクセスした連絡先を表示しています。",
    reviewLinkClickExplanationDaily:
      "%{day}に「%{name}」のレビューリンクをクリックした連絡先を表示しています。",
    reviewLinkClickExplanationMonthly:
      "%{startDay}と%{endDay}の間に「%{name}」のレビューリンクをクリックした連絡先を表示しています。",
    reviewLinkClickAllExplanationDaily:
      "%{day}にレビューリンクをクリックした連絡先を表示しています。",
    reviewLinkClickAllExplanationMonthly:
      "%{startDay}と%{endDay}の間にレビューリンクをクリックした連絡先を表示しています。",
    tabs: {
      edit: "連絡先",
      activity: "アクティビティ",
    },
    activityType: "アクティビティ種類",
    activityTypes: {
      [ActivityType.UrlView]: "URLクリック",
      [ActivityType.LandingPageView]: "クッションページPV",
      [ActivityType.ReviewLinkClick]: "レビューリンククリック",
    },
    activityEmpty: "アクティビティがありません",
    landingPageName: "クッションページ",
    activityTimes: "%{times}回",
  },
  groups: {
    name: "グループ名",
    empty: "グループがありません",
    add: "グループを追加",
    createSuccess: "グループを作成しました。",
    editSuccess: "グループを保存しました。",
    deleteConfirm: "このグループを削除しますか？",
    deleteSuccess: "グループを削除しました。",
    namePlaceholder: "全角30文字、半角90文字まで",
    addresses: "連絡先",
    totalContacts: "連絡先(%{count}件)",
    selectFromAddress: "連絡先を選ぶ",
    selectedCount: "%{count}件の連絡先を選択中",
    selectingExplanation: {
      sendForm: "追加したいグループのチェックボックスを選択してください。",
    },
    maxSelectGroup: "選択できるグループは最大100件です。",
  },
  templates: {
    name: "名前",
    content: "内容",
    empty: "テンプレートがありません",
    add: "テンプレートを追加",
    createSuccess: "テンプレートを作成しました。",
    editSuccess: "テンプレートを保存しました。",
    deleteConfirm: "このテンプレートを削除しますか？",
    deleteSuccess: "テンプレートを削除しました。",
    selectingExplanation: "使いたいテンプレートをクリックしてください。",
    selected: "選択されたテンプレートを使っています",
    contentPlaceholder: "全角46文字、半角140文字まで",
    type: "タイプ",
  },
  replyTemplates: {
    add: "返信テンプレートを追加",
    createSuccess: "返信テンプレートを作成しました。",
    editSuccess: "返信テンプレートを保存しました。",
    deleteConfirm: "この返信テンプレートを削除しますか？",
    deleteSuccess: "返信テンプレートを削除しました。",
    selectingExplanation: "使いたい返信テンプレートをクリックしてください。",
    empty: "返信テンプレートがありません",
  },
  client: {
    type: "送信タイプ初期値",
    editSuccess: "保存しました。",
    sendUserIdNote:
      "ここで設定した値が、送信画面の「送信者ID」に表示されます(※クラウド送信時にのみ使用)",
  },
  history: {
    sentAt: "日付",
    empty: "履歴がありません。",
    type: "送信タイプ",
    cloud: "クラウド",
    scheduled: "予約",
    direct: "端末",
    messageParts: "メール件数",
    totalContacts: "送信先(%{count}件)",
    successContacts: "成功%{count}件",
    errorContacts: "失敗%{count}件",
  },
  contacts: {
    status: "ステータス",
    statuses: {
      error: "送信エラー",
      success: "送信成功",
      unsent: "未送信",
      sent: "送信中",
    },
    groups: "グループ",
  },
  landingPageSettings: {
    add: "クッションページを追加する",
    name: "ID",
    title: "タイトル",
    message: "メッセージ",
    logo: "トップ画像",
    logoEditNote:
      "トップ画像がまだ設定されていません。設定するには「保存」のボタンを押す必要があります。",
    createSuccess: "クッションページを作成しました。",
    deleteConfirm:
      "このクッションページを削除しますか？削除したら集計がなくなります。",
    deleteSuccess: "クッションページを削除しました。",
    editSuccess: "クッションページを保存しました。",
    preview: "プレビュー",
    selectingExplanation: "使いたいクッションページをクリックしてください。",
    tabs: {
      settings: "設定",
      reviewLinks: "レビューリンク",
    },
    default: "デフォルトクッションページに戻す",
    optOut: {
      promptStart: "当店からのSMS配信を希望しない場合は",
      promptLink: "こちら",
      promptEnd: "をタップしてください",
    },
    qrCode: "QRコード",
    copyUrl: "リンクをコピー",
    copyUrlNotification: "リンクをクリップボードにコピーしました。",
  },
  reviewLinks: {
    add: "レビューリンクを追加する",
    empty: "レビューリンクがありません。",
    url: "URL",
    active: "アクティブ",
    hasIcon: "👍  アイコンを表示する",
    connect: {
      gmb: "Googleビジネスプロフィールと連携する",
    },
    connectSuccess: "連携できました。",
    createSuccess: "レビューリンクを作成しました。",
    editSuccess: "レビューリンクを保存しました。",
    deleteConfirm: "このレビューリンクを削除しますか？",
    deleteSuccess: "レビューリンクを削除しました。",
    color: "色",
    sort: "順番",
  },
  validation: {
    required: "必須",
    phoneNumber: "不正な電話番号",
    sendUserId:
      "1つ以上の英字を含む、最大11文字の半角英数字で入力してください。",
    cloudPublishMaxLength: "クラウドで送信するメッセージは1600バイトまで",
    directPublishMaxLength: "端末で送信するメッセージは670文字まで",
    templateContentMaxLength: "内容は全角46文字、半角140文字まで",
    addressNameMaxLength: "名前は全角8文字、半角24文字まで",
    groupNameMaxLength: "名前は全角30文字、半角90文字まで",
    url: "URLを入力してください (httpまたはhttpsは必須です）",
    landingPageName: "最大10文字の半角英数字（小文字）で入力してください。",
    groupsMax: "端末送信の場合はグループは選択できません",
    forbiddenTags: "使えないHTMLタグが入っています。",
    contactMin: "送信先を最低1件設定してください。",
    mail: "正しいメールアドレスを入力してください。",
  },
  siteSelector: {
    title: "店舗を選んでください",
    select: "この店舗を使う",
  },
  statistics: {
    daily: "日別",
    monthly: "月別",
    total: "合計",
    dailyColumnTitle: "%{year}年%{month}月日別データ",
    monthlyColumnTitle: "%{year}年月別データ",
    cloudMessagesSent: "クラウド送信数",
    urlViews: "クリック数",
    ctr: "CTR",
    landingPageViews: "クッションページPV数",
    reviewLinkClicks: "口コミボタンクリック数（%{name}）",
    reviewLinkClicksTotal: "口コミボタンクリック数（計）",
    landingPageAll: "全部のクッションページ",
    gmbReviews: "口コミ数 (Google)",
    gmbPercent: "口コミ率 (Google)",
  },
  reviews: {
    userName: "ユーザー名",
    rating: "評価",
    ratingDesc: "評価 (降順)",
    ratingAsc: "評価 (昇順)",
    content: "クチコミ内容",
    reply: "返信",
    empty: "クチコミがありません。",
    ratingValues: {
      FIVE: "5",
      FOUR: "4",
      THREE: "3",
      TWO: "2",
      ONE: "1",
      STAR_RATING_UNSPECIFIED: "",
    },
    replyComment: "返信内容",
    doReply: "返信する",
    doReplySuccess: "返信しました。",
    updateReply: "返信を更新する",
    updateSuccess: "返信を更新しました。",
    updateTime: "更新日時",
    sortOrder: "ソート順",
    deleteReply: "返信を削除",
    deleteConfirm: "この返信を削除しますか？",
    deleteSuccess: "返信を削除しました。",
    noComment: "このユーザーは評価のみ投稿しました。",
    leaveConfirm: "返信はまだ投稿されていませんがよろしいですか？",
    selectFromTemplate: "テンプレートから選ぶ",
    multiReply: "複数返信する（%{count}）",
    multiReplyRecipients: "ユーザー名：",
    multiReplyExplanation: "%{count}件クチコミを返信します。",
    multiNoneSelected: "返信対象クチコミがありません。",
  },
  survey: {
    surveyTypeEc: "EC",
    surveyTypeStore: "店舗",
    table: {
      title: {
        publish: "公開",
        title: "タイトル",
      },
      item: {
        untitled: "(タイトルなし)",
        qrCode: "QRコード",
        settings: "設定",
        meoSites: "対象店舗",
        reviewMediaSettings: "媒体設定",
        products: "対象商品",
        ecEmailSettings: "配信設定",
      },
      settings: {
        add: "作成",
        duplicate: "複製",
        deleteCountConfirm: "選択した%{count}件を削除しますか？",
        deleteSuccess: "アンケートを削除しました。",
      },
    },
    createSuccess: "アンケートを作成しました。",
    createError: "アンケートの作成に失敗しました。",
    editSuccess: "アンケートを保存しました。",
    previous: "戻る",
    next: "次へ",
    createNew: "新規作成",
    createTemplate: "テンプレートから作成",
    questionCategoriesTitle: {
      branchQuestionItem: "分岐選択肢",
      groupQuestionItem: "質問グループ",
      baseQuestionItem: "基本情報",
      customQuestionItem: "質問項目",
      selectedQuestions: "アンケート項目：%{count}",
    },
    topPageTitle: "アンケートTOP見出し",
    topPageNote: "アンケートTOP注意事項",
    defaultTopPageNote: `
    <p>
      このアンケートは当店をご利用いただいた方を対象としています。<br></br>
      アンケートに回答することで得られるデータは、当店のサービス改善に活用させていただきます。<br><br>
      ご同意いただいた上でアンケートにお答えください。
    </p>
    `,
    publish: "公開設定",
    randomDisplay: "ランダム設定",
    randomDisplayCount: "表示設定数：%{count}",
    customPrompt: "プロンプト追加注意",
    randomQuestionsOrder: "QAシャッフル",
    randomSettingText1:
      "ランダム設定は、アンケート項目の任意項目から、ランダムに質問を表示します。",
    randomSettingText2: "ランダム表示する質問の数",
    randomSettingText3:
      "※ 任意項目数が、ランダム表示設定数に満たない場合は、ランダム出力されません。任意項目を多く作成しておくことで、より多彩なアンケートとなり、口コミに言及される種類が増えます。",
    selectGptModel: "アルゴリズム設定",
    selectGptModelText: "キキコミの生成文アルゴリズムを選択してください。",
    selectGptModelOption: "GPT4校正",
    selectImpressionPrompt: "印象変換",
    impressionPromptMode: {
      random: "ランダムに適用",
      always: "常に適用",
    },
    selectLanguage: "言語設定",
    surveyTitle: "アンケートタイトル",
    logo: "ロゴ",
    recipientEmail: "アンケート送付先",
    googleLink: "Google口コミ投稿リンクURL",
    displayRequired: "表示必須",
    inputRequired: "入力必須",
    surveyOnly: "アンケート専用",
    archive: "不採用",
    questionDelete: "削除",
    required: "必須",
    questionText: {
      male: "男性",
      female: "女性",
      noAnswer: "無回答",
      age: "歳",
      conditions: "規約に同意する",
      teenagers: "10代",
      twenties: "20代",
      thirties: "30代",
      forties: "40代",
      fifties: "50代",
      seniors: "60代以上",
      notSet: "しない",
      random: "ランダム",
      flee: "ウィジウィグをアクティブにする。",
      selectAll: "all",
    },
    reviewMediaType: {
      google: "Google",
      other: "Google以外",
    },
    reviewMediaName: "媒体名",
    editPlaceholder: "プレースホルダーの編集ができます。",
    prefecturesPlaceholder: "都道府県を選択してください。",
    freeTextLabel: "最後の選択肢に複数行フィールドを設置する",
    notPrompt: "not prompt",
    completionMessageLabel: "アンケート終了テキスト",
    exceededCompletionMessageLabel:
      "アンケート終了テキスト（生成アンケート数超過の場合）",
    requestReviewMessageLabel: "クチコミ案内テキスト",
    questionEditModalTitle: "質問編集",
    questionsActions: {
      fileName: "questions",
      download: "質問データをJSONでダウンロード",
      upload: "JSONで質問を登録",
      createJson: "新規登録",
      addJson: "追加登録",
    },
    uploadModalNote:
      "すでに登録済みの質問データは削除されます。ご注意ください。",
    updateModalNote: "現在の質問の後方に追加されます。",
    waitDaysTitle: "商品購入日からの経過日数",
    sendTimeTitle: "配信時間（目安）",
    errors: {
      groupItemEmpty:
        "質問が設定されていないグループがあります。必ず質問を設定してください。",
      jsonParseError: "入力されたJSONデータが正しくありません",
      missingProperty: "プロパティが設定されていません",
      invalidValue: "不正な値です",
      invalidFormType: "不正なformTypeです",
    },
    editSendTimePlaceholder: "時間を選んでください。",
  },
  meoSites: {
    table: {
      title: {
        meoSiteName: "店舗名",
        url: "アンケートURL",
        mediaName: "媒体",
        reviewLink: "口コミURL",
      },
      item: {
        surveyTitle: "アンケート名：%{title}",
        qrCode: "QRコード",
        reviewLink: "口コミURL編集",
        defaultUrl: "https://www.gyro-n.com/portal/kikicomi/dummygbp.html",
        mediaNameGoogle: "Google",
      },
      settings: {
        add: "追加",
        addSuccess: "対象店舗を追加しました。",
        deleteCountConfirm: "選択した%{count}件を削除しますか？",
        deleteSuccess: "対象店舗を削除しました。",
        import: "CSVインポート",
        qrFileName: "様QR",
      },
    },
    csv: {
      fileName: "対象店舗.csv",
      importButtonText: "インポート",
      sampleDownload: "雛形CSVファイルをダウンロードする",
      importFile: "CSVファイル",
      importSuccess: "対象店舗を%{count}件インポートできました。",
      importErrors:
        "対象店舗を%{count}件インポートできましたが、以下のエラーでインポートできなかった件もありました。",
      importErrorLine: "%{line}行: ",
      importErrorDescription: {
        survey_id_required: "アンケートIDが必須です",
        meo_site_id_required: "店舗IDが必須です",
        csv_read_error: "CSVの読み込み失敗しました",
      },
      success: "対象店舗を設定しました。",
    },
  },
  meoSiteSettings: {
    table: {
      filter: {
        localBusinesses: "ローカルビジネス",
      },
      title: {
        meoSiteName: "店舗名",
        recipientEmail: "アンケート送付先",
        url: "管理画面URL",
      },
      item: {
        url: "Copy URL",
        copyUrlNotification: "リンクをクリップボードにコピーしました。",
        qr: "Show QR code",
      },
      settings: {
        recipientEmail: "アンケート送付先設定",
        import: "CSVインポート",
        selectingExplanation:
          "追加したい店舗のチェックボックスを選択してください。",
      },
    },
    settingMail: {
      success: "アンケート送付先を設定しました。",
      inputMailPlaceholder: "送付メールアドレスを改行ごとに入力してください。",
    },
    csv: {
      fileName: "店舗一覧.csv",
      importButtonText: "インポート",
      sampleDownload: "雛形CSVファイルをダウンロードする",
      importFile: "CSVファイル",
      importSuccess: "送付メールアドレスを%{count}件インポートできました。",
      importErrors:
        "送付メールアドレスを%{count}件インポートできましたが、以下のエラーでインポートできなかった件もありました。",
      importErrorLine: "%{line}行: ",
      importErrorDescription: {
        meo_site_id_required: "店舗IDが必須です",
        invalid_mail_address: "不正なメールアドレスです",
        csv_read_error: "CSVの読み込み失敗しました",
      },
      success: "アンケート送付先を設定しました。",
    },
  },
  products: {
    table: {
      title: {
        productCode: "商品番号",
        productName: "商品名",
        url: "アンケートURL",
        mediaName: "媒体",
        reviewLink: "口コミURL",
      },
      item: {
        surveyTitle: "アンケート名：%{title}",
        qrCode: "QRコード",
        reviewLink: "口コミURL編集",
        defaultUrl: "https://www.gyro-n.com/portal/kikicomi/dummygbp.html",
        mediaNameGoogle: "Google",
      },
      settings: {
        add: "追加",
        addSuccess: "商品を追加しました。",
        deleteCountConfirm: "選択した%{count}件を削除しますか？",
        deleteSuccess: "対象商品を削除しました。",
        import: "CSVインポート",
        addSettingsSuccess: "対象商品を追加しました。",
        deleteSettingsSuccess: "商品を削除しました。",
        editSuccess: "商品を保存しました。",
      },
    },
    csv: {
      fileName: "対象商品.csv",
      importButtonText: "インポート",
      sampleDownload: "雛形CSVファイルをダウンロードする",
      importFile: "CSVファイル",
      importSuccess: "対象商品を%{count}件インポートできました。",
      importErrors:
        "対象商品を%{count}件インポートできましたが、以下のエラーでインポートできなかった件もありました。",
      importErrorLine: "%{line}行: ",
      importErrorDescription: {
        survey_id_required: "アンケートIDが必須です",
        client_next_engine_product_id_required: "商品IDが必須です",
        csv_read_error: "CSVの読み込み失敗しました",
      },
      success: "対象商品を設定しました。",
    },
  },
  productSettings: {
    table: {
      filter: {
        ecProviders: "媒体",
      },
      title: {
        productCode: "商品番号",
        productName: "商品名",
        reviewPageUrl: "クチコミURL",
        recipientEmail: "アンケート結果送付先",
        url: "管理画面URL",
        ecProviderId: "媒体",
      },
      item: {
        url: "Copy URL",
        copyUrlNotification: "リンクをクリップボードにコピーしました。",
        qr: "Show QR code",
        untitled: "(タイトルなし)",
      },
      settings: {
        add: "商品の追加",
        recipientEmail: "アンケート結果送付先設定",
        import: "CSVインポート",
        addSuccess: "商品を追加しました。",
        selectingExplanation:
          "追加したい商品のチェックボックスを選択してください。",
      },
    },
    settingMail: {
      success: "アンケート結果送付先を設定しました。",
      inputMailPlaceholder: "送付メールアドレスを改行ごとに入力してください。",
    },
    csv: {
      fileName: "商品一覧.csv",
      importButtonText: "インポート",
      sampleDownload: "雛形CSVファイルをダウンロードする",
      importFile: "CSVファイル",
      importSuccess: "商品を%{count}件インポートできました。",
      importErrors:
        "商品を%{count}件インポートできましたが、以下のエラーでインポートできなかった件もありました。",
      importErrorLine: "%{line}行: ",
      importErrorDescription: {
        product_code_required: "商品番号が必須です",
        product_name_required: "商品名が必須です",
        csv_read_error: "CSVの読み込み失敗しました",
      },
      success: "アンケート結果送付先を設定しました。",
    },
  },
  contractDetails: {
    company: "法人名",
    account: "Gyro-nアカウント",
    plan: "キキコミ契約プラン",
    count: "クチコミ生成数/アカウント",
    month: "（%{month}/月）",
    storeName: "ECサイト名",
    storeLogo: "ロゴ",
  },
  ecLinkStatus: {
    noEcLinks: "購入媒体がありません。",
    next_engine: "ネクストエンジン",
    dfm: "DFM",
  },
  orders: {
    table: {
      title: {
        details: "注文内容",
        orderId: "注文番号",
        purchasedAt: "購入日",
        purchaserName: "名前",
        emailAddress: "メールアドレス",
        products: "購入商品",
        ecProvider: "媒体",
        productCount: "対象アイテム数",
        surveyCount: "アンケート数",
        emailSentCount: "送信予定と完了数",
        productName: "アイテム",
        reviewLink: "アンケートURL",
        sendAt: "送信予定日",
        detailsProductName: "購入商品",
        detailsProductCode: "商品番号",
      },
      settings: {
        import: "CSVインポート",
      },
    },
    csv: {
      fileName: "購買一覧.csv",
      importButtonText: "インポート",
      sampleDownload: "雛形CSVファイルをダウンロードする",
      importFile: "CSVファイル",
      importSuccess: "購買を%{count}件インポートできました。",
      importErrors:
        "購買を%{count}件インポートできましたが、以下のエラーでインポートできなかった件もありました。",
      importErrorLine: "%{line}行: ",
      importErrorDescription: {
        order_id_required: "注文番号が必須です",
        product_code_required: "商品番号が必須です",
        email_address_required: "メールアドレスが必須です",
        purchased_at_required: "購入日が必須です",
        ec_provider_required: "媒体名が必須です",
        csv_read_error: "CSVの読み込み失敗しました",
      },
    },
  },
  orderSurveyEmails: {
    table: {
      title: {
        subject: "件名",
        emailAddress: "宛先",
        sentAt: "送信日時",
        sendAt: "送信予定",
        orderId: "注文番号",
        openStatus: "開封",
        sendStatus: "成功",
        clickCount: "クリック",
      },
    },
    Open_Status_Read: "○",
    Open_Status_Unread: "×",
    Send_Status_Success: "○",
    Send_Status_Failure: "×",
    emailTitle: "送付メール %{number}",
    noOrderSurveyEmails: "送付メールがありません。",
  },
  mailTemplate: {
    table: {
      title: {
        name: "テンプレート名",
        title: "メールタイトル",
        from: "From",
        bcc: "BCC",
        contentType: "タイプ",
        contents: "メール本文",
      },
      item: {
        untitled: "(タイトルなし)",
        tags: "差し込み文章",
      },
      settings: {
        add: "作成",
        delete: "削除",
        deleteCountConfirm: "選択した%{count}件を削除しますか？",
        addSuccess: "メールテンプレートを追加しました。",
      },
    },
    createSuccess: "メールテンプレートを作成しました。",
    editSuccess: "メールテンプレートを保存しました。",
    deleteConfirm: "このメールテンプレートを削除しますか？",
    deleteSuccess: "メールテンプレートを削除しました。",
    sendTestMailSuccess: "テストメールを送信しました。",
    selected: "選択されたメールテンプレートを使っています",
    editMailTemplateSenderPlaceholder: "送信元を選んでください。",
    tags: {
      reviewLink: "クチコミURL",
      reviewPageUrl: "アンケートURL",
      optoutLink: "メール配信停止URL",
      storeName: "ECサイト名",
      storeLogo: "ロゴ",
    },
    optoutPageTemplate: {
      title: "オプトアウトページ",
      editSuccess: "オプトアウトページを保存しました。",
      preview: "プレビュー",
    },
    testEmail: {
      title: "テストメールを送付",
      emailAddress: "メールアドレス",
      button: "テストメールを送信",
      send: "送付",
      cancel: "閉じる",
    },
  },
  mailTemplateSender: {
    table: {
      title: {
        name: "表示名",
        senderEmail: "送信元アドレス",
        replyEmail: "返信元アドレス",
      },
      settings: {
        add: "作成",
        edit: "編集",
        delete: "削除",
        deleteCountConfirm: "選択した%{count}件を削除しますか？",
        addSuccess: "送付元アドレスを追加しました。",
      },
    },
    createSuccess: "送信元アドレスを作成しました。",
    editSuccess: "送信元アドレスを保存しました。",
    deleteConfirm: "この送信元アドレスを削除しますか？",
    deleteSuccess: "送信元アドレスを削除しました。",
    selectingExplanation: "使いたい送信元アドレスをクリックしてください。",
    selected: "選択された送信元アドレスを使っています",
  },
  mailExclusionSettings: {
    title: "除外設定",
    setting: "配信除外設定",
    emailList: "除外リスト",
    allowAll: "除外しない",
    sameProductExclude: "同一商品購入を除外",
    add: "追加する",
    addEmailList: {
      title: "除外リスト",
      newList: "新規登録",
      updateList: "追加登録",
      newDescription: "既に登録済みのリストは削除されます、ご注意ください",
      updateDescription: "新しく追加されます",
      placeholder: "例）example@kikicomi.com",
    },
    createSuccess: "除外リストを登録しました。",
    editSuccess: "配信除外設定を保存しました。",
    updateSuccess: "除外リストを追加しました。",
  },
  errors: {
    unknown: "エラーが発生しました。",
    couldNotConnect: "連携ができませんでした。",

    smsNotEnabled: "選択された店舗はGyro-n Reviewの契約がありません。",
    noContractBody: "このアプリを使うにはGyro-n Reviewの契約が必要です。",
    noContractLink: "ご契約画面はこちら",
    noMEOSites: {
      message1: "Gyro-n Reviewにて選択できる店舗がありません。",
      message2: "MEO管理画面の「",
      message3: "Gyro-n Review 管理",
      message4: "」から、店舗のReview機能が有効になっているかご確認ください。",
    },
    addressDuplicate:
      "エラーが発生しました。この電話番号は既に登録されています。",
    optedOut: "この電話番号はお客様が配信停止に設定しています。",
    blacklisted: "この電話番号は配信不能リストに入っています。",
    optedOutGroup:
      "このグループはお客様が配信停止に設定した電話番号が含まれています。",
    blacklistedGroup: "このグループは配信不能の電話番号が含まれています。",
    onlyOneContactAllowed: "端末送信は送信先1人までです。",
    duplicateLandingPageName:
      "同じIDのクッションページは既に登録されています。",
    landingPageNotFound: "存在しないクッションページID：%{names}",
    contactGroupOverlap:
      "送信先の名前がグループに登録されたものと一致しません。",
    cannotUpdateGroupHasUnsentSchedule:
      "未送信の予約があるため、グループを更新できません。",
    cannotDeleteGroupHasUnsentSchedule:
      "未送信の予約があるため、グループを削除できません。",
    cannotUpdateAddressHasUnsentSchedule:
      "連絡先が登録されたグループに、未送信の予約があるため更新できません。",
    cannotDeleteAddressHasUnsentSchedule:
      "連絡先が登録されたグループに、未送信の予約があるため削除できません。",
    cannotMultipleDeleteAddressHasUnsentSchedule:
      "連絡先[%{name}]が登録されたグループに、未送信の予約があるため削除できません。",
    notEnoughContact: "送信先を1件以上設定してください。",
    notEnoughQuota: "クラウド送信の残り件数が足りません。",
  },
};
