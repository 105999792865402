import { Page } from "features/appstate/AppStateSlice";
import i18n from "i18n";

export const SSO_BASE = process.env.REACT_APP_SSO_BASE;
export const SMS_API_BASE = process.env.REACT_APP_SMS_API_BASE;
export const LANDING_PAGE_BASE = process.env.REACT_APP_LANDING_PAGE_BASE;
export const SURVEY_PAGE_BASE = process.env.REACT_APP_SURVEY_BASE;
export const UI_BASE = process.env.REACT_APP_UI_BASE;
export const BILLING_BASE = process.env.REACT_APP_BILLING_BASE;
export const URL_SERVICE_BASE = process.env.REACT_APP_URL_SERVICE_BASE;
export const APP_VERSION = process.env.REACT_APP_VERSION;
export const SEO_UI_BASE = process.env.REACT_APP_SEO_UI_BASE;
export const KIKICOMI_BASE = process.env.REACT_APP_KIKICOMI_BASE;

export const MODE_SURVEY = "survey";

// This for padding around the main content card
const cardPaddingXl = 4;
const cardPaddingLg = 0;
const cardPaddingMd = 0;
export const getCardLayout = (page: Page) => {
  if (page === Page.statistics) {
    return {
      xs: { span: 24 },
      md: { span: 22, offset: 1 },
    };
  }
  return {
    xs: { span: 24 },
    md: { span: 24 - cardPaddingMd * 2, offset: cardPaddingMd },
    lg: { span: 24 - cardPaddingLg * 2, offset: cardPaddingLg },
    xl: { span: 24 - cardPaddingXl * 2, offset: cardPaddingXl },
  };
};

export const formItemLayout = {
  labelCol: {
    xs: 24,
    sm: 6,
  },
  wrapperCol: {
    xs: 24,
    sm: 14,
  },
};

export const mobileMediaQuery = "(max-width: 576px)";

export const defaultMessage = "\n\n配信停止：${optout}";

export const BOM = "\ufeff";

export const defaultRadioItemsValue = [
  { name: "radiogroup1", value: "選択肢１" },
  { name: "radiogroup2", value: "選択肢２" },
  { name: "radiogroup3", value: "選択肢３" },
];
export const defaultPromptRadioItemsValue = [
  { name: "radiogroup1", value: "特になし", notPrompt: true },
  { name: "radiogroup2", value: "感想を書く" },
];
export const defaultGenerationItems = [
  {
    name: "radiogroup1",
    value: i18n.t("survey.questionText.teenagers"),
  },
  {
    name: "radiogroup2",
    value: i18n.t("survey.questionText.twenties"),
  },
  {
    name: "radiogroup3",
    value: i18n.t("survey.questionText.thirties"),
  },
  {
    name: "radiogroup4",
    value: i18n.t("survey.questionText.forties"),
  },
  {
    name: "radiogroup5",
    value: i18n.t("survey.questionText.fifties"),
  },
  {
    name: "radiogroup6",
    value: i18n.t("survey.questionText.seniors"),
  },
  {
    name: "radiogroup7",
    value: i18n.t("survey.questionText.noAnswer"),
  },
];

export const defaultCheckboxItemsValue = [
  { name: "checkbox1", value: "選択肢１" },
  { name: "checkbox2", value: "選択肢２" },
  { name: "checkbox3", value: "選択肢３" },
];

export const surveyTypeStore = "store";
export const surveyTypeEc = "ec";
export const proofReadingModel = "キキコミGPT";

export const PAGE_LIMIT = 100;

export const timeFormat = "HH:mm:ss";

export const optoutPageTemplateTagItems = {
  [i18n.t("mailTemplate.tags.storeName")]: "${storeName}",
  [i18n.t("mailTemplate.tags.storeLogo")]: "${storeLogo}",
};

export const mailTemplateTagItems = {
  [i18n.t("mailTemplate.tags.reviewLink")]: "${reviewLink}",
  [i18n.t("mailTemplate.tags.optoutLink")]: "${optoutLink}",
  ...optoutPageTemplateTagItems,
};

export enum ImpressionPromptMode {
  Random = "random",
  Always = "always",
}

export const ecAllowAll = "off";
export const ecSameProductExclude = "same_product";
export const ecOptoutTypeStore = "store";
export const mailExclusionAddMode = "MAIL_EXCLUSION_ADD_MODE";
export const mailExclusionUpdateMode = "MAIL_EXCLUSION_UPDATE_MODE";
