import React from "react";
import { connect } from "react-redux";
import {
  ReviewLink,
  ReviewLinkColors,
  ReviewLinkEditForm,
  ReviewLinkType,
} from "api/sms-api";
import {
  getReviewLink,
  editReviewLink,
  deleteReviewLink,
  getColors,
} from "./ReviewLinkActions";
import { clearReviewLink } from "./ReviewLinkSlice";
import { RootState } from "store/store";
import { Page, setPage } from "features/appstate/AppStateSlice";
import { Spin, Row, Popconfirm } from "antd";
import { CustomError, ErrorWrapper } from "errors/errors";
import { Formik } from "formik";
import { Form, Input, Checkbox, Select } from "formik-antd";
import i18n from "i18n";
import { reviewLinkEditFormSchema } from "validation";
import { formItemLayout } from "../../constants";
import { useParams } from "react-router-dom";
import NotFound from "components/NotFound";
import BackButton from "components/BackButton";
import SaveButton from "components/SaveButton";
import { pushTo } from "utils/navigation";
import { useHistory } from "react-router-dom";
import ColorOption from "./ColorOption";
import DeleteButton from "components/DeleteButton";

interface ReviewLinkEditProps {
  getLoading: boolean;
  editLoading: boolean;
  reviewLink?: ReviewLink;
  error?: ErrorWrapper;
  getReviewLink: (landingPageSettingsId: string, id: string) => void;
  editReviewLink: (
    landingPageSettingsId: string,
    id: string,
    form: ReviewLinkEditForm
  ) => Promise<void>;
  deleteReviewLink: (
    landingPageSettingsId: string,
    id: string
  ) => Promise<void>;
  clearReviewLink: () => void;
  setPage: (page: Page) => void;
  colors?: ReviewLinkColors;
  colorsLoading: boolean;
  getColors: () => void;
}

const ReviewLinkEdit: React.FC<ReviewLinkEditProps> = (props) => {
  const history = useHistory();
  const { lpsId, id } = useParams() as any;

  React.useEffect(() => {
    props.setPage(Page.landingPageSettings);
  }, [props.setPage]);

  React.useEffect(() => {
    props.getReviewLink(lpsId, id);
  }, [props.getReviewLink, lpsId, id]);

  React.useEffect(() => {
    props.getColors();
  }, [props.getColors]);

  React.useEffect(() => {
    // Run on unmount
    return () => {
      props.clearReviewLink();
    };
  }, [props.clearReviewLink]);

  if (props.error?.error === CustomError.NotFound) {
    return <NotFound />;
  }

  if (props.getLoading || !props.reviewLink || props.colorsLoading) {
    return <Spin />;
  }

  const initialValues: ReviewLinkEditForm = {
    text: props.reviewLink?.text || "",
    url: props.reviewLink?.url || "",
    active: props.reviewLink?.active || false,
    reviewLinkColorId: props.reviewLink?.reviewLinkColorId || "",
    hasIcon: props.reviewLink?.hasIcon || false,
  };

  const handleSubmit = async (form: ReviewLinkEditForm) => {
    const finalForm = { ...form };
    // Cannot edit URL
    delete finalForm.url;
    await props.editReviewLink(lpsId, id, finalForm);
    pushTo(history, `/${Page.landingPageSettings}/${lpsId}/reviewLinks`, {});
  };

  const handleDelete = async () => {
    await props.deleteReviewLink(lpsId, id);
    pushTo(history, `/${Page.landingPageSettings}/${lpsId}/reviewLinks`, {});
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={reviewLinkEditFormSchema}
      >
        {(_formikBag) => {
          return (
            <Form {...formItemLayout}>
              <Form.Item name="text" label={i18n.t("common.message")}>
                <Input name="text" />
              </Form.Item>
              <Form.Item name="url" label={i18n.t("reviewLinks.url")}>
                <Input name="url" disabled={true} />
              </Form.Item>
              <Form.Item
                name="reviewLinkColorId"
                label={i18n.t("reviewLinks.color")}
              >
                <Select className="color-picker" name="reviewLinkColorId">
                  {props.colors!.colors.map((color) => (
                    <Select.Option key={color.id} value={color.id}>
                      <ColorOption color={color} />
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name="active" label={i18n.t("reviewLinks.active")}>
                <Checkbox name="active" />
              </Form.Item>
              {props.reviewLink &&
                props.reviewLink.reviewLinkType !==
                  ReviewLinkType.GoogleMyBusiness && (
                  <Form.Item
                    name="hasIcon"
                    label={i18n.t("reviewLinks.hasIcon")}
                  >
                    <Checkbox name="hasIcon" />
                  </Form.Item>
                )}
              <Row className="form-button-row">
                <SaveButton loading={props.editLoading} />
                <BackButton />
                <Popconfirm
                  title={i18n.t("reviewLinks.deleteConfirm")}
                  onConfirm={handleDelete}
                  okText={i18n.t("common.delete")}
                  cancelText={i18n.t("common.cancel")}
                  okType="danger"
                >
                  <DeleteButton loading={props.editLoading} />
                </Popconfirm>
              </Row>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

const mapStateToProps = (root: RootState) => {
  return {
    reviewLink: root.reviewLinks.item,
    getLoading: root.reviewLinks.getLoading,
    editLoading: root.reviewLinks.editLoading,
    error: root.reviewLinks.error,
    colors: root.reviewLinks.colors,
    colorsLoading: root.reviewLinks.colorsLoading,
  };
};

const mapDispatchToProps = {
  getReviewLink,
  editReviewLink,
  deleteReviewLink,
  clearReviewLink,
  setPage,
  getColors,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReviewLinkEdit);
