import React, { useState, useEffect } from "react";
import { Page, setPage } from "features/appstate/AppStateSlice";
import { RootState } from "store/store";
import { connect } from "react-redux";
import { useHistory, useLocation, RouteComponentProps } from "react-router-dom";
import { Table, Row, Col, Button, Spin } from "antd";
import i18n from "i18n";
import { Paginated, Order, PaginationParams } from "api/sms-api";
import { PAGE_LIMIT } from "../../constants";
import _ from "lodash";
import {
  setSelectedOrders,
  clearSelectedOrders,
  clearItems,
} from "./OrderHistorySlice";
import OrderDetailsDialog from "./OrderDetailsDialog";
import { getOrderHistory, exportCsv } from "./OrderHistoryActions";
import qs from "qs";
import SimplePagination, {
  PaginatedComponentProps,
} from "components/SimplePagination";
import SMSLink from "components/SMSLink";
import { MODE_SURVEY, surveyTypeStore } from "../../constants";
import moment from "moment";
import { ImportOutlined, ExportOutlined } from "@ant-design/icons";

interface OrderHistoryProps {
  items: Paginated<Order>;
  listLoading: boolean;
  getOrderHistory: (param: PaginationParams) => void;

  setPage: (page: Page) => void;

  selectedOrders: Array<Order>;
  setSelectedOrders: (selected: Array<Order>) => void;
  clearSelectedOrders: () => void;
  clearItems: () => void;

  exportCsv: () => void;
}

const Orders: React.FC<
  OrderHistoryProps & RouteComponentProps & PaginatedComponentProps
> = (props) => {
  const history = useHistory();
  const location = useLocation();
  const parsed = qs.parse(location.search, { ignoreQueryPrefix: true });
  const [isOrderDetailsModalOpen, setIsOrderDetailsModalOpen] = useState(false);
  const [orderId, setOrderId] = useState<string>("");

  useEffect(() => {
    props.setPage(Page.orderHistory);
  }, [props.setPage]);

  useEffect(() => {
    const params = {
      ..._.omit(parsed, ["before", "after"]),
    };
    props.getOrderHistory({ ...params, limit: PAGE_LIMIT });
  }, [props.getOrderHistory]);

  React.useEffect(() => {
    return () => {
      props.clearItems();
      props.clearSelectedOrders();
    };
  }, []);

  if (props.listLoading) {
    return <Spin />;
  }

  const columns = () => [
    {
      title: i18n.t("orders.table.title.orderId"),
      dataIndex: "orderId",
      key: "orderId",
      render: (_: any, item: Order) => {
        return (
          <Button type="link" onClick={onClickOrderDetailsButton(item.id)}>
            {item.orderId}
          </Button>
        );
      },
    },
    {
      title: i18n.t("orders.table.title.purchasedAt"),
      dataIndex: "purchasedAt",
      key: "purchasedAt",
      render: (_: any, item: Order) => {
        return moment(item.purchasedAt).format("YYYY-MM-DD HH:mm:ss");
      },
    },
    {
      title: i18n.t("orders.table.title.emailAddress"),
      dataIndex: "emailAddress",
      key: "emailAddress",
    },
    {
      title: i18n.t("orders.table.title.ecProvider"),
      dataIndex: "ecProvider",
      key: "ecProvider",
      render: (_: any, item: Order) => {
        return i18n.t(`ecLinkStatus.${item.ecProvider}`);
      },
    },
    {
      title: i18n.t("orders.table.title.productCount"),
      dataIndex: "productCount",
      key: "productCount",
    },
    {
      title: i18n.t("orders.table.title.surveyCount"),
      dataIndex: "surveyCount",
      key: "surveyCount",
    },
    {
      title: i18n.t("orders.table.title.emailSentCount"),
      dataIndex: "emailSentCount",
      key: "emailSentCount",
      render: (_: any, item: Order) => {
        return (
          <SMSLink
            to={`/${Page.orderHistory}/orderSurveyEmailDetails`}
            queryParams={{
              id: item.id,
              mode: MODE_SURVEY,
              surveyType: parsed.surveyType || surveyTypeStore,
            }}
          >
            {`${item.emailSentCount}/${item.totalEmailCount}`}
          </SMSLink>
        );
      },
    },
  ];

  const selectedRowKeys = props.selectedOrders.map(({ id }) => id);

  const handleSelect = (record: Order, isSelected: boolean) => {
    if (isSelected) {
      // Adding
      const newSelected = [
        ...props.selectedOrders,
        {
          id: record.id,
          clientId: record.clientId,
          orderId: record.orderId,
          purchasedAt: record.purchasedAt,
          emailAddress: record.emailAddress,
          ecProvider: record.ecProvider,
          productCount: record.productCount,
          surveyCount: record.surveyCount,
          emailSentCount: record.emailSentCount,
          totalEmailCount: record.totalEmailCount,
          createdAt: record.createdAt,
          updatedAt: record.updatedAt,
        },
      ];
      props.setSelectedOrders(newSelected);
    } else {
      // Removing
      let current = [...props.selectedOrders];
      current = current.filter((c) => c.id !== record.id);
      props.setSelectedOrders(current);
    }
  };

  const handleSelectAll = (isSelected: boolean, changeRows: Array<Order>) => {
    if (isSelected) {
      // Add all
      const newSelections = changeRows.map((row) => {
        return {
          id: row.id,
          clientId: row.clientId,
          orderId: row.orderId,
          purchasedAt: row.purchasedAt,
          emailAddress: row.emailAddress,
          ecProvider: row.ecProvider,
          productCount: row.productCount,
          surveyCount: row.surveyCount,
          emailSentCount: row.emailSentCount,
          totalEmailCount: row.totalEmailCount,
          createdAt: row.createdAt,
          updatedAt: row.updatedAt,
        };
      });

      // Add the newly selected rows, deduplicating by key
      const newResult = _.uniqBy(
        [...props.selectedOrders, ...newSelections],
        "id"
      );
      props.setSelectedOrders(newResult);
    } else {
      // Remove all
      props.setSelectedOrders([]);
    }
  };

  const rowSelection = {
    selectedRowKeys,
    onSelect: handleSelect,
    onSelectAll: handleSelectAll,
    preserveSelectedRowKeys: true,
  };

  const closeOrderDetailsModal = () => {
    setIsOrderDetailsModalOpen(false);
  };

  const onClickOrderDetailsButton = (id: string) => () => {
    setOrderId(id);
    setIsOrderDetailsModalOpen(true);
  };

  const handleClickExport = () => {
    props.exportCsv();
  };

  const pagination = (
    <SimplePagination
      items={props.items.items}
      getCursor={(model: Order) => model.createdAt}
      sortDescending={true}
      hasRight={props.items.hasRight}
      hasLeft={props.items.hasLeft}
      location={props.location}
      history={history}
      match={props.match}
    />
  );

  return (
    <div>
      <div className="table-button-row">
        <Row justify="space-between">
          <Col>
            <div className="form-button-row">
              <OrderDetailsDialog
                existingParams={parsed}
                orderId={orderId}
                visible={isOrderDetailsModalOpen}
                closeModal={closeOrderDetailsModal}
              />
            </div>
          </Col>
          <Col>
            <div className="form-button-row">
              <Button icon={<ExportOutlined />} onClick={handleClickExport}>
                {i18n.t("common.csvExport")}
              </Button>
              <SMSLink
                to={`/${Page.orderHistory}/import`}
                queryParams={{
                  mode: MODE_SURVEY,
                  surveyType: parsed.surveyType || surveyTypeStore,
                }}
              >
                <Button icon={<ImportOutlined />}>
                  {i18n.t("orders.table.settings.import")}
                </Button>
              </SMSLink>
            </div>
          </Col>
        </Row>
      </div>
      <Table
        rowSelection={rowSelection}
        dataSource={props.items.items}
        columns={columns()}
        rowKey={(items: Order) => items.id}
        size="middle"
        pagination={false}
      />
      <Row className="pagination-row">{pagination}</Row>
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    items: state.orderHistory.items,
    listLoading: state.orderHistory.listLoading,
    selectedOrders: state.orderHistory.selectedOrders,
  };
};

const mapDispatchToProps = {
  setPage,
  getOrderHistory,
  clearItems,
  setSelectedOrders,
  clearSelectedOrders,
  exportCsv,
};

export default connect(mapStateToProps, mapDispatchToProps)(Orders);
