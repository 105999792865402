import React from "react";
import { connect } from "react-redux";
import { Page, setPage } from "features/appstate/AppStateSlice";
import { Formik } from "formik";
import { Form, Input } from "formik-antd";
import { Row } from "antd";
import i18n from "i18n";
import { ReplyTemplateAddForm, MEOSite } from "api/sms-api";
import { formItemLayout } from "../../constants";
import { RootState } from "store/store";
import { addReplyTemplate } from "./ReplyTemplateActions";
import { clearReplyTemplate } from "./ReplyTemplateSlice";
import { templateAddFormSchema } from "validation";
import BackButton from "components/BackButton";
import CreateButton from "components/CreateButton";
import { pushTo } from "utils/navigation";
import { useHistory } from "react-router-dom";

interface ReplyTemplateAddProps {
  setPage: (page: Page) => void;
  addReplyTemplate: (form: ReplyTemplateAddForm) => Promise<void>;
  loading: boolean;
  meoSite: MEOSite;
  clearReplyTemplate: () => void;
}

const ReplyTemplateAdd: React.FC<ReplyTemplateAddProps> = (props) => {
  const history = useHistory();

  React.useEffect(() => {
    props.setPage(Page.replyTemplates);
  }, [props.setPage]);

  React.useEffect(() => {
    return () => {
      props.clearReplyTemplate();
    };
  }, [props.clearReplyTemplate]);

  const initialValues: ReplyTemplateAddForm = {
    meoSiteId: props.meoSite.id,
    name: "",
    content: "${to}様\nありがとうございます。",
  };

  const handleSubmit = async (form: ReplyTemplateAddForm) => {
    await props.addReplyTemplate(form);
    pushTo(history, `/${Page.replyTemplates}`, {});
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={templateAddFormSchema}
    >
      {(_formikBag) => {
        return (
          <Form {...formItemLayout}>
            <Form.Item name="name" label={i18n.t("templates.name")}>
              <Input name="name" />
            </Form.Item>
            <Form.Item name="content" label={i18n.t("templates.content")}>
              <Input.TextArea
                className="message"
                name="content"
                autoSize={{ minRows: 3 }}
              />
            </Form.Item>
            <Row className="form-button-row">
              <CreateButton loading={props.loading} />
              <BackButton />
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = (root: RootState) => {
  return {
    loading: root.replyTemplates.createLoading,
    meoSite: root.seo.meoSite!,
  };
};

const mapDispatchToProps = {
  setPage,
  addReplyTemplate,
  clearReplyTemplate,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReplyTemplateAdd);
