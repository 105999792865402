import React from "react";
import { Row, Col } from "antd";
import i18n from "i18n";

interface SortableHeaderProps {
  itemLength: number;
}

const SortableHeader: React.FC<SortableHeaderProps> = (props) => {
  return (
    <>
      <label style={{ margin: "0" }}>
        {i18n.t("survey.questionCategoriesTitle.selectedQuestions", {
          count: props.itemLength,
        })}
      </label>
      <Row justify="end" align="middle" style={{ margin: "5px" }}>
        <Col span={2}>
          <label
            style={{ textAlign: "center", margin: "0", paddingLeft: "3px" }}
          >
            {i18n.t("survey.displayRequired")}
          </label>
        </Col>
        <Col span={2}>
          <label
            style={{ textAlign: "center", margin: "0", paddingLeft: "3px" }}
          >
            {i18n.t("survey.inputRequired")}
          </label>
        </Col>
        <Col span={2}>
          <label
            style={{ textAlign: "center", margin: "0", paddingLeft: "3px" }}
          >
            {i18n.t("survey.surveyOnly")}
          </label>
        </Col>
        <Col span={2}>
          <label
            style={{ textAlign: "center", margin: "0", paddingLeft: "3px" }}
          >
            {i18n.t("survey.archive")}
          </label>
        </Col>
        <Col span={2}>
          <label
            style={{ textAlign: "center", margin: "0", paddingLeft: "15px" }}
          >
            {i18n.t("survey.questionDelete")}
          </label>
        </Col>
      </Row>
    </>
  );
};

export default SortableHeader;
