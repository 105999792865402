import React from "react";
import { Space, Input, Radio, Checkbox } from "antd";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import { PlusCircleOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { OptionItems } from "./SurveySlice";
import i18n from "i18n";

const { TextArea } = Input;

interface QuestionEditRadioFormProps {
  handleRadioItemsChange: (radioItems: Array<OptionItems>) => void;
  radioItems: Array<OptionItems>;

  callParentHasCustomText: () => void;
  hasCustomText: boolean;

  callParentTextPlaceholderChange: (placeholder: string) => void;
  customTextPlaceholder: string;

  callNotPromptCheckboxChange?: (index: number, checked: boolean) => void;
  isPromptRadioButton?: boolean;

  callDefaultItemChange: (text: string) => void;
  setDefaultItem: string | null;
}

const QuestionEditRadioForm: React.FC<QuestionEditRadioFormProps> = ({
  handleRadioItemsChange,
  radioItems,
  callParentHasCustomText,
  hasCustomText,
  callParentTextPlaceholderChange,
  customTextPlaceholder,
  callNotPromptCheckboxChange,
  isPromptRadioButton,
  callDefaultItemChange,
  setDefaultItem,
}) => {
  const appendOption = (index: number): Array<OptionItems> => {
    let newItem = [...radioItems];
    newItem.splice(index + 1, 0, { name: "", value: "" });
    newItem = newItem.map((item, index) => {
      return { ...item, name: `radiogroup${index + 1}` };
    });
    return [...newItem];
  };

  const deleteOption = (index: number) => {
    let newItem = [...radioItems];
    newItem.splice(index, 1);
    newItem = newItem.map((item, index) => {
      return { ...item, name: `radiogroup${index + 1}` };
    });
    return newItem;
  };

  const handleClickPlusButton = (index: number) => {
    handleRadioItemsChange(appendOption(index));
  };

  const handleClickMinusButton = (index: number) => {
    handleRadioItemsChange(deleteOption(index));
  };

  const customTextCheckboxChange = () => {
    callParentHasCustomText();
  };

  const notPromptCheckboxChange = (index: number) => (
    e: CheckboxChangeEvent
  ) => {
    if (callNotPromptCheckboxChange) {
      callNotPromptCheckboxChange(index, e.target.checked);
    }
  };

  const customTextChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    callParentTextPlaceholderChange(e.target.value);
  };

  const defaultItemChange = (text: string) => {
    callDefaultItemChange(text);
  };

  return (
    <Radio.Group
      name="radiogroup"
      onChange={(e) => {
        if (e.target.value) {
          defaultItemChange(e.target.value);
        }
      }}
      value={setDefaultItem}
    >
      <Space direction="vertical">
        {radioItems.map((item, index) => {
          const isDeleteVisible = index === 0 ? "hidden" : undefined;
          return (
            <div key={item.name} className="question-item-horizontal">
              <Radio
                name="radiogroup"
                className="question-item-horizontal"
                style={{
                  marginRight: "0",
                  textAlign: "center",
                }}
                value={radioItems[index].name}
              >
                <Input
                  name={item.name}
                  value={item.value}
                  onChange={(e) => {
                    const value = e.target.value;
                    const newItem = radioItems.map((item, i) =>
                      i === index ? { ...item, value } : item
                    );
                    handleRadioItemsChange(newItem);
                  }}
                />
              </Radio>
              <PlusCircleOutlined
                onClick={() => handleClickPlusButton(index)}
                className="question-item-option-icon"
                style={{ color: "blue" }}
              />
              <MinusCircleOutlined
                onClick={() => handleClickMinusButton(index)}
                className="question-item-option-icon"
                style={{
                  color: "red",
                  visibility: isDeleteVisible,
                }}
              />
              {isPromptRadioButton && (
                <Checkbox
                  checked={item.notPrompt}
                  onChange={notPromptCheckboxChange(index)}
                  style={{ marginLeft: "5px" }}
                >
                  {i18n.t("survey.notPrompt")}
                </Checkbox>
              )}
            </div>
          );
        })}
      </Space>
      <div className="radio-free-text">
        <Checkbox
          checked={hasCustomText}
          onChange={customTextCheckboxChange}
          style={{ marginBottom: "5px" }}
        >
          {i18n.t("survey.freeTextLabel")}
        </Checkbox>
        <TextArea
          rows={4}
          name="placeholder"
          placeholder={i18n.t("survey.editPlaceholder")}
          disabled={!hasCustomText}
          onChange={customTextChange}
          value={customTextPlaceholder}
        />
      </div>
    </Radio.Group>
  );
};

export default QuestionEditRadioForm;
