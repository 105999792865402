import React from "react";
import { MEOSite } from "api/sms-api";
import { RootState } from "store/store";
import { connect } from "react-redux";
import { getMEOSite, listMEOSites } from "features/seo/SEOActions";
import { setMEOSite } from "features/seo/SEOSlice";
import { Spin, Alert, Button, Row } from "antd";
import i18n from "i18n";
import { Formik } from "formik";
import { Form, Select } from "formik-antd";
import { useHistory } from "react-router-dom";
import { Page } from "features/appstate/AppStateSlice";
import { SEO_UI_BASE } from "../constants";

const { Option } = Select;

interface MeoSiteSelectorProps {
  meoSites: Array<MEOSite>;
  listMEOSites: () => void;
  setMEOSite: (site: MEOSite) => void;
  getMEOSite: (meoSiteId: number) => void;
  loading: boolean;
}

interface SiteForm {
  siteId: number;
}

const MeoSiteSelector: React.FC<MeoSiteSelectorProps> = (props) => {
  const history = useHistory();

  React.useEffect(() => {
    props.listMEOSites();
  }, [props.listMEOSites]);

  const handleSubmit = (form: SiteForm) => {
    history.replace(`/${Page.publish}?meo_site_id=${form.siteId}`);
    // seoから、選択したmeoSiteのmyBusinessLocationId, googleAccountなどを取得する
    props.getMEOSite(form.siteId);
  };

  if (props.loading) {
    return <Spin />;
  }

  if (props.meoSites.length === 0) {
    return (
      <Alert
        message={
          <div>
            <p style={{ marginBottom: "0" }}>
              {i18n.t("errors.noMEOSites.message1")}
            </p>
            <p style={{ marginBottom: "0" }}>
              {i18n.t("errors.noMEOSites.message2")}
              <a href={`${SEO_UI_BASE}/meoReviews?keywordType=MEO`}>
                {i18n.t("errors.noMEOSites.message3")}
              </a>
              {i18n.t("errors.noMEOSites.message4")}
            </p>
          </div>
        }
        type="error"
      />
    );
  } else {
    const firstId = props.meoSites[0].id;
    const initialValues: SiteForm = {
      siteId: firstId,
    };
    return (
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {(_formikBag) => (
          <Form>
            <Row justify="center">
              <Select
                showSearch
                optionFilterProp="children"
                name="siteId"
                size="large"
                className="meo-site-select"
                style={{ width: "30em", maxWidth: "100%" }}
              >
                {props.meoSites.map((meoSite) => (
                  <Option
                    key={meoSite.id}
                    value={meoSite.id}
                    lebel={meoSite.name}
                  >
                    {meoSite.name}
                  </Option>
                ))}
              </Select>
            </Row>
            <Row justify="center" style={{ marginTop: "1em" }}>
              <Button type="primary" htmlType="submit">
                {i18n.t("siteSelector.select")}
              </Button>
            </Row>
          </Form>
        )}
      </Formik>
    );
  }
};

const mapStateToProps = (state: RootState) => {
  return {
    meoSites: state.seo.meoSites,
    loading: state.seo.listLoading,
  };
};

const mapDispatchToProps = {
  listMEOSites,
  setMEOSite,
  getMEOSite,
};

export default connect(mapStateToProps, mapDispatchToProps)(MeoSiteSelector);
