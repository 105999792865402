import React from "react";
import { connect } from "react-redux";
import { Page, setPage } from "features/appstate/AppStateSlice";
import { Formik } from "formik";
import { Form, Input } from "formik-antd";
import { Row } from "antd";
import i18n from "i18n";
import { AddressAddForm, MEOSite } from "api/sms-api";
import { formItemLayout } from "../../constants";
import { RootState } from "store/store";
import { addAddress } from "./AddressActions";
import { addressAddFormSchema } from "validation";
import { transformPhoneNumber } from "utils/phone_number";
import BackButton from "components/BackButton";
import CreateButton from "components/CreateButton";
import { pushTo } from "utils/navigation";
import { useHistory } from "react-router-dom";

interface AddressAddProps {
  setPage: (page: Page) => void;
  addAddress: (form: AddressAddForm) => Promise<void>;
  loading: boolean;
  meoSite: MEOSite;
}

const AddressAdd: React.FC<AddressAddProps> = (props) => {
  const history = useHistory();
  React.useEffect(() => {
    props.setPage(Page.addresses);
  }, [props.setPage]);

  const initialValues: AddressAddForm = {
    meoSiteId: props.meoSite.id,
    name: "",
    phoneNumber: "",
  };

  const handleSubmit = async (form: AddressAddForm) => {
    form.phoneNumber = transformPhoneNumber(form.phoneNumber);
    await props.addAddress(form);
    pushTo(history, `/${Page.addresses}`, {});
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={addressAddFormSchema}
    >
      {(_formikBag) => {
        return (
          <Form {...formItemLayout}>
            <Form.Item name="name" label={i18n.t("common.name")}>
              <Input
                name="name"
                placeholder={i18n.t("addresses.namePlaceholder")}
              />
            </Form.Item>
            <Form.Item name="phoneNumber" label={i18n.t("common.phoneNumber")}>
              <Input name="phoneNumber" />
            </Form.Item>
            <Row className="form-button-row">
              <CreateButton loading={props.loading} />
              <BackButton />
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = (root: RootState) => {
  return {
    loading: root.addresses.createLoading,
    meoSite: root.seo.meoSite!,
  };
};

const mapDispatchToProps = {
  setPage,
  addAddress,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddressAdd);
