import { AppDispatch, AppThunk, AsyncAppThunk } from "store/store";
import { httpWrapper } from "../../utils/http";
import { SMS_API_BASE } from "../../constants";
import { handleError } from "errors/errors";
import {
  listReplyTemplatesStart,
  listReplyTemplatesSuccess,
  listReplyTemplatesError,
  addReplyTemplateStart,
  addReplyTemplateSuccess,
  addReplyTemplateError,
  getReplyTemplateStart,
  getReplyTemplateSuccess,
  getReplyTemplateError,
  editReplyTemplateStart,
  editReplyTemplateSuccess,
  editReplyTemplateError,
  deleteReplyTemplateStart,
  deleteReplyTemplateSuccess,
  deleteReplyTemplateError,
} from "./ReplyTemplateSlice";
import {
  ReplyTemplate,
  Paginated,
  ListReplyTemplateParams,
  ReplyTemplateAddForm,
} from "../../api/sms-api";
import i18n from "i18n";
import { showNotification } from "utils/notifications";

export const listReplyTemplates = (
  params: ListReplyTemplateParams
): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(listReplyTemplatesStart());
  try {
    const templates = await httpWrapper<Paginated<ReplyTemplate>>(
      "GET",
      `${SMS_API_BASE}/reply_templates`,
      params
    );

    dispatch(listReplyTemplatesSuccess(templates));
  } catch (e) {
    handleError(e);
    dispatch(listReplyTemplatesError(e));
  }
};

export const addReplyTemplate = (
  params: ReplyTemplateAddForm
): AsyncAppThunk<void> => async (dispatch: AppDispatch) => {
  dispatch(addReplyTemplateStart());
  try {
    await httpWrapper("POST", `${SMS_API_BASE}/reply_templates`, params);

    showNotification(i18n.t("replyTemplates.createSuccess"));
    dispatch(addReplyTemplateSuccess());
  } catch (e) {
    handleError(e);
    dispatch(addReplyTemplateError(e));
  }
};

export const editReplyTemplate = (
  id: string,
  params: ReplyTemplateAddForm
): AsyncAppThunk<void> => async (dispatch: AppDispatch) => {
  dispatch(editReplyTemplateStart());
  try {
    await httpWrapper("PATCH", `${SMS_API_BASE}/reply_templates/${id}`, params);

    showNotification(i18n.t("replyTemplates.editSuccess"));
    dispatch(editReplyTemplateSuccess());
  } catch (e) {
    handleError(e);
    dispatch(editReplyTemplateError(e));
  }
};

export const getReplyTemplate = (id: string): AppThunk => async (
  dispatch: AppDispatch
) => {
  dispatch(getReplyTemplateStart());
  try {
    const template = await httpWrapper<ReplyTemplate>(
      "GET",
      `${SMS_API_BASE}/reply_templates/${id}`,
      {}
    );
    dispatch(getReplyTemplateSuccess(template));
  } catch (e) {
    handleError(e);
    dispatch(getReplyTemplateError(e));
  }
};

export const deleteReplyTemplate = (id: string): AppThunk => async (
  dispatch: AppDispatch
) => {
  dispatch(deleteReplyTemplateStart());
  try {
    await httpWrapper("DELETE", `${SMS_API_BASE}/reply_templates/${id}`, {});
    showNotification(i18n.t("replyTemplates.deleteSuccess"));
    dispatch(deleteReplyTemplateSuccess());
  } catch (e) {
    handleError(e);
    dispatch(deleteReplyTemplateError(e));
  }
};
