import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Client } from "../../api/sms-api";
import {
  NonPaginatedCRUDState,
  initialNonPaginatedCRUDState,
} from "store/CRUDStore";
import { ErrorWrapper } from "errors/errors";

interface OptoutPageTemplateState extends NonPaginatedCRUDState<Client> {}

export const initialState: OptoutPageTemplateState = {
  ...initialNonPaginatedCRUDState,
};

export const slice = createSlice({
  name: "optoutPageTemplate",
  initialState,
  reducers: {
    editOptoutPageTemplateStart: (state) => {
      state.editLoading = true;
    },
    editOptoutPageTemplateSuccess: (state) => {
      state.editLoading = false;
    },
    editOptoutPageTemplateError: (
      state,
      _action: PayloadAction<ErrorWrapper>
    ) => {
      state.editLoading = false;
    },
    getOptoutPageTemplateStart: (state) => {
      state.getLoading = true;
    },
    getOptoutPageTemplateSuccess: (state, action: PayloadAction<Client>) => {
      state.item = action.payload;
      state.getLoading = false;
      state.error = undefined;
    },
    getOptoutPageTemplateError: (
      state,
      action: PayloadAction<ErrorWrapper>
    ) => {
      state.getLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  editOptoutPageTemplateStart,
  editOptoutPageTemplateSuccess,
  editOptoutPageTemplateError,
  getOptoutPageTemplateStart,
  getOptoutPageTemplateSuccess,
  getOptoutPageTemplateError,
} = slice.actions;

export default slice.reducer;
