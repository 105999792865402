import React, { useState, useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import { connect } from "react-redux";
import { Page, setPage } from "features/appstate/AppStateSlice";
import {
  listMailTemplateSenders,
  deleteMailTemplateSenders,
} from "./MailTemplateSenderActions";
import {
  setSelectedMailTemplateSenders,
  clearSelectedMailTemplateSenders,
  clearItems,
} from "./MailTemplateSenderSlice";
import {
  MailTemplateSender,
  Paginated,
  PaginationParams,
  MailTemplateSenderDeleteForm,
} from "api/sms-api";
import { Table, Button, Row, Popconfirm } from "antd";
import { RootState } from "store/store";
import qs from "qs";
import { useHistory, useLocation } from "react-router-dom";
import i18n from "i18n";
import { CheckOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import SimplePagination, {
  PaginatedComponentProps,
} from "components/SimplePagination";
import _ from "lodash";
import { PAGE_LIMIT } from "../../constants";
import MailTemplateSenderAddDialog from "./MailTemplateSenderAddDialog";

interface MailTemplateSenderListProps extends PaginatedComponentProps {
  items: Paginated<MailTemplateSender>;
  loading: boolean;
  listMailTemplateSenders: (param: PaginationParams) => void;
  setPage: (page: Page) => void;

  deleteMailTemplateSenders: (params: MailTemplateSenderDeleteForm) => void;
  deleteLoading: boolean;
  deleteFinished: boolean;

  selectedMailTemplateSenders: Array<MailTemplateSender>;
  setSelectedMailTemplateSenders: (selected: Array<MailTemplateSender>) => void;
  clearSelectedMailTemplateSenders: () => void;
  clearItems: () => void;
}

const MailTemplateSenders: React.FC<
  MailTemplateSenderListProps & RouteComponentProps & PaginatedComponentProps
> = (props) => {
  const history = useHistory();
  const location = useLocation();
  const parsed = qs.parse(location.search, { ignoreQueryPrefix: true });
  const [
    isMailTemplateSenderAddModalOpen,
    setIsMailTemplateSenderAddModalOpen,
  ] = useState(false);
  const [
    isMailTemplateSenderEditModalOpen,
    setIsMailTemplateSenderEditModalOpen,
  ] = useState(false);

  useEffect(() => {
    props.setPage(Page.mailTemplateSenders);
  }, [props.setPage]);

  useEffect(() => {
    const params = {
      ..._.omit(parsed, ["before", "after"]),
    };
    props.listMailTemplateSenders({ ...params, limit: PAGE_LIMIT });
  }, [props.listMailTemplateSenders]);

  useEffect(() => {
    return () => {
      props.clearItems();
    };
  }, [props.clearItems]);

  useEffect(() => {
    if (props.deleteFinished && !props.deleteLoading) {
      props.clearSelectedMailTemplateSenders();
      const params = {
        ..._.omit(parsed, ["mode", "before", "after"]),
      };
      props.listMailTemplateSenders({ ...params, limit: PAGE_LIMIT });
    }
  }, [
    props.listMailTemplateSenders,
    props.deleteFinished,
    props.deleteLoading,
  ]);

  const columns = () => [
    {
      title: "",
      dataIndex: "id",
      key: "id",
      render: (_: any) => {
        return <CheckOutlined className="survey-table-publish" />;
      },
    },
    {
      title: i18n.t("mailTemplateSender.table.title.name"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: i18n.t("mailTemplateSender.table.title.senderEmail"),
      dataIndex: "senderEmail",
      key: "senderEmail",
    },
    {
      title: i18n.t("mailTemplateSender.table.title.replyEmail"),
      dataIndex: "replyEmail",
      key: "replyEmail",
    },
  ];

  const selectedRowKeys = props.selectedMailTemplateSenders.map(({ id }) => id);
  const selectedMailTemplateSender =
    props.selectedMailTemplateSenders.length === 1
      ? props.selectedMailTemplateSenders[0]
      : undefined;

  const handleSelect = (record: MailTemplateSender, isSelected: boolean) => {
    if (isSelected) {
      // Adding
      const newSelected = [
        ...props.selectedMailTemplateSenders,
        {
          id: record.id,
          clientId: record.clientId,
          name: record.name,
          senderEmail: record.senderEmail,
          replyEmail: record.replyEmail,
          createdAt: record.createdAt,
          updatedAt: record.updatedAt,
        },
      ];
      props.setSelectedMailTemplateSenders(newSelected);
    } else {
      // Removing
      let current = [...props.selectedMailTemplateSenders];
      current = current.filter((c) => c.id !== record.id);
      props.setSelectedMailTemplateSenders(current);
    }
  };

  const handleSelectAll = (
    isSelected: boolean,
    changeRows: Array<MailTemplateSender>
  ) => {
    if (isSelected) {
      // Add all
      const newSelections = changeRows.map((row) => {
        return {
          id: row.id,
          clientId: row.clientId,
          name: row.name,
          senderEmail: row.senderEmail,
          replyEmail: row.replyEmail,
          createdAt: row.createdAt,
          updatedAt: row.updatedAt,
        };
      });

      // Add the newly selected rows, deduplicating by key
      const newResult = _.uniqBy(
        [...props.selectedMailTemplateSenders, ...newSelections],
        "id"
      );
      props.setSelectedMailTemplateSenders(newResult);
    } else {
      // Remove all
      props.setSelectedMailTemplateSenders([]);
    }
  };

  const rowSelection = {
    selectedRowKeys,
    onSelect: handleSelect,
    onSelectAll: handleSelectAll,
    preserveSelectedRowKeys: true,
  };

  const closeMailTemplateSenderAddModal = () => {
    setIsMailTemplateSenderAddModalOpen(false);
  };

  const onClickMailTemplateSenderAddButton = () => {
    setIsMailTemplateSenderAddModalOpen(true);
  };

  const closeMailTemplateSenderEditModal = () => {
    setIsMailTemplateSenderEditModalOpen(false);
    props.clearSelectedMailTemplateSenders();
  };

  const onClickMailTemplateSenderEditButton = () => {
    setIsMailTemplateSenderEditModalOpen(true);
  };

  const handleDelete = () => {
    const selectedIds: Array<string> = props.selectedMailTemplateSenders.map(
      (s) => s.id!
    );
    const form: MailTemplateSenderDeleteForm = {
      mailTemplateSenderIds: selectedIds,
    };
    props.deleteMailTemplateSenders(form);
  };

  const editButton = (
    <Button
      onClick={onClickMailTemplateSenderEditButton}
      disabled={props.selectedMailTemplateSenders.length !== 1}
      icon={<EditOutlined />}
    >
      {i18n.t("common.edit")}
    </Button>
  );

  const deleteButton = (
    <Popconfirm
      title={i18n.t("mailTemplateSender.table.settings.deleteCountConfirm", {
        count: props.selectedMailTemplateSenders.length,
      })}
      disabled={props.selectedMailTemplateSenders.length < 1}
      onConfirm={handleDelete}
      okText={i18n.t("common.delete")}
      cancelText={i18n.t("common.cancel")}
      okType="danger"
    >
      <Button
        danger={props.selectedMailTemplateSenders.length > 0}
        disabled={props.selectedMailTemplateSenders.length < 1}
        icon={<DeleteOutlined />}
      >
        {i18n.t("common.delete")}
      </Button>
    </Popconfirm>
  );

  const pagination = (
    <SimplePagination
      items={props.items.items}
      getCursor={(model: MailTemplateSender) => model.createdAt}
      sortDescending={true}
      hasRight={props.items.hasRight}
      hasLeft={props.items.hasLeft}
      location={props.location}
      history={history}
      match={props.match}
    />
  );

  return (
    <div>
      <div className="table-button-row">
        <div className="form-button-row">
          <Button type="primary" onClick={onClickMailTemplateSenderAddButton}>
            {i18n.t("mailTemplateSender.table.settings.add")}
          </Button>
          {editButton}
          {deleteButton}
          <MailTemplateSenderAddDialog
            existingParams={parsed}
            visible={isMailTemplateSenderAddModalOpen}
            closeModal={closeMailTemplateSenderAddModal}
          />
          <MailTemplateSenderAddDialog
            item={selectedMailTemplateSender}
            existingParams={parsed}
            visible={isMailTemplateSenderEditModalOpen}
            closeModal={closeMailTemplateSenderEditModal}
          />
        </div>
      </div>
      <Table
        rowSelection={rowSelection}
        dataSource={props.items.items}
        columns={columns()}
        rowKey={(item: MailTemplateSender) => item.id}
        size="middle"
        pagination={false}
      />
      <Row className="pagination-row">{pagination}</Row>
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    items: state.mailTemplateSender.items,
    selectedMailTemplateSenders:
      state.mailTemplateSender.selectedMailTemplateSenders,
    loading: state.mailTemplateSender.listLoading,
    deleteLoading: state.mailTemplateSender.deleteLoading,
    deleteFinished: state.mailTemplateSender.deleteFinished,
  };
};

const mapDispatchToProps = {
  setPage,
  listMailTemplateSenders,
  deleteMailTemplateSenders,
  clearItems,
  setSelectedMailTemplateSenders,
  clearSelectedMailTemplateSenders,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MailTemplateSenders);
