import React, { useState } from "react";
import { useSortable } from "@dnd-kit/sortable";
import classnames from "classnames";
import { Field } from "formik";
import { Row, Col, Button } from "antd";
import i18n from "i18n";
import { Tooltip } from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  CaretDownOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { FlattenedPickedQuestion } from "api/sms-api";
import { DEFAULT_VALUE } from "./QuestionEdit";

interface SortableItemProps {
  value: FlattenedPickedQuestion;
  index: number;
  parentIndex: number | undefined;
  grandParentIndex: number | undefined;
  collapsed: boolean;

  handleClickDisplayRequired: () => void;
  handleClickInputRequired: () => void;
  handleClickSurveyOnly: () => void;
  deleteSelectedQuestions: () => void;
  handleClickQuestion: () => void;
  handleClickArchive: () => void;
  handleCollapse?(): void;
  duplicateQuestion: () => void;
}

const SortableItem: React.FC<SortableItemProps> = (props) => {
  const {
    setNodeRef,
    listeners,
    attributes,
    transform,
    isDragging,
  } = useSortable({
    id: props.value.selectId || "",
  });
  const classes = classnames("survey-dnd-item", {
    dragging: isDragging,
    surveyOnly: props.value.surveyOnly,
    archive: props.value.archive,
    group: props.value.formType === "group",
  });

  let hidden = props.value.depth > 0;
  if (props.value.formType === "group" && props.value.parentId) {
    hidden = false;
  }
  const checkboxClasses = classnames("survey-dnd-checkbox", {
    hidden,
  });

  let title = props.value.title;
  if (props.value.formType === "group") {
    const randomCount = props.value.options?.randomCount;
    const displayRandomCount =
      Number(randomCount) === 0
        ? i18n.t("survey.questionText.selectAll")
        : `${randomCount ?? DEFAULT_VALUE}`;
    title = `${title}（${displayRandomCount}/${props.value.children.length}）`;
  }

  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [activeTooltip, setActiveTooltip] = useState("");

  const handleClickDisplayRequired = () => {
    props.handleClickDisplayRequired();
  };
  const handleClickInputRequired = () => {
    props.handleClickInputRequired();
  };
  const handleClickSurveyOnly = () => {
    props.handleClickSurveyOnly();
  };
  const handleClickArchive = () => {
    props.handleClickArchive();
  };
  const handleClickDelete = () => {
    props.deleteSelectedQuestions();
  };
  const handleEditButtonClick = () => {
    props.handleClickQuestion();
  };
  const handleDuplicateButtonClick = () => {
    props.duplicateQuestion();
  };

  const handleMouseEnter = (tooltipName: string) => {
    setTooltipVisible(true);
    setActiveTooltip(tooltipName);
  };

  const handleMouseLeave = () => {
    setTooltipVisible(false);
    setActiveTooltip("");
  };

  let index = (props.index + 1).toString();
  if (
    typeof props.parentIndex !== "undefined" &&
    typeof props.grandParentIndex === "undefined"
  ) {
    index = `${props.parentIndex + 1} - ${props.index + 1}`;
  } else if (
    typeof props.parentIndex !== "undefined" &&
    typeof props.grandParentIndex !== "undefined"
  ) {
    index = `${props.grandParentIndex + 1} - ${props.parentIndex + 1} - ${
      props.index + 1
    }`;
  }

  // 分岐選択肢内グループで最初の項目の削除ボタンは非表示にする
  const isDeleteButtonVisible =
    props.value.formType === "group" &&
    typeof props.parentIndex !== "undefined" &&
    props.index === 0;

  return (
    <>
      <Row align="middle" style={{ marginLeft: `${50 * props.value.depth}px` }}>
        <Col span={2}>{index}</Col>
        <Col span={12}>
          <li
            key={props.value.selectId}
            ref={setNodeRef}
            {...attributes}
            style={
              {
                "--translate-x": transform
                  ? `${Math.round(transform.x)}px`
                  : undefined,
                "--translate-y": transform
                  ? `${Math.round(transform.y)}px`
                  : undefined,
              } as React.CSSProperties
            }
            className={classes}
          >
            <Row align="middle" justify="space-between">
              <Col span={15} {...listeners}>
                {title}
              </Col>
              {props.handleCollapse && (
                <Col span={3} className="survey-dnd-item-icon">
                  <Button type="text" onClick={props.handleCollapse}>
                    <CaretDownOutlined
                      rotate={props.collapsed ? 180 : undefined}
                    />
                  </Button>
                </Col>
              )}
              <Col span={3} className="survey-dnd-item-icon">
                <Button type="text" onClick={handleEditButtonClick}>
                  <EditOutlined
                    style={{
                      color: "#1890ff",
                    }}
                  />
                </Button>
              </Col>
              <Col span={3} className="survey-dnd-item-icon">
                <Button type="text" onClick={handleDuplicateButtonClick}>
                  <PlusCircleOutlined />
                </Button>
              </Col>
            </Row>
          </li>
        </Col>
        <Col
          span={2}
          className={checkboxClasses}
          style={props.value.depth > 0 ? { visibility: "hidden" } : undefined}
        >
          <span
            onMouseEnter={() => handleMouseEnter("displayRequired")}
            onMouseLeave={handleMouseLeave}
          >
            <Tooltip
              title={i18n.t("survey.displayRequired")}
              visible={tooltipVisible && activeTooltip === "displayRequired"}
              placement="top"
            >
              <Field
                type="checkbox"
                name="displayRequired"
                checked={props.value.displayRequired}
                value={props.value.selectId}
                onClick={handleClickDisplayRequired}
              />
            </Tooltip>
          </span>
        </Col>
        <Col span={2} className={checkboxClasses}>
          <span
            onMouseEnter={() => handleMouseEnter("inputRequired")}
            onMouseLeave={handleMouseLeave}
          >
            <Tooltip
              title={i18n.t("survey.inputRequired")}
              visible={tooltipVisible && activeTooltip === "inputRequired"}
              placement="top"
            >
              <Field
                type="checkbox"
                name="inputRequired"
                checked={props.value.inputRequired}
                value={props.value.selectId}
                onClick={handleClickInputRequired}
              />
            </Tooltip>
          </span>
        </Col>
        <Col span={2} className={checkboxClasses}>
          <span
            onMouseEnter={() => handleMouseEnter("surveyOnly")}
            onMouseLeave={handleMouseLeave}
          >
            <Tooltip
              title={i18n.t("survey.surveyOnly")}
              visible={tooltipVisible && activeTooltip === "surveyOnly"}
              placement="top"
            >
              <Field
                type="checkbox"
                name="surveyOnly"
                checked={props.value.surveyOnly}
                value={props.value.selectId}
                onClick={handleClickSurveyOnly}
              />
            </Tooltip>
          </span>
        </Col>
        <Col span={2} style={{ textAlign: "center", margin: "0" }}>
          <span
            onMouseEnter={() => handleMouseEnter("archive")}
            onMouseLeave={handleMouseLeave}
          >
            <Tooltip
              title={i18n.t("survey.archive")}
              visible={tooltipVisible && activeTooltip === "archive"}
              placement="top"
            >
              <Field
                type="checkbox"
                name="archive"
                checked={props.value.archive}
                value={props.value.selectId}
                onClick={handleClickArchive}
              />
            </Tooltip>
          </span>
        </Col>
        <Col
          span={2}
          style={{
            color: "red",
            textAlign: "center",
            margin: "0",
            paddingBottom: "5px",
            visibility: isDeleteButtonVisible ? "hidden" : undefined,
          }}
        >
          <DeleteOutlined onClick={handleClickDelete} />
        </Col>
      </Row>
    </>
  );
};

export default SortableItem;
