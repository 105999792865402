import React from "react";
import { RootState } from "store/store";
import { connect } from "react-redux";
import { Modal } from "antd";
import i18n from "i18n";
import { Form, Input, Select } from "antd";
import { ProductsAddForm, EcProvider } from "api/sms-api";
import { addProduct } from "./ProductSettingsActions";

interface AddProductDialogProps {
  visible: boolean;
  existingParams: any;
  closeModal: () => void;

  addProduct: (form: ProductsAddForm, existingParams: any) => void;
  ecProviders: Array<EcProvider>;
}

const AddProductDialog: React.FC<AddProductDialogProps> = (props) => {
  const [form] = Form.useForm();

  const onClickAddProductButton = (value: {
    productCode: string;
    productName: string;
    reviewPageUrl?: string;
    ecProviderId: string;
  }) => {
    props.addProduct(value, props.existingParams);
    form.resetFields();
    props.closeModal();
  };

  const onClickCancelButton = () => {
    form.resetFields();
    props.closeModal();
  };

  const ecProviderOptions = props.ecProviders.map((ecProvider) => {
    return {
      label: ecProvider.name,
      value: ecProvider.id,
    };
  });

  return (
    <Modal
      visible={props.visible}
      title={i18n.t("productSettings.table.settings.add")}
      okText={i18n.t("common.save")}
      cancelText={i18n.t("common.cancel")}
      onCancel={onClickCancelButton}
      onOk={() => {
        form.validateFields().then((values) => {
          onClickAddProductButton(values);
        });
      }}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          name="productCode"
          label={i18n.t("productSettings.table.title.productCode")}
          className="input-mail-form"
          validateTrigger={["onBlur"]}
          rules={[
            {
              validator(_, value) {
                if (value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(i18n.t("validation.required")));
              },
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="productName"
          label={i18n.t("productSettings.table.title.productName")}
          className="input-mail-form"
          validateTrigger={["onBlur"]}
          rules={[
            {
              validator(_, value) {
                if (value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(i18n.t("validation.required")));
              },
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="reviewPageUrl"
          label={i18n.t("productSettings.table.title.reviewPageUrl")}
          className="input-mail-form"
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="ecProviderId"
          label={i18n.t("productSettings.table.title.ecProviderId")}
          className="input-mail-form"
          validateTrigger={["onBlur"]}
          rules={[
            {
              validator(_, value) {
                if (value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(i18n.t("validation.required")));
              },
            },
          ]}
        >
          <Select options={ecProviderOptions} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

const mapStateToProps = (state: RootState) => {
  return { ecProviders: state.ecProviders.items };
};

const mapDispatchToProps = {
  addProduct,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddProductDialog);
