import { AppDispatch, AppThunk, AsyncAppThunk } from "store/store";
import { httpWrapper } from "../../utils/http";
import { SMS_API_BASE } from "../../constants";
import { handleError } from "errors/errors";
import {
  getClientStart,
  getClientSuccess,
  getClientError,
  editClientStart,
  editClientSuccess,
  editClientError,
  getUsageStatisticsStart,
  getUsageStatisticsSuccess,
  getUsageStatisticsError,
  getSSOClientSuccess,
  getSSOClientStart,
  getSSOClientError,
} from "./ClientSlice";
import {
  Client,
  ClientEditForm,
  UsageStatistics,
  SSOClient,
} from "../../api/sms-api";
import i18n from "i18n";
import { showNotification } from "utils/notifications";

export const editClient = (params: ClientEditForm): AppThunk => async (
  dispatch: AppDispatch
) => {
  dispatch(editClientStart());
  try {
    const updated = await httpWrapper<Client>(
      "PATCH",
      `${SMS_API_BASE}/client`,
      params
    );

    showNotification(i18n.t("client.editSuccess"));
    dispatch(editClientSuccess(updated));
  } catch (e) {
    handleError(e);
    dispatch(editClientError(e));
  }
};

export const getClient = (): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(getClientStart());
  try {
    const client = await httpWrapper<Client>(
      "GET",
      `${SMS_API_BASE}/client`,
      {}
    );
    dispatch(getClientSuccess(client));
  } catch (e) {
    handleError(e);
    dispatch(getClientError(e));
  }
};

export const getSSOClient = (): AsyncAppThunk<SSOClient | undefined> => async (
  dispatch: AppDispatch
) => {
  dispatch(getSSOClientStart());
  try {
    const client = await httpWrapper<SSOClient>(
      "GET",
      `${SMS_API_BASE}/client/sso_client`,
      {}
    );
    dispatch(getSSOClientSuccess(client));
    return client;
  } catch (e) {
    handleError(e);
    dispatch(getSSOClientError());
  }
};

export const getUsageStatistics = (meoSiteId: number): AppThunk => async (
  dispatch: AppDispatch
) => {
  dispatch(getUsageStatisticsStart());
  try {
    const stats = await httpWrapper<UsageStatistics>(
      "GET",
      `${SMS_API_BASE}/client/statistics`,
      { meoSiteId }
    );
    dispatch(getUsageStatisticsSuccess(stats));
  } catch (e) {
    handleError(e);
    dispatch(getUsageStatisticsError(e));
  }
};
