import React from "react";
import { pushTo } from "utils/navigation";
import { RollbackOutlined } from "@ant-design/icons";
import SMSLink from "components/SMSLink";
import { connect } from "react-redux";
import { Page, setPage } from "features/appstate/AppStateSlice";
import { setSavedForm } from "features/reviews/ReviewSlice";
import { listReplyTemplates } from "features/reply_templates/ReplyTemplateActions";
import { clearItems } from "features/reply_templates/ReplyTemplateSlice";
import {
  Paginated,
  ReplyTemplate,
  ListReplyTemplateParams,
  MEOSite,
  GMBReplyForm,
} from "api/sms-api";
import { Table, Button, Row, Alert } from "antd";
import { RootState } from "store/store";
import qs from "qs";
import i18n from "i18n";
import SimplePagination, {
  PaginatedComponentProps,
} from "components/SimplePagination";
import { RouteComponentProps } from "react-router";
import { useHistory, useLocation } from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";
import _ from "lodash";
import Media from "react-media";
import { mobileMediaQuery } from "../../constants";

interface ReplyTemplatesProps {
  templates: Paginated<ReplyTemplate>;
  loading: boolean;
  savedForm?: GMBReplyForm;

  meoSite: MEOSite;
  setPage: (page: Page) => void;
  listReplyTemplates: (params: ListReplyTemplateParams) => void;
  clearItems: () => void;
  setSavedForm: (form: GMBReplyForm) => void;
}

const ReplyTemplates: React.FC<
  ReplyTemplatesProps & RouteComponentProps & PaginatedComponentProps
> = (props) => {
  const history = useHistory();
  const location = useLocation();

  const [selecting, setSelecting] = React.useState(false);

  const parsed = qs.parse(location.search, { ignoreQueryPrefix: true });

  React.useEffect(() => {
    props.setPage(Page.replyTemplates);
  }, [props.setPage]);

  React.useEffect(() => {
    if (_.has(parsed, "selecting")) {
      setSelecting(true);
    }

    // Remove other parameters that might show up in the query string
    const params = {
      ..._.omit(parsed, ["selecting"]),
      meoSiteId: props.meoSite.id,
    };

    props.listReplyTemplates(params);
  }, [props.listReplyTemplates, props.meoSite]);

  React.useEffect(() => {
    return () => {
      props.clearItems();
    };
  }, [props.clearItems]);

  // Select a template, or if null, go back without selecting anything
  const selectReplyTemplate = (template?: ReplyTemplate) => {
    if (props.savedForm) {
      const form = Object.assign({}, props.savedForm);
      if (template) {
        form.comment = template.content;
      }
      props.setSavedForm(form);
    }
    pushTo(history, parsed["backUrl"] as string, {
      shortcutSelected: template ? "template" : "back",
    });
  };

  const columns = (smallScreen: boolean) => [
    {
      title: i18n.t("templates.name"),
      dataIndex: "name",
      key: "name",
      render: (name: string, template: ReplyTemplate) => {
        const nameText = smallScreen ? _.truncate(name, { length: 10 }) : name;
        if (selecting) {
          return (
            <Button type="link" onClick={() => selectReplyTemplate(template)}>
              {nameText}
            </Button>
          );
        } else {
          return (
            <SMSLink to={`${Page.replyTemplates}/${template.id}`}>
              {" "}
              {nameText}
            </SMSLink>
          );
        }
      },
    },
    {
      title: i18n.t("templates.content"),
      dataIndex: "content",
      key: "content",
      render: (content: string) => {
        return _.truncate(content, { length: 20 });
      },
    },
  ];

  const addButton = (
    <SMSLink to={`/${Page.replyTemplates}/add`}>
      <Button icon={<PlusOutlined />} type="primary">
        {i18n.t("replyTemplates.add")}
      </Button>
    </SMSLink>
  );

  const pagination = (
    <SimplePagination
      items={props.templates.items}
      getCursor={(model: ReplyTemplate) => model.createdAt}
      sortDescending={true}
      hasRight={props.templates.hasRight}
      hasLeft={props.templates.hasLeft}
      location={props.location}
      history={history}
      match={props.match}
    />
  );

  const selectingExplanation = selecting && (
    <Alert
      message={
        <div>
          <span>{i18n.t("replyTemplates.selectingExplanation")}</span>
          <Button
            icon={<RollbackOutlined />}
            onClick={() => selectReplyTemplate(undefined)}
          >
            {i18n.t("common.back")}
          </Button>
        </div>
      }
      type="info"
      style={{ marginBottom: "2em" }}
    />
  );

  return (
    <Media query={mobileMediaQuery}>
      {(smallScreen) => (
        <div>
          {selectingExplanation}
          <Table
            dataSource={props.templates.items}
            columns={columns(smallScreen)}
            locale={{ emptyText: i18n.t("replyTemplates.empty") }}
            rowKey={(c: ReplyTemplate) => c.id}
            size="middle"
            pagination={false}
            loading={props.loading}
            style={{ marginTop: "1em" }}
          />
          <Row className="pagination-row">{pagination}</Row>
          <Row className="form-button-row">
            {selecting ? <div /> : addButton}
          </Row>
        </div>
      )}
    </Media>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    templates: state.replyTemplates.items,
    loading: state.replyTemplates.listLoading,
    savedForm: state.reviews.savedForm,
    meoSite: state.seo.meoSite!,
  };
};

const mapDispatchToProps = {
  setPage,
  listReplyTemplates,
  setSavedForm,
  clearItems,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReplyTemplates);
