import React, { useState, useEffect, ReactElement } from "react";
import { Page, setPage } from "features/appstate/AppStateSlice";
import { RootState } from "store/store";
import { connect } from "react-redux";
import { useHistory, useLocation, RouteComponentProps } from "react-router-dom";
import {
  Table,
  Row,
  Col,
  Dropdown,
  Menu,
  Button,
  Modal,
  Select,
  Typography,
  Alert,
  Spin,
} from "antd";
import i18n from "i18n";
import {
  Paginated,
  SurveyMeoSiteSettingsGetParam,
  SurveyMeoSiteSettings,
  LocalBusiness,
  SurveyMeoSitesAddForm,
} from "api/sms-api";
import {
  CopyOutlined,
  CheckOutlined,
  RollbackOutlined,
  ImportOutlined,
  LeftOutlined,
  RightOutlined,
  ExportOutlined,
} from "@ant-design/icons";
import CopyToClipboard from "react-copy-to-clipboard";
import { showNotification } from "utils/notifications";
import { KIKICOMI_BASE, MODE_SURVEY, surveyTypeStore } from "../../constants";
import SurveyQR from "components/SurveyQR";
import _ from "lodash";
import {
  setSelectedMeoSites,
  clearSelectedMeoSites,
  clearItems,
} from "./MeoSiteSettingsSlice";
import MeoSitesSettingMail from "./MeoSiteSettingsMail";
import {
  getLocalBusinessesList,
  getSurveyMeoSiteSettings,
  getSurveyMeoSiteSettingsByLocalBusiness,
  addMeoSites,
} from "./MeoSiteSettingsActions";
import SMSLink from "components/SMSLink";
import qs from "qs";
import { pushTo } from "utils/navigation";
import { exportCsv } from "features/meo_site_settings/MeoSiteSettingsActions";

const { Text } = Typography;
const KIKICOMI_BASE_URL = `${KIKICOMI_BASE}?siteId=`;
const PAGE_LIMIT = 100;

interface MeoSiteSettingsProps {
  items: Paginated<SurveyMeoSiteSettings>;
  listLoading: boolean;
  getSurveyMeoSiteSettings: (param: SurveyMeoSiteSettingsGetParam) => void;
  getSurveyMeoSiteSettingsByLocalBusiness: (
    id: number,
    param: SurveyMeoSiteSettingsGetParam
  ) => void;
  clearItems: () => void;

  localBusinesses: Array<LocalBusiness>;
  getLocalBusinessesList: () => void;

  setPage: (page: Page) => void;

  selectedMeoSites: Array<SurveyMeoSiteSettings>;
  setSelectedMeoSites: (selected: Array<SurveyMeoSiteSettings>) => void;
  clearSelectedMeoSites: () => void;

  updateSurveyMeoSiteSettingsLoading: boolean;

  addMeoSites: (params: Array<SurveyMeoSitesAddForm>) => Promise<void>;
  createLoading: boolean;

  exportCsv: () => void;
}

const MeoSiteSettings: React.FC<MeoSiteSettingsProps & RouteComponentProps> = (
  props
) => {
  const history = useHistory();
  const location = useLocation();
  const parsed = qs.parse(location.search, { ignoreQueryPrefix: true });
  const [surveyId, setSurveyId] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pageIndex, setPageIndex] = useState(1);
  const [localBusinessId, setLocalBusinessId] = useState<number | undefined>(
    undefined
  );

  const emailsSet = new Set<string>();
  (props.items.items as SurveyMeoSiteSettings[])
    .filter((item) =>
      props.selectedMeoSites.some(
        (selected) => selected.meoSiteId === item.meoSiteId
      )
    )
    .forEach((item) => {
      if (item.recipientEmail) {
        item.recipientEmail.forEach((email) => emailsSet.add(email));
      }
    });

  const emails = Array.from(emailsSet);

  useEffect(() => {
    props.setPage(Page.meoSiteSettings);
  }, [props.setPage]);

  useEffect(() => {
    const params = {
      pageSize: PAGE_LIMIT,
      page: pageIndex,
    };
    props.getSurveyMeoSiteSettings(params);
  }, [props.getSurveyMeoSiteSettings]);

  React.useEffect(() => {
    props.getLocalBusinessesList();
  }, [props.getLocalBusinessesList]);

  React.useEffect(() => {
    return () => {
      props.clearItems();
      props.clearSelectedMeoSites();
    };
  }, []);

  useEffect(() => {
    if (_.has(parsed, "surveyId")) {
      setSurveyId(parsed["surveyId"] as string);
    }
  }, []);

  if (props.listLoading || props.createLoading) {
    return <Spin />;
  }

  const handleQR = (id: number) => {
    Modal.info({
      title: null,
      icon: null,
      content: <SurveyQR url={`${KIKICOMI_BASE_URL}${id}`} />,
    });
  };

  const urlQRCodeDropdown = (id: number) => (
    <Menu>
      <Menu.Item>
        <CopyToClipboard
          text={`${KIKICOMI_BASE_URL}${id}`}
          onCopy={() =>
            showNotification(
              i18n.t("meoSiteSettings.table.item.copyUrlNotification")
            )
          }
        >
          <div>{i18n.t("meoSiteSettings.table.item.url")}</div>
        </CopyToClipboard>
      </Menu.Item>
      <Menu.Item>
        <div onClick={() => handleQR(id)}>
          {i18n.t("meoSiteSettings.table.item.qr")}
        </div>
      </Menu.Item>
    </Menu>
  );

  const columns = () => [
    {
      title: "",
      dataIndex: "surveyCount",
      key: "surveyCount",
      render: (_: any, items: SurveyMeoSiteSettings) => {
        let result = null;
        if (items.surveyCount > 0) {
          result = <CheckOutlined className="survey-table-publish" />;
        }
        return result;
      },
    },
    {
      title: i18n.t("meoSiteSettings.table.title.meoSiteName"),
      dataIndex: "meoSiteName",
      key: "meoSiteName",
    },
    {
      title: i18n.t("meoSiteSettings.table.title.recipientEmail"),
      dataIndex: "recipientEmail",
      key: "recipientEmail",
      render: (_: any, items: SurveyMeoSiteSettings) => {
        if (!items.recipientEmail) {
          return null;
        }
        return items.recipientEmail.map((item, index) => {
          return <div key={index}>{item}</div>;
        });
      },
    },
    {
      title: i18n.t("meoSiteSettings.table.title.url"),
      dataIndex: "url",
      key: "url",
      render: (_: any, items: SurveyMeoSiteSettings): JSX.Element => {
        return (
          <Dropdown
            overlay={() => urlQRCodeDropdown(items.meoSiteId)}
            trigger={["click"]}
          >
            <Button type="link" icon={<CopyOutlined />} size="large" />
          </Dropdown>
        );
      },
    },
  ];

  const selectedRowKeys = props.selectedMeoSites.map(
    ({ meoSiteId }) => meoSiteId
  );

  const handleSelect = (record: SurveyMeoSiteSettings, isSelected: boolean) => {
    if (isSelected) {
      // Adding
      const newSelected = [
        ...props.selectedMeoSites,
        {
          meoSiteId: record.meoSiteId,
          meoSiteName: record.meoSiteName,
          recipientEmail: [],
          surveyCount: record.surveyCount,
        },
      ];
      props.setSelectedMeoSites(newSelected);
    } else {
      // Removing
      let current = [...props.selectedMeoSites];
      current = current.filter((c) => c.meoSiteId !== record.meoSiteId);
      props.setSelectedMeoSites(current);
    }
  };

  const handleSelectAll = (
    isSelected: boolean,
    changeRows: Array<SurveyMeoSiteSettings>
  ) => {
    if (isSelected) {
      // Add all
      const newSelections = changeRows.map((row) => {
        return {
          meoSiteId: row.meoSiteId,
          meoSiteName: row.meoSiteName,
          recipientEmail: row.recipientEmail,
          surveyCount: row.surveyCount,
        };
      });

      // Add the newly selected rows, deduplicating by key
      const newResult = _.uniqBy(
        [...props.selectedMeoSites, ...newSelections],
        "meoSiteId"
      );
      props.setSelectedMeoSites(newResult);
    } else {
      // Remove all
      props.setSelectedMeoSites([]);
    }
  };

  const rowSelection = {
    selectedRowKeys,
    onSelect: handleSelect,
    onSelectAll: handleSelectAll,
    preserveSelectedRowKeys: true,
  };

  const selectLocalBusiness = (value: number) => {
    setLocalBusinessId(value);
    setPageIndex(1);
    const params = {
      pageSize: PAGE_LIMIT,
      page: 1,
    };
    if (value) {
      props.getSurveyMeoSiteSettingsByLocalBusiness(value, params);
    } else {
      props.getSurveyMeoSiteSettings(params);
    }
  };

  const onClickSetEmailButton = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const finishSelection = async (save: boolean) => {
    if (!surveyId) return;

    if (save) {
      const form = props.selectedMeoSites.map((item) => ({
        surveysId: surveyId,
        meoSiteId: item.meoSiteId,
      }));

      await props.addMeoSites(form);
    }

    pushTo(history, `/${Page.meoSites}`, {
      id: surveyId,
      mode: MODE_SURVEY,
      surveyType: parsed.surveyType || surveyTypeStore,
    });
  };

  let selectingExplanation: ReactElement | null = null;
  if (surveyId) {
    selectingExplanation = (
      <Alert
        message={
          <div>
            <div>
              <span>
                {i18n.t("meoSiteSettings.table.settings.selectingExplanation")}
              </span>
            </div>
            <div style={{ marginTop: "1em" }}>
              <Button
                type="primary"
                icon={<CheckOutlined />}
                onClick={() => finishSelection(true)}
                style={{ marginRight: "1em" }}
                disabled={props.createLoading}
              >
                {i18n.t("common.select")}
              </Button>
              <Button
                icon={<RollbackOutlined />}
                onClick={() => finishSelection(false)}
              >
                {i18n.t("common.back")}
              </Button>
            </div>
          </div>
        }
        type="info"
        style={{ marginBottom: "2em" }}
      />
    );
  }

  const onLeft = () => {
    if (props.items.items.length > 0) {
      const params = {
        pageSize: PAGE_LIMIT,
        page: pageIndex - 1,
      };
      setPageIndex(pageIndex - 1);
      props.getSurveyMeoSiteSettings(params);
    }
  };

  const onRight = () => {
    if (props.items.items.length > 0) {
      const params = {
        pageSize: PAGE_LIMIT,
        page: pageIndex + 1,
      };
      setPageIndex(pageIndex + 1);
      props.getSurveyMeoSiteSettings(params);
    }
  };

  const handleClickExport = () => {
    props.exportCsv();
  };

  return (
    <div>
      <div className="table-button-row">
        {selectingExplanation}
        <Row>
          <Col xs={20} md={12} lg={8}>
            <Text>
              {i18n.t("meoSiteSettings.table.filter.localBusinesses")}
            </Text>
            <Select
              value={localBusinessId}
              allowClear
              showSearch
              optionFilterProp="label"
              size="middle"
              dropdownMatchSelectWidth={false}
              options={props.localBusinesses
                .map((item) => {
                  return {
                    value: item.id,
                    label: `${item.name} (${item.siteCount})`,
                  };
                })
                .sort((a, b) => a.label.localeCompare(b.label))}
              className="meo-businesses-select"
              onChange={selectLocalBusiness}
            />
          </Col>
        </Row>
        <Row justify="space-between">
          <Button
            type="primary"
            onClick={onClickSetEmailButton}
            disabled={!(props.selectedMeoSites.length > 0) || surveyId !== null}
          >
            {i18n.t("meoSiteSettings.table.settings.recipientEmail")}
          </Button>
          <MeoSitesSettingMail
            emails={emails}
            pageSize={PAGE_LIMIT}
            page={pageIndex}
            visible={isModalOpen}
            closeModal={closeModal}
          />
          <Col>
            <Button
              icon={<ExportOutlined />}
              onClick={handleClickExport}
              disabled={surveyId !== null}
            >
              {i18n.t("common.csvExport")}
            </Button>
            <SMSLink
              to={`/${Page.meoSiteSettings}/import`}
              queryParams={{
                mode: MODE_SURVEY,
                surveyType: parsed.surveyType || surveyTypeStore,
              }}
            >
              <Button icon={<ImportOutlined />} disabled={surveyId !== null}>
                {i18n.t("meoSiteSettings.table.settings.import")}
              </Button>
            </SMSLink>
          </Col>
        </Row>
      </div>
      <Table
        rowSelection={rowSelection}
        dataSource={props.items.items}
        columns={columns()}
        rowKey={(items: SurveyMeoSiteSettings) => items.meoSiteId}
        size="middle"
        pagination={false}
      />
      <Row className="pagination-row">
        <div className="simple-pagination">
          <Button.Group>
            <Button
              onClick={onLeft}
              disabled={!props.items.hasLeft}
              className="left"
              icon={<LeftOutlined />}
            />
            <Button
              onClick={onRight}
              disabled={!props.items.hasRight}
              className="right"
              icon={<RightOutlined />}
            />
          </Button.Group>
        </div>
      </Row>
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    items: state.meoSiteSettings.items,
    listLoading: state.meoSiteSettings.listLoading,
    localBusinesses: state.meoSiteSettings.localBusinesses,
    selectedMeoSites: state.meoSiteSettings.selectedMeoSites,
    updateSurveyMeoSiteSettingsLoading:
      state.meoSiteSettings.updateSurveyMeoSiteSettingsLoading,
    createLoading: state.meoSiteSettings.createLoading,
  };
};

const mapDispatchToProps = {
  setPage,
  getLocalBusinessesList,
  getSurveyMeoSiteSettings,
  getSurveyMeoSiteSettingsByLocalBusiness,
  clearItems,
  setSelectedMeoSites,
  clearSelectedMeoSites,
  addMeoSites,
  exportCsv,
};

export default connect(mapStateToProps, mapDispatchToProps)(MeoSiteSettings);
