export function getUtf8ByteCount(s: string) {
  const utf8 = unescape(encodeURIComponent(s));
  const bytes = [];
  for (let i = 0; i < utf8.length; i++) {
    bytes.push(utf8.charCodeAt(i));
  }

  return bytes.length;
}
export function convertContent(str: string) {
  if (!str) {
    return "";
  }
  return str
    .replace(/\$\{link\}/g, "1234567890123456789") // 19byte
    .replace(/\$\{user\}/g, "1234567890123456"); // 16byte
}

export function splitStringByNewline(str: string) {
  if (!str) {
    return [];
  }
  return str
    .split(/\r\n|\r|\n|,/g)
    .filter((line: string) => line.trim() !== "");
}

export function emailIsInvalid(emailList: Array<string>) {
  const regex = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  if (emailList) {
    const invalidEmail = emailList
      .filter((f) => f.trim() !== "")
      .filter((v) => !regex.test(v));
    return invalidEmail.length > 0;
  }
  return false;
}

export function replaceStoreLogoInserts(
  content: string,
  insert: string,
  storeLogoUrl: string
) {
  let result = content;
  if (storeLogoUrl) {
    result = content.replaceAll(insert, `<img src="${storeLogoUrl}" />`);
  }
  return result;
}
