import React from "react";
import { BorderOutlined, QrcodeOutlined } from "@ant-design/icons";
import {
  LandingData,
  LandingPageSettings,
  LandingDataPreviewForm,
  LandingPageSettingsEditForm,
  LogoUploadResult,
  MEOSite,
  ReviewLinkColors,
} from "api/sms-api";
import { RootState } from "store/store";
import {
  deleteLandingPageSettings,
  editLandingPageSettings,
  uploadLogo,
  getPreviewData,
} from "./LandingPageSettingsActions";
import { clearLandingPageSettings } from "./LandingPageSettingsSlice";
import { connect } from "react-redux";
import { Formik } from "formik";
import { Form, Input } from "formik-antd";
import { formItemLayout } from "../../constants";
import i18n from "i18n";
import { Button, Alert, Row, Popconfirm, Modal, Spin } from "antd";
import { landingPageSettingsEditFormSchema } from "validation";
import SaveButton from "components/SaveButton";
import { showPreview } from "./Preview";
import { useHistory } from "react-router-dom";
import DeleteButton from "components/DeleteButton";
import { pushTo } from "utils/navigation";
import { Page } from "features/appstate/AppStateSlice";
import LandingPageQR from "components/LandingPageQR";
import RichEditor from "components/RichEditor";
import { getColors } from "features/review_link/ReviewLinkActions";
import LandingPageLinkCopyButton from "./LandingPageLinkCopyButton";

interface LandingPageSettingsEditProps {
  previewData?: LandingData;
  item: LandingPageSettings;
  editLoading: boolean;
  deleteLoading: boolean;
  logoUploading: boolean;
  meoSite: MEOSite;
  getLoading: boolean;
  getPreviewLoading: boolean;

  clearLandingPageSettings: () => void;
  uploadLogo: (file: File) => Promise<LogoUploadResult | undefined>;
  editLandingPageSettings: (
    id: string,
    form: LandingPageSettingsEditForm
  ) => Promise<void>;
  getPreviewData: (form: LandingDataPreviewForm) => void;
  deleteLandingPageSettings: (id: string) => Promise<void>;

  colors?: ReviewLinkColors;
  getColors: () => void;
}

const defaultLandingPage = `<h1 style="line-height:1.2em;"><span style="color:#f60;">\${store}</span>へご来店いただきありがとうございました！</h1>
<p>
  当店のサービスにご満足いただけたでしょうか？<br>
  よろしければ、当店の印象に残ったサービスや雰囲気、などをくわしく<strong>クチコミ</strong>していただけると幸いです。<br>
  よろしくお願いします！
</p>
`;

const defaultLogoUrl =
  "https://gyro-n-seo-sms-logos.s3-ap-northeast-1.amazonaws.com/default.png";

const LandingPageSettingsEdit: React.FC<LandingPageSettingsEditProps> = (
  props
) => {
  const history = useHistory();
  const [logoUrl, setLogoUrl] = React.useState<string | undefined>(undefined);
  const [logoChanged, setLogoChanged] = React.useState(false);
  const [modalVisible, setModalVisible] = React.useState(false);

  React.useEffect(() => {
    setLogoUrl(props.item.logoUrl);
  }, [props.item]);

  React.useEffect(() => {
    return () => {
      props.clearLandingPageSettings();
    };
  }, [props.clearLandingPageSettings]);

  function handleOk() {
    setModalVisible(false);
  }

  React.useEffect(() => {
    if (
      !props.getLoading &&
      !props.getPreviewLoading &&
      props.previewData &&
      modalVisible &&
      props.colors
    ) {
      showPreview(props.previewData, props.colors, handleOk);
    }
  }, [
    props.getLoading,
    props.getPreviewLoading,
    props.previewData,
    props.colors,
  ]);

  React.useEffect(() => {
    props.getColors();
  }, [props.getColors]);

  const initialValues: LandingPageSettingsEditForm = {
    title: props.item.title,
    message: props.item.message,
  };

  const handleSubmit = async (form: LandingPageSettingsEditForm) => {
    if (logoUrl) {
      form.logoUrl = logoUrl;
    }
    if (props.item) {
      await props.editLandingPageSettings(props.item.id, form);
      setLogoChanged(false);
      pushTo(history, `/${Page.landingPageSettings}`, {});
    }
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }

    const result = await props.uploadLogo(e.target.files[0]);
    if (result) {
      setLogoUrl(result.url);
      setLogoChanged(true);
    }
  };

  const preview = (form: LandingPageSettingsEditForm) => {
    const previewForm = {
      landingPageSettingsId: props.item.id,
      meoSiteId: props.meoSite.id,
      logoUrl: logoUrl || "",
      message: form.message || "",
    };
    props.getPreviewData(previewForm);
    setModalVisible(true);
  };

  const handleDelete = async () => {
    await props.deleteLandingPageSettings(props.item.id);
    pushTo(history, `/${Page.landingPageSettings}`, {});
  };

  const handleQR = () => {
    Modal.info({
      title: null,
      icon: null,
      content: <LandingPageQR landingPageSettings={props.item} />,
    });
  };

  if (!props.colors) {
    return <Spin />;
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={landingPageSettingsEditFormSchema}
    >
      {(formikBag) => {
        return (
          <Form {...formItemLayout}>
            <Form.Item name="name" label={i18n.t("landingPageSettings.name")}>
              {props.item.name}
            </Form.Item>
            <Form.Item name="title" label={i18n.t("landingPageSettings.title")}>
              <Input name="title" />
            </Form.Item>
            <Form.Item
              name="message"
              label={i18n.t("landingPageSettings.message")}
            >
              <RichEditor
                value={formikBag.values.message || ""}
                onChange={(value) => {
                  formikBag.setFieldValue("message", value);
                }}
              />
            </Form.Item>
            <Form.Item name="file" label={i18n.t("landingPageSettings.logo")}>
              <input type="file" onChange={handleFileChange} />
              {logoUrl && (
                <img
                  style={{ marginTop: "1em", maxWidth: "100%" }}
                  src={logoUrl}
                />
              )}
              {logoChanged && (
                <Alert
                  style={{ marginTop: "1em" }}
                  type="warning"
                  message={i18n.t("landingPageSettings.logoEditNote")}
                />
              )}
            </Form.Item>
            <Row className="form-button-row">
              <SaveButton
                disabled={props.logoUploading}
                loading={props.editLoading}
              />
              <Button
                icon={<BorderOutlined />}
                onClick={() => preview(formikBag.values)}
                loading={props.getPreviewLoading}
              >
                {i18n.t("landingPageSettings.preview")}
              </Button>
              <Button icon={<QrcodeOutlined />} onClick={handleQR}>
                {i18n.t("landingPageSettings.qrCode")}
              </Button>
              <LandingPageLinkCopyButton lpsId={props.item.id} />
              {props.item?.name === "default" && (
                <Button
                  className="default-landing-page-settings"
                  onClick={() => {
                    formikBag.setFieldValue("message", defaultLandingPage);
                    setLogoUrl(defaultLogoUrl);
                  }}
                  loading={props.editLoading}
                >
                  {i18n.t("landingPageSettings.default")}
                </Button>
              )}
              {props.item.name !== "default" && (
                <Popconfirm
                  title={i18n.t("landingPageSettings.deleteConfirm")}
                  onConfirm={handleDelete}
                  okText={i18n.t("common.delete")}
                  cancelText={i18n.t("common.cancel")}
                  okType="danger"
                >
                  <DeleteButton loading={props.deleteLoading} />
                </Popconfirm>
              )}
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    editLoading: state.landingPageSettings.editLoading,
    deleteLoading: state.landingPageSettings.editLoading,
    logoUploading: state.landingPageSettings.fileUploading,
    meoSite: state.seo.meoSite!,
    previewData: state.landingPageSettings.previewData,
    getLoading: state.landingPageSettings.getLoading,
    getPreviewLoading: state.landingPageSettings.getPreviewLoading,
    colors: state.reviewLinks.colors,
  };
};

const mapDispatchToProps = {
  editLandingPageSettings,
  deleteLandingPageSettings,
  clearLandingPageSettings,
  uploadLogo,
  getPreviewData,
  getColors,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LandingPageSettingsEdit);
