/* eslint-disable */
import { AppDispatch, AppThunk } from "store/store";
import { httpWrapper } from "../../utils/http";
import { SMS_API_BASE } from "../../constants";
import { handleError } from "errors/errors";
import {
  listHistoryStart,
  listHistorySuccess,
  listHistoryError,
  getHistoryStart,
  getHistorySuccess,
  getHistoryError,
} from "./HistorySlice";
import {
  Paginated,
  ListHistoryParams,
  FullHistory,
  FullHistoryGroup,
  History,
} from "../../api/sms-api";

export const listHistory = (params: ListHistoryParams): AppThunk => async (
  dispatch: AppDispatch
) => {
  dispatch(listHistoryStart());
  try {
    const items = await httpWrapper<Paginated<FullHistory>>(
      "GET",
      `${SMS_API_BASE}/history`,
      params
    );

    dispatch(listHistorySuccess(items));
  } catch (e) {
    handleError(e);
    dispatch(listHistoryError(e));
  }
};

export const getHistory = (id: string): AppThunk => async (
  dispatch: AppDispatch
) => {
  dispatch(getHistoryStart());
  try {
    const item = await httpWrapper<FullHistoryGroup>(
      "GET",
      `${SMS_API_BASE}/history/${id}`,
      {}
    );
    dispatch(getHistorySuccess(item));
  } catch (e) {
    handleError(e);
    dispatch(getHistoryError(e));
  }
};
