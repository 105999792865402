import React, { useEffect } from "react";
import i18n from "i18n";
import { Spin, Row, Col, Table, Alert, Descriptions, Typography } from "antd";
import { connect } from "react-redux";
import { RootState } from "store/store";
import {
  OrderSurveyEmailWithProductsDetail,
  OrderSurveyEmailDetails,
} from "api/sms-api";
import { getOrderSurveyEmailDetails } from "./OrderHistoryActions";
import qs from "qs";
import { Page, setPage } from "features/appstate/AppStateSlice";
import moment from "moment";
import { SURVEY_PAGE_BASE } from "../../constants";

const { Paragraph } = Typography;

interface SurveyEmailDetailsProps {
  setPage: (page: Page) => void;

  orderSurveyEmailDetails: Array<OrderSurveyEmailWithProductsDetail>;
  orderSurveyEmailDetailsLoading: boolean;
  getOrderSurveyEmailDetails: (orderId: string) => void;
}

const SurveyEmailDetails: React.FC<SurveyEmailDetailsProps> = (props) => {
  const parsed = qs.parse(location.search, { ignoreQueryPrefix: true });
  const orderId = parsed.id ? parsed.id.toString() : "";

  useEffect(() => {
    props.setPage(Page.orderSurveyEmailDetails);
  }, [props.setPage]);

  useEffect(() => {
    if (orderId) {
      props.getOrderSurveyEmailDetails(orderId);
    }
  }, [props.getOrderSurveyEmailDetails, orderId]);

  if (props.orderSurveyEmailDetailsLoading) {
    return <Spin />;
  }

  const noSurveyEmailsAlert = (
    <Alert
      type="warning"
      message={i18n.t("orderSurveyEmails.noOrderSurveyEmails")}
    />
  );

  const columns = () => [
    {
      title: i18n.t("orders.table.title.productName"),
      dataIndex: "productName",
      key: "productName",
    },
    {
      title: i18n.t("orders.table.title.reviewLink"),
      dataIndex: "reviewLink",
      key: "reviewLink",
      render: (_: any, item: OrderSurveyEmailDetails) => {
        return (
          <Paragraph
            copyable
            className="url-column"
          >{`${SURVEY_PAGE_BASE}/static?id=${item.surveysId}&clientNextEngineProductId=${item.clientNextEngineProductId}`}</Paragraph>
        );
      },
    },
    {
      title: i18n.t("orders.table.title.sendAt"),
      dataIndex: "sendAt",
      key: "sendAt",
      render: (_: any, item: OrderSurveyEmailDetails) => {
        return moment(item.sendAt).format("YYYY-MM-DD HH:mm:ss");
      },
    },
  ];

  let emailRows: any = noSurveyEmailsAlert;
  let orderDetails: any = null;
  if (props.orderSurveyEmailDetails.length > 0) {
    emailRows = props.orderSurveyEmailDetails.map(
      (orderSurveyEmail: any, index: number) => {
        return (
          <>
            <div className="table-button-row">
              <Row justify="space-between">
                <Col>
                  <div className="form-button-row">
                    <span>
                      <b>
                        {i18n.t("orderSurveyEmails.emailTitle", {
                          number: index + 1,
                        })}
                      </b>
                    </span>
                  </div>
                </Col>
              </Row>
            </div>
            <Table
              dataSource={orderSurveyEmail.products}
              columns={columns()}
              rowKey={(product: OrderSurveyEmailDetails) =>
                product.clientNextEngineProductId
              }
              size="middle"
              pagination={false}
            />
          </>
        );
      }
    );
    // Fill in the order id and email address to provide a bit of context for the emails
    const order = props.orderSurveyEmailDetails[0].order;
    orderDetails = (
      <Descriptions bordered>
        <Descriptions.Item label={i18n.t("orders.table.title.orderId")}>
          {order.orderId}
        </Descriptions.Item>
        <Descriptions.Item label={i18n.t("orders.table.title.emailAddress")}>
          {order.emailAddress}
        </Descriptions.Item>
      </Descriptions>
    );
  }

  return (
    <div>
      {orderDetails}
      {emailRows}
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    orderSurveyEmailDetails: state.orderHistory.orderSurveyEmailDetails,
    orderSurveyEmailDetailsLoading:
      state.orderHistory.orderSurveyEmailDetailsLoading,
  };
};

const mapDispatchToProps = {
  setPage,
  getOrderSurveyEmailDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(SurveyEmailDetails);
