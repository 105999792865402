import { EcProvider } from "api/sms-api";
import { AppDispatch } from "store/store";
import { httpWrapper } from "utils/http";
import {
  getEcProvidersStart,
  getEcProvidersSuccess,
  getEcProvidersError,
} from "./EcProvidersSlice";
import { SMS_API_BASE } from "../../constants";
import { handleError } from "errors/errors";

export const getEcProviders = () => async (dispatch: AppDispatch) => {
  dispatch(getEcProvidersStart());
  try {
    const ecProviders = await httpWrapper<Array<EcProvider>>(
      "GET",
      `${SMS_API_BASE}/client_next_engine/ec_providers`,
      {}
    );
    dispatch(getEcProvidersSuccess(ecProviders));
  } catch (e) {
    handleError(e);
    dispatch(getEcProvidersError());
  }
};
