import React from "react";

import DatePicker from "antd-mobile/lib/date-picker"; // for js
import "antd-mobile/lib/date-picker/style/css"; // for css
import { Input } from "antd";
import { CalendarOutlined } from "@ant-design/icons";
import moment from "moment";

const jaLocale = {
  okText: "OK",
  dismissText: "キャンセル",
  extra: "",
  DatePickerLocale: {
    year: "年",
    month: "月",
    day: "日",
    hour: "時",
    minute: "分",
    am: "午前",
    pm: "午後",
  },
};

interface MobileTimePickerProps {
  date?: Date;
  setDate: (date: Date) => void;
}

const MobileTimePicker: React.FC<MobileTimePickerProps> = (props) => {
  const now = moment();

  // Round min and max to the next 5 minutes
  const remainder = 5 - (now.minute() % 5);
  const minDate = now.clone().add(remainder, "minutes");
  const maxDate = minDate.clone().add(3, "months");
  return (
    <DatePicker
      value={props.date}
      mode="datetime"
      minDate={minDate.toDate()}
      maxDate={maxDate.toDate()}
      minuteStep={5}
      onChange={props.setDate}
      locale={jaLocale}
    >
      <Input
        value={props.date ? moment(props.date).format("YYYY-MM-DD HH:mm") : ""}
        suffix={<CalendarOutlined />}
      />
    </DatePicker>
  );
};

export default MobileTimePicker;
