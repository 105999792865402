import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  Space,
  Input,
  Row,
  Col,
  Card,
  Radio,
  InputNumber,
  DatePicker,
  Select,
  Checkbox,
  Rate,
} from "antd";
import {
  EditOutlined,
  PlusCircleOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";
import i18n from "i18n";
import SurveyRichEditor from "./SurveyRichEditor";
import { OptionItems, setRadioItems, setCheckboxItems } from "./SurveySlice";
import { RootState } from "store/store";
import { connect } from "react-redux";
import {
  defaultRadioItemsValue,
  defaultPromptRadioItemsValue,
  defaultGenerationItems,
  defaultCheckboxItemsValue,
} from "../../constants";
import QuestionEditRadioForm from "./QuestionEditRadioForm";
import { PickedQuestion } from "api/sms-api";
import type { CheckboxChangeEvent } from "antd/es/checkbox";

const { TextArea } = Input;

const defaultTitleLineColor = "#d9d9d9";
const focusTitleLineColor = "#1890ff";

export const DEFAULT_VALUE = 1;

const groupOptions = Array.from({ length: 10 }, (_, i) => ({
  value: i + 1,
  label: `${i + 1}`,
}));
groupOptions.push({
  value: 0,
  label: i18n.t("survey.questionText.selectAll"),
});

const prefectures = [
  { value: "北海道", label: "北海道" },
  { value: "青森県", label: "青森県" },
  { value: "岩手県", label: "岩手県" },
  { value: "宮城県", label: "宮城県" },
  { value: "秋田県", label: "秋田県" },
  { value: "山形県", label: "山形県" },
  { value: "福島県", label: "福島県" },
  { value: "茨城県", label: "茨城県" },
  { value: "栃木県", label: "栃木県" },
  { value: "群馬県", label: "群馬県" },
  { value: "埼玉県", label: "埼玉県" },
  { value: "千葉県", label: "千葉県" },
  { value: "東京都", label: "東京都" },
  { value: "神奈川県", label: "神奈川県" },
  { value: "新潟県", label: "新潟県" },
  { value: "富山県", label: "富山県" },
  { value: "石川県", label: "石川県" },
  { value: "福井県", label: "福井県" },
  { value: "山梨県", label: "山梨県" },
  { value: "長野県", label: "長野県" },
  { value: "岐阜県", label: "岐阜県" },
  { value: "静岡県", label: "静岡県" },
  { value: "愛知県", label: "愛知県" },
  { value: "三重県", label: "三重県" },
  { value: "滋賀県", label: "滋賀県" },
  { value: "京都府", label: "京都府" },
  { value: "大阪府", label: "大阪府" },
  { value: "兵庫県", label: "兵庫県" },
  { value: "奈良県", label: "奈良県" },
  { value: "和歌山県", label: "和歌山県" },
  { value: "鳥取県", label: "鳥取県" },
  { value: "島根県", label: "島根県" },
  { value: "岡山県", label: "岡山県" },
  { value: "広島県", label: "広島県" },
  { value: "山口県", label: "山口県" },
  { value: "徳島県", label: "徳島県" },
  { value: "香川県", label: "香川県" },
  { value: "愛媛県", label: "愛媛県" },
  { value: "高知県", label: "高知県" },
  { value: "福岡県", label: "福岡県" },
  { value: "佐賀県", label: "佐賀県" },
  { value: "長崎県", label: "長崎県" },
  { value: "熊本県", label: "熊本県" },
  { value: "大分県", label: "大分県" },
  { value: "宮崎県", label: "宮崎県" },
  { value: "鹿児島県", label: "鹿児島県" },
  { value: "沖縄県", label: "沖縄県" },
];

interface QuestionEditProps {
  visible: boolean;
  closeModal: () => void;
  saveEditedResult: (result: any) => void;

  index: number;
  item: PickedQuestion;
}

const QuestionEdit: React.FC<QuestionEditProps> = (props) => {
  const editItem = props.item;
  const [form] = Form.useForm();
  const [titleLineColor, setTitleLineColor] = useState(defaultTitleLineColor);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [editConditions, setEditConditions] = useState("");
  const [editFreeInput, setEditFreeInput] = useState("");
  const [isFreeInput, setFreeInput] = useState(false);
  const [editRadioItems, setEditRadioItems] = useState<Array<OptionItems>>(
    defaultRadioItemsValue
  );
  const [hasCustomText, setHasCustomText] = useState(false);
  const [customTextPlaceholder, setCustomTextPlaceholder] = useState("");
  const [editCheckboxItems, setEditCheckboxItems] = useState<
    Array<OptionItems>
  >(defaultCheckboxItemsValue);
  const [defaultItem, setDefaultItem] = useState(
    editItem.options?.defaultItem || null
  );

  // 分岐用選択肢
  const isBranch = editItem.formType === "branch";

  // 質問グループ
  const isGroup = editItem.formType === "group";

  // 基本情報
  const isEmail = editItem.formType === "email";
  const isGender = editItem.formType === "gender";
  const isAge = editItem.formType === "age";
  const isName = editItem.formType === "name";
  const isHurigana = editItem.formType === "hurigana";
  const isBirth = editItem.formType === "birth";
  const isPrefectures = editItem.formType === "prefectures";
  const isConditions = editItem.formType === "conditions";
  const isGenerations = editItem.formType === "generations";

  // 質問項目
  const isText = editItem.formType === "text";
  const isComment = editItem.formType === "comment";
  const isRating = editItem.formType === "rating";
  const isNumber = editItem.formType === "number";
  const isRadiogroup = editItem.formType === "radiogroup";
  const isCheckbox = editItem.formType === "checkbox";
  const isPromptRadioButton = editItem.formType === "promptradiobutton";

  useEffect(() => {
    form.setFieldsValue({
      title: editItem.title,
      placeholder: editItem.placeholder,
      randomCount:
        isGroup && editItem.options && editItem.options.randomCount != null
          ? Number(editItem.options.randomCount)
          : DEFAULT_VALUE,
    });

    if (isConditions && editItem.options && editItem.options.conditions) {
      setEditConditions(editItem.options.conditions);
    } else {
      setEditConditions("");
    }

    if (editItem.options && editItem.options.freeInput) {
      setFreeInput(editItem.options.freeInput.isFreeInput);
      setEditFreeInput(editItem.options.freeInput.value);
    } else {
      setFreeInput(false);
      setEditFreeInput("");
    }

    if (
      (isRadiogroup || isPromptRadioButton || isGenerations) &&
      editItem.options &&
      editItem.options.radioItems &&
      editItem.options.radioItems.length > 0
    ) {
      setEditRadioItems(editItem.options.radioItems);
      setDefaultItem(editItem.options.defaultItem || null);
    } else {
      if (isGenerations) {
        setEditRadioItems(defaultGenerationItems);
      } else if (isPromptRadioButton) {
        setEditRadioItems(defaultPromptRadioItemsValue);
        setDefaultItem(null);
      } else {
        setEditRadioItems(defaultRadioItemsValue);
      }
    }

    if (isBranch && editItem.children && editItem.children.length > 0) {
      const radioItems: OptionItems[] = editItem.children.map((item, index) => {
        const notPrompt = Boolean(item.options?.notPrompt);
        return {
          name: `radiogroup${index + 1}`,
          value: item.title,
          selectId: item.selectId,
          notPrompt,
        };
      });
      setEditRadioItems(radioItems);
      setDefaultItem(editItem.options?.defaultItem || null);
    }

    if (
      (isRadiogroup ||
        isPromptRadioButton ||
        isGenerations ||
        isCheckbox ||
        isBranch) &&
      editItem.options &&
      editItem.options.hasCustomText
    ) {
      setHasCustomText(editItem.options.hasCustomText);
    } else {
      setHasCustomText(false);
    }
    if (
      (isRadiogroup ||
        isPromptRadioButton ||
        isGenerations ||
        isCheckbox ||
        isBranch) &&
      editItem.options &&
      editItem.options.customTextPlaceholder
    ) {
      setCustomTextPlaceholder(editItem.options.customTextPlaceholder);
    } else {
      setCustomTextPlaceholder("");
    }

    if (
      isCheckbox &&
      editItem.options &&
      editItem.options.checkboxItems &&
      editItem.options.checkboxItems.length > 0
    ) {
      setEditCheckboxItems(editItem.options.checkboxItems);
    } else {
      setEditCheckboxItems(defaultCheckboxItemsValue);
    }
  }, [props.visible]);

  const appendOption = (
    prevItems: Array<OptionItems>,
    index: number,
    formType: string
  ): Array<OptionItems> => {
    let newItem = [...prevItems];
    newItem.splice(index + 1, 0, { name: "", value: "" });
    newItem = newItem.map((item, index) => {
      return { ...item, name: `${formType}${index + 1}` };
    });
    return [...newItem];
  };

  const deleteOption = (
    prevItems: Array<OptionItems>,
    index: number,
    formType: string
  ) => {
    let newItem = [...prevItems];
    newItem.splice(index, 1);
    newItem = newItem.map((item, index) => {
      return { ...item, name: `${formType}${index + 1}` };
    });
    return newItem;
  };

  const onClickSave = (values: any) => {
    let options = null;

    if (isConditions) {
      options = editConditions;
    } else if (isRadiogroup || isPromptRadioButton || isGenerations) {
      options = {
        freeInput: {
          isFreeInput: isFreeInput,
          value: editFreeInput,
        },
        radioItems: editRadioItems,
        hasCustomText,
        customTextPlaceholder,
        defaultItem,
      };
    } else if (isBranch) {
      options = {
        radioItems: editRadioItems,
        hasCustomText,
        customTextPlaceholder,
        defaultItem,
      };
    } else if (isCheckbox) {
      options = {
        freeInput: {
          isFreeInput: isFreeInput,
          value: editFreeInput,
        },
        checkboxItems: editCheckboxItems,
        hasCustomText,
        customTextPlaceholder,
        defaultItem,
      };
    } else if (isGroup) {
      options = values.randomCount;
    } else {
      options = {
        freeInput: {
          isFreeInput: isFreeInput,
          value: editFreeInput,
        },
      };
    }
    const result = {
      index: props.index,
      title: values.title || "",
      formType: props.item.formType,
      options,
      placeholder: values.placeholder || "",
    };

    setEditConditions("");
    setEditFreeInput("");
    props.saveEditedResult(result);
  };

  const closeModal = () => {
    setEditConditions("");
    setEditFreeInput("");
    props.closeModal();
  };

  const handleCheckboxClick = () => {
    setFreeInput(isFreeInput ? false : true);
  };

  const handleRadioItemsChange = (radioItems: Array<OptionItems>) => {
    setEditRadioItems(radioItems);
  };

  const callParentHasCustomText = () => {
    setHasCustomText(!hasCustomText);
  };

  const callParentTextPlaceholderChange = (placeholder: string) => {
    setCustomTextPlaceholder(placeholder);
  };

  const customTextCheckboxChange = () => {
    callParentHasCustomText();
  };

  const customTextChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    callParentTextPlaceholderChange(e.target.value);
  };

  const notPromptCheckboxChange = (index: number) => (
    e: CheckboxChangeEvent
  ) => {
    callNotPromptChange(index, e.target.checked);
  };

  const callNotPromptCheckboxChange = (index: number, checked: boolean) => {
    const newItem = editRadioItems.map((item, idx) => {
      if (idx === index) {
        return { ...item, notPrompt: checked };
      }
      return item;
    });
    setEditRadioItems(newItem);
  };

  const callNotPromptChange = (index: number, checked: boolean) => {
    const newItem = editCheckboxItems.map((item, idx) => {
      if (idx === index) {
        return { ...item, notPrompt: checked };
      }
      return item;
    });
    setEditCheckboxItems(newItem);
  };

  const callDefaultItemChange = (text: string) => {
    setDefaultItem(text);
  };

  return (
    <Modal
      destroyOnClose
      visible={props.visible}
      title={i18n.t("survey.questionEditModalTitle")}
      okText={i18n.t("common.save")}
      closable={false}
      maskClosable={false}
      cancelText={i18n.t("common.cancel")}
      onCancel={closeModal}
      onOk={() => {
        form.validateFields().then((values: any) => {
          onClickSave(values);
        });
      }}
    >
      <Form form={form} layout="vertical">
        <Row justify="center">
          <Col span={24}>
            <Card className="question-card">
              {editItem.inputRequired ? (
                <label style={{ color: "red", fontWeight: "bold" }}>
                  {i18n.t("survey.required")}
                </label>
              ) : null}
              <div
                className="question-titlle-underline"
                style={{
                  borderBottomColor: titleLineColor,
                  marginBottom: "24px",
                }}
              >
                <Row justify="space-between" align="middle">
                  <Col span={22}>
                    <Form.Item name="title" style={{ marginBottom: "0" }}>
                      <TextArea
                        name="title"
                        rows={1}
                        autoSize
                        size="large"
                        className="question-titlle"
                        bordered={false}
                        onFocus={() => {
                          setIsInputFocused(true);
                          setTitleLineColor(focusTitleLineColor);
                        }}
                        onMouseOver={() => {
                          setTitleLineColor(focusTitleLineColor);
                        }}
                        onBlur={() => {
                          setTitleLineColor(defaultTitleLineColor);
                          setIsInputFocused(false);
                        }}
                        onMouseOut={() => {
                          if (!isInputFocused) {
                            setTitleLineColor(defaultTitleLineColor);
                          }
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col>
                    <EditOutlined className="question-titlle-icon" />
                  </Col>
                </Row>
              </div>

              <div className="question-card-container">
                {!isConditions && !isGroup && !isBranch && (
                  <Row style={{ marginBottom: "5px" }}>
                    <Checkbox
                      name="isFreeInput"
                      onClick={handleCheckboxClick}
                      checked={isFreeInput ? true : false}
                    >
                      {i18n.t("survey.questionText.flee")}
                    </Checkbox>
                  </Row>
                )}
                {isFreeInput && !isConditions && !isGroup && !isBranch && (
                  <>
                    <Form.Item
                      name="freeInput"
                      rules={[
                        {
                          validator(_, value) {
                            const regex = /<script[\s\S]*?>/gi;
                            if (!regex.test(value)) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              new Error(i18n.t("validation.forbiddenTags"))
                            );
                          },
                        },
                      ]}
                    >
                      <SurveyRichEditor
                        value={editFreeInput}
                        onChange={(value) => {
                          setEditFreeInput(value);
                        }}
                        isFreeInput={isFreeInput}
                      />
                    </Form.Item>
                  </>
                )}
                {isBranch && (
                  <QuestionEditRadioForm
                    isPromptRadioButton={true}
                    handleRadioItemsChange={handleRadioItemsChange}
                    radioItems={editRadioItems}
                    callParentHasCustomText={callParentHasCustomText}
                    hasCustomText={hasCustomText}
                    callParentTextPlaceholderChange={
                      callParentTextPlaceholderChange
                    }
                    customTextPlaceholder={customTextPlaceholder}
                    callNotPromptCheckboxChange={callNotPromptCheckboxChange}
                    callDefaultItemChange={callDefaultItemChange}
                    setDefaultItem={defaultItem}
                  />
                )}
                {isGroup && (
                  <Form.Item
                    name="randomCount"
                    label={i18n.t("survey.questionText.random")}
                  >
                    <Select options={groupOptions} style={{ width: "100px" }} />
                  </Form.Item>
                )}
                {(isEmail || isName || isHurigana || isText) && (
                  <Form.Item name="placeholder">
                    <Input
                      name="placeholder"
                      size="large"
                      placeholder={i18n.t("survey.editPlaceholder")}
                    />
                  </Form.Item>
                )}
                {isGender && (
                  <Radio.Group name="gender" size="large" defaultValue={1}>
                    <Space direction="vertical">
                      <Radio value={1}>
                        {i18n.t("survey.questionText.male")}
                      </Radio>
                      <Radio value={2}>
                        {i18n.t("survey.questionText.female")}
                      </Radio>
                      <Radio value={3}>
                        {i18n.t("survey.questionText.noAnswer")}
                      </Radio>
                    </Space>
                  </Radio.Group>
                )}
                {isAge && (
                  <>
                    <InputNumber
                      name="age"
                      style={{ marginRight: "5px" }}
                      disabled
                    />
                    {i18n.t("survey.questionText.age")}
                  </>
                )}
                {isBirth && <DatePicker name="birth" disabled />}
                {isPrefectures && (
                  <Select
                    placeholder={i18n.t("survey.prefecturesPlaceholder")}
                    options={prefectures}
                  />
                )}
                {isConditions && (
                  <>
                    <Form.Item
                      name="conditions"
                      rules={[
                        {
                          validator(_, value) {
                            const regex = /<script[\s\S]*?>/gi;
                            if (!regex.test(value)) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              new Error(i18n.t("validation.forbiddenTags"))
                            );
                          },
                        },
                      ]}
                    >
                      <SurveyRichEditor
                        value={editConditions}
                        onChange={(value) => {
                          setEditConditions(value);
                        }}
                      />
                    </Form.Item>
                    <Checkbox disabled>
                      {i18n.t("survey.questionText.conditions")}
                    </Checkbox>
                  </>
                )}
                {isGenerations && (
                  <QuestionEditRadioForm
                    handleRadioItemsChange={handleRadioItemsChange}
                    radioItems={editRadioItems}
                    callParentHasCustomText={callParentHasCustomText}
                    hasCustomText={hasCustomText}
                    callParentTextPlaceholderChange={
                      callParentTextPlaceholderChange
                    }
                    customTextPlaceholder={customTextPlaceholder}
                    callDefaultItemChange={callDefaultItemChange}
                    setDefaultItem={defaultItem}
                  />
                )}

                {isComment && (
                  <Form.Item name="placeholder">
                    <TextArea
                      rows={4}
                      name="placeholder"
                      placeholder={i18n.t("survey.editPlaceholder")}
                    />
                  </Form.Item>
                )}
                {isRating && <Rate disabled />}
                {isNumber && <InputNumber name="number" disabled />}
                {isRadiogroup && (
                  <QuestionEditRadioForm
                    handleRadioItemsChange={handleRadioItemsChange}
                    radioItems={editRadioItems}
                    callParentHasCustomText={callParentHasCustomText}
                    hasCustomText={hasCustomText}
                    callParentTextPlaceholderChange={
                      callParentTextPlaceholderChange
                    }
                    customTextPlaceholder={customTextPlaceholder}
                    callDefaultItemChange={callDefaultItemChange}
                    setDefaultItem={defaultItem}
                  />
                )}
                {isPromptRadioButton && (
                  <QuestionEditRadioForm
                    isPromptRadioButton={true}
                    handleRadioItemsChange={handleRadioItemsChange}
                    radioItems={editRadioItems}
                    callParentHasCustomText={callParentHasCustomText}
                    hasCustomText={hasCustomText}
                    callParentTextPlaceholderChange={
                      callParentTextPlaceholderChange
                    }
                    customTextPlaceholder={customTextPlaceholder}
                    callNotPromptCheckboxChange={callNotPromptCheckboxChange}
                    callDefaultItemChange={callDefaultItemChange}
                    setDefaultItem={defaultItem}
                  />
                )}
                {isCheckbox && (
                  <>
                    <Space direction="vertical">
                      {editCheckboxItems.map((item, index) => {
                        const isDeleteVisible =
                          index === 0 ? "hidden" : undefined;
                        return (
                          <div
                            key={item.name}
                            className="question-item-horizontal"
                          >
                            <Checkbox
                              name="checkbox"
                              disabled
                              className="question-item-horizontal"
                            >
                              <Input
                                name={item.name}
                                value={item.value}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  const newItem = editCheckboxItems.map(
                                    (item, i) =>
                                      i === index ? { ...item, value } : item
                                  );
                                  setEditCheckboxItems(newItem);
                                }}
                              />
                            </Checkbox>
                            <PlusCircleOutlined
                              onClick={() =>
                                setEditCheckboxItems(
                                  appendOption(
                                    editCheckboxItems,
                                    index,
                                    "checkbox"
                                  )
                                )
                              }
                              className="question-item-option-icon"
                              style={{ color: "blue" }}
                            />
                            <MinusCircleOutlined
                              onClick={() =>
                                setEditCheckboxItems(
                                  deleteOption(
                                    editCheckboxItems,
                                    index,
                                    "checkbox"
                                  )
                                )
                              }
                              className="question-item-option-icon"
                              style={{
                                color: "red",
                                visibility: isDeleteVisible,
                              }}
                            />
                            <Checkbox
                              checked={item.notPrompt}
                              onChange={notPromptCheckboxChange(index)}
                              style={{ marginLeft: "5px" }}
                            >
                              {i18n.t("survey.notPrompt")}
                            </Checkbox>
                          </div>
                        );
                      })}
                    </Space>
                    <div className="radio-free-text">
                      <Checkbox
                        checked={hasCustomText}
                        onChange={customTextCheckboxChange}
                        style={{ marginBottom: "5px" }}
                      >
                        {i18n.t("survey.freeTextLabel")}
                      </Checkbox>
                      <TextArea
                        rows={4}
                        name="placeholder"
                        placeholder={i18n.t("survey.editPlaceholder")}
                        disabled={!hasCustomText}
                        onChange={customTextChange}
                        value={customTextPlaceholder}
                      />
                    </div>
                  </>
                )}
              </div>
            </Card>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    radioItems: state.survey.radioItems,
    checkboxItems: state.survey.checkboxItems,
  };
};

const mapDispatchToProps = {
  setRadioItems,
  setCheckboxItems,
};

export default connect(mapStateToProps, mapDispatchToProps)(QuestionEdit);
