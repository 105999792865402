import React from "react";
import QRCode from "qrcode.react";
import { UI_BASE } from "../constants";
import i18n from "i18n";
import { Typography } from "antd";

const { Text } = Typography;

interface BookmarkQRProps {
  meoSiteId: number;
}

const BookmarkQR: React.FC<BookmarkQRProps> = (props) => {
  const url = `${UI_BASE}/publish?meo_site_id=${props.meoSiteId}`;

  return (
    <div>
      <Text type="secondary">{i18n.t("menu.bookmarkQR")}</Text>
      <QRCode
        style={{ marginTop: "1em" }}
        value={url}
        size={128}
        level="M"
        includeMargin={true}
      />
    </div>
  );
};

export default BookmarkQR;
