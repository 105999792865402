import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Client, UsageStatistics, SSOClient } from "../../api/sms-api";
import { CustomError, ErrorWrapper } from "errors/errors";
import { CRUDState, initialCRUDState } from "store/CRUDStore";

interface ClientState extends CRUDState<Client> {
  getLoading: boolean;
  editLoading: boolean;

  ssoClient?: SSOClient;
  ssoClientLoading: boolean;

  statistics?: UsageStatistics;
  statisticsLoading: boolean;
}

export const initialState: ClientState = {
  ...initialCRUDState,
  statisticsLoading: false,
  ssoClientLoading: false,
};

export const slice = createSlice({
  name: "client",
  initialState,
  reducers: {
    editClientStart: (state) => {
      state.editLoading = true;
    },
    editClientSuccess: (state, action: PayloadAction<Client>) => {
      state.item = action.payload;
      state.editLoading = false;
    },
    editClientError: (state, _action: PayloadAction<CustomError>) => {
      state.editLoading = false;
    },
    getClientStart: (state) => {
      state.getLoading = true;
    },
    getClientSuccess: (state, action: PayloadAction<Client>) => {
      state.item = action.payload;
      state.getLoading = false;
      state.error = undefined;
    },
    getClientError: (state, action: PayloadAction<ErrorWrapper>) => {
      state.getLoading = false;
      state.error = action.payload;
    },
    getSSOClientStart: (state) => {
      state.ssoClientLoading = true;
    },
    getSSOClientSuccess: (state, action: PayloadAction<SSOClient>) => {
      state.ssoClient = action.payload;
      state.ssoClientLoading = false;
    },
    getSSOClientError: (state) => {
      state.ssoClientLoading = false;
    },
    getUsageStatisticsStart: (state) => {
      state.statisticsLoading = true;
    },
    getUsageStatisticsSuccess: (
      state,
      action: PayloadAction<UsageStatistics>
    ) => {
      state.statistics = action.payload;
      state.statisticsLoading = false;
    },
    getUsageStatisticsError: (state, action: PayloadAction<ErrorWrapper>) => {
      state.statisticsLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  editClientStart,
  editClientSuccess,
  editClientError,
  getClientStart,
  getClientSuccess,
  getClientError,
  getSSOClientStart,
  getSSOClientSuccess,
  getSSOClientError,
  getUsageStatisticsStart,
  getUsageStatisticsSuccess,
  getUsageStatisticsError,
} = slice.actions;

export default slice.reducer;
