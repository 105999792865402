import React, { useState, useEffect, useRef } from "react";
import { Formik } from "formik";
import { Form, Input, Switch } from "formik-antd";
import i18n from "i18n";
import { CloseOutlined } from "@ant-design/icons";
import { Spin, Row, Col, Button } from "antd";
import SaveButton from "components/SaveButton";
import { connect } from "react-redux";
import { RootState } from "store/store";
import {
  Surveys,
  SurveysEditForm,
  LogoUploadResult,
  LanguageModel,
  LanguageSettings,
} from "api/sms-api";
import {
  addSurveys,
  editSurveySettings,
  getSurvey,
  uploadLogo,
  getLanguageModelListAll,
  getLanguageSettingsListAll,
} from "./SurveyActions";
import { useHistory } from "react-router-dom";
import { pushTo } from "utils/navigation";
import qs from "qs";
import RandomDisplaySetting from "./RandomDisplaySettingModal";
import { Page, setPage } from "features/appstate/AppStateSlice";
import {
  MODE_SURVEY,
  surveyTypeStore,
  proofReadingModel,
  ImpressionPromptMode,
} from "../../constants";
import RichEditor from "components/RichEditor";
import SelectGptModelModal from "./SelectGptModelModal";
import SelectImpressionPromptModal from "./SelectImpressionPromptModal";
import SelectLanguageModal from "./SelectLanguageModal";

const DEFAULT_RANDOM_COUNT = 2;
const DEFAULT_LANGUAGE_MODEL = "4";
const DEFAULT_LANGUAGE_SETTING = "en";

interface SurveySettingsProps {
  setPage: (page: Page) => void;

  survey: Surveys;
  getLoading: boolean;
  getSurvey: (surveyId: string) => void;

  addSurveys: (params: SurveysEditForm) => Promise<void>;
  editSurveySettings: (
    surveyId: string,
    params: SurveysEditForm
  ) => Promise<void>;

  uploadLogo: (file: File) => Promise<LogoUploadResult | undefined>;

  languageModel: Array<LanguageModel>;
  getLanguageModelListAll: () => void;
  getLanguageModelLoading: boolean;

  languageSettings: Array<LanguageSettings>;
  getLanguageSettingsListAll: () => void;
  getLanguageSettingsListLoading: boolean;
}

const SurveySettings: React.FC<SurveySettingsProps> = (props) => {
  const history = useHistory();
  const [isRandomDisplayModalOpen, setIsRandomDisplayModalOpen] = useState(
    false
  );
  const [isLanguageModel, setIsLanguageModel] = useState(false);
  const [isImpressionPrompt, setIsImpressionPrompt] = useState(false);
  const [isLanguageSettings, setIsLanguageSettings] = useState(false);
  const [logoUrl, setLogoUrl] = useState<string>("");
  const logoInputRef = useRef<HTMLInputElement>(null);

  const parsed = qs.parse(location.search, { ignoreQueryPrefix: true });
  const surveyId = parsed.id ? parsed.id.toString() : "";
  const type = parsed.type ? parsed.type.toString() : "";
  const surveyType = parsed.surveyType
    ? (parsed.surveyType as string)
    : surveyTypeStore;

  useEffect(() => {
    props.setPage(Page.surveySettings);
  }, [props.setPage]);

  useEffect(() => {
    if (surveyId) {
      props.getSurvey(surveyId);
    }
  }, [props.getSurvey]);

  useEffect(() => {
    if (props.survey && props.survey.logoUrl) {
      setLogoUrl(props.survey.logoUrl);
    }
  }, [props.survey]);

  useEffect(() => {
    props.getLanguageModelListAll();
  }, [props.getLanguageModelListAll]);

  useEffect(() => {
    props.getLanguageSettingsListAll();
  }, [props.getLanguageSettingsListAll]);

  if (
    props.getLoading ||
    props.getLanguageModelLoading ||
    props.getLanguageSettingsListLoading
  ) {
    return <Spin />;
  }

  const handleSubmit = async (form: SurveysEditForm) => {
    form.logoUrl = logoUrl;
    let targetRoute = "/survey";
    if (type === "new" || type === "template") {
      if (props.survey && props.survey.id) {
        await props.editSurveySettings(props.survey.id, form);
      } else {
        await props.addSurveys(form);
      }
      if (type === "new") {
        targetRoute = `/${Page.survey}/edit`;
      } else if (type === "template") {
        targetRoute = `/${Page.survey}/template`;
      }
      pushTo(history, targetRoute, {
        mode: MODE_SURVEY,
        surveyType: parsed.surveyType || surveyTypeStore,
        type: type,
      });
    } else {
      await props.editSurveySettings(surveyId, form);
      pushTo(history, targetRoute, {
        mode: MODE_SURVEY,
        surveyType: parsed.surveyType || surveyTypeStore,
      });
    }
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }

    const result = await props.uploadLogo(e.target.files[0]);

    if (result) {
      setLogoUrl(result.url);
    }
  };

  const handleFileRemove = async () => {
    if (logoInputRef.current) {
      logoInputRef.current.value = "";
    }
    setLogoUrl("");
  };

  const initialValues = {
    title: (props.survey && props.survey.title) || "",
    topPageTitle: (props.survey && props.survey.topPageTitle) || "",
    topPageNote:
      (props.survey && props.survey.topPageNote) ||
      i18n.t("survey.defaultTopPageNote"),
    logoUrl: logoUrl || "",
    publish: (props.survey && props.survey.publish) || false,
    randomDisplay: props.survey?.randomDisplay || false,
    randomDisplayCount:
      props.survey?.randomDisplayCount || DEFAULT_RANDOM_COUNT,
    customPrompt: props.survey?.customPrompt || false,
    customPromptText: props.survey?.customPromptText || "",
    randomQuestionsOrder: props.survey?.randomQuestionsOrder ?? false,
    isLanguageModelSelectable: props.survey?.isLanguageModelSelectable || false,
    languageModelId:
      (props.survey?.languageModelId ||
        props.languageModel.find((item) => item.code === DEFAULT_LANGUAGE_MODEL)
          ?.id) ??
      null,
    surveyType,
    isProofreading: props.survey?.isProofreading || false,
    isLanguageSettingsSelectable:
      props.survey?.settingOptions.isLanguageSettingsSelectable || false,
    languageSettingsId:
      (props.survey?.settingOptions.languageSettingsId ||
        props.languageSettings.find(
          (item) => item.code === DEFAULT_LANGUAGE_SETTING
        )?.id) ??
      null,
    isImpressionPrompt:
      props.survey?.settingOptions.impressionPromptOptions.isImpressionPrompt ||
      false,
    impressionPromptMode:
      props.survey?.settingOptions.impressionPromptOptions
        .impressionPromptMode || ImpressionPromptMode.Random,
  };

  const getImpressionModeText = (
    isImpressionPrompt: boolean,
    impressionPromptMode: ImpressionPromptMode
  ) => {
    if (!isImpressionPrompt) return null;

    return impressionPromptMode === ImpressionPromptMode.Random
      ? i18n.t("survey.impressionPromptMode.random")
      : i18n.t("survey.impressionPromptMode.always");
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {(formikBag) => {
        return (
          <Form layout="vertical">
            <div className="question-container">
              <Row justify="center">
                <Col span={18}>
                  <Form.Item name="title" label={i18n.t("survey.surveyTitle")}>
                    <Input name="title" />
                  </Form.Item>
                  <Form.Item name="logo" label={i18n.t("survey.logo")}>
                    <input
                      type="file"
                      onChange={handleFileChange}
                      ref={logoInputRef}
                    />
                    {logoUrl && (
                      <Row align="top">
                        <img
                          style={{ marginTop: "1em", maxWidth: "90%" }}
                          src={logoUrl}
                        />
                        <Button
                          shape="circle"
                          icon={<CloseOutlined />}
                          type="text"
                          style={{ marginTop: "0.5em" }}
                          onClick={handleFileRemove}
                        />
                      </Row>
                    )}
                  </Form.Item>
                  <Form.Item
                    name="topPageTitle"
                    label={i18n.t("survey.topPageTitle")}
                  >
                    <Input name="topPageTitle" />
                  </Form.Item>
                  <Form.Item
                    name="topPageNote"
                    label={i18n.t("survey.topPageNote")}
                  >
                    <RichEditor
                      value={formikBag.values.topPageNote || ""}
                      onChange={(value) => {
                        formikBag.setFieldValue("topPageNote", value);
                      }}
                    />
                  </Form.Item>
                  <Form.Item name="publish" label={i18n.t("survey.publish")}>
                    <Switch name="publish" />
                  </Form.Item>
                  <Form.Item
                    name="randomDisplay"
                    label={i18n.t("survey.randomDisplay")}
                  >
                    <Switch
                      name="randomDisplay"
                      onChange={(checked: boolean) => {
                        if (checked) {
                          setIsRandomDisplayModalOpen(true);
                        }
                      }}
                    />
                    {formikBag.values.randomDisplay && (
                      <span className="toggle-label">
                        {i18n.t("survey.randomDisplayCount", {
                          count:
                            formikBag.values.randomDisplayCount ||
                            DEFAULT_RANDOM_COUNT,
                        })}
                      </span>
                    )}
                  </Form.Item>
                  <RandomDisplaySetting
                    visible={isRandomDisplayModalOpen}
                    closeModal={() => setIsRandomDisplayModalOpen(false)}
                  />
                  <Form.Item
                    name="randomQuestionsOrder"
                    label={i18n.t("survey.randomQuestionsOrder")}
                  >
                    <Switch name="randomQuestionsOrder" />
                  </Form.Item>
                  <Form.Item
                    name="isLanguageModelSelectable"
                    label={i18n.t("survey.selectGptModel")}
                  >
                    <Switch
                      name="isLanguageModelSelectable"
                      onChange={(checked: boolean) => {
                        if (checked) {
                          setIsLanguageModel(true);
                        }
                      }}
                    />
                    {formikBag.values.isLanguageModelSelectable && (
                      <>
                        <span className="toggle-label">
                          {props.languageModel.find(
                            (item) =>
                              item.id === formikBag.values.languageModelId
                          )?.name ?? ""}
                        </span>
                        <span className="toggle-label">
                          {props.languageModel
                            .find(
                              (item) =>
                                item.id === formikBag.values.languageModelId
                            )
                            ?.name?.includes(proofReadingModel) &&
                          formikBag.values.isProofreading
                            ? i18n.t("survey.selectGptModelOption")
                            : ""}
                        </span>
                      </>
                    )}
                  </Form.Item>
                  <SelectGptModelModal
                    visible={isLanguageModel}
                    closeModal={() => setIsLanguageModel(false)}
                    selectModelId={formikBag.values.languageModelId}
                  />
                  <Form.Item
                    name="isImpressionPrompt"
                    label={i18n.t("survey.selectImpressionPrompt")}
                  >
                    <Switch
                      name="isImpressionPrompt"
                      onChange={(checked: boolean) => {
                        if (checked) {
                          setIsImpressionPrompt(true);
                        }
                      }}
                    />
                    <span className="toggle-label">
                      {getImpressionModeText(
                        formikBag.values.isImpressionPrompt,
                        formikBag.values.impressionPromptMode
                      )}
                    </span>
                  </Form.Item>
                  <SelectImpressionPromptModal
                    visible={isImpressionPrompt}
                    closeModal={() => setIsImpressionPrompt(false)}
                  />
                  <Form.Item
                    name="customPrompt"
                    label={i18n.t("survey.customPrompt")}
                  >
                    <Switch name="customPrompt" />
                  </Form.Item>
                  <Form.Item name="customPromptText">
                    <Input.TextArea name="customPromptText" />
                  </Form.Item>
                  <Form.Item
                    name="isLanguageSettingsSelectable"
                    label={i18n.t("survey.selectLanguage")}
                  >
                    <Switch
                      name="isLanguageSettingsSelectable"
                      onChange={(checked: boolean) => {
                        if (checked) {
                          setIsLanguageSettings(true);
                        }
                      }}
                    />
                    {formikBag.values.isLanguageSettingsSelectable && (
                      <span className="toggle-label">
                        {props.languageSettings.find(
                          (item) =>
                            item.id === formikBag.values.languageSettingsId
                        )?.name ?? ""}
                      </span>
                    )}
                  </Form.Item>
                  <SelectLanguageModal
                    visible={isLanguageSettings}
                    closeModal={() => setIsLanguageSettings(false)}
                    selectLanguageId={formikBag.values.languageSettingsId}
                  />
                </Col>
              </Row>
              <Row justify="end" style={{ marginTop: "2.5em" }}>
                <SaveButton />
              </Row>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    survey: state.survey.survey!,
    getLoading: state.survey.getLoading,
    languageModel: state.survey.languageModel,
    getLanguageModelLoading: state.survey.getLanguageModelLoading,
    languageSettings: state.survey.languageSettings,
    getLanguageSettingsLoading: state.survey.getLanguageSettingsListLoading,
  };
};

const mapDispatchToProps = {
  setPage,
  getSurvey,
  editSurveySettings,
  addSurveys,
  uploadLogo,
  getLanguageModelListAll,
  getLanguageSettingsListAll,
};

export default connect(mapStateToProps, mapDispatchToProps)(SurveySettings);
