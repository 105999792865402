import {
  Paginated,
  Order,
  OrderDetails,
  PaginationParams,
  OrderSurveyEmailWithProductsDetail,
  OrderCsvImportResult,
} from "api/sms-api";
import { AppDispatch } from "store/store";
import { httpWrapper } from "utils/http";
import {
  getOrderHistoryStart,
  getOrderHistorySuccess,
  getOrderHistoryError,
  getOrderDetailsStart,
  getOrderDetailsSuccess,
  getOrderDetailsError,
  getOrderSurveyEmailDetailsStart,
  getOrderSurveyEmailDetailsSuccess,
  getOrderSurveyEmailDetailsError,
  exportStart,
  exportSuccess,
  exportError,
  importStart,
  importSuccess,
  importError,
} from "./OrderHistorySlice";
import { SMS_API_BASE, BOM } from "../../constants";
import { handleError } from "errors/errors";
import download from "js-file-download";
import i18n from "i18n";

export const getOrderHistory = (param: PaginationParams) => async (
  dispatch: AppDispatch
) => {
  dispatch(getOrderHistoryStart());
  try {
    const orders = await httpWrapper<Paginated<Order>>(
      "GET",
      `${SMS_API_BASE}/order_history/list_orders`,
      param
    );
    dispatch(getOrderHistorySuccess(orders));
  } catch (e) {
    handleError(e);
    dispatch(getOrderHistoryError());
  }
};

export const getOrderDetails = (orderId: string) => async (
  dispatch: AppDispatch
) => {
  dispatch(getOrderDetailsStart());
  try {
    const orderDetails = await httpWrapper<OrderDetails>(
      "GET",
      `${SMS_API_BASE}/order_history/order_details/${orderId}`,
      {}
    );
    dispatch(getOrderDetailsSuccess(orderDetails));
  } catch (e) {
    handleError(e);
    dispatch(getOrderDetailsError());
  }
};

export const getOrderSurveyEmailDetails = (orderId: string) => async (
  dispatch: AppDispatch
) => {
  dispatch(getOrderSurveyEmailDetailsStart());
  try {
    const orderSurveyEmailDetails = await httpWrapper<
      Array<OrderSurveyEmailWithProductsDetail>
    >(
      "GET",
      `${SMS_API_BASE}/order_history/order_survey_email_details/${orderId}`,
      {}
    );
    dispatch(getOrderSurveyEmailDetailsSuccess(orderSurveyEmailDetails));
  } catch (e) {
    handleError(e);
    dispatch(getOrderSurveyEmailDetailsError());
  }
};

export const exportCsv = () => async (dispatch: AppDispatch) => {
  dispatch(exportStart());
  try {
    const result = await httpWrapper<any>(
      "POST",
      `${SMS_API_BASE}/order_history/export`,
      {}
    );
    download(`${BOM}${result}`, i18n.t("orders.csv.fileName"));
    dispatch(exportSuccess());
    return result;
  } catch (e) {
    handleError(e);
    dispatch(exportError());
  }
};

export const importCsv = (file: File) => async (dispatch: AppDispatch) => {
  dispatch(importStart());
  try {
    const formData = new FormData();
    formData.append("file", file);
    const result = await httpWrapper<OrderCsvImportResult>(
      "POST",
      `${SMS_API_BASE}/order_history/import`,
      formData,
      { "Content-Type": "multipart/form-data" }
    );
    dispatch(importSuccess(result));
    return result;
  } catch (e) {
    handleError(e);
    dispatch(importError());
  }
};
