import React, { useEffect } from "react";
import { RootState } from "store/store";
import { connect } from "react-redux";
import { Modal, Button } from "antd";
import i18n from "i18n";
import { Descriptions, Spin, Table } from "antd";
import { getOrderDetails } from "./OrderHistoryActions";
import { OrderDetails, SimpleProduct } from "api/sms-api";
import moment from "moment";

interface OrderDetailsDialogProps {
  orderId: string;
  details: OrderDetails | null;
  detailsLoading: boolean;
  visible: boolean;
  existingParams: any;
  closeModal: () => void;

  getOrderDetails: (orderId: string) => void;
}

const OrderDetailsDialog: React.FC<OrderDetailsDialogProps> = (props) => {
  useEffect(() => {
    if (props.orderId) {
      props.getOrderDetails(props.orderId);
    }
  }, [props.getOrderDetails, props.orderId]);

  const onClickCancelButton = () => {
    props.closeModal();
  };

  if (props.detailsLoading) {
    return <Spin />;
  }

  const columns = () => [
    {
      title: i18n.t("orders.table.title.detailsProductName"),
      dataIndex: "productName",
      key: "productName",
    },
    {
      title: i18n.t("orders.table.title.detailsProductCode"),
      dataIndex: "productCode",
      key: "productCode",
    },
  ];

  let description: any = null;
  let products: any = null;
  if (props.details) {
    description = (
      <Descriptions bordered column={1}>
        <Descriptions.Item label={i18n.t("orders.table.title.orderId")}>
          {props.details?.orderId}
        </Descriptions.Item>
        <Descriptions.Item label={i18n.t("orders.table.title.purchasedAt")}>
          {moment(props.details?.purchasedAt).format("YYYY-MM-DD HH:mm:ss")}
        </Descriptions.Item>
        <Descriptions.Item label={i18n.t("orders.table.title.emailAddress")}>
          {props.details?.emailAddress}
        </Descriptions.Item>
      </Descriptions>
    );
    // Populate the products table
    products = (
      <Table
        bordered
        dataSource={props.details?.products}
        columns={columns()}
        rowKey={(product: SimpleProduct) => product.id}
        size="middle"
        pagination={false}
      />
    );
  }

  return (
    <Modal
      onCancel={onClickCancelButton}
      visible={props.visible}
      title={i18n.t("orders.table.title.details")}
      footer={[
        <Button key="close" onClick={onClickCancelButton}>
          {i18n.t("common.close")}
        </Button>,
      ]}
    >
      {description}
      <p />
      {products}
    </Modal>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    details: state.orderHistory.details,
    detailsLoading: state.orderHistory.detailsLoading,
  };
};

const mapDispatchToProps = {
  getOrderDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetailsDialog);
