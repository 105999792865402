import qs from "qs";
import _ from "lodash";
import * as H from "history";

const PARAMS_TO_PRESERVE = ["meo_site_id"];

// Make a new query param object that keeps the initial value of certain parameters.
// This includes the default list of parameters that are preserved no matter what, plus
// any parameters that are specified in the function call.
export const getNewParams = (paramsToSet: any, preserve: Array<string>) => {
  const parsed = qs.parse(location.search, { ignoreQueryPrefix: true });
  const newParams: any = { ...paramsToSet };
  const allPreserve = [...PARAMS_TO_PRESERVE, ...preserve];
  for (const param of allPreserve) {
    if (_.has(parsed, param)) {
      newParams[param] = parsed[param];
    }
  }

  return newParams;
};

/*
 * `pathname` -- if defined, push to this pathname. Otherwise, keep the current pathname
 * `queryParams` -- the query parameters whose values should be set
 * `preserve` -- parameters whose value should be preserved
 * Any parameters not present in `queryParams`, `preserve`, or the default `PARAMS_TO_PRESERVE`
 * will be deleted.
 */
export const pushTo = (
  history: H.History<any>,
  pathname: string | null,
  queryParams: any,
  preserve: Array<string> = [],
  replace = false
) => {
  const newParams = getNewParams(queryParams, preserve);
  const newPathname = pathname || location.pathname;
  const newLocationDescriptorObject = {
    pathname: newPathname,
    search: qs.stringify(newParams),
  };
  if (replace) {
    history.replace(newLocationDescriptorObject);
  } else {
    history.push(newLocationDescriptorObject);
  }
};
