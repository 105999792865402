import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GMBReviews, GMBReview, GMBReplyForm } from "../../api/sms-api";

// For manually updating a review that we post/edit/delete the reply on, since
// Google's API has some lag/caching issues...
export interface ReviewNeedsUpdate {
  reviewName: string;
  replyComment?: string;
}

interface ReviewState {
  gmbReviews?: GMBReviews;
  gmbReview?: GMBReview;
  listLoading: boolean;
  getLoading: boolean;
  updateLoading: boolean;
  deleteLoading: boolean;
  needsUpdate: Array<ReviewNeedsUpdate>;
  savedForm?: GMBReplyForm;
  selected: Array<GMBReview>;
}

const initialState: ReviewState = {
  listLoading: false,
  getLoading: false,
  updateLoading: false,
  deleteLoading: false,
  selected: [],
  needsUpdate: [],
};

export const slice = createSlice({
  name: "reviews",
  initialState,
  reducers: {
    listGMBStart: (state) => {
      state.listLoading = true;
    },
    listGMBSuccess: (state, action: PayloadAction<GMBReviews>) => {
      state.listLoading = false;
      state.gmbReviews = action.payload;
    },
    listGMBError: (state) => {
      state.listLoading = false;
    },
    getGMBStart: (state) => {
      state.getLoading = true;
    },
    getGMBSuccess: (state, action: PayloadAction<GMBReview>) => {
      state.getLoading = false;
      state.gmbReview = action.payload;
    },
    getGMBError: (state) => {
      state.getLoading = false;
    },
    updateGMBReplyStart: (state) => {
      state.updateLoading = true;
    },
    updateGMBReplySuccess: (state) => {
      state.updateLoading = false;
    },
    updateGMBReplyError: (state) => {
      state.updateLoading = false;
    },
    deleteGMBReplyStart: (state) => {
      state.deleteLoading = true;
    },
    deleteGMBReplySuccess: (state) => {
      state.deleteLoading = false;
    },
    deleteGMBReplyError: (state) => {
      state.deleteLoading = false;
    },
    setSavedForm: (state, action: PayloadAction<GMBReplyForm>) => {
      state.savedForm = action.payload;
    },
    clearSavedForm: (state) => {
      state.savedForm = undefined;
    },
    setNeedsUpdate: (
      state,
      action: PayloadAction<Array<ReviewNeedsUpdate>>
    ) => {
      state.needsUpdate = action.payload;
    },
    setSelected: (state, action: PayloadAction<Array<GMBReview>>) => {
      state.selected = action.payload;
    },
  },
});

export const {
  listGMBStart,
  listGMBSuccess,
  listGMBError,
  getGMBStart,
  getGMBSuccess,
  getGMBError,
  updateGMBReplyStart,
  updateGMBReplySuccess,
  updateGMBReplyError,
  deleteGMBReplyStart,
  deleteGMBReplySuccess,
  deleteGMBReplyError,
  setSavedForm,
  clearSavedForm,
  setNeedsUpdate,
  setSelected,
} = slice.actions;

export default slice.reducer;
