import React from "react";
import { connect } from "react-redux";
import { Page, setPage } from "features/appstate/AppStateSlice";
import { importCsv } from "features/meo_site_settings/MeoSiteSettingsActions";
import { MEOSite, RecipientEmailCsvImportResult } from "api/sms-api";
import { RootState } from "store/store";
import i18n from "i18n";
import { ImportOutlined } from "@ant-design/icons";
import { clearImport } from "./MeoSiteSettingsSlice";
import "moment/locale/ja";
import { Formik, FormikHelpers } from "formik";
import { formItemLayout } from "../../constants";
import { Form, Input } from "formik-antd";
import { Row, Button } from "antd";
import SaveButton from "components/SaveButton";
import ImportResult from "./ImportResult";
import BackButton from "components/BackButton";

interface RecipientEmailCsvImportProps {
  setPage: (page: Page) => void;
  meoSite: MEOSite;
  loading: boolean;
  result?: RecipientEmailCsvImportResult;
  clearImport: () => void;
  importCsv: (file: File) => Promise<RecipientEmailCsvImportResult | undefined>;
}

interface ImportForm {
  file?: File;
}

const RecipientEmailCsvImport: React.FC<RecipientEmailCsvImportProps> = (
  props
) => {
  const [file, setFile] = React.useState<File | undefined>(undefined);

  React.useEffect(() => {
    props.setPage(Page.meoSiteSettings);
  }, [props.setPage]);

  React.useEffect(() => {
    // Run on unmount
    return () => {
      props.clearImport();
    };
  }, [props.clearImport]);

  const initialValues: ImportForm = {};

  const handleSubmit = async (_: any, formikBag: FormikHelpers<ImportForm>) => {
    if (file) {
      await props.importCsv(file);
      formikBag.resetForm();
      setFile(undefined);
    }
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }

    setFile(e.target.files[0]);
  };

  return (
    <div>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {(_formikBag) => {
          return (
            <Form {...formItemLayout}>
              <Form.Item
                name="file"
                label={i18n.t("meoSiteSettings.csv.importFile")}
              >
                <Input
                  name="file"
                  className="plain-input"
                  type="file"
                  onChange={handleFileChange}
                />
              </Form.Item>
              <Row className="form-button-row">
                <SaveButton
                  icon={<ImportOutlined />}
                  text={i18n.t("meoSiteSettings.csv.importButtonText")}
                  disabled={!file}
                  loading={props.loading}
                />
                <Button
                  href={`${process.env.PUBLIC_URL}/meo_sites_settings/sample.csv`}
                >
                  {i18n.t("meoSiteSettings.csv.sampleDownload")}
                </Button>
                <BackButton />
              </Row>
            </Form>
          );
        }}
      </Formik>
      {props.result && <ImportResult result={props.result} />}
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    loading: state.meoSiteSettings.createLoading,
    result: state.meoSiteSettings.importResult,
    meoSite: state.seo.meoSite!,
  };
};
const mapDispatchToProps = {
  setPage,
  importCsv,
  clearImport,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RecipientEmailCsvImport);
