import React from "react";
import { connect } from "react-redux";
import { Page, setPage } from "features/appstate/AppStateSlice";
import { Formik, FormikHelpers } from "formik";
import { Form, Input } from "formik-antd";
import { Button, Row } from "antd";
import i18n from "i18n";
import { GroupAddForm, MEOSite } from "api/sms-api";
import { formItemLayout } from "../../constants";
import { RootState } from "store/store";
import { addGroup } from "./GroupActions";
import { groupAddFormSchema } from "validation";
import BackButton from "components/BackButton";
import CreateButton from "components/CreateButton";
import { useHistory, useLocation } from "react-router-dom";
import qs from "qs";
import { pushTo } from "utils/navigation";
import { setSavedForm, clearSavedForm, clearGroup } from "./GroupSlice";

interface GroupAddProps {
  setPage: (page: Page) => void;
  addGroup: (form: GroupAddForm) => void;
  loading: boolean;
  addFinished: boolean;
  meoSite: MEOSite;
  savedForm?: ModifiedGroupForm;
  setSavedForm: (form: ModifiedGroupForm) => void;
  clearSavedForm: () => void;
  clearGroup: () => void;
}

export interface ModifiedGroupForm extends GroupAddForm {}

const GroupAdd: React.FC<GroupAddProps> = (props) => {
  const location = useLocation();
  const history = useHistory();
  const parsed = qs.parse(location.search, { ignoreQueryPrefix: true });
  React.useEffect(() => {
    props.setPage(Page.group);
  }, [props.setPage]);

  React.useEffect(() => {
    if (props.addFinished) {
      pushTo(history, `/${Page.group}`, {});
    }
  }, [props.addFinished]);

  React.useEffect(() => {
    // Run on unmount
    return () => {
      props.clearGroup();
    };
  }, [props.clearGroup]);

  let initialValues: ModifiedGroupForm = {
    meoSiteId: props.meoSite.id,
    name: "",
    addressIds: [],
  };

  if (props.savedForm && parsed.shortcutSelected === "back") {
    initialValues = props.savedForm;
    props.clearSavedForm();
  }

  const handleSubmit = (
    form: ModifiedGroupForm,
    helpers: FormikHelpers<ModifiedGroupForm>
  ) => {
    const groupAddForm: GroupAddForm = {
      meoSiteId: props.meoSite.id,
      name: form.name,
      addressIds: form.addressIds,
    };
    props.addGroup(groupAddForm);
    helpers.resetForm();
  };

  const goToSelectAddress = (form: ModifiedGroupForm) => {
    props.setSavedForm(form);
    pushTo(history, "/addresses", {
      selecting: "group",
      backUrl: "/group/add",
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={groupAddFormSchema}
    >
      {(formikBag) => {
        return (
          <Form {...formItemLayout}>
            <Form.Item name="name" label={i18n.t("common.name")}>
              <Input
                name="name"
                placeholder={i18n.t("groups.namePlaceholder")}
              />
            </Form.Item>
            <Form.Item
              name="addressIds"
              label={i18n.t("groups.addresses")}
              extra={i18n.t("groups.selectedCount", {
                count: formikBag.values.addressIds.length,
              })}
            >
              <Button
                className="template-shortcut max-width-on-mobile"
                onClick={() => goToSelectAddress(formikBag.values)}
              >
                {i18n.t("groups.selectFromAddress")}
              </Button>
              {formikBag.errors.addressIds && formikBag.touched.addressIds && (
                <div className="ant-form-item-has-error">
                  <div className="ant-form-item-explain">
                    {formikBag.errors.addressIds}
                  </div>
                </div>
              )}
            </Form.Item>
            <Row className="form-button-row">
              <CreateButton loading={props.loading} />
              <BackButton />
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = (root: RootState) => {
  return {
    loading: root.group.createLoading,
    addFinished: root.group.addFinished,
    meoSite: root.seo.meoSite!,
    savedForm: root.group.savedForm,
  };
};

const mapDispatchToProps = {
  setPage,
  addGroup,
  setSavedForm,
  clearSavedForm,
  clearGroup,
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupAdd);
