import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Paginated, FullScheduledWithParts } from "api/sms-api";
import { initialCRUDState } from "store/CRUDStore";
import { ErrorWrapper } from "errors/errors";
import { ModifiedScheduledEditForm } from "./ScheduleEdit";

interface ScheduleState {
  items: Paginated<FullScheduledWithParts>;
  item?: FullScheduledWithParts;
  listLoading: boolean;
  createLoading: boolean;
  getLoading: boolean;
  editLoading: boolean;
  deleteLoading: boolean;
  deleteFinished: boolean;
  addFinished: boolean;
  editFinished: boolean;
  error?: ErrorWrapper;
  savedForm?: ModifiedScheduledEditForm;
}

const initialState: ScheduleState = {
  ...initialCRUDState,
};

export const slice = createSlice({
  name: "schedule",
  initialState,
  reducers: {
    listScheduledsStart: (state) => {
      state.listLoading = true;
      state.error = undefined;
    },
    listScheduledsSuccess: (
      state,
      action: PayloadAction<Paginated<FullScheduledWithParts>>
    ) => {
      state.items = action.payload;
      state.listLoading = false;
    },
    listScheduledsError: (state, _action: PayloadAction<ErrorWrapper>) => {
      state.listLoading = false;
    },
    editScheduledStart: (state) => {
      state.editLoading = true;
      state.error = undefined;
    },
    editScheduledSuccess: (state) => {
      state.editLoading = false;
    },
    editScheduledError: (state, action: PayloadAction<ErrorWrapper>) => {
      state.editLoading = false;
      state.error = action.payload;
    },
    getScheduledStart: (state) => {
      state.getLoading = true;
      state.error = undefined;
    },
    getScheduledSuccess: (
      state,
      action: PayloadAction<FullScheduledWithParts>
    ) => {
      state.item = action.payload;
      state.getLoading = false;
    },
    getScheduledError: (state, action: PayloadAction<ErrorWrapper>) => {
      state.getLoading = false;
      state.error = action.payload;
    },
    deleteScheduledStart: (state) => {
      state.deleteLoading = true;
      state.error = undefined;
    },
    deleteScheduledSuccess: (state) => {
      state.deleteLoading = false;
      state.deleteFinished = true;
    },
    deleteScheduledError: (state, _action: PayloadAction<ErrorWrapper>) => {
      state.deleteLoading = false;
    },
    clearScheduled: (state) => {
      // Reset everything that was specific to viewing one Scheduled,
      state.item = undefined;
      state.deleteFinished = false;
    },
    clearItems: (state) => {
      state.items = { items: [], hasRight: false, hasLeft: false };
    },
    setSavedForm: (state, action: PayloadAction<ModifiedScheduledEditForm>) => {
      state.savedForm = action.payload;
    },
    clearSavedForm: (state) => {
      state.savedForm = undefined;
    },
  },
});

export const {
  listScheduledsStart,
  listScheduledsSuccess,
  listScheduledsError,
  editScheduledStart,
  editScheduledSuccess,
  editScheduledError,
  getScheduledStart,
  getScheduledSuccess,
  getScheduledError,
  deleteScheduledStart,
  deleteScheduledSuccess,
  deleteScheduledError,
  clearScheduled,
  clearItems,
  setSavedForm,
  clearSavedForm,
} = slice.actions;

export default slice.reducer;
