import React from "react";
import { Contact, ContactGroups } from "api/sms-api";
import { Table } from "antd";
import PageLocale from "./PageLocale";
import i18n from "i18n";

interface ContactBlockHistoryProps {
  contactGroups: Array<ContactGroups>;
  showStatus: boolean;
  isDirect: boolean;
}

const locale = PageLocale;

const ContactBlockHistory: React.FC<ContactBlockHistoryProps> = (props) => {
  const columns: Array<any> = [
    {
      title: i18n.t("common.name"),
      dataIndex: "contact",
      key: "contact",
      render: (contact: Contact) => {
        return contact.name;
      },
    },
    {
      title: i18n.t("common.phoneNumber"),
      dataIndex: "contact",
      key: "contact",
      render: (contact: Contact) => {
        return contact.phoneNumber;
      },
    },
  ];

  if (props.showStatus) {
    columns.push({
      title: i18n.t("contacts.status"),
      dataIndex: "contact",
      key: "contact",
      render: (contact: Contact) => {
        return i18n.t(`contacts.statuses.${contact.status}`);
      },
    });
  }
  if (!props.isDirect) {
    columns.push({
      title: i18n.t("contacts.groups"),
      dataIndex: "groupNames",
      key: "groupNames",
      render: (groupNames: Array<string>) => {
        return groupNames.join(", ");
      },
    });
  }

  return (
    <Table
      title={() =>
        props.isDirect
          ? i18n.t("common.addressDirect")
          : i18n.t("common.addresses")
      }
      dataSource={props.contactGroups}
      rowKey={(contactGroups: ContactGroups) => contactGroups.contact.id}
      columns={columns}
      pagination={{ locale }}
    />
  );
};

export default ContactBlockHistory;
