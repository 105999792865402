import React from "react";
import BackButton from "./BackButton";
import i18n from "i18n";
import { Row } from "antd";
import { Typography } from "antd";

const { Text } = Typography;

interface MessageDisplayProps {
  message: string;
}

const MessageDisplay: React.FC<MessageDisplayProps> = (props) => {
  return (
    <div>
      <Text type="secondary">{i18n.t("common.message")}</Text>
      <p style={{ whiteSpace: "pre-wrap", marginTop: "1em" }}>
        {props.message}
      </p>
      <Row className="form-button-row">
        <BackButton />
      </Row>
    </div>
  );
};

export default MessageDisplay;
