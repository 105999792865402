import React from "react";
import { connect } from "react-redux";
import { ClientEditForm, Client, PublishType } from "api/sms-api";
import { editClient } from "./ClientActions";
import { RootState } from "store/store";
import { Page, setPage } from "features/appstate/AppStateSlice";
import { Spin, Row } from "antd";
import { CustomError, ErrorWrapper } from "errors/errors";
import { Formik, FormikHelpers } from "formik";
import { Form, Input, Radio } from "formik-antd";
import i18n from "i18n";
import { ClientEditFormSchema } from "validation";
import { formItemLayout } from "../../constants";
import NotFound from "components/NotFound";
import SaveButton from "components/SaveButton";
import { MobileOutlined, CloudOutlined } from "@ant-design/icons";

interface ClientEditProps {
  id: string;
  getLoading: boolean;
  editLoading: boolean;
  client?: Client;
  error?: ErrorWrapper;
  editClient: (form: ClientEditForm) => void;
  setPage: (page: Page) => void;
}

const ClientEdit: React.FC<ClientEditProps> = (props) => {
  React.useEffect(() => {
    props.setPage(Page.client);
  }, [props.setPage]);

  if (props.error?.error === CustomError.NotFound) {
    return <NotFound />;
  }

  if (props.getLoading || !props.client) {
    return <Spin />;
  }

  const initialValues: ClientEditForm = {
    sendUserId: props.client?.sendUserId || "",
    defaultPublishType: props.client?.defaultPublishType || PublishType.Direct,
  };

  const handleSubmit = (
    form: ClientEditForm,
    helpers: FormikHelpers<ClientEditForm>
  ) => {
    if (form.sendUserId == "") {
      delete form.sendUserId;
    }
    props.editClient(form);
    helpers.setFieldValue("sendUserId", form.sendUserId);
    helpers.setFieldValue("defaultPublishType", form.defaultPublishType);
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={ClientEditFormSchema}
      >
        {(_formikBag) => {
          return (
            <Form {...formItemLayout}>
              <Form.Item
                name="sendUserId"
                label={i18n.t("common.sendUserId")}
                extra={i18n.t("client.sendUserIdNote")}
              >
                <Input
                  name="sendUserId"
                  placeholder={i18n.t("common.sendUserIdPlaceholder")}
                />
              </Form.Item>
              <Form.Item
                name="defaultPublishType"
                label={i18n.t("client.type")}
              >
                <Radio.Group
                  name="defaultPublishType"
                  className="form-radio-group"
                  defaultValue={initialValues.defaultPublishType}
                >
                  <Radio.Button value={PublishType.Direct}>
                    <MobileOutlined style={{ marginRight: "0.5em" }} />
                    {i18n.t("publishTypes.directLink")}
                  </Radio.Button>
                  <Radio.Button value={PublishType.Cloud}>
                    <CloudOutlined style={{ marginRight: "0.5em" }} />
                    {i18n.t("publishTypes.cloud")}
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
              <Row className="form-button-row">
                <SaveButton loading={props.editLoading} />
              </Row>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

const mapStateToProps = (root: RootState) => {
  return {
    client: root.client.item,
    getLoading: root.client.getLoading,
    editLoading: root.client.editLoading,
    error: root.client.error,
  };
};
const mapDispatchToProps = {
  editClient,
  setPage,
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientEdit);
