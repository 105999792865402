import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  Paginated,
  Order,
  OrderDetails,
  OrderSurveyEmailWithProductsDetail,
  OrderCsvImportResult,
} from "api/sms-api";
import { CRUDState, initialCRUDState } from "store/CRUDStore";

export interface OrdersState extends CRUDState<Order> {
  selectedOrders: Array<Order>;
  detailsLoading: boolean;
  details: OrderDetails | null;
  orderSurveyEmailDetails: Array<OrderSurveyEmailWithProductsDetail>;
  orderSurveyEmailDetailsLoading: boolean;
  importResult?: OrderCsvImportResult;
  csvImporting: boolean;
  csvExporting: boolean;
}

const initialState: OrdersState = {
  ...initialCRUDState,
  selectedOrders: [],
  details: null,
  detailsLoading: false,
  orderSurveyEmailDetails: [],
  orderSurveyEmailDetailsLoading: false,
  csvImporting: false,
  csvExporting: false,
};

export const slice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    getOrderHistoryStart: (state) => {
      state.listLoading = true;
    },
    getOrderHistorySuccess: (
      state,
      action: PayloadAction<Paginated<Order>>
    ) => {
      state.items = action.payload;
      state.listLoading = false;
    },
    getOrderHistoryError: (state) => {
      state.listLoading = false;
    },
    getOrderDetailsStart: (state) => {
      state.detailsLoading = true;
    },
    getOrderDetailsSuccess: (state, action: PayloadAction<OrderDetails>) => {
      state.details = action.payload;
      state.detailsLoading = false;
    },
    getOrderDetailsError: (state) => {
      state.detailsLoading = false;
    },
    clearItems: (state) => {
      state.items = { items: [], hasRight: false, hasLeft: false };
    },
    setSelectedOrders: (state, action: PayloadAction<Array<Order>>) => {
      state.selectedOrders = action.payload;
    },
    clearSelectedOrders: (state) => {
      state.selectedOrders = [];
    },
    getOrderSurveyEmailDetailsStart: (state) => {
      state.orderSurveyEmailDetailsLoading = true;
    },
    getOrderSurveyEmailDetailsSuccess: (
      state,
      action: PayloadAction<Array<OrderSurveyEmailWithProductsDetail>>
    ) => {
      state.orderSurveyEmailDetails = action.payload;
      state.orderSurveyEmailDetailsLoading = false;
    },
    getOrderSurveyEmailDetailsError: (state) => {
      state.orderSurveyEmailDetailsLoading = false;
    },
    exportStart: (state) => {
      state.csvExporting = true;
    },
    exportSuccess: (state) => {
      state.csvExporting = false;
    },
    exportError: (state) => {
      state.csvExporting = false;
    },
    importStart: (state) => {
      state.csvImporting = true;
    },
    importSuccess: (state, action: PayloadAction<OrderCsvImportResult>) => {
      state.csvImporting = false;
      state.importResult = action.payload;
    },
    importError: (state) => {
      state.csvImporting = false;
    },
    clearImport: (state) => {
      state.importResult = undefined;
    },
  },
});

export const {
  clearItems,
  getOrderHistoryStart,
  getOrderHistorySuccess,
  getOrderHistoryError,
  getOrderDetailsStart,
  getOrderDetailsSuccess,
  getOrderDetailsError,
  setSelectedOrders,
  clearSelectedOrders,
  getOrderSurveyEmailDetailsStart,
  getOrderSurveyEmailDetailsSuccess,
  getOrderSurveyEmailDetailsError,
  exportStart,
  exportSuccess,
  exportError,
  importStart,
  importSuccess,
  importError,
  clearImport,
} = slice.actions;

export default slice.reducer;
