import { Button, Col, Popconfirm, Row, Spin, Typography } from "antd";
import { GMBReplyForm, GMBReview, MEOSite } from "api/sms-api";
import { formItemLayout } from "../../constants";
import { Formik } from "formik";
import { Form, Input } from "formik-antd";
import i18n from "i18n";
import React from "react";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { RootState } from "store/store";
import { getGMBReview, updateGMBReply, deleteGMBReply } from "./ReviewActions";
import { ReviewNeedsUpdate, setNeedsUpdate, setSavedForm } from "./ReviewSlice";
import { MailOutlined, DeleteOutlined, FormOutlined } from "@ant-design/icons";
import { pushTo } from "utils/navigation";
import { Page } from "features/appstate/AppStateSlice";
import { gmbReplyFormSchema } from "validation";
import BackButton from "components/BackButton";
import { PromptIfDirty } from "components/PromptIfDirty";

const { Text } = Typography;

interface ReviewDetailsProps {
  gmbReview?: GMBReview;
  getLoading: boolean;
  updateLoading: boolean;
  deleteLoading: boolean;
  savedForm?: GMBReplyForm;
  getGMBReview: (meoSiteId: number, reviewName: string) => Promise<void>;
  updateGMBReply: (form: GMBReplyForm, isUpdate: boolean) => Promise<void>;
  deleteGMBReply: (meoSiteId: number, reviewName: string) => Promise<void>;
  setSavedForm: (form: GMBReplyForm) => void;
  setNeedsUpdate: (items: Array<ReviewNeedsUpdate>) => void;
  meoSite: MEOSite;
}

const ReviewDetails: React.FC<ReviewDetailsProps> = (props) => {
  const history = useHistory();
  const { name } = useParams() as any;
  React.useEffect(() => {
    props.getGMBReview(props.meoSite.id, name);
  }, [props.meoSite, props.getGMBReview, name]);

  if (!props.gmbReview || props.getLoading) {
    return <Spin />;
  }

  const initialValues: GMBReplyForm = {
    reviewNames: [],
    meoSiteId: props.meoSite.id,
    comment: props.gmbReview.reviewReply?.comment || "",
  };

  if (props.savedForm) {
    initialValues.comment = props.savedForm.comment;
  }

  const handleSubmit = async (form: GMBReplyForm) => {
    const newForm = {
      ...form,
      reviewNames: [props.gmbReview!.name],
    };
    const isUpdate = !!props.gmbReview!.reviewReply;
    await props.updateGMBReply(newForm, isUpdate);
    props.setNeedsUpdate([
      {
        reviewName: props.gmbReview!.name,
        replyComment: form.comment,
      },
    ]);
    pushTo(history, `/${Page.reviews}`, {});
  };

  const handleDelete = async () => {
    await props.deleteGMBReply(props.meoSite.id, props.gmbReview!.name);
    props.setNeedsUpdate([
      {
        reviewName: props.gmbReview!.name,
      },
    ]);
    pushTo(history, `/${Page.reviews}`, {});
  };

  let comment = (
    <Text style={{ fontStyle: "italic" }} type="secondary">
      {i18n.t("reviews.noComment")}
    </Text>
  );

  if (props.gmbReview!.comment) {
    comment = <Text>{props.gmbReview!.comment}</Text>;
  }

  const goToSelectTemplate = () => {
    const reviewName = props.gmbReview!.name;
    props.setSavedForm({
      meoSiteId: props.meoSite.id,
      reviewNames: [reviewName],
      comment: "",
    });
    pushTo(history, `/${Page.replyTemplates}`, {
      selecting: true,
      backUrl: `/${Page.reviews}/${encodeURIComponent(reviewName)}`,
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={gmbReplyFormSchema}
      onSubmit={handleSubmit}
    >
      {(_formikBag) => {
        return (
          <Form {...formItemLayout}>
            <PromptIfDirty message={i18n.t("reviews.leaveConfirm")} />
            <Form.Item name="comment" label={i18n.t("reviews.replyComment")}>
              <Input.TextArea name="comment" autoSize={{ minRows: 3 }} />
            </Form.Item>
            <Row className="ant-form-item">
              <Col {...formItemLayout.labelCol}></Col>
              <Col>
                <Button icon={<FormOutlined />} onClick={goToSelectTemplate}>
                  {i18n.t("reviews.selectFromTemplate")}
                </Button>
              </Col>
            </Row>
            <Form.Item name="unused" label={i18n.t("reviews.content")}>
              {comment}
            </Form.Item>
            <Row className="form-button-row">
              <Button
                loading={props.updateLoading}
                disabled={props.deleteLoading}
                icon={<MailOutlined />}
                htmlType="submit"
                type="primary"
              >
                {props.gmbReview?.reviewReply
                  ? i18n.t("reviews.updateReply")
                  : i18n.t("reviews.doReply")}
              </Button>
              <BackButton
                disabled={props.updateLoading || props.deleteLoading}
              />
              {props.gmbReview?.reviewReply && (
                <Popconfirm
                  title={i18n.t("reviews.deleteConfirm")}
                  onConfirm={handleDelete}
                  okText={i18n.t("common.delete")}
                  cancelText={i18n.t("common.cancel")}
                  okType="danger"
                >
                  <Button
                    loading={props.deleteLoading}
                    disabled={props.updateLoading}
                    danger={true}
                    icon={<DeleteOutlined />}
                  >
                    {i18n.t("reviews.deleteReply")}
                  </Button>
                </Popconfirm>
              )}
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    gmbReview: state.reviews.gmbReview,
    getLoading: state.reviews.getLoading,
    deleteLoading: state.reviews.deleteLoading,
    updateLoading: state.reviews.updateLoading,
    savedForm: state.reviews.savedForm,
    meoSite: state.seo.meoSite!,
  };
};

const mapDispatchToProps = {
  getGMBReview,
  updateGMBReply,
  deleteGMBReply,
  setSavedForm,
  setNeedsUpdate,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReviewDetails);
