import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  Template,
  Paginated,
  TemplateAddForm,
  MessageStats,
} from "../../api/sms-api";
import { ErrorWrapper } from "errors/errors";
import { CRUDState, initialCRUDState } from "store/CRUDStore";

export interface TemplateGetResult {
  template: Template;

  // Whether the template should be placed in the regular "item"
  // part of the store, or the "shortcutItem" part
  forShortcut: boolean;
}

export interface TemplateSavedForm extends TemplateAddForm {
  cursorPosition?: number;
}

interface TemplatesState extends CRUDState<Template> {
  shortcutItem?: Template;
  savedForm?: TemplateSavedForm;
  messageStats?: MessageStats;
  messageStatsLoading: boolean;
}

export const initialState: TemplatesState = {
  ...initialCRUDState,
  messageStatsLoading: false,
};

export const slice = createSlice({
  name: "templates",
  initialState,
  reducers: {
    listTemplatesStart: (state) => {
      state.listLoading = true;
    },
    listTemplatesSuccess: (
      state,
      action: PayloadAction<Paginated<Template>>
    ) => {
      state.items = action.payload;
      state.listLoading = false;
    },
    listTemplatesError: (state, _action: PayloadAction<ErrorWrapper>) => {
      state.listLoading = false;
    },
    addTemplateStart: (state) => {
      state.createLoading = true;
    },
    addTemplateSuccess: (state) => {
      state.createLoading = false;
    },
    addTemplateError: (state, _action: PayloadAction<ErrorWrapper>) => {
      state.createLoading = false;
    },
    editTemplateStart: (state) => {
      state.editLoading = true;
    },
    editTemplateSuccess: (state) => {
      state.editLoading = false;
    },
    editTemplateError: (state, _action: PayloadAction<ErrorWrapper>) => {
      state.editLoading = false;
    },
    getTemplateStart: (state) => {
      state.getLoading = true;
    },
    getTemplateSuccess: (state, action: PayloadAction<TemplateGetResult>) => {
      if (action.payload.forShortcut) {
        state.shortcutItem = action.payload.template;
      } else {
        state.item = action.payload.template;
      }
      state.getLoading = false;
      state.error = undefined;
    },
    getTemplateError: (state, action: PayloadAction<ErrorWrapper>) => {
      state.getLoading = false;
      state.error = action.payload;
    },
    deleteTemplateStart: (state) => {
      state.deleteLoading = true;
    },
    deleteTemplateSuccess: (state) => {
      state.deleteLoading = false;
      state.deleteFinished = true;
    },
    deleteTemplateError: (state, _action: PayloadAction<ErrorWrapper>) => {
      state.deleteLoading = false;
    },
    clearTemplate: (state) => {
      // Reset everything that was specific to viewing one Template
      state.item = undefined;
      state.shortcutItem = undefined;
      state.deleteFinished = false;
      // state.savedForm = undefined;
    },
    setSavedForm: (state, action: PayloadAction<TemplateSavedForm>) => {
      state.savedForm = action.payload;
    },
    clearItems: (state) => {
      state.items = { items: [], hasRight: false, hasLeft: false };
    },
    getMessageStatsStart: (state) => {
      state.messageStatsLoading = true;
    },
    getMessageStatsSuccess: (state, action: PayloadAction<MessageStats>) => {
      state.messageStatsLoading = false;
      state.messageStats = action.payload;
    },
    getMessageStatsFailure: (state) => {
      state.messageStatsLoading = false;
    },
  },
});

export const {
  listTemplatesStart,
  listTemplatesSuccess,
  listTemplatesError,
  addTemplateStart,
  addTemplateSuccess,
  addTemplateError,
  editTemplateStart,
  editTemplateSuccess,
  editTemplateError,
  getTemplateStart,
  getTemplateSuccess,
  getTemplateError,
  deleteTemplateStart,
  deleteTemplateSuccess,
  deleteTemplateError,
  clearTemplate,
  setSavedForm,
  clearItems,
  getMessageStatsStart,
  getMessageStatsSuccess,
  getMessageStatsFailure,
} = slice.actions;

export default slice.reducer;
