import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  Paginated,
  SurveyMeoSites,
  SurveyMeoSitesCsvImportResult,
} from "api/sms-api";
import { CRUDState, initialCRUDState } from "store/CRUDStore";
import { ErrorWrapper } from "errors/errors";

export interface SurveyMeoSitesState extends CRUDState<SurveyMeoSites> {
  selectedSurveyMeoSites: Array<{ id: string }>;
  multipleDeleteLoading: boolean;
  multipleDeleteFinished: boolean;
  importResult?: SurveyMeoSitesCsvImportResult;
  csvImporting: boolean;
  csvExporting: boolean;
  editReviewLinkLoading: boolean;
}

const initialState: SurveyMeoSitesState = {
  ...initialCRUDState,
  selectedSurveyMeoSites: [],
  multipleDeleteLoading: false,
  multipleDeleteFinished: false,
  csvImporting: false,
  csvExporting: false,
  editReviewLinkLoading: false,
};

export const slice = createSlice({
  name: "surveyMeoSites",
  initialState,
  reducers: {
    getSurveyMeoSitesListStart: (state) => {
      state.listLoading = true;
    },
    getSurveyMeoSitesListSuccess: (
      state,
      action: PayloadAction<Paginated<SurveyMeoSites>>
    ) => {
      state.items = action.payload;
      state.listLoading = false;
    },
    getSurveyMeoSitesListError: (state) => {
      state.listLoading = false;
    },
    clearItems: (state) => {
      state.items = { items: [], hasRight: false, hasLeft: false };
    },
    deleteSurveyMeoSitesListStart: (state) => {
      state.multipleDeleteLoading = true;
    },
    deleteSurveyMeoSitesListSuccess: (state) => {
      state.multipleDeleteLoading = false;
      state.multipleDeleteFinished = true;
    },
    deleteSurveyMeoSitesListError: (
      state,
      _action: PayloadAction<ErrorWrapper>
    ) => {
      state.multipleDeleteLoading = false;
    },
    editReviewLinkStart: (state) => {
      state.editReviewLinkLoading = true;
    },
    editReviewLinkSuccess: (state) => {
      state.editReviewLinkLoading = false;
    },
    editReviewLinkError: (state, action: PayloadAction<ErrorWrapper>) => {
      state.editReviewLinkLoading = false;
      state.error = action.payload;
    },
    setSelectedSurveyMeoSites: (
      state,
      action: PayloadAction<Array<{ id: string }>>
    ) => {
      state.selectedSurveyMeoSites = action.payload;
    },
    clearSelectedSurveyMeoSites: (state) => {
      state.selectedSurveyMeoSites = [];
    },
    exportStart: (state) => {
      state.csvExporting = true;
    },
    exportSuccess: (state) => {
      state.csvExporting = false;
    },
    exportError: (state) => {
      state.csvExporting = false;
    },
    importStart: (state) => {
      state.csvImporting = true;
    },
    importSuccess: (
      state,
      action: PayloadAction<SurveyMeoSitesCsvImportResult>
    ) => {
      state.csvImporting = false;
      state.importResult = action.payload;
    },
    importError: (state) => {
      state.csvImporting = false;
    },
    clearImport: (state) => {
      state.importResult = undefined;
    },
  },
});

export const {
  getSurveyMeoSitesListStart,
  getSurveyMeoSitesListSuccess,
  getSurveyMeoSitesListError,
  clearItems,
  deleteSurveyMeoSitesListStart,
  deleteSurveyMeoSitesListSuccess,
  deleteSurveyMeoSitesListError,
  editReviewLinkStart,
  editReviewLinkSuccess,
  editReviewLinkError,
  setSelectedSurveyMeoSites,
  clearSelectedSurveyMeoSites,
  clearImport,
  exportStart,
  exportSuccess,
  exportError,
  importStart,
  importSuccess,
  importError,
} = slice.actions;

export default slice.reducer;
