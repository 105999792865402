import React from "react";
import { connect } from "react-redux";
import { FullScheduledWithParts } from "api/sms-api";
import { getScheduled } from "./ScheduleActions";
import { RootState } from "store/store";
import { Page, setPage } from "features/appstate/AppStateSlice";
import { Spin } from "antd";
import { CustomError, ErrorWrapper } from "errors/errors";
import NotFound from "components/NotFound";
import GroupBlock from "components/GroupBlock";
import { useParams } from "react-router-dom";
import BackButton from "components/BackButton";

interface ScheduledGroupsProps {
  getLoading: boolean;
  item?: FullScheduledWithParts;
  error?: ErrorWrapper;
  getScheduled: (id: string) => void;
  setPage: (page: Page) => void;
}

const ScheduledGroups: React.FC<ScheduledGroupsProps> = (props) => {
  const { id } = useParams() as any;

  React.useEffect(() => {
    props.setPage(Page.scheduled);
  }, [props.setPage]);

  React.useEffect(() => {
    props.getScheduled(id);
  }, [props.getScheduled, id]);

  if (props.error?.error === CustomError.NotFound) {
    return <NotFound />;
  }

  if (props.getLoading || !props.item) {
    return <Spin />;
  }

  return (
    <div>
      <GroupBlock groups={props.item.fullScheduled.groups} />
      <BackButton />
    </div>
  );
};

const mapStateToProps = (root: RootState) => {
  return {
    item: root.scheduleds.item,
    getLoading: root.scheduleds.getLoading,
    error: root.scheduleds.error,
  };
};
const mapDispatchToProps = {
  getScheduled,
  setPage,
};

export default connect(mapStateToProps, mapDispatchToProps)(ScheduledGroups);
