import React from "react";
import { Page, setPage } from "features/appstate/AppStateSlice";
import {
  StatisticsResponse,
  MEOSite,
  DailyStatisticsForm,
  StatisticsDataPoint,
  MonthlyStatisticsForm,
  LandingPageSettings,
  Paginated,
  GMBStatisticsResponse,
} from "api/sms-api";
import { RootState } from "store/store";
import {
  getDailyStatistics,
  getMonthlyStatistics,
  getGMBDailyStatistics,
  getGMBMonthlyStatistics,
} from "./StatisticsActions";
import { clearStatistics } from "./StatisticsSlice";
import { connect } from "react-redux";
import moment from "moment";
import { Table, DatePicker, Radio, Row, Col, Select, Spin } from "antd";
import i18n from "i18n";
import qs from "qs";
import { pushTo } from "utils/navigation";
import { useHistory, useLocation } from "react-router-dom";
import { RadioChangeEvent } from "antd/lib/radio";
import locale from "antd/es/date-picker/locale/ja_JP";
import "moment/locale/ja";
import StatisticsChart, { SeriesData } from "./StatisticsChart";
import _ from "lodash";
import { listLandingPageSettings } from "../landing_page_settings/LandingPageSettingsActions";
import SMSLink from "components/SMSLink";

const { Option } = Select;

interface StatisticsProps {
  response?: StatisticsResponse;
  gmbResponse?: GMBStatisticsResponse;
  loading: boolean;
  gmbLoading: boolean;
  meoSite: MEOSite;
  landingPageSettings: Paginated<LandingPageSettings>;
  lpsLoading: boolean;

  listLandingPageSettings: (meoSiteId: number) => void;
  getDailyStatistics: (form: DailyStatisticsForm) => void;
  getMonthlyStatistics: (form: MonthlyStatisticsForm) => void;
  getGMBDailyStatistics: (form: DailyStatisticsForm) => void;
  getGMBMonthlyStatistics: (form: MonthlyStatisticsForm) => void;
  setPage: (page: Page) => void;
  clearStatistics: () => void;
}

const Statistics: React.FC<StatisticsProps> = (props) => {
  const history = useHistory();
  const location = useLocation();

  const [year, setYear] = React.useState<number | null>(null);
  const [month, setMonth] = React.useState<number | null>(null);
  const [formType, setFormType] = React.useState<string | null>(null);
  const [lpsId, setLpsId] = React.useState<string | null>(null);

  React.useEffect(() => {
    props.setPage(Page.statistics);
  }, [props.setPage]);

  React.useEffect(() => {
    return () => {
      props.clearStatistics();
    };
  }, [props.clearStatistics]);

  const getForm = () => {
    const parsed = qs.parse(location.search, { ignoreQueryPrefix: true });
    const queryYear = parseInt(parsed["year"] as string, 10);
    const queryMonth = parseInt(parsed["month"] as string, 10);
    const queryType = parsed["type"] as string;
    const lpsId = parsed["lps_id"] as string;

    const validForm =
      (queryType === "daily" &&
        _.isFinite(queryYear) &&
        _.isFinite(queryMonth)) ||
      (queryType === "monthly" && _.isFinite(queryYear));
    if (validForm) {
      setYear(queryYear);
      setMonth(queryMonth);
      setFormType(queryType);
      setLpsId(lpsId);

      return {
        type: queryType,
        year: queryYear,
        month: queryMonth,
        meoSiteId: props.meoSite.id,
        landingPageSettingsId: lpsId,
      };
    } else {
      return null;
    }
  };

  React.useEffect(() => {
    // Only fetch once, at the first time they come to this screen. Otherwise
    // we'll have to fetch the landing pages every time they change a filter.
    if (props.landingPageSettings.items.length === 0) {
      props.listLandingPageSettings(props.meoSite.id);
    }
  }, [
    props.landingPageSettings,
    props.listLandingPageSettings,
    props.meoSite,
    location.search,
  ]);

  React.useEffect(() => {
    const updatedForm = getForm();

    if (updatedForm) {
      if (updatedForm.type === "daily") {
        const form = {
          meoSiteId: updatedForm.meoSiteId,
          landingPageSettingsId: updatedForm.landingPageSettingsId,
          year: updatedForm.year,
          month: updatedForm.month!,
        };
        props.getDailyStatistics(form);
        props.getGMBDailyStatistics(form);
      } else {
        const form = {
          landingPageSettingsId: updatedForm.landingPageSettingsId,
          meoSiteId: updatedForm.meoSiteId,
          year: updatedForm.year,
        };
        props.getMonthlyStatistics(form);
        props.getGMBMonthlyStatistics(form);
      }
    } else {
      // Push to the default form
      const now = moment();

      pushTo(
        history,
        null,
        {
          year: now.year(),
          month: now.month() + 1,
          type: "daily",
        },
        ["lps_id"],
        true
      );
    }
  }, [
    props.getDailyStatistics,
    props.getGMBDailyStatistics,
    props.getMonthlyStatistics,
    props.getGMBMonthlyStatistics,
    location,
    history,
  ]);

  let timeTitle = "";
  if (formType === "daily") {
    timeTitle = i18n.t("statistics.dailyColumnTitle", {
      year: year,
      month: month,
    });
  } else if (formType === "monthly") {
    timeTitle = i18n.t("statistics.monthlyColumnTitle", {
      year: year,
    });
  }

  const percent = (n: number) => `${(n * 100).toFixed(2)}%`;

  const linkIfPresent = (
    n: number,
    startName: string,
    endName: string,
    formType: string | null,
    otherQueryParams: Record<string, unknown>,
    time?: string
  ) => {
    if (time && n > 0) {
      const start = moment(time).toISOString();

      const end =
        formType === "daily"
          ? moment(time).add(1, "days").toISOString()
          : moment(time).add(1, "months").toISOString();
      return (
        <SMSLink
          to={`/${Page.addresses}`}
          queryParams={{
            [startName]: start,
            [endName]: end,
            details: formType,
            ...otherQueryParams,
          }}
        >
          {n}
        </SMSLink>
      );
    } else {
      return n;
    }
  };
  const columns = () => {
    const baseColumns = [
      {
        title: timeTitle,
        dataIndex: "time",
        render: (time: string | null) =>
          time ? time : i18n.t("statistics.total"),
        fixed: "left",
      } as any,
      {
        title: i18n.t("statistics.cloudMessagesSent"),
        dataIndex: "cloudMessagesSent",
      },
      {
        title: i18n.t("statistics.urlViews"),
        dataIndex: "urlViews",
        render: (urlViews: number, record: StatisticsDataPoint) => {
          return linkIfPresent(
            urlViews,
            "url_view_start",
            "url_view_end",
            formType,
            {},
            record.time
          );
        },
      },
      {
        title: i18n.t("statistics.ctr"),
        dataIndex: "urlCtr",
        render: (ctr: number) => percent(ctr),
      },
      {
        title: i18n.t("statistics.landingPageViews"),
        dataIndex: "landingPageViews",
        render: (landingPageViews: number, record: StatisticsDataPoint) => {
          return linkIfPresent(
            landingPageViews,
            "landing_page_view_start",
            "landing_page_view_end",
            formType,
            {},
            record.time
          );
        },
      },
    ];

    // Get the ReviewLink columns from the first data point -- they should be the same for
    // every data point, so it doesn't matter which one we get it from
    if (props.response && props.response.dataPoints.length > 0) {
      props.response.dataPoints[0].reviewLinks.forEach((link, i) => {
        baseColumns.push({
          title: i18n.t("statistics.reviewLinkClicks", {
            name: _.truncate(link.name, { length: 12 }),
          }),
          key: `reviewLinkClicks_${i}`,
          render: (_: any, record: StatisticsDataPoint) => {
            const clicks = record.reviewLinks[i].clicks;
            return linkIfPresent(
              clicks,
              "review_link_click_start",
              "review_link_click_end",
              formType,
              { review_link_name: record.reviewLinks[i].name },
              record.time
            );
          },
        } as any);

        baseColumns.push({
          title: i18n.t("statistics.ctr"),
          key: `reviewLinkCtr_${i}`,
          render: (_: any, record: StatisticsDataPoint) => {
            return percent(record.reviewLinks[i].ctr);
          },
        } as any);
      });
    }

    baseColumns.push({
      title: i18n.t("statistics.reviewLinkClicksTotal"),
      key: "reviewLinkTotalClicks",
      render: (_: any, record: StatisticsDataPoint) => {
        return linkIfPresent(
          record.reviewLinksTotal.clicks,
          "review_link_click_start",
          "review_link_click_end",
          formType,
          {},
          record.time
        );
      },
    } as any);

    baseColumns.push({
      title: i18n.t("statistics.ctr"),
      key: "reviewLinkTotalCtr",
      render: (_: any, record: StatisticsDataPoint) => {
        return percent(record.reviewLinksTotal.ctr);
      },
    } as any);

    // baseColumns.push({
    //   title: i18n.t("statistics.gmbReviews"),
    //   render: (_: any, record: StatisticsDataPoint) => {
    //     if (props.gmbLoading) {
    //       return (
    //         <span style={{ fontStyle: "italic" }}>
    //           {i18n.t("common.loading")}
    //         </span>
    //       );
    //     } else if (props.gmbResponse) {
    //       const match = props.gmbResponse.dataPoints.find(
    //         (gmbPoint) => gmbPoint.time === record.time
    //       );
    //       return match?.reviews || 0;
    //     } else {
    //       return null;
    //     }
    //   },
    // } as any);

    // baseColumns.push({
    //   title: i18n.t("statistics.gmbPercent"),
    //   render: (_: any, record: StatisticsDataPoint) => {
    //     if (props.gmbLoading) {
    //       return (
    //         <span style={{ fontStyle: "italic" }}>
    //           {i18n.t("common.loading")}
    //         </span>
    //       );
    //     } else if (props.gmbResponse) {
    //       const match = props.gmbResponse.dataPoints.find(
    //         (gmbPoint) => gmbPoint.time === record.time
    //       );
    //       if (match && record.cloudMessagesSent !== 0) {
    //         return percent(match.reviews / record.cloudMessagesSent);
    //       } else {
    //         return 0;
    //       }
    //     } else {
    //       return null;
    //     }
    //   },
    // } as any);

    return baseColumns;
  };

  const handleMonthChange = (date: moment.Moment | null) => {
    if (date) {
      pushTo(history, null, { year: date.year(), month: date.month() + 1 }, [
        "type",
      ]);
    }
  };

  const handleYearChange = (date: moment.Moment | null) => {
    if (date) {
      pushTo(history, null, { year: date.year() }, ["type", "lps_id"]);
    }
  };

  const handleTypeChange = (e: RadioChangeEvent) => {
    if (e.target.value) {
      pushTo(history, null, { type: e.target.value }, ["year", "lps_id"]);
    }
  };

  const handleLpsChange = (value: string) => {
    pushTo(history, null, { lps_id: value === "all" ? undefined : value }, [
      "type",
      "month",
      "year",
    ]);
  };

  const selectedTime = moment();

  if (month) {
    selectedTime.month(month - 1);
  }
  if (year) {
    selectedTime.year(year);
  }

  // All the data points except the "final/total" one, since that one doesn't go in the chart
  const chartPoints = props.response?.dataPoints.filter((p) => p.time) || [];
  const gmbChartPoints =
    props.gmbResponse?.dataPoints.filter((p) => p.time) || [];

  const xAxisLabels = chartPoints.map((p) => p.time!);

  // Data for the left/top chart
  const leftBarSeriesData = {
    name: i18n.t("statistics.cloudMessagesSent"),
    data: chartPoints.map((p) => p.cloudMessagesSent),
  };

  const leftLineSeriesData = [
    {
      name: i18n.t("statistics.urlViews"),
      data: chartPoints.map((p) => p.urlViews),
    },
  ];

  // Data for the right/bottom chart
  const rightBarSeriesData = {
    name: i18n.t("statistics.landingPageViews"),
    data: chartPoints.map((p) => p.landingPageViews),
  };

  // One series for each reviewLink site (Google, Tabelog, etc), plus one
  // series for the total
  const rightLineSeriesData: Array<SeriesData> = [];

  chartPoints.forEach((point, pointIndex) => {
    point.reviewLinks.forEach((reviewLink, reviewLinkIndex) => {
      if (!rightLineSeriesData[reviewLinkIndex]) {
        // For the first time we see each ReviewLink (should be on the first data point),
        // start a new empty series
        rightLineSeriesData.push({
          name: reviewLink.name,
          data: [],
        });
      }

      rightLineSeriesData[reviewLinkIndex].data.push(reviewLink.clicks);
    });

    // Final series is for the total, create it on the first chart point
    if (pointIndex === 0) {
      rightLineSeriesData.push({
        name: i18n.t("statistics.reviewLinkClicksTotal"),
        data: [],
      });
    }

    rightLineSeriesData[rightLineSeriesData.length - 1].data.push(
      point.reviewLinksTotal.clicks
    );
  });

  if (!props.gmbLoading) {
    // This series should always be present, keep it at the last index
    rightLineSeriesData.push({
      name: i18n.t("statistics.gmbReviews"),
      data: [],
    });

    gmbChartPoints.forEach((point) => {
      rightLineSeriesData[rightLineSeriesData.length - 1].data.push(
        point.reviews
      );
    });
  }

  const monthPicker = (
    <DatePicker
      allowClear={false}
      onChange={handleMonthChange}
      picker="month"
      locale={locale as any}
      value={selectedTime}
      style={{ marginLeft: "1em" }}
    />
  );

  const yearPicker = (
    <DatePicker
      allowClear={false}
      onChange={handleYearChange}
      picker="year"
      locale={locale as any}
      value={selectedTime}
      style={{ marginLeft: "1em" }}
    />
  );

  const lpsPicker = props.lpsLoading ? (
    <Spin size="small" style={{ marginLeft: "1em" }} />
  ) : (
    <Select
      value={lpsId ? lpsId : "all"}
      onChange={handleLpsChange}
      className="landing-page-select"
    >
      <Option value="all">{i18n.t("statistics.landingPageAll")}</Option>
      {props.landingPageSettings.items.map((lps) => (
        <Option key={lps.id} value={lps.id}>
          {lps.name}
        </Option>
      ))}
    </Select>
  );

  return (
    <div className="statistics">
      <Row style={{ marginBottom: "1em" }}>
        <Radio.Group onChange={handleTypeChange} value={formType}>
          <Radio.Button value="daily">
            {i18n.t("statistics.daily")}
          </Radio.Button>
          <Radio.Button value="monthly">
            {i18n.t("statistics.monthly")}
          </Radio.Button>
        </Radio.Group>
        {formType === "daily" ? monthPicker : yearPicker}
        {lpsPicker}
      </Row>
      <Row style={{ marginBottom: "4em" }}>
        <Table
          columns={columns()}
          dataSource={props.response?.dataPoints || []}
          rowKey="time"
          pagination={false}
          size="small"
          scroll={{ y: 700, x: 2000 }}
          loading={props.loading}
        />
      </Row>
      <Row>
        <Col xs={24} xxl={12}>
          <StatisticsChart
            xAxisLabels={xAxisLabels}
            barSeriesData={leftBarSeriesData}
            lineSeriesData={leftLineSeriesData}
            loading={props.loading}
          />
        </Col>
        <Col xs={24} xxl={12}>
          <StatisticsChart
            xAxisLabels={xAxisLabels}
            barSeriesData={rightBarSeriesData}
            lineSeriesData={rightLineSeriesData}
            loading={props.loading}
          />
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    response: state.statistics.response,
    gmbResponse: state.statistics.gmbResponse,
    loading: state.statistics.loading,
    gmbLoading: state.statistics.gmbLoading,
    lpsLoading: state.landingPageSettings.listLoading,
    meoSite: state.seo.meoSite!,
    landingPageSettings: state.landingPageSettings.items,
  };
};

const mapDispatchToProps = {
  setPage,
  getDailyStatistics,
  getMonthlyStatistics,
  getGMBDailyStatistics,
  getGMBMonthlyStatistics,
  listLandingPageSettings,
  clearStatistics,
};

export default connect(mapStateToProps, mapDispatchToProps)(Statistics);
