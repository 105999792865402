import React from "react";
import i18n from "i18n";
import { useHistory } from "react-router-dom";
import { Button } from "antd";
import { RollbackOutlined } from "@ant-design/icons";

interface BackButtonProps {
  loading?: boolean;
  disabled?: boolean;
}

const BackButton: React.FC<BackButtonProps> = (props) => {
  const history = useHistory();

  const handleClick = () => {
    history.goBack();
  };
  return (
    <Button
      icon={<RollbackOutlined />}
      disabled={props.disabled}
      loading={props.loading}
      onClick={handleClick}
    >
      {i18n.t("common.back")}
    </Button>
  );
};

export default BackButton;
