import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  Paginated,
  SurveyProducts,
  SurveyProductsCsvImportResult,
} from "api/sms-api";
import { CRUDState, initialCRUDState } from "store/CRUDStore";
import { ErrorWrapper } from "errors/errors";

export interface SurveyProductsState extends CRUDState<SurveyProducts> {
  selectedSurveyProducts: Array<{ id: string }>;
  multipleDeleteLoading: boolean;
  multipleDeleteFinished: boolean;
  importResult?: SurveyProductsCsvImportResult;
  csvImporting: boolean;
  csvExporting: boolean;
  editReviewLinkLoading: boolean;
}

const initialState: SurveyProductsState = {
  ...initialCRUDState,
  selectedSurveyProducts: [],
  multipleDeleteLoading: false,
  multipleDeleteFinished: false,
  csvImporting: false,
  csvExporting: false,
  editReviewLinkLoading: false,
};

export const slice = createSlice({
  name: "surveyProducts",
  initialState,
  reducers: {
    getSurveyProductsListStart: (state) => {
      state.listLoading = true;
    },
    getSurveyProductsListSuccess: (
      state,
      action: PayloadAction<Paginated<SurveyProducts>>
    ) => {
      state.items = action.payload;
      state.listLoading = false;
    },
    getSurveyProductsListError: (state) => {
      state.listLoading = false;
    },
    clearItems: (state) => {
      state.items = { items: [], hasRight: false, hasLeft: false };
    },
    deleteSurveyProductsListStart: (state) => {
      state.multipleDeleteLoading = true;
    },
    deleteSurveyProductsListSuccess: (state) => {
      state.multipleDeleteLoading = false;
      state.multipleDeleteFinished = true;
    },
    deleteSurveyProductsListError: (
      state,
      _action: PayloadAction<ErrorWrapper>
    ) => {
      state.multipleDeleteLoading = false;
    },
    editReviewLinkStart: (state) => {
      state.editReviewLinkLoading = true;
    },
    editReviewLinkSuccess: (state) => {
      state.editReviewLinkLoading = false;
    },
    editReviewLinkError: (state, action: PayloadAction<ErrorWrapper>) => {
      state.editReviewLinkLoading = false;
      state.error = action.payload;
    },
    setSelectedSurveyProducts: (
      state,
      action: PayloadAction<Array<{ id: string }>>
    ) => {
      state.selectedSurveyProducts = action.payload;
    },
    clearSelectedSurveyProducts: (state) => {
      state.selectedSurveyProducts = [];
    },
    exportStart: (state) => {
      state.csvExporting = true;
    },
    exportSuccess: (state) => {
      state.csvExporting = false;
    },
    exportError: (state) => {
      state.csvExporting = false;
    },
    importStart: (state) => {
      state.csvImporting = true;
    },
    importSuccess: (
      state,
      action: PayloadAction<SurveyProductsCsvImportResult>
    ) => {
      state.csvImporting = false;
      state.importResult = action.payload;
    },
    importError: (state) => {
      state.csvImporting = false;
    },
    clearImport: (state) => {
      state.importResult = undefined;
    },
  },
});

export const {
  getSurveyProductsListStart,
  getSurveyProductsListSuccess,
  getSurveyProductsListError,
  clearItems,
  deleteSurveyProductsListStart,
  deleteSurveyProductsListSuccess,
  deleteSurveyProductsListError,
  editReviewLinkStart,
  editReviewLinkSuccess,
  editReviewLinkError,
  setSelectedSurveyProducts,
  clearSelectedSurveyProducts,
  clearImport,
  exportStart,
  exportSuccess,
  exportError,
  importStart,
  importSuccess,
  importError,
} = slice.actions;

export default slice.reducer;
