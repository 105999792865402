import React from "react";
import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import i18n from "i18n";
import { NativeButtonProps } from "antd/lib/button/button";

const CreateButton: React.FC<NativeButtonProps> = (props) => {
  return (
    <Button icon={<PlusOutlined />} htmlType="submit" type="primary" {...props}>
      {i18n.t("common.create")}
    </Button>
  );
};

export default CreateButton;
