import React, { useEffect } from "react";
import { RootState } from "store/store";
import { connect } from "react-redux";
import { Modal } from "antd";
import i18n from "i18n";
import { Form, Input } from "antd";
import {
  MailTemplateSenderAddForm,
  MailTemplateSenderUpdateForm,
  MailTemplateSender,
} from "api/sms-api";
import {
  addMailTemplateSender,
  editMailTemplateSender,
} from "./MailTemplateSenderActions";
import { emailIsInvalid } from "utils/strings";

interface MailTemplateSenderAddDialogProps {
  item?: MailTemplateSender;
  visible: boolean;
  existingParams: any;
  closeModal: () => void;

  addMailTemplateSender: (
    form: MailTemplateSenderAddForm,
    existingParams: any
  ) => void;

  editMailTemplateSender: (
    id: string,
    form: MailTemplateSenderUpdateForm
  ) => void;
}

const MailTemplateSenderAddDialog: React.FC<MailTemplateSenderAddDialogProps> = (
  props
) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (props.item) {
      form.setFieldsValue({
        name: props.item.name,
        senderEmail: props.item.senderEmail,
        replyEmail: props.item.replyEmail,
      });
    }
  }, [props.item]);

  const onClickAddButton = (value: {
    name: string;
    senderEmail: string;
    replyEmail: string;
  }) => {
    if (props.item) {
      props.editMailTemplateSender(props.item.id, value);
    } else {
      props.addMailTemplateSender(value, props.existingParams);
    }
    form.resetFields();
    props.closeModal();
  };

  const onClickCancelButton = () => {
    form.resetFields();
    props.closeModal();
  };

  const titleKey = props.item ? "edit" : "add";

  return (
    <Modal
      visible={props.visible}
      title={i18n.t(`mailTemplateSender.table.settings.${titleKey}`)}
      okText={i18n.t("common.save")}
      cancelText={i18n.t("common.cancel")}
      onCancel={onClickCancelButton}
      onOk={() => {
        form.validateFields().then((values) => {
          onClickAddButton(values);
        });
      }}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          name="name"
          label={i18n.t("mailTemplateSender.table.title.name")}
          className="input-mail-form"
          validateTrigger={["onBlur"]}
          rules={[
            {
              validator(_, value) {
                if (value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(i18n.t("validation.required")));
              },
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="senderEmail"
          label={i18n.t("mailTemplateSender.table.title.senderEmail")}
          className="input-mail-form"
          validateTrigger={["onBlur"]}
          rules={[
            {
              validator(_, value) {
                if (value && !emailIsInvalid([value])) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(i18n.t("validation.mail")));
              },
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="replyEmail"
          label={i18n.t("mailTemplateSender.table.title.replyEmail")}
          className="input-mail-form"
          validateTrigger={["onBlur"]}
          rules={[
            {
              validator(_, value) {
                if (value && !emailIsInvalid([value])) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(i18n.t("validation.mail")));
              },
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

const mapStateToProps = (state: RootState) => {
  return state;
};

const mapDispatchToProps = {
  addMailTemplateSender,
  editMailTemplateSender,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MailTemplateSenderAddDialog);
