import {
  Paginated,
  PaginationParams,
  OrderSurveyEmailWithOrder,
} from "api/sms-api";
import { AppDispatch } from "store/store";
import { httpWrapper } from "utils/http";
import {
  getOrderSurveyEmailsStart,
  getOrderSurveyEmailsSuccess,
  getOrderSurveyEmailsError,
} from "./OrderSurveyEmailsSlice";
import { SMS_API_BASE } from "../../constants";
import { handleError } from "errors/errors";

export const getOrderSurveyEmails = (param: PaginationParams) => async (
  dispatch: AppDispatch
) => {
  dispatch(getOrderSurveyEmailsStart());
  try {
    const orders = await httpWrapper<Paginated<OrderSurveyEmailWithOrder>>(
      "GET",
      `${SMS_API_BASE}/order_survey_emails/list`,
      param
    );
    dispatch(getOrderSurveyEmailsSuccess(orders));
  } catch (e) {
    handleError(e);
    dispatch(getOrderSurveyEmailsError());
  }
};
