import React from "react";
import i18n from "i18n";
import { useHistory, useLocation } from "react-router-dom";
import { pushTo } from "utils/navigation";
import { MODE_SURVEY, surveyTypeStore } from "../constants";
import qs from "qs";

interface BackArrowButtonProps {
  url?: string;
}

const BackArrowButton: React.FC<BackArrowButtonProps> = (props) => {
  const history = useHistory();
  const location = useLocation();
  const parsed = qs.parse(location.search, { ignoreQueryPrefix: true });

  const handleClick = () => {
    if (props.url) {
      pushTo(
        history,
        props.url,
        {
          mode: MODE_SURVEY,
          surveyType: parsed.surveyType || surveyTypeStore,
        },
        [],
        true
      );
    } else {
      history.goBack();
    }
  };

  return (
    <div className="pentagon-button-wrapper">
      <button className="pentagon-button" onClick={handleClick}>
        <span>{i18n.t("common.back")}</span>
      </button>
    </div>
  );
};

export default BackArrowButton;
