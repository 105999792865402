import React from "react";
import { Form, Input } from "formik-antd";
import { Formik } from "formik";
import { SearchGroupParams } from "api/sms-api";
import { Button, Row, Col } from "antd";
import i18n from "i18n";
import { pushTo } from "utils/navigation";
import { useHistory } from "react-router-dom";

interface GroupFiltersProps {}

const GroupFilters: React.FC<GroupFiltersProps> = () => {
  const history = useHistory();

  const initialValues: SearchGroupParams = {
    query: "",
  };

  const handleSubmit = (form: SearchGroupParams) => {
    // Make sure to preserve the selection mode parameters so searching while
    // in selection mode doesn't break
    pushTo(history, null, form, ["selecting", "backUrl", "scheduledId"]);
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {(_formikBag) => {
        return (
          <Form
            className="max-width-on-mobile"
            style={{ marginBottom: "0.5em" }}
          >
            <Row>
              <Col xs={20} md={12} lg={8} style={{ paddingRight: "1em" }}>
                <Form.Item name="query">
                  <Input name="query" />
                </Form.Item>
              </Col>
              <Col xs={4} md={6} lg={4}>
                <Button htmlType="submit">{i18n.t("common.search")}</Button>
              </Col>
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
};

export default GroupFilters;
