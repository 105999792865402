import React from "react";

import { ActivityGroup, ActivityType } from "api/sms-api";
import ActivityTag from "./ActivityTag";
import { has, keys } from "lodash";
import { RightOutlined } from "@ant-design/icons";

interface ActivityGroupProps {
  activityGroup: ActivityGroup;
}

const ActivityGroupDisplay: React.FC<ActivityGroupProps> = (props) => {
  const urlViewCount = props.activityGroup.activities.filter(
    (a) => a.activityType === ActivityType.UrlView
  ).length;
  const landingPageViewCount = props.activityGroup.activities.filter(
    (a) => a.activityType === ActivityType.LandingPageView
  ).length;

  const urlViewsTag =
    urlViewCount > 0 ? (
      <ActivityTag activityType={ActivityType.UrlView} times={urlViewCount} />
    ) : null;
  const landingPageViewsTag =
    landingPageViewCount > 0 ? (
      <ActivityTag
        activityType={ActivityType.LandingPageView}
        times={landingPageViewCount}
      />
    ) : null;

  // Determine how many times each
  const nameToCountMap: Record<string, number> = {};

  props.activityGroup.activities
    .filter((a) => a.activityType === ActivityType.ReviewLinkClick)
    .forEach((a) => {
      const name = a.reviewLinkName!;
      if (!has(nameToCountMap, name)) {
        nameToCountMap[name] = 0;
      }
      nameToCountMap[name] += 1;
    });

  const reviewLinkClickTags = keys(nameToCountMap).map((name) => {
    const count = nameToCountMap[name];
    return (
      <ActivityTag
        key={name}
        activityType={ActivityType.ReviewLinkClick}
        times={count}
        reviewLinkName={name}
      />
    );
  });

  const arrow = (
    <RightOutlined style={{ fontSize: "1.3em", color: "#999999" }} />
  );
  return (
    <div className="activity-group">
      <div className="url-side">
        {urlViewsTag}
        {landingPageViewsTag && arrow}
        {landingPageViewsTag}
        {reviewLinkClickTags.length > 0 && arrow}
      </div>
      <div className="review-link-side">{reviewLinkClickTags}</div>
    </div>
  );
};

export default ActivityGroupDisplay;
