import { AppDispatch, AppThunk, AsyncAppThunk } from "store/store";
import { httpWrapper } from "../../utils/http";
import { SMS_API_BASE } from "../../constants";
import { handleError } from "errors/errors";
import {
  editOptoutPageTemplateStart,
  editOptoutPageTemplateSuccess,
  editOptoutPageTemplateError,
  getOptoutPageTemplateStart,
  getOptoutPageTemplateSuccess,
  getOptoutPageTemplateError,
} from "./OptoutPageTemplateSlice";
import { ClientEditOptoutPageTemplateForm, Client } from "../../api/sms-api";
import i18n from "i18n";
import { showNotification } from "utils/notifications";

export const editOptoutPageTemplate = (
  params: ClientEditOptoutPageTemplateForm
): AsyncAppThunk<void> => async (dispatch: AppDispatch) => {
  dispatch(editOptoutPageTemplateStart());
  try {
    await httpWrapper(
      "PATCH",
      `${SMS_API_BASE}/client/update_optout_page_template`,
      params
    );

    showNotification(i18n.t("mailTemplate.optoutPageTemplate.editSuccess"));
    dispatch(editOptoutPageTemplateSuccess());
  } catch (e) {
    handleError(e);
    dispatch(editOptoutPageTemplateError(e));
  }
};

export const getOptoutPageTemplate = (): AppThunk => async (
  dispatch: AppDispatch
) => {
  dispatch(getOptoutPageTemplateStart());
  try {
    const client = await httpWrapper<Client>(
      "GET",
      `${SMS_API_BASE}/client`,
      {}
    );
    dispatch(getOptoutPageTemplateSuccess(client));
  } catch (e) {
    handleError(e);
    dispatch(getOptoutPageTemplateError(e));
  }
};
