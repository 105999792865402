import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ReviewLink,
  ReviewLinkStatus,
  ReviewLinkColors,
} from "../../api/sms-api";
import { ErrorWrapper } from "errors/errors";
import {
  initialNonPaginatedCRUDState,
  NonPaginatedCRUDState,
} from "store/CRUDStore";

interface ReviewLinksState extends NonPaginatedCRUDState<ReviewLink> {
  status?: ReviewLinkStatus;
  statusLoading: boolean;
  connectLoading: boolean;
  connectError?: ErrorWrapper;
  colors?: ReviewLinkColors;
  colorsLoading: boolean;
}

export const initialState: ReviewLinksState = {
  ...initialNonPaginatedCRUDState,
  statusLoading: false,
  connectLoading: false,
  colorsLoading: false,
};

export const slice = createSlice({
  name: "review_links",
  initialState,
  reducers: {
    listReviewLinksStart: (state) => {
      state.listLoading = true;
    },
    listReviewLinksSuccess: (
      state,
      action: PayloadAction<Array<ReviewLink>>
    ) => {
      state.items = action.payload;
      state.listLoading = false;
    },
    listReviewLinksError: (state, _action: PayloadAction<ErrorWrapper>) => {
      state.listLoading = false;
    },
    addReviewLinkStart: (state) => {
      state.createLoading = true;
    },
    addReviewLinkSuccess: (state) => {
      state.createLoading = false;
    },
    addReviewLinkError: (state, _action: PayloadAction<ErrorWrapper>) => {
      state.createLoading = false;
    },
    editReviewLinkStart: (state) => {
      state.editLoading = true;
    },
    editReviewLinkSuccess: (state) => {
      state.editLoading = false;
    },
    editReviewLinkError: (state, _action: PayloadAction<ErrorWrapper>) => {
      state.editLoading = false;
    },
    getReviewLinkStart: (state) => {
      state.getLoading = true;
    },
    getReviewLinkSuccess: (state, action: PayloadAction<ReviewLink>) => {
      state.item = action.payload;
      state.getLoading = false;
    },
    getReviewLinkError: (state, action: PayloadAction<ErrorWrapper>) => {
      state.getLoading = false;
      state.error = action.payload;
    },
    connectStart: (state) => {
      state.connectLoading = true;
    },
    connectSuccess: (state) => {
      state.connectLoading = false;
    },
    connectError: (state, action: PayloadAction<ErrorWrapper>) => {
      state.connectLoading = false;
      state.connectError = action.payload;
    },
    getStatusStart: (state) => {
      state.statusLoading = true;
    },
    getStatusSuccess: (state, action: PayloadAction<ReviewLinkStatus>) => {
      state.status = action.payload;
      state.statusLoading = false;
    },
    getStatusError: (state) => {
      state.statusLoading = false;
    },
    getColorsStart: (state) => {
      state.colorsLoading = true;
    },
    getColorsSuccess: (state, action: PayloadAction<ReviewLinkColors>) => {
      state.colorsLoading = false;
      state.colors = action.payload;
    },
    getColorsError: (state) => {
      state.colorsLoading = false;
    },
    clearReviewLink: (state) => {
      // Reset everything that was specific to viewing one ReviewLink
      state.item = undefined;
      state.deleteFinished = false;
    },
    clearItems: (state) => {
      state.items = [];
    },
  },
});

export const {
  listReviewLinksStart,
  listReviewLinksSuccess,
  listReviewLinksError,
  addReviewLinkStart,
  addReviewLinkSuccess,
  addReviewLinkError,
  editReviewLinkStart,
  editReviewLinkSuccess,
  editReviewLinkError,
  getReviewLinkStart,
  getReviewLinkSuccess,
  getReviewLinkError,
  connectStart,
  connectSuccess,
  connectError,
  getStatusStart,
  getStatusSuccess,
  getStatusError,
  getColorsStart,
  getColorsSuccess,
  getColorsError,
  clearReviewLink,
  clearItems,
} = slice.actions;

export default slice.reducer;
