import React from "react";
import { Group } from "api/sms-api";
import { Table } from "antd";
import PageLocale from "./PageLocale";
import i18n from "i18n";
import SMSLink from "./SMSLink";

interface GroupBlockProps {
  groups: Array<Group>;
}

const locale = PageLocale;

const GroupBlock: React.FC<GroupBlockProps> = (props) => {
  const columns: Array<any> = [
    {
      title: i18n.t("groups.name"),
      dataIndex: "name",
      key: "name",
      render: (_: any, record: Group) => {
        return <SMSLink to={`/group/${record.id}`}>{record.name}</SMSLink>;
      },
    },
  ];

  return (
    <Table
      title={() => i18n.t("common.group")}
      dataSource={props.groups}
      rowKey={(group: Group) => group.id}
      columns={columns}
      pagination={{ locale }}
    />
  );
};

export default GroupBlock;
