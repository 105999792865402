import React from "react";
import { Form } from "formik-antd";
import RichEditor from "components/RichEditor";
import i18n from "i18n";
import { FormikProps } from "formik";
import { ReviewMediaSettingsFormProps } from "./ReviewMediaSettings";

interface CompletionMessageProps {
  completionMessageName: string;
  completionMessageValue: string;
  messageLabel: string;
  messageName: string;
  messageValue: string;
  formikBag: FormikProps<ReviewMediaSettingsFormProps>;
}

const CompletionMessage: React.FC<CompletionMessageProps> = ({
  completionMessageName,
  completionMessageValue,
  messageLabel,
  messageName,
  messageValue,
  formikBag,
}) => {
  return (
    <>
      <Form.Item
        label={i18n.t("survey.completionMessageLabel")}
        name={completionMessageName}
        className="review-media-container"
      >
        <RichEditor
          value={completionMessageValue}
          onChange={(value) => {
            formikBag.setFieldValue(completionMessageName, value);
          }}
        />
      </Form.Item>
      <Form.Item
        label={messageLabel}
        name={messageName}
        className="review-media-container"
      >
        <RichEditor
          value={messageValue}
          onChange={(value) => {
            formikBag.setFieldValue(messageName, value);
          }}
        />
      </Form.Item>
    </>
  );
};

export default CompletionMessage;
