import {
  setCanPaginateLeft,
  setCanPaginateRight,
  resetPagination,
} from "features/appstate/AppStateSlice";
import queryString from "qs";
import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Button } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { RootState } from "store/store";
import { pushTo } from "utils/navigation";

// For any table that uses pagination
export interface PaginatedComponentProps {
  canPaginateLeft: boolean;
  canPaginateRight: boolean;
}

// For the SimplePagination component itself (not exported)
interface SimplePaginationProps<A> {
  items: Array<A>;
  hasRight: boolean;
  hasLeft: boolean;
  getCursor: (model: A) => string;

  // Which way the models should be sorted; this determines whether
  // to use 'after' or 'before' in the URL when paginating
  sortDescending: boolean;

  canPaginateLeft: boolean;
  canPaginateRight: boolean;

  setCanPaginateLeft: (can: boolean) => void;
  setCanPaginateRight: (can: boolean) => void;
  resetPagination: () => void;
}

const SimplePagination: React.FC<
  SimplePaginationProps<any> & RouteComponentProps
> = (props) => {
  React.useEffect(() => {
    props.setCanPaginateLeft(props.hasLeft);
    props.setCanPaginateRight(props.hasRight);
  }, [
    props.hasLeft,
    props.hasRight,
    props.setCanPaginateLeft,
    props.setCanPaginateRight,
  ]);

  React.useEffect(() => {
    return () => {
      props.resetPagination();
    };
  }, [props.resetPagination]);

  const onLeft = () => {
    if (props.items.length > 0) {
      const firstItem = props.items[0];
      const firstCursor = props.getCursor(firstItem);
      const parsed = queryString.parse(location.search, {
        ignoreQueryPrefix: true,
      });
      if (props.sortDescending) {
        delete parsed["before"];
        parsed["after"] = firstCursor;
      } else {
        delete parsed["after"];
        parsed["before"] = firstCursor;
      }
      pushTo(props.history, null, parsed);
    }
  };

  const onRight = () => {
    if (props.items.length > 0) {
      const lastItem = props.items[props.items.length - 1];
      const lastCursor = props.getCursor(lastItem);
      const parsed = queryString.parse(location.search, {
        ignoreQueryPrefix: true,
      });
      if (props.sortDescending) {
        parsed["before"] = lastCursor;
        delete parsed["after"];
      } else {
        parsed["after"] = lastCursor;
        delete parsed["before"];
      }
      pushTo(props.history, null, parsed);
    }
  };

  return (
    <div className="simple-pagination">
      <Button.Group>
        <Button
          onClick={onLeft}
          disabled={!props.canPaginateLeft}
          className="left"
          icon={<LeftOutlined />}
        />
        <Button
          onClick={onRight}
          disabled={!props.canPaginateRight}
          className="right"
          icon={<RightOutlined />}
        />
      </Button.Group>
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    canPaginateLeft: state.app.canPaginateLeft,
    canPaginateRight: state.app.canPaginateRight,
  };
};

const mapDispatchToProps = {
  setCanPaginateLeft,
  setCanPaginateRight,
  resetPagination,
};

export default connect(mapStateToProps, mapDispatchToProps)(SimplePagination);
