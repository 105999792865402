import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { EcProvider } from "api/sms-api";
import {
  NonPaginatedCRUDState,
  initialNonPaginatedCRUDState,
} from "store/CRUDStore";

export interface EcProvidersState extends NonPaginatedCRUDState<EcProvider> {}

const initialState: EcProvidersState = {
  ...initialNonPaginatedCRUDState,
};

export const slice = createSlice({
  name: "ecProviders",
  initialState,
  reducers: {
    getEcProvidersStart: (state) => {
      state.listLoading = true;
    },
    getEcProvidersSuccess: (
      state,
      action: PayloadAction<Array<EcProvider>>
    ) => {
      state.items = action.payload;
      state.listLoading = false;
    },
    getEcProvidersError: (state) => {
      state.listLoading = false;
    },
  },
});

export const {
  getEcProvidersStart,
  getEcProvidersSuccess,
  getEcProvidersError,
} = slice.actions;

export default slice.reducer;
