import React from "react";
import { Form, Input } from "formik-antd";
import { Formik } from "formik";
import { SearchAddressParams, AddressDeleteForm, MEOSite } from "api/sms-api";
import { Button, Row, Col, Popconfirm } from "antd";
import i18n from "i18n";
import { pushTo } from "utils/navigation";
import { useHistory } from "react-router-dom";
import { DeleteOutlined } from "@ant-design/icons";
import { SelectedAddress } from "./AddressSlice";
import { connect } from "react-redux";
import { deleteAddresses } from "features/address/AddressActions";

interface AddressFiltersProps {
  selecting: string | null;
  selected: Array<SelectedAddress>;
  meoSite: MEOSite;
  deleteAddresses: (params: AddressDeleteForm) => void;
}

const AddressFilters: React.FC<AddressFiltersProps> = (props) => {
  const history = useHistory();

  const initialValues: SearchAddressParams = {
    query: "",
  };

  const handleSubmit = (form: SearchAddressParams) => {
    // Make sure to preserve the selection mode parameters so searching while
    // in selection mode doesn't break
    pushTo(history, null, form, ["selecting", "backUrl", "scheduledId"]);
  };

  const handleDelete = () => {
    const selectedIds: Array<string> = props.selected.map((s) => s.id!);
    const form: AddressDeleteForm = {
      addressIds: selectedIds,
    };
    props.deleteAddresses(form);
  };

  const deleteButton = props.selecting === null && (
    <Col xs={4} md={6} lg={4}>
      <Popconfirm
        title={i18n.t("addresses.deleteCountConfirm", {
          count: props.selected.length,
        })}
        disabled={props.selected.length < 1}
        onConfirm={handleDelete}
        okText={i18n.t("common.delete")}
        cancelText={i18n.t("common.cancel")}
        okType="danger"
      >
        <Button
          danger={props.selected.length > 0}
          disabled={props.selected.length < 1}
          icon={<DeleteOutlined />}
        >
          {i18n.t("common.delete")}
        </Button>
      </Popconfirm>
    </Col>
  );

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {(_formikBag) => {
        return (
          <Form
            className="max-width-on-mobile"
            style={{ marginBottom: "0.5em" }}
          >
            <Row>
              <Col xs={20} md={12} lg={8} style={{ paddingRight: "1em" }}>
                <Form.Item name="query">
                  <Input name="query" />
                </Form.Item>
              </Col>
              <Col xs={4} md={6} lg={4}>
                <Button htmlType="submit">{i18n.t("common.search")}</Button>
              </Col>
              {deleteButton}
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
};
const mapStateToProps = () => {
  return {};
};
const mapDispatchToProps = {
  deleteAddresses,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddressFilters);
