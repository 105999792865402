import React from "react";
import { Spin, Row } from "antd";
import { Form, Select } from "formik-antd";
import i18n from "i18n";
import CustomModal from "components/CustomModal";
import { LanguageSettings } from "api/sms-api";
import { connect } from "react-redux";
import { RootState } from "store/store";

interface SelectLanguageModalProps {
  visible: boolean;
  closeModal: () => void;
  selectLanguageId: string | null;

  languageSettings: Array<LanguageSettings>;
  getLanguageSettingsListLoading: boolean;
}

const SelectLanguageModal: React.FC<SelectLanguageModalProps> = ({
  visible,
  closeModal,
  languageSettings,
  getLanguageSettingsListLoading,
}) => {
  const options = languageSettings.map(({ name, id }) => ({
    label: name,
    value: id,
  }));

  const handleCloseModal = () => {
    closeModal();
  };

  return (
    <CustomModal
      title={i18n.t("survey.selectLanguage")}
      okText={i18n.t("common.save")}
      visible={visible}
      closeModal={handleCloseModal}
    >
      <Form layout="vertical">
        <Row className="select-language">
          {getLanguageSettingsListLoading ? (
            <Spin />
          ) : (
            <Form.Item name="languageSettingsId">
              <Select
                name="languageSettingsId"
                options={options}
                style={{ width: "150px" }}
              />
            </Form.Item>
          )}
        </Row>
      </Form>
    </CustomModal>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    languageSettings: state.survey.languageSettings,
    getLanguageSettingsListLoading: state.survey.getLanguageSettingsListLoading,
  };
};

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectLanguageModal);
