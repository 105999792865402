import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Paginated, FullHistory, FullHistoryGroup } from "../../api/sms-api";
import { ErrorWrapper } from "errors/errors";
import { initialCRUDState } from "store/CRUDStore";

interface HistoryState {
  items: Paginated<FullHistory>;
  item?: FullHistoryGroup;
  listLoading: boolean;
  getLoading: boolean;
  error?: ErrorWrapper;
}

export const initialState: HistoryState = {
  ...initialCRUDState,
};

export const slice = createSlice({
  name: "history",
  initialState,
  reducers: {
    listHistoryStart: (state) => {
      state.listLoading = true;
    },
    listHistorySuccess: (
      state,
      action: PayloadAction<Paginated<FullHistory>>
    ) => {
      state.items = action.payload;
      state.listLoading = false;
    },
    listHistoryError: (state, _action: PayloadAction<ErrorWrapper>) => {
      state.listLoading = false;
    },
    getHistoryStart: (state) => {
      state.getLoading = true;
    },
    getHistorySuccess: (state, action: PayloadAction<FullHistoryGroup>) => {
      state.item = action.payload;
      state.getLoading = false;
    },
    getHistoryError: (state, action: PayloadAction<ErrorWrapper>) => {
      state.getLoading = false;
      state.error = action.payload;
    },
    clearItems: (state) => {
      state.items = { items: [], hasRight: false, hasLeft: false };
    },
  },
});

export const {
  listHistoryStart,
  listHistorySuccess,
  listHistoryError,
  getHistoryStart,
  getHistorySuccess,
  getHistoryError,
  clearItems,
} = slice.actions;

export default slice.reducer;
