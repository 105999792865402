import * as Yup from "yup";
import { setValidationMessages } from "./i18n/validation";
import { PublishType } from "api/sms-api";
import { Timing } from "components/SendForm";
import i18n from "i18n";
import { getUtf8ByteCount, convertContent } from "utils/strings";

setValidationMessages();

// https://stackoverflow.com/a/23299989
const e164Regex = new RegExp(/^\+?[0-9]\d{1,14}$/);

export const addressAddFormSchema = Yup.object().shape({
  name: Yup.string()
    .required()
    .test(
      "max-bytes",
      i18n.t("validation.addressNameMaxLength"),
      function (message) {
        return getUtf8ByteCount(message || "") <= 24;
      }
    ),
  phoneNumber: Yup.string()
    .required()
    .matches(e164Regex, i18n.t("validation.phoneNumber")),
});

export const groupAddFormSchema = Yup.object().shape({
  name: Yup.string()
    .required()
    .test(
      "max-bytes",
      i18n.t("validation.groupNameMaxLength"),
      function (message) {
        return getUtf8ByteCount(message || "") <= 90;
      }
    ),
});

export const templateAddFormSchema = Yup.object().shape({
  name: Yup.string().required(),
  content: Yup.string().required(),
});

const alphanumeric = new RegExp(/^[a-z0-9]+$/i);
const alphanumericLowercase = new RegExp(/^[a-z0-9]+$/);
const oneLetter = new RegExp(/[a-z]+/i);

export const ClientEditFormSchema = Yup.object().shape({
  sendUserId: Yup.string()
    .max(11, i18n.t("validation.sendUserId"))
    .matches(alphanumeric, i18n.t("validation.sendUserId"))
    .matches(oneLetter, i18n.t("validation.sendUserId")),
});

const recipientArray = (type: PublishType) => {
  let base = Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string()
          .required()
          .test(
            "max-bytes",
            i18n.t("validation.addressNameMaxLength"),
            function (message) {
              return getUtf8ByteCount(message || "") <= 24;
            }
          ),
        phoneNumber: Yup.string()
          .required()
          .matches(e164Regex, i18n.t("validation.phoneNumber")),
      })
    )
    .when(["groups"], {
      is: (groups: Array<string>) => (groups || []).length < 1,
      then: Yup.array().min(1, i18n.t("validation.contactMin")),
      otherwise: Yup.array().min(0),
    });

  if (type === PublishType.Direct) {
    base = base.max(1, i18n.t("errors.onlyOneContactAllowed"));
  }

  return base;
};

const messageValidation = Yup.string()
  .required()
  .when(["type"], {
    is: (type: PublishType) => type === PublishType.Cloud,
    then: Yup.string().test(
      "max-bytes",
      i18n.t("validation.cloudPublishMaxLength"),
      function (message) {
        return getUtf8ByteCount(convertContent(message || "")) <= 1600;
      }
    ),
    otherwise: Yup.string().test(
      "max-length",
      i18n.t("validation.directPublishMaxLength"),
      function (message) {
        return convertContent(message || "").length <= 670;
      }
    ),
  });

export const modifiedPublishFormSchema = Yup.object().shape({
  contacts: Yup.mixed().when(["type"], {
    is: (type: PublishType) => type === PublishType.Cloud,
    then: recipientArray(PublishType.Cloud),
    otherwise: recipientArray(PublishType.Direct),
  }),
  groups: Yup.mixed().when(["type"], {
    is: (type: PublishType) => type === PublishType.Direct,
    then: Yup.array().max(0, i18n.t("validation.groupsMax")),
    otherwise: Yup.array().max(100, i18n.t("validation.groupsMax")),
  }),
  message: messageValidation,
  sendUserId: Yup.mixed().when(["type"], {
    is: (type: PublishType) => type === PublishType.Cloud,
    then: Yup.string()
      .required()
      .max(11, i18n.t("validation.sendUserId"))
      .matches(alphanumeric, i18n.t("validation.sendUserId"))
      .matches(oneLetter, i18n.t("validation.sendUserId")),
    otherwise: Yup.mixed(),
  }),
  scheduledTime: Yup.mixed().when(["type", "timing"], {
    is: (type: PublishType, timing: Timing) =>
      type === PublishType.Cloud && timing === Timing.Scheduled,
    then: Yup.date().required(),
    otherwise: Yup.mixed(),
  }),
});

export const modifiedScheduledEditFormSchema = Yup.object().shape({
  contacts: recipientArray(PublishType.Cloud),
  message: messageValidation,
  sendUserId: Yup.string()
    .required()
    .max(11, i18n.t("validation.sendUserId"))
    .matches(alphanumeric, i18n.t("validation.sendUserId"))
    .matches(oneLetter, i18n.t("validation.sendUserId")),
  scheduledTime: Yup.date().required(),
});

const validateTags = (message: string) => {
  const scriptRegex = new RegExp(/<script[\s\S]*?>/gi);
  return !scriptRegex.test(message);
};

export const landingPageSettingsAddFormSchema = Yup.object().shape({
  name: Yup.string()
    .required()
    .max(10, i18n.t("validation.landingPageName"))
    .matches(alphanumericLowercase, i18n.t("validation.landingPageName")),
  message: Yup.string()
    .required()
    .test("tags", i18n.t("validation.forbiddenTags"), (message) =>
      validateTags(message || "")
    ),
});

export const landingPageSettingsEditFormSchema = Yup.object().shape({
  message: Yup.string()
    .required()
    .test("tags", i18n.t("validation.forbiddenTags"), (message) =>
      validateTags(message || "")
    ),
});

export const reviewLinkEditFormSchema = Yup.object().shape({
  text: Yup.string(),
});

export const reviewLinkAddFormSchema = Yup.object().shape({
  text: Yup.string().required(),
  url: Yup.string().required().url(i18n.t("validation.url")),
});

export const gmbReplyFormSchema = Yup.object().shape({
  comment: Yup.string().required(),
});

export const reviewMediaSettingsFormSchema = Yup.object().shape({
  completionMessage: Yup.string().test(
    "tags",
    i18n.t("validation.forbiddenTags"),
    (message) => validateTags(message || "")
  ),
  exceededCompletionMessage: Yup.string().test(
    "tags",
    i18n.t("validation.forbiddenTags"),
    (message) => validateTags(message || "")
  ),
});

export const ecEmailSettingsFormSchema = Yup.object().shape({
  waitDays: Yup.number().required(),
  sendTime: Yup.string().required(),
});

export const mailExclusionSettingsAddFormSchema = Yup.object().shape({
  emailAddresses: Yup.string().required(),
});
