import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ClientPlanDetails,
  ClientAllSuggestionsCount,
  ClientEcLogoDetails,
} from "api/sms-api";
import { CRUDState, initialCRUDState } from "store/CRUDStore";

export interface ContractDetailsState extends CRUDState<ClientPlanDetails> {
  clientPlanDetails: ClientPlanDetails;
  getClientPlanDetailsLoading: boolean;
  clientAllSuggestionsCount: ClientAllSuggestionsCount;
  getClientAllSuggestionsLoading: boolean;
  clientType: string;
  getClientTypeLoading: boolean;
  getClientEcLogoDetailsLoading: boolean;
  createClientEcLogoDetailsLoading: boolean;
  editClientEcLogoDetailsLoading: boolean;
  clientEcLogoDetails?: ClientEcLogoDetails;
}

const initialState: ContractDetailsState = {
  ...initialCRUDState,
  clientPlanDetails: { plan: "", value: "", type: "" },
  clientAllSuggestionsCount: { count: 0 },
  getClientPlanDetailsLoading: false,
  getClientAllSuggestionsLoading: false,
  clientType: "",
  getClientTypeLoading: false,
  getClientEcLogoDetailsLoading: false,
  createClientEcLogoDetailsLoading: false,
  editClientEcLogoDetailsLoading: false,
};

export const slice = createSlice({
  name: "contractDetails",
  initialState,
  reducers: {
    getClientAllSuggestionsCountStart: (state) => {
      state.getClientAllSuggestionsLoading = true;
    },
    getClientAllSuggestionsCountSuccess: (
      state,
      action: PayloadAction<ClientAllSuggestionsCount>
    ) => {
      state.clientAllSuggestionsCount = action.payload;
      state.getClientAllSuggestionsLoading = false;
    },
    getClientAllSuggestionsCountError: (state) => {
      state.getClientAllSuggestionsLoading = false;
    },
    getClientPlanDetailsStart: (state) => {
      state.getClientPlanDetailsLoading = true;
    },
    getClientPlanDetailsSuccess: (
      state,
      action: PayloadAction<ClientPlanDetails>
    ) => {
      state.clientPlanDetails = action.payload;
      state.getClientPlanDetailsLoading = false;
    },
    getClientPlanDetailsError: (state) => {
      state.getClientPlanDetailsLoading = false;
    },
    getClientTypeStart: (state) => {
      state.getClientTypeLoading = true;
    },
    getClientTypeSuccess: (state, action: PayloadAction<string>) => {
      state.clientType = action.payload;
      state.getClientTypeLoading = false;
    },
    getClientTypeError: (state) => {
      state.getClientTypeLoading = false;
    },
    getClientEcLogoDetailsStart: (state) => {
      state.getClientEcLogoDetailsLoading = true;
    },
    getClientEcLogoDetailsSuccess: (
      state,
      action: PayloadAction<ClientEcLogoDetails>
    ) => {
      state.clientEcLogoDetails = action.payload;
      state.getClientEcLogoDetailsLoading = false;
    },
    getClientEcLogoDetailsError: (state) => {
      state.getClientEcLogoDetailsLoading = false;
    },
    createClientEcLogoDetailsStart: (state) => {
      state.createClientEcLogoDetailsLoading = true;
    },
    createClientEcLogoDetailsSuccess: (
      state,
      action: PayloadAction<ClientEcLogoDetails>
    ) => {
      state.clientEcLogoDetails = action.payload;
      state.createClientEcLogoDetailsLoading = false;
    },
    createClientEcLogoDetailsError: (state) => {
      state.createClientEcLogoDetailsLoading = false;
    },
    editClientEcLogoDetailsStart: (state) => {
      state.editClientEcLogoDetailsLoading = true;
    },
    editClientEcLogoDetailsSuccess: (
      state,
      action: PayloadAction<ClientEcLogoDetails>
    ) => {
      state.clientEcLogoDetails = action.payload;
      state.editClientEcLogoDetailsLoading = false;
    },
    editClientEcLogoDetailsError: (state) => {
      state.editClientEcLogoDetailsLoading = false;
    },
    clearClientEcLogo: (state) => {
      if (state.clientEcLogoDetails) {
        state.clientEcLogoDetails.storeLogoUrl = undefined;
      }
    },
  },
});

export const {
  getClientAllSuggestionsCountStart,
  getClientAllSuggestionsCountSuccess,
  getClientAllSuggestionsCountError,
  getClientPlanDetailsStart,
  getClientPlanDetailsSuccess,
  getClientPlanDetailsError,
  getClientTypeStart,
  getClientTypeSuccess,
  getClientTypeError,
  getClientEcLogoDetailsStart,
  getClientEcLogoDetailsSuccess,
  getClientEcLogoDetailsError,
  createClientEcLogoDetailsStart,
  createClientEcLogoDetailsSuccess,
  createClientEcLogoDetailsError,
  editClientEcLogoDetailsStart,
  editClientEcLogoDetailsSuccess,
  editClientEcLogoDetailsError,
  clearClientEcLogo,
} = slice.actions;

export default slice.reducer;
