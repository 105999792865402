import React, { useEffect } from "react";
import { RootState } from "store/store";
import { connect } from "react-redux";
import i18n from "i18n";
import { Formik } from "formik";
import { Form, Input, Select } from "formik-antd";
import { Spin, Row, Col } from "antd";
import {
  ProductsEditForm,
  EcProvider,
  SurveyProductSettings,
} from "api/sms-api";
import { editProduct, getSurveyProductSetting } from "./ProductSettingsActions";
import { useHistory } from "react-router-dom";
import { pushTo } from "utils/navigation";
import qs from "qs";
import { Page, setPage } from "features/appstate/AppStateSlice";
import { MODE_SURVEY, surveyTypeStore } from "../../constants";
import SaveButton from "components/SaveButton";

interface EditProductProps {
  setPage: (page: Page) => void;
  getLoading: boolean;
  productSetting?: SurveyProductSettings;
  getSurveyProductSetting: (id: string) => void;

  editProduct: (id: string, form: ProductsEditForm) => void;
  ecProviders: Array<EcProvider>;
}

const EditProduct: React.FC<EditProductProps> = (props) => {
  const history = useHistory();
  const parsed = qs.parse(location.search, { ignoreQueryPrefix: true });
  const productId = parsed.id ? parsed.id.toString() : "";
  const surveyType = parsed.surveyType
    ? (parsed.surveyType as string)
    : surveyTypeStore;

  useEffect(() => {
    props.setPage(Page.productSettings);
  }, [props.setPage]);

  useEffect(() => {
    if (productId) {
      props.getSurveyProductSetting(productId);
    }
  }, [props.getSurveyProductSetting]);

  if (props.getLoading) {
    return <Spin />;
  }

  const handleSubmit = async (form: ProductsEditForm) => {
    const targetRoute = "/productSettings";
    await props.editProduct(productId, form);

    pushTo(history, targetRoute, {
      mode: MODE_SURVEY,
      surveyType,
    });
  };

  const initialValues = {
    productCode:
      (props.productSetting && props.productSetting.productCode) || "",
    productName:
      (props.productSetting && props.productSetting.productName) || "",
    reviewPageUrl:
      (props.productSetting && props.productSetting.reviewPageUrl) || "",
    ecProviderId:
      (props.productSetting && props.productSetting.ecProviderId) || "",
  };

  const ecProviderOptions = props.ecProviders.map((ecProvider) => {
    return {
      label: ecProvider.name,
      value: ecProvider.id,
    };
  });

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {(formikBag) => {
        return (
          <Form layout="vertical">
            <Row justify="center">
              <Col span={18}>
                <Form.Item
                  name="productCode"
                  label={i18n.t("productSettings.table.title.productCode")}
                  className="input-mail-form"
                  validateTrigger={["onBlur"]}
                  rules={[
                    {
                      validator(_, value) {
                        if (value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(i18n.t("validation.required"))
                        );
                      },
                    },
                  ]}
                >
                  <Input name="productCode" />
                </Form.Item>
                <Form.Item
                  name="productName"
                  label={i18n.t("productSettings.table.title.productName")}
                  className="input-mail-form"
                  validateTrigger={["onBlur"]}
                  rules={[
                    {
                      validator(_, value) {
                        if (value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(i18n.t("validation.required"))
                        );
                      },
                    },
                  ]}
                >
                  <Input name="productName" />
                </Form.Item>
                <Form.Item
                  name="reviewPageUrl"
                  label={i18n.t("productSettings.table.title.reviewPageUrl")}
                  className="input-mail-form"
                >
                  <Input name="reviewPageUrl" />
                </Form.Item>
                <Form.Item
                  name="ecProviderId"
                  label={i18n.t("productSettings.table.title.ecProviderId")}
                  className="input-mail-form"
                  validateTrigger={["onBlur"]}
                  rules={[
                    {
                      validator(_, value) {
                        if (value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(i18n.t("validation.required"))
                        );
                      },
                    },
                  ]}
                >
                  <Select
                    name="mailTemplateSenderId"
                    defaultValue={formikBag.values.ecProviderId}
                    options={ecProviderOptions}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row justify="end" style={{ marginTop: "2.5em" }}>
              <SaveButton />
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    productSetting: state.productSettings.item,
    getLoading: state.productSettings.getLoading,
    ecProviders: state.ecProviders.items,
  };
};

const mapDispatchToProps = {
  setPage,
  editProduct,
  getSurveyProductSetting,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditProduct);
