import React from "react";
import { Link, LinkProps, withRouter } from "react-router-dom";
import * as H from "history";
import { LocationDescriptorObject } from "history";
import { RouteComponentProps } from "react-router";
import qs from "qs";
import { getNewParams } from "utils/navigation";

// A Link that preserves certain query parameters

interface QueryParamProps {
  queryParams?: Record<string, unknown>;
}

type SMSLinkProps = React.PropsWithoutRef<LinkProps<H.LocationState>> &
  React.RefAttributes<HTMLAnchorElement> &
  RouteComponentProps &
  QueryParamProps;

const SMSLink: React.FC<SMSLinkProps> = (props) => {
  const newParams = getNewParams(props.queryParams || {}, []);
  const location: LocationDescriptorObject = {
    pathname: props.to as string,
    search: qs.stringify(newParams),
  };
  return <Link to={location}>{props.children}</Link>;
};

export default withRouter(SMSLink);
