import {
  Paginated,
  SurveyProductSettings,
  SurveyProductSettingsUpdateItem,
  RecipientEmailCsvImportResult,
  ProductsAddForm,
  PaginationParams,
  SurveyProductsAddForm,
  ProductsDeleteForm,
  ProductsEditForm,
} from "api/sms-api";
import { AppDispatch } from "store/store";
import { httpWrapper } from "utils/http";
import {
  getSurveyProductSettingsStart,
  getSurveyProductSettingsSuccess,
  getSurveyProductSettingsError,
  getSurveyProductSettingStart,
  getSurveyProductSettingSuccess,
  getSurveyProductSettingError,
  updateSurveyProductSettingsStart,
  updateSurveyProductSettingsSuccess,
  updateSurveyProductSettingsError,
  exportStart,
  exportSuccess,
  exportError,
  importStart,
  importSuccess,
  importError,
  addProductStart,
  addProductSuccess,
  addProductError,
  addProductSettingsStart,
  addProductSettingsSuccess,
  addProductSettingsError,
  deleteProductSettingsStart,
  deleteProductSettingsSuccess,
  deleteProductSettingsError,
} from "./ProductSettingsSlice";
import { SMS_API_BASE, BOM, PAGE_LIMIT } from "../../constants";
import { handleError } from "errors/errors";
import { showNotification } from "utils/notifications";
import i18n from "i18n";
import download from "js-file-download";
import _ from "lodash";

export const getSurveyProductSettings = (
  param: PaginationParams,
  ecProviderId?: string
) => async (dispatch: AppDispatch) => {
  dispatch(getSurveyProductSettingsStart());
  try {
    let searchParams = param;
    if (ecProviderId) {
      searchParams = Object.assign({}, param, { ecProviderId });
    }
    const surveyProductSettings = await httpWrapper<
      Paginated<SurveyProductSettings>
    >("GET", `${SMS_API_BASE}/client_next_engine/list_products`, searchParams);
    dispatch(getSurveyProductSettingsSuccess(surveyProductSettings));
  } catch (e) {
    handleError(e);
    dispatch(getSurveyProductSettingsError());
  }
};

export const getSurveyProductSetting = (id: string) => async (
  dispatch: AppDispatch
) => {
  dispatch(getSurveyProductSettingStart());
  try {
    const surveyProductSetting = await httpWrapper<SurveyProductSettings>(
      "GET",
      `${SMS_API_BASE}/client_next_engine/products/${id}`,
      {}
    );
    dispatch(getSurveyProductSettingSuccess(surveyProductSetting));
  } catch (e) {
    handleError(e);
    dispatch(getSurveyProductSettingError(e));
  }
};

export const updateSurveyProductSettings = (
  form: Array<SurveyProductSettingsUpdateItem>,
  existingParams: any
) => async (dispatch: AppDispatch) => {
  dispatch(updateSurveyProductSettingsStart());
  try {
    const surveyProductSettings = await httpWrapper<
      Paginated<SurveyProductSettings>
    >("PATCH", `${SMS_API_BASE}/client_next_engine/product_settings`, {
      updateItems: form,
    });
    showNotification(i18n.t("productSettings.settingMail.success"));
    dispatch(updateSurveyProductSettingsSuccess(surveyProductSettings));
    // Reload the list of products
    const getParams = {
      ..._.omit(existingParams, ["before", "after"]),
    };
    dispatch(getSurveyProductSettings({ ...getParams, limit: PAGE_LIMIT }));
  } catch (e) {
    const error: any = e;
    handleError(error);
    dispatch(updateSurveyProductSettingsError(error));
  }
};

export const updateSurveyProduct = (
  pageSize: number,
  page: number,
  form: SurveyProductSettingsUpdateItem
) => async (dispatch: AppDispatch) => {
  dispatch(updateSurveyProductSettingsStart());
  try {
    const surveyProductSettings = await httpWrapper<
      Paginated<SurveyProductSettings>
    >("PATCH", `${SMS_API_BASE}/client_next_engine/products`, {
      form,
    });
    showNotification(i18n.t("productSettings.settings.updateSuccess"));
    dispatch(updateSurveyProductSettingsSuccess(surveyProductSettings));
  } catch (e) {
    const error: any = e;
    handleError(error);
    dispatch(updateSurveyProductSettingsError(error));
  }
};

export const exportCsv = () => async (dispatch: AppDispatch) => {
  dispatch(exportStart());
  try {
    const result = await httpWrapper<any>(
      "POST",
      `${SMS_API_BASE}/client_next_engine/products/export`,
      {}
    );
    download(`${BOM}${result}`, i18n.t("productSettings.csv.fileName"));
    dispatch(exportSuccess());
    return result;
  } catch (e) {
    handleError(e);
    dispatch(exportError());
  }
};

export const importCsv = (file: File) => async (dispatch: AppDispatch) => {
  dispatch(importStart());
  try {
    const formData = new FormData();
    formData.append("file", file);
    const result = await httpWrapper<RecipientEmailCsvImportResult>(
      "POST",
      `${SMS_API_BASE}/client_next_engine/products/import`,
      formData,
      { "Content-Type": "multipart/form-data" }
    );
    dispatch(importSuccess(result));
    return result;
  } catch (e) {
    handleError(e);
    dispatch(importError());
  }
};

export const addProduct = (
  params: ProductsAddForm,
  existingParams: any
) => async (dispatch: AppDispatch) => {
  dispatch(addProductStart());
  try {
    await httpWrapper(
      "POST",
      `${SMS_API_BASE}/client_next_engine/create_product`,
      params
    );
    showNotification(i18n.t("products.table.settings.addSuccess"));
    dispatch(addProductSuccess());
    // Reload the list of products
    const getParams = {
      ..._.omit(existingParams, ["before", "after"]),
    };
    dispatch(getSurveyProductSettings({ ...getParams, limit: PAGE_LIMIT }));
  } catch (e) {
    const error: any = e;
    handleError(error);
    dispatch(addProductError(error));
  }
};

export const editProduct = (id: string, params: ProductsEditForm) => async (
  dispatch: AppDispatch
) => {
  dispatch(addProductStart());
  try {
    await httpWrapper(
      "PATCH",
      `${SMS_API_BASE}/client_next_engine/product/${id}`,
      params
    );
    showNotification(i18n.t("products.table.settings.editSuccess"));
    dispatch(addProductSuccess());
  } catch (e) {
    const error: any = e;
    handleError(error);
    dispatch(addProductError(error));
  }
};

export const addProductSettings = (
  params: Array<SurveyProductsAddForm>
) => async (dispatch: AppDispatch) => {
  dispatch(addProductSettingsStart());
  try {
    await httpWrapper(
      "POST",
      `${SMS_API_BASE}/client_next_engine/survey_products`,
      params
    );
    showNotification(i18n.t("products.table.settings.addSettingsSuccess"));
    dispatch(addProductSettingsSuccess());
  } catch (e) {
    const error: any = e;
    handleError(error);
    dispatch(addProductSettingsError(error));
  }
};

export const deleteProductSettings = (params: ProductsDeleteForm) => async (
  dispatch: AppDispatch
) => {
  dispatch(deleteProductSettingsStart());
  try {
    await httpWrapper(
      "PUT",
      `${SMS_API_BASE}/client_next_engine/products`,
      params
    );
    showNotification(i18n.t("products.table.settings.deleteSettingsSuccess"));
    dispatch(deleteProductSettingsSuccess());
  } catch (e) {
    const error: any = e;
    handleError(error);
    dispatch(deleteProductSettingsError(error));
  }
};
