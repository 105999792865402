import React, { ReactElement } from "react";
import SMSLink from "components/SMSLink";
import { setSavedForm as setSavedPublishForm } from "features/publish/PublishSlice";
import { setSavedForm as setSavedScheduleForm } from "features/schedule/ScheduleSlice";
import { setSelected, SelectedGroup, clearItems } from "./GroupSlice";
import { Page, setPage } from "features/appstate/AppStateSlice";
import { connect } from "react-redux";
import {
  Paginated,
  ListGroupParams,
  FullGroup,
  MEOSite,
  IdAndName,
} from "api/sms-api";
import { RootState } from "store/store";
import qs from "qs";
import { listGroup } from "features/group/GroupActions";
import i18n from "i18n";
import { Alert, Table, Row, Button } from "antd";
import {
  PlusOutlined,
  RollbackOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import { pushTo } from "utils/navigation";
import "moment/locale/ja";
import _ from "lodash";
import { ModifiedPublishForm } from "components/SendForm";
import { ModifiedScheduledEditForm } from "features/schedule/ScheduleEdit";
import { RouteComponentProps, useHistory, useLocation } from "react-router";
import SimplePagination, {
  PaginatedComponentProps,
} from "components/SimplePagination";
import GroupFilters from "./GroupFilters";

interface GroupProps {
  items: Paginated<FullGroup>;
  loading: boolean;

  meoSite: MEOSite;
  listGroup: (params: ListGroupParams) => void;
  setPage: (page: Page) => void;

  savedPublishForm?: ModifiedPublishForm;
  setSavedPublishForm: (form: ModifiedPublishForm) => void;
  savedScheduleForm?: ModifiedScheduledEditForm;
  setSavedScheduleForm: (form: ModifiedScheduledEditForm) => void;

  selected: Array<SelectedGroup>;
  setSelected: (selected: Array<SelectedGroup>) => void;
  clearItems: () => void;
}

const GroupList: React.FC<
  GroupProps & RouteComponentProps & PaginatedComponentProps
> = (props) => {
  const history = useHistory();
  const location = useLocation();
  const parsed = qs.parse(location.search, { ignoreQueryPrefix: true });
  const [selecting, setSelecting] = React.useState<string | null>(null);
  const [scheduledId, setScheduledId] = React.useState<string | null>(null);
  const [selectAlert, setSelectAlert] = React.useState(false);

  React.useEffect(() => {
    props.setPage(Page.group);
  }, [props.setPage]);

  React.useEffect(() => {
    if (props.savedPublishForm && selecting === "send_form") {
      const selectedGroup: Array<SelectedGroup> = props.savedPublishForm.groups
        .filter((g) => !!g.id)
        .map((g) => {
          return {
            id: g.id,
            name: g.name,
          };
        });
      props.setSelected(selectedGroup);
    }
    if (props.savedScheduleForm && selecting === "scheduled") {
      const selectedGroup: Array<SelectedGroup> = props.savedScheduleForm.groups
        .filter((g) => !!g.id)
        .map((g) => {
          return {
            id: g.id,
            name: g.name,
          };
        });
      props.setSelected(selectedGroup);
    }
  }, [props.setSelected, selecting]);

  React.useEffect(() => {
    if (_.has(parsed, "selecting")) {
      setSelecting(parsed["selecting"] as string);
    }
    if (_.has(parsed, "scheduledId")) {
      setScheduledId(parsed["scheduledId"] as string);
    }

    // Remove other parameters that might show up in the query string
    const params = {
      ..._.omit(parsed, ["selecting", "scheduledId"]),
      meoSiteId: props.meoSite.id,
    };

    props.listGroup({ ...params, limit: 100 });
  }, [props.listGroup, location.search, props.meoSite]);

  React.useEffect(() => {
    return () => {
      props.clearItems();
    };
  }, [props.clearItems]);

  const finishSelection = (save: boolean) => {
    if (selecting === "send_form" && props.savedPublishForm) {
      if (save) {
        // For PublishForm, we select by id, so assume everything has a id (and name)
        const selectedGroup: Array<IdAndName> = props.selected.map((s) => {
          return {
            id: s.id!,
            name: s.name!,
          };
        });
        const form = {
          ...props.savedPublishForm,
          groups: selectedGroup,
        };
        props.setSavedPublishForm(form);
      }
      pushTo(history, "/publish", {
        shortcutSelected: "back",
      });
    }
    if (selecting === "scheduled" && props.savedScheduleForm) {
      if (save) {
        // For ScheduleForm, we select by id, so assume everything has a id (and name)
        const selectedGroup: Array<IdAndName> = props.selected.map((s) => {
          return {
            id: s.id!,
            name: s.name!,
          };
        });
        const form = {
          ...props.savedScheduleForm,
          groups: selectedGroup,
        };
        props.setSavedScheduleForm(form);
      }
      pushTo(history, `/scheduled/${scheduledId}`, {
        shortcutSelected: "back",
      });
    }
  };

  const columns = [
    {
      title: i18n.t("common.name"),
      dataIndex: "name",
      key: "name",
      render: (_: any, record: FullGroup) => {
        if (selecting) {
          return <div>{record.group.name}</div>;
        } else {
          return (
            <SMSLink to={`/group/edit/${record.group.id}`}>
              {record.group.name}
            </SMSLink>
          );
        }
      },
    },
    {
      title: i18n.t("groups.addresses"),
      dataIndex: "addresses",
      key: "addresses",
      render: (_: any, record: FullGroup) => {
        return (
          <span>
            <SMSLink to={`/${Page.group}/${record.group.id}`}>
              <div>
                {i18n.t("groups.totalContacts", {
                  count: record.addresses.length,
                })}
              </div>
            </SMSLink>
          </span>
        );
      },
    },
  ];

  const addButton = (
    <SMSLink to="/group/add">
      <Button icon={<PlusOutlined />} type="primary">
        {i18n.t("groups.add")}
      </Button>
    </SMSLink>
  );

  const pagination = (
    <SimplePagination
      items={props.items.items}
      getCursor={(model: FullGroup) => model.group.createdAt}
      sortDescending={true}
      hasRight={props.items.hasRight}
      hasLeft={props.items.hasLeft}
      location={props.location}
      history={history}
      match={props.match}
    />
  );

  let selectingExplanation: ReactElement | null = null;
  if (selecting) {
    const text = i18n.t("groups.selectingExplanation.sendForm");
    selectingExplanation = (
      <Alert
        message={
          <div>
            <div>
              <span>{text}</span>
            </div>
            <div style={{ marginTop: "1em" }}>
              <Button
                type="primary"
                icon={<CheckOutlined />}
                onClick={() => finishSelection(true)}
                style={{ marginRight: "1em" }}
              >
                {i18n.t("common.select")}
              </Button>
              <Button
                icon={<RollbackOutlined />}
                onClick={() => finishSelection(false)}
              >
                {i18n.t("common.back")}
              </Button>
            </div>
          </div>
        }
        type="info"
        style={{ marginBottom: "2em" }}
      />
    );
  }

  const selectMaxAlert = selectAlert && (
    <Alert
      message={
        <div>
          <span>{i18n.t("groups.maxSelectGroup")}</span>
        </div>
      }
      type="error"
      style={{ marginBottom: "2em" }}
    />
  );

  const handleSelect = (
    record: FullGroup,
    isSelected: boolean,
    selectedRows: Array<any>
  ) => {
    if (selectedRows.length > 100) {
      setSelectAlert(true);
      return;
    }

    if (isSelected) {
      // Adding
      const newSelected = [
        ...props.selected,
        {
          id: record.group.id,
          name: record.group.name,
        },
      ];
      props.setSelected(newSelected);
    } else {
      // Removing
      let current = [...props.selected];
      // Remove by ID
      current = current.filter((c) => c.id !== record.group.id);
      props.setSelected(current);
    }
    setSelectAlert(false);
  };

  const handleSelectAll = (
    isSelected: boolean,
    selectedRows: Array<FullGroup>, // Don't use; this will contain `undefined` values when you move between pages
    changeRows: Array<FullGroup>
  ) => {
    if (selectedRows.length > 100) {
      setSelectAlert(true);
      return;
    }
    if (isSelected) {
      // Add all
      const newSelections = changeRows.map((a) => {
        return {
          id: a.group.id,
          name: a.group.name,
        };
      });

      // Add the newly selected rows, deduplicating by key
      const newResult = _.uniqBy(
        [...props.selected, ...newSelections],
        (a: SelectedGroup) => {
          return selecting === "send_form" || selecting === "scheduled"
            ? a.id!
            : a.name!;
        }
      );
      props.setSelected(newResult);
    } else {
      // Remove all
      const deselectedNumbers = changeRows.map((a) => a.group.id);
      const newResult = props.selected.filter(
        (c) => !deselectedNumbers.includes(c.id!)
      );
      props.setSelected(newResult);
    }
  };

  const selectedRowKeys = props.selected.map((s: SelectedGroup) => {
    return s.id!;
  });

  const rowSelection = {
    selectedRowKeys,
    onSelect: handleSelect,
    onSelectAll: handleSelectAll,
    preserveSelectedRowKeys: true,
  };

  return (
    <div>
      {selectingExplanation}
      {selectMaxAlert}
      <GroupFilters />
      <Table
        rowSelection={selecting ? rowSelection : undefined}
        dataSource={props.items.items}
        columns={columns}
        locale={{ emptyText: i18n.t("groups.empty") }}
        rowKey={(s: FullGroup) => s.group.id}
        size="middle"
        pagination={false}
        loading={props.loading}
      />
      <Row className="pagination-row">{pagination}</Row>
      <Row className="form-button-row">{!selecting && addButton}</Row>
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    items: state.group.items,
    loading: state.group.listLoading,
    meoSite: state.seo.meoSite!,
    savedPublishForm: state.publish.savedForm,
    savedScheduleForm: state.scheduleds.savedForm,
    selected: state.group.selected,
  };
};

const mapDispatchToProps = {
  setPage,
  listGroup,
  setSavedPublishForm,
  setSavedScheduleForm,
  setSelected,
  clearItems,
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupList);
