import React, { useEffect, useState } from "react";
import { Page, setPage } from "features/appstate/AppStateSlice";
import {
  SurveyMeoSites,
  SurveyMeoSiteDeleteForm,
  Paginated,
  SurveyMeoSitesParams,
  SurveyMeoSitesEditForm,
  ReviewMediaData,
} from "api/sms-api";
import {
  Spin,
  Table,
  Button,
  Row,
  Modal,
  Col,
  Popconfirm,
  Typography,
} from "antd";
import { RootState } from "store/store";
import { connect } from "react-redux";
import {
  getSurveyMeoSitesList,
  deleteSurveyMeoSites,
  editReviewLink,
} from "./SurveyMeoSitesActions";
import { getSurvey, getReviewMediaSettings } from "../survey/SurveyActions";
import {
  setSelectedSurveyMeoSites,
  clearSelectedSurveyMeoSites,
  clearItems,
} from "./SurveyMeoSitesSlice";
import i18n from "i18n";
import { RouteComponentProps, useLocation, useHistory } from "react-router-dom";
import { compact } from "lodash";
import SurveyQR from "components/SurveyQR";
import {
  PlusOutlined,
  QrcodeOutlined,
  ImportOutlined,
  ExportOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import _ from "lodash";
import SimplePagination, {
  PaginatedComponentProps,
} from "components/SimplePagination";
import qs from "qs";
import {
  MODE_SURVEY,
  SURVEY_PAGE_BASE,
  surveyTypeStore,
} from "../../constants";
import { pushTo } from "utils/navigation";
import SMSLink from "components/SMSLink";
import { exportCsv } from "features/survey_meo_sites/SurveyMeoSitesActions";
import { Surveys } from "api/sms-api";
import ReviewLinkEdit from "./ReviewLinkEdit";

const { Paragraph } = Typography;
const PAGE_LIMIT = 100;

interface SurveyMeoSitesListProps {
  items: Paginated<SurveyMeoSites>;
  listLoading: boolean;
  getSurveyMeoSitesList: (params: SurveyMeoSitesParams) => void;
  clearItems: () => void;

  survey?: Surveys;
  getLoading: boolean;
  getSurvey: (surveyId: string) => void;

  reviewMediaSettings?: ReviewMediaData;
  reviewMediaSettingLoading: boolean;
  getReviewMediaSettings: (surveyId: string) => void;

  deleteSurveyMeoSites: (params: SurveyMeoSiteDeleteForm) => void;
  multipleDeleteFinished: boolean;
  multipleDeleteLoading: boolean;

  selectedSurveyMeoSites: Array<{ id: string }>;
  setSelectedSurveyMeoSites: (selected: Array<{ id: string }>) => void;
  clearSelectedSurveyMeoSites: () => void;

  setPage: (page: Page) => void;

  exportCsv: (id: string) => void;

  editReviewLink: (
    surveyId: string,
    params: SurveyMeoSitesEditForm
  ) => Promise<void>;
}

const SurveyMeoSitesList: React.FC<
  SurveyMeoSitesListProps & RouteComponentProps & PaginatedComponentProps
> = (props) => {
  const history = useHistory();
  const location = useLocation();
  const parsed = qs.parse(location.search, { ignoreQueryPrefix: true });
  const surveyId = parsed["id"] as string;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editReviewLink, setEditReviewLink] = useState<{
    id: string;
    reviewLink: string | null;
  }>({
    id: "",
    reviewLink: null,
  });

  useEffect(() => {
    props.setPage(Page.meoSites);
  }, [props.setPage]);

  useEffect(() => {
    if (surveyId) {
      props.getSurvey(surveyId);
    }
  }, [props.getSurvey]);

  useEffect(() => {
    if (surveyId) {
      props.getReviewMediaSettings(surveyId);
    }
  }, [props.getReviewMediaSettings, surveyId]);

  useEffect(() => {
    const params = {
      ..._.omit(parsed, ["mode"]),
      id: surveyId,
    };
    props.getSurveyMeoSitesList({ ...params, limit: PAGE_LIMIT });
  }, [props.getSurveyMeoSitesList, location.search]);

  useEffect(() => {
    if (props.multipleDeleteFinished && !props.multipleDeleteLoading) {
      props.clearSelectedSurveyMeoSites();
      const params = {
        ..._.omit(parsed, ["mode", "before", "after"]),
        id: surveyId,
      };
      props.getSurveyMeoSitesList({ ...params, limit: PAGE_LIMIT });
    }
  }, [
    props.getSurveyMeoSitesList,
    props.multipleDeleteFinished,
    props.multipleDeleteLoading,
  ]);

  useEffect(() => {
    return () => {
      props.clearItems();
      props.clearSelectedSurveyMeoSites();
    };
  }, []);

  if (
    props.listLoading ||
    props.getLoading ||
    props.reviewMediaSettingLoading
  ) {
    return <Spin />;
  }

  const handleQR = (id: number, siteName: string) => {
    Modal.info({
      title: null,
      icon: null,
      content: (
        <SurveyQR
          url={`${SURVEY_PAGE_BASE}/static?id=${surveyId}&siteId=${id}`}
          fileName={`${siteName}${i18n.t(
            "meoSites.table.settings.qrFileName"
          )}`}
          title={siteName}
        />
      ),
    });
  };

  const handleReviewLink = (id: string, reviewLink: string | null) => {
    setEditReviewLink({ id, reviewLink });
    setIsModalOpen(true);
  };

  const saveModal = async (id: string, value: string | null) => {
    setIsModalOpen(false);
    const form = {
      reviewLink: value,
    };
    await props.editReviewLink(id, form);
    if (surveyId) {
      const params = {
        ..._.omit(parsed, ["mode"]),
        id: surveyId,
      };
      await props.getSurveyMeoSitesList({ ...params, limit: PAGE_LIMIT });
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const columns = () =>
    compact([
      {
        title: i18n.t("meoSites.table.title.meoSiteName"),
        dataIndex: "meoSiteName",
        key: "meoSiteName",
      },
      {
        title: i18n.t("meoSites.table.title.url"),
        dataIndex: "url",
        key: "url",
        render: (_: any, surveyMeoSites: SurveyMeoSites): JSX.Element => {
          return (
            <Paragraph
              copyable
              className="url-column"
            >{`${SURVEY_PAGE_BASE}/static?id=${surveyId}&siteId=${surveyMeoSites.meoSiteId}`}</Paragraph>
          );
        },
      },
      {
        title: i18n.t("meoSites.table.title.mediaName"),
        key: "mediaName",
        render: (_: any) => {
          if (typeof props.survey === "undefined") {
            return "";
          }
          return props.survey.isGoogleReview
            ? i18n.t("meoSites.table.item.mediaNameGoogle")
            : props.reviewMediaSettings?.mediaName || "";
        },
      },
      {
        title: i18n.t("meoSites.table.title.reviewLink"),
        dataIndex: "reviewLink",
        key: "reviewLink",
        render: (_: any, surveyMeoSites: SurveyMeoSites): JSX.Element => {
          if (!surveyMeoSites.reviewLink) {
            return <></>;
          }
          return (
            <Paragraph copyable className="url-column">
              {surveyMeoSites.reviewLink}
            </Paragraph>
          );
        },
      },
      {
        title: null,
        key: "buttons",
        render: (_: any, surveyMeoSites: SurveyMeoSites): JSX.Element => {
          return (
            <div className="survey-table-buttons">
              <Button
                onClick={() =>
                  handleQR(surveyMeoSites.meoSiteId, surveyMeoSites.meoSiteName)
                }
                icon={<QrcodeOutlined />}
              >
                {i18n.t("meoSites.table.item.qrCode")}
              </Button>
              <Button
                onClick={() =>
                  handleReviewLink(surveyMeoSites.id, surveyMeoSites.reviewLink)
                }
                icon={<EditOutlined />}
              >
                {i18n.t("meoSites.table.item.reviewLink")}
              </Button>
            </div>
          );
        },
      },
    ]);

  const goToSelectMeoSite = () => {
    pushTo(
      history,
      `/${Page.meoSiteSettings}`,
      {
        surveyId,
        mode: MODE_SURVEY,
        surveyType: parsed.surveyType || surveyTypeStore,
      },
      [],
      true
    );
  };

  const addButton = (
    <Button onClick={goToSelectMeoSite} icon={<PlusOutlined />} type="primary">
      {i18n.t("meoSites.table.settings.add")}
    </Button>
  );

  const handleDelete = () => {
    const selectedIds: Array<string> = props.selectedSurveyMeoSites.map(
      (s) => s.id!
    );
    const form: SurveyMeoSiteDeleteForm = {
      surveyMeoSiteIds: selectedIds,
    };
    props.deleteSurveyMeoSites(form);
  };

  const deleteButton = (
    <Popconfirm
      title={i18n.t("meoSites.table.settings.deleteCountConfirm", {
        count: props.selectedSurveyMeoSites.length,
      })}
      disabled={props.selectedSurveyMeoSites.length < 1}
      onConfirm={handleDelete}
      okText={i18n.t("common.delete")}
      cancelText={i18n.t("common.cancel")}
      okType="danger"
    >
      <Button
        danger={props.selectedSurveyMeoSites.length > 0}
        disabled={props.selectedSurveyMeoSites.length < 1}
        icon={<DeleteOutlined />}
      >
        {i18n.t("common.delete")}
      </Button>
    </Popconfirm>
  );

  const csvImportButton = (
    <SMSLink
      to={`/${Page.meoSites}/import`}
      queryParams={{
        mode: MODE_SURVEY,
        surveyType: parsed.surveyType || surveyTypeStore,
      }}
    >
      <Button icon={<ImportOutlined />}>
        {i18n.t("meoSites.table.settings.import")}
      </Button>
    </SMSLink>
  );

  const handleClickExport = () => {
    props.exportCsv(surveyId);
  };

  const csvExportButton = (
    <Button icon={<ExportOutlined />} onClick={handleClickExport}>
      {i18n.t("common.csvExport")}
    </Button>
  );

  const selectedRowKeys = props.selectedSurveyMeoSites.map(({ id }) => id);

  const handleSelect = (record: SurveyMeoSites, isSelected: boolean) => {
    if (isSelected) {
      // Adding
      const newSelected = [
        ...props.selectedSurveyMeoSites,
        {
          id: record.id,
        },
      ];
      props.setSelectedSurveyMeoSites(newSelected);
    } else {
      // Removing
      let current = [...props.selectedSurveyMeoSites];
      current = current.filter((c) => c.id !== record.id);
      props.setSelectedSurveyMeoSites(current);
    }
  };

  const handleSelectAll = (
    isSelected: boolean,
    changeRows: Array<SurveyMeoSites>
  ) => {
    if (isSelected) {
      // Add all
      const newSelections = changeRows.map((a) => {
        return {
          id: a.id,
        };
      });

      // Add the newly selected rows, deduplicating by key
      const newResult = _.uniqBy(
        [...props.selectedSurveyMeoSites, ...newSelections],
        (a: { id: string }) => {
          return a.id!;
        }
      );
      props.setSelectedSurveyMeoSites(newResult);
    } else {
      // Remove all
      props.setSelectedSurveyMeoSites([]);
    }
  };

  const rowSelection = {
    selectedRowKeys,
    onSelect: handleSelect,
    onSelectAll: handleSelectAll,
    preserveSelectedRowKeys: true,
  };

  const pagination = (
    <SimplePagination
      items={props.items.items}
      getCursor={(model: SurveyMeoSites) => model.createdAt}
      sortDescending={true}
      hasRight={props.items.hasRight}
      hasLeft={props.items.hasLeft}
      location={props.location}
      history={history}
      match={props.match}
    />
  );

  return (
    <div>
      <div className="table-button-row">
        <Row className="table-button-row">
          {i18n.t("meoSites.table.item.surveyTitle", {
            title: props.survey ? props.survey.title : "",
          })}
        </Row>
        <Row className="meo-sites-form-button-row">
          <Col>
            {addButton}
            {deleteButton}
          </Col>
          <Col>
            {csvExportButton}
            {csvImportButton}
          </Col>
        </Row>
      </div>
      <Table
        rowSelection={rowSelection}
        dataSource={props.items.items}
        columns={columns()}
        rowKey={(meoSites: SurveyMeoSites) => meoSites.id}
        size="middle"
        pagination={false}
        loading={props.listLoading}
      />
      <ReviewLinkEdit
        visible={isModalOpen}
        closeModal={closeModal}
        saveModal={saveModal}
        id={editReviewLink.id}
        value={editReviewLink.reviewLink}
      />
      <Row className="pagination-row">{pagination}</Row>
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    survey: state.survey.survey,
    items: state.surveyMeoSites.items,
    listLoading: state.surveyMeoSites.listLoading,
    multipleDeleteFinished: state.surveyMeoSites.multipleDeleteFinished,
    multipleDeleteLoading: state.surveyMeoSites.multipleDeleteLoading,
    selectedSurveyMeoSites: state.surveyMeoSites.selectedSurveyMeoSites,
    reviewMediaSettings: state.survey.reviewMediaSettings,
    reviewMediaSettingLoading: state.survey.reviewMediaSettingLoading,
  };
};

const mapDispatchToProps = {
  setPage,
  getReviewMediaSettings,
  getSurvey,
  getSurveyMeoSitesList,
  setSelectedSurveyMeoSites,
  clearItems,
  deleteSurveyMeoSites,
  clearSelectedSurveyMeoSites,
  exportCsv,
  editReviewLink,
};

export default connect(mapStateToProps, mapDispatchToProps)(SurveyMeoSitesList);
