import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ClientEcOptoutDetails } from "../../api/sms-api";
import {
  NonPaginatedCRUDState,
  initialNonPaginatedCRUDState,
} from "store/CRUDStore";
import { ErrorWrapper } from "errors/errors";

interface MailExclusionSettingsState
  extends NonPaginatedCRUDState<ClientEcOptoutDetails> {
  items: Array<ClientEcOptoutDetails>;
  mailExclusionSetting?: string;
  updateLoading: boolean;
  getMailExclusionSettingLoading: boolean;
}

export const initialState: MailExclusionSettingsState = {
  ...initialNonPaginatedCRUDState,
  updateLoading: false,
  getMailExclusionSettingLoading: false,
};

export const slice = createSlice({
  name: "mailExclusionSettings",
  initialState,
  reducers: {
    listMailExclusionSettingsStart: (state) => {
      state.listLoading = true;
    },
    listMailExclusionSettingsSuccess: (state, action) => {
      state.items = action.payload;
      state.listLoading = false;
    },
    listMailExclusionSettingsError: (
      state,
      _action: PayloadAction<ErrorWrapper>
    ) => {
      state.listLoading = false;
    },
    addMailExclusionSettingsStart: (state) => {
      state.createLoading = true;
    },
    addMailExclusionSettingsSuccess: (state) => {
      state.createLoading = false;
    },
    addMailExclusionSettingsError: (
      state,
      _action: PayloadAction<ErrorWrapper>
    ) => {
      state.createLoading = false;
    },
    updateMailExclusionSettingsStart: (state) => {
      state.updateLoading = true;
    },
    updateMailExclusionSettingsSuccess: (state) => {
      state.updateLoading = false;
    },
    updateMailExclusionSettingsError: (
      state,
      _action: PayloadAction<ErrorWrapper>
    ) => {
      state.updateLoading = false;
    },
    editMailExclusionSettingsStart: (state) => {
      state.editLoading = true;
    },
    editMailExclusionSettingsSuccess: (state) => {
      state.editLoading = false;
    },
    editMailExclusionSettingsError: (
      state,
      _action: PayloadAction<ErrorWrapper>
    ) => {
      state.editLoading = false;
    },
    getMailExclusionSettingStart: (state) => {
      state.getMailExclusionSettingLoading = true;
    },
    getMailExclusionSettingSuccess: (state, action) => {
      state.mailExclusionSetting = action.payload;
      state.getMailExclusionSettingLoading = false;
    },
    getMailExclusionSettingError: (
      state,
      _action: PayloadAction<ErrorWrapper>
    ) => {
      state.getMailExclusionSettingLoading = false;
    },
  },
});

export const {
  listMailExclusionSettingsStart,
  listMailExclusionSettingsSuccess,
  listMailExclusionSettingsError,
  addMailExclusionSettingsStart,
  addMailExclusionSettingsSuccess,
  addMailExclusionSettingsError,
  updateMailExclusionSettingsStart,
  updateMailExclusionSettingsSuccess,
  updateMailExclusionSettingsError,
  editMailExclusionSettingsStart,
  editMailExclusionSettingsSuccess,
  editMailExclusionSettingsError,
  getMailExclusionSettingStart,
  getMailExclusionSettingSuccess,
  getMailExclusionSettingError,
} = slice.actions;

export default slice.reducer;
