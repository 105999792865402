import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Paginated, OrderSurveyEmailWithOrder } from "api/sms-api";
import { CRUDState, initialCRUDState } from "store/CRUDStore";

export interface OrderSurveyEmailsState
  extends CRUDState<OrderSurveyEmailWithOrder> {}

const initialState: OrderSurveyEmailsState = {
  ...initialCRUDState,
};

export const slice = createSlice({
  name: "orderSurveyEmails",
  initialState,
  reducers: {
    getOrderSurveyEmailsStart: (state) => {
      state.listLoading = true;
    },
    getOrderSurveyEmailsSuccess: (
      state,
      action: PayloadAction<Paginated<OrderSurveyEmailWithOrder>>
    ) => {
      state.items = action.payload;
      state.listLoading = false;
    },
    getOrderSurveyEmailsError: (state) => {
      state.listLoading = false;
    },
    clearItems: (state) => {
      state.items = { items: [], hasRight: false, hasLeft: false };
    },
  },
});

export const {
  getOrderSurveyEmailsStart,
  getOrderSurveyEmailsSuccess,
  getOrderSurveyEmailsError,
  clearItems,
} = slice.actions;

export default slice.reducer;
