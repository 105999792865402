import React from "react";
import { Modal, Form, Input } from "antd";
import i18n from "i18n";

interface ReviewLinkEditProps {
  visible: boolean;
  id: string;
  value: string | null;
  saveModal: (id: string, value: string | null) => void;
  closeModal: () => void;
}

const ReviewLinkEdit: React.FC<ReviewLinkEditProps> = (props) => {
  const [form] = Form.useForm();

  const handleSaveButton = (value: { reviewLink: string | null }) => {
    form.resetFields();
    props.saveModal(props.id, value.reviewLink);
  };

  const handleCancel = () => {
    form.resetFields();
    props.closeModal();
  };

  return (
    <Modal
      destroyOnClose
      visible={props.visible}
      title={i18n.t("meoSites.table.item.reviewLink")}
      okText={i18n.t("common.save")}
      cancelText={i18n.t("common.cancel")}
      onOk={() => {
        form.validateFields().then((values) => {
          handleSaveButton(values);
        });
      }}
      onCancel={handleCancel}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          reviewLink: props.value || i18n.t("meoSites.table.item.defaultUrl"),
        }}
      >
        <Form.Item name="reviewLink">
          <Input name="reviewLink" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ReviewLinkEdit;
