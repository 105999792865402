import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MailTemplateWithSender, Paginated } from "../../api/sms-api";
import { CRUDState, initialCRUDState } from "store/CRUDStore";
import { ErrorWrapper } from "errors/errors";

interface MailTemplatesState extends CRUDState<MailTemplateWithSender> {
  items: Paginated<MailTemplateWithSender>;
  selectedMailTemplates: Array<MailTemplateWithSender>;
  sendingTestEmail: boolean;
}

export const initialState: MailTemplatesState = {
  ...initialCRUDState,
  selectedMailTemplates: [],
  sendingTestEmail: false,
};

export const slice = createSlice({
  name: "mailTemplates",
  initialState,
  reducers: {
    listMailTemplateStart: (state) => {
      state.listLoading = true;
    },
    listMailTemplateSuccess: (state, action) => {
      state.items = action.payload;
      state.listLoading = false;
    },
    listMailTemplateError: (state, _action: PayloadAction<ErrorWrapper>) => {
      state.listLoading = false;
    },
    addMailTemplateStart: (state) => {
      state.createLoading = true;
    },
    addMailTemplateSuccess: (state) => {
      state.createLoading = false;
    },
    addMailTemplateError: (state, _action: PayloadAction<ErrorWrapper>) => {
      state.createLoading = false;
    },
    editMailTemplateStart: (state) => {
      state.editLoading = true;
    },
    editMailTemplateSuccess: (state) => {
      state.editLoading = false;
    },
    editMailTemplateError: (state, _action: PayloadAction<ErrorWrapper>) => {
      state.editLoading = false;
    },
    getMailTemplateStart: (state) => {
      state.getLoading = true;
    },
    getMailTemplateSuccess: (
      state,
      action: PayloadAction<MailTemplateWithSender>
    ) => {
      state.item = action.payload;
      state.getLoading = false;
      state.error = undefined;
    },
    getMailTemplateError: (state, action: PayloadAction<ErrorWrapper>) => {
      state.getLoading = false;
      state.error = action.payload;
    },
    deleteMailTemplateStart: (state) => {
      state.deleteLoading = true;
    },
    deleteMailTemplateSuccess: (state) => {
      state.deleteLoading = false;
      state.deleteFinished = true;
    },
    deleteMailTemplateError: (state, _action: PayloadAction<ErrorWrapper>) => {
      state.deleteLoading = false;
    },
    clearItems: (state) => {
      state.items = { items: [], hasRight: false, hasLeft: false };
    },
    setSelectedMailTemplates: (
      state,
      action: PayloadAction<Array<MailTemplateWithSender>>
    ) => {
      state.selectedMailTemplates = action.payload;
    },
    clearSelectedMailTemplates: (state) => {
      state.selectedMailTemplates = [];
    },
    sendMailTemplateTestMailStart: (state) => {
      state.sendingTestEmail = true;
    },
    sendMailTemplateTestMailSuccess: (state) => {
      state.sendingTestEmail = false;
    },
    sendMailTemplateTestMailError: (
      state,
      action: PayloadAction<ErrorWrapper>
    ) => {
      state.sendingTestEmail = false;
      state.error = action.payload;
    },
  },
});

export const {
  listMailTemplateStart,
  listMailTemplateSuccess,
  listMailTemplateError,
  addMailTemplateStart,
  addMailTemplateSuccess,
  addMailTemplateError,
  editMailTemplateStart,
  editMailTemplateSuccess,
  editMailTemplateError,
  getMailTemplateStart,
  getMailTemplateSuccess,
  getMailTemplateError,
  deleteMailTemplateStart,
  deleteMailTemplateSuccess,
  deleteMailTemplateError,
  clearItems,
  setSelectedMailTemplates,
  clearSelectedMailTemplates,
  sendMailTemplateTestMailStart,
  sendMailTemplateTestMailSuccess,
  sendMailTemplateTestMailError,
} = slice.actions;

export default slice.reducer;
