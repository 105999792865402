import React from "react";

import { Layout, Menu, Dropdown, Row } from "antd";
import i18n from "i18n";
import { SSO_BASE, BILLING_BASE } from "../constants";
import { toggleSidebar } from "features/appstate/AppStateSlice";
import { connect } from "react-redux";
import { RootState } from "store/store";
import {
  LockOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
  DownOutlined,
} from "@ant-design/icons";
import { mobileMediaQuery } from "../constants";
import Media from "react-media";
import { UsageStatistics, SSOClient, Client, MEOSite } from "api/sms-api";
import Logo from "./Logo";

const { Header } = Layout;

interface AppHeaderProps {
  client?: Client;
  ssoClient: SSOClient;
  meoSite?: MEOSite;
  statistics?: UsageStatistics;
  sidebarCollapsed: boolean;
  survey?: boolean;
  toggleSidebar: () => void;
}

const AppHeader: React.FC<AppHeaderProps> = (props) => {
  const menu = (
    <Menu>
      <Menu.Item key="1">
        <a href={`${SSO_BASE}/logout`}>
          <LockOutlined />
          {i18n.t("menu.logout")}
        </a>
      </Menu.Item>
    </Menu>
  );

  const handleMenuButtonClick = () => {
    props.toggleSidebar();
  };

  const menuButton = props.sidebarCollapsed ? (
    <MenuUnfoldOutlined
      className="menu-button"
      style={{ fontSize: 24 }}
      onClick={handleMenuButtonClick}
    />
  ) : (
    <MenuFoldOutlined
      className="menu-button"
      style={{ fontSize: 24 }}
      onClick={handleMenuButtonClick}
    />
  );

  const remainingMessages = props.statistics
    ? props.statistics.totalAllowedMessages - props.statistics.usedMessages
    : null;

  const storeName = props.meoSite?.name || "";

  const messageDisplay = remainingMessages !== null && (
    <a
      href={`${BILLING_BASE}/option/seo/sms_charge.html`}
      target="_blank"
      rel="noopener noreferrer"
      style={{ marginRight: "1em" }}
    >
      {i18n.t("header.remainingMessages", {
        parts: remainingMessages,
      })}
    </a>
  );

  const userMenu = (smallScreen: boolean) => (
    <Dropdown overlay={menu} trigger={["click"]} placement="bottomRight">
      <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
        <UserOutlined />
        <span className="user-name">
          {smallScreen ? "" : props.ssoClient.userName}
        </span>
        <DownOutlined />
      </a>
    </Dropdown>
  );

  return (
    <Media query={mobileMediaQuery}>
      {(smallScreen) => {
        if (smallScreen) {
          return (
            <Header className="sms-header">
              <Row justify="space-between" align="middle">
                {menuButton}
                <Logo ssoClient={props.ssoClient} />
              </Row>
              {props.survey ? (
                <Row justify="end">{i18n.t("header.survey")}</Row>
              ) : null}
              <Row justify="space-between">{storeName}</Row>
              <Row justify="end">
                {messageDisplay}
                {userMenu(smallScreen)}
              </Row>
            </Header>
          );
        } else {
          return (
            <Header className="sms-header">
              <Row justify="space-between" align="middle">
                <div>
                  <Logo ssoClient={props.ssoClient} />
                  {props.survey ? (
                    <span style={{ marginLeft: "1em" }}>
                      {i18n.t("header.survey")}
                    </span>
                  ) : null}
                </div>
                <span>{storeName}</span>
                <div>
                  {messageDisplay}
                  {userMenu(smallScreen)}
                </div>
              </Row>
            </Header>
          );
        }
      }}
    </Media>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    meoSite: state.seo.meoSite,
    sidebarCollapsed: state.app.sidebarCollapsed,
  };
};
const mapDispatchToProps = {
  toggleSidebar,
};

export default connect(mapStateToProps, mapDispatchToProps)(AppHeader);
