import { ActivityType } from "api/sms-api";
import i18n from "i18n";
import React from "react";
import classnames from "classnames";

interface ActivityTagProps {
  activityType: ActivityType;
  times?: number;
  reviewLinkName?: string;
}

const ActivityTag: React.FC<ActivityTagProps> = (props) => {
  const message = i18n.t(`addresses.activityTypes.${props.activityType}`);
  const classes = classnames("activity-tag", {
    "url-view": props.activityType === ActivityType.UrlView,
    "landing-page-view": props.activityType === ActivityType.LandingPageView,
    "review-link-click": props.activityType === ActivityType.ReviewLinkClick,
  });

  return (
    <div className={classes}>
      <div>{message}</div>
      {props.reviewLinkName && <div>{props.reviewLinkName}</div>}
      {(props.times || 0) > 1 && (
        <div>{i18n.t("addresses.activityTimes", { times: props.times })}</div>
      )}
    </div>
  );
};

export default ActivityTag;
