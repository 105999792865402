import React from "react";
import { connect } from "react-redux";
import { pushTo } from "utils/navigation";
import { ReplyTemplate, ReplyTemplateAddForm, MEOSite } from "api/sms-api";
import {
  getReplyTemplate,
  editReplyTemplate,
  deleteReplyTemplate,
} from "./ReplyTemplateActions";
import { clearReplyTemplate } from "./ReplyTemplateSlice";
import { RootState } from "store/store";
import { Page, setPage } from "features/appstate/AppStateSlice";
import { Spin, Row, Popconfirm } from "antd";
import { CustomError, ErrorWrapper } from "errors/errors";
import { Formik } from "formik";
import { Form, Input } from "formik-antd";
import i18n from "i18n";
import { templateAddFormSchema } from "validation";
import { formItemLayout } from "../../constants";
import { useHistory, useParams } from "react-router-dom";
import NotFound from "components/NotFound";
import BackButton from "components/BackButton";
import SaveButton from "components/SaveButton";
import DeleteButton from "components/DeleteButton";

interface ReplyTemplateEditProps {
  getLoading: boolean;
  editLoading: boolean;
  deleteLoading: boolean;
  deleteFinished: boolean;
  template?: ReplyTemplate;
  error?: ErrorWrapper;
  getReplyTemplate: (id: string) => void;
  deleteReplyTemplate: (id: string) => void;
  editReplyTemplate: (id: string, form: ReplyTemplateAddForm) => Promise<void>;
  setPage: (page: Page) => void;
  clearReplyTemplate: () => void;
  meoSite: MEOSite;
}

const ReplyTemplateEdit: React.FC<ReplyTemplateEditProps> = (props) => {
  const history = useHistory();
  const { id } = useParams() as any;

  React.useEffect(() => {
    props.setPage(Page.replyTemplates);
  }, [props.setPage]);

  React.useEffect(() => {
    props.getReplyTemplate(id);
  }, [props.getReplyTemplate, id]);

  React.useEffect(() => {
    if (props.deleteFinished) {
      pushTo(history, `/${Page.replyTemplates}`, {});
    }
  }, [props.deleteFinished]);

  React.useEffect(() => {
    return () => {
      props.clearReplyTemplate();
    };
  }, [props.clearReplyTemplate]);

  if (props.error?.error === CustomError.NotFound) {
    return <NotFound />;
  }

  if (props.getLoading || !props.template) {
    return <Spin />;
  }

  const initialValues: ReplyTemplateAddForm = {
    meoSiteId: props.meoSite.id,
    name: props.template?.name || "",
    content: props.template?.content || "",
  };

  const handleSubmit = async (form: ReplyTemplateAddForm) => {
    await props.editReplyTemplate(id, form);
    pushTo(history, `/${Page.replyTemplates}`, {});
  };

  const handleDelete = () => {
    props.deleteReplyTemplate(id);
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={templateAddFormSchema}
      >
        {(_formikBag) => {
          return (
            <Form {...formItemLayout}>
              <Form.Item name="name" label={i18n.t("templates.name")}>
                <Input name="name" />
              </Form.Item>
              <Form.Item name="content" label={i18n.t("templates.content")}>
                <Input.TextArea
                  className="message"
                  name="content"
                  autoSize={{ minRows: 3 }}
                />
              </Form.Item>
              <Row className="form-button-row">
                <SaveButton loading={props.editLoading} />
                <BackButton />
                <Popconfirm
                  title={i18n.t("replyTemplates.deleteConfirm")}
                  onConfirm={handleDelete}
                  okText={i18n.t("common.delete")}
                  cancelText={i18n.t("common.cancel")}
                  okType="danger"
                >
                  <DeleteButton loading={props.deleteLoading} />
                </Popconfirm>
              </Row>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

const mapStateToProps = (root: RootState) => {
  return {
    template: root.replyTemplates.item,
    getLoading: root.replyTemplates.getLoading,
    editLoading: root.replyTemplates.editLoading,
    deleteLoading: root.replyTemplates.deleteLoading,
    deleteFinished: root.replyTemplates.deleteFinished,
    error: root.replyTemplates.error,
    meoSite: root.seo.meoSite!,
  };
};
const mapDispatchToProps = {
  getReplyTemplate,
  editReplyTemplate,
  deleteReplyTemplate,
  clearReplyTemplate,
  setPage,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReplyTemplateEdit);
