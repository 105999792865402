import React from "react";
import { connect } from "react-redux";
import { pushTo } from "utils/navigation";
import { Address, AddressAddForm, MEOSite } from "api/sms-api";
import { getAddress, editAddress, deleteAddress } from "./AddressActions";
import { clearAddress } from "./AddressSlice";
import { RootState } from "store/store";
import { Spin, Row, Popconfirm } from "antd";
import { CustomError } from "errors/errors";
import { Formik } from "formik";
import { Form, Input } from "formik-antd";
import i18n from "i18n";
import { addressAddFormSchema } from "validation";
import { formItemLayout } from "../../constants";
import { useHistory, useParams } from "react-router-dom";
import NotFound from "components/NotFound";
import { transformPhoneNumber } from "utils/phone_number";
import BackButton from "components/BackButton";
import SaveButton from "components/SaveButton";
import DeleteButton from "components/DeleteButton";
import { Page } from "features/appstate/AppStateSlice";

interface AddressEditProps {
  getLoading: boolean;
  editLoading: boolean;
  editFinished: boolean;
  deleteLoading: boolean;
  deleteFinished: boolean;
  address?: Address;
  error?: any;
  getAddress: (id: string) => void;
  deleteAddress: (id: string) => void;
  editAddress: (id: string, form: AddressAddForm) => Promise<void>;
  clearAddress: () => void;
  meoSite: MEOSite;
}

const AddressEdit: React.FC<AddressEditProps> = (props) => {
  const history = useHistory();
  const { id } = useParams() as any;

  React.useEffect(() => {
    props.getAddress(id);
  }, [props.getAddress, id]);

  React.useEffect(() => {
    if (props.deleteFinished || props.editFinished) {
      pushTo(history, `/${Page.addresses}`, {});
    }
  }, [props.deleteFinished, props.editFinished]);

  React.useEffect(() => {
    // Run on unmount
    return () => {
      props.clearAddress();
    };
  }, [props.clearAddress]);

  if (props.error?.error === CustomError.NotFound) {
    return <NotFound />;
  }

  if (props.getLoading || !props.address) {
    return <Spin />;
  }

  const initialValues: AddressAddForm = {
    meoSiteId: props.meoSite.id,
    name: props.address?.name || "",
    phoneNumber: props.address?.phoneNumber || "",
  };

  const handleSubmit = async (form: AddressAddForm) => {
    form.phoneNumber = transformPhoneNumber(form.phoneNumber);
    await props.editAddress(id, form);
  };

  const handleDelete = () => {
    props.deleteAddress(id);
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={addressAddFormSchema}
      >
        {(_formikBag) => {
          return (
            <Form {...formItemLayout}>
              <Form.Item name="name" label={i18n.t("common.name")}>
                <Input
                  name="name"
                  placeholder={i18n.t("addresses.namePlaceholder")}
                />
              </Form.Item>
              <Form.Item
                name="phoneNumber"
                label={i18n.t("common.phoneNumber")}
              >
                <Input name="phoneNumber" />
              </Form.Item>
              <Row className="form-button-row">
                <SaveButton loading={props.editLoading} />
                <BackButton />
                <Popconfirm
                  title={i18n.t("addresses.deleteConfirm")}
                  onConfirm={handleDelete}
                  okText={i18n.t("common.delete")}
                  cancelText={i18n.t("common.cancel")}
                  okType="danger"
                >
                  <DeleteButton loading={props.deleteLoading} />
                </Popconfirm>
              </Row>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

const mapStateToProps = (root: RootState) => {
  return {
    address: root.addresses.item,
    getLoading: root.addresses.getLoading,
    editLoading: root.addresses.editLoading,
    editFinished: root.addresses.editFinished,
    deleteLoading: root.addresses.deleteLoading,
    deleteFinished: root.addresses.deleteFinished,
    error: root.addresses.error,
    meoSite: root.seo.meoSite!,
  };
};
const mapDispatchToProps = {
  getAddress,
  editAddress,
  deleteAddress,
  clearAddress,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddressEdit);
