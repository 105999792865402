import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LandingPageSettings, LandingData, Paginated } from "api/sms-api";
import { CRUDState, initialCRUDState } from "store/CRUDStore";
import { ErrorWrapper } from "errors/errors";

export interface LandingPageSettingsState
  extends CRUDState<LandingPageSettings> {
  previewData?: LandingData;
  fileUploading: boolean;
  getPreviewLoading: boolean;
}

const initialState: LandingPageSettingsState = {
  ...initialCRUDState,
  fileUploading: false,
  getPreviewLoading: false,
};

export const slice = createSlice({
  name: "landingPageSettings",
  initialState,
  reducers: {
    getSettingsStart: (state) => {
      state.getLoading = true;
    },
    getSettingsSuccess: (state, action: PayloadAction<LandingPageSettings>) => {
      state.item = action.payload;
      state.getLoading = false;
    },
    getSettingsError: (state) => {
      state.getLoading = false;
    },
    listSettingsStart: (state) => {
      state.listLoading = true;
    },
    listSettingsSuccess: (
      state,
      action: PayloadAction<Paginated<LandingPageSettings>>
    ) => {
      state.items = action.payload;
      state.listLoading = false;
    },
    listSettingsError: (state) => {
      state.listLoading = false;
    },
    editSettingsStart: (state) => {
      state.editLoading = true;
    },
    editSettingsSuccess: (state) => {
      state.editLoading = false;
    },
    editSettingsError: (state, action: PayloadAction<ErrorWrapper>) => {
      state.editLoading = false;
      state.error = action.payload;
    },
    fileUploadStart: (state) => {
      state.fileUploading = true;
    },
    fileUploadSuccess: (state) => {
      state.fileUploading = false;
    },
    fileUploadError: (state) => {
      state.fileUploading = false;
    },
    getPreviewDataStart: (state) => {
      state.getPreviewLoading = true;
    },
    getPreviewDataSuccess: (state, action: PayloadAction<LandingData>) => {
      state.previewData = action.payload;
      state.getPreviewLoading = false;
    },
    getPreviewDataError: (state) => {
      state.getPreviewLoading = false;
    },
    deleteSettingsStart: (state) => {
      state.deleteLoading = true;
    },
    deleteSettingsSuccess: (state) => {
      state.deleteLoading = false;
      state.deleteFinished = true;
    },
    deleteSettingsError: (state) => {
      state.deleteLoading = false;
    },
    addSettingsStart: (state) => {
      state.createLoading = true;
    },
    addSettingsSuccess: (state) => {
      state.createLoading = false;
    },
    addSettingsError: (state, action: PayloadAction<ErrorWrapper>) => {
      state.createLoading = false;
      state.error = action.payload;
    },
    clearLandingPageSettings: (state) => {
      state.item = undefined;
      state.error = undefined;
    },
    clearItems: (state) => {
      state.items = { items: [], hasRight: false, hasLeft: false };
    },
  },
});

export const {
  listSettingsStart,
  listSettingsSuccess,
  listSettingsError,
  getSettingsStart,
  getSettingsSuccess,
  getSettingsError,
  editSettingsStart,
  editSettingsSuccess,
  editSettingsError,
  fileUploadStart,
  fileUploadSuccess,
  fileUploadError,
  getPreviewDataStart,
  getPreviewDataSuccess,
  getPreviewDataError,
  deleteSettingsStart,
  deleteSettingsSuccess,
  deleteSettingsError,
  addSettingsStart,
  addSettingsSuccess,
  addSettingsError,
  clearLandingPageSettings,
  clearItems,
} = slice.actions;

export default slice.reducer;
