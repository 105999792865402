import React, { ChangeEvent } from "react";
import { connect } from "react-redux";
import { pushTo } from "utils/navigation";
import {
  Template,
  TemplateAddForm,
  MEOSite,
  MessageStats,
  MessageStatsForm,
} from "api/sms-api";
import {
  getTemplate,
  editTemplate,
  deleteTemplate,
  getMessageStats,
} from "./TemplatesActions";
import {
  clearTemplate,
  TemplateSavedForm,
  setSavedForm,
} from "./TemplatesSlice";
import { RootState } from "store/store";
import { Page, setPage } from "features/appstate/AppStateSlice";
import { Spin, Row, Popconfirm, Col, Button } from "antd";
import { CustomError, ErrorWrapper } from "errors/errors";
import { Formik } from "formik";
import { Form, Input } from "formik-antd";
import i18n from "i18n";
import { templateAddFormSchema } from "validation";
import { formItemLayout } from "../../constants";
import { useHistory, useParams, useLocation } from "react-router-dom";
import NotFound from "components/NotFound";
import BackButton from "components/BackButton";
import SaveButton from "components/SaveButton";
import DeleteButton from "components/DeleteButton";
import qs from "qs";
import { convertContent } from "utils/strings";

interface TemplateEditProps {
  getLoading: boolean;
  editLoading: boolean;
  deleteLoading: boolean;
  deleteFinished: boolean;
  template?: Template;
  error?: ErrorWrapper;
  getTemplate: (id: string, forShortcut: boolean) => void;
  deleteTemplate: (id: string) => void;
  editTemplate: (id: string, form: TemplateAddForm) => Promise<void>;
  setPage: (page: Page) => void;
  clearTemplate: () => void;
  meoSite: MEOSite;
  savedForm?: TemplateAddForm;
  setSavedForm: (form: TemplateSavedForm) => void;

  messageStats?: MessageStats;
  messageStatsLoading: boolean;
  getMessageStats: (form: MessageStatsForm) => void;
}

const TemplateEdit: React.FC<TemplateEditProps> = (props) => {
  const location = useLocation();
  const history = useHistory();
  const parsed = qs.parse(location.search, { ignoreQueryPrefix: true });
  const { id } = useParams() as any;
  const [cursorPosition, setCursorPosition] = React.useState(0);
  const debounceWait = 500;
  const [
    timeoutHandler,
    setTimeoutHandler,
  ] = React.useState<NodeJS.Timeout | null>(null);

  React.useEffect(() => {
    props.setPage(Page.templates);
  }, [props.setPage]);

  React.useEffect(() => {
    props.getTemplate(id, false);
  }, [props.getTemplate, id]);

  React.useEffect(() => {
    if (props.deleteFinished) {
      pushTo(history, "/templates", {});
    }
  }, [props.deleteFinished]);

  React.useEffect(() => {
    return () => {
      props.clearTemplate();
    };
  }, [props.clearTemplate]);

  React.useEffect(() => {
    if (props.template) {
      props.getMessageStats({
        message: props.template.content,
        contacts: [{ phoneNumber: "+819000000000", name: "dummy" }],
        groups: [],
      });
    }
  }, [props.getMessageStats, props.template]);

  if (props.error?.error === CustomError.NotFound) {
    return <NotFound />;
  }

  if (props.getLoading || !props.template) {
    return <Spin />;
  }

  let initialValues: TemplateAddForm = {
    meoSiteId: props.meoSite.id,
    name: props.template?.name || "",
    content: props.template?.content || "",
  };

  if (props.savedForm && parsed.shortcutSelected === "landing_page") {
    initialValues = props.savedForm;
  }

  // props.getMessageStats({message: initialValues.content, numberOfContacts: 1});

  const handleSubmit = async (form: TemplateAddForm) => {
    await props.editTemplate(id, form);
    pushTo(history, `/${Page.templates}`, {});
  };

  const handleDelete = () => {
    props.deleteTemplate(id);
  };

  const handleMessageChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const message = convertContent(event.target.value);
    if (timeoutHandler) {
      clearTimeout(timeoutHandler);
    }

    setTimeoutHandler(
      setTimeout(() => {
        props.getMessageStats({
          message: message,
          contacts: [{ phoneNumber: "+819000000000", name: "dummy" }],
          groups: [],
        });
      }, debounceWait)
    );
  };

  const goToSelectLandingPageSettings = (form: TemplateAddForm) => {
    props.setSavedForm({
      ...form,
      cursorPosition,
    });
    pushTo(history, `/${Page.landingPageSettings}`, {
      selecting: `/${Page.templates}/${id}`,
    });
  };

  const handleBlur = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setCursorPosition(event.target.selectionStart);
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={templateAddFormSchema}
      >
        {(formikBag) => {
          return (
            <Form {...formItemLayout}>
              <Form.Item name="name" label={i18n.t("templates.name")}>
                <Input
                  name="name"
                  disabled={props.template?.type === "gyron"}
                />
              </Form.Item>
              <Form.Item name="content" label={i18n.t("templates.content")}>
                <Input.TextArea
                  className="message"
                  name="content"
                  placeholder={i18n.t("templates.contentPlaceholder")}
                  onBlur={handleBlur}
                  autoSize={{ minRows: 3 }}
                  disabled={props.template?.type === "gyron"}
                  onChange={handleMessageChange}
                />
              </Form.Item>
              <Row className="ant-form-item">
                <Col {...formItemLayout.labelCol}></Col>
                <Button
                  className="landing-page-shortcut max-width-on-mobile"
                  onClick={() =>
                    goToSelectLandingPageSettings(formikBag.values)
                  }
                >
                  {i18n.t("messageForm.selectLandingPage")}
                </Button>
              </Row>
              {props.messageStatsLoading && (
                <Row>
                  <Col {...formItemLayout.labelCol} />
                  <Col {...formItemLayout.wrapperCol}>
                    <Spin />
                  </Col>
                </Row>
              )}
              {!props.messageStatsLoading &&
                props.messageStats &&
                props.messageStats.numberOfParts > 0 && (
                  <Row>
                    <Col {...formItemLayout.labelCol} />
                    <Col {...formItemLayout.wrapperCol}>
                      {i18n.t("messageForm.multiMessageNote", {
                        parts: props.messageStats.numberOfParts,
                      })}
                    </Col>
                  </Row>
                )}
              {props.template?.type === "store" && (
                <Row className="form-button-row">
                  <SaveButton
                    loading={props.editLoading || props.messageStatsLoading}
                  />
                  <BackButton />
                  <Popconfirm
                    title={i18n.t("templates.deleteConfirm")}
                    onConfirm={handleDelete}
                    okText={i18n.t("common.delete")}
                    cancelText={i18n.t("common.cancel")}
                    okType="danger"
                  >
                    <DeleteButton loading={props.deleteLoading} />
                  </Popconfirm>
                </Row>
              )}
              {props.template?.type === "gyron" && (
                <Row className="form-button-row">
                  <BackButton />
                </Row>
              )}
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

const mapStateToProps = (root: RootState) => {
  return {
    template: root.templates.item,
    getLoading: root.templates.getLoading,
    editLoading: root.templates.editLoading,
    deleteLoading: root.templates.deleteLoading,
    deleteFinished: root.templates.deleteFinished,
    error: root.templates.error,
    meoSite: root.seo.meoSite!,
    savedForm: root.templates.savedForm,
    messageStats: root.templates.messageStats,
    messageStatsLoading: root.templates.messageStatsLoading,
  };
};
const mapDispatchToProps = {
  getTemplate,
  editTemplate,
  deleteTemplate,
  clearTemplate,
  setPage,
  setSavedForm,
  getMessageStats,
};

export default connect(mapStateToProps, mapDispatchToProps)(TemplateEdit);
