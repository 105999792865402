import { AppDispatch, AppThunk, AsyncAppThunk } from "store/store";
import { httpWrapper } from "../../utils/http";
import { SMS_API_BASE, PAGE_LIMIT } from "../../constants";
import { handleError } from "errors/errors";
import {
  listMailTemplateSenderStart,
  listMailTemplateSenderSuccess,
  listMailTemplateSenderError,
  listAllMailTemplateSenderStart,
  listAllMailTemplateSenderSuccess,
  listAllMailTemplateSenderError,
  addMailTemplateSenderStart,
  addMailTemplateSenderSuccess,
  addMailTemplateSenderError,
  editMailTemplateSenderStart,
  editMailTemplateSenderSuccess,
  editMailTemplateSenderError,
  getMailTemplateSenderStart,
  getMailTemplateSenderSuccess,
  getMailTemplateSenderError,
  deleteMailTemplateSenderStart,
  deleteMailTemplateSenderSuccess,
  deleteMailTemplateSenderError,
} from "./MailTemplateSenderSlice";
import {
  MailTemplateSender,
  MailTemplateSenderAddForm,
  MailTemplateSenderDeleteForm,
  MailTemplateSenderUpdateForm,
  Paginated,
  PaginationParams,
} from "../../api/sms-api";
import i18n from "i18n";
import { showNotification } from "utils/notifications";
import _ from "lodash";

export const listMailTemplateSenders = (
  params: PaginationParams
): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(listMailTemplateSenderStart());
  try {
    const mailTemplateSenders = await httpWrapper<
      Paginated<MailTemplateSender>
    >("GET", `${SMS_API_BASE}/mail_template_sender/list`, params);
    dispatch(listMailTemplateSenderSuccess(mailTemplateSenders));
  } catch (e) {
    handleError(e);
    dispatch(listMailTemplateSenderError(e));
  }
};

export const listAllMailTemplateSenders = (): AppThunk => async (
  dispatch: AppDispatch
) => {
  dispatch(listAllMailTemplateSenderStart());
  try {
    const mailTemplateSenders = await httpWrapper<Array<MailTemplateSender>>(
      "GET",
      `${SMS_API_BASE}/mail_template_sender/list_all`,
      {}
    );
    dispatch(listAllMailTemplateSenderSuccess(mailTemplateSenders));
  } catch (e) {
    handleError(e);
    dispatch(listAllMailTemplateSenderError(e));
  }
};

export const addMailTemplateSender = (
  params: MailTemplateSenderAddForm,
  existingParams: any
): AsyncAppThunk<void> => async (dispatch: AppDispatch) => {
  dispatch(addMailTemplateSenderStart());
  try {
    await httpWrapper(
      "POST",
      `${SMS_API_BASE}/mail_template_sender/add`,
      params
    );

    showNotification(i18n.t("mailTemplateSender.createSuccess"));
    dispatch(addMailTemplateSenderSuccess());
    // Reload the list of mail template senders
    const getParams = {
      ..._.omit(existingParams, ["before", "after"]),
    };
    dispatch(listMailTemplateSenders({ ...getParams, limit: PAGE_LIMIT }));
  } catch (e) {
    handleError(e);
    dispatch(addMailTemplateSenderError(e));
  }
};

export const editMailTemplateSender = (
  id: string,
  params: MailTemplateSenderUpdateForm
): AsyncAppThunk<void> => async (dispatch: AppDispatch) => {
  dispatch(editMailTemplateSenderStart());
  try {
    await httpWrapper(
      "PATCH",
      `${SMS_API_BASE}/mail_template_sender/update/${id}`,
      params
    );

    showNotification(i18n.t("mailTemplateSender.editSuccess"));
    dispatch(editMailTemplateSenderSuccess());
  } catch (e) {
    handleError(e);
    dispatch(editMailTemplateSenderError(e));
  }
};

export const getMailTemplateSender = (id: string): AppThunk => async (
  dispatch: AppDispatch
) => {
  dispatch(getMailTemplateSenderStart());
  try {
    const MailTemplateSender = await httpWrapper<MailTemplateSender>(
      "GET",
      `${SMS_API_BASE}/mail_template_sender/${id}`,
      {}
    );
    dispatch(getMailTemplateSenderSuccess(MailTemplateSender));
  } catch (e) {
    handleError(e);
    dispatch(getMailTemplateSenderError(e));
  }
};

export const deleteMailTemplateSenders = (
  params: MailTemplateSenderDeleteForm
): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(deleteMailTemplateSenderStart());
  try {
    await httpWrapper(
      "POST",
      `${SMS_API_BASE}/mail_template_sender/delete`,
      params
    );
    showNotification(i18n.t("mailTemplateSender.deleteSuccess"));
    dispatch(deleteMailTemplateSenderSuccess());
  } catch (e) {
    handleError(e);
    dispatch(deleteMailTemplateSenderError(e));
  }
};
