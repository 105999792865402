import { ReviewLinkColor } from "api/sms-api";
import React from "react";

interface ColorOptionProps {
  color: ReviewLinkColor;
}

const ColorOption: React.FC<ColorOptionProps> = (props) => {
  // Make the text the same color as the background, to hide it (we want to
  // have some text so that the layout works)
  const style = {
    background: props.color.hexCode,
    color: props.color.hexCode,
  };
  return (
    <div className="color-sample" style={style}>
      {props.color.hexCode}
    </div>
  );
};

export default ColorOption;
