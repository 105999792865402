import React, { useEffect, useState } from "react";
import { RootState } from "store/store";
import { connect } from "react-redux";
import i18n from "i18n";
import { Formik } from "formik";
import { Form, Input, Radio, Select } from "formik-antd";
import { Spin, Row, Col, Button } from "antd";
import {
  MailTemplateWithSender,
  MailTemplateSender,
  MailTemplateAddForm,
  MailTemplateContentType,
  ClientEcLogoDetails,
} from "api/sms-api";
import {
  addMailTemplate,
  editMailTemplate,
  getMailTemplate,
} from "./MailTemplateActions";
import { useHistory } from "react-router-dom";
import { pushTo } from "utils/navigation";
import qs from "qs";
import { Page, setPage } from "features/appstate/AppStateSlice";
import { listAllMailTemplateSenders } from "features/mail_template_sender/MailTemplateSenderActions";
import { emailIsInvalid, replaceStoreLogoInserts } from "utils/strings";
import {
  MODE_SURVEY,
  surveyTypeStore,
  mailTemplateTagItems,
} from "../../constants";
import RichEditor from "components/RichEditor";
import SaveButton from "components/SaveButton";
import OptoutPageTemplatePreviewDialog from "features/optout_page_template/OptoutPageTemplatePreviewDialog";
import MailTemplateSendTestEmailDialog from "./MailTemplateSendTestEmailDialog";
import { getClientEcLogoDetails } from "features/contract_details/ContractDetailsActions";

const defaultTestSenderEmail = "support_desk@gyro-n.com";

interface MailTemplateAddProps {
  setPage: (page: Page) => void;
  getLoading: boolean;
  mailTemplate?: MailTemplateWithSender;
  addMailTemplate: (form: MailTemplateAddForm) => void;
  editMailTemplate: (id: string, form: MailTemplateAddForm) => void;
  getMailTemplate: (id: string) => void;
  mailTemplateSenders: Array<MailTemplateSender>;
  listAllMailTemplateSenders: () => void;
  listAllLoading: boolean;
  clientEcLogoDetails?: ClientEcLogoDetails;
  getClientEcLogoDetails: () => void;
  getClientEcLogoDetailsLoading: boolean;
}

const MailTemplateAdd: React.FC<MailTemplateAddProps> = (props) => {
  const history = useHistory();
  const parsed = qs.parse(location.search, { ignoreQueryPrefix: true });
  const mailTemplateId = parsed.id ? parsed.id.toString() : "";
  const type = parsed.type ? parsed.type.toString() : "";
  const surveyType = parsed.surveyType
    ? (parsed.surveyType as string)
    : surveyTypeStore;
  const isNewMode = type === "new" && !mailTemplateId;
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  const [isSendTestEmailModalOpen, setIsSendTestEmailModalOpen] = useState(
    false
  );

  useEffect(() => {
    props.setPage(Page.mailTemplates);
  }, [props.setPage]);

  useEffect(() => {
    if (mailTemplateId) {
      props.getMailTemplate(mailTemplateId);
    }
  }, [props.getMailTemplate]);

  useEffect(() => {
    props.listAllMailTemplateSenders();
  }, [props.listAllMailTemplateSenders]);

  useEffect(() => {
    if (!props.getClientEcLogoDetailsLoading) {
      props.getClientEcLogoDetails();
    }
  }, [props.getClientEcLogoDetails]);

  if (props.getLoading || props.listAllLoading) {
    return <Spin />;
  }

  const handleSubmit = async (form: MailTemplateAddForm) => {
    const targetRoute = "/mailTemplates";
    if (isNewMode) {
      await props.addMailTemplate(form);
    } else {
      await props.editMailTemplate(mailTemplateId, form);
    }
    pushTo(history, targetRoute, {
      mode: MODE_SURVEY,
      surveyType,
    });
  };

  const closePreviewModal = () => {
    setIsPreviewModalOpen(false);
  };

  const handleSentTestEmail = async () => {
    setIsSendTestEmailModalOpen(true);
  };

  const closeSendTestEmailModal = () => {
    setIsSendTestEmailModalOpen(false);
  };

  const initialValues = {
    name: (!isNewMode && props.mailTemplate && props.mailTemplate.title) || "",
    mailTemplateSenderId:
      (!isNewMode &&
        props.mailTemplate &&
        props.mailTemplate.mailTemplateSender.id) ||
      "",
    bcc: (!isNewMode && props.mailTemplate && props.mailTemplate.bcc) || "",
    title: (!isNewMode && props.mailTemplate && props.mailTemplate.title) || "",
    contentType:
      (!isNewMode && props.mailTemplate && props.mailTemplate.contentType) ||
      MailTemplateContentType.Text,
    contents:
      (!isNewMode && props.mailTemplate && props.mailTemplate.contents) || "",
  };

  const previewButton: any = {
    name: i18n.t("mailTemplate.optoutPageTemplate.preview"),
    icon: "eye",
    exec: function () {
      setIsPreviewModalOpen(true);
    },
  };

  const processContent = (content: string): string => {
    return replaceStoreLogoInserts(
      content,
      mailTemplateTagItems[i18n.t("mailTemplate.tags.storeLogo")],
      props.clientEcLogoDetails?.storeLogoUrl || ""
    );
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {(formikBag) => {
        const sender:
          | MailTemplateSender
          | undefined = props.mailTemplateSenders.find(
          (sender) => sender.id === formikBag.values.mailTemplateSenderId
        );
        const senderEmailAddress: string =
          (sender && sender.senderEmail) || defaultTestSenderEmail;
        return (
          <Form layout="vertical">
            <Row justify="center">
              <Col span={18}>
                <Form.Item
                  name="name"
                  label={i18n.t("mailTemplate.table.title.name")}
                >
                  <Input name="name" />
                </Form.Item>
                <Form.Item
                  name="mailTemplateSenderId"
                  label={i18n.t("mailTemplate.table.title.from")}
                  validate={(value: any) => {
                    if (value) {
                      return undefined;
                    }
                    return i18n.t("validation.required");
                  }}
                >
                  <Select
                    name="mailTemplateSenderId"
                    defaultValue={formikBag.values.mailTemplateSenderId}
                    placeholder={i18n.t(
                      "mailTemplate.editMailTemplateSenderPlaceholder"
                    )}
                    options={props.mailTemplateSenders
                      .map((item) => {
                        return {
                          value: item.id,
                          label: item.name,
                        };
                      })
                      .sort((a, b) => a.label.localeCompare(b.label))}
                  />
                </Form.Item>
                <Form.Item
                  name="bcc"
                  label={i18n.t("mailTemplate.table.title.bcc")}
                  className="input-mail-form"
                  validate={(value: any) => {
                    if (value && !emailIsInvalid([value])) {
                      return undefined;
                    }
                    return i18n.t("validation.mail");
                  }}
                >
                  <Input name="bcc" />
                </Form.Item>
                <Form.Item
                  name="title"
                  label={i18n.t("mailTemplate.table.title.title")}
                >
                  <Input name="title" />
                </Form.Item>
                <Form.Item
                  name="contentType"
                  label={i18n.t("mailTemplate.table.title.contentType")}
                >
                  <Radio.Group
                    name="contentType"
                    className="form-radio-group"
                    defaultValue={initialValues.contentType}
                  >
                    <Radio.Button value={MailTemplateContentType.Text}>
                      {i18n.t("mailTemplateContentTypes.text")}
                    </Radio.Button>
                    <Radio.Button value={MailTemplateContentType.Html}>
                      {i18n.t("mailTemplateContentTypes.html")}
                    </Radio.Button>
                  </Radio.Group>
                </Form.Item>
                <Form.Item
                  name="contents"
                  label={i18n.t("mailTemplate.table.title.contents")}
                >
                  <RichEditor
                    hideButtonList={["preview"]}
                    customButtons={[previewButton]}
                    tagList={mailTemplateTagItems}
                    value={formikBag.values.contents || ""}
                    onChange={(value) => {
                      formikBag.setFieldValue("contents", value);
                    }}
                  />
                </Form.Item>
                <OptoutPageTemplatePreviewDialog
                  content={processContent(formikBag.values.contents || "")}
                  visible={isPreviewModalOpen}
                  closeModal={closePreviewModal}
                />
                <MailTemplateSendTestEmailDialog
                  bcc={formikBag.values.bcc}
                  title={formikBag.values.title}
                  senderEmailAddress={senderEmailAddress}
                  contents={formikBag.values.contents || ""}
                  visible={isSendTestEmailModalOpen}
                  closeModal={closeSendTestEmailModal}
                />
              </Col>
            </Row>
            <Row justify="end" style={{ marginTop: "2.5em" }}>
              <Button
                type="default"
                className="mail-template-test-button"
                onClick={handleSentTestEmail}
              >
                {i18n.t("mailTemplate.testEmail.button")}
              </Button>
              <SaveButton />
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    mailTemplate: state.mailTemplates.item,
    getLoading: state.mailTemplates.getLoading,
    listAllLoading: state.mailTemplateSender.listAllLoading,
    mailTemplateSenders: state.mailTemplateSender.allItems,
    getClientEcLogoDetailsLoading:
      state.contractDetails.getClientEcLogoDetailsLoading,
    clientEcLogoDetails: state.contractDetails.clientEcLogoDetails,
  };
};

const mapDispatchToProps = {
  setPage,
  addMailTemplate,
  editMailTemplate,
  getMailTemplate,
  listAllMailTemplateSenders,
  getClientEcLogoDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(MailTemplateAdd);
