import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MEOSite, MEOSites } from "api/sms-api";
import { ErrorWrapper } from "errors/errors";

interface SEOState {
  meoSite?: MEOSite;
  meoSites: Array<MEOSite>;
  getLoading: boolean;
  listLoading: boolean;
  error?: ErrorWrapper;
}

const initialState: SEOState = {
  meoSites: [],
  getLoading: false,
  listLoading: false,
};

export const slice = createSlice({
  name: "seo",
  initialState,
  reducers: {
    getMEOSiteStart: (state) => {
      state.getLoading = true;
    },
    getMEOSiteSuccess: (state, action: PayloadAction<MEOSite>) => {
      state.meoSite = action.payload;
      state.getLoading = false;
      state.error = undefined;
    },
    getMEOSiteError: (state, action: PayloadAction<ErrorWrapper>) => {
      state.getLoading = false;
      state.error = action.payload;
    },
    listMEOSitesStart: (state) => {
      state.listLoading = true;
    },
    listMEOSitesSuccess: (state, action: PayloadAction<MEOSites>) => {
      state.meoSites = action.payload.items;
      state.listLoading = false;
      state.error = undefined;
    },
    listMEOSitesError: (state, action: PayloadAction<ErrorWrapper>) => {
      state.listLoading = false;
      state.error = action.payload;
    },
    setMEOSite: (state, action: PayloadAction<MEOSite>) => {
      state.meoSite = action.payload;
    },
  },
});

export const {
  getMEOSiteStart,
  getMEOSiteSuccess,
  getMEOSiteError,
  listMEOSitesStart,
  listMEOSitesSuccess,
  listMEOSitesError,
  setMEOSite,
} = slice.actions;

export default slice.reducer;
