import React, { ChangeEvent } from "react";
import { connect } from "react-redux";
import { pushTo } from "utils/navigation";
import {
  ScheduledEditForm,
  FullScheduledWithParts,
  MessageStats,
  MessageStatsForm,
  MEOSite,
  PublishType,
  UsageStatistics,
} from "api/sms-api";
import {
  getScheduled,
  editScheduled,
  deleteScheduled,
} from "./ScheduleActions";
import { clearScheduled, setSavedForm, clearSavedForm } from "./ScheduleSlice";
import { RootState } from "store/store";
import { Page, setPage } from "features/appstate/AppStateSlice";
import { Spin, Row, Popconfirm, Col, message } from "antd";
import { CustomError, ErrorWrapper } from "errors/errors";
import { Formik, FormikProps, FormikHelpers } from "formik";
import { Form, Input } from "formik-antd";
import i18n from "i18n";
import { useHistory, useParams, useLocation } from "react-router-dom";
import ScheduledTimePicker from "components/ScheduledTimePicker";
import NotFound from "components/NotFound";
import { modifiedScheduledEditFormSchema } from "validation";
import { mobileMediaQuery, formItemLayout } from "../../constants";
import MobileTimePicker from "components/MobileTimePicker";
import moment from "moment";
import Media from "react-media";
import PhoneNumberInput, {
  FormWithRecipients,
  handleSendErrors,
} from "components/PhoneNumberInput";
import { getMessageStats } from "features/publish/PublishActions";
import { getUsageStatistics } from "features/client/ClientActions";
import {
  duplicatePhoneNumberCheck,
  transformPhoneNumberList,
  transformContactList,
  transformGroupList,
} from "utils/phone_number";
import { convertContent } from "utils/strings";
import BackButton from "components/BackButton";
import SaveButton from "components/SaveButton";
import DeleteButton from "components/DeleteButton";
import qs from "qs";

interface ScheduledEditProps {
  getLoading: boolean;
  editLoading: boolean;
  deleteLoading: boolean;
  deleteFinished: boolean;
  scheduled?: FullScheduledWithParts;
  error?: ErrorWrapper;
  getScheduled: (id: string) => void;
  deleteScheduled: (id: string) => void;
  editScheduled: (
    id: string,
    form: ScheduledEditForm
  ) => Promise<void | ErrorWrapper>;
  setPage: (page: Page) => void;
  clearScheduled: () => void;
  messageStats?: MessageStats;
  messageStatsLoading: boolean;
  getMessageStats: (form: MessageStatsForm) => void;
  getUsageStatistics: (meoSiteId: number) => void;
  meoSite: MEOSite;
  savedForm?: ModifiedScheduledEditForm;
  setSavedForm: (form: ModifiedScheduledEditForm) => void;
  clearSavedForm: () => void;
  statistics?: UsageStatistics;
  statisticsLoading: boolean;
}

// See SentForm.tsx -> ModifiedPublishForm, this is the same idea
export interface ModifiedScheduledEditForm extends FormWithRecipients {
  message: string;
  sendUserId: string;
  scheduledTime: string;
}

const ScheduledEdit: React.FC<ScheduledEditProps> = (props) => {
  const location = useLocation();
  const history = useHistory();
  const { id } = useParams() as any;
  const parsed = qs.parse(location.search, { ignoreQueryPrefix: true });

  React.useEffect(() => {
    props.setPage(Page.scheduled);
  }, [props.setPage]);

  React.useEffect(() => {
    props.getScheduled(id);
  }, [props.getScheduled, id]);

  React.useEffect(() => {
    if (props.deleteFinished) {
      pushTo(history, `/${Page.scheduled}`, {});
    }
  }, [props.deleteFinished]);

  React.useEffect(() => {
    // Run on unmount
    return () => {
      props.clearScheduled();
    };
  }, [props.clearScheduled]);

  // Clear the message stats on load if no saved form, or get the stats if
  // there is a saved form
  React.useEffect(() => {
    props.getMessageStats({
      message: props.savedForm
        ? convertContent(props.savedForm.message)
        : props.scheduled
        ? convertContent(props.scheduled.fullScheduled.scheduled.message)
        : "",
      contacts: props.savedForm
        ? transformContactList(props.savedForm.contacts)
        : props.scheduled
        ? props.scheduled.fullScheduled.contacts
        : [],
      groups: props.savedForm
        ? transformGroupList(props.savedForm.groups)
        : props.scheduled
        ? props.scheduled.fullScheduled.groups
        : [],
    });
  }, [props.getMessageStats, props.savedForm]);

  const debounceWait = 500;
  const [
    timeoutHandler,
    setTimeoutHandler,
  ] = React.useState<NodeJS.Timeout | null>(null);

  if (props.error?.error === CustomError.NotFound) {
    return <NotFound />;
  }

  if (props.getLoading || !props.scheduled) {
    return <Spin />;
  }

  let initialValues: ModifiedScheduledEditForm = {
    contacts: props.scheduled?.fullScheduled.contacts || [
      { phoneNumber: "", name: "" },
    ],
    message: props.scheduled?.fullScheduled.scheduled.message || "",
    sendUserId: props.scheduled?.fullScheduled.scheduled.sendUserId || "",
    scheduledTime: props.scheduled?.fullScheduled.scheduled.scheduledTime || "",
    type: PublishType.Cloud,
    groups: props.scheduled?.fullScheduled.groups || [{ id: "", name: "" }],
  };

  if (props.savedForm && parsed.shortcutSelected === "back") {
    // When returning from selecting Addresses, remove the default blank
    // contact in the form if present.
    const modifiedSavedForm = {
      ...props.savedForm,
      contacts: props.savedForm.contacts.filter((c) => c.phoneNumber !== ""),
      groups: props.savedForm.groups.filter((g) => g.id !== ""),
    };

    // If this results in 0 contacts (i.e. they removed all of them), add the blank one back in
    if (
      modifiedSavedForm.contacts.length === 0 &&
      modifiedSavedForm.groups.length === 0
    ) {
      modifiedSavedForm.contacts = [
        {
          name: "",
          phoneNumber: "",
        },
      ];
    }

    initialValues = modifiedSavedForm;
  }

  const handleSubmit = async (
    form: ModifiedScheduledEditForm,
    formikBag: FormikHelpers<ModifiedScheduledEditForm>
  ) => {
    form.contacts = transformPhoneNumberList(form.contacts);
    if (!duplicatePhoneNumberCheck(form.contacts)) {
      message.warn(i18n.t("messageForm.duplicatePhoneNumber"));
    } else {
      const result = await props.editScheduled(id, form);
      handleSendErrors(result, form, formikBag);
    }
  };

  const handleDelete = () => {
    props.deleteScheduled(id);
  };

  const handleContactRemove = (
    form: FormikProps<ModifiedScheduledEditForm>
  ) => (i: number) => {
    const contacts = form.values.contacts.filter((c, idx) => idx !== i);
    props.getMessageStats({
      message: convertContent(form.values.message),
      contacts: transformContactList(contacts),
      groups: transformGroupList(form.values.groups),
    });
  };

  const handleGroupRemove = (form: FormikProps<ModifiedScheduledEditForm>) => (
    i: number
  ) => {
    const groups = form.values.groups.filter((c, idx) => idx !== i);
    props.getMessageStats({
      message: convertContent(form.values.message),
      contacts: transformContactList(form.values.contacts),
      groups: transformGroupList(groups),
    });
  };

  const handleContactInput = (form: FormikProps<ModifiedScheduledEditForm>) => (
    phoneNumber: string,
    i: number
  ) => {
    if (phoneNumber.length >= 11) {
      form.values.contacts.map((c, idx) => {
        if (idx === i) {
          c.phoneNumber = phoneNumber;
        }
      });
      props.getMessageStats({
        message: convertContent(form.values.message),
        contacts: transformContactList(form.values.contacts),
        groups: transformGroupList(form.values.groups),
      });
    }
  };

  const handleMessageChange = (
    form: FormikProps<ModifiedScheduledEditForm>
  ) => (event: ChangeEvent<HTMLTextAreaElement>) => {
    const message = convertContent(event.target.value);
    if (timeoutHandler) {
      clearTimeout(timeoutHandler);
    }

    setTimeoutHandler(
      setTimeout(() => {
        props.getMessageStats({
          message,
          contacts: form.values.contacts,
          groups: form.values.groups,
        });
      }, debounceWait)
    );
  };

  const remainingMessages = props.statistics
    ? props.statistics.totalAllowedMessages - props.statistics.usedMessages
    : null;

  return (
    <Media query={mobileMediaQuery}>
      {(smallScreen) => (
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={modifiedScheduledEditFormSchema}
        >
          {(formikBag) => {
            return (
              <Form className="scheduled-edit-form" {...formItemLayout}>
                <PhoneNumberInput
                  smallScreen={smallScreen}
                  onContactRemove={handleContactRemove(formikBag)}
                  onGroupRemove={handleGroupRemove(formikBag)}
                  formikBag={formikBag as any}
                  onContactInput={handleContactInput(formikBag)}
                  setSavedForm={(form: any) => {
                    props.setSavedForm(form);
                  }}
                  scheduledId={id}
                  selectingParameter="scheduled"
                />

                <Form.Item
                  name="sendUserId"
                  label={i18n.t("common.sendUserId")}
                  extra={i18n.t("common.sendUserIdNote")}
                >
                  <Input
                    name="sendUserId"
                    placeholder={i18n.t("common.sendUserIdPlaceholder")}
                  />
                </Form.Item>
                <Form.Item
                  name="message"
                  label={i18n.t("common.message")}
                  extra={i18n.t("common.messageNote")}
                >
                  <Input.TextArea
                    className="message"
                    name="message"
                    onChange={handleMessageChange(formikBag)}
                    placeholder={i18n.t("messageForm.messagePlaceholder")}
                    autoSize={{ minRows: 3 }}
                  />
                </Form.Item>
                <Form.Item
                  name="scheduledTime"
                  label={i18n.t("messageForm.scheduledTime")}
                >
                  {smallScreen ? (
                    <MobileTimePicker
                      date={moment(formikBag.values.scheduledTime).toDate()}
                      setDate={(date: Date) =>
                        formikBag.setFieldValue("scheduledTime", date)
                      }
                    />
                  ) : (
                    <ScheduledTimePicker />
                  )}
                </Form.Item>
                {props.messageStatsLoading && (
                  <Row>
                    <Col {...formItemLayout.labelCol} />
                    <Col {...formItemLayout.wrapperCol}>
                      <Spin />
                    </Col>
                  </Row>
                )}
                {!props.messageStatsLoading &&
                  props.messageStats &&
                  props.messageStats.senderCount > 0 &&
                  props.messageStats.numberOfParts > 0 &&
                  props.scheduled &&
                  remainingMessages !== null && (
                    <Row
                      className={
                        props.messageStats.senderCount > 100 ||
                        remainingMessages <
                          props.messageStats.numberOfParts -
                            props.scheduled.gyronMessageParts
                          ? "error"
                          : ""
                      }
                    >
                      <Col {...formItemLayout.labelCol} />
                      <Col {...formItemLayout.wrapperCol}>
                        {props.messageStats.senderCount > 100 &&
                          i18n.t("messageForm.multiMessageMaxErrorNote", {
                            parts: props.messageStats.senderCount,
                          })}
                        {props.messageStats.senderCount <= 100 &&
                          remainingMessages >=
                            props.messageStats.numberOfParts -
                              props.scheduled.gyronMessageParts &&
                          i18n.t("messageForm.multiMessageNote", {
                            parts: props.messageStats.numberOfParts,
                          })}
                        {props.messageStats.senderCount <= 100 &&
                          remainingMessages <
                            props.messageStats.numberOfParts -
                              props.scheduled.gyronMessageParts &&
                          i18n.t("messageForm.multiMessageErrorNote", {
                            parts:
                              props.messageStats.numberOfParts -
                              remainingMessages -
                              props.scheduled.gyronMessageParts,
                            allowed: remainingMessages,
                          })}
                      </Col>
                    </Row>
                  )}
                <Row className="form-button-row">
                  <SaveButton
                    loading={props.editLoading}
                    disabled={
                      !props.messageStatsLoading &&
                      props.messageStats &&
                      props.messageStats.numberOfParts > 0 &&
                      props.scheduled &&
                      (remainingMessages == null ||
                        remainingMessages <
                          props.messageStats.numberOfParts -
                            props.scheduled.gyronMessageParts ||
                        props.messageStats.senderCount > 100)
                    }
                  />
                  <BackButton />
                  <Popconfirm
                    title={i18n.t("schedule.deleteConfirm")}
                    onConfirm={handleDelete}
                    okText={i18n.t("common.delete")}
                    cancelText={i18n.t("common.cancel")}
                    okType="danger"
                  >
                    <DeleteButton loading={props.deleteLoading} />
                  </Popconfirm>
                </Row>
              </Form>
            );
          }}
        </Formik>
      )}
    </Media>
  );
};

const mapStateToProps = (root: RootState) => {
  return {
    scheduled: root.scheduleds.item,
    getLoading: root.scheduleds.getLoading,
    editLoading: root.scheduleds.editLoading,
    deleteLoading: root.scheduleds.deleteLoading,
    deleteFinished: root.scheduleds.deleteFinished,
    error: root.scheduleds.error,
    messageStats: root.publish.messageStats,
    messageStatsLoading: root.publish.messageStatsLoading,
    meoSite: root.seo.meoSite!,
    savedForm: root.scheduleds.savedForm,
    statistics: root.client.statistics,
    statisticsLoading: root.client.statisticsLoading,
  };
};

const mapDispatchToProps = {
  getScheduled,
  editScheduled,
  deleteScheduled,
  clearScheduled,
  setPage,
  getMessageStats,
  getUsageStatistics,
  setSavedForm,
  clearSavedForm,
};

export default connect(mapStateToProps, mapDispatchToProps)(ScheduledEdit);
