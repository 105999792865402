import React, { useState } from "react";
import { Formik, FormikProps } from "formik";
import { Input, Form } from "formik-antd";
import { Modal, Radio } from "antd";
import i18n from "i18n";

const { TextArea } = Input;

interface JsonRegistrationModalProps {
  isModalOpen: boolean;
  closeModal: () => void;
  uploadQuestions: (
    result: FormikProps<{ questions: string }>,
    isCreateNewJson: boolean
  ) => void;
  error: string;
}

const JsonRegistrationModal: React.FC<JsonRegistrationModalProps> = (props) => {
  const [isCreateNewJson, setIsCreateNewJson] = useState(true);

  const handleOk = (form: FormikProps<{ questions: string }>) => {
    props.uploadQuestions(form, isCreateNewJson);
  };

  const handleCancel = () => {
    props.closeModal();
  };

  const valueReset = (form: FormikProps<{ questions: string }>) => {
    form.setFieldValue("questions", "");
  };

  const initialValues = { questions: "" };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={() => {
        // ModalのonOkイベントで処理を行う
      }}
    >
      {(formikBag) => {
        return (
          <Modal
            title={i18n.t("survey.questionsActions.upload")}
            visible={props.isModalOpen}
            okText={i18n.t("common.save")}
            cancelText={i18n.t("common.cancel")}
            onOk={() => handleOk(formikBag)}
            onCancel={handleCancel}
          >
            <Radio.Group
              defaultValue={true}
              onChange={(e) => setIsCreateNewJson(e.target.value)}
            >
              <Radio value={true} onClick={() => valueReset(formikBag)}>
                {i18n.t("survey.questionsActions.createJson")}
              </Radio>
              <Radio value={false} onClick={() => valueReset(formikBag)}>
                {i18n.t("survey.questionsActions.addJson")}
              </Radio>
            </Radio.Group>
            <div
              style={{ color: "red", fontWeight: "bold", marginBottom: "10px" }}
            >
              {isCreateNewJson
                ? i18n.t("survey.uploadModalNote")
                : i18n.t("survey.updateModalNote")}
            </div>
            <Form.Item name="questions">
              <span style={{ color: "#ff4d4f" }}>{props.error}</span>
              <TextArea name="questions" autoSize={{ minRows: 5 }} />
            </Form.Item>
          </Modal>
        );
      }}
    </Formik>
  );
};

export default JsonRegistrationModal;
