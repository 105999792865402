import React, { ReactElement } from "react";
import { Button } from "antd";
import { SaveOutlined } from "@ant-design/icons";
import i18n from "i18n";
import { NativeButtonProps } from "antd/lib/button/button";

interface SaveButtonProps extends NativeButtonProps {
  icon?: ReactElement;
  text?: string;
}

const SaveButton: React.FC<SaveButtonProps> = (props) => {
  return (
    <Button
      icon={props.icon || <SaveOutlined />}
      htmlType="submit"
      type="primary"
      {...props}
    >
      {props.text || i18n.t("common.save")}
    </Button>
  );
};

export default SaveButton;
