import React from "react";
import { mobileMediaQuery } from "../../constants";
import SMSLink from "components/SMSLink";
import { RouteComponentProps } from "react-router-dom";
import { connect } from "react-redux";
import {
  listReviewLinks,
  connectReviewLink,
  updateReviewLinkDisplayOrders,
  getStatus,
  getColors,
} from "features/review_link/ReviewLinkActions";
import { clearItems } from "features/review_link/ReviewLinkSlice";
import {
  ReviewLink,
  ListReviewLinkParams,
  MEOSite,
  ReviewLinkConnectForm,
  ReviewLinkType,
  ReviewLinkStatus,
  ReviewLinkColors,
} from "api/sms-api";
import { Table, Button, Row } from "antd";
import { RootState } from "store/store";
import qs from "qs";
import { useLocation } from "react-router-dom";
import i18n from "i18n";
import { PlusOutlined, CheckOutlined, MenuOutlined } from "@ant-design/icons";
import { Page } from "features/appstate/AppStateSlice";
import Media from "react-media";
import _ from "lodash";
import ColorOption from "./ColorOption";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import arrayMove from "array-move";

interface ReviewLinksProps {
  landingPageSettingsId: string;

  links: Array<ReviewLink>;
  listLoading: boolean;
  status?: ReviewLinkStatus;
  statusLoading: boolean;
  connectLoading: boolean;
  editLoading: boolean;

  colors?: ReviewLinkColors;
  colorsLoading: boolean;
  getColors: () => void;

  meoSite: MEOSite;
  listReviewLinks: (
    landingPageSettingsId: string,
    params: ListReviewLinkParams
  ) => void;
  connectReviewLink: (
    landingPageSettingsId: string,
    form: ReviewLinkConnectForm
  ) => Promise<void>;
  updateReviewLinkDisplayOrders: (
    landingPageSettingsId: string,
    links: Array<ReviewLink>
  ) => Promise<void>;
  getStatus: (landingPageSettingsId: string, meoSiteId: number) => void;
  clearItems: () => void;
}

const DragHandle = SortableHandle(() => (
  <MenuOutlined style={{ cursor: "pointer", color: "#999" }} />
));

const MySortableItem = SortableElement((props: any) => <tr {...props} />);
const MySortableContainer = SortableContainer((props: any) => (
  <tbody {...props} />
));

const ReviewLinks: React.FC<ReviewLinksProps & RouteComponentProps> = (
  props
) => {
  const location = useLocation();

  React.useEffect(() => {
    props.getStatus(props.landingPageSettingsId, props.meoSite.id);
  }, [props.getStatus, props.landingPageSettingsId, props.meoSite]);

  function doList() {
    const parsed = qs.parse(location.search, { ignoreQueryPrefix: true });

    const params = {
      ...parsed,
      meoSiteId: props.meoSite.id,
    };

    props.listReviewLinks(props.landingPageSettingsId, params);
  }

  React.useEffect(() => {
    doList();
  }, [
    props.listReviewLinks,
    location.search,
    props.meoSite,
    props.landingPageSettingsId,
  ]);

  React.useEffect(() => {
    return () => {
      props.clearItems();
    };
  }, [props.clearItems]);

  React.useEffect(() => {
    props.getColors();
  }, [props.getColors]);

  const columns = [
    {
      title: i18n.t("reviewLinks.sort"),
      dataIndex: "sort",
      width: 60,
      className: "drag-visible",
      render: () => <DragHandle />,
    },
    {
      title: i18n.t("common.message"),
      dataIndex: "text",
      key: "text",
      render: (text: string, link: ReviewLink) => {
        return (
          <SMSLink
            to={`/${Page.landingPageSettings}/${props.landingPageSettingsId}/reviewLinks/${link.id}`}
          >
            {text}
          </SMSLink>
        );
      },
      className: "drag-visible",
    },
    {
      title: i18n.t("reviewLinks.url"),
      dataIndex: "url",
      key: "url",
      render: (url: string) => <span>{url}</span>,
    },
    {
      title: i18n.t("reviewLinks.color"),
      dataIndex: "reviewLinkColorId",
      key: "reviewLinkColorId",
      render: (id: string) => {
        const matchingColor = (props.colors?.colors || []).find(
          (c) => c.id === id
        );
        if (matchingColor) {
          return <ColorOption color={matchingColor} />;
        } else {
          return null;
        }
      },
    },
    {
      title: i18n.t("reviewLinks.active"),
      dataIndex: "active",
      key: "active",
      render: (active: boolean) => {
        if (active) {
          return <CheckOutlined />;
        }
        return null;
      },
    },
    {
      title: i18n.t("reviewLinks.hasIcon"),
      dataIndex: "hasIcon",
      key: "hasIcon",
      render: (hasIcon: boolean) => {
        if (hasIcon) {
          return <CheckOutlined />;
        }
        return null;
      },
    },
  ];

  const doConnect = async () => {
    await props.connectReviewLink(props.landingPageSettingsId, {
      meoSiteId: props.meoSite.id,
      type: ReviewLinkType.GoogleMyBusiness,
    });
    doList();
    props.getStatus(props.landingPageSettingsId, props.meoSite.id);
  };

  const addButton = (smallScreen: boolean) => {
    const linkStyle: React.CSSProperties = {};
    const buttonStyle: React.CSSProperties = {
      marginRight: "1em",
    };

    if (smallScreen) {
      linkStyle.width = "100%";
      buttonStyle.marginBottom = "1em";
    }

    return (
      <SMSLink
        to={`/${Page.landingPageSettings}/${props.landingPageSettingsId}/reviewLinks/add`}
        style={linkStyle}
      >
        <Button
          icon={<PlusOutlined />}
          style={buttonStyle}
          type="primary"
          block={smallScreen}
        >
          {i18n.t("reviewLinks.add")}
        </Button>
      </SMSLink>
    );
  };

  const connectButton = (smallScreen: boolean) => {
    if (!props.status || props.statusLoading) {
      // Can't determine whether the button should be shown or not yet
      return null;
    }

    const buttonStyle: React.CSSProperties = {};

    if (smallScreen) {
      buttonStyle.width = "100%";
      buttonStyle.marginBottom = "1em";
    }

    const hasRefreshToken =
      !_.isNil(props.meoSite.myBusinessLocationId) &&
      !_.isNil(props.meoSite.googleAccount?.refreshToken);
    const alreadyHasGoogle =
      props.status.existingLinks.indexOf(ReviewLinkType.GoogleMyBusiness) !==
      -1;

    if (hasRefreshToken && !alreadyHasGoogle) {
      return (
        <Button
          onClick={doConnect}
          icon={<PlusOutlined />}
          type="default"
          style={buttonStyle}
          loading={props.connectLoading}
        >
          {i18n.t("reviewLinks.connect.gmb")}
        </Button>
      );
    }

    return null;
  };

  const onSortEnd = async (indexes: any) => {
    if (indexes.oldIndex !== indexes.newIndex) {
      const newData = arrayMove(
        [...props.links],
        indexes.oldIndex,
        indexes.newIndex
      ).filter((el: any) => !!el);
      await props.updateReviewLinkDisplayOrders(
        props.landingPageSettingsId,
        newData
      );
      doList();
    }
  };

  const DraggableContainer = (props: Record<string, any>) => (
    <MySortableContainer
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const DraggableBodyRow = (innerProps: Record<string, any>) => {
    // const { dataSource } = this.state;
    const restProps = _.omit(innerProps, ["className", "style"]);
    const index = props.links.findIndex(
      (x: ReviewLink) => x.id === restProps["data-row-key"]
    );
    return <MySortableItem index={index} {...restProps} />;
  };

  return (
    <Media query={mobileMediaQuery}>
      {(smallScreen) => {
        return (
          <div>
            <Table
              className="review-link-list"
              dataSource={props.links}
              columns={columns}
              locale={{ emptyText: i18n.t("reviewLinks.empty") }}
              rowKey={(c: ReviewLink) => c.id}
              size="middle"
              pagination={false}
              loading={
                props.listLoading || props.colorsLoading || props.editLoading
              }
              components={{
                body: {
                  wrapper: DraggableContainer,
                  row: DraggableBodyRow,
                },
              }}
            />
            <Row className="form-button-row">
              {addButton(smallScreen)}
              {connectButton(smallScreen)}
            </Row>
          </div>
        );
      }}
    </Media>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    links: state.reviewLinks.items,
    listLoading: state.reviewLinks.listLoading,
    status: state.reviewLinks.status,
    statusLoading: state.reviewLinks.statusLoading,
    connectLoading: state.reviewLinks.connectLoading,
    colors: state.reviewLinks.colors,
    colorsLoading: state.reviewLinks.colorsLoading,
    editLoading: state.reviewLinks.editLoading,
    meoSite: state.seo.meoSite!,
  };
};

const mapDispatchToProps = {
  listReviewLinks,
  connectReviewLink,
  updateReviewLinkDisplayOrders,
  getStatus,
  clearItems,
  getColors,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReviewLinks);
