import i18n from "i18n";
import { showErrorNotification } from "utils/notifications";

export enum CustomError {
  Unknown = "unknown",
  Unauthed = "unauthed",
  NotFound = "not_found",
  CouldNotConnect = "could_not_connect",
  MeoSiteNotEnabled = "meo_site_not_enabled",
  CannotSend = "cannot_send",
  AddressDuplicate = "address_duplicate",
  DuplicateLandingPageName = "duplicate_landing_page_name",
  LandingPageNotFound = "landing_page_not_found",
  ContactGroupOverlap = "contact_group_overlap",
  CannotUpdateGroupHasUnsentSchedule = "cannot_update_group_has_unsent_schedule",
  CannotDeleteGroupHasUnsentSchedule = "cannot_delete_group_has_unsent_schedule",
  CannotUpdateAddressHasUnsentSchedule = "cannot_update_address_has_unsent_schedule",
  CannotDeleteAddressHasUnsentSchedule = "cannot_delete_address_has_unsent_schedule",
  CannotMultipleDeleteAddressHasUnsentSchedule = "cannot_multiple_delete_address_has_unsent_schedule",
  NotEnoughContact = "not_enough_contact",
  NotEnoughQuota = "not_enough_quota",
}

export enum SendError {
  CannotSendToOptOut = "cannot_send_to_opt_out",
  CannotSendToBlacklist = "cannot_send_to_blacklist",
  CannotSendToOptOutGroup = "cannot_send_to_opt_out_group",
  CannotSendToBlacklistGroup = "cannot_send_to_blacklist_group",
}

// This is what comes back from the API: an error key, plus any other
// descriptive information. This is the canonical error type, the analogue to
// `CustomError` in the API. This can go in the Redux state.
export interface ErrorWrapper {
  error: CustomError | SendError;

  // For any arbitrary "error detail" field
  [x: string]: any;
}

export function isErrorWrapper(obj: any): obj is ErrorWrapper {
  return obj && (obj as ErrorWrapper).error !== undefined;
}

// Return the message to show in a temporary popup, if any, for when the given
// error comes back from the API
function getNotificationMessage(e: CustomError) {
  switch (e) {
    case CustomError.Unknown:
      return i18n.t("errors.unknown");
    case CustomError.CouldNotConnect:
      return i18n.t("errors.couldNotConnect");
    case CustomError.AddressDuplicate:
      return i18n.t("errors.addressDuplicate");
    case CustomError.DuplicateLandingPageName:
      return i18n.t("errors.duplicateLandingPageName");
    case CustomError.CannotUpdateGroupHasUnsentSchedule:
      return i18n.t("errors.cannotUpdateGroupHasUnsentSchedule");
    case CustomError.CannotDeleteGroupHasUnsentSchedule:
      return i18n.t("errors.cannotDeleteGroupHasUnsentSchedule");
    case CustomError.CannotUpdateAddressHasUnsentSchedule:
      return i18n.t("errors.cannotUpdateAddressHasUnsentSchedule");
    case CustomError.CannotDeleteAddressHasUnsentSchedule:
      return i18n.t("errors.cannotDeleteAddressHasUnsentSchedule");
    case CustomError.NotEnoughContact:
      return i18n.t("errors.notEnoughContact");
    case CustomError.NotEnoughQuota:
      return i18n.t("errors.notEnoughQuota");
    default:
      return null;
  }
}

export function handleError(errorJson?: any) {
  const errorKey: CustomError = errorJson
    ? ((errorJson["error"] || "unknown") as CustomError)
    : CustomError.Unknown;
  let message = null;
  if (errorKey == "cannot_multiple_delete_address_has_unsent_schedule") {
    message = i18n.t("errors.cannotMultipleDeleteAddressHasUnsentSchedule", {
      name: errorJson["name"],
    });
  } else {
    message = getNotificationMessage(errorKey);
  }
  if (message) {
    showErrorNotification(message);
  }
}
