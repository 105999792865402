import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  Paginated,
  LocalBusiness,
  LocalBusinesses,
  SurveyMeoSiteSettings,
  RecipientEmailCsvImportResult,
} from "api/sms-api";
import { ErrorWrapper } from "errors/errors";
import { CRUDState, initialCRUDState } from "store/CRUDStore";

export interface MeoSitesState extends CRUDState<SurveyMeoSiteSettings> {
  localBusinesses: Array<LocalBusiness>;
  localBusinessesLoading: boolean;
  updateSurveyMeoSiteSettingsLoading: boolean;
  selectedMeoSites: Array<SurveyMeoSiteSettings>;
  importResult?: RecipientEmailCsvImportResult;
  csvImporting: boolean;
  csvExporting: boolean;
}

const initialState: MeoSitesState = {
  ...initialCRUDState,
  localBusinesses: [],
  localBusinessesLoading: false,
  updateSurveyMeoSiteSettingsLoading: false,
  selectedMeoSites: [],
  csvImporting: false,
  csvExporting: false,
};

export const slice = createSlice({
  name: "meoSites",
  initialState,
  reducers: {
    getSurveyMeoSiteSettingsStart: (state) => {
      state.listLoading = true;
    },
    getSurveyMeoSiteSettingsSuccess: (
      state,
      action: PayloadAction<Paginated<SurveyMeoSiteSettings>>
    ) => {
      state.items = action.payload;
      state.listLoading = false;
    },
    getSurveyMeoSiteSettingsError: (state) => {
      state.listLoading = false;
    },
    clearItems: (state) => {
      state.items = { items: [], hasRight: false, hasLeft: false };
    },
    getLocalBusinessesStart: (state) => {
      state.localBusinessesLoading = true;
    },
    getLocalBusinessesSuccess: (
      state,
      action: PayloadAction<LocalBusinesses>
    ) => {
      state.localBusinesses = action.payload.items;
      state.localBusinessesLoading = false;
    },
    getLocalBusinessesError: (state) => {
      state.localBusinessesLoading = false;
    },
    updateSurveyMeoSiteSettingsStart: (state) => {
      state.updateSurveyMeoSiteSettingsLoading = true;
    },
    updateSurveyMeoSiteSettingsSuccess: (
      state,
      action: PayloadAction<Paginated<SurveyMeoSiteSettings>>
    ) => {
      state.items = action.payload;
      state.updateSurveyMeoSiteSettingsLoading = false;
    },
    updateSurveyMeoSiteSettingsError: (
      state,
      action: PayloadAction<ErrorWrapper>
    ) => {
      state.updateSurveyMeoSiteSettingsLoading = false;
      state.error = action.payload;
    },
    addMeoSitesStart: (state) => {
      state.createLoading = true;
    },
    addMeoSitesSuccess: (state) => {
      state.createLoading = false;
    },
    addMeoSitesError: (state, action: PayloadAction<ErrorWrapper>) => {
      state.createLoading = false;
      state.error = action.payload;
    },
    setSelectedMeoSites: (
      state,
      action: PayloadAction<Array<SurveyMeoSiteSettings>>
    ) => {
      state.selectedMeoSites = action.payload;
    },
    clearSelectedMeoSites: (state) => {
      state.selectedMeoSites = [];
    },
    exportStart: (state) => {
      state.csvExporting = true;
    },
    exportSuccess: (state) => {
      state.csvExporting = false;
    },
    exportError: (state) => {
      state.csvExporting = false;
    },
    importStart: (state) => {
      state.csvImporting = true;
    },
    importSuccess: (
      state,
      action: PayloadAction<RecipientEmailCsvImportResult>
    ) => {
      state.csvImporting = false;
      state.importResult = action.payload;
    },
    importError: (state) => {
      state.csvImporting = false;
    },
    clearImport: (state) => {
      state.importResult = undefined;
    },
  },
});

export const {
  getSurveyMeoSiteSettingsStart,
  getSurveyMeoSiteSettingsSuccess,
  getSurveyMeoSiteSettingsError,
  clearItems,
  getLocalBusinessesStart,
  getLocalBusinessesSuccess,
  getLocalBusinessesError,
  updateSurveyMeoSiteSettingsStart,
  updateSurveyMeoSiteSettingsSuccess,
  updateSurveyMeoSiteSettingsError,
  addMeoSitesStart,
  addMeoSitesSuccess,
  addMeoSitesError,
  setSelectedMeoSites,
  clearSelectedMeoSites,
  clearImport,
  exportStart,
  exportSuccess,
  exportError,
  importStart,
  importSuccess,
  importError,
} = slice.actions;

export default slice.reducer;
