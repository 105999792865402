import React from "react";
import { BorderOutlined } from "@ant-design/icons";
import {
  LandingData,
  LandingDataPreviewForm,
  LandingPageSettingsEditForm,
  LogoUploadResult,
  MEOSite,
  LandingPageSettingsAddForm,
  ReviewLinkColors,
} from "api/sms-api";
import { RootState } from "store/store";
import {
  addLandingPageSettings,
  uploadLogo,
  getPreviewData,
} from "./LandingPageSettingsActions";
import { clearLandingPageSettings } from "./LandingPageSettingsSlice";
import { connect } from "react-redux";
import { Formik } from "formik";
import { Form, Input } from "formik-antd";
import { formItemLayout } from "../../constants";
import i18n from "i18n";
import { Button, Row, Spin } from "antd";
import { landingPageSettingsAddFormSchema } from "validation";
import { showPreview } from "./Preview";
import { useHistory } from "react-router-dom";
import CreateButton from "components/CreateButton";
import { pushTo } from "utils/navigation";
import { Page } from "features/appstate/AppStateSlice";
import RichEditor from "components/RichEditor";
import { getColors } from "features/review_link/ReviewLinkActions";

interface LandingPageSettingsAddProps {
  previewData?: LandingData;
  createLoading: boolean;
  logoUploading: boolean;
  meoSite: MEOSite;
  getPreviewLoading: boolean;

  addLandingPageSettings: (params: LandingPageSettingsAddForm) => Promise<void>;
  uploadLogo: (file: File) => Promise<LogoUploadResult | undefined>;
  getPreviewData: (form: LandingDataPreviewForm) => void;

  colors?: ReviewLinkColors;
  getColors: () => void;
}

const LandingPageSettingsAdd: React.FC<LandingPageSettingsAddProps> = (
  props
) => {
  const history = useHistory();
  const [logoUrl, setLogoUrl] = React.useState<string | undefined>(undefined);
  const [modalVisible, setModalVisible] = React.useState(false);

  function handleOk() {
    setModalVisible(false);
  }

  React.useEffect(() => {
    if (
      !props.getPreviewLoading &&
      props.previewData &&
      modalVisible &&
      props.colors
    ) {
      showPreview(props.previewData, props.colors, handleOk);
    }
  }, [props.getPreviewLoading, props.previewData, props.colors]);

  React.useEffect(() => {
    props.getColors();
  }, [props.getColors]);

  if (props.createLoading || !props.colors) {
    return <Spin />;
  }

  const initialValues: LandingPageSettingsAddForm = {
    meoSiteId: props.meoSite.id,
    name: "",
    message: "",
  };

  const handleSubmit = async (form: LandingPageSettingsAddForm) => {
    if (logoUrl) {
      form.logoUrl = logoUrl;
    }
    await props.addLandingPageSettings(form);
    pushTo(history, `/${Page.landingPageSettings}`, {});
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }

    const result = await props.uploadLogo(e.target.files[0]);
    if (result) {
      setLogoUrl(result.url);
    }
  };

  const preview = (form: LandingPageSettingsEditForm) => {
    const previewForm = {
      meoSiteId: props.meoSite.id,
      logoUrl: logoUrl || "",
      message: form.message || "",
    };
    props.getPreviewData(previewForm);
    setModalVisible(true);
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={landingPageSettingsAddFormSchema}
    >
      {(formikBag) => {
        return (
          <Form {...formItemLayout}>
            <Form.Item name="name" label={i18n.t("landingPageSettings.name")}>
              <Input name="name" />
            </Form.Item>
            <Form.Item name="title" label={i18n.t("landingPageSettings.title")}>
              <Input name="title" />
            </Form.Item>
            <Form.Item
              name="message"
              label={i18n.t("landingPageSettings.message")}
            >
              <RichEditor
                value={formikBag.values.message || ""}
                onChange={(value) => {
                  formikBag.setFieldValue("message", value);
                }}
              />
            </Form.Item>
            <Form.Item name="file" label={i18n.t("landingPageSettings.logo")}>
              <input type="file" onChange={handleFileChange} />
              {logoUrl && (
                <img
                  style={{ marginTop: "1em", maxWidth: "100%" }}
                  src={logoUrl}
                />
              )}
            </Form.Item>
            <Row className="form-button-row">
              <CreateButton
                loading={props.createLoading}
                disabled={props.logoUploading}
              />
              <Button
                icon={<BorderOutlined />}
                onClick={() => preview(formikBag.values)}
                loading={props.getPreviewLoading}
              >
                {i18n.t("landingPageSettings.preview")}
              </Button>
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    editLoading: state.landingPageSettings.editLoading,
    logoUploading: state.landingPageSettings.fileUploading,
    meoSite: state.seo.meoSite!,
    previewData: state.landingPageSettings.previewData,
    getLoading: state.landingPageSettings.getLoading,
    getPreviewLoading: state.landingPageSettings.getPreviewLoading,
    item: state.landingPageSettings.item,
    colors: state.reviewLinks.colors,
  };
};

const mapDispatchToProps = {
  addLandingPageSettings,
  clearLandingPageSettings,
  uploadLogo,
  getPreviewData,
  getColors,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LandingPageSettingsAdd);
