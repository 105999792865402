import { AppDispatch, AppThunk, AsyncAppThunk } from "store/store";
import {
  listScheduledsStart,
  listScheduledsSuccess,
  listScheduledsError,
  editScheduledStart,
  editScheduledSuccess,
  editScheduledError,
  getScheduledStart,
  getScheduledSuccess,
  getScheduledError,
  deleteScheduledStart,
  deleteScheduledSuccess,
  deleteScheduledError,
} from "./ScheduleSlice";
import { httpWrapper } from "../../utils/http";
import { SMS_API_BASE } from "../../constants";
import {
  ListScheduledParams,
  Paginated,
  ScheduledEditForm,
  FullScheduledWithParts,
} from "../../api/sms-api";
import { handleError, ErrorWrapper } from "errors/errors";
import i18n from "i18n";
import { showNotification } from "utils/notifications";

export const listScheduleds = (params: ListScheduledParams): AppThunk => async (
  dispatch: AppDispatch
) => {
  dispatch(listScheduledsStart());
  try {
    const scheduleds = await httpWrapper<Paginated<FullScheduledWithParts>>(
      "GET",
      `${SMS_API_BASE}/scheduled`,
      params
    );

    dispatch(listScheduledsSuccess(scheduleds));
  } catch (e) {
    handleError(e);
    dispatch(listScheduledsError(e.toString));
  }
};

export const editScheduled = (
  id: string,
  params: ScheduledEditForm
): AsyncAppThunk<void | ErrorWrapper> => async (dispatch: AppDispatch) => {
  dispatch(editScheduledStart());
  try {
    await httpWrapper("PATCH", `${SMS_API_BASE}/scheduled/${id}`, params);

    showNotification(i18n.t("schedule.editSuccess"));
    dispatch(editScheduledSuccess());
  } catch (e) {
    handleError(e);
    dispatch(editScheduledError(e));
    return e;
  }
};

export const getScheduled = (id: string): AppThunk => async (
  dispatch: AppDispatch
) => {
  dispatch(getScheduledStart());
  try {
    const scheduled = await httpWrapper<FullScheduledWithParts>(
      "GET",
      `${SMS_API_BASE}/scheduled/${id}`,
      {}
    );
    dispatch(getScheduledSuccess(scheduled));
  } catch (e) {
    handleError(e);
    dispatch(getScheduledError(e));
  }
};

export const deleteScheduled = (id: string): AppThunk => async (
  dispatch: AppDispatch
) => {
  dispatch(deleteScheduledStart());
  try {
    await httpWrapper("DELETE", `${SMS_API_BASE}/scheduled/${id}`, {});
    showNotification(i18n.t("schedule.deleteSuccess"));
    dispatch(deleteScheduledSuccess());
  } catch (e) {
    handleError(e);
    dispatch(deleteScheduledError(e));
  }
};
