import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Paginated, FullGroup } from "../../api/sms-api";
import { ErrorWrapper } from "errors/errors";
import { initialCRUDState, CRUDState } from "store/CRUDStore";
import { ModifiedGroupForm } from "./GroupAdd";

export interface SelectedGroup {
  id: string;
  name: string;
}

interface GroupState extends CRUDState<FullGroup> {
  savedForm?: ModifiedGroupForm;
  shortcutLoading: boolean;
  searchResults: Array<FullGroup>;

  selected: Array<SelectedGroup>;
}

export const initialState: GroupState = {
  ...initialCRUDState,
  shortcutLoading: false,
  searchResults: [],
  selected: [],
};

export const slice = createSlice({
  name: "group",
  initialState,
  reducers: {
    listGroupStart: (state) => {
      state.listLoading = true;
    },
    listGroupSuccess: (state, action: PayloadAction<Paginated<FullGroup>>) => {
      state.items = action.payload;
      state.listLoading = false;
    },
    listGroupError: (state, _action: PayloadAction<ErrorWrapper>) => {
      state.listLoading = false;
    },
    getGroupStart: (state) => {
      state.getLoading = true;
    },
    getGroupSuccess: (state, action: PayloadAction<FullGroup>) => {
      state.item = action.payload;
      state.getLoading = false;
    },
    getGroupError: (state, action: PayloadAction<ErrorWrapper>) => {
      state.getLoading = false;
      state.error = action.payload;
    },
    addGroupStart: (state) => {
      state.createLoading = true;
    },
    addGroupSuccess: (state) => {
      state.createLoading = false;
      state.addFinished = true;
    },
    addGroupError: (state, _action: PayloadAction<ErrorWrapper>) => {
      state.createLoading = false;
    },
    editGroupStart: (state) => {
      state.editLoading = true;
    },
    editGroupSuccess: (state) => {
      state.editLoading = false;
      state.editFinished = true;
    },
    editGroupError: (state, _action: PayloadAction<ErrorWrapper>) => {
      state.editLoading = false;
    },
    deleteGroupStart: (state) => {
      state.deleteLoading = true;
    },
    deleteGroupSuccess: (state) => {
      state.deleteLoading = false;
      state.deleteFinished = true;
    },
    deleteGroupError: (state, _action: PayloadAction<ErrorWrapper>) => {
      state.deleteLoading = false;
    },
    clearGroup: (state) => {
      state.item = undefined;
      state.deleteFinished = false;
      state.addFinished = false;
      state.editFinished = false;
    },
    setSavedForm: (state, action: PayloadAction<ModifiedGroupForm>) => {
      state.savedForm = action.payload;
    },
    clearSavedForm: (state) => {
      state.savedForm = undefined;
    },
    setSelected: (state, action: PayloadAction<Array<SelectedGroup>>) => {
      state.selected = action.payload;
    },
    clearItems: (state) => {
      state.items = { items: [], hasRight: false, hasLeft: false };
    },
  },
});

export const {
  listGroupStart,
  listGroupSuccess,
  listGroupError,
  getGroupStart,
  getGroupSuccess,
  getGroupError,
  addGroupStart,
  addGroupSuccess,
  addGroupError,
  editGroupStart,
  editGroupSuccess,
  editGroupError,
  deleteGroupStart,
  deleteGroupSuccess,
  deleteGroupError,
  clearGroup,
  setSavedForm,
  clearSavedForm,
  setSelected,
  clearItems,
} = slice.actions;

export default slice.reducer;
