import React from "react";
import { connect } from "react-redux";
import { FullHistoryGroup, HistoryType } from "api/sms-api";
import { getHistory } from "./HistoryActions";
import { RootState } from "store/store";
import { Page, setPage } from "features/appstate/AppStateSlice";
import { Spin, Row } from "antd";
import { CustomError, ErrorWrapper } from "errors/errors";
import NotFound from "components/NotFound";
import ContactBlockHistory from "components/ContactBlockHistory";
import { useParams } from "react-router-dom";
import BackButton from "components/BackButton";

interface HistoryContactsProps {
  getLoading: boolean;
  item?: FullHistoryGroup;
  error?: ErrorWrapper;
  getHistory: (id: string) => void;
  setPage: (page: Page) => void;
}

const HistoryContacts: React.FC<HistoryContactsProps> = (props) => {
  const { id } = useParams() as any;

  React.useEffect(() => {
    props.setPage(Page.history);
  }, [props.setPage]);

  React.useEffect(() => {
    props.getHistory(id);
  }, [props.getHistory, id]);

  if (props.error?.error === CustomError.NotFound) {
    return <NotFound />;
  }

  if (props.getLoading || !props.item) {
    return <Spin />;
  }

  return (
    <div>
      <ContactBlockHistory
        contactGroups={props.item.contactGroups}
        showStatus={props.item.history.type !== HistoryType.Direct}
        isDirect={props.item.history.type === HistoryType.Direct}
      />
      <Row className="form-button-row">
        <BackButton />
      </Row>
    </div>
  );
};

const mapStateToProps = (root: RootState) => {
  return {
    item: root.history.item,
    getLoading: root.history.getLoading,
    error: root.history.error,
  };
};
const mapDispatchToProps = {
  getHistory,
  setPage,
};

export default connect(mapStateToProps, mapDispatchToProps)(HistoryContacts);
