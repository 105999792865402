import React from "react";
import { RootState } from "store/store";
import { connect } from "react-redux";
import { Modal } from "antd";
import i18n from "i18n";
import { Form, Input } from "antd";
import { MailTemplateSentTestMailForm } from "api/sms-api";
import { sendMailTemplateTestMail } from "./MailTemplateActions";
import { emailIsInvalid } from "utils/strings";

interface MailTemplateSendTestEmailDialogProps {
  visible: boolean;
  closeModal: () => void;
  bcc?: string;
  senderEmailAddress: string;
  title: string;
  contents: string;
  sendMailTemplateTestMail: (form: MailTemplateSentTestMailForm) => void;
}

const MailTemplateSendTestEmailDialog: React.FC<MailTemplateSendTestEmailDialogProps> = (
  props
) => {
  const [form] = Form.useForm();

  const onClickSendButton = (value: { emailAddress: string }) => {
    const params: MailTemplateSentTestMailForm = {
      emailAddress: value.emailAddress,
      title: props.title,
      senderEmailAddress: props.senderEmailAddress,
      contents: props.contents,
      bcc: props.bcc,
    };
    props.sendMailTemplateTestMail(params);
    form.resetFields();
    props.closeModal();
  };

  const onClickCancelButton = () => {
    form.resetFields();
    props.closeModal();
  };

  return (
    <Modal
      visible={props.visible}
      title={i18n.t("mailTemplate.testEmail.title")}
      okText={i18n.t("mailTemplate.testEmail.send")}
      cancelText={i18n.t("mailTemplate.testEmail.cancel")}
      onCancel={onClickCancelButton}
      onOk={() => {
        form.validateFields().then((values) => {
          onClickSendButton(values);
        });
      }}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          name="emailAddress"
          label={i18n.t("mailTemplate.testEmail.emailAddress")}
          className="input-mail-form"
          validateTrigger={["onBlur"]}
          rules={[
            {
              validator(_, value) {
                if (value && !emailIsInvalid([value])) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(i18n.t("validation.mail")));
              },
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

const mapStateToProps = (state: RootState) => {
  return state;
};

const mapDispatchToProps = {
  sendMailTemplateTestMail,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MailTemplateSendTestEmailDialog);
