import { AppDispatch, AppThunk, AsyncAppThunk } from "store/store";
import {
  publishStart,
  publishSuccess,
  publishFailure,
  getMessageStatsStart,
  getMessageStatsSuccess,
  getMessageStatsFailure,
} from "./PublishSlice";
import { httpWrapper } from "../../utils/http";
import { SMS_API_BASE } from "../../constants";
import {
  PublishForm,
  ScheduleForm,
  MessageStatsForm,
  MessageStats,
  DirectLinkForm,
  DirectLinkResponse,
} from "../../api/sms-api";
import { handleError, ErrorWrapper } from "errors/errors";
import i18n from "i18n";
import _ from "lodash";
import { showNotification } from "utils/notifications";

export const publish = (
  form: PublishForm
): AsyncAppThunk<void | ErrorWrapper> => async (dispatch: AppDispatch) => {
  dispatch(publishStart());
  try {
    await httpWrapper<void>(
      "post",
      `${SMS_API_BASE}/sns/publish`,
      _.omit(form, ["type", "cellPhone"])
    );

    // このメッセージはちょっと長いから６秒
    showNotification(i18n.t("cloudPublish.success", 6));
    dispatch(publishSuccess());
  } catch (e) {
    handleError(e);
    dispatch(publishFailure(e));
    return e;
  }
};

export const schedule = (
  form: ScheduleForm
): AsyncAppThunk<void | ErrorWrapper> => async (dispatch: AppDispatch) => {
  dispatch(publishStart());
  try {
    await httpWrapper<void>("post", `${SMS_API_BASE}/scheduled`, form);
    showNotification(i18n.t("schedule.createSuccess"));
    dispatch(publishSuccess());
  } catch (e) {
    handleError(e);
    dispatch(publishFailure(e));
    return e;
  }
};

export const getMessageStats = (form: MessageStatsForm): AppThunk => async (
  dispatch: AppDispatch
) => {
  dispatch(getMessageStatsStart());
  try {
    const stats = await httpWrapper<MessageStats>(
      "post",
      `${SMS_API_BASE}/meta/message_stats`,
      form
    );
    dispatch(getMessageStatsSuccess(stats));
  } catch (e) {
    dispatch(getMessageStatsFailure());
  }
};

export const getDirectLink = (
  form: DirectLinkForm
): AsyncAppThunk<ErrorWrapper | DirectLinkResponse> => async (
  dispatch: AppDispatch
) => {
  dispatch(publishStart());
  try {
    const link = await httpWrapper<DirectLinkResponse>(
      "POST",
      `${SMS_API_BASE}/sns/link`,
      _.omit(form, ["type"])
    );
    dispatch(publishSuccess());
    return link;
  } catch (e) {
    handleError(e);
    dispatch(publishFailure(e));
    return e;
  }
};
