import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { EcLink } from "api/sms-api";
import { CRUDState, initialCRUDState } from "store/CRUDStore";

export interface EcLinkStatusState extends CRUDState<EcLink> {
  ecLinks: Array<EcLink>;
  ecLinksLoading: boolean;
}

const initialState: EcLinkStatusState = {
  ...initialCRUDState,
  ecLinks: [],
  ecLinksLoading: false,
};

export const slice = createSlice({
  name: "ecLinkStatus",
  initialState,
  reducers: {
    getEcLinksStart: (state) => {
      state.ecLinksLoading = true;
    },
    getEcLinksSuccess: (state, action: PayloadAction<Array<EcLink>>) => {
      state.ecLinks = action.payload;
      state.ecLinksLoading = false;
    },
    getEcLinksError: (state) => {
      state.ecLinksLoading = false;
    },
  },
});

export const {
  getEcLinksStart,
  getEcLinksSuccess,
  getEcLinksError,
} = slice.actions;

export default slice.reducer;
