import React, { useRef, useMemo } from "react";
import JoditEditor from "jodit-react";
import i18n from "i18n";

// This is from https://github.com/jodit/jodit-react/issues/43#issuecomment-657175944
// If we don't use `useMemo`, there will be weird issues where the editor loses
// focus when typing

interface RichEditorProps {
  tagList?: any;
  value: string;
  onChange: (value: string) => void;
  customButtons?: Array<any>;
  hideButtonList?: Array<string>;
}

const RichEditor: React.FC<RichEditorProps> = (props) => {
  const editor = useRef(null);

  let buttons: Array<any> = [
    "source",
    "|",
    "bold",
    "strikethrough",
    "underline",
    "italic",
    "eraser",
    "|",
    "superscript",
    "subscript",
    "|",
    "ul",
    "ol",
    "|",
    "outdent",
    "indent",
    "|",
    "font",
    "fontsize",
    "brush",
    "paragraph",
    "|",
    "image",
    "video",
    "table",
    "link",
    "|",
    "align",
    "undo",
    "redo",
    "\n",
    "selectall",
    "cut",
    "copy",
    "paste",
    "copyformat",
    "|",
    "hr",
    "symbol",
    "fullsize",
    "preview",
  ];

  // Remove any buttons that exist in the hideButtonList
  const hideButtonList = props.hideButtonList || [];
  buttons = buttons.filter(
    (button) => !hideButtonList.find((hideButton) => button === hideButton)
  );

  // Tag list for inserting into the text
  if (props.tagList) {
    const buttonName = i18n.t("mailTemplate.table.item.tags");
    let tagButton: any = {
      name: buttonName,
      list: props.tagList,
      childTemplate: (editor: any, key: string) => {
        return `<span>${key}</span>`;
      },
      exec: function (editor: any, t: any, event: any) {
        if (
          event.control &&
          event.control.args &&
          event.control.args.length > 0
        ) {
          let index = 0;
          if (event.control.args.length > 1) {
            index = 1;
          }
          // Only use if you always want to insert after the current selection
          /** editor.selection.setCursorAfter(editor.selection.current()); **/
          editor.selection.insertHTML(event.control.args[index]);
        }
      },
    };
    // Insert an ordinary button if there is only one thing on the tag list
    const keyList = Object.keys(props.tagList);
    if (keyList.length === 1) {
      tagButton = {
        name: buttonName,
        exec: function (editor: any) {
          editor.selection.insertHTML(props.tagList[keyList[0]]);
        },
      };
    }
    buttons.push("|");
    buttons.push(tagButton);
  }
  // Add any custom buttons if there are any
  if (props.customButtons) {
    buttons.push("|");
    buttons.push(...props.customButtons);
  }

  const config = {
    placeholder: "",
    language: "ja",
    showTooltipDelay: 500,
    buttons,
    buttonsMD: buttons,
    buttonsSM: buttons,
    buttonsXS: buttons,
  };

  return useMemo(
    () => (
      <JoditEditor
        ref={editor}
        value={props.value}
        config={config as any}
        onChange={props.onChange}
      />
    ),
    []
  );
};

export default RichEditor;
