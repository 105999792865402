import { AppDispatch, AppThunk, AsyncAppThunk } from "store/store";
import { httpWrapper } from "../../utils/http";
import { SMS_API_BASE } from "../../constants";
import { handleError } from "errors/errors";
import {
  listReviewLinksStart,
  listReviewLinksSuccess,
  listReviewLinksError,
  addReviewLinkStart,
  addReviewLinkSuccess,
  addReviewLinkError,
  getReviewLinkStart,
  getReviewLinkSuccess,
  getReviewLinkError,
  editReviewLinkStart,
  editReviewLinkSuccess,
  editReviewLinkError,
  connectStart,
  connectSuccess,
  connectError,
  getStatusStart,
  getStatusSuccess,
  getStatusError,
  getColorsStart,
  getColorsSuccess,
  getColorsError,
} from "./ReviewLinkSlice";
import {
  ReviewLink,
  ListReviewLinkParams,
  ReviewLinkAddForm,
  ReviewLinkEditForm,
  ReviewLinkConnectForm,
  ReviewLinkStatus,
  ReviewLinkColors,
} from "../../api/sms-api";
import i18n from "i18n";
import { showNotification } from "utils/notifications";

export const listReviewLinks = (
  landingPageSettingsId: string,
  params: ListReviewLinkParams
): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(listReviewLinksStart());
  try {
    const links = await httpWrapper<Array<ReviewLink>>(
      "GET",
      `${SMS_API_BASE}/landing_page_settings/${landingPageSettingsId}/review_links`,
      params
    );

    dispatch(listReviewLinksSuccess(links));
  } catch (e) {
    handleError(e);
    dispatch(listReviewLinksError(e));
  }
};

export const addReviewLink = (
  landingPageSettingsId: string,
  params: ReviewLinkAddForm
): AsyncAppThunk<void> => async (dispatch: AppDispatch) => {
  dispatch(addReviewLinkStart());
  try {
    await httpWrapper(
      "POST",
      `${SMS_API_BASE}/landing_page_settings/${landingPageSettingsId}/review_links`,
      params
    );
    showNotification(i18n.t("reviewLinks.createSuccess"));
    dispatch(addReviewLinkSuccess());
  } catch (e) {
    handleError(e);
    dispatch(addReviewLinkError(e));
  }
};

export const editReviewLink = (
  landingPageSettingsId: string,
  id: string,
  params: ReviewLinkEditForm
): AsyncAppThunk<void> => async (dispatch: AppDispatch) => {
  dispatch(editReviewLinkStart());
  try {
    await httpWrapper(
      "PATCH",
      `${SMS_API_BASE}/landing_page_settings/${landingPageSettingsId}/review_links/${id}`,
      params
    );
    showNotification(i18n.t("reviewLinks.editSuccess"));
    dispatch(editReviewLinkSuccess());
  } catch (e) {
    handleError(e);
    dispatch(editReviewLinkError(e));
  }
};

export const deleteReviewLink = (
  landingPageSettingsId: string,
  id: string
): AsyncAppThunk<void> => async (dispatch: AppDispatch) => {
  dispatch(editReviewLinkStart());
  try {
    await httpWrapper(
      "DELETE",
      `${SMS_API_BASE}/landing_page_settings/${landingPageSettingsId}/review_links/${id}`,
      {}
    );
    showNotification(i18n.t("reviewLinks.deleteSuccess"));
    dispatch(editReviewLinkSuccess());
  } catch (e) {
    handleError(e);
    dispatch(editReviewLinkError(e));
  }
};

/*
 * Update each ReviewLink provided to have a displayOrder that corresponds to its index in the array
 */
export const updateReviewLinkDisplayOrders = (
  landingPageSettingsId: string,
  links: Array<ReviewLink>
): AsyncAppThunk<void> => async (dispatch: AppDispatch) => {
  dispatch(editReviewLinkStart());
  try {
    const promises = links.map((link, index) => {
      const form: ReviewLinkEditForm = {
        displayOrder: index,
      };
      return httpWrapper(
        "PATCH",
        `${SMS_API_BASE}/landing_page_settings/${landingPageSettingsId}/review_links/${link.id}`,
        form
      );
    });

    await Promise.all(promises);
    dispatch(editReviewLinkSuccess());
  } catch (e) {
    handleError(e);
    dispatch(editReviewLinkError(e));
  }
};

export const getReviewLink = (
  landingPageSettingsId: string,
  id: string
): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(getReviewLinkStart());
  try {
    const link = await httpWrapper<ReviewLink>(
      "GET",
      `${SMS_API_BASE}/landing_page_settings/${landingPageSettingsId}/review_links/${id}`,
      {}
    );
    dispatch(getReviewLinkSuccess(link));
  } catch (e) {
    handleError(e);
    dispatch(getReviewLinkError(e));
  }
};

export const connectReviewLink = (
  landingPageSettingsId: string,
  form: ReviewLinkConnectForm
): AsyncAppThunk<void> => async (dispatch: AppDispatch) => {
  dispatch(connectStart());
  try {
    await httpWrapper(
      "POST",
      `${SMS_API_BASE}/landing_page_settings/${landingPageSettingsId}/review_links/connect`,
      form
    );
    showNotification(i18n.t("reviewLinks.connectSuccess"));
    dispatch(connectSuccess());
  } catch (e) {
    handleError(e);
    dispatch(connectError(e));
  }
};

export const getStatus = (
  landingPageSettingsId: string,
  meoSiteId: number
): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(getStatusStart());
  try {
    const status = await httpWrapper<ReviewLinkStatus>(
      "GET",
      `${SMS_API_BASE}/landing_page_settings/${landingPageSettingsId}/review_links/status?meo_site_id=${meoSiteId}`,
      {}
    );
    dispatch(getStatusSuccess(status));
  } catch (e) {
    handleError(e);
    dispatch(getStatusError());
  }
};

export const getColors = (): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(getColorsStart());
  try {
    const colors = await httpWrapper<ReviewLinkColors>(
      "GET",
      `${SMS_API_BASE}/landing_page_settings/review_link_colors`,
      {}
    );
    dispatch(getColorsSuccess(colors));
  } catch (e) {
    handleError(e);
    dispatch(getColorsError());
  }
};
