import React from "react";
import { connect } from "react-redux";
import { FullHistoryGroup } from "api/sms-api";
import { getHistory } from "./HistoryActions";
import { RootState } from "store/store";
import { Page, setPage } from "features/appstate/AppStateSlice";
import { Spin } from "antd";
import { CustomError, ErrorWrapper } from "errors/errors";
import NotFound from "components/NotFound";
import { useParams } from "react-router-dom";
import MessageDisplay from "components/MessageDisplay";

interface HistoryMessageProps {
  getLoading: boolean;
  item?: FullHistoryGroup;
  error?: ErrorWrapper;
  getHistory: (id: string) => void;
  setPage: (page: Page) => void;
}

const HistoryMessage: React.FC<HistoryMessageProps> = (props) => {
  const { id } = useParams() as any;

  React.useEffect(() => {
    props.setPage(Page.history);
  }, [props.setPage]);

  React.useEffect(() => {
    props.getHistory(id);
  }, [props.getHistory, id]);

  if (props.error?.error === CustomError.NotFound) {
    return <NotFound />;
  }

  if (props.getLoading || !props.item) {
    return <Spin />;
  }

  return <MessageDisplay message={props.item.history.message} />;
};

const mapStateToProps = (root: RootState) => {
  return {
    item: root.history.item,
    getLoading: root.history.getLoading,
    error: root.history.error,
  };
};
const mapDispatchToProps = {
  getHistory,
  setPage,
};

export default connect(mapStateToProps, mapDispatchToProps)(HistoryMessage);
