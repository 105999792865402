import React, { useEffect } from "react";
import { Button, Space } from "antd";
import SMSLink from "components/SMSLink";
import { RightOutlined } from "@ant-design/icons";
import i18n from "i18n";
import { MODE_SURVEY, surveyTypeStore } from "../../constants";
import { Page, setPage } from "features/appstate/AppStateSlice";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import qs from "qs";

interface SurveyAddProps {
  setPage: (page: Page) => void;
}

const SurveyAdd: React.FC<SurveyAddProps> = (props) => {
  const location = useLocation();
  const parsed = qs.parse(location.search, { ignoreQueryPrefix: true });

  useEffect(() => {
    props.setPage(Page.surveyAdd);
  }, [props.setPage]);

  const createNewButton = (
    <SMSLink
      to={`/${Page.survey}/settings`}
      queryParams={{
        mode: MODE_SURVEY,
        surveyType: parsed.surveyType || surveyTypeStore,
        type: "new",
      }}
    >
      <Button block>
        {i18n.t("survey.createNew")}
        <RightOutlined />
      </Button>
    </SMSLink>
  );

  const createTemplateButton = (
    <SMSLink
      to={`/${Page.survey}/settings`}
      queryParams={{
        mode: MODE_SURVEY,
        surveyType: parsed.surveyType || surveyTypeStore,
        type: "template",
      }}
    >
      <Button block>
        {i18n.t("survey.createTemplate")}
        <RightOutlined />
      </Button>
    </SMSLink>
  );

  return (
    <Space direction="vertical">
      {createTemplateButton}
      {createNewButton}
    </Space>
  );
};

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {
  setPage,
};

export default connect(mapStateToProps, mapDispatchToProps)(SurveyAdd);
