import React from "react";
import { LandingPageSettings } from "api/sms-api";
import i18n from "i18n";
import QRCode from "qrcode.react";
import { LANDING_PAGE_BASE } from "../constants";
import { Button } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { saveAs } from "file-saver";

interface LandingPageQRProps {
  landingPageSettings: LandingPageSettings;
}

const LandingPageQR: React.FC<LandingPageQRProps> = (props) => {
  const url = `${LANDING_PAGE_BASE}/static/${props.landingPageSettings.id}`;

  const handleDownload = () => {
    const canvas: any = document.querySelector(".lps-qr-code > canvas");
    canvas.toBlob(function (blob: any) {
      saveAs(blob, "qr.png");
    });
  };

  return (
    <div className="lps-qr-code" style={{ textAlign: "center" }}>
      <QRCode value={url} size={256} level="M" includeMargin={true} />
      <Button
        onClick={() => handleDownload()}
        icon={<DownloadOutlined />}
        style={{ marginTop: "1em" }}
      >
        {i18n.t("common.download")}
      </Button>
    </div>
  );
};

export default LandingPageQR;
