import {
  Row,
  Select,
  Table,
  DatePicker,
  Button,
  Radio,
  Typography,
} from "antd";
import {
  GMBReviews,
  GMBReview,
  GMBReviewFilter,
  GMBStarRating,
  MEOSite,
  GMBSort,
} from "api/sms-api";
import i18n from "i18n";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import { RootState } from "store/store";
import { listGMBReviews } from "./ReviewActions";
import { truncate } from "lodash";
import { Page, setPage } from "features/appstate/AppStateSlice";
import { pushTo } from "utils/navigation";
import { useHistory, useLocation } from "react-router-dom";
import qs from "qs";
import locale from "antd/es/date-picker/locale/ja_JP";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import SMSLink from "components/SMSLink";
import { ReviewNeedsUpdate, setNeedsUpdate, setSelected } from "./ReviewSlice";
import _ from "lodash";

const { Option } = Select;
const { RangePicker } = DatePicker;
const { Text } = Typography;

interface ReviewsProps {
  gmbReviews?: GMBReviews;
  needsUpdate: Array<ReviewNeedsUpdate>;
  listLoading: boolean;
  meoSite: MEOSite;
  selected: Array<GMBReview>;

  setPage: (page: Page) => void;
  listGMBReviews: (filter: GMBReviewFilter) => Promise<void>;
  setNeedsUpdate: (items: Array<ReviewNeedsUpdate>) => void;
  setSelected: (selected: Array<GMBReview>) => void;
}

const ReviewList: React.FC<ReviewsProps> = (props) => {
  const history = useHistory();
  const location = useLocation();

  const [ratingFilter, setRatingFilter] = React.useState<GMBStarRating | null>(
    null
  );
  const [startDate, setStartDate] = React.useState<moment.Moment | null>(null);
  const [endDate, setEndDate] = React.useState<moment.Moment | null>(null);
  const [canPaginateLeft, setCanPaginateLeft] = React.useState<boolean>(false);
  const [canPaginateRight, setCanPaginateRight] = React.useState<boolean>(
    false
  );
  const [offsets, setOffsets] = React.useState<Array<number>>([]);
  const [sort, setSort] = React.useState<GMBSort>(GMBSort.UpdateTime);

  React.useEffect(() => {
    const parsed = qs.parse(location.search, { ignoreQueryPrefix: true });
    const queryRating = parsed["rating"] as GMBStarRating;
    const queryStart = parsed["start"] as string;
    const queryEnd = parsed["end"] as string;
    const querySkip = parseInt(parsed["skip"] as string, 10);
    const querySort = (parsed["sort"] as GMBSort) || GMBSort.UpdateTime;

    setRatingFilter(queryRating);
    setStartDate(queryStart ? moment(queryStart) : null);
    setEndDate(queryEnd ? moment(queryEnd) : null);
    setCanPaginateLeft(!!querySkip && querySkip > 0);
    setSort(querySort);

    const filter: GMBReviewFilter = {
      meoSiteId: props.meoSite.id,
      rating: queryRating || undefined,
      start: queryStart || undefined,
      end: queryEnd || undefined,
      skip: querySkip || undefined,
      sort: querySort || undefined,
    };

    props.listGMBReviews(filter);
  }, [props.listGMBReviews, location.search]);

  React.useEffect(() => {
    props.setPage(Page.reviews);
  }, [props.setPage]);

  React.useEffect(() => {
    props.setSelected([]);
  }, [props.setSelected]);

  React.useEffect(() => {
    setCanPaginateRight(!!props.gmbReviews?.hasMore);
  }, [props.gmbReviews]);

  React.useEffect(() => {
    return () => {
      props.setNeedsUpdate([]);
    };
  }, [props.setNeedsUpdate]);

  const columns = [
    {
      title: i18n.t("common.time"),
      key: "updateTime",
      render: (_: any, review: GMBReview) =>
        moment(review.updateTime).format("LLLL"),
    },
    {
      title: i18n.t("reviews.userName"),
      key: "userName",
      render: (_: any, review: GMBReview) => review.reviewer.displayName || "",
    },
    {
      title: i18n.t("reviews.rating"),
      key: "rating",
      width: "5%",
      render: (_: any, review: GMBReview) =>
        i18n.t(`reviews.ratingValues.${review.starRating}`),
    },
    {
      title: i18n.t("reviews.content"),
      key: "content",
      render: (_: any, review: GMBReview) => {
        const comment = review.comment
          ? truncate(review.comment, { length: 50 })
          : "////" + i18n.t("reviews.noComment");
        const style = review.comment ? {} : { fontStyle: "italic" };
        return (
          <SMSLink to={`${Page.reviews}/${encodeURIComponent(review.name)}`}>
            <span style={style}>{comment}</span>
          </SMSLink>
        );
      },
    },
    {
      title: i18n.t("reviews.reply"),
      key: "reply",
      render: (_: any, review: GMBReview) => {
        let comment = review.reviewReply?.comment;

        // The list API doesn't return the newest info if called right after a
        // reply post/update, so we have to fake it by overwriting the
        // appropriate row with what we know the reply should actually be
        const matchingUpdateItem = props.needsUpdate.find(
          (item) => item.reviewName === review.name
        );
        if (matchingUpdateItem) {
          comment = matchingUpdateItem.replyComment;
          if (comment && review.reviewer.displayName) {
            // Replace ${to} in the comment with the reviewer's name
            comment = comment.replace("${to}", review.reviewer.displayName!);
          }
        }
        return truncate(comment, { length: 50 });
      },
    },
  ];

  const handleRatingChange = (value: string) => {
    // Have to clear the pagination when changing non-pagination filters
    setOffsets([]);
    pushTo(history, null, { rating: value === "all" ? undefined : value }, [
      "start",
      "end",
    ]);
  };

  const handleRangeChange = (dates: any) => {
    const start = dates && dates[0] ? (dates[0] as moment.Moment) : undefined;
    const end = dates && dates[1] ? (dates[1] as moment.Moment) : undefined;

    setOffsets([]);
    pushTo(
      history,
      null,
      { start: start?.toISOString(), end: end?.toISOString() },
      ["rating"]
    );
  };

  const onLeft = () => {
    if (offsets.length > 0) {
      const copied = [...offsets];
      copied.pop(); // This is the offset we're currently using; throw it away
      setOffsets(copied);
      pushTo(history, null, { skip: copied[copied.length - 1] }, [
        "start",
        "end",
        "rating",
        "sort",
      ]);
    }
  };

  const onRight = () => {
    const newOffset = props.gmbReviews?.offset || undefined;
    if (newOffset) {
      const copied = [...offsets];
      copied.push(newOffset);
      setOffsets(copied);
    }
    pushTo(history, null, { skip: newOffset }, [
      "start",
      "end",
      "rating",
      "sort",
    ]);
  };

  const handleSortClick = (e: any) => {
    let newSort = e.target.value;

    // Toggle feature
    if (sort === GMBSort.Rating && newSort === GMBSort.Rating) {
      newSort = GMBSort.RatingDesc;
    } else if (sort === GMBSort.RatingDesc && newSort === GMBSort.RatingDesc) {
      newSort = GMBSort.Rating;
    }
    pushTo(history, null, { sort: newSort }, ["rating"]);
  };

  const handleSelect = (record: GMBReview, isSelected: boolean) => {
    if (isSelected) {
      // Adding
      const newSelected = [...props.selected, record];
      props.setSelected(newSelected);
    } else {
      // Removing
      let current = [...props.selected];
      current = current.filter((c) => c.name !== record.name);
      props.setSelected(current);
    }
  };

  const handleSelectAll = (
    isSelected: boolean,
    _selectedRows: Array<GMBReview>, // Don't use; this will contain `undefined` values when you move between pages
    changeRows: Array<GMBReview>
  ) => {
    if (isSelected) {
      // Add all
      // Add the newly selected rows, deduplicating by name
      const newResult = _.uniqBy(
        [...props.selected, ...changeRows],
        (a: GMBReview) => a.name
      );
      props.setSelected(newResult);
    } else {
      // Remove all
      const deselectedNames = changeRows.map((a) => a.name);
      const newResult = props.selected.filter(
        (c) => !deselectedNames.includes(c.name)
      );
      props.setSelected(newResult);
    }
  };

  const rowSelection = {
    selectedRowKeys: props.selected.map((r) => r.name),
    onSelect: handleSelect,
    preserveSelectedRowKeys: true,
    onSelectAll: handleSelectAll,
  };

  const goToMultiReply = () => {
    pushTo(history, `${Page.reviews}/replyMulti`, {});
  };

  const multiReplyButton = (
    <Button type="primary" onClick={goToMultiReply}>
      {i18n.t("reviews.multiReply", { count: props.selected.length })}
    </Button>
  );

  return (
    <div>
      <Row align="middle" style={{ marginBottom: "1em" }}>
        <RangePicker
          disabled={props.listLoading || sort !== GMBSort.UpdateTime}
          value={[startDate, endDate]}
          onChange={handleRangeChange}
          locale={locale as any}
          style={{ marginRight: "1em" }}
        />
        <Select
          value={ratingFilter ? ratingFilter.toString() : "all"}
          onChange={handleRatingChange}
          className="landing-page-select"
          disabled={props.listLoading}
          style={{ marginRight: "1.5em" }}
        >
          <Option value="all">
            {i18n.t("reviews.rating") + i18n.t("common.all")}
          </Option>
          {[
            GMBStarRating.FIVE,
            GMBStarRating.FOUR,
            GMBStarRating.THREE,
            GMBStarRating.TWO,
            GMBStarRating.ONE,
          ].map((star) => (
            <Option key={star} value={star}>
              {i18n.t("reviews.rating") +
                i18n.t(`reviews.ratingValues.${star}`)}
            </Option>
          ))}
        </Select>
        <Text type="secondary" style={{ marginRight: "0.5em" }}>
          {i18n.t("reviews.sortOrder")}
        </Text>
        <Radio.Group
          disabled={props.listLoading}
          value={sort}
          optionType="button"
        >
          <Radio.Button
            onClick={handleSortClick}
            checked={sort === GMBSort.UpdateTime}
            value={GMBSort.UpdateTime}
          >
            {i18n.t("reviews.updateTime")}
          </Radio.Button>
          {sort === GMBSort.Rating ? (
            <Radio.Button
              onClick={handleSortClick}
              checked={sort === GMBSort.Rating}
              value={GMBSort.Rating}
            >
              {i18n.t("reviews.ratingAsc")}
            </Radio.Button>
          ) : (
            <Radio.Button
              onClick={handleSortClick}
              checked={sort === GMBSort.RatingDesc}
              value={GMBSort.RatingDesc}
            >
              {i18n.t("reviews.ratingDesc")}
            </Radio.Button>
          )}
        </Radio.Group>
      </Row>
      <Row style={{ marginBottom: "1em" }}>
        <Table
          dataSource={props.gmbReviews?.reviews || []}
          columns={columns}
          rowSelection={rowSelection}
          locale={{ emptyText: i18n.t("reviews.empty") }}
          rowKey={(r: GMBReview) => r.name}
          size="middle"
          pagination={false}
          loading={props.listLoading}
          style={{ width: "100%" }}
        />
      </Row>
      <Row className="pagination-row">
        <Button.Group>
          <Button
            onClick={onLeft}
            disabled={!canPaginateLeft}
            className="left"
            icon={<LeftOutlined />}
          />
          <Button
            onClick={onRight}
            disabled={!canPaginateRight}
            className="right"
            icon={<RightOutlined />}
          />
        </Button.Group>
      </Row>
      {props.selected.length > 1 && multiReplyButton}
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    gmbReviews: state.reviews.gmbReviews,
    needsUpdate: state.reviews.needsUpdate,
    listLoading: state.reviews.listLoading,
    selected: state.reviews.selected,
    meoSite: state.seo.meoSite!,
  };
};

const mapDispatchToProps = {
  listGMBReviews,
  setNeedsUpdate,
  setPage,
  setSelected,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReviewList);
