import React, { useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import { connect } from "react-redux";
import { Page, setPage } from "features/appstate/AppStateSlice";
import { listMailTemplates, deleteMailTemplates } from "./MailTemplateActions";
import {
  setSelectedMailTemplates,
  clearSelectedMailTemplates,
  clearItems,
} from "./MailTemplateSlice";
import {
  MailTemplateSender,
  MailTemplateWithSender,
  Paginated,
  PaginationParams,
  MailTemplateDeleteForm,
} from "api/sms-api";
import { Table, Button, Row, Popconfirm } from "antd";
import { RootState } from "store/store";
import qs from "qs";
import { useHistory, useLocation } from "react-router-dom";
import i18n from "i18n";
import { CheckOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import SimplePagination, {
  PaginatedComponentProps,
} from "components/SimplePagination";
import _ from "lodash";
import { PAGE_LIMIT, MODE_SURVEY, surveyTypeStore } from "../../constants";
import SMSLink from "components/SMSLink";

interface MailTemplateListProps extends PaginatedComponentProps {
  items: Paginated<MailTemplateWithSender>;
  loading: boolean;
  listMailTemplates: (param: PaginationParams) => void;
  setPage: (page: Page) => void;

  deleteMailTemplates: (params: MailTemplateDeleteForm) => void;
  deleteLoading: boolean;
  deleteFinished: boolean;

  selectedMailTemplates: Array<MailTemplateWithSender>;
  setSelectedMailTemplates: (selected: Array<MailTemplateWithSender>) => void;
  clearSelectedMailTemplates: () => void;
  clearItems: () => void;
}

const MailTemplates: React.FC<
  MailTemplateListProps & RouteComponentProps & PaginatedComponentProps
> = (props) => {
  const history = useHistory();
  const location = useLocation();
  const parsed = qs.parse(location.search, { ignoreQueryPrefix: true });

  useEffect(() => {
    props.setPage(Page.mailTemplates);
  }, [props.setPage]);

  useEffect(() => {
    const params = {
      ..._.omit(parsed, ["before", "after"]),
    };
    props.listMailTemplates({ ...params, limit: PAGE_LIMIT });
  }, [props.listMailTemplates]);

  useEffect(() => {
    return () => {
      props.clearItems();
    };
  }, [props.clearItems]);

  useEffect(() => {
    if (props.deleteFinished && !props.deleteLoading) {
      props.clearSelectedMailTemplates();
      const params = {
        ..._.omit(parsed, ["mode", "before", "after"]),
      };
      props.listMailTemplates({ ...params, limit: PAGE_LIMIT });
    }
  }, [props.listMailTemplates, props.deleteFinished, props.deleteLoading]);

  const columns = () => [
    {
      title: "",
      dataIndex: "id",
      key: "id",
      render: (_: any) => {
        return <CheckOutlined className="survey-table-publish" />;
      },
    },
    {
      title: i18n.t("mailTemplate.table.title.name"),
      dataIndex: "name",
      key: "name",
      render: (title: string, record: MailTemplateWithSender) => {
        return (
          <SMSLink
            to={`/${Page.mailTemplates}/settings`}
            queryParams={{
              id: record.id,
              type: "edit",
              mode: MODE_SURVEY,
              surveyType: parsed.surveyType || surveyTypeStore,
            }}
          >
            {title || i18n.t("mailTemplate.table.item.untitled")}
          </SMSLink>
        );
      },
    },
    {
      title: i18n.t("mailTemplate.table.title.title"),
      dataIndex: "title",
      key: "title",
    },
    {
      title: i18n.t("mailTemplate.table.title.from"),
      dataIndex: "mailTemplateSender",
      key: "mailTemplateSender",
      render: (mailTemplateSender: MailTemplateSender) => {
        return mailTemplateSender.senderEmail;
      },
    },
  ];

  const selectedRowKeys = props.selectedMailTemplates.map(({ id }) => id);

  const handleSelect = (
    record: MailTemplateWithSender,
    isSelected: boolean
  ) => {
    if (isSelected) {
      // Adding
      const newSelected = [
        ...props.selectedMailTemplates,
        {
          id: record.id,
          clientId: record.clientId,
          name: record.name,
          title: record.title,
          mailTemplateSender: record.mailTemplateSender,
          bcc: record.bcc,
          contentType: record.contentType,
          contents: record.contents,
          createdAt: record.createdAt,
          updatedAt: record.updatedAt,
        },
      ];
      props.setSelectedMailTemplates(newSelected);
    } else {
      // Removing
      let current = [...props.selectedMailTemplates];
      current = current.filter((c) => c.id !== record.id);
      props.setSelectedMailTemplates(current);
    }
  };

  const handleSelectAll = (
    isSelected: boolean,
    changeRows: Array<MailTemplateWithSender>
  ) => {
    if (isSelected) {
      // Add all
      const newSelections = changeRows.map((row) => {
        return {
          id: row.id,
          clientId: row.clientId,
          name: row.name,
          title: row.title,
          mailTemplateSender: row.mailTemplateSender,
          bcc: row.bcc,
          contentType: row.contentType,
          contents: row.contents,
          createdAt: row.createdAt,
          updatedAt: row.updatedAt,
        };
      });

      // Add the newly selected rows, deduplicating by key
      const newResult = _.uniqBy(
        [...props.selectedMailTemplates, ...newSelections],
        "id"
      );
      props.setSelectedMailTemplates(newResult);
    } else {
      // Remove all
      props.setSelectedMailTemplates([]);
    }
  };

  const rowSelection = {
    selectedRowKeys,
    onSelect: handleSelect,
    onSelectAll: handleSelectAll,
    preserveSelectedRowKeys: true,
  };

  const handleDelete = () => {
    const selectedIds: Array<string> = props.selectedMailTemplates.map(
      (s) => s.id!
    );
    const form: MailTemplateDeleteForm = {
      mailTemplateIds: selectedIds,
    };
    props.deleteMailTemplates(form);
  };

  const addButton = (
    <SMSLink
      to={`${Page.mailTemplates}/settings`}
      queryParams={{
        mode: MODE_SURVEY,
        type: "new",
        surveyType: parsed.surveyType || surveyTypeStore,
      }}
    >
      <Button
        icon={<PlusOutlined />}
        type="primary"
        disabled={props.items.items.length > 0}
      >
        {i18n.t("mailTemplate.table.settings.add")}
      </Button>
    </SMSLink>
  );

  const deleteButton = (
    <Popconfirm
      title={i18n.t("mailTemplate.table.settings.deleteCountConfirm", {
        count: props.selectedMailTemplates.length,
      })}
      disabled={props.selectedMailTemplates.length < 1}
      onConfirm={handleDelete}
      okText={i18n.t("common.delete")}
      cancelText={i18n.t("common.cancel")}
      okType="danger"
    >
      <Button
        danger={props.selectedMailTemplates.length > 0}
        disabled={props.selectedMailTemplates.length < 1}
        icon={<DeleteOutlined />}
      >
        {i18n.t("common.delete")}
      </Button>
    </Popconfirm>
  );

  const pagination = (
    <SimplePagination
      items={props.items.items}
      getCursor={(model: MailTemplateWithSender) => model.createdAt}
      sortDescending={true}
      hasRight={props.items.hasRight}
      hasLeft={props.items.hasLeft}
      location={props.location}
      history={history}
      match={props.match}
    />
  );

  return (
    <div>
      <div className="table-button-row">
        <div className="form-button-row">
          {addButton}
          {deleteButton}
        </div>
      </div>
      <Table
        rowSelection={rowSelection}
        dataSource={props.items.items}
        columns={columns()}
        rowKey={(item: MailTemplateWithSender) => item.id}
        size="middle"
        pagination={false}
      />
      <Row className="pagination-row">{pagination}</Row>
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    items: state.mailTemplates.items,
    selectedMailTemplates: state.mailTemplates.selectedMailTemplates,
    loading: state.mailTemplates.listLoading,
    deleteLoading: state.mailTemplates.deleteLoading,
    deleteFinished: state.mailTemplates.deleteFinished,
  };
};

const mapDispatchToProps = {
  setPage,
  listMailTemplates,
  deleteMailTemplates,
  clearItems,
  setSelectedMailTemplates,
  clearSelectedMailTemplates,
};

export default connect(mapStateToProps, mapDispatchToProps)(MailTemplates);
