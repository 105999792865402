import { AppThunk, AppDispatch } from "store/store";
import {
  listMEOSitesStart,
  listMEOSitesError,
  listMEOSitesSuccess,
  getMEOSiteStart,
  getMEOSiteSuccess,
  getMEOSiteError,
} from "./SEOSlice";
import { SMS_API_BASE } from "../../constants";
import { MEOSites, MEOSite } from "api/sms-api";
import { httpWrapper } from "utils/http";
import { handleError } from "errors/errors";

export const getMEOSite = (meoSiteId: number): AppThunk => async (
  dispatch: AppDispatch
) => {
  dispatch(getMEOSiteStart());
  try {
    const site = await httpWrapper<MEOSite>(
      "GET",
      `${SMS_API_BASE}/seo/meo_sites/${meoSiteId}`,
      {}
    );
    dispatch(getMEOSiteSuccess(site));
  } catch (e) {
    handleError(e);
    dispatch(getMEOSiteError(e));
  }
};

export const listMEOSites = (): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(listMEOSitesStart());
  try {
    const meoSites = await httpWrapper<MEOSites>(
      "GET",
      `${SMS_API_BASE}/seo/meo_sites`,
      {}
    );
    dispatch(listMEOSitesSuccess(meoSites));
  } catch (e) {
    handleError(e);
    dispatch(listMEOSitesError(e));
  }
};
