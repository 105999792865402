import {
  QuestionSetChanges,
  Paginated,
  Surveys,
  SurveysDeleteForm,
  QuestionItem,
  SurveysEditForm,
  TemplateSurveys,
  SelectedQuestion,
  PaginationParams,
  SubmitQuestionItems,
  LogoUploadResult,
  ReviewMediaData,
  LanguageModel,
  SurveyEcEmailSettingsData,
  SurveyEcEmailSettings,
  LanguageSettings,
} from "api/sms-api";
import { AppDispatch } from "store/store";
import { httpWrapper } from "utils/http";
import {
  getSurveyListStart,
  getSurveyListSuccess,
  getSurveyListError,
  getSurveyStart,
  getSurveySuccess,
  getSurveyError,
  addSurveysStart,
  addSurveysSuccess,
  addSurveysError,
  addQuestionsStart,
  addQuestionsSuccess,
  addQuestionsError,
  duplicateSurveyStart,
  duplicateSurveySuccess,
  duplicateSurveyError,
  editSurveysStart,
  editSurveysSuccess,
  editSurveysError,
  editSurveySettingsStart,
  editSurveySettingsSuccess,
  editSurveySettingsError,
  getReviewMediaSettingsStart,
  getReviewMediaSettingsSuccess,
  getReviewMediaSettingsError,
  editReviewMediaSettingsStart,
  editReviewMediaSettingsSuccess,
  editReviewMediaSettingsError,
  deleteSurveysStart,
  deleteSurveysSuccess,
  deleteSurveysError,
  getSelectedQuestionsStart,
  getSelectedQuestionsSuccess,
  getSelectedQuestionsError,
  getQuestionBranchItemsStart,
  getQuestionBranchItemsSuccess,
  getQuestionBranchItemsError,
  getQuestionGroupItemsStart,
  getQuestionGroupItemsSuccess,
  getQuestionGroupItemsError,
  getBaseQuestionItemStart,
  getBaseQuestionItemSuccess,
  getBaseQuestionItemError,
  getCustomQuestionItemStart,
  getCustomQuestionItemSuccess,
  getCustomQuestionItemError,
  getTemplateSurveysStart,
  getTemplateSurveysSuccess,
  getTemplateSurveysError,
  getTemplateQuestionsStart,
  getTemplateQuestionsSuccess,
  getTemplateQuestionsError,
  fileUploadStart,
  fileUploadSuccess,
  fileUploadError,
  getLanguageModelListStart,
  getLanguageModelListSuccess,
  getLanguageModelListError,
  getLanguageSettingsListStart,
  getLanguageSettingsListSuccess,
  getLanguageSettingsListError,
  getEcEmailSettingsStart,
  getEcEmailSettingsSuccess,
  getEcEmailSettingsError,
  editEcEmailSettingsStart,
  editEcEmailSettingsSuccess,
  editEcEmailSettingsError,
} from "./SurveySlice";
import { SMS_API_BASE } from "../../constants";
import i18n from "i18n";
import { showNotification } from "utils/notifications";
import { handleError } from "errors/errors";

export const getSurveyList = (params: PaginationParams) => async (
  dispatch: AppDispatch
) => {
  dispatch(getSurveyListStart());
  try {
    const surveyList = await httpWrapper<Paginated<Surveys>>(
      "GET",
      `${SMS_API_BASE}/surveys`,
      params
    );
    dispatch(getSurveyListSuccess(surveyList));
  } catch (e) {
    handleError(e);
    dispatch(getSurveyListError());
  }
};

export const getSurvey = (id: string) => async (dispatch: AppDispatch) => {
  dispatch(getSurveyStart());
  try {
    const survey = await httpWrapper<Surveys>(
      "GET",
      `${SMS_API_BASE}/surveys/${id}`,
      {}
    );
    dispatch(getSurveySuccess(survey));
  } catch (e) {
    handleError(e);
    dispatch(getSurveyError());
  }
};

export const addSurveys = (params: SurveysEditForm) => async (
  dispatch: AppDispatch
) => {
  dispatch(addSurveysStart());
  try {
    const survey = await httpWrapper<Surveys>(
      "POST",
      `${SMS_API_BASE}/surveys`,
      params
    );
    dispatch(addSurveysSuccess(survey));
  } catch (e) {
    const error: any = e;
    handleError(error);
    dispatch(addSurveysError(error));
  }
};

export const duplicateSurvey = (params: SurveysDeleteForm) => async (
  dispatch: AppDispatch
) => {
  dispatch(duplicateSurveyStart());
  try {
    await httpWrapper("POST", `${SMS_API_BASE}/surveys/duplicate`, params);
    dispatch(duplicateSurveySuccess());
  } catch (e) {
    const error: any = e;
    handleError(error);
    dispatch(duplicateSurveyError(error));
  }
};

export const addQuestions = (
  id: string,
  params: Array<SubmitQuestionItems>,
  isClose: boolean
) => async (dispatch: AppDispatch) => {
  dispatch(addQuestionsStart());
  try {
    await httpWrapper("POST", `${SMS_API_BASE}/surveys/${id}`, params);
    showNotification(i18n.t("survey.createSuccess"));
    dispatch(addQuestionsSuccess());
    if (!isClose) {
      dispatch(getSelectedQuestions(id));
    }
  } catch (e) {
    const error: any = e;
    handleError(error);
    dispatch(addQuestionsError(error));
  }
};

export const editSurveys = (
  id: string,
  form: QuestionSetChanges,
  isClose: boolean
) => async (dispatch: AppDispatch) => {
  dispatch(editSurveysStart());
  try {
    await httpWrapper("PATCH", `${SMS_API_BASE}/surveys/${id}`, form);
    showNotification(i18n.t("survey.editSuccess"));
    dispatch(editSurveysSuccess());
    if (!isClose) {
      dispatch(getSelectedQuestions(id));
    }
  } catch (e) {
    const error: any = e;
    handleError(error);
    dispatch(editSurveysError(error));
  }
};

export const editSurveySettings = (id: string, form: SurveysEditForm) => async (
  dispatch: AppDispatch
) => {
  dispatch(editSurveySettingsStart());
  try {
    await httpWrapper("PATCH", `${SMS_API_BASE}/surveys/settings/${id}`, form);
    showNotification(i18n.t("survey.editSuccess"));
    dispatch(editSurveySettingsSuccess());
  } catch (e) {
    const error: any = e;
    handleError(error);
    dispatch(editSurveySettingsError(error));
  }
};

export const getReviewMediaSettings = (id: string) => async (
  dispatch: AppDispatch
) => {
  dispatch(getReviewMediaSettingsStart());
  try {
    const reviewMedia = await httpWrapper<ReviewMediaData>(
      "GET",
      `${SMS_API_BASE}/surveys/review_media/${id}`,
      {}
    );
    dispatch(getReviewMediaSettingsSuccess(reviewMedia));
  } catch (e) {
    handleError(e);
    dispatch(getReviewMediaSettingsError());
  }
};

export const editReviewMediaSettings = (
  id: string,
  form: ReviewMediaData
) => async (dispatch: AppDispatch) => {
  dispatch(editReviewMediaSettingsStart());
  try {
    await httpWrapper(
      "PATCH",
      `${SMS_API_BASE}/surveys/review_media/${id}`,
      form
    );
    showNotification(i18n.t("survey.editSuccess"));
    dispatch(editReviewMediaSettingsSuccess());
  } catch (e) {
    const error: any = e;
    handleError(error);
    dispatch(editReviewMediaSettingsError(error));
  }
};

export const deleteSurveys = (params: SurveysDeleteForm) => async (
  dispatch: AppDispatch
) => {
  dispatch(deleteSurveysStart());
  try {
    await httpWrapper("PUT", `${SMS_API_BASE}/surveys`, params);
    showNotification(i18n.t("survey.table.settings.deleteSuccess"));
    dispatch(deleteSurveysSuccess());
  } catch (e) {
    const error: any = e;
    handleError(error);
    dispatch(deleteSurveysError(error));
  }
};

export const getSelectedQuestions: any = (surveyId: string) => async (
  dispatch: AppDispatch
) => {
  dispatch(getSelectedQuestionsStart());
  try {
    const selectedQuestions = await httpWrapper<Array<SelectedQuestion>>(
      "GET",
      `${SMS_API_BASE}/surveys/questions/${surveyId}`,
      {}
    );
    dispatch(getSelectedQuestionsSuccess(selectedQuestions));
  } catch (e) {
    handleError(e);
    dispatch(getSelectedQuestionsError(e));
  }
};

export const geteQuestionBranchItems = () => async (dispatch: AppDispatch) => {
  dispatch(getQuestionBranchItemsStart());
  try {
    const questionBranchItems = await httpWrapper<Array<QuestionItem>>(
      "GET",
      `${SMS_API_BASE}/surveys/question_branch_items`,
      {}
    );
    dispatch(getQuestionBranchItemsSuccess(questionBranchItems));
  } catch (e) {
    handleError(e);
    dispatch(getQuestionBranchItemsError());
  }
};

export const geteQuestionGroupItems = () => async (dispatch: AppDispatch) => {
  dispatch(getQuestionGroupItemsStart());
  try {
    const questionGroupItems = await httpWrapper<Array<QuestionItem>>(
      "GET",
      `${SMS_API_BASE}/surveys/question_group_items`,
      {}
    );
    dispatch(getQuestionGroupItemsSuccess(questionGroupItems));
  } catch (e) {
    handleError(e);
    dispatch(getQuestionGroupItemsError());
  }
};

export const getBaseQuestionItem = () => async (dispatch: AppDispatch) => {
  dispatch(getBaseQuestionItemStart());
  try {
    const questionItems = await httpWrapper<Array<QuestionItem>>(
      "GET",
      `${SMS_API_BASE}/surveys/question_items`,
      {}
    );
    dispatch(getBaseQuestionItemSuccess(questionItems));
  } catch (e) {
    handleError(e);
    dispatch(getBaseQuestionItemError());
  }
};

export const getCustomQuestionItem = () => async (dispatch: AppDispatch) => {
  dispatch(getCustomQuestionItemStart());
  try {
    const questionCustomItems = await httpWrapper<Array<QuestionItem>>(
      "GET",
      `${SMS_API_BASE}/surveys/question_custom_items`,
      {}
    );
    dispatch(getCustomQuestionItemSuccess(questionCustomItems));
  } catch (e) {
    handleError(e);
    dispatch(getCustomQuestionItemError());
  }
};

export const getTemplateSurveys = () => async (dispatch: AppDispatch) => {
  dispatch(getTemplateSurveysStart());
  try {
    const templateSurveys = await httpWrapper<Array<TemplateSurveys>>(
      "GET",
      `${SMS_API_BASE}/surveys/template`,
      {}
    );
    dispatch(getTemplateSurveysSuccess(templateSurveys));
  } catch (e) {
    handleError(e);
    dispatch(getTemplateSurveysError());
  }
};

export const getTemplateQuestions = (templateSurveyId: string) => async (
  dispatch: AppDispatch
) => {
  dispatch(getTemplateQuestionsStart());
  try {
    const templateQuestions = await httpWrapper<Array<SelectedQuestion>>(
      "GET",
      `${SMS_API_BASE}/surveys/template/${templateSurveyId}`,
      {}
    );
    dispatch(getTemplateQuestionsSuccess(templateQuestions));
  } catch (e) {
    handleError(e);
    dispatch(getTemplateQuestionsError());
  }
};

export const uploadLogo = (file: File) => async (dispatch: AppDispatch) => {
  dispatch(fileUploadStart());
  try {
    const formData = new FormData();
    formData.append("file", file);
    const result = await httpWrapper<LogoUploadResult>(
      "POST",
      `${SMS_API_BASE}/surveys/logo`,
      formData,
      { "Content-Type": "multipart/form-data" }
    );
    dispatch(fileUploadSuccess());
    return result;
  } catch (e) {
    handleError(e);
    dispatch(fileUploadError());
  }
};

export const getLanguageModelListAll = () => async (dispatch: AppDispatch) => {
  dispatch(getLanguageModelListStart());
  try {
    const languageModelList = await httpWrapper<Array<LanguageModel>>(
      "GET",
      `${SMS_API_BASE}/surveys/language_model`,
      {}
    );
    dispatch(getLanguageModelListSuccess(languageModelList));
  } catch (e) {
    handleError(e);
    dispatch(getLanguageModelListError());
  }
};

export const getLanguageSettingsListAll = () => async (
  dispatch: AppDispatch
) => {
  dispatch(getLanguageSettingsListStart());
  try {
    const languageSettingsList = await httpWrapper<Array<LanguageSettings>>(
      "GET",
      `${SMS_API_BASE}/surveys/language_settings`,
      {}
    );
    dispatch(getLanguageSettingsListSuccess(languageSettingsList));
  } catch (e) {
    handleError(e);
    dispatch(getLanguageSettingsListError());
  }
};

export const getEcEmailSettings = (surveysId: string) => async (
  dispatch: AppDispatch
) => {
  dispatch(getEcEmailSettingsStart());
  try {
    const ecEmailSettings = await httpWrapper<SurveyEcEmailSettings>(
      "GET",
      `${SMS_API_BASE}/survey_settings/ec_email`,
      { surveysId }
    );
    dispatch(getEcEmailSettingsSuccess(ecEmailSettings));
  } catch (e) {
    handleError(e);
    dispatch(getEcEmailSettingsError());
  }
};

export const editEcEmailSettings = (form: SurveyEcEmailSettingsData) => async (
  dispatch: AppDispatch
) => {
  dispatch(editEcEmailSettingsStart());
  try {
    await httpWrapper("POST", `${SMS_API_BASE}/survey_settings/ec_email`, form);
    showNotification(i18n.t("survey.editSuccess"));
    dispatch(editEcEmailSettingsSuccess());
  } catch (e) {
    const error: any = e;
    handleError(error);
    dispatch(editEcEmailSettingsError(error));
  }
};
