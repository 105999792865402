/* istanbul ignore file */
import React from "react";
import ReactDOM from "react-dom";
import "./ant-custom.css";
import "./index.scss";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import store from "store/store";
import { Provider } from "react-redux";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  gtmId: "GTM-M7RPW23",
};

TagManager.initialize(tagManagerArgs);

const rootElement = document.getElementById("root");
ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  rootElement
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
