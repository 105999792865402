import React from "react";
import { Page, setPage } from "features/appstate/AppStateSlice";
import { LandingPageSettings, MEOSite } from "api/sms-api";
import { Spin, Tabs } from "antd";
import LandingPageSettingsEdit from "./LandingPageSettingsEdit";
import { RootState } from "store/store";
import { connect } from "react-redux";
import { getLandingPageSettings } from "./LandingPageSettingsActions";
import i18n from "i18n";
import ReviewLinks from "features/review_link/ReviewLinks";
import { RouteComponentProps, useParams } from "react-router-dom";
import { pushTo } from "utils/navigation";

const { TabPane } = Tabs;

interface LandingPageSettingsDetailsProps {
  meoSite: MEOSite;
  item?: LandingPageSettings;

  loading: boolean;
  getLandingPageSettings: (id: string) => void;
  setPage: (page: Page) => void;
}

const LandingPageSettingsDetails: React.FC<
  LandingPageSettingsDetailsProps & RouteComponentProps
> = (props) => {
  let { key } = useParams() as any;
  const { id } = useParams() as any;

  if (!key) {
    key = "settings";
  }

  React.useEffect(() => {
    props.setPage(Page.landingPageSettings);
  }, [props.setPage]);

  React.useEffect(() => {
    props.getLandingPageSettings(id);
  }, [props.getLandingPageSettings, id]);

  if (!props.item || props.loading) {
    return <Spin />;
  }

  const onTabClick = (key: string) => {
    pushTo(
      props.history,
      `/${Page.landingPageSettings}/${props.item!.id}/${key}`,
      {}
    );
  };

  return (
    <Tabs activeKey={key} onTabClick={onTabClick}>
      <TabPane tab={i18n.t("landingPageSettings.tabs.settings")} key="settings">
        <LandingPageSettingsEdit item={props.item} />
      </TabPane>
      <TabPane
        tab={i18n.t("landingPageSettings.tabs.reviewLinks")}
        key="reviewLinks"
      >
        <ReviewLinks {...props} landingPageSettingsId={props.item.id} />
      </TabPane>
    </Tabs>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    item: state.landingPageSettings.item,
    loading: state.landingPageSettings.getLoading,
    meoSite: state.seo.meoSite!,
  };
};

const mapDispatchToProps = {
  setPage,
  getLandingPageSettings,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LandingPageSettingsDetails);
