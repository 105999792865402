import React from "react";
import { Menu, Layout } from "antd";
import SubMenu from "antd/lib/menu/SubMenu";
import { toggleSidebar, Page } from "features/appstate/AppStateSlice";
import { RootState } from "store/store";
import { connect } from "react-redux";
import i18n from "i18n";
import Drawer from "rc-drawer";
import "rc-drawer/assets/index.css";
import {
  FileDoneOutlined,
  ShopOutlined,
  ReadOutlined,
  MailOutlined,
  EditOutlined,
  FileTextOutlined,
  StopOutlined,
} from "@ant-design/icons";
import { mobileMediaQuery, surveyTypeStore } from "../constants";
import Media from "react-media";
import SMSLink from "./SMSLink";
import { MODE_SURVEY } from "../constants";

const { Sider } = Layout;

interface SidebarProps {
  page: Page;
  collapsed: boolean;
  toggleSidebar: () => void;
  surveyType?: string;
}

// For desktop, use a static sidebar (antd Sider wrapping antd Menu)
// For mobile, use a collapsible sidebar (rc-drawer wrapping antd Menu)
const Sidebar: React.FC<SidebarProps> = (props) => {
  const handleMenuButtonClick = () => {
    props.toggleSidebar();
  };
  const surveyType = props.surveyType || surveyTypeStore;
  const isSurveyTypeStore = surveyType === surveyTypeStore;
  const ecProductsDisplay = !isSurveyTypeStore ? (
    <Menu.Item key={Page.productSettings} onClick={handleMenuButtonClick}>
      <SMSLink
        to={`/${Page.productSettings}`}
        queryParams={{ mode: MODE_SURVEY, surveyType }}
      >
        <ShopOutlined />
        {i18n.t("menu.productSettings")}
      </SMSLink>
    </Menu.Item>
  ) : null;
  const ecEmailSettingsDisplay = !isSurveyTypeStore ? (
    <SubMenu key="ecEmailSettings" title={i18n.t("menu.emailSettings")}>
      <Menu.Item key={Page.mailTemplates} onClick={handleMenuButtonClick}>
        <SMSLink
          to={`/${Page.mailTemplates}`}
          queryParams={{ mode: MODE_SURVEY, surveyType }}
        >
          <EditOutlined />
          {i18n.t("menu.mailTemplates")}
        </SMSLink>
      </Menu.Item>
      <Menu.Item key={Page.mailTemplateSenders} onClick={handleMenuButtonClick}>
        <SMSLink
          to={`/${Page.mailTemplateSenders}`}
          queryParams={{ mode: MODE_SURVEY, surveyType }}
        >
          <MailOutlined />
          {i18n.t("menu.mailTemplateSenders")}
        </SMSLink>
      </Menu.Item>
      <Menu.Item key={Page.orderSurveyEmails} onClick={handleMenuButtonClick}>
        <SMSLink
          to={`/${Page.orderSurveyEmails}`}
          queryParams={{ mode: MODE_SURVEY, surveyType }}
        >
          <ShopOutlined />
          {i18n.t("menu.orderSurveyEmails")}
        </SMSLink>
      </Menu.Item>
      <Menu.Item key={Page.optoutPageTemplate} onClick={handleMenuButtonClick}>
        <SMSLink
          to={`/${Page.optoutPageTemplate}`}
          queryParams={{ mode: MODE_SURVEY, surveyType }}
        >
          <FileTextOutlined />
          {i18n.t("menu.optoutPageTemplate")}
        </SMSLink>
      </Menu.Item>
      <Menu.Item
        key={Page.mailExclusionSettings}
        onClick={handleMenuButtonClick}
      >
        <SMSLink
          to={`/${Page.mailExclusionSettings}`}
          queryParams={{ mode: MODE_SURVEY, surveyType }}
        >
          <StopOutlined />
          {i18n.t("menu.mailExclusionSettings")}
        </SMSLink>
      </Menu.Item>
    </SubMenu>
  ) : null;
  const ecOrderHistoryDisplay = !isSurveyTypeStore ? (
    <SubMenu key="ecOrderHistory" title={i18n.t("menu.orderHistory")}>
      <Menu.Item key={Page.orderHistory} onClick={handleMenuButtonClick}>
        <SMSLink
          to={`/${Page.orderHistory}`}
          queryParams={{ mode: MODE_SURVEY, surveyType }}
        >
          <ShopOutlined />
          {i18n.t("menu.orderHistory")}
        </SMSLink>
      </Menu.Item>
      <Menu.Item key={Page.ecLinkStatus} onClick={handleMenuButtonClick}>
        <SMSLink
          to={`/${Page.ecLinkStatus}`}
          queryParams={{ mode: MODE_SURVEY, surveyType }}
        >
          <ShopOutlined />
          {i18n.t("menu.ecLinkStatus")}
        </SMSLink>
      </Menu.Item>
    </SubMenu>
  ) : null;
  const storeMiddleDisplay = isSurveyTypeStore ? (
    <Menu.Item key={Page.meoSiteSettings} onClick={handleMenuButtonClick}>
      <SMSLink
        to={`/${Page.meoSiteSettings}`}
        queryParams={{ mode: MODE_SURVEY, surveyType }}
      >
        <ShopOutlined />
        {i18n.t("menu.meoSiteSettings")}
      </SMSLink>
    </Menu.Item>
  ) : null;

  const menu = (
    <Menu
      mode="inline"
      selectedKeys={[props.page]}
      style={{ borderRight: 0, width: 256 }}
    >
      <Menu.Item key={Page.survey} onClick={handleMenuButtonClick}>
        <SMSLink
          to={`/${Page.survey}`}
          queryParams={{ mode: MODE_SURVEY, surveyType: props.surveyType }}
        >
          <FileDoneOutlined />
          {i18n.t("menu.survey")}
        </SMSLink>
      </Menu.Item>
      {storeMiddleDisplay}
      {ecProductsDisplay}
      <Menu.Item key={Page.contractDetails} onClick={handleMenuButtonClick}>
        <SMSLink
          to={`/${Page.contractDetails}`}
          queryParams={{ mode: MODE_SURVEY, surveyType: props.surveyType }}
        >
          <ReadOutlined />
          {i18n.t("menu.contractDetails")}
        </SMSLink>
      </Menu.Item>
      {ecEmailSettingsDisplay}
      {ecOrderHistoryDisplay}
    </Menu>
  );

  return (
    <Media query={mobileMediaQuery}>
      {(smallScreen) => {
        return smallScreen ? (
          <Drawer
            className="sidebar"
            open={!props.collapsed}
            width="240px"
            handler={false} // No default "close" button
            showMask={false} // Don't make the content unclickable when menu is open
          >
            {menu}
          </Drawer>
        ) : (
          <Sider className="sidebar" width={256}>
            {menu}
          </Sider>
        );
      }}
    </Media>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    page: state.app.page,
    meoSite: state.seo.meoSite,
    collapsed: state.app.sidebarCollapsed,
  };
};

const mapDispatchToProps = {
  toggleSidebar,
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
