import React, { useEffect } from "react";
import { Page, setPage } from "features/appstate/AppStateSlice";
import {
  Surveys,
  SurveysDeleteForm,
  Paginated,
  PaginationParams,
} from "api/sms-api";
import { Spin, Table, Button, Row, Col, Popconfirm } from "antd";
import { RootState } from "store/store";
import { connect } from "react-redux";
import { getSurveyList, deleteSurveys, duplicateSurvey } from "./SurveyActions";
import {
  setSelectedSurveys,
  clearSurvey,
  clearPickedQuestions,
  clearSelectedSurveys,
  clearItems,
} from "./SurveySlice";
import i18n from "i18n";
import { RouteComponentProps, useLocation, useHistory } from "react-router-dom";
import SMSLink from "components/SMSLink";
import { compact } from "lodash";
import {
  PlusOutlined,
  SettingOutlined,
  ShopOutlined,
  CommentOutlined,
  DeleteOutlined,
  CheckOutlined,
  FieldTimeOutlined,
} from "@ant-design/icons";
import _ from "lodash";
import SimplePagination, {
  PaginatedComponentProps,
} from "components/SimplePagination";
import qs from "qs";
import { MODE_SURVEY, surveyTypeStore } from "../../constants";

const PAGE_LIMIT = 100;

interface SurveyListProps {
  items: Paginated<Surveys>;
  listLoading: boolean;
  getSurveyList: (params: PaginationParams) => void;
  clearItems: () => void;

  deleteSurveys: (params: SurveysDeleteForm) => void;
  multipleDeleteFinished: boolean;
  multipleDeleteLoading: boolean;

  selectedSurveys: Array<{ id: string }>;
  setSelectedSurveys: (selected: Array<{ id: string }>) => void;
  clearSelectedSurveys: () => void;

  setPage: (page: Page) => void;
  clearPickedQuestions: () => void;
  clearSurvey: () => void;

  duplicateSurvey: (params: SurveysDeleteForm) => void;
}

const SurveyTable: React.FC<
  SurveyListProps & RouteComponentProps & PaginatedComponentProps
> = (props) => {
  const history = useHistory();
  const location = useLocation();
  const parsed = qs.parse(location.search, { ignoreQueryPrefix: true });
  const isSurveyTypeStore = parsed.surveyType === surveyTypeStore;
  let surveyLinkItem = "meoSites";
  let pageItem = Page.meoSites;
  if (!isSurveyTypeStore) {
    surveyLinkItem = "products";
    pageItem = Page.products;
  }

  useEffect(() => {
    props.setPage(Page.survey);
  }, [props.setPage]);

  useEffect(() => {
    props.clearSurvey();
    props.clearPickedQuestions();
    const params = {
      ..._.omit(parsed, ["mode"]),
    };
    props.getSurveyList({ ...params, limit: PAGE_LIMIT });
  }, [props.getSurveyList, location.search]);

  useEffect(() => {
    if (props.multipleDeleteFinished && !props.multipleDeleteLoading) {
      props.clearSelectedSurveys();
      const params = {
        ..._.omit(parsed, ["mode", "before", "after"]),
      };
      props.getSurveyList({ ...params, limit: PAGE_LIMIT });
    }
  }, [
    props.getSurveyList,
    props.multipleDeleteFinished,
    props.multipleDeleteLoading,
  ]);

  useEffect(() => {
    return () => {
      props.clearItems();
      props.clearSelectedSurveys();
    };
  }, []);

  if (props.listLoading) {
    return <Spin />;
  }

  const columns = () =>
    compact([
      {
        title: i18n.t("survey.table.title.publish"),
        dataIndex: "publish",
        key: "publish",
        render: (publish: boolean) => {
          return (
            <>{publish && <CheckOutlined className="survey-table-publish" />}</>
          );
        },
      },
      {
        title: i18n.t("survey.table.title.title"),
        dataIndex: "title",
        key: "title",
        render: (title: string, record: Surveys) => {
          return (
            <SMSLink
              to={`/${Page.survey}/edit`}
              queryParams={{
                id: record.id,
                type: "edit",
                mode: MODE_SURVEY,
                surveyType: parsed.surveyType || surveyTypeStore,
              }}
            >
              {title || i18n.t("survey.table.item.untitled")}
            </SMSLink>
          );
        },
      },
      {
        title: null,
        key: "buttons",
        render: (_: any, survey: Surveys): JSX.Element => {
          return (
            <div className="survey-table-buttons">
              <SMSLink
                to={`/${Page.survey}/settings`}
                queryParams={{
                  id: survey.id,
                  mode: MODE_SURVEY,
                  surveyType: parsed.surveyType || surveyTypeStore,
                }}
              >
                <Button icon={<SettingOutlined />}>
                  {i18n.t("survey.table.item.settings")}
                </Button>
              </SMSLink>
              <SMSLink
                to={`/${pageItem}`}
                queryParams={{
                  id: survey.id,
                  mode: MODE_SURVEY,
                  surveyType: parsed.surveyType || surveyTypeStore,
                }}
              >
                <Button icon={<ShopOutlined />}>
                  {i18n.t(`survey.table.item.${surveyLinkItem}`)}
                </Button>
              </SMSLink>
              <SMSLink
                to={`/${Page.survey}/reviewMediaSettings`}
                queryParams={{
                  id: survey.id,
                  mode: MODE_SURVEY,
                  surveyType: parsed.surveyType || surveyTypeStore,
                }}
              >
                <Button icon={<CommentOutlined />}>
                  {i18n.t("survey.table.item.reviewMediaSettings")}
                </Button>
              </SMSLink>
              {!isSurveyTypeStore && (
                <SMSLink
                  to={`/${Page.survey}/ecEmailSettings`}
                  queryParams={{
                    id: survey.id,
                    mode: MODE_SURVEY,
                    surveyType: parsed.surveyType || surveyTypeStore,
                  }}
                >
                  <Button icon={<FieldTimeOutlined />}>
                    {i18n.t("survey.table.item.ecEmailSettings")}
                  </Button>
                </SMSLink>
              )}
            </div>
          );
        },
      },
    ]);

  const addButton = (
    <SMSLink
      to={`${Page.survey}/add`}
      queryParams={{
        mode: MODE_SURVEY,
        surveyType: parsed.surveyType || surveyTypeStore,
      }}
    >
      <Button icon={<PlusOutlined />} type="primary">
        {i18n.t("survey.table.settings.add")}
      </Button>
    </SMSLink>
  );

  const handleDuplicate = async () => {
    const selectedIds: Array<string> = props.selectedSurveys.map((s) => s.id!);
    const form: SurveysDeleteForm = {
      surveyIds: selectedIds,
    };
    await props.duplicateSurvey(form);
    await props.getSurveyList({ limit: 100 });
  };

  const duplicateButton = (
    <Button
      icon={<PlusOutlined />}
      type="primary"
      disabled={props.selectedSurveys.length < 1}
      onClick={handleDuplicate}
    >
      {i18n.t("survey.table.settings.duplicate")}
    </Button>
  );

  const handleDelete = () => {
    const selectedIds: Array<string> = props.selectedSurveys.map((s) => s.id!);
    const form: SurveysDeleteForm = {
      surveyIds: selectedIds,
    };
    props.deleteSurveys(form);
  };

  const deleteButton = (
    <Col xs={4} md={6} lg={4}>
      <Popconfirm
        title={i18n.t("survey.table.settings.deleteCountConfirm", {
          count: props.selectedSurveys.length,
        })}
        disabled={props.selectedSurveys.length < 1}
        onConfirm={handleDelete}
        okText={i18n.t("common.delete")}
        cancelText={i18n.t("common.cancel")}
        okType="danger"
      >
        <Button
          danger={props.selectedSurveys.length > 0}
          disabled={props.selectedSurveys.length < 1}
          icon={<DeleteOutlined />}
        >
          {i18n.t("common.delete")}
        </Button>
      </Popconfirm>
    </Col>
  );

  const selectedRowKeys = props.selectedSurveys.map(({ id }) => id);

  const handleSelect = (record: Surveys, isSelected: boolean) => {
    if (isSelected) {
      // Adding
      const newSelected = [
        ...props.selectedSurveys,
        {
          id: record.id,
        },
      ];
      props.setSelectedSurveys(newSelected);
    } else {
      // Removing
      let current = [...props.selectedSurveys];
      current = current.filter((c) => c.id !== record.id);
      props.setSelectedSurveys(current);
    }
  };

  const handleSelectAll = (isSelected: boolean, changeRows: Array<Surveys>) => {
    if (isSelected) {
      // Add all
      const newSelections = changeRows.map((a) => {
        return {
          id: a.id,
        };
      });

      // Add the newly selected rows, deduplicating by key
      const newResult = _.uniqBy(
        [...props.selectedSurveys, ...newSelections],
        (a: { id: string }) => {
          return a.id!;
        }
      );
      props.setSelectedSurveys(newResult);
    } else {
      // Remove all
      props.setSelectedSurveys([]);
    }
  };

  const rowSelection = {
    selectedRowKeys,
    onSelect: handleSelect,
    onSelectAll: handleSelectAll,
    preserveSelectedRowKeys: true,
  };

  const pagination = (
    <SimplePagination
      items={props.items.items}
      getCursor={(model: Surveys) => model.createdAt}
      sortDescending={true}
      hasRight={props.items.hasRight}
      hasLeft={props.items.hasLeft}
      location={props.location}
      history={history}
      match={props.match}
    />
  );

  return (
    <div>
      <div className="table-button-row">
        <Row className="form-button-row">
          {addButton}
          {duplicateButton}
          {deleteButton}
        </Row>
      </div>
      <Table
        rowSelection={rowSelection}
        dataSource={props.items.items}
        columns={columns()}
        rowKey={(surveys: Surveys) => surveys.id}
        size="middle"
        pagination={false}
        loading={props.listLoading}
      />
      <Row className="pagination-row">{pagination}</Row>
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    items: state.survey.items,
    listLoading: state.survey.listLoading,
    selectedSurveys: state.survey.selectedSurveys,
    multipleDeleteFinished: state.survey.multipleDeleteFinished,
    multipleDeleteLoading: state.survey.multipleDeleteLoading,
  };
};

const mapDispatchToProps = {
  setPage,
  getSurveyList,
  deleteSurveys,
  clearItems,
  setSelectedSurveys,
  clearSelectedSurveys,
  clearSurvey,
  clearPickedQuestions,
  duplicateSurvey,
};

export default connect(mapStateToProps, mapDispatchToProps)(SurveyTable);
