import React from "react";
import { Page, setPage } from "features/appstate/AppStateSlice";
import {
  LandingPageSettings,
  MEOSite,
  Paginated,
  LandingData,
  LandingDataPreviewForm,
  ReviewLinkColors,
} from "api/sms-api";
import { Spin, Table, Button, Row, Alert, Modal } from "antd";
import { RootState } from "store/store";
import { connect } from "react-redux";
import {
  listLandingPageSettings,
  getPreviewData,
} from "./LandingPageSettingsActions";
import { getColors } from "features/review_link/ReviewLinkActions";
import { clearItems } from "./LandingPageSettingsSlice";
import i18n from "i18n";
import { RouteComponentProps, useLocation, useHistory } from "react-router-dom";
import { pushTo } from "utils/navigation";
import SMSLink from "components/SMSLink";
import { has, compact } from "lodash";
import { PlusOutlined } from "@ant-design/icons";
import qs from "qs";
import { RollbackOutlined } from "@ant-design/icons";
import { setSavedForm } from "features/publish/PublishSlice";
import { ModifiedPublishForm } from "components/SendForm";
import {
  TemplateSavedForm,
  setSavedForm as setSavedTemplateForm,
} from "features/templates/TemplatesSlice";
import { showPreview } from "./Preview";
import { BorderOutlined, QrcodeOutlined } from "@ant-design/icons";
import LandingPageQR from "components/LandingPageQR";
import Media from "react-media";
import { mobileMediaQuery } from "../../constants";
import LandingPageLinkCopyButton from "./LandingPageLinkCopyButton";

interface LandingPageSettingsProps {
  items: Paginated<LandingPageSettings>;
  previewData?: LandingData;
  getPreviewLoading: boolean;
  loading: boolean;
  meoSite: MEOSite;
  savedPublishForm?: ModifiedPublishForm;
  savedTemplateForm?: TemplateSavedForm;

  setPage: (page: Page) => void;
  listLandingPageSettings: (meoSiteId: number) => void;
  setSavedPublishForm: (form: ModifiedPublishForm) => void;
  setSavedTemplateForm: (form: TemplateSavedForm) => void;
  getPreviewData: (form: LandingDataPreviewForm) => void;
  clearItems: () => void;

  colors?: ReviewLinkColors;
  getColors: () => void;
}

const LandingPageSettingsTable: React.FC<
  LandingPageSettingsProps & RouteComponentProps
> = (props) => {
  const history = useHistory();
  const location = useLocation();
  const [selecting, setSelecting] = React.useState("");
  const [previewVisible, setPreviewVisible] = React.useState(false);

  function handleOk() {
    setPreviewVisible(false);
  }

  React.useEffect(() => {
    props.setPage(Page.landingPageSettings);
  }, [props.setPage]);

  React.useEffect(() => {
    if (
      !props.getPreviewLoading &&
      props.previewData &&
      previewVisible &&
      props.colors
    ) {
      showPreview(props.previewData, props.colors, handleOk);
    }
  }, [props.getPreviewLoading, props.previewData, props.colors]);

  React.useEffect(() => {
    const parsed = qs.parse(location.search, { ignoreQueryPrefix: true });
    if (has(parsed, "selecting")) {
      setSelecting(parsed["selecting"] as string);
    }

    props.listLandingPageSettings(props.meoSite.id);
  }, [props.listLandingPageSettings, props.meoSite, location.search]);

  React.useEffect(() => {
    return () => {
      props.clearItems();
    };
  }, [props.clearItems]);

  React.useEffect(() => {
    props.getColors();
  }, [props.getColors]);

  if (props.loading && !props.colors) {
    return <Spin />;
  }

  // Select a page, or if null, go back without selecting anything
  const selectPage = (lps?: LandingPageSettings) => {
    const getNewMessage = (baseMessage: string, cursorPosition?: number) => {
      if (lps) {
        const link = "${link:" + lps.name + "}";

        // Insert the link at the appropriate cursor position if it was saved
        // (normally it should be...)
        if (cursorPosition) {
          const before = baseMessage.substr(0, cursorPosition);
          const after = baseMessage.substr(cursorPosition);
          return before + link + after;
        } else {
          // Otherwise, just put the link at the end
          return baseMessage + link;
        }
      } else {
        return baseMessage;
      }
    };

    if (selecting === `/${Page.publish}`) {
      if (props.savedPublishForm) {
        const form = Object.assign({}, props.savedPublishForm);
        form.message = getNewMessage(form.message, form.cursorPosition);
        props.setSavedPublishForm(form);
      }
    } else {
      // Saved template form
      if (props.savedTemplateForm) {
        const form = Object.assign({}, props.savedTemplateForm);
        form.content = getNewMessage(form.content, form.cursorPosition);
        props.setSavedTemplateForm(form);
      }
    }
    pushTo(history, selecting, { shortcutSelected: "landing_page" });
  };

  const handlePreview = (lps: LandingPageSettings) => {
    const previewForm = {
      landingPageSettingsId: lps.id,
      meoSiteId: props.meoSite.id,
      logoUrl: lps.logoUrl,
      message: lps.message,
    };
    props.getPreviewData(previewForm);
    setPreviewVisible(true);
  };

  const handleQR = (lps: LandingPageSettings) => {
    Modal.info({
      title: null,
      icon: null,
      content: <LandingPageQR landingPageSettings={lps} />,
    });
  };

  const columns = (smallScreen: boolean) =>
    compact([
      {
        title: i18n.t("landingPageSettings.name"),
        dataIndex: "name",
        key: "name",
        render: (name: string, record: LandingPageSettings) => {
          if (selecting) {
            return (
              <Button type="link" onClick={() => selectPage(record)}>
                {name}
              </Button>
            );
          } else {
            return (
              <SMSLink to={`/${Page.landingPageSettings}/${record.id}`}>
                {name}
              </SMSLink>
            );
          }
        },
      },
      smallScreen
        ? null
        : {
            title: i18n.t("landingPageSettings.title"),
            dataIndex: "title",
            key: "title",
            render: (title: string) => {
              return title;
            },
          },
      {
        title: null,
        key: "buttons",
        render: (_: any, lps: LandingPageSettings): JSX.Element => {
          return (
            <div className="landing-page-buttons">
              <Button
                loading={props.getPreviewLoading}
                onClick={() => handlePreview(lps)}
                icon={<BorderOutlined />}
              >
                {i18n.t("landingPageSettings.preview")}
              </Button>
              <Button onClick={() => handleQR(lps)} icon={<QrcodeOutlined />}>
                {i18n.t("landingPageSettings.qrCode")}
              </Button>
              <LandingPageLinkCopyButton lpsId={lps.id} />
            </div>
          );
        },
      },
    ]);

  const addButton = (
    <SMSLink to={`${Page.landingPageSettings}/add`}>
      <Button icon={<PlusOutlined />} type="primary">
        {i18n.t("landingPageSettings.add")}
      </Button>
    </SMSLink>
  );

  const selectingExplanation = selecting && (
    <Alert
      message={
        <div>
          <span>{i18n.t("landingPageSettings.selectingExplanation")}</span>
          <Button
            icon={<RollbackOutlined />}
            onClick={() => selectPage(undefined)}
          >
            {i18n.t("common.back")}
          </Button>
        </div>
      }
      type="info"
      style={{ marginBottom: "2em" }}
    />
  );

  return (
    <Media query={mobileMediaQuery}>
      {(smallScreen) => (
        <div>
          {selectingExplanation}
          <Table
            dataSource={props.items.items}
            columns={columns(smallScreen)}
            rowKey={(lps: LandingPageSettings) => lps.id}
            size="middle"
            pagination={false}
            loading={props.loading}
          />
          <Row className="form-button-row">
            {props.items.items.length < 10 && addButton}
          </Row>
          <div style={{ textAlign: "center", margin: "40px 0" }}>
            <a
              href="https://www.gyro-n.com/portal/downloadables/cushionpage/"
              target="_blank"
              rel="noreferrer noopener nofollow"
            >
              <img
                src="https://www.gyro-n.com/export/sites/www.gyro-n.com/static/images/portal/cushionpage_image_dl.png"
                style={{ maxWidth: "70%", marginBottom: "1em" }}
              />
            </a>
            <a
              href="https://www.gyro-n.com/portal/downloadables/review/"
              target="_blank"
              rel="noreferrer noopener nofollow"
            >
              <img
                src="https://www.gyro-n.com/export/sites/www.gyro-n.com/static/images/review/download_thumb.png"
                style={{ maxWidth: "70%" }}
              />
            </a>
          </div>
        </div>
      )}
    </Media>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    items: state.landingPageSettings.items,
    loading: state.landingPageSettings.listLoading,
    meoSite: state.seo.meoSite!,
    savedPublishForm: state.publish.savedForm,
    savedTemplateForm: state.templates.savedForm,
    previewData: state.landingPageSettings.previewData,
    getPreviewLoading: state.landingPageSettings.getPreviewLoading,
    colors: state.reviewLinks.colors,
  };
};

const mapDispatchToProps = {
  setPage,
  listLandingPageSettings,
  setSavedPublishForm: setSavedForm,
  setSavedTemplateForm,
  getPreviewData,
  clearItems,
  getColors,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LandingPageSettingsTable);
