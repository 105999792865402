import { setLocale } from "yup";
import i18n from "i18n";

export function setValidationMessages() {
  setLocale({
    mixed: {
      required: i18n.t("validation.required"),
    },
  });
}
