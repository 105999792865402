import { notification } from "antd";

const defaultDuration = 4;

notification.config({
  placement: "bottomRight",
  duration: defaultDuration,
});

export function showNotification(text: string, duration?: number) {
  notification.success({
    message: text,
    duration: duration || defaultDuration,
  });
}

export function showErrorNotification(text: string) {
  notification.error({
    message: text,
  });
}
