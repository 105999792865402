import camelCaseKeys from "camelcase-keys";
import axios, { Method } from "axios";
import { SSO_BASE, SMS_API_BASE } from "../constants";
import snakeCaseKeys from "snakecase-keys";
import { CustomError } from "errors/errors";
import qs from "qs";
import _ from "lodash";
import Cookies from "universal-cookie";

export async function httpWrapper<R>(
  method: Method,
  url: string,
  data: any,
  headers: Record<string, any> = {}
): Promise<R> {
  let fullData = data;
  let fullUrl = url;
  const snakeCased = snakeCaseKeys(data, { deep: true });

  if (!(data instanceof FormData)) {
    fullUrl = url;
    fullData = snakeCased;
  }
  // For GET and DELETE requests, data should go in the query string. Otherwise
  // (for POST and PATCH), data goes in the request body.
  if (method.toUpperCase() === "GET" || method.toUpperCase() === "DELETE") {
    if (!_.isEmpty(snakeCased)) {
      const queryString = qs.stringify(snakeCased, { indices: false });
      fullUrl = `${url}?${queryString}`;
    }
    fullData = {};
  }

  try {
    const response = await axios({
      method,
      url: fullUrl,
      timeout: 60000, // ms
      data: fullData,
      headers,
      withCredentials: true,
      validateStatus: function () {
        return true;
      },
    });

    if (response.status === 401) {
      // any 401 response being returned from the API will immediately redirect the user to the SSO login screen
      if (method.toUpperCase() === "GET") {
        const cookies = new Cookies();
        cookies.set("gyr_review_session_redirect", location.href, {
          path: "/",
        });
      }
      window.location = `${SSO_BASE}/login?service=${SMS_API_BASE}/meta/login_callback` as any;
      return Promise.reject({ error: CustomError.Unauthed });
    } else if (response.status === 404) {
      return Promise.reject({ error: CustomError.NotFound });
    } else if (response.status >= 500) {
      // this will always be some kind of internal error
      return Promise.reject({ error: CustomError.Unknown });
    } else if (response.status >= 400) {
      return Promise.reject(response.data);
    } else {
      const camelCased = camelCaseKeys(response.data || {}, { deep: true });
      return Promise.resolve((camelCased as unknown) as R);
    }
  } catch (e) {
    // The HTTP request itself failing before it gets an API response is an internal error
    return Promise.reject({ error: CustomError.Unknown });
  }
}
