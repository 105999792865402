import React from "react";
import { Menu, Layout, Divider } from "antd";
import { toggleSidebar, Page } from "features/appstate/AppStateSlice";
import { RootState } from "store/store";
import { connect } from "react-redux";
import i18n from "i18n";
import Drawer from "rc-drawer";
import "rc-drawer/assets/index.css";
import {
  SendOutlined,
  ContactsOutlined,
  ProfileOutlined,
  CalendarOutlined,
  HistoryOutlined,
  SettingOutlined,
  HomeOutlined,
  LineChartOutlined,
  GroupOutlined,
  CommentOutlined,
  FormOutlined,
} from "@ant-design/icons";
import { mobileMediaQuery } from "../constants";
import Media from "react-media";
import SMSLink from "./SMSLink";
import { MEOSite } from "api/sms-api";
import BookmarkQR from "./BookmarkQR";

const { Sider } = Layout;

interface SidebarProps {
  page: Page;
  meoSite?: MEOSite;
  collapsed: boolean;
  toggleSidebar: () => void;
}

// For desktop, use a static sidebar (antd Sider wrapping antd Menu)
// For mobile, use a collapsible sidebar (rc-drawer wrapping antd Menu)
const Sidebar: React.FC<SidebarProps> = (props) => {
  const handleMenuButtonClick = () => {
    props.toggleSidebar();
  };
  const menu = (
    <Menu
      mode="inline"
      selectedKeys={[props.page]}
      style={{ borderRight: 0, width: 256 }}
    >
      <Menu.SubMenu
        key="publishSubmenu"
        icon={<SendOutlined />}
        title={<span className="submenu-title">{i18n.t("menu.publish")}</span>}
      >
        <Menu.Item key={Page.publish} onClick={handleMenuButtonClick}>
          <SMSLink to={`/${Page.publish}`}>
            <SendOutlined />
            {i18n.t("menu.publish")}
          </SMSLink>
        </Menu.Item>
        <Menu.Item key={Page.templates} onClick={handleMenuButtonClick}>
          <SMSLink to={`/${Page.templates}`}>
            <ProfileOutlined />
            {i18n.t("menu.templates")}
          </SMSLink>
        </Menu.Item>
        <Menu.Item key={Page.scheduled} onClick={handleMenuButtonClick}>
          <SMSLink to={`/${Page.scheduled}`}>
            <CalendarOutlined />
            {i18n.t("menu.scheduled")}
          </SMSLink>
        </Menu.Item>
        <Menu.Item key={Page.history} onClick={handleMenuButtonClick}>
          <SMSLink to={`/${Page.history}`}>
            <HistoryOutlined />
            {i18n.t("menu.history")}
          </SMSLink>
        </Menu.Item>
        <Menu.Item key={Page.client} onClick={handleMenuButtonClick}>
          <SMSLink to={`/${Page.client}`}>
            <SettingOutlined />
            {i18n.t("menu.client")}
          </SMSLink>
        </Menu.Item>
      </Menu.SubMenu>
      <Menu.SubMenu
        key="addressSubmenu"
        icon={<ContactsOutlined />}
        title={
          <span className="submenu-title">{i18n.t("menu.addresses")}</span>
        }
      >
        <Menu.Item key={Page.addresses} onClick={handleMenuButtonClick}>
          <SMSLink to={`/${Page.addresses}`}>
            <ContactsOutlined />
            {i18n.t("menu.addresses")}
          </SMSLink>
        </Menu.Item>
        <Menu.Item key={Page.group} onClick={handleMenuButtonClick}>
          <SMSLink to={`/${Page.group}`}>
            <GroupOutlined />
            {i18n.t("menu.group")}
          </SMSLink>
        </Menu.Item>
      </Menu.SubMenu>
      <Menu.Item key={Page.landingPageSettings} onClick={handleMenuButtonClick}>
        <SMSLink to={`/${Page.landingPageSettings}`}>
          <HomeOutlined />
          {i18n.t("menu.landingPageSettings")}
        </SMSLink>
      </Menu.Item>
      <Menu.SubMenu
        key="reviewsSubmenu"
        icon={<CommentOutlined />}
        title={<span className="submenu-title">{i18n.t("menu.reviews")}</span>}
      >
        <Menu.Item key={Page.reviews} onClick={handleMenuButtonClick}>
          <SMSLink to={`/${Page.reviews}`}>
            <CommentOutlined />
            {i18n.t("menu.reviews")}
          </SMSLink>
        </Menu.Item>
        <Menu.Item key={Page.replyTemplates} onClick={handleMenuButtonClick}>
          <SMSLink to={`/${Page.replyTemplates}`}>
            <FormOutlined />
            {i18n.t("menu.replyTemplates")}
          </SMSLink>
        </Menu.Item>
      </Menu.SubMenu>
      <Menu.Item key={Page.statistics} onClick={handleMenuButtonClick}>
        <SMSLink to={`/${Page.statistics}`}>
          <LineChartOutlined />
          {i18n.t("menu.statistics")}
        </SMSLink>
      </Menu.Item>
    </Menu>
  );

  const qr = (
    <div>
      <Divider />
      <div style={{ textAlign: "center" }}>
        {props.meoSite && <BookmarkQR meoSiteId={props.meoSite.id} />}
      </div>
    </div>
  );

  return (
    <Media query={mobileMediaQuery}>
      {(smallScreen) => {
        return smallScreen ? (
          <Drawer
            className="sidebar"
            open={!props.collapsed}
            width="240px"
            handler={false} // No default "close" button
            showMask={false} // Don't make the content unclickable when menu is open
          >
            {menu}
            {qr}
          </Drawer>
        ) : (
          <Sider className="sidebar" width={256}>
            {menu}
            {qr}
          </Sider>
        );
      }}
    </Media>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    page: state.app.page,
    meoSite: state.seo.meoSite,
    collapsed: state.app.sidebarCollapsed,
  };
};

const mapDispatchToProps = {
  toggleSidebar,
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
