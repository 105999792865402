import { AppDispatch, AppThunk, AsyncAppThunk } from "store/store";
import { httpWrapper } from "../../utils/http";
import { SMS_API_BASE } from "../../constants";
import { handleError } from "errors/errors";
import {
  listMailTemplateStart,
  listMailTemplateSuccess,
  listMailTemplateError,
  addMailTemplateStart,
  addMailTemplateSuccess,
  addMailTemplateError,
  editMailTemplateStart,
  editMailTemplateSuccess,
  editMailTemplateError,
  getMailTemplateStart,
  getMailTemplateSuccess,
  getMailTemplateError,
  deleteMailTemplateStart,
  deleteMailTemplateSuccess,
  deleteMailTemplateError,
  sendMailTemplateTestMailStart,
  sendMailTemplateTestMailSuccess,
  sendMailTemplateTestMailError,
} from "./MailTemplateSlice";
import {
  MailTemplateWithSender,
  MailTemplateAddForm,
  MailTemplateDeleteForm,
  Paginated,
  PaginationParams,
  MailTemplateSentTestMailForm,
} from "../../api/sms-api";
import i18n from "i18n";
import { showNotification } from "utils/notifications";

export const listMailTemplates = (params: PaginationParams): AppThunk => async (
  dispatch: AppDispatch
) => {
  dispatch(listMailTemplateStart());
  try {
    const mailTemplates = await httpWrapper<Paginated<MailTemplateWithSender>>(
      "GET",
      `${SMS_API_BASE}/mail_template/list`,
      params
    );
    dispatch(listMailTemplateSuccess(mailTemplates));
  } catch (e) {
    handleError(e);
    dispatch(listMailTemplateError(e));
  }
};

export const addMailTemplate = (
  params: MailTemplateAddForm
): AsyncAppThunk<void> => async (dispatch: AppDispatch) => {
  dispatch(addMailTemplateStart());
  try {
    await httpWrapper("POST", `${SMS_API_BASE}/mail_template/add`, params);

    showNotification(i18n.t("mailTemplate.createSuccess"));
    dispatch(addMailTemplateSuccess());
  } catch (e) {
    handleError(e);
    dispatch(addMailTemplateError(e));
  }
};

export const editMailTemplate = (
  id: string,
  params: MailTemplateAddForm
): AsyncAppThunk<void> => async (dispatch: AppDispatch) => {
  dispatch(editMailTemplateStart());
  try {
    await httpWrapper(
      "PATCH",
      `${SMS_API_BASE}/mail_template/update/${id}`,
      params
    );

    showNotification(i18n.t("mailTemplate.editSuccess"));
    dispatch(editMailTemplateSuccess());
  } catch (e) {
    handleError(e);
    dispatch(editMailTemplateError(e));
  }
};

export const getMailTemplate = (id: string): AppThunk => async (
  dispatch: AppDispatch
) => {
  dispatch(getMailTemplateStart());
  try {
    const MailTemplate = await httpWrapper<MailTemplateWithSender>(
      "GET",
      `${SMS_API_BASE}/mail_template/${id}`,
      {}
    );
    dispatch(getMailTemplateSuccess(MailTemplate));
  } catch (e) {
    handleError(e);
    dispatch(getMailTemplateError(e));
  }
};

export const deleteMailTemplates = (
  params: MailTemplateDeleteForm
): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(deleteMailTemplateStart());
  try {
    await httpWrapper("POST", `${SMS_API_BASE}/mail_template/delete`, params);
    showNotification(i18n.t("mailTemplate.deleteSuccess"));
    dispatch(deleteMailTemplateSuccess());
  } catch (e) {
    handleError(e);
    dispatch(deleteMailTemplateError(e));
  }
};

export const sendMailTemplateTestMail = (
  params: MailTemplateSentTestMailForm
): AsyncAppThunk<void> => async (dispatch: AppDispatch) => {
  dispatch(sendMailTemplateTestMailStart());
  try {
    await httpWrapper(
      "POST",
      `${SMS_API_BASE}/mail_template/send_test_mail`,
      params
    );

    showNotification(i18n.t("mailTemplate.sendTestMailSuccess"));
    dispatch(sendMailTemplateTestMailSuccess());
  } catch (e) {
    handleError(e);
    dispatch(sendMailTemplateTestMailError(e));
  }
};
