import React, { useState, useEffect, useMemo, useRef } from "react";
import { Page, setPage } from "features/appstate/AppStateSlice";
import { connect } from "react-redux";
import { RootState } from "store/store";
import {
  DndContext,
  MouseSensor,
  TouchSensor,
  UniqueIdentifier,
  useSensors,
  useSensor,
  Active,
  Over,
  DragMoveEvent,
  DragStartEvent,
} from "@dnd-kit/core";
import { arrayMove } from "@dnd-kit/sortable";
import {
  addQuestions,
  editSurveys,
  geteQuestionBranchItems,
  geteQuestionGroupItems,
  getBaseQuestionItem,
  getCustomQuestionItem,
  getSelectedQuestions,
  getTemplateQuestions,
  getSurvey,
} from "./SurveyActions";
import {
  QuestionSetChanges,
  QuestionItem,
  SelectedQuestion,
  SubmitQuestionItems,
  Surveys,
  PickedQuestionOptions,
  PickedQuestion,
  QuestionJSON,
  FlattenedPickedQuestion,
} from "api/sms-api";
import { Button, Row, Col, Collapse } from "antd";
import i18n from "i18n";
import { setConditions, setFreeInput, OptionItems } from "./SurveySlice";
import DraggableItem from "./DraggableItem";
import SelectItem from "./SelectItems";
import { Formik, FormikProps } from "formik";
import { useHistory, useLocation } from "react-router-dom";
import qs from "qs";
import { pushTo } from "utils/navigation";
import QuestionEdit, { DEFAULT_VALUE } from "./QuestionEdit";
import {
  MODE_SURVEY,
  defaultRadioItemsValue,
  defaultGenerationItems,
  defaultPromptRadioItemsValue,
  defaultCheckboxItemsValue,
  surveyTypeStore,
} from "../../constants";
import { showNotification } from "utils/notifications";
import { ErrorWrapper } from "errors/errors";
import download from "js-file-download";
import moment from "moment";
import JsonRegistrationModal from "./JsonRegistrationModal";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import Ajv from "ajv";

const { Panel } = Collapse;
export const CATEGORY_TYPE_SELECTED = "selectedQuestions";
export const CATEGORY_TYPE_BRANCH = "branchQuestionItem";
export const CATEGORY_TYPE_GROUP = "groupQuestionItem";
export const CATEGORY_TYPE_BASE = "baseQuestionItem";
export const CATEGORY_TYPE_CUSTOM = "customQuestionItem";

const BRANCH_MAX_DEPTH = 2;

interface SurveyEditProps {
  setPage: (page: Page) => void;

  selectedQuestions: Array<SelectedQuestion>;
  getSelectedQuestions: (surveyId: string) => void;

  getTemplateQuestions: (templateSurveyId: string) => void;

  questionBranchItems: Array<QuestionItem>;
  geteQuestionBranchItems: () => void;

  questionGroupItems: Array<QuestionItem>;
  geteQuestionGroupItems: () => void;

  baseQuestionItem: Array<QuestionItem>;
  getBaseQuestionItem: () => void;

  customQuestionItem: Array<QuestionItem>;
  getCustomQuestionItem: () => void;

  addQuestions: (
    surveyId: string,
    params: Array<SubmitQuestionItems>,
    isClose: boolean
  ) => Promise<void>;
  editSurveys: (
    surveyId: string,
    form: QuestionSetChanges,
    isClose: boolean
  ) => Promise<void>;

  setConditions: (conditions: string) => void;
  setFreeInput: (freeInput: string) => void;

  survey: Surveys;
  getSurvey: (surveyId: string) => void;

  error?: ErrorWrapper;
}

const SurveyEdit: React.FC<SurveyEditProps> = (props) => {
  const [selectedQuestions, setSelectedQuestions] = useState<
    Array<PickedQuestion>
  >([]);
  const [errorIsGroupEmpty, setErrorIsGroupEmpty] = useState(false);
  const [formInitlValues, setFormInitlValues] = useState<{
    displayRequired: Array<string>;
    inputRequired: Array<string>;
    surveyOnly: Array<string>;
    archive: Array<string>;
  }>({
    displayRequired: [],
    inputRequired: [],
    surveyOnly: [],
    archive: [],
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isJsonRegistrationModalOpen, setJsonRegistrationModalOpen] = useState(
    false
  );
  const [errorJsonImport, setErrorJsonImport] = useState("");
  const [editQuestion, setEditQuestion] = useState<{
    index: number;
    item: PickedQuestion | null;
  } | null>(null);
  const [deleteItems, setDeleteItems] = useState<Array<string>>([]);
  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
  const [offsetLeft, setOffsetLeft] = useState(0);
  const colRef = useRef<any>(null);
  const [isCreated, setIsCreated] = useState(false);

  const flatten = (
    items: PickedQuestion[],
    parentId: string | null = null,
    depth = 0
  ): FlattenedPickedQuestion[] => {
    return items.reduce<FlattenedPickedQuestion[]>((acc, item, index) => {
      return [
        ...acc,
        { ...item, parentId, depth, index },
        ...flatten(item.children, item.selectId, depth + 1),
      ];
    }, []);
  };

  const flattenTree = (items: PickedQuestion[]): FlattenedPickedQuestion[] => {
    return flatten(items);
  };

  const removeChildrenOf = (
    items: FlattenedPickedQuestion[],
    ids: UniqueIdentifier[]
  ) => {
    const excludeParentIds = [...ids];
    return items.filter((item) => {
      if (item.parentId && excludeParentIds.includes(item.parentId)) {
        if (item.children.length) {
          excludeParentIds.push(item.selectId || "");
        }
        return false;
      }

      return true;
    });
  };

  const flattenedItems = useMemo(() => {
    const flattenedTree = flattenTree(selectedQuestions);
    const collapsedItems = flattenedTree.reduce<UniqueIdentifier[]>(
      (acc, { children, collapsed, selectId }) =>
        collapsed && children.length ? [...acc, selectId || ""] : acc,
      []
    );
    return removeChildrenOf(
      flattenedTree,
      activeId ? [activeId, ...collapsedItems] : collapsedItems
    );
  }, [activeId, selectedQuestions]);

  const transformSelectedQuestions = (
    item: SelectedQuestion
  ): PickedQuestion => {
    const selectId = uuidv4();
    return {
      id: item.id,
      title: item.title || "",
      options: item.options || null,
      displayRequired: item.displayRequired,
      inputRequired: item.inputRequired,
      surveyOnly: item.surveyOnly,
      formType: item.formType,
      selectId,
      placeholder: item.placeholder || null,
      archive: item.archive,
      children: item.children
        ? item.children.map((child) => transformSelectedQuestions(child))
        : [],
    };
  };

  let questionCounter = 0;
  const transformSubmitQuestions = (
    item: PickedQuestion
  ): SubmitQuestionItems => {
    const order = questionCounter++;
    let options: any = item.options || null;
    if (item.formType === "branch") {
      const radioItems = item.children.map((item: PickedQuestion, index) => {
        return { name: `radiogroup${index + 1}`, value: item.title, index };
      });
      options = { ...options, radioItems: radioItems };
    }
    if (item.formType === "group" && !options?.hasOwnProperty("randomCount")) {
      options = { ...options, randomCount: DEFAULT_VALUE };
    }
    return {
      id: (surveyId && type === "edit") || isCreated ? item.id : null,
      formType: item.formType,
      title: item.title,
      options,
      displayRequired: item.displayRequired,
      inputRequired: item.inputRequired,
      surveyOnly: item.surveyOnly,
      questionOrder: order,
      placeholder: item.placeholder,
      archive: item.archive,
      children: item.children.map((child) => transformSubmitQuestions(child)),
    };
  };

  const resetState = () => {
    setActiveId(null);
    setOffsetLeft(0);

    document.body.style.setProperty("cursor", "");
  };

  const getDragDepth = (offset: number, indentationWidth: number) => {
    return Math.round(offset / indentationWidth);
  };

  const getMaxDepth = ({
    previousItem,
    limitDepth,
  }: {
    previousItem: FlattenedPickedQuestion;
    limitDepth: number;
  }) => {
    let maxDepth = 0;
    if (previousItem) {
      maxDepth = previousItem.depth + 1;
    }

    if (maxDepth >= limitDepth) {
      maxDepth = limitDepth;
    }

    return maxDepth;
  };

  const getMinDepth = ({ nextItem }: { nextItem: FlattenedPickedQuestion }) => {
    if (nextItem) {
      return nextItem.depth;
    }

    return 0;
  };

  const getDepth = (
    items: FlattenedPickedQuestion[],
    index: number,
    offset: number,
    limitDepth: number
  ) => {
    const previousItem = items[index - 1];
    const nextItem = items[index + 1];
    const dragDepth = getDragDepth(offset, 50);
    const maxDepth = getMaxDepth({
      previousItem,
      limitDepth,
    });
    const minDepth = getMinDepth({ nextItem });
    let depth = dragDepth;
    if (dragDepth >= maxDepth) {
      depth = maxDepth;
    } else if (dragDepth < minDepth) {
      depth = minDepth;
    }
    return depth;
  };

  const buildTree = (
    flattenedItems: FlattenedPickedQuestion[]
  ): PickedQuestion[] => {
    const root: any = { id: "root", children: [] };
    const nodes: Record<string, any> = { [root.id]: root };
    const items = flattenedItems.map((item) => ({ ...item, children: [] }));
    for (const item of items) {
      const { selectId, children } = item;
      const parentId = item.parentId ?? root.id;
      const parent = nodes[parentId] ?? findItem(items, parentId);

      if (!selectId) {
        continue;
      }
      nodes[selectId] = { selectId, children };
      parent.children.push(item);
    }
    return root.children;
  };

  const findItem = (
    items: FlattenedPickedQuestion[],
    itemId: UniqueIdentifier
  ) => {
    return items.find(({ selectId }) => selectId === itemId);
  };

  const setProperty = <T extends keyof PickedQuestion>(
    items: PickedQuestion[],
    selectId: UniqueIdentifier,
    property: T,
    setter: (value: PickedQuestion[T]) => PickedQuestion[T]
  ) => {
    for (const item of items) {
      if (item.selectId === selectId) {
        item[property] = setter(item[property]);
        continue;
      }

      if (item.children.length) {
        item.children = setProperty(item.children, selectId, property, setter);
      }
    }

    return [...items];
  };

  const history = useHistory();
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));
  const location = useLocation();
  const parsed = qs.parse(location.search, { ignoreQueryPrefix: true });
  const surveyId = parsed.id ? parsed.id.toString() : "";
  const type = parsed.type ? parsed.type.toString() : "";

  useEffect(() => {
    props.setPage(Page.surveyEdit);
  }, [props.setPage]);

  useEffect(() => {
    if (surveyId) {
      //アンケートタイトル表示のため
      props.getSurvey(surveyId);
    }
  }, [props.getSurvey]);

  useEffect(() => {
    if (surveyId && type === "edit") {
      props.getSelectedQuestions(surveyId);
    }
    if (surveyId && type === "template") {
      props.getTemplateQuestions(surveyId);
    }
    props.geteQuestionBranchItems();
    props.geteQuestionGroupItems();
    props.getBaseQuestionItem();
    props.getCustomQuestionItem();
  }, []);

  useEffect(() => {
    if (surveyId || isCreated) {
      const selectedQuestions = props.selectedQuestions.map((item) => {
        return transformSelectedQuestions(item);
      });
      const displayRequired = selectedQuestions
        .filter((item) => item.displayRequired)
        .map((item) => item.selectId || "");
      const inputRequired = selectedQuestions
        .filter((item) => item.inputRequired)
        .map((item) => item.selectId || "");
      const surveyOnly = selectedQuestions
        .filter((item) => item.surveyOnly)
        .map((item) => item.selectId || "");
      const archive = selectedQuestions
        .filter((item) => item.archive)
        .map((item) => item.selectId || "");
      const initialValues = {
        displayRequired,
        inputRequired,
        surveyOnly,
        archive,
      };
      setFormInitlValues(initialValues);
      setSelectedQuestions(selectedQuestions);
    } else {
      setSelectedQuestions([]);
    }
  }, [props.selectedQuestions]);

  const findCategories = (id: UniqueIdentifier) => {
    const category = [
      CATEGORY_TYPE_SELECTED,
      CATEGORY_TYPE_BRANCH,
      CATEGORY_TYPE_GROUP,
      CATEGORY_TYPE_BASE,
      CATEGORY_TYPE_CUSTOM,
    ];
    let categoryName = "";
    if (category.includes(id.toString())) {
      categoryName = id.toString();
    }
    if (flattenedItems.find((item) => item.selectId === id)) {
      categoryName = CATEGORY_TYPE_SELECTED;
    }
    if (props.questionBranchItems.find((item) => item.id === id)) {
      categoryName = CATEGORY_TYPE_BRANCH;
    }
    if (props.questionGroupItems.find((item) => item.id === id)) {
      categoryName = CATEGORY_TYPE_GROUP;
    }
    if (props.baseQuestionItem.find((item) => item.id === id)) {
      categoryName = CATEGORY_TYPE_BASE;
    }
    if (props.customQuestionItem.find((item) => item.id === id)) {
      categoryName = CATEGORY_TYPE_CUSTOM;
    }
    return categoryName;
  };

  const onDragCancel = () => {
    resetState();
  };

  const onDragStart = ({ active: { id: activeId } }: DragStartEvent) => {
    setActiveId(activeId);
    document.body.style.setProperty("cursor", "grabbing");
  };

  const onDragMove = ({ delta }: DragMoveEvent) => {
    setOffsetLeft(delta.x);
  };

  const getParentId = (depth: any, previousItem: FlattenedPickedQuestion) => {
    if (depth === 0 || !previousItem) {
      return null;
    }

    if (depth === previousItem.depth) {
      return previousItem.parentId;
    }

    if (depth > previousItem.depth) {
      return previousItem.selectId;
    }

    return null;
  };

  const onDragEnd = ({
    active,
    over,
  }: {
    active: Active;
    over: Over | null;
  }) => {
    resetState();
    // 移動した項目が別の項目と接触したときに、接触した項目のIDを取得する
    const overId = over?.id;
    if (overId == null) {
      return;
    }

    // 接触した項目のカテゴリー
    const overCategory = findCategories(overId);
    // 移動させた項目のカテゴリー
    const activeCategory = findCategories(active.id);

    if (
      !overCategory ||
      !activeCategory ||
      overCategory !== CATEGORY_TYPE_SELECTED ||
      !(
        activeCategory === CATEGORY_TYPE_SELECTED ||
        activeCategory === CATEGORY_TYPE_BRANCH ||
        activeCategory === CATEGORY_TYPE_GROUP ||
        activeCategory === CATEGORY_TYPE_BASE ||
        activeCategory === CATEGORY_TYPE_CUSTOM
      )
    ) {
      return;
    }

    setSelectedQuestions((items) => {
      const clonedItems: FlattenedPickedQuestion[] = JSON.parse(
        JSON.stringify(flattenTree(items))
      );
      const overIndex = clonedItems.findIndex(
        (item) => item.selectId === overId
      );
      const overTreeItem = clonedItems[overIndex];
      const overParentItem = clonedItems.find(
        (item) => item.selectId === overTreeItem?.parentId
      );

      if (
        overCategory === CATEGORY_TYPE_SELECTED &&
        activeCategory === CATEGORY_TYPE_SELECTED
      ) {
        if (overIndex < 0) {
          return items;
        }

        const activeIndex = clonedItems.findIndex(
          (item) => item.selectId === active.id
        );
        // 選択したアンケート項目一覧内での項目移動
        const activeTreeItem = clonedItems[activeIndex];
        const activeParentItem = clonedItems.find(
          (item) => item.selectId === activeTreeItem.parentId
        );

        // 分岐選択肢内のグループ項目は分岐選択肢外に移動させない
        if (
          activeParentItem?.formType === "branch" &&
          !(overParentItem?.formType === "branch")
        ) {
          return items;
        }

        const sortedItems = arrayMove(clonedItems, activeIndex, overIndex);
        const previousItem = sortedItems[overIndex - 1];
        if (
          previousItem &&
          (previousItem.formType === "group" || previousItem.parentId) &&
          !(
            activeTreeItem.formType === "group" ||
            activeTreeItem.formType === "branch"
          )
        ) {
          // 「質問グループ」内への移動
          // グループ内にはグループ・分岐選択肢を配置しない
          let depth = 0;
          if (
            overParentItem?.formType === "branch" ||
            overTreeItem?.depth === BRANCH_MAX_DEPTH ||
            previousItem.depth === BRANCH_MAX_DEPTH ||
            (previousItem.formType === "group" && previousItem.parentId) // 1つ上の項目が分岐選択肢のグループ項目。分岐選択肢の子要素は編集ポップアップからのみ追加可能。
          ) {
            // 分岐用選択肢内のグループ項目の子要素の位置
            depth = BRANCH_MAX_DEPTH;
          } else {
            depth = getDepth(sortedItems, overIndex, offsetLeft, 1);
          }
          const parentId = getParentId(depth, previousItem) || null;
          const displayRequired = previousItem.displayRequired;
          const inputRequired = previousItem.inputRequired;
          const surveyOnly = previousItem.surveyOnly;
          sortedItems[overIndex] = {
            ...activeTreeItem,
            depth,
            parentId,
            displayRequired,
            inputRequired,
            surveyOnly,
          };
        } else if (
          overParentItem?.formType === "branch" &&
          activeParentItem?.formType === "branch"
        ) {
          // 分岐選択肢内の移動の場合、parentIdは修正しない
        } else {
          sortedItems[overIndex] = {
            ...activeTreeItem,
            parentId: null,
          };
        }
        const newItems = buildTree(sortedItems);
        return newItems;
      } else {
        // 質問グループ・基本情報・質問項目からアンケート項目への移動
        let activeItems;
        if (activeCategory === CATEGORY_TYPE_SELECTED) {
          activeItems = items;
        } else if (activeCategory === CATEGORY_TYPE_BRANCH) {
          activeItems = props.questionBranchItems;
        } else if (activeCategory === CATEGORY_TYPE_GROUP) {
          activeItems = props.questionGroupItems;
        } else if (activeCategory === CATEGORY_TYPE_BASE) {
          activeItems = props.baseQuestionItem;
        } else if (activeCategory === CATEGORY_TYPE_CUSTOM) {
          activeItems = props.customQuestionItem;
        }
        if (!activeItems) {
          return items;
        }
        const activeIndex = activeItems.findIndex(
          (item: QuestionItem | PickedQuestion) => item.id === active.id
        );
        const isBelowOverItem =
          over &&
          active.rect.current.translated &&
          active.rect.current.translated.top > over.rect.top + over.rect.height;
        const modifier = isBelowOverItem ? 1 : 0;
        let newIndex = 0;
        if (overIndex >= 0) {
          newIndex = overIndex + modifier;
        } else {
          newIndex = clonedItems.length > 0 ? clonedItems.length : 0;
        }
        if (
          overTreeItem?.formType === "group" &&
          overTreeItem.parentId !== null
        ) {
          // 分岐選択肢内のgroup項目に衝突した場合は、新しい項目をひとつ下に配置する
          newIndex = newIndex + 1;
        }

        let options = null;
        if (
          activeItems[activeIndex].formType === "radiogroup" ||
          activeItems[activeIndex].formType === "branch"
        ) {
          options = { radioItems: defaultRadioItemsValue };
        } else if (activeItems[activeIndex].formType === "generations") {
          options = { radioItems: defaultGenerationItems };
        } else if (activeItems[activeIndex].formType === "promptradiobutton") {
          options = {
            radioItems: defaultPromptRadioItemsValue,
            hasCustomText: true,
          };
        } else if (activeItems[activeIndex].formType === "checkbox") {
          options = { checkboxItems: defaultCheckboxItemsValue };
        }

        const updateItems: Array<FlattenedPickedQuestion> = [
          ...clonedItems.slice(0, newIndex),
          {
            id: uuidv4(),
            title: activeItems[activeIndex].title,
            options,
            displayRequired: false,
            inputRequired: false,
            surveyOnly: false,
            formType: activeItems[activeIndex].formType || "",
            placeholder: null,
            archive: false,
            children: [],
            parentId: null,
            depth: 0,
            selectId: uuidv4(),
            index: 0,
          },
          ...clonedItems.slice(newIndex, clonedItems.length),
        ];
        const previousItem = updateItems[newIndex - 1];
        if (
          previousItem &&
          (previousItem.formType === "group" || previousItem.parentId) &&
          !(activeItems[activeIndex].formType === "group") &&
          !(activeItems[activeIndex].formType === "branch") &&
          !(
            previousItem.formType === "group" &&
            previousItem.parentId &&
            typeof overTreeItem === "undefined"
          ) // 分岐選択肢の子要素にドラッグ＆ドロップで子要素を追加できないようにする
        ) {
          const questionWidth = colRef.current?.offsetWidth || 0;
          let depth = 0;
          if (
            overParentItem?.formType === "branch" ||
            overTreeItem?.depth === 2
          ) {
            // 分岐用選択肢内のグループ項目の子要素の位置
            depth = 2;
          } else {
            depth = getDepth(
              updateItems,
              newIndex,
              offsetLeft - questionWidth,
              1
            );
          }
          updateItems[newIndex].depth = depth;
          updateItems[newIndex].parentId =
            getParentId(depth, previousItem) || null;
          updateItems[newIndex].displayRequired = previousItem.displayRequired;
          updateItems[newIndex].inputRequired = previousItem.inputRequired;
          updateItems[newIndex].surveyOnly = previousItem.surveyOnly;
        }

        // 分岐用選択肢の場合は、グループ項目を追加する
        if (activeItems[activeIndex].formType === "branch") {
          const addItems = defaultRadioItemsValue.map((item) => {
            return {
              id: uuidv4(),
              title: item.value,
              options: null,
              displayRequired: false,
              inputRequired: false,
              surveyOnly: false,
              formType: "group",
              placeholder: null,
              archive: false,
              children: [],
              parentId: updateItems[newIndex].selectId || null,
              depth: updateItems[newIndex].depth + 1,
              selectId: uuidv4(),
              index: 0,
            };
          });
          updateItems.splice(newIndex + 1, 0, ...addItems);
        }

        const newItems = buildTree(updateItems);
        return newItems;
      }
    });
  };

  const handleSubmit = async (isClose: boolean) => {
    setErrorIsGroupEmpty(false);
    const clonedItems: FlattenedPickedQuestion[] = JSON.parse(
      JSON.stringify(flattenTree(selectedQuestions))
    );
    const emptyGroupItems = clonedItems.filter((item) => {
      const parent = clonedItems.find(
        (clonedItem) => clonedItem.selectId === item.parentId
      );
      return (
        item.formType === "group" &&
        item.children.length === 0 &&
        parent?.formType !== "branch" // 分岐選択肢内のグループはバリデーションを除外
      );
    });
    if (emptyGroupItems.length > 0) {
      setErrorIsGroupEmpty(true);
      return;
    } else {
      setErrorIsGroupEmpty(false);
    }

    const submitItems: Array<SubmitQuestionItems> = selectedQuestions.map(
      (item) => transformSubmitQuestions(item)
    );
    if ((surveyId && type === "edit") || isCreated) {
      const paramSurveyId =
        type === "template" || isCreated ? props.survey.id : surveyId;
      await props.editSurveys(
        paramSurveyId,
        {
          deleteItems: deleteItems,
          updateItems: submitItems,
        },
        isClose
      );
    } else if (props.survey.id && !isCreated) {
      setIsCreated(true);
      await props.addQuestions(props.survey.id, submitItems, isClose);
    } else {
      showNotification(i18n.t("survey.createError"));
    }

    if (isClose) {
      pushTo(history, "/survey", {
        mode: MODE_SURVEY,
        surveyType: parsed.surveyType || surveyTypeStore,
      });
    }
  };

  const handleCancel = () => {
    pushTo(history, "/survey", {
      mode: MODE_SURVEY,
      surveyType: parsed.surveyType || surveyTypeStore,
    });
  };

  const handleClickCheckBox = (checkIndex: number, propertyName: string) => {
    const targetItem = flattenedItems.filter(
      (_, index) => index === checkIndex
    );
    if (targetItem.length > 0) {
      const parentItem = targetItem[0];
      const childrenItems: Array<string> = [];
      parentItem.children.forEach((child) => {
        if (child.selectId) {
          childrenItems.push(child.selectId);
        }
        if (child.children.length > 0) {
          child.children.forEach((item) => {
            if (item.selectId) {
              childrenItems.push(item.selectId);
            }
          });
        }
      });
      const targetItems =
        parentItem.formType === "branch" && propertyName !== "displayRequired"
          ? [parentItem.selectId]
          : [parentItem.selectId, ...childrenItems];
      const clonedItems: FlattenedPickedQuestion[] = JSON.parse(
        JSON.stringify(flattenTree(selectedQuestions))
      );
      const updateItems = clonedItems.map((item) =>
        targetItems.includes(item.selectId)
          ? { ...item, [propertyName]: !parentItem[propertyName] }
          : item
      );
      const newItems = buildTree(updateItems);
      setSelectedQuestions(newItems);
    }
  };

  const handleClickDisplayRequired = (checkIndex: number) => {
    handleClickCheckBox(checkIndex, "displayRequired");
  };

  const handleClickInputRequired = (checkIndex: number) => {
    handleClickCheckBox(checkIndex, "inputRequired");
  };

  const handleClickSurveyOnly = (checkIndex: number) => {
    handleClickCheckBox(checkIndex, "surveyOnly");
  };

  const handleClickArchive = (checkIndex: number) => {
    const targetFlattenedItem = flattenedItems.find(
      (_, index) => index === checkIndex
    );
    const clonedItems: FlattenedPickedQuestion[] = JSON.parse(
      JSON.stringify(flattenTree(selectedQuestions))
    );
    const updateItems = clonedItems.map((item) =>
      item.selectId === targetFlattenedItem?.selectId
        ? { ...item, archive: !item.archive }
        : item
    );
    const newItems = buildTree(updateItems);
    setSelectedQuestions(newItems);
  };

  const deleteSelectedQuestions = (deleteIndex: number) => {
    const deleteItem = flattenedItems.filter(
      (_, index) => index === deleteIndex
    );
    if (deleteItem.length > 0) {
      const parentItem = deleteItem[0];

      // データベースから削除する項目の一覧を取得
      const childrenItems: Array<string> = [];
      parentItem.children.forEach((child) => {
        childrenItems.push(child.id);
        if (child.children.length > 0) {
          child.children.forEach((item) => {
            childrenItems.push(item.id);
          });
        }
      });
      const targetDeleteItems = [
        ...deleteItems,
        parentItem.id,
        ...childrenItems,
      ].filter((id): id is string => id !== undefined);
      setDeleteItems(targetDeleteItems);

      // UIから削除する項目の一覧を取得
      const childrenUIItems: Array<string> = [];
      parentItem.children.map((child) => {
        if (child.selectId) {
          childrenUIItems.push(child.selectId);
        }
        if (child.children.length > 0) {
          child.children.forEach((item) => {
            if (item.selectId) {
              childrenUIItems.push(item.selectId);
            }
          });
        }
      });
      const updateUIItems = [parentItem.selectId, ...childrenUIItems];
      const clonedItems: FlattenedPickedQuestion[] = JSON.parse(
        JSON.stringify(flattenTree(selectedQuestions))
      );
      const filterItem = clonedItems.filter(
        (item) => !updateUIItems.includes(item.selectId || "")
      );
      const newItems = buildTree(filterItem);
      setSelectedQuestions(newItems);
    }
  };

  const duplicateQuestion = (clickIndex: number) => {
    const addItems: Array<FlattenedPickedQuestion> = [];
    const addItemsRecursively = (
      duplicateItem: FlattenedPickedQuestion,
      index: number
    ) => {
      const selectId = uuidv4();
      addItems.push({
        ...duplicateItem,
        id: uuidv4(),
        selectId,
        index,
      });
      if (duplicateItem.children.length > 0) {
        duplicateItem.children.forEach((item, childIndex) => {
          const childrenItem: FlattenedPickedQuestion = {
            ...item,
            parentId: selectId,
            depth: duplicateItem.depth + 1,
            index: childIndex,
          };
          addItemsRecursively(childrenItem, childIndex);
        });
      }
    };

    const countAllChildren = (
      obj: FlattenedPickedQuestion | PickedQuestion
    ) => {
      if (!obj.children) return 0;

      let count = obj.children.length;

      obj.children.forEach((child) => {
        count += countAllChildren(child);
      });

      return count;
    };

    const duplicateItem = flattenedItems.find(
      (_, index) => index === clickIndex
    );

    if (duplicateItem) {
      const clonedItems: FlattenedPickedQuestion[] = JSON.parse(
        JSON.stringify(flattenTree(selectedQuestions))
      );
      addItemsRecursively(duplicateItem, clickIndex + 1);
      const duplicateItemChildrenLength = countAllChildren(duplicateItem);
      const newIndex = clickIndex + duplicateItemChildrenLength;
      const updateItems: Array<FlattenedPickedQuestion> = [
        ...clonedItems.slice(0, newIndex + 1),
        ...addItems,
        ...clonedItems.slice(newIndex + 1, clonedItems.length),
      ];
      const newItems = buildTree(updateItems);
      setSelectedQuestions(newItems);
    }
  };

  const handleCollapse = (id: UniqueIdentifier) => {
    setSelectedQuestions((items) =>
      setProperty(items, id, "collapsed", (value) => {
        return !value;
      })
    );
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const saveEditedResult = (result: any) => {
    let options: PickedQuestionOptions | null = null;
    if (result.formType === "conditions") {
      options = { conditions: result.options };
      props.setConditions(result.options || "");
    } else if (
      result.formType === "generations" ||
      result.formType === "radiogroup" ||
      result.formType === "promptradiobutton"
    ) {
      options = {
        radioItems: result.options.radioItems,
        hasCustomText: result.options.hasCustomText,
        customTextPlaceholder: result.options.customTextPlaceholder,
        defaultItem: result.options.defaultItem,
        freeInput: result.options.freeInput,
      };
      props.setFreeInput(result.options.freeInput.value || "");
    } else if (result.formType === "branch") {
      options = {
        radioItems: result.options.radioItems,
        hasCustomText: result.options.hasCustomText,
        customTextPlaceholder: result.options.customTextPlaceholder,
        defaultItem: result.options.defaultItem,
      };
    } else if (result.formType === "checkbox") {
      options = {
        checkboxItems: result.options.checkboxItems,
        hasCustomText: result.options.hasCustomText,
        customTextPlaceholder: result.options.customTextPlaceholder,
        defaultItem: result.options.defaultItem,
        freeInput: result.options.freeInput,
      };
      props.setFreeInput(result.options.freeInput.value || "");
    } else if (result.formType === "group") {
      options = { randomCount: result.options };
    } else {
      options = { freeInput: result.options.freeInput };
    }
    const targetFlattenedItem = flattenedItems.find(
      (_, index) => index === result.index
    );
    const clonedItems: FlattenedPickedQuestion[] = JSON.parse(
      JSON.stringify(flattenTree(selectedQuestions))
    );
    const updateItems = clonedItems.map((item) =>
      item.selectId === targetFlattenedItem?.selectId
        ? {
            ...item,
            title: result.title,
            options: { ...item.options, ...options },
            placeholder: result.placeholder,
          }
        : item
    );

    if (result.formType === "branch") {
      const updateItemIndex = clonedItems.findIndex(
        (item) => item.selectId === targetFlattenedItem?.selectId
      );

      // 削除
      updateItems[updateItemIndex].children.forEach((child) => {
        // 分岐選択肢の子要素がresult.options.radioItemsに存在するか確認
        const existsInRadioItems = result.options.radioItems.some(
          (radioItem: OptionItems) => radioItem.selectId === child.selectId
        );

        if (!existsInRadioItems) {
          // アイテムが存在しない場合、削除
          // データベースから削除
          const childrenItems = child.children.map((child) => child.id);
          const targetDeleteItems = [
            ...deleteItems,
            child.id,
            ...childrenItems,
          ].filter((id): id is string => id !== undefined);
          setDeleteItems(targetDeleteItems);

          // UIから削除
          const childrenUiItems = child.children.map((child) => child.selectId);
          const updateUIItems = [child.selectId, ...childrenUiItems];
          for (let i = updateItems.length - 1; i >= 0; i--) {
            if (updateUIItems.includes(updateItems[i].selectId)) {
              updateItems.splice(i, 1);
            }
          }
        }
      });

      result.options.radioItems.forEach(
        (radioItem: OptionItems, index: number) => {
          const notPrompt = Boolean(radioItem.notPrompt);
          const existItemIndex = updateItems.findIndex(
            (updateItem) => updateItem.selectId === radioItem.selectId
          );
          if (existItemIndex > 0) {
            // 更新
            updateItems[existItemIndex].title = radioItem.value;
            updateItems[existItemIndex].options = {
              ...updateItems[existItemIndex].options,
              notPrompt,
            };
          } else {
            // 追加
            const prevRadioItem = result.options.radioItems[index - 1];
            const prevItemIndex = updateItems.findIndex(
              (updateItem) => updateItem.selectId === prevRadioItem.selectId
            );
            const prevItem = updateItems.find(
              (updateItem) => updateItem.selectId === prevRadioItem.selectId
            );
            const childCount = prevItem?.children.length || 0;
            const addItems: FlattenedPickedQuestion = {
              id: uuidv4(),
              title: radioItem.value,
              options: { notPrompt },
              displayRequired: updateItems[updateItemIndex].displayRequired,
              inputRequired: false,
              surveyOnly: false,
              formType: "group",
              placeholder: null,
              archive: false,
              children: [],
              parentId: updateItems[updateItemIndex].selectId || null,
              depth: updateItems[updateItemIndex].depth + 1,
              selectId: uuidv4(),
              index: 0,
            };
            updateItems.splice(prevItemIndex + childCount + 1, 0, addItems);
          }
        }
      );
    }

    const newItems = buildTree(updateItems);
    setSelectedQuestions(newItems);
    setIsModalOpen(false);
  };

  const handleClickQuestion = (editIndex: number) => {
    const editItem = flattenedItems.filter((_, index) => index === editIndex);

    setEditQuestion({
      index: editIndex,
      item: editItem.length > 0 ? editItem[0] : null,
    });

    if (editItem[0].formType === "conditions") {
      props.setConditions(editItem[0].options?.conditions || "");
    }
    editItem.forEach((item) => {
      const freeInputValue = item?.options?.freeInput?.value || "";
      props.setFreeInput(freeInputValue);
    });
    setIsModalOpen(true);
  };

  const createJson = () => {
    const omitQuestion = (item: PickedQuestion) => {
      const omittedQuestion: any = _.omit(item, ["id", "selectId"]);
      if (omittedQuestion.children) {
        omittedQuestion.children = omittedQuestion.children.map(
          (child: PickedQuestion) => omitQuestion(child)
        );
      }
      return omittedQuestion;
    };

    const result = JSON.stringify(
      {
        questions: selectedQuestions.map((item) => omitQuestion(item)),
      },
      null,
      4
    );

    return result;
  };

  const downloadQuestionsJSON = () => {
    download(
      createJson(),
      `${i18n.t("survey.questionsActions.fileName")}_${moment().format(
        "YYYYMMDDHHmmss"
      )}.json`,
      "application/json"
    );
  };

  const openUploadModal = () => {
    setJsonRegistrationModalOpen(true);
  };

  const closeUploadModal = () => {
    setJsonRegistrationModalOpen(false);
    setErrorJsonImport("");
  };

  const uploadQuestions = (
    result: FormikProps<{ questions: string }>,
    isCreateNewJson = false
  ) => {
    try {
      const obj = JSON.parse(result.values.questions);
      let questionsObj: QuestionJSON[];
      if (!obj.hasOwnProperty("questions")) {
        throw new Error(`questions${i18n.t("survey.errors.missingProperty")}`);
      } else {
        questionsObj = JSON.parse(result.values.questions).questions;
      }
      const ajv = new Ajv();
      const schema = {
        required: [
          "title",
          "options",
          "displayRequired",
          "inputRequired",
          "surveyOnly",
          "formType",
          "placeholder",
          "archive",
        ],
        type: "object",
        properties: {
          title: {
            type: "string",
          },
          options: {
            type: ["object", "null"],
          },
          displayRequired: {
            type: "boolean",
          },
          inputRequired: {
            type: "boolean",
          },
          surveyOnly: {
            type: "boolean",
          },
          formType: {
            type: "string",
          },
          placeholder: {
            type: ["string", "null"],
          },
          archive: {
            type: "boolean",
          },
        },
      };
      const validate = ajv.compile(schema);

      const transformItems = (item: QuestionJSON) => {
        const valid = validate(item);
        if (!valid) {
          const err = validate.errors?.[0];
          if (err) {
            switch (err.keyword) {
              case "required":
                throw new Error(
                  `${err.params.missingProperty}${i18n.t(
                    "survey.errors.missingProperty"
                  )}`
                );
              case "type":
                throw new Error(i18n.t("survey.errors.invalidValue"));
            }
          }
        }

        const formTypeList = [
          "email",
          "gender",
          "age",
          "name",
          "hurigana",
          "birth",
          "prefectures",
          "conditions",
          "generations",
          "text",
          "comment",
          "rating",
          "number",
          "radiogroup",
          "checkbox",
          "promptradiobutton",
          "group",
          "branch",
        ];

        if (!formTypeList.includes(item.formType)) {
          throw new Error(i18n.t("survey.errors.invalidFormType"));
        }

        const transformedChildren = item.children
          ? item.children.map((child) => transformItems(child))
          : [];

        const result: PickedQuestion = {
          ...item,
          id: uuidv4(),
          selectId: uuidv4(),
          children: transformedChildren,
        };
        return result;
      };

      const updateItems = questionsObj.map((item: QuestionJSON) =>
        transformItems(item)
      );

      if (isCreateNewJson) {
        setSelectedQuestions(updateItems);
      } else {
        const newItems = [...selectedQuestions, ...updateItems];
        setSelectedQuestions(newItems);
      }

      setJsonRegistrationModalOpen(false);
      setErrorJsonImport("");

      if ((surveyId && type === "edit") || isCreated) {
        const clonedItems: FlattenedPickedQuestion[] = JSON.parse(
          JSON.stringify(flattenTree(selectedQuestions))
        );
        const targetItems = clonedItems.map((question) => question.id);
        if (targetItems.length > 0) {
          const updateItems = [...deleteItems, ...targetItems];
          if (isCreateNewJson) {
            setDeleteItems(updateItems);
          }
        }
      }
    } catch (e: any) {
      if (e instanceof SyntaxError) {
        setErrorJsonImport(i18n.t("survey.errors.jsonParseError"));
      } else {
        setErrorJsonImport(e.message);
      }
    }
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={formInitlValues}
      onSubmit={() => {
        // 「保存」ボタンのonClickイベントで処理を行う
      }}
    >
      {(_formikBag) => {
        return (
          <DndContext
            sensors={sensors}
            onDragEnd={onDragEnd}
            onDragMove={onDragMove}
            onDragStart={onDragStart}
            onDragCancel={onDragCancel}
          >
            <label style={{ color: "red", fontWeight: "bold" }}>
              {errorIsGroupEmpty
                ? i18n.t("survey.errors.groupItemEmpty")
                : null}
              {props.error ? i18n.t("errors.unknown") : null}
            </label>
            <Row justify="end" style={{ marginBottom: "24px" }}>
              <div>
                <Button onClick={handleCancel}>
                  {i18n.t("common.cancel")}
                </Button>
                <Button
                  onClick={() => handleSubmit(true)}
                  type="primary"
                  htmlType="submit"
                  style={{
                    marginLeft: "8px",
                    backgroundColor: "#5CB85C",
                    border: "#5CB85C",
                  }}
                  disabled={props.error ? true : false}
                >
                  {i18n.t("common.saveAndCloseButton")}
                </Button>
                <Button
                  onClick={() => handleSubmit(false)}
                  type="primary"
                  htmlType="submit"
                  style={{ marginLeft: "8px" }}
                  disabled={props.error ? true : false}
                >
                  {i18n.t("common.save")}
                </Button>
              </div>
            </Row>
            <Row justify="space-between">
              <Col span={7} ref={colRef}>
                <Collapse
                  defaultActiveKey={["1", "2", "3", "4"]}
                  expandIconPosition={"right"}
                  bordered={false}
                >
                  <Panel
                    header={i18n.t(
                      "survey.questionCategoriesTitle.branchQuestionItem"
                    )}
                    key="1"
                  >
                    {props.questionBranchItems.map((item) => (
                      <DraggableItem
                        key={item.id}
                        id={item.id}
                        title={item.title}
                      />
                    ))}
                  </Panel>
                  <Panel
                    header={i18n.t(
                      "survey.questionCategoriesTitle.groupQuestionItem"
                    )}
                    key="2"
                  >
                    {props.questionGroupItems.map((item) => (
                      <DraggableItem
                        key={item.id}
                        id={item.id}
                        title={item.title}
                      />
                    ))}
                  </Panel>
                  <Panel
                    header={i18n.t(
                      "survey.questionCategoriesTitle.baseQuestionItem"
                    )}
                    key="3"
                  >
                    {props.baseQuestionItem.map((item) => (
                      <DraggableItem
                        key={item.id}
                        id={item.id}
                        title={item.title}
                      />
                    ))}
                  </Panel>
                  <Panel
                    header={i18n.t(
                      "survey.questionCategoriesTitle.customQuestionItem"
                    )}
                    key="4"
                  >
                    <div>
                      {props.customQuestionItem.map((item) => (
                        <DraggableItem
                          key={item.id}
                          id={item.id}
                          title={item.title}
                        />
                      ))}
                    </div>
                  </Panel>
                </Collapse>
              </Col>
              <Col span={16} className="survey-dnd-container">
                <SelectItem
                  categoryId={CATEGORY_TYPE_SELECTED}
                  items={flattenedItems}
                  handleClickDisplayRequired={handleClickDisplayRequired}
                  handleClickInputRequired={handleClickInputRequired}
                  deleteSelectedQuestions={deleteSelectedQuestions}
                  handleClickQuestion={handleClickQuestion}
                  handleClickSurveyOnly={handleClickSurveyOnly}
                  handleClickArchive={handleClickArchive}
                  handleCollapse={handleCollapse}
                  duplicateQuestion={duplicateQuestion}
                />
                {editQuestion && editQuestion.item !== null ? (
                  <QuestionEdit
                    index={editQuestion.index}
                    item={editQuestion.item}
                    visible={isModalOpen}
                    closeModal={closeModal}
                    saveEditedResult={saveEditedResult}
                  />
                ) : null}
              </Col>
            </Row>
            <Row justify="end" style={{ marginTop: "24px" }}>
              <Col span={14} className="json-transfer-container">
                <Row justify="space-around">
                  <Button onClick={downloadQuestionsJSON}>
                    {i18n.t("survey.questionsActions.download")}
                  </Button>
                  <Button onClick={openUploadModal}>
                    {i18n.t("survey.questionsActions.upload")}
                  </Button>
                  <JsonRegistrationModal
                    isModalOpen={isJsonRegistrationModalOpen}
                    closeModal={closeUploadModal}
                    uploadQuestions={uploadQuestions}
                    error={errorJsonImport}
                  />
                </Row>
              </Col>
            </Row>
          </DndContext>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    survey: state.survey.survey!,
    selectedQuestions: state.survey.selectedQuestions,
    questionBranchItems: state.survey.questionBranchItems,
    questionGroupItems: state.survey.questionGroupItems,
    baseQuestionItem: state.survey.baseQuestionItem,
    customQuestionItem: state.survey.customQuestionItem,
    pickedQuestions: state.survey.pickedQuestions,
    error: state.survey.error,
  };
};

const mapDispatchToProps = {
  setPage,
  getSelectedQuestions,
  getTemplateQuestions,
  geteQuestionBranchItems,
  geteQuestionGroupItems,
  getBaseQuestionItem,
  getCustomQuestionItem,
  addQuestions,
  editSurveys,
  setConditions,
  setFreeInput,
  getSurvey,
};

export default connect(mapStateToProps, mapDispatchToProps)(SurveyEdit);
