import React, { ChangeEvent } from "react";
import { formItemLayout } from "../constants";
import i18n from "i18n";
import { Input, Form, AutoComplete } from "formik-antd";
import {
  CloseSquareOutlined,
  PlusOutlined,
  ContactsOutlined,
  GroupOutlined,
} from "@ant-design/icons";
import {
  Row,
  Col,
  AutoComplete as AntAutoComplete,
  Form as AntForm,
  message,
  Button,
} from "antd";
import { connect } from "react-redux";
import { RootState } from "store/store";
import { searchAddresses } from "features/address/AddressActions";
import { clearSearchResults } from "features/address/AddressSlice";
import {
  Address,
  NameAndPhoneNumber,
  MEOSite,
  PublishType,
  IdAndName,
} from "api/sms-api";
import { FormikProps, FieldArray, FormikHelpers } from "formik";
import _ from "lodash";
import {
  isErrorWrapper,
  CustomError,
  ErrorWrapper,
  SendError,
} from "errors/errors";
import { pushTo } from "utils/navigation";
import { useHistory } from "react-router-dom";

const { Option } = AntAutoComplete;

export interface FormWithRecipients {
  contacts: Array<NameAndPhoneNumber>;
  type: PublishType;
  groups: Array<IdAndName>;
}

interface PhoneNumberInputProps<A extends FormWithRecipients> {
  smallScreen: boolean;
  formikBag: FormikProps<A>;
  meoSite: MEOSite;

  searchResults: Array<Address>;
  searchLoading: boolean;
  searchAddresses: (meoSiteId: number, query: string) => void;

  setSavedForm?: (form: A) => void;
  clearSearchResults: () => void;

  onContactRemove: (i: number) => void;
  onGroupRemove: (i: number) => void;
  onContactInput: (phoneNumber: string, i: number) => void;
  scheduledId: string;
  selectingParameter: string;
}

export function handleSendErrors<A extends FormWithRecipients>(
  result: any,
  form: A,
  formikBag: FormikHelpers<A>
): void {
  function setErrors(result: ErrorWrapper, errorMessage: string) {
    const phoneNumbers = result["phone_numbers"];
    const groupIds = result["group_ids"];
    form.contacts.forEach((nameAndPhoneNumber, i) => {
      if (
        phoneNumbers &&
        phoneNumbers.indexOf(nameAndPhoneNumber.phoneNumber) !== -1
      ) {
        formikBag.setFieldError(`contacts[${i}].phoneNumber`, errorMessage);
      }
    });
    form.groups.forEach((idAndName, i) => {
      if (groupIds && groupIds.indexOf(idAndName.id) !== -1) {
        formikBag.setFieldError(`groups[${i}].id`, errorMessage);
      }
    });
  }

  if (isErrorWrapper(result) && result.error === CustomError.CannotSend) {
    for (const subError of result["errors"] as Array<ErrorWrapper>) {
      if (subError.error === SendError.CannotSendToOptOut) {
        setErrors(subError, i18n.t("errors.optedOut"));
      } else if (subError.error === SendError.CannotSendToBlacklist) {
        setErrors(subError, i18n.t("errors.blacklisted"));
      } else if (subError.error === SendError.CannotSendToOptOutGroup) {
        setErrors(subError, i18n.t("errors.optedOutGroup"));
      } else if (subError.error === SendError.CannotSendToBlacklistGroup) {
        setErrors(subError, i18n.t("errors.blacklistedGroup"));
      }
    }
  }
  if (
    isErrorWrapper(result) &&
    result.error === CustomError.ContactGroupOverlap
  ) {
    setErrors(result, i18n.t("errors.contactGroupOverlap"));
  }

  // if (
  //   isErrorWrapper(result) &&
  //   result.error === CustomError.CannotSendToOptOut
  // ) {
  //   setErrors(result, i18n.t("errors.optedOut"));
  // } else if (
  //   isErrorWrapper(result) &&
  //   result.error === CustomError.CannotSendToBlacklist
  // ) {
  //   setErrors(result, i18n.t("errors.blacklisted"));
  // }
}

const PhoneNumberInput = <A extends FormWithRecipients>(
  props: PhoneNumberInputProps<A>
) => {
  const history = useHistory();

  React.useEffect(() => {
    return () => {
      props.clearSearchResults();
    };
  }, [props.clearSearchResults]);

  // Homemade debounce because lodash's version plus formik-antd doesn't seem to work
  // when used in the `onChange` handler for some reason
  const debounceWait = 500;
  const [
    timeoutHandler,
    setTimeoutHandler,
  ] = React.useState<NodeJS.Timeout | null>(null);

  const doSearch = (query: string) => {
    props.searchAddresses(props.meoSite.id, query);
  };

  const searchHandler = (minLength: number) => (query: string) => {
    if (query.length >= minLength) {
      if (timeoutHandler) {
        clearTimeout(timeoutHandler);
      }

      setTimeoutHandler(
        setTimeout(() => {
          doSearch(query);
        }, debounceWait)
      );
    }
  };

  const handleContactRemove = (i: number) => {
    props.onContactRemove(i);
  };

  const handleGroupRemove = (i: number) => {
    props.onGroupRemove(i);
  };

  const handleContactInput = (
    event: ChangeEvent<HTMLInputElement>,
    i: number
  ) => {
    props.onContactInput(event.target.value, i);
  };

  const handleAdd = (currentNumber: number) => {
    if (currentNumber >= 99) {
      message.info(i18n.t("messageForm.maxContacts"));
    }
  };

  // Select a search result, setting all values, including the address ID
  const selectHandler = (i: number) => (_value: string, option: any) => {
    const address = JSON.parse(option.value);
    props.formikBag.setFieldValue(`contacts.${i}.name`, address["name"]);
    props.formikBag.setFieldValue(
      `contacts.${i}.phoneNumber`,
      address["phoneNumber"]
    );
    props.onContactInput(address["phoneNumber"], i);
  };

  const handleBlur = () => {
    props.clearSearchResults();
  };

  const goToSelectAddress = (form: A) => {
    if (props.setSavedForm) {
      props.setSavedForm(form);
      pushTo(history, "/addresses", {
        selecting: props.selectingParameter,
        backUrl: "",
        scheduledId: props.scheduledId,
      });
    }
  };

  const goToSelectGroup = (form: A) => {
    if (props.setSavedForm) {
      props.setSavedForm(form);
      pushTo(history, "/group", {
        selecting: props.selectingParameter,
        scheduledId: props.scheduledId,
      });
    }
  };
  // When there are enough items to overflow, add a margin to make it easier to
  // understand the UI
  const hasManyItems = props.formikBag.values["contacts"].length >= 5;
  const hasManyGroups = props.formikBag.values["groups"].length >= 5;
  return (
    <div>
      <div>
        <FieldArray
          name="contacts"
          render={(arrayHelpers) => (
            <div className="phone-number-table">
              <div
                className="phone-number-table-rows"
                style={hasManyItems ? { marginBottom: "2em" } : {}}
              >
                {props.formikBag.values["contacts"].map(
                  (contact: NameAndPhoneNumber, i: number) => {
                    return (
                      <AntForm.Item
                        key={i}
                        style={{ marginBottom: "0em" }}
                        labelCol={
                          i === 0 ? formItemLayout.labelCol : { xs: 0, sm: 6 }
                        }
                        label={
                          // Only the first phone number needs the label.
                          // Other ones get a blank label with no colon, to keep
                          // vertical alignment.
                          i === 0 ? i18n.t("common.addresses") : <div />
                        }
                        colon={i === 0}
                      >
                        <Row className="phone-number-table-row">
                          <Col xs={{ span: 24 }} sm={{ span: 16 }}>
                            <Row justify="space-between">
                              <Col style={{ flexGrow: 1 }}>
                                <Row>
                                  <Col style={{ width: "50%" }}>
                                    <Form.Item name={`contacts.${i}.name`}>
                                      <AutoComplete
                                        value={contact.name}
                                        placeholder={i18n.t("common.name")}
                                        name={`contacts.${i}.name`}
                                        filterOption={false}
                                        onSearch={searchHandler(1)}
                                        onSelect={selectHandler(i)}
                                        onBlur={handleBlur}
                                        showSearch={true}
                                        className="name-autocomplete"
                                      >
                                        {props.searchResults.map((result) => (
                                          <Option
                                            key={result.id}
                                            value={JSON.stringify(result)}
                                          >
                                            {`${result.name} (${result.phoneNumber})`}
                                          </Option>
                                        ))}
                                      </AutoComplete>
                                    </Form.Item>
                                  </Col>
                                  <Col style={{ width: "50%" }}>
                                    <Form.Item
                                      name={`contacts.${i}.phoneNumber`}
                                    >
                                      <Input
                                        value={contact.phoneNumber}
                                        placeholder={i18n.t(
                                          "common.phoneNumber"
                                        )}
                                        name={`contacts.${i}.phoneNumber`}
                                        className="phone-number"
                                        onChange={(event) => {
                                          handleContactInput(event, i);
                                        }}
                                      />
                                    </Form.Item>
                                  </Col>
                                </Row>
                              </Col>

                              {(props.formikBag.values.contacts.length > 1 ||
                                (props.formikBag.values.type ===
                                  PublishType.Cloud &&
                                  props.formikBag.values.groups.length >
                                    0)) && (
                                <Col style={{ flexGrow: 0 }}>
                                  <Button
                                    className="remove-button"
                                    icon={<CloseSquareOutlined />}
                                    onClick={() => {
                                      arrayHelpers.remove(i);
                                      handleContactRemove(i);
                                    }}
                                  />
                                </Col>
                              )}
                            </Row>
                          </Col>
                        </Row>
                      </AntForm.Item>
                    );
                  }
                )}
              </div>
              {_.isString(props.formikBag.errors.contacts) && (
                <Row style={{ marginBottom: "1em" }}>
                  <Col {...formItemLayout.labelCol} />
                  <Col {...formItemLayout.wrapperCol}>
                    <span className="error">
                      {props.formikBag.errors.contacts}
                    </span>
                  </Col>
                </Row>
              )}
              {props.formikBag.values.type === PublishType.Cloud && (
                <Row className="ant-form-item">
                  <Col {...formItemLayout.labelCol} />
                  <Col
                    className="form-button-row"
                    xs={{ span: 24 }}
                    sm={{ span: 18 }}
                  >
                    <Button
                      className="max-width-on-mobile"
                      icon={<PlusOutlined />}
                      onClick={() => {
                        handleAdd(props.formikBag.values.contacts.length);
                        arrayHelpers.push({
                          name: "",
                          phoneNumber: "",
                        });
                      }}
                    >
                      {i18n.t("messageForm.recipientAdd")}
                    </Button>
                    {props.setSavedForm && (
                      <Button
                        onClick={() =>
                          goToSelectAddress(props.formikBag.values)
                        }
                        icon={<ContactsOutlined />}
                        className="select-address max-width-on-mobile"
                      >
                        {i18n.t("messageForm.selectFromAddresses")}
                      </Button>
                    )}
                    {props.setSavedForm && (
                      <Button
                        onClick={() => goToSelectGroup(props.formikBag.values)}
                        icon={<GroupOutlined />}
                        className="select-address max-width-on-mobile"
                      >
                        {i18n.t("messageForm.selectFromGroups")}
                      </Button>
                    )}
                  </Col>
                </Row>
              )}
            </div>
          )}
        />
      </div>
      <div>
        <FieldArray
          name="groups"
          render={(arrayHelpers) => (
            <div className="phone-number-table">
              <div
                className="phone-number-table-rows"
                style={hasManyGroups ? { marginBottom: "2em" } : {}}
              >
                {props.formikBag.values["groups"].map(
                  (groups: IdAndName, i: number) => {
                    return i === 0 && groups.id == "" ? undefined : (
                      <AntForm.Item
                        key={i}
                        style={{ marginBottom: "0em" }}
                        labelCol={
                          i === 0 ? formItemLayout.labelCol : { xs: 0, sm: 6 }
                        }
                        label={i === 0 ? i18n.t("common.group") : <div />}
                        colon={i === 0}
                      >
                        <Row className="phone-number-table-row">
                          <Col xs={{ span: 24 }} sm={{ span: 16 }}>
                            <Row justify="space-between">
                              <Col style={{ flexGrow: 1 }}>
                                <Row>
                                  <Col style={{ width: "100%" }}>
                                    <Form.Item name={`groups.${i}.id`}>
                                      {groups.name}
                                    </Form.Item>
                                  </Col>
                                </Row>
                              </Col>
                              <Col style={{ flexGrow: 0 }}>
                                <Button
                                  className="remove-button"
                                  icon={<CloseSquareOutlined />}
                                  onClick={() => {
                                    arrayHelpers.remove(i);
                                    handleGroupRemove(i);
                                  }}
                                />
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </AntForm.Item>
                    );
                  }
                )}
              </div>
              {_.isString(props.formikBag.errors.groups) && (
                <Row style={{ marginBottom: "1em" }}>
                  <Col {...formItemLayout.labelCol} />
                  <Col {...formItemLayout.wrapperCol}>
                    <span className="error">
                      {props.formikBag.errors.groups}
                    </span>
                  </Col>
                </Row>
              )}
            </div>
          )}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    searchResults: state.addresses.searchResults,
    searchLoading: state.addresses.listLoading,
    meoSite: state.seo.meoSite!,
  };
};

const mapDispatchToProps = {
  searchAddresses,
  clearSearchResults,
};

export default connect(mapStateToProps, mapDispatchToProps)(PhoneNumberInput);
