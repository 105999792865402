import { Table } from "antd";
import { ActivityGroup } from "api/sms-api";
import i18n from "i18n";
import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { RootState } from "store/store";
import ActivityGroupDisplay from "./ActivityGroup";
import { getActivity } from "./AddressActions";

interface ActivityListProps {
  activity: Array<ActivityGroup>;
  loading: boolean;

  getActivity: (id: string) => void;
}

const ActivityList: React.FC<ActivityListProps> = (props) => {
  const { id } = useParams() as any;

  React.useEffect(() => {
    props.getActivity(id);
  }, [props.getActivity]);

  const columns = [
    {
      title: i18n.t("common.time"),
      key: "time",
      render: (_: any, group: ActivityGroup) => {
        // The activities are sorted inside the ActivityGroup on the API, so
        // just display the first (=earliest) time
        return moment(group.activities[0].time).format("LLLL");
      },
    },
    {
      title: i18n.t("addresses.landingPageName"),
      dataIndex: "landingPageName",
    },
    {
      title: i18n.t("addresses.tabs.activity"),
      key: "group",
      render: (_: any, group: ActivityGroup) => {
        return <ActivityGroupDisplay activityGroup={group} />;
      },
    },
  ];

  return (
    <Table
      dataSource={props.activity}
      columns={columns}
      locale={{ emptyText: i18n.t("addresses.activityEmpty") }}
      size="middle"
      pagination={false}
      loading={props.loading}
      rowKey={(group: ActivityGroup) => group.activities[0].time}
    />
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    activity: state.addresses.activity,
    loading: state.addresses.activityLoading,
  };
};

const mapDispatchToProps = {
  getActivity,
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivityList);
