import React from "react";
import { useDraggable } from "@dnd-kit/core";
import classnames from "classnames";

interface SurveyQuestionItemProps {
  id: string;
  title: string;
}

const DraggableItem: React.FC<SurveyQuestionItemProps> = ({
  id,
  title = "",
}) => {
  const {
    setNodeRef,
    listeners,
    attributes,
    transform,
    isDragging,
  } = useDraggable({
    id,
  });
  const classes = classnames("survey-dnd-item", {
    dragging: isDragging,
  });
  return (
    <li
      key={id}
      ref={setNodeRef}
      {...listeners}
      {...attributes}
      style={
        {
          "--translate-x": transform
            ? `${Math.round(transform.x)}px`
            : undefined,
          "--translate-y": transform
            ? `${Math.round(transform.y)}px`
            : undefined,
        } as React.CSSProperties
      }
      className={classes}
    >
      <div>{title}</div>
    </li>
  );
};

export default DraggableItem;
