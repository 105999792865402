import { AppDispatch, AppThunk } from "store/store";
import { httpWrapper } from "../../utils/http";
import { SMS_API_BASE } from "../../constants";
import { handleError } from "errors/errors";
import {
  getStatisticsStart,
  getStatisticsSuccess,
  getStatisticsError,
  getGMBStatisticsStart,
  getGMBStatisticsSuccess,
  getGMBStatisticsError,
} from "./StatisticsSlice";
import {
  StatisticsResponse,
  DailyStatisticsForm,
  MonthlyStatisticsForm,
  GMBStatisticsResponse,
} from "api/sms-api";

export const getDailyStatistics = (
  form: DailyStatisticsForm
): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(getStatisticsStart());
  try {
    const response = await httpWrapper<StatisticsResponse>(
      "GET",
      `${SMS_API_BASE}/statistics/daily`,
      form
    );
    dispatch(getStatisticsSuccess(response));
  } catch (e) {
    handleError(e);
    dispatch(getStatisticsError());
  }
};

export const getMonthlyStatistics = (
  form: MonthlyStatisticsForm
): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(getStatisticsStart());
  try {
    const response = await httpWrapper<StatisticsResponse>(
      "GET",
      `${SMS_API_BASE}/statistics/monthly`,
      form
    );
    dispatch(getStatisticsSuccess(response));
  } catch (e) {
    handleError(e);
    dispatch(getStatisticsError());
  }
};

export const getGMBDailyStatistics = (
  form: DailyStatisticsForm
): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(getGMBStatisticsStart());
  try {
    const response = await httpWrapper<GMBStatisticsResponse>(
      "GET",
      `${SMS_API_BASE}/statistics/gmb/daily`,
      form
    );
    dispatch(getGMBStatisticsSuccess(response));
  } catch (e) {
    handleError(e);
    dispatch(getGMBStatisticsError());
  }
};

export const getGMBMonthlyStatistics = (
  form: MonthlyStatisticsForm
): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(getGMBStatisticsStart());
  try {
    const response = await httpWrapper<GMBStatisticsResponse>(
      "GET",
      `${SMS_API_BASE}/statistics/gmb/monthly`,
      form
    );
    dispatch(getGMBStatisticsSuccess(response));
  } catch (e) {
    handleError(e);
    dispatch(getGMBStatisticsError());
  }
};
