import { AppDispatch, AppThunk, AsyncAppThunk } from "store/store";
import { httpWrapper } from "../../utils/http";
import { SMS_API_BASE } from "../../constants";
import { handleError } from "errors/errors";
import {
  listAddressesStart,
  listAddressesSuccess,
  listAddressesError,
  addAddressStart,
  addAddressSuccess,
  addAddressError,
  getAddressStart,
  getAddressSuccess,
  getAddressError,
  editAddressStart,
  editAddressSuccess,
  editAddressError,
  deleteAddressStart,
  deleteAddressSuccess,
  deleteAddressError,
  deleteAddressesStart,
  deleteAddressesSuccess,
  deleteAddressesError,
  searchAddressesSuccess,
  importStart,
  importSuccess,
  importError,
  getActivityError,
  getActivitySuccess,
  getActivityStart,
} from "./AddressSlice";
import {
  Address,
  Paginated,
  ListAddressParams,
  AddressAddForm,
  AddressDeleteForm,
  AddressImportResult,
  FullAddress,
  ActivityGroup,
} from "../../api/sms-api";
import i18n from "i18n";
import { showNotification } from "utils/notifications";

export const listAddresses = (params: ListAddressParams): AppThunk => async (
  dispatch: AppDispatch
) => {
  dispatch(listAddressesStart());
  try {
    const addresses = await httpWrapper<Paginated<FullAddress>>(
      "GET",
      `${SMS_API_BASE}/addresses`,
      params
    );

    dispatch(listAddressesSuccess(addresses));
  } catch (e) {
    handleError(e);
    dispatch(listAddressesError(e));
  }
};

export const addAddress = (
  params: AddressAddForm
): AsyncAppThunk<void> => async (dispatch: AppDispatch) => {
  dispatch(addAddressStart());
  try {
    await httpWrapper("POST", `${SMS_API_BASE}/addresses`, params);

    showNotification(i18n.t("addresses.createSuccess"));
    dispatch(addAddressSuccess());
  } catch (e) {
    handleError(e);
    dispatch(addAddressError(e));
  }
};

export const editAddress = (
  id: string,
  params: AddressAddForm
): AsyncAppThunk<void> => async (dispatch: AppDispatch) => {
  dispatch(editAddressStart());
  try {
    await httpWrapper("PATCH", `${SMS_API_BASE}/addresses/${id}`, params);

    showNotification(i18n.t("addresses.editSuccess"));
    dispatch(editAddressSuccess());
  } catch (e) {
    handleError(e);
    dispatch(editAddressError(e));
  }
};

export const getAddress = (id: string): AppThunk => async (
  dispatch: AppDispatch
) => {
  dispatch(getAddressStart());
  try {
    const address = await httpWrapper<Address>(
      "GET",
      `${SMS_API_BASE}/addresses/${id}`,
      {}
    );
    dispatch(getAddressSuccess(address));
  } catch (e) {
    handleError(e);
    dispatch(getAddressError(e));
  }
};

export const getActivity = (id: string): AppThunk => async (
  dispatch: AppDispatch
) => {
  dispatch(getActivityStart());
  try {
    const activity = await httpWrapper<Array<ActivityGroup>>(
      "GET",
      `${SMS_API_BASE}/addresses/${id}/activity`,
      {}
    );
    dispatch(getActivitySuccess(activity));
  } catch (e) {
    handleError(e);
    dispatch(getActivityError());
  }
};

export const deleteAddress = (id: string): AppThunk => async (
  dispatch: AppDispatch
) => {
  dispatch(deleteAddressStart());
  try {
    await httpWrapper("DELETE", `${SMS_API_BASE}/addresses/${id}`, {});
    showNotification(i18n.t("addresses.deleteSuccess"));
    dispatch(deleteAddressSuccess());
  } catch (e) {
    handleError(e);
    dispatch(deleteAddressError(e));
  }
};

export const deleteAddresses = (params: AddressDeleteForm): AppThunk => async (
  dispatch: AppDispatch
) => {
  dispatch(deleteAddressesStart());
  try {
    await httpWrapper("PUT", `${SMS_API_BASE}/addresses`, params);
    showNotification(i18n.t("addresses.deleteSuccess"));
    dispatch(deleteAddressesSuccess());
  } catch (e) {
    handleError(e);
    dispatch(deleteAddressesError(e));
  }
};

export const searchAddresses = (
  meoSiteId: number,
  query: string
): AppThunk => async (dispatch: AppDispatch) => {
  dispatch(listAddressesStart());
  try {
    const results = await httpWrapper<Array<Address>>(
      "GET",
      `${SMS_API_BASE}/addresses/search?meo_site_id=${meoSiteId}&query=${query}`,
      {}
    );
    dispatch(searchAddressesSuccess(results));
  } catch (e) {
    handleError(e);
    dispatch(listAddressesError(e));
  }
};

export const importCsv = (
  file: File,
  meoSiteId: number
): AsyncAppThunk<AddressImportResult | undefined> => async (
  dispatch: AppDispatch
) => {
  dispatch(importStart());
  try {
    const formData = new FormData();
    formData.append("file", file);
    const result = await httpWrapper<AddressImportResult>(
      "POST",
      `${SMS_API_BASE}/addresses/import?meo_site_id=${meoSiteId}`,
      formData,
      { "Content-Type": "multipart/form-data" }
    );
    dispatch(importSuccess(result));
    return result;
  } catch (e) {
    handleError(e);
    dispatch(importError());
  }
};
