import { Button } from "antd";
import { LANDING_PAGE_BASE } from "../../constants";
import i18n from "i18n";
import React from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { showNotification } from "utils/notifications";
import { CopyOutlined } from "@ant-design/icons";

interface LandingPageLinkCopyButtonProps {
  lpsId: string;
}

const LandingPageLinkCopyButton: React.FC<LandingPageLinkCopyButtonProps> = (
  props
) => {
  return (
    <CopyToClipboard
      text={`${LANDING_PAGE_BASE}/static/${props.lpsId}`}
      onCopy={() =>
        showNotification(i18n.t("landingPageSettings.copyUrlNotification"))
      }
    >
      <Button icon={<CopyOutlined />}>
        {i18n.t("landingPageSettings.copyUrl")}
      </Button>
    </CopyToClipboard>
  );
};

export default LandingPageLinkCopyButton;
