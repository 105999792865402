import {
  SurveyMeoSitesParams,
  Paginated,
  SurveyMeoSites,
  SurveyMeoSiteDeleteForm,
  SurveyMeoSitesCsvImportResult,
  SurveyMeoSitesEditForm,
} from "api/sms-api";
import { AppDispatch } from "store/store";
import { httpWrapper } from "utils/http";
import {
  getSurveyMeoSitesListStart,
  getSurveyMeoSitesListSuccess,
  getSurveyMeoSitesListError,
  deleteSurveyMeoSitesListStart,
  deleteSurveyMeoSitesListSuccess,
  deleteSurveyMeoSitesListError,
  exportStart,
  exportSuccess,
  exportError,
  importStart,
  importSuccess,
  importError,
  editReviewLinkStart,
  editReviewLinkSuccess,
  editReviewLinkError,
} from "./SurveyMeoSitesSlice";
import { SMS_API_BASE, BOM } from "../../constants";
import { handleError } from "errors/errors";
import { showNotification } from "utils/notifications";
import i18n from "i18n";
import download from "js-file-download";

export const getSurveyMeoSitesList = (params: SurveyMeoSitesParams) => async (
  dispatch: AppDispatch
) => {
  dispatch(getSurveyMeoSitesListStart());
  try {
    const surveyMeoSitesList = await httpWrapper<Paginated<SurveyMeoSites>>(
      "GET",
      `${SMS_API_BASE}/surveys/meo_sites`,
      params
    );
    dispatch(getSurveyMeoSitesListSuccess(surveyMeoSitesList));
  } catch (e) {
    handleError(e);
    dispatch(getSurveyMeoSitesListError());
  }
};

export const deleteSurveyMeoSites = (params: SurveyMeoSiteDeleteForm) => async (
  dispatch: AppDispatch
) => {
  dispatch(deleteSurveyMeoSitesListStart());
  try {
    await httpWrapper("PUT", `${SMS_API_BASE}/surveys/meo_sites`, params);
    showNotification(i18n.t("meoSites.table.settings.deleteSuccess"));
    dispatch(deleteSurveyMeoSitesListSuccess());
  } catch (e) {
    const error: any = e;
    handleError(error);
    dispatch(deleteSurveyMeoSitesListError(error));
  }
};

export const exportCsv = (id: string) => async (dispatch: AppDispatch) => {
  dispatch(exportStart());
  try {
    const result = await httpWrapper<any>(
      "POST",
      `${SMS_API_BASE}/surveys/meo_sites/export/${id}`,
      {}
    );
    download(`${BOM}${result}`, i18n.t("meoSites.csv.fileName"));
    dispatch(exportSuccess());
    return result;
  } catch (e) {
    handleError(e);
    dispatch(exportError());
  }
};

export const importCsv = (file: File) => async (dispatch: AppDispatch) => {
  dispatch(importStart());
  try {
    const formData = new FormData();
    formData.append("file", file);
    const result = await httpWrapper<SurveyMeoSitesCsvImportResult>(
      "POST",
      `${SMS_API_BASE}/surveys/meo_sites/import`,
      formData,
      { "Content-Type": "multipart/form-data" }
    );
    dispatch(importSuccess(result));
    return result;
  } catch (e) {
    handleError(e);
    dispatch(importError());
  }
};

export const editReviewLink = (
  id: string,
  form: SurveyMeoSitesEditForm
) => async (dispatch: AppDispatch) => {
  dispatch(editReviewLinkStart());
  try {
    await httpWrapper("PATCH", `${SMS_API_BASE}/surveys/meo_sites/${id}`, form);
    dispatch(editReviewLinkSuccess());
  } catch (e) {
    const error: any = e;
    handleError(error);
    dispatch(editReviewLinkError(error));
  }
};
