import React, { ChangeEvent } from "react";
import { connect } from "react-redux";
import { Page, setPage } from "features/appstate/AppStateSlice";
import { Formik } from "formik";
import { Form, Input } from "formik-antd";
import { Row, Button, Col, Spin } from "antd";
import i18n from "i18n";
import {
  TemplateAddForm,
  MEOSite,
  MessageStatsForm,
  MessageStats,
} from "api/sms-api";
import { formItemLayout, defaultMessage } from "../../constants";
import { RootState } from "store/store";
import { addTemplate, getMessageStats } from "./TemplatesActions";
import {
  setSavedForm,
  clearTemplate,
  TemplateSavedForm,
} from "./TemplatesSlice";
import { templateAddFormSchema } from "validation";
import BackButton from "components/BackButton";
import CreateButton from "components/CreateButton";
import { pushTo } from "utils/navigation";
import { useHistory, useLocation } from "react-router-dom";
import qs from "qs";
import { convertContent } from "utils/strings";

interface TemplateAddProps {
  setPage: (page: Page) => void;
  addTemplate: (form: TemplateAddForm) => Promise<void>;
  loading: boolean;
  meoSite: MEOSite;

  messageStats?: MessageStats;
  messageStatsLoading: boolean;
  getMessageStats: (form: MessageStatsForm) => void;

  savedForm?: TemplateAddForm;
  setSavedForm: (form: TemplateSavedForm) => void;
  clearTemplate: () => void;
}

const TemplateAdd: React.FC<TemplateAddProps> = (props) => {
  const location = useLocation();
  const history = useHistory();
  const parsed = qs.parse(location.search, { ignoreQueryPrefix: true });
  const [cursorPosition, setCursorPosition] = React.useState(0);
  const debounceWait = 500;
  const [
    timeoutHandler,
    setTimeoutHandler,
  ] = React.useState<NodeJS.Timeout | null>(null);

  React.useEffect(() => {
    props.setPage(Page.templates);
  }, [props.setPage]);

  React.useEffect(() => {
    props.getMessageStats({
      message: defaultMessage,
      contacts: [{ phoneNumber: "+819000000000", name: "dummy" }],
      groups: [],
    });
  }, [props.getMessageStats]);

  React.useEffect(() => {
    return () => {
      props.clearTemplate();
    };
  }, [props.clearTemplate]);

  let initialValues: TemplateAddForm = {
    meoSiteId: props.meoSite.id,
    name: "",
    content: defaultMessage,
  };

  if (props.savedForm && parsed.shortcutSelected === "landing_page") {
    initialValues = props.savedForm;
  }

  const handleSubmit = async (form: TemplateAddForm) => {
    await props.addTemplate(form);
    pushTo(history, `/${Page.templates}`, {});
  };

  const handleBlur = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setCursorPosition(event.target.selectionStart);
  };

  const handleMessageChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const message = convertContent(event.target.value);
    if (timeoutHandler) {
      clearTimeout(timeoutHandler);
    }

    setTimeoutHandler(
      setTimeout(() => {
        props.getMessageStats({
          message: message,
          contacts: [{ phoneNumber: "+819000000000", name: "dummy" }],
          groups: [],
        });
      }, debounceWait)
    );
  };

  const goToSelectLandingPageSettings = (form: TemplateAddForm) => {
    props.setSavedForm({
      ...form,
      cursorPosition,
    });
    pushTo(history, `/${Page.landingPageSettings}`, {
      selecting: `/${Page.templates}/add`,
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={templateAddFormSchema}
    >
      {(formikBag) => {
        return (
          <Form {...formItemLayout}>
            <Form.Item name="name" label={i18n.t("templates.name")}>
              <Input name="name" />
            </Form.Item>
            <Form.Item name="content" label={i18n.t("templates.content")}>
              <Input.TextArea
                className="message"
                name="content"
                placeholder={i18n.t("templates.contentPlaceholder")}
                onBlur={handleBlur}
                autoSize={{ minRows: 3 }}
                onChange={handleMessageChange}
              />
            </Form.Item>
            <Row className="ant-form-item">
              <Col {...formItemLayout.labelCol}></Col>
              <Button
                className="landing-page-shortcut max-width-on-mobile"
                onClick={() => goToSelectLandingPageSettings(formikBag.values)}
              >
                {i18n.t("messageForm.selectLandingPage")}
              </Button>
            </Row>
            {props.messageStatsLoading && (
              <Row>
                <Col {...formItemLayout.labelCol} />
                <Col {...formItemLayout.wrapperCol}>
                  <Spin />
                </Col>
              </Row>
            )}
            {!props.messageStatsLoading &&
              props.messageStats &&
              props.messageStats.numberOfParts > 0 && (
                <Row>
                  <Col {...formItemLayout.labelCol} />
                  <Col {...formItemLayout.wrapperCol}>
                    {i18n.t("messageForm.multiMessageNote", {
                      parts: props.messageStats.numberOfParts,
                    })}
                  </Col>
                </Row>
              )}

            <Row className="form-button-row">
              <CreateButton
                loading={props.loading || props.messageStatsLoading}
              />
              <BackButton />
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = (root: RootState) => {
  return {
    loading: root.templates.createLoading,
    meoSite: root.seo.meoSite!,
    savedForm: root.templates.savedForm,
    messageStats: root.templates.messageStats,
    messageStatsLoading: root.templates.messageStatsLoading,
  };
};

const mapDispatchToProps = {
  setPage,
  addTemplate,
  setSavedForm,
  clearTemplate,
  getMessageStats,
};

export default connect(mapStateToProps, mapDispatchToProps)(TemplateAdd);
