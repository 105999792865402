import { ImpressionPromptMode } from "../constants";

/* Basic DB model */
interface DBModel {
  id: string;
  createdAt: string;
  updatedAt: string;
}

/* Pagination */
export interface Paginated<A> {
  items: Array<A>;
  hasRight: boolean;
  hasLeft: boolean;
}

export interface PaginationParams {
  limit?: number;
  before?: string;
  after?: string;
}

/* Publishing / Direct links */
export enum PublishType {
  Cloud = "cloud",
  Direct = "direct",
}

export enum TemplateType {
  Store = "store",
  Gyron = "gyron",
  All = "all",
}

export interface NameAndPhoneNumber {
  name: string;
  phoneNumber: string;
}

export interface IdAndName {
  id: string;
  name: string;
}

export interface PublishForm {
  meoSiteId: number;
  contacts: Array<NameAndPhoneNumber>;
  message: string;
  sendUserId: string;
  groups: Array<IdAndName>;
}

export interface ScheduleForm extends PublishForm {
  scheduledTime: string;
}

export interface DirectLinkForm {
  meoSiteId: number;
  contact: NameAndPhoneNumber;
  message: string;
}

export interface DirectLinkResponse {
  link: string;
}

/* Addresses */
export interface ListAddressParams extends PaginationParams {
  meoSiteId: number;
  query?: string;
  urlViewStart?: string;
  urlViewEnd?: string;
  landingPageViewStart?: string;
  landingPageViewEnd?: string;
  reviewLinkClickStart?: string;
  reviewLinkClickEnd?: string;
  reviewLinkId?: string;
}

export interface Address extends DBModel {
  meoSiteId: number;
  name: string;
  phoneNumber: string;
}

export interface FullAddress {
  address: Address;
  optedOut: boolean;
  blacklisted: boolean;
}

export enum AddressImportErrorType {
  NameRequired = "name_required",
  NameTooLong = "name_too_long",
  InvalidPhoneNumber = "invalid_phone_number",
  CsvReadError = "csv_read_error",
}

export interface AddressImportError {
  lineNumber: number;
  errorType: AddressImportErrorType;
}

export interface AddressImportResult {
  successCount: number;
  errors: Array<AddressImportError>;
}

export interface AddressAddForm {
  meoSiteId: number;
  name: string;
  phoneNumber: string;
}

export interface AddressDeleteForm {
  addressIds: Array<string>;
}

/* Publishing / Direct links */
export enum MailTemplateContentType {
  Text = "text",
  Html = "html",
}

/* Mail Template Sender */
export interface MailTemplateSender extends DBModel {
  clientId: string;
  name: string;
  senderEmail: string;
  replyEmail: string;
}

export interface MailTemplateSenderAddForm {
  name: string;
  senderEmail: string;
  replyEmail: string;
}

export interface MailTemplateSenderUpdateForm {
  name: string;
  senderEmail: string;
  replyEmail: string;
}

export interface MailTemplateSenderDeleteForm {
  mailTemplateSenderIds: Array<string>;
}

/* Mail Template */
export interface MailTemplate extends DBModel {
  clientId: string;
  name: string;
  mailTemplateSenderId: string;
  mailTemplateSender: string;
  bcc: string;
  title: string;
  contentType: string;
  contents: string;
}

export interface MailTemplateWithSender extends DBModel {
  clientId: string;
  name: string;
  mailTemplateSender: MailTemplateSender;
  bcc: string;
  title: string;
  contentType: string;
  contents: string;
}

export interface MailTemplateAddForm {
  name: string;
  mailTemplateSenderId: string;
  bcc: string;
  title: string;
  contentType: string;
  contents: string;
}

export interface MailTemplateUpdateForm {
  name: string;
  mailTemplateSenderId: string;
  bcc: string;
  title: string;
  contentType: string;
  contents: string;
}

export interface MailTemplateDeleteForm {
  mailTemplateIds: Array<string>;
}

export interface SearchAddressParams {
  query: string;
}

/* Group */
export interface Group extends DBModel {
  meoSiteId: number;
  name: string;
}

export interface FullGroup {
  group: Group;
  addresses: Array<Address>;
}

export interface ListGroupParams extends PaginationParams {
  meoSiteId: number;
}

export interface GroupAddForm {
  meoSiteId: number;
  name: string;
  addressIds: Array<string>;
}

export interface SearchGroupParams {
  query: string;
}

/* Contacts */
export interface Contact extends DBModel {
  meoSiteId: number;
  name: string;
  phoneNumber: string;
  scheduledId?: string;
  historyId?: string;
  status: ContactStatus;
}

export interface ContactGroups extends DBModel {
  contact: Contact;
  groupNames: Array<string>;
}

export enum ContactStatus {
  unsent = "unsent",
  sent = "sent",
  success = "success",
  error = "error",
}

/* Schedule */
export interface ListScheduledParams extends PaginationParams {
  meoSiteId: number;
}

interface Scheduled extends DBModel {
  meoSiteId: number;
  message: string;
  sendUserId: string;
  scheduledTime: string;
  sentAt?: string;
}

export interface FullScheduled {
  scheduled: Scheduled;
  contacts: Array<Contact>;
  groups: Array<Group>;
}

export interface FullScheduledWithParts {
  fullScheduled: FullScheduled;
  gyronMessageParts: number;
}

export interface ScheduledEditForm {
  contacts: Array<NameAndPhoneNumber>;
  message: string;
  sendUserId: string;
  scheduledTime: string;
  groups: Array<IdAndName>;
}

/* Templates */
export interface ListTemplateParams extends PaginationParams {
  meoSiteId: number;
}

export interface Template extends DBModel {
  meoSiteId: number;
  name: string;
  content: string;
  type: TemplateType;
}

export interface TemplateAddForm {
  meoSiteId: number;
  name: string;
  content: string;
}

export interface ListReplyTemplateParams extends PaginationParams {
  meoSiteId: number;
}

export interface ReplyTemplate extends DBModel {
  meoSiteId: number;
  name: string;
  content: string;
}

export interface ReplyTemplateAddForm {
  meoSiteId: number;
  name: string;
  content: string;
}

/* Client */

export interface SSOClient {
  clientToken: string;
  userName: string;
  clientName: string;
  admin: boolean;
  userToken: string;
  affiliateLogoUrl?: string;
  affiliateLogoType?: number;
}

export interface Client extends DBModel {
  sendUserId: string;
  token: string;
  defaultPublishType: PublishType;
  optoutPageTemplate?: string;
  mailExclusionSetting?: string;
}

export interface ClientEditForm {
  sendUserId?: string;
  defaultPublishType: PublishType;
  optoutPageTemplate?: string;
}

export interface ClientEcLogoEditForm {
  storeName?: string;
  storeLogoUrl?: string;
}

export interface ClientEditOptoutPageTemplateForm {
  optoutPageTemplate?: string;
}

export interface ClientEcOptoutDetails extends DBModel {
  emailAddress: string;
  optoutType: string;
}

export interface ClientEcLogoDetails extends DBModel {
  clientId: string;
  storeName?: string;
  storeLogoUrl?: string;
}

export interface UsageStatistics {
  totalAllowedMessages: number;
  usedMessages: number;
}

/* History */
export enum HistoryType {
  Cloud = "cloud",
  Direct = "direct",
  Scheduled = "scheduled",
}

export interface History extends DBModel {
  meoSiteId: number;
  message: string;
  sendUserId: string;
  sentAt: string;
  type: HistoryType;
}

export interface FullHistoryGroup {
  history: History;
  contactGroups: Array<ContactGroups>;
}

export interface FullHistory {
  history: History;
  contacts: Array<Contact>;
  gyronMessageParts: number;
}

export interface ListHistoryParams extends PaginationParams {
  meoSiteId: number;
}

/* Message stats */

export interface MessageStatsForm {
  message: string;
  contacts: Array<NameAndPhoneNumber>;
  groups: Array<IdAndName>;
}

export interface MessageStats {
  numberOfParts: number;
  senderCount: number;
}

export interface GoogleAccount {
  refreshToken?: string;
}

/* MEO Sites */
export interface MEOSite {
  id: number;
  name: string;
  businessName: string;
  googleAccount?: GoogleAccount;
  myBusinessLocationId?: string;
}

export interface MEOSites {
  items: Array<MEOSite>;
}

/* Landing page settings */
export interface LandingPageSettings extends DBModel {
  name: string;
  title?: string;
  message: string;
  logoUrl?: string;
}

export interface LandingDataPreviewForm {
  landingPageSettingsId?: string;
  meoSiteId: number;
  message: string;
  logoUrl?: string;
}

export interface LandingPageSettingsAddForm {
  meoSiteId: number;
  name: string;
  title?: string;
  message: string;
  logoUrl?: string;
}

export interface LandingPageSettingsEditForm {
  title?: string;
  message?: string;
  logoUrl?: string;
}

export interface LogoUploadResult {
  url: string;
}

export enum ReviewLinkType {
  User = "user",
  GoogleMyBusiness = "google_my_business",
}

export interface ReviewLinkColor {
  id: string;
  hexCode: string;
}

export interface ReviewLinkColors {
  colors: Array<ReviewLinkColor>;
}

export interface ReviewLink extends DBModel {
  text: string;
  url: string;
  active: boolean;
  reviewLinkType: ReviewLinkType;
  reviewLinkColorId: string;
  hasIcon: boolean;
  displayOrder: number;
}

export interface ListReviewLinkParams {
  meoSiteId: number;
}

export interface ReviewLinkAddForm {
  text: string;
  url: string;
  reviewLinkColorId: string;
  hasIcon: boolean;
}

export interface ReviewLinkEditForm {
  text?: string;
  url?: string;
  active?: boolean;
  reviewLinkColorId?: string;
  hasIcon?: boolean;
  displayOrder?: number;
}

export interface ReviewLinkConnectForm {
  meoSiteId: number;
  type: ReviewLinkType;
}

export interface ReviewLinkStatus {
  existingLinks: Array<ReviewLinkType>;
}

export interface LandingData {
  message: string;
  logoUrl?: string;
  links: Array<ReviewLink>;
  hideOptout: boolean;
}

/* URL views */
export interface UrlView extends DBModel {
  meoSiteId: number;
  viewedAt: string;
}

export interface FullUrlView {
  urlView: UrlView;
  contact: Contact;
}

export interface ListUrlViewParams extends PaginationParams {
  meoSiteId: number;
}

/* Statistics */

interface BaseStatisticsForm {
  meoSiteId: number;
  landingPageSettingsId?: string;
  year: number;
}

export interface DailyStatisticsForm extends BaseStatisticsForm {
  month: number;
}

export interface MonthlyStatisticsForm extends BaseStatisticsForm {}

export interface StatisticsReviewLinkTotal {
  clicks: number;
  ctr: number;
}

export interface StatisticsReviewLink extends StatisticsReviewLinkTotal {
  reviewLinkId: string;
  name: string;
}

export interface StatisticsDataPoint {
  time?: string;
  cloudMessagesSent: number;
  urlViews: number;
  urlCtr: number;
  landingPageViews: number;
  reviewLinks: Array<StatisticsReviewLink>;
  reviewLinksTotal: StatisticsReviewLinkTotal;
}

export interface StatisticsResponse {
  dataPoints: Array<StatisticsDataPoint>;
}

export interface GMBStatisticsDataPoint {
  time?: string;
  reviews: number;
}

export interface GMBStatisticsResponse {
  dataPoints: Array<GMBStatisticsDataPoint>;
}

/* Blacklist */
export interface Blacklist extends DBModel {
  phoneNumber: string;
}

export interface ListBlacklistParams extends PaginationParams {
  meoSiteId: number;
}

/* GMB */
export interface GMBReviewer {
  displayName?: string;
  isAnonymous: boolean;
}

export enum GMBStarRating {
  STAR_RATING_UNSPECIFIED = "STAR_RATING_UNSPECIFIED",
  ONE = "ONE",
  TWO = "TWO",
  THREE = "THREE",
  FOUR = "FOUR",
  FIVE = "FIVE",
}

export interface GMBReviewReply {
  comment: string;
}

export interface GMBReview {
  name: string;
  reviewer: GMBReviewer;
  starRating: GMBStarRating;
  comment: string;
  createTime: string;
  updateTime: string;
  reviewReply?: GMBReviewReply;
}

export interface GMBReviews {
  reviews: Array<GMBReview>;
  offset: number;
  hasMore: boolean;
}

export enum GMBSort {
  UpdateTime = "update_time",
  RatingDesc = "rating_desc",
  Rating = "rating",
}

export interface GMBReviewFilter {
  meoSiteId: number;
  sort?: GMBSort;
  skip?: number;
  rating?: GMBStarRating;
  start?: string;
  end?: string;
}

export interface GMBReplyForm {
  meoSiteId: number;
  reviewNames: Array<string>;
  comment: string;
}

/* Activity */
export enum ActivityType {
  UrlView = "url_view",
  LandingPageView = "landing_page_view",
  ReviewLinkClick = "review_link_click",
}

export interface Activity {
  activityType: ActivityType;
  time: string;
  reviewLinkName?: string;
}

export interface ActivityGroup {
  activities: Array<Activity>;
  landingPageName: string;
}

/* surveys */
export interface ImpressionPromptOptions {
  isImpressionPrompt: boolean;
  impressionPromptMode: ImpressionPromptMode;
}

export interface SettingOptions {
  isLanguageSettingsSelectable: boolean;
  languageSettingsId: string | null;
  impressionPromptOptions: ImpressionPromptOptions;
}

export interface Surveys extends DBModel {
  title: string | null;
  topPageTitle: string | null;
  topPageNote: string | null;
  logoUrl: string | null;
  completionMessage: string | null;
  publish: boolean;
  randomDisplay: boolean;
  randomDisplayCount: number | null;
  isGoogleReview: boolean;
  requestReviewMessage: string | null;
  customPrompt: boolean;
  customPromptText: string | null;
  randomQuestionsOrder: boolean;
  isLanguageModelSelectable: boolean;
  languageModelId: string | null;
  surveyType: string;
  isProofreading: boolean;
  settingOptions: SettingOptions;
}

export interface SurveysEditForm {
  title: string;
  publish: boolean;
  randomDisplay: boolean;
  randomDisplayCount: number;
  topPageTitle: string;
  topPageNote: string;
  logoUrl: string;
  customPrompt: boolean;
  customPromptText: string;
  randomQuestionsOrder: boolean;
  isLanguageModelSelectable: boolean;
  languageModelId: string | null;
  isProofreading: boolean;
  surveyType: string;
  isLanguageSettingsSelectable: boolean;
  languageSettingsId: string | null;
  isImpressionPrompt: boolean;
  impressionPromptMode: ImpressionPromptMode;
}

export interface ReviewMediaData {
  isGoogleReview: boolean;
  completionMessageV2: string;
  requestReviewMessage: string;
  mediaName: string | null;
  nonGoogleCompletionMessageV2: string;
  nonGoogleRequestReviewMessage: string;
}

export interface SurveysDeleteForm {
  surveyIds: Array<string>;
}

export interface QuestionSetChanges {
  deleteItems: Array<string>;
  updateItems: Array<SubmitQuestionItems>;
}

export interface PickedQuestionOptions {
  randomCount?: string;
  conditions?: string;
  radioItems?: Array<{ name: string; value: string; notPrompt?: boolean }>;
  checkboxItems?: Array<{ name: string; value: string; notPrompt?: boolean }>;
  hasCustomText?: boolean;
  customTextPlaceholder?: string;
  defaultItem?: string;
  freeInput?: { isFreeInput: boolean; value: string };
  notPrompt?: boolean;
}

export interface PickedQuestion {
  id: string;
  title: string;
  options: PickedQuestionOptions | null;
  displayRequired: boolean;
  inputRequired: boolean;
  surveyOnly: boolean;
  formType: string;
  selectId?: string;
  placeholder: string | null;
  archive: boolean;
  children: Array<PickedQuestion | FlattenedPickedQuestion>;
  collapsed?: boolean;
}

export interface FlattenedPickedQuestion extends PickedQuestion {
  parentId: string | null;
  depth: number;
  index: number;
  [key: string]: any;
}

export interface SubmitQuestionItems {
  id: string | null;
  formType: string;
  title: string;
  options: PickedQuestionOptions | null;
  displayRequired: boolean;
  inputRequired: boolean;
  questionOrder: number;
  placeholder: string | null;
  surveyOnly: boolean;
  archive: boolean;
  children: Array<SubmitQuestionItems>;
}

export interface SelectedQuestion extends DBModel {
  surveysId: string;
  formType: string;
  title: string | null;
  options: Record<string, string> | null;
  displayRequired: boolean;
  inputRequired: boolean;
  surveyOnly: boolean;
  questionOrder: number;
  placeholder: string | null;
  archive: boolean;
  parentId: string | null;
  children?: Array<SelectedQuestion>;
}

export interface QuestionItem extends DBModel {
  title: string;
  formType: string | null;
  itemOrder: number | null;
}

export interface TemplateSurveys extends DBModel {
  title: string;
}

export interface SurveyMeoSitesParams extends PaginationParams {
  id: string;
}

export interface SurveyMeoSites extends DBModel {
  meoSiteId: number;
  meoSiteName: string;
  localBusinessId: number | null;
  reviewLink: string | null;
}

export interface SurveyMeoSiteDeleteForm {
  surveyMeoSiteIds: Array<string>;
}

export interface SurveyMeoSitesAddForm {
  surveysId: string;
  meoSiteId: number;
}

export interface SurveyMeoSitesEditForm {
  reviewLink: string | null;
}

export enum SurveyMeoSitesCsvImportErrorType {
  SurveyIdRequired = "survey_id_required",
  MeoSiteIdRequired = "meo_site_id_required",
  CsvReadError = "csv_read_error",
}

export interface SurveyMeoSitesCsvImportError {
  lineNumber: number;
  errorType: SurveyMeoSitesCsvImportErrorType;
}

export interface SurveyMeoSitesCsvImportResult {
  successCount: number;
  errors: Array<SurveyMeoSitesCsvImportError>;
}

export interface LanguageModel extends DBModel {
  code: string | null;
  name: string | null;
}

export interface LanguageSettings extends DBModel {
  code: string | null;
  name: string | null;
}

/* MEO Sites */
export interface SurveyMeoSiteSettings {
  meoSiteId: number;
  meoSiteName: string;
  recipientEmail: Array<string> | null;
  surveyCount: number;
}

export interface SurveyMeoSiteSettingsGetParam {
  pageSize: number;
  page: number;
}

export interface LocalBusiness {
  id: number;
  name: string;
  siteCount: number;
}

export interface LocalBusinesses {
  items: Array<LocalBusiness>;
}

export interface SurveyMeoSiteSettingsUpdateItem {
  meoSiteId: number;
  recipientEmail: Array<string>;
}

export enum RecipientEmailCsvImportErrorType {
  MeoSiteIdRequired = "meo_site_id_required",
  CsvReadError = "csv_read_error",
}

export interface RecipientEmailCsvImportError {
  lineNumber: number;
  errorType: RecipientEmailCsvImportErrorType;
}

export interface RecipientEmailCsvImportResult {
  successCount: number;
  errors: Array<RecipientEmailCsvImportError>;
}

export interface ClientPlanDetails {
  plan: string;
  value: string;
  type: string;
}

export interface ClientAllSuggestionsCount {
  count: number;
}

export interface ContractDetailsGetParam {
  year: number;
  month: number;
}

export interface QuestionJSON {
  title: string;
  options: PickedQuestionOptions | null;
  displayRequired: boolean;
  inputRequired: boolean;
  surveyOnly: boolean;
  formType: string;
  placeholder: string | null;
  archive: boolean;
  children: Array<QuestionJSON>;
}

export interface ProductsAddForm {
  productCode: string;
  productName: string;
  reviewPageUrl?: string;
}

export interface ProductsEditForm {
  productCode: string;
  productName: string;
  reviewPageUrl?: string;
  ecProviderId: string;
}

export interface ProductsDeleteForm {
  clientNextEngineProductIds: Array<string>;
}

export interface SurveyProductsParams extends PaginationParams {
  id: string;
}

export interface SurveyProducts extends DBModel {
  clientNextEngineProductId: string;
  productCode: string;
  productName: string;
  reviewLink: string | null;
  reviewPageUrl: string | null;
}

export interface SurveyProductDeleteForm {
  surveyClientNextEngineProductIds: Array<string>;
}

export interface SurveyProductsAddForm {
  surveysId: string;
  clientNextEngineProductId: string;
}

export interface SurveyProductsEditForm {
  reviewLink: string | null;
}

export enum SurveyProductsCsvImportErrorType {
  SurveyIdRequired = "survey_id_required",
  ClientNextEngineProductIdRequired = "client_next_engine_product_id_required",
  CsvReadError = "csv_read_error",
}

export interface SurveyProductsCsvImportError {
  lineNumber: number;
  errorType: SurveyProductsCsvImportErrorType;
}

export interface SurveyProductsCsvImportResult {
  successCount: number;
  errors: Array<SurveyProductsCsvImportError>;
}

export interface SurveyProductSettings extends DBModel {
  clientId: string;
  productCode: string;
  productName: string;
  reviewPageUrl: string | null;
  recipientEmail: Array<string> | null;
  surveyCount: number;
  ecProviderId: string;
  ecProvider: string;
}

export interface SurveyProductSettingsGetParam {
  pageSize: number;
  page: number;
}

export interface SurveyProductSettingsUpdateItem {
  id: string;
  recipientEmail: Array<string>;
}

export interface EcLink {
  linkName: string;
  isLinked: boolean;
}

export interface Order extends DBModel {
  clientId: string;
  orderId: string;
  purchasedAt: string;
  emailAddress?: string;
  ecProvider: string;
  productCount: number;
  surveyCount: number;
  emailSentCount: number;
  totalEmailCount: number;
}

export interface SimpleProduct extends DBModel {
  clientId: string;
  productCode: number;
  productName: string;
  reviewPageUrl: string | null;
  recipientEmail: Array<string> | null;
}

export interface OrderDetails {
  id: string;
  clientId: string;
  orderId: string;
  purchasedAt: string;
  purchaserName?: string;
  emailAddress?: string;
  products: Array<SimpleProduct>;
}

export interface SurveyEcEmailSettings extends DBModel {
  id: string;
  surveysId: string;
  waitDays: number;
  sendTime: string;
}

export interface SurveyEcEmailSettingsData {
  surveysId: string;
  waitDays: number;
  sendTime: string;
}

export interface OrderSurveyEmailDetails {
  surveysId: string;
  clientNextEngineProductId: string;
  productCode: string;
  productName: string;
  reviewLink?: string;
  sendAt: string;
}

export interface OrderSurveyEmailWithProductsDetail {
  id: string;
  order: Order;
  products: Array<OrderSurveyEmailDetails>;
}

export interface OrderSurveyEmail extends DBModel {
  clientId: string;
  orderId: string;
  subject: string;
  emailAddress: string;
  emailContent: string;
  sendAt: string;
  sentAt?: string;
  sendStatus?: string;
  openStatus?: string;
  clickCount?: number;
}

export interface OrderSurveyEmailWithOrder extends DBModel {
  clientId: string;
  orderId: string;
  subject: string;
  emailAddress: string;
  emailContent: string;
  sendAt: string;
  sentAt?: string;
  sendStatus?: string;
  openStatus?: string;
  clickCount?: number;
  order: Order;
  orderIndex: number;
  hasMultipleOrders: boolean;
}

export enum OrderCsvImportErrorType {
  OrderIdRequired = "order_id_required",
  ProductCodeRequired = "product_code_required",
  EmailAddressRequired = "email_address_required",
  PurchasedAtRequired = "purchased_at_required",
  EcProviderRequired = "ec_provider_required",
  CsvReadError = "csv_read_error",
}

export interface OrderCsvImportError {
  lineNumber: number;
  errorType: OrderCsvImportErrorType;
}

export interface OrderCsvImportResult {
  successCount: number;
  errors: Array<OrderCsvImportError>;
}

export interface EcProvider extends DBModel {
  code: string;
  name: string;
}

export interface MailExclusionSettingsForm {
  emailAddresses: string;
  optoutType: string;
  mode: string;
}

export interface MailExclusionSettingsUpdateForm {
  emailAddresses: Array<string>;
  optoutType: string;
}

export interface MailExclusionSettingsEditForm {
  mailExclusionSetting: string;
}

export interface MailExclusionSettingsDeleteForm {
  emailAddresses: Array<string>;
  optoutType: string;
}

export interface MailTemplateSentTestMailForm {
  emailAddress: string;
  bcc?: string;
  senderEmailAddress: string;
  title: string;
  contents: string;
}
