import {
  ClientEcLogoDetails,
  ClientPlanDetails,
  ClientAllSuggestionsCount,
  ContractDetailsGetParam,
  ClientEcLogoEditForm,
  LogoUploadResult,
} from "api/sms-api";
import {
  getClientAllSuggestionsCountStart,
  getClientAllSuggestionsCountSuccess,
  getClientAllSuggestionsCountError,
  getClientPlanDetailsStart,
  getClientPlanDetailsSuccess,
  getClientPlanDetailsError,
  getClientTypeStart,
  getClientTypeSuccess,
  getClientTypeError,
  getClientEcLogoDetailsStart,
  getClientEcLogoDetailsSuccess,
  getClientEcLogoDetailsError,
  createClientEcLogoDetailsStart,
  createClientEcLogoDetailsSuccess,
  createClientEcLogoDetailsError,
  editClientEcLogoDetailsStart,
  editClientEcLogoDetailsSuccess,
  editClientEcLogoDetailsError,
} from "./ContractDetailsSlice";
import { AppDispatch } from "store/store";
import { httpWrapper } from "utils/http";
import { SMS_API_BASE, surveyTypeStore } from "../../constants";
import { handleError } from "errors/errors";

export const getClientAllSuggestionsCount = (
  param: ContractDetailsGetParam
) => async (dispatch: AppDispatch) => {
  dispatch(getClientAllSuggestionsCountStart());
  try {
    const contractDeails = await httpWrapper<ClientAllSuggestionsCount>(
      "GET",
      `${SMS_API_BASE}/surveys/client_all_suggestions_count`,
      param
    );
    dispatch(getClientAllSuggestionsCountSuccess(contractDeails));
  } catch (e) {
    handleError(e);
    dispatch(getClientAllSuggestionsCountError());
  }
};

export const getClientPlanDetails = () => async (dispatch: AppDispatch) => {
  dispatch(getClientPlanDetailsStart());
  try {
    const contractDeails = await httpWrapper<ClientPlanDetails>(
      "GET",
      `${SMS_API_BASE}/surveys/client_plan_details?service=kikicomi`,
      {}
    );
    dispatch(getClientPlanDetailsSuccess(contractDeails));
  } catch (e) {
    handleError(e);
    dispatch(getClientPlanDetailsError());
  }
};

export const getClientType = () => async (dispatch: AppDispatch) => {
  dispatch(getClientTypeStart());
  try {
    const typeDetails = await httpWrapper<ClientPlanDetails>(
      "GET",
      `${SMS_API_BASE}/surveys/client_plan_details?service=kikicomi`,
      {}
    );
    const type =
      typeDetails && typeDetails.type ? typeDetails.type : surveyTypeStore;
    dispatch(getClientTypeSuccess(type));
  } catch (e) {
    handleError(e);
    dispatch(getClientTypeError());
  }
};

export const getClientEcLogoDetails = () => async (dispatch: AppDispatch) => {
  dispatch(getClientEcLogoDetailsStart());
  try {
    const clientEcLogoDetails = await httpWrapper<ClientEcLogoDetails>(
      "GET",
      `${SMS_API_BASE}/client_ec_logo/get_with_session`,
      {}
    );
    dispatch(getClientEcLogoDetailsSuccess(clientEcLogoDetails));
  } catch (e) {
    handleError(e);
    dispatch(getClientEcLogoDetailsError());
  }
};

const uploadLogo = async (file: File) => {
  const formData = new FormData();
  formData.append("file", file);
  return httpWrapper<LogoUploadResult>(
    "POST",
    `${SMS_API_BASE}/client_ec_logo/logo`,
    formData,
    { "Content-Type": "multipart/form-data" }
  );
};

export const createClientEcLogoDetails = (
  params: ClientEcLogoEditForm,
  file: File | null
) => async (dispatch: AppDispatch) => {
  dispatch(createClientEcLogoDetailsStart());
  try {
    // First upload the logo
    if (file) {
      const result = await uploadLogo(file);
      // Then get the logo url
      if (result) {
        params.storeLogoUrl = result.url;
      }
    }
    // Then update the rest
    const clientEcLogoDetails = await httpWrapper<ClientEcLogoDetails>(
      "POST",
      `${SMS_API_BASE}/client_ec_logo`,
      params
    );
    dispatch(createClientEcLogoDetailsSuccess(clientEcLogoDetails));
  } catch (e) {
    handleError(e);
    dispatch(createClientEcLogoDetailsError());
  }
};

export const editClientEcLogoDetails = (
  id: string,
  params: ClientEcLogoEditForm,
  file: File | null
) => async (dispatch: AppDispatch) => {
  dispatch(editClientEcLogoDetailsStart());
  try {
    // First upload the logo if it exists
    if (file) {
      const result = await uploadLogo(file);
      // Then get the logo url
      if (result) {
        params.storeLogoUrl = result.url;
      }
    }
    // Then update the rest
    const clientEcLogoDetails = await httpWrapper<ClientEcLogoDetails>(
      "PATCH",
      `${SMS_API_BASE}/client_ec_logo/${id}`,
      params
    );
    dispatch(editClientEcLogoDetailsSuccess(clientEcLogoDetails));
  } catch (e) {
    handleError(e);
    dispatch(editClientEcLogoDetailsError());
  }
};
