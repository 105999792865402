import React from "react";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { useDroppable, UniqueIdentifier } from "@dnd-kit/core";
import SurveyQuestionItem from "./SortableItem";
import SortableHeader from "./SortableHeader";
import { FlattenedPickedQuestion } from "api/sms-api";

interface SelectItemsProps {
  categoryId: UniqueIdentifier;
  items: Array<FlattenedPickedQuestion>;

  handleClickDisplayRequired: (index: number) => void;
  handleClickInputRequired: (index: number) => void;
  handleClickSurveyOnly: (index: number) => void;
  deleteSelectedQuestions: (index: number) => void;
  handleClickQuestion: (index: number) => void;
  handleClickArchive: (index: number) => void;
  handleCollapse: (index: UniqueIdentifier) => void;
  duplicateQuestion: (index: number) => void;
}

const SelectItems: React.FC<SelectItemsProps> = (props) => {
  const { setNodeRef } = useDroppable({ id: props.categoryId });
  const getParentIndex = (value: FlattenedPickedQuestion) => {
    const parentItem = props.items.find(
      (item) => item.selectId === value.parentId
    )?.index;
    return parentItem;
  };
  const getGrandParentIndex = (value: FlattenedPickedQuestion) => {
    const parentItem = props.items.find(
      (item) => item.selectId === value.parentId
    );
    const grandParentItemIndex = props.items.find(
      (item) => item.selectId === parentItem?.parentId
    )?.index;
    return grandParentItemIndex;
  };

  return (
    <SortableContext items={props.items} strategy={verticalListSortingStrategy}>
      <SortableHeader itemLength={props.items.length}></SortableHeader>
      <div
        ref={setNodeRef}
        style={{ height: "100%", maxHeight: "1090px", overflowY: "auto" }}
      >
        {props.items.map((value, index) => (
          <SurveyQuestionItem
            key={value.selectId}
            value={value}
            handleClickDisplayRequired={() =>
              props.handleClickDisplayRequired(index)
            }
            handleClickInputRequired={() =>
              props.handleClickInputRequired(index)
            }
            handleClickSurveyOnly={() => props.handleClickSurveyOnly(index)}
            deleteSelectedQuestions={() => props.deleteSelectedQuestions(index)}
            handleClickQuestion={() => props.handleClickQuestion(index)}
            handleClickArchive={() => props.handleClickArchive(index)}
            collapsed={Boolean(value.collapsed && value.children.length)}
            handleCollapse={
              value.children.length
                ? () => props.handleCollapse(value.selectId || "")
                : undefined
            }
            duplicateQuestion={() => props.duplicateQuestion(index)}
            index={value.index}
            parentIndex={getParentIndex(value)}
            grandParentIndex={getGrandParentIndex(value)}
          />
        ))}
      </div>
    </SortableContext>
  );
};

export default SelectItems;
