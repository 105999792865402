import React, { useEffect } from "react";
import { Page, setPage } from "features/appstate/AppStateSlice";
import {
  SurveyProducts,
  SurveyProductDeleteForm,
  Paginated,
  SurveyProductsParams,
  ReviewMediaData,
} from "api/sms-api";
import {
  Spin,
  Table,
  Button,
  Row,
  Modal,
  Col,
  Popconfirm,
  Typography,
} from "antd";
import { RootState } from "store/store";
import { connect } from "react-redux";
import {
  getSurveyProductsList,
  deleteSurveyProducts,
} from "./SurveyProductsActions";
import { getSurvey, getReviewMediaSettings } from "../survey/SurveyActions";
import {
  setSelectedSurveyProducts,
  clearSelectedSurveyProducts,
  clearItems,
} from "./SurveyProductsSlice";
import i18n from "i18n";
import { RouteComponentProps, useLocation, useHistory } from "react-router-dom";
import { compact } from "lodash";
import SurveyQR from "components/SurveyQR";
import {
  PlusOutlined,
  QrcodeOutlined,
  ImportOutlined,
  ExportOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import _ from "lodash";
import SimplePagination, {
  PaginatedComponentProps,
} from "components/SimplePagination";
import qs from "qs";
import {
  MODE_SURVEY,
  SURVEY_PAGE_BASE,
  surveyTypeStore,
} from "../../constants";
import { pushTo } from "utils/navigation";
import SMSLink from "components/SMSLink";
import { exportCsv } from "features/survey_products/SurveyProductsActions";
import { Surveys } from "api/sms-api";

const { Paragraph } = Typography;
const PAGE_LIMIT = 100;

interface SurveyProductsListProps {
  items: Paginated<SurveyProducts>;
  listLoading: boolean;
  getSurveyProductsList: (params: SurveyProductsParams) => void;
  clearItems: () => void;

  survey?: Surveys;
  getLoading: boolean;
  getSurvey: (surveyId: string) => void;

  reviewMediaSettings?: ReviewMediaData;
  reviewMediaSettingLoading: boolean;
  getReviewMediaSettings: (surveyId: string) => void;

  deleteSurveyProducts: (params: SurveyProductDeleteForm) => void;
  multipleDeleteFinished: boolean;
  multipleDeleteLoading: boolean;

  selectedSurveyProducts: Array<{ id: string }>;
  setSelectedSurveyProducts: (selected: Array<{ id: string }>) => void;
  clearSelectedSurveyProducts: () => void;

  setPage: (page: Page) => void;

  exportCsv: (id: string) => void;
}

const SurveyProductsList: React.FC<
  SurveyProductsListProps & RouteComponentProps & PaginatedComponentProps
> = (props) => {
  const history = useHistory();
  const location = useLocation();
  const parsed = qs.parse(location.search, { ignoreQueryPrefix: true });
  const surveyId = parsed["id"] as string;

  useEffect(() => {
    props.setPage(Page.products);
  }, [props.setPage]);

  useEffect(() => {
    if (surveyId) {
      props.getSurvey(surveyId);
    }
  }, [props.getSurvey]);

  useEffect(() => {
    if (surveyId) {
      props.getReviewMediaSettings(surveyId);
    }
  }, [props.getReviewMediaSettings, surveyId]);

  useEffect(() => {
    const params = {
      ..._.omit(parsed, ["mode"]),
      id: surveyId,
    };
    props.getSurveyProductsList({ ...params, limit: PAGE_LIMIT });
  }, [props.getSurveyProductsList, location.search]);

  useEffect(() => {
    if (props.multipleDeleteFinished && !props.multipleDeleteLoading) {
      props.clearSelectedSurveyProducts();
      const params = {
        ..._.omit(parsed, ["mode", "before", "after"]),
        id: surveyId,
      };
      props.getSurveyProductsList({ ...params, limit: PAGE_LIMIT });
    }
  }, [
    props.getSurveyProductsList,
    props.multipleDeleteFinished,
    props.multipleDeleteLoading,
  ]);

  useEffect(() => {
    return () => {
      props.clearItems();
      props.clearSelectedSurveyProducts();
    };
  }, []);

  if (
    props.listLoading ||
    props.getLoading ||
    props.reviewMediaSettingLoading
  ) {
    return <Spin />;
  }

  const handleQR = (id: string) => {
    Modal.info({
      title: null,
      icon: null,
      content: (
        <SurveyQR
          url={`${SURVEY_PAGE_BASE}/static?id=${surveyId}&clientNextEngineProductId=${id}`}
        />
      ),
    });
  };

  const columns = () =>
    compact([
      {
        title: i18n.t("products.table.title.productCode"),
        dataIndex: "productCode",
        key: "productCode",
      },
      {
        title: i18n.t("products.table.title.productName"),
        dataIndex: "productName",
        key: "productName",
      },
      {
        title: i18n.t("products.table.title.url"),
        dataIndex: "url",
        key: "url",
        render: (_: any, surveyProducts: SurveyProducts): JSX.Element => {
          return (
            <Paragraph
              copyable
              className="url-column"
            >{`${SURVEY_PAGE_BASE}/static?id=${surveyId}&clientNextEngineProductId=${surveyProducts.clientNextEngineProductId}`}</Paragraph>
          );
        },
      },
      {
        title: i18n.t("products.table.title.reviewLink"),
        dataIndex: "reviewPageUrl",
        key: "reviewPageUrl",
        render: (_: any, surveyProducts: SurveyProducts): JSX.Element => {
          if (!surveyProducts.reviewPageUrl) {
            return <></>;
          }
          return (
            <Paragraph copyable className="url-column">
              {surveyProducts.reviewPageUrl}
            </Paragraph>
          );
        },
      },
      {
        title: null,
        key: "buttons",
        render: (_: any, surveyProducts: SurveyProducts): JSX.Element => {
          return (
            <div className="survey-table-buttons">
              <Button
                onClick={() =>
                  handleQR(surveyProducts.clientNextEngineProductId)
                }
                icon={<QrcodeOutlined />}
              >
                {i18n.t("products.table.item.qrCode")}
              </Button>
            </div>
          );
        },
      },
    ]);

  const goToSelectProduct = () => {
    pushTo(
      history,
      `/${Page.productSettings}`,
      {
        surveyId,
        mode: MODE_SURVEY,
        surveyType: parsed.surveyType || surveyTypeStore,
      },
      [],
      true
    );
  };

  const addButton = (
    <Button onClick={goToSelectProduct} icon={<PlusOutlined />} type="primary">
      {i18n.t("products.table.settings.add")}
    </Button>
  );

  const handleDelete = () => {
    const selectedIds: Array<string> = props.selectedSurveyProducts.map(
      (s) => s.id!
    );
    const form: SurveyProductDeleteForm = {
      surveyClientNextEngineProductIds: selectedIds,
    };
    props.deleteSurveyProducts(form);
  };

  const deleteButton = (
    <Popconfirm
      title={i18n.t("products.table.settings.deleteCountConfirm", {
        count: props.selectedSurveyProducts.length,
      })}
      disabled={props.selectedSurveyProducts.length < 1}
      onConfirm={handleDelete}
      okText={i18n.t("common.delete")}
      cancelText={i18n.t("common.cancel")}
      okType="danger"
    >
      <Button
        danger={props.selectedSurveyProducts.length > 0}
        disabled={props.selectedSurveyProducts.length < 1}
        icon={<DeleteOutlined />}
      >
        {i18n.t("common.delete")}
      </Button>
    </Popconfirm>
  );

  const csvImportButton = (
    <SMSLink
      to={`/${Page.products}/import`}
      queryParams={{
        mode: MODE_SURVEY,
        surveyType: parsed.surveyType || surveyTypeStore,
      }}
    >
      <Button icon={<ImportOutlined />}>
        {i18n.t("products.table.settings.import")}
      </Button>
    </SMSLink>
  );

  const handleClickExport = () => {
    props.exportCsv(surveyId);
  };

  const csvExportButton = (
    <Button icon={<ExportOutlined />} onClick={handleClickExport}>
      {i18n.t("common.csvExport")}
    </Button>
  );

  const selectedRowKeys = props.selectedSurveyProducts.map(({ id }) => id);

  const handleSelect = (record: SurveyProducts, isSelected: boolean) => {
    if (isSelected) {
      // Adding
      const newSelected = [
        ...props.selectedSurveyProducts,
        {
          id: record.id,
        },
      ];
      props.setSelectedSurveyProducts(newSelected);
    } else {
      // Removing
      let current = [...props.selectedSurveyProducts];
      current = current.filter((c) => c.id !== record.id);
      props.setSelectedSurveyProducts(current);
    }
  };

  const handleSelectAll = (
    isSelected: boolean,
    changeRows: Array<SurveyProducts>
  ) => {
    if (isSelected) {
      // Add all
      const newSelections = changeRows.map((a) => {
        return {
          id: a.id,
        };
      });

      // Add the newly selected rows, deduplicating by key
      const newResult = _.uniqBy(
        [...props.selectedSurveyProducts, ...newSelections],
        (a: { id: string }) => {
          return a.id!;
        }
      );
      props.setSelectedSurveyProducts(newResult);
    } else {
      // Remove all
      props.setSelectedSurveyProducts([]);
    }
  };

  const rowSelection = {
    selectedRowKeys,
    onSelect: handleSelect,
    onSelectAll: handleSelectAll,
    preserveSelectedRowKeys: true,
  };

  const pagination = (
    <SimplePagination
      items={props.items.items}
      getCursor={(model: SurveyProducts) => model.createdAt}
      sortDescending={true}
      hasRight={props.items.hasRight}
      hasLeft={props.items.hasLeft}
      location={props.location}
      history={history}
      match={props.match}
    />
  );

  return (
    <div>
      <div className="table-button-row">
        <Row className="table-button-row">
          {i18n.t("products.table.item.surveyTitle", {
            title: props.survey ? props.survey.title : "",
          })}
        </Row>
        <Row className="meo-sites-form-button-row">
          <Col>
            {addButton}
            {deleteButton}
          </Col>
          <Col>
            {csvExportButton}
            {csvImportButton}
          </Col>
        </Row>
      </div>
      <Table
        rowSelection={rowSelection}
        dataSource={props.items.items}
        columns={columns()}
        rowKey={(products: SurveyProducts) => products.id}
        size="middle"
        pagination={false}
        loading={props.listLoading}
      />
      <Row className="pagination-row">{pagination}</Row>
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    survey: state.survey.survey,
    items: state.surveyProducts.items,
    listLoading: state.surveyProducts.listLoading,
    multipleDeleteFinished: state.surveyProducts.multipleDeleteFinished,
    multipleDeleteLoading: state.surveyProducts.multipleDeleteLoading,
    selectedSurveyProducts: state.surveyProducts.selectedSurveyProducts,
    reviewMediaSettings: state.survey.reviewMediaSettings,
    reviewMediaSettingLoading: state.survey.reviewMediaSettingLoading,
  };
};

const mapDispatchToProps = {
  setPage,
  getReviewMediaSettings,
  getSurvey,
  getSurveyProductsList,
  setSelectedSurveyProducts,
  clearItems,
  deleteSurveyProducts,
  clearSelectedSurveyProducts,
  exportCsv,
};

export default connect(mapStateToProps, mapDispatchToProps)(SurveyProductsList);
