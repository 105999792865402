import React, { useRef, useMemo } from "react";
import JoditEditor from "jodit-react";
import { connect } from "react-redux";
import { RootState } from "store/store";

// This is from https://github.com/jodit/jodit-react/issues/43#issuecomment-657175944
// If we don't use `useMemo`, there will be weird issues where the editor loses
// focus when typing

interface SurveyRichEditorProps {
  value: string;
  onChange: (value: string) => void;
  conditions: string;
  freeInput: string;
  isFreeInput?: boolean;
}

const SurveyRichEditor: React.FC<SurveyRichEditorProps> = (props) => {
  const editor = useRef(null);

  const buttons = [
    "source",
    "|",
    "bold",
    "strikethrough",
    "underline",
    "italic",
    "eraser",
    "|",
    "superscript",
    "subscript",
    "|",
    "ul",
    "ol",
    "|",
    "outdent",
    "indent",
    "|",
    "font",
    "fontsize",
    "brush",
    "paragraph",
    "|",
    "image",
    "video",
    "table",
    "link",
    "|",
    "align",
    "undo",
    "redo",
    "\n",
    "selectall",
    "cut",
    "copy",
    "paste",
    "copyformat",
    "|",
    "hr",
    "symbol",
    "fullsize",
    "preview",
  ];

  const config = {
    placeholder: "",
    language: "ja",
    showTooltipDelay: 500,
    buttons,
    buttonsMD: buttons,
    buttonsSM: buttons,
    buttonsXS: buttons,
  };

  const value = props.isFreeInput ? props.freeInput || "" : props.conditions;

  return useMemo(
    () => (
      <JoditEditor
        ref={editor}
        value={value}
        config={config as any}
        onChange={props.onChange}
      />
    ),
    [props.conditions, props.freeInput]
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    conditions: state.survey.conditions,
    freeInput: state.survey.freeInput,
  };
};

export default connect(mapStateToProps, {})(SurveyRichEditor);
